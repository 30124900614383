import { toNumber } from "lodash";

import {
  AssetCreateInput,
  AssetType,
  AssetUpdateInput
} from "@api/__generated__/globalTypes";

import { AssetFormValues } from "../types";

const mapFormValuesToAsset = (
  data: AssetFormValues
): Omit<AssetCreateInput, "lastWillId"> | AssetUpdateInput => {
  let assetName;

  if (data.name) {
    assetName = data.name;
  } else if (data.type === AssetType.REAL_ESTATE && data.address) {
    assetName = data.address.address1;
  } else assetName = "Unnamed Asset";

  return {
    type: data.type,
    name: assetName,
    subtype: data.subtype,
    liabilityValue: data.liabilityValue
      ? toNumber(data.liabilityValue) || 0
      : undefined,
    grossValue: data.grossValue
      ? toNumber(data.grossValue) || 0
      : undefined,
    isGift: !!data.isGift,
    isFunded: !!data.isFunded,
    address: data.address,
    institution: data.institution,
    beneficiaries: data.isGift
      ? (data.beneficiaries || []).map((beneficiary) => ({
          personId: beneficiary.personId,
          backupDistribution: beneficiary.backupDistribution,
          dollarValue: beneficiary.dollarValue
            ? toNumber(beneficiary.dollarValue) || 0
            : undefined,
          percentageValue: beneficiary.percentageValue
            ? toNumber(beneficiary.percentageValue) || 0
            : undefined,
          name: beneficiary.name,
          charity: beneficiary.charity?.name
            ? {
                name: beneficiary.charity.name,
                ein: beneficiary.charity.ein,
                address: beneficiary.charity.fullAddress,
                isCharity: true
              }
            : undefined,
          // TODO: If UI will allow to add backup beneficiaries, update it.
          backupBeneficiaries: []
        }))
      : []
  };
};

export default mapFormValuesToAsset;
