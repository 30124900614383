import { Typography, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import SearchIconOutlined from "@mui/icons-material/SearchOutlined";

import ModalStepFooter from "@components/ModalStepFooter";

import { AdvisorTutorialProps } from "../types";

const AdvisorTutorialClientDetails: React.FC<
  AdvisorTutorialProps
> = ({ onNext, onPrev }) => (
  <Box>
    <Box
      display="flex"
      flexDirection="column"
      gap="1.25rem"
      alignItems="center"
      sx={{
        mb: "1.25rem"
      }}
    >
      <SearchIconOutlined />
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Client Estate Plan Details" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage defaultMessage='To view a client&apos;s estate plan details, click on their name from the Client Summary dashboard or using the main menu under "Shared with Me"' />
        <br /> <br />
        <FormattedMessage defaultMessage="As long as your client has granted you view permission, you can access their estate plan documents and data, asset details, and other estate plan visualizations (4/5)." />
      </Typography>
    </Box>
    <ModalStepFooter
      prevButtonProps={{ type: "button", onClick: onPrev }}
      nextButtonProps={{
        type: "submit",
        label: "Continue",
        onClick: onNext
      }}
    />
  </Box>
);

export default AdvisorTutorialClientDetails;
