import { LastWill_representatives as Representative } from "@api/plans/__generated__/LastWill";
import { Trust_trustees as Trustee } from "@api/trusts/__generated__/Trust";

const checkSameReps = (
  reps: (Representative | null)[],
  trustPeople: (Trustee | null)[]
) => {
  if (reps.length !== trustPeople.length) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < reps.length; i++) {
    if (
      reps[i]?.representative?.personId !==
      trustPeople[i]?.trustee?.personId
    ) {
      return false; // Ids don't match, return false
    }
  }

  return true; // All ids matched
};

export default checkSameReps;
