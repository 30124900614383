import React, { useCallback } from "react";
import { Popover as MaterialPopover } from "@mui/material";

import { PopoverProps } from "./types";

const Popover: React.FC<PopoverProps> = ({
  TriggerButton,
  children
}) => {
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "mui-popover" : undefined;

  return (
    <>
      <TriggerButton
        sx={{
          cursor: "pointer"
        }}
        aria-describedby={id}
        {...{ onClick }}
      />
      <MaterialPopover
        {...{ id, open, anchorEl, onClose }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {children}
      </MaterialPopover>
    </>
  );
};

export default Popover;
