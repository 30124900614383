import {
  Box,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip
} from "@mui/material";
import React from "react";
import { generatePath } from "react-router-dom";
import { startCase, camelCase } from "lodash";

import { ROUTES } from "@setup/consts/routes";
import { FinancialAdvisorMetricsQuery_financialAdvisorMetrics_draftPlansData as DraftPlanInfoItem } from "@api/financialAdvisor/__generated__/FinancialAdvisorMetricsQuery";
import { Invitation } from "@api/invitation/__generated__/Invitation";

import { DASHBOARD_SECTION_ROUTES } from "../../consts";
import { getLinkFullUrl } from "../../utils";

import { SharedPlanInfoItem, SharedPlanDetailProps } from "./types";
import ClientLink from "../../components/AdvisorDashTools/ClientLink";
import UserTooltip from "../../components/AdvisorDashTools/UserTooltip";

const SharedPlanTable: React.FC<SharedPlanDetailProps> = ({
  plans = [],
  draftPlans = [],
  invitations = []
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem",
      variant: "body1"
    }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Plan Type</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Last Updated</TableCell>
          <TableCell>Your Role</TableCell>
          <TableCell>Permissions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {plans.map((plan: SharedPlanInfoItem) => {
          const {
            id,
            primaryDrafter,
            planType,
            updated,
            myRole,
            permissionLevel
          } = plan;

          const path = generatePath(":pathname?planId=:planId", {
            planId: id.toString(),
            pathname: getLinkFullUrl([
              ROUTES.Dashboard,
              DASHBOARD_SECTION_ROUTES.DocsAndData
            ])
          });

          return (
            <TableRow key={id}>
              <UserTooltip
                name={primaryDrafter?.fullName ?? "Client"}
                email={primaryDrafter?.email ?? "--"}
                child={
                  <TableCell>
                    <Link href={path}>
                      {primaryDrafter?.fullName}
                    </Link>
                  </TableCell>
                }
                path={path}
              />
              <TableCell>{planType}</TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label="Complete"
                  color="success"
                  sx={{
                    borderRadius: "0.25rem",
                    fontWeight: "bold",
                    fontSize: "0.75rem",
                    color: "#ffffff",
                    width: "100%"
                  }}
                />
              </TableCell>
              <TableCell>{updated}</TableCell>
              <TableCell>{myRole}</TableCell>
              <TableCell>{permissionLevel}</TableCell>
            </TableRow>
          );
        })}
        {draftPlans &&
          draftPlans.map((draftPlan: DraftPlanInfoItem, index) => {
            const { data = {}, type, lastPath, user } = draftPlan;

            const clientName = user?.fullName
              ? user?.fullName
              : data?.users?.length && data?.users[0]?.firstName && data?.users[0]?.lastName
              ? `${data?.users[0]?.firstName} ${data?.users[0]?.lastName}`
              : "New Client";
            const clientEmail = user?.email
              ? user?.email
              : data?.users[0]?.email ?? "--";

            return (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${clientName}_${index}`}>
                <UserTooltip
                  name={clientName}
                  email={clientEmail}
                  child={<TableCell>{clientName}</TableCell>}
                />
                <TableCell>{startCase(camelCase(type))}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label="Draft"
                    color="info"
                    sx={{
                      borderRadius: "0.25rem",
                      fontWeight: "bold",
                      fontSize: "0.75rem",
                      color: "#ffffff",
                      width: "100%"
                    }}
                  />
                </TableCell>
                <TableCell>{lastPath}</TableCell>
                <TableCell>Financial Advisor</TableCell>
                <TableCell>--</TableCell>
              </TableRow>
            );
          })}

        {invitations &&
          invitations.map((invite: Invitation) => {
            const {
              clientName,
              token,
              recommendedPlan,
              clientEmail
            } = invite;

            return (
              <TableRow key={token}>
                <UserTooltip
                  name={clientName}
                  email={clientEmail}
                  child={<TableCell>{clientName}</TableCell>}
                />
                <TableCell>
                  {recommendedPlan
                    ? startCase(camelCase(recommendedPlan))
                    : ""}
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label="Invited"
                    color="warning"
                    sx={{
                      borderRadius: "0.25rem",
                      fontWeight: "bold",
                      fontSize: "0.75rem",
                      color: "#ffffff",
                      width: "100%"
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ClientLink token={token} />
                </TableCell>
                <TableCell>Financial Advisor</TableCell>
                <TableCell>--</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </Box>
);

export default SharedPlanTable;
