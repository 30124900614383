import { BaseSyntheticEvent, useCallback, useState } from "react";
import {
  Stack,
  Popover,
  MenuItem,
  IconButton,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreVert
} from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

import theme from "@setup/theme";
import { usePlanTodosContext } from "@providers/planTodos/context";

import { PlanItem as IPlanItem } from "./type";
import UpdatePlanTodo from "./UpdatePlanTodo";

const PlanItem: React.FC<IPlanItem> = ({ task }) => {
  const [optionsOpen, setOptionsOpen] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const { togglePlanTodoStatus, deletePlanTodo } =
    usePlanTodosContext();
  const handleOpenMenu = (event: BaseSyntheticEvent) => {
    setOptionsOpen(event.currentTarget);
  };

  const completed = !!task.completedAt;

  const handleCloseMenu = useCallback(() => {
    setOptionsOpen(null);
  }, []);

  const handleToggle = useCallback(() => {
    handleCloseMenu();
    togglePlanTodoStatus(task, !task.completedAt);
  }, [handleCloseMenu, togglePlanTodoStatus, task]);

  const handleEdit = useCallback(() => {
    handleCloseMenu();
    setEditMode(!editMode);
  }, [editMode, handleCloseMenu]);

  const handleDelete = useCallback(async () => {
    handleCloseMenu();
    deletePlanTodo(task.id);
  }, [handleCloseMenu, deletePlanTodo, task.id]);

  return (
    <Stack
      direction="row"
      sx={{
        px: 1,
        ...(completed &&
          !editMode && {
            color: theme.palette.text.disabled,
            textDecoration: "line-through"
          })
      }}
    >
      {editMode ? (
        <UpdatePlanTodo
          close={() => setEditMode(false)}
          {...{ task }}
        />
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              color="success"
              {...{ checked: completed, onChange: handleToggle }}
            />
          }
          label={task?.title}
          sx={{ flexGrow: 1, m: 0 }}
        />
      )}

      <IconButton
        size="large"
        color="inherit"
        sx={{ opacity: 0.48 }}
        onClick={handleOpenMenu}
      >
        <MoreVert />
      </IconButton>

      <Popover
        open={Boolean(optionsOpen)}
        anchorEl={optionsOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75
            }
          }
        }}
      >
        {!completed && (
          <MenuItem onClick={handleToggle}>
            <CheckCircleOutlined sx={{ mr: 2 }} />
            <FormattedMessage defaultMessage="Mark Complete" />
          </MenuItem>
        )}
        <MenuItem onClick={handleEdit}>
          <EditOutlined sx={{ mr: 2 }} />
          <FormattedMessage defaultMessage="Edit" />
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          sx={{ color: theme.palette.error.main }}
        >
          <DeleteOutlined sx={{ mr: 2 }} />
          <FormattedMessage defaultMessage="Delete" />
        </MenuItem>
      </Popover>
    </Stack>
  );
};

export default PlanItem;
