import * as yup from "yup";
import { useCallback, useMemo } from "react";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  MultiSelectFormValues,
  UseMultiSelectArchetypeReturnType
} from "./types";

const useMultiSelectSchema = (name: string): yup.AnyObjectSchema =>
  useMemo(
    () => makeYupSchema(yup.array().of(yup.string()), name),
    [name]
  );

const useMultiSelectArchetype = (
  name: string,
  onFormSubmit?: (data: MultiSelectFormValues) => void
): UseMultiSelectArchetypeReturnType => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useMultiSelectSchema(name);
  const controls = useFormWithYup<MultiSelectFormValues>(schema);

  const onSubmit = useCallback(
    (data: MultiSelectFormValues) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [saveAndContinue, onFormSubmit]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export default useMultiSelectArchetype;
