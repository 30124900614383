/* eslint-disable react/no-unescaped-entities */
import { useIntl } from "react-intl";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ModalToggler from "@components/Modal/partials/ModalToggler";
import Modal from "@components/Modal";
import useToggle from "@hooks/useToggle";

const PermissionLevelInfoModal: React.FC = () => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  /* TODO: Add back in Edit and Account Owner options once we have those settings fixed
<p>
              <strong>Edit.</strong> "Edit" access allows that person
              to make changes to your plan data and documents. None of
              these changes are official unless you re-sign the
              documents following the instructions on your Just In
              Case Estates checklist.
            </p>
            <p>
              <strong>Account Owner.</strong> Account Owner access
              grants full Edit Access as well as the ability to invite
              and manage people who have access to your plan.
            </p>
*/

  return (
    <>
      <ModalToggler
        icon={<OpenInNewIcon fontSize="small" color="inherit" />}
        label={intl.formatMessage({
          defaultMessage: "Learn more about permission levels."
        })}
        onClick={toggleOn}
      />
      <Modal
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Permission Level Overview"
        })}
        content={
          <>
            <p>
              <strong>View Role Only.</strong> "View Role Only" allows
              that person to see their role in their estate plan
              (i.e., Beneficiary, Executor, etc.). It does not provide
              any other account access or information.
            </p>
            <p>
              <strong>View Docs & Data.</strong> "View Docs & Data"
              allows that person to view your plan data and download
              plan documents.
            </p>
          </>
        }
        actions={[
          {
            label: intl.formatMessage({ defaultMessage: "Got it" }),
            onClick: toggleOff,
            variant: "outlined",
            color: "inherit"
          }
        ]}
      />
    </>
  );
};

export default PermissionLevelInfoModal;
