/* eslint-disable react/no-unescaped-entities */
import { useIntl } from "react-intl";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ModalToggler from "@components/Modal/partials/ModalToggler";
import Modal from "@components/Modal";
import useToggle from "@hooks/useToggle";

const RoleInfoModal: React.FC = () => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <>
      <ModalToggler
        icon={<OpenInNewIcon fontSize="small" color="inherit" />}
        label={intl.formatMessage({
          defaultMessage: "Learn more about roles."
        })}
        onClick={toggleOn}
      />
      <Modal
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Role"
        })}
        content={
          <p>
            Assigning roles for people with whom you've shared your
            plan helps keep things organized. However, the roles that
            you assign here don't change anything in your plan
            documents. Only direct edits to your plan will do that.
            <br />
            <br />
            When you make your estate plan, JIC Estates automatically
            generates roles for the people included in your documents
            based on their responsibility.
            <br />
            <br />
            If you want to share your plan with aditional people, you
            can designate a Role for that person so that your Executor
            and other people who may have access to your plan know who
            they are.
          </p>
        }
        actions={[
          {
            label: intl.formatMessage({ defaultMessage: "Got it" }),
            onClick: toggleOff,
            variant: "outlined",
            color: "inherit"
          }
        ]}
      />
    </>
  );
};

export default RoleInfoModal;
