/* eslint-disable react/no-array-index-key */
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback } from "react";

import { ReviewBoxProps } from "./types";
import * as Styled from "./styles";
import { isReviewItemsArray } from "./utils";

const ReviewBox: React.FC<ReviewBoxProps> = ({
  items,
  canEdit = true,
  ...props
}) => {
  const renderReviewHeader = useCallback(
    (reviewItem: ReviewBoxProps) => (
      <Grid item xs={12} sx={{ zIndex: 2 }}>
        <Typography
          component="h3"
          sx={{
            lineHeight: 1.6,
            color: grey[600],
            fontWeight: 500
          }}
        >
          {reviewItem.title}{" "}
          {reviewItem.href && canEdit && (
            <Styled.Link to={reviewItem.href}>
              <FormattedMessage defaultMessage="Edit" />
            </Styled.Link>
          )}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            lineHeight: 1.6,
            color: grey[600]
          }}
        >
          {reviewItem?.description}{" "}
        </Typography>
      </Grid>
    ),
    [canEdit]
  );

  const renderItems = useCallback(
    (subitems: string[][] | ReviewBoxProps[] | undefined) => {
      if (!subitems || subitems.length === 0) return null;

      const hasMoreItems = subitems.length > 1;

      return (
        <Grid item xs={12}>
          {isReviewItemsArray(subitems) ? (
            subitems.map((item, index) => (
              <Grid
                item
                container
                mb={2}
                xs={12}
                spacing={1}
                key={`${item.title}_${index}`}
              >
                {renderReviewHeader(item)}
                {renderItems(item.items)}
              </Grid>
            ))
          ) : (
            <Grid container spacing={{ xs: 2, md: 5.5 }}>
              {subitems.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={hasMoreItems ? 12 : 12}
                  key={`${item[0]}_${index}`}
                >
                  {item.map((text, i) => (
                    <Typography key={`${text}_${i}`}>
                      {text}
                    </Typography>
                  ))}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      );
    },
    [renderReviewHeader]
  );

  return (
    <Grid container spacing={1}>
      {renderReviewHeader(props)}
      {renderItems(items)}
    </Grid>
  );
};

export default ReviewBox;
