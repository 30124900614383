import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as Styled from "./styles";
import { DropdownListProps, DropdownProps } from "./types";

const DropdownList: React.FC<DropdownListProps> = ({ items }) => {
  const [isItemOpen, setIsItemOpen] = useState(false);

  const toggleDropdown = (index: number) => {
    // eslint-disable-next-line no-param-reassign
    items[index].isOpen = !isItemOpen;
    setIsItemOpen(!isItemOpen);
  };

  const handleClick = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    toggleDropdown(index);
  };

  return (
    <Styled.DesktopSubMenuList>
      {items.map(
        (
          {
            id,
            label,
            onClick,
            href,
            items: subItemItems,
            isExternal,
            isOpen = false,
            isDisabled
          },
          index
        ) => (
          <Styled.DesktopSubMenuListItem key={id}>
            {subItemItems && subItemItems.length && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <Styled.DesktopSubMenuLink
                  onClick={(e: React.MouseEvent) =>
                    handleClick(e, index)
                  }
                  variant="text"
                  disabled={isDisabled}
                  {...{ href, isExternal }}
                >
                  <ExpandMoreIcon
                    color="inherit"
                    sx={{
                      transform: isOpen
                        ? "rotate0"
                        : "rotate(-90deg)",
                      transition: "transform .5s",
                      fontSize: "1.25rem"
                    }}
                  />
                  {label}
                </Styled.DesktopSubMenuLink>
              </Box>
            )}
            {subItemItems && isOpen && (
              <DropdownList items={subItemItems} />
            )}

            {!subItemItems && (
              <Styled.DesktopSubMenuLink
                variant="text"
                disabled={isDisabled}
                onClick={onClick}
                {...{ href, isExternal }}
              >
                {label}
              </Styled.DesktopSubMenuLink>
            )}
          </Styled.DesktopSubMenuListItem>
        )
      )}
    </Styled.DesktopSubMenuList>
  );
};

const AccountDropdown: React.FC<DropdownProps> = ({
  label,
  href,
  items,
  onClick,
  isExternal
}) => {
  const theme = useTheme();

  return (
    <>
      {href && (
        <Styled.Link
          variant="text"
          {...{ href, onClick, isExternal }}
        >
          {label}
        </Styled.Link>
      )}
      {!href && !!onClick && (
        <Styled.MenuButton onClick={onClick}>
          {label}
        </Styled.MenuButton>
      )}
      {!href && !onClick && (
        <Typography
          sx={{
            fontWeight: 600,
            textDecoration: "none",
            padding: "0.5rem",
            color: theme.palette.grey[500]
          }}
        >
          {label}
        </Typography>
      )}
      {items && !!items.length && <DropdownList {...{ items }} />}
    </>
  );
};

export default AccountDropdown;
