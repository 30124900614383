import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { FileType } from "@api/__generated__/globalTypes";
import { ROUTES } from "@setup/consts/routes";
import Image from "@components/Image";
import PPPImage from "@assets/images/docIcons/DocumentIconPetProtection.png";
import { File } from "@api/files/__generated__/File";

import { useSlideActions } from "../hooks";

const editLink = `${ROUTES.PetStart}`;

const PetProtectionSlide = () => {
  const { petProtection } = useMemberPlansState();
  const planFile = useMemo(
    () =>
      petProtection?.plan?.files?.find(
        (f: File) => f.type === FileType.PET_PROTECTION_DOCUMENT
      ),
    [petProtection]
  );

  const actions = useSlideActions({
    planFile,
    editLink,
    memberPlan: petProtection
  });
  const navigate = useNavigate();

  const handleGoToEdit = useCallback(() => {
    if (editLink) {
      navigate(editLink);
    }
  }, [navigate]);

  const renderTop = useCallback(
    () =>
      petProtection && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography align="center" variant="h5">
            <FormattedMessage defaultMessage="Pet Protection Plan" />
          </Typography>
          <Typography align="center" variant="h5">
            <FormattedMessage
              defaultMessage="of {fullName}"
              values={{
                fullName: petProtection.plan.primaryDrafter?.fullName
              }}
            />{" "}
          </Typography>
          <Box sx={{ mt: "1rem" }}>
            <Image
              height="64px"
              width="64px"
              src={PPPImage}
              alt="Pet estate plan icon"
            />
          </Box>
          <Typography mt="1.2rem" align="center" fontSize="1rem">
            <FormattedMessage defaultMessage="Ensure the comfort and care of your pet -- no matter what" />
          </Typography>
        </Box>
      ),
    [petProtection]
  );

  if (!petProtection?.petProtection) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {renderTop()}
        <Typography mt="1.5rem" align="center" fontSize="15px">
          <FormattedMessage defaultMessage="You haven’t created a Pet Estate Plan yet." />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleGoToEdit}
            variant="contained"
            sx={{
              flex: "1",
              borderWidth: "2px",
              fontWeight: "500",
              fontSize: "14px",
              marginTop: "1.1rem"
            }}
          >
            <FormattedMessage defaultMessage="CREATE PET ESTATE PLAN" />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {renderTop()}
      <Grid
        container
        item
        xs={12}
        mt="1.5rem"
        justifyContent="space-around"
      >
        {actions.map(({ icon, label }) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer"
            }}
            key={label}
          >
            {icon}
            <Typography fontSize="0.875rem">{label}</Typography>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default PetProtectionSlide;
