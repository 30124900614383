import {
  useMediaQuery,
  Box,
  Typography,
  useTheme
} from "@mui/material";
import { startCase } from "lodash";
import { FormattedMessage } from "react-intl";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { useMemberPlansState } from "@providers/memberPlansState/context";

import UpdateDocumentsButton from "./UpdateDocumentsButton";
import useDocumentHistory from "./useDocumentHistory";

const DocumentHistory = () => {
  const docHistory = useDocumentHistory();
  const theme = useTheme();
  const { trust, lastWill, petProtection } = useMemberPlansState();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      style={{
        marginTop: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "21px"
      }}
    >
      {docHistory.map((history) => (
        <Box
          sx={{
            borderRadius: "4px",
            padding: "4px"
          }}
          key={history.plan}
        >
          <VerticalTimeline layout="1-column" lineColor="#ccc">
            {history.timeline.map((time, index) => (
              <VerticalTimelineElement
                iconStyle={{
                  background:
                    index === history.timeline.length - 1
                      ? theme.palette.success.main
                      : theme.palette.text.secondary,
                  color: "#fff",
                  height: "0.625rem",
                  width: "0.625rem",
                  border: "none",
                  boxShadow: "none",
                  marginTop: "1.1rem"
                }}
                date={time}
                contentStyle={{
                  borderRadius: "0",
                  boxShadow: "none",
                  marginTop: isDesktop ? "-40px" : "0px",
                  paddingTop: "0",
                  paddingBottom: "0"
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Typography
                  color={theme.palette.grey["500"]}
                  variant="body1"
                >
                  {index === 0 ? (
                    <FormattedMessage
                      defaultMessage="{plan} Created"
                      values={{
                        plan: startCase(history.plan.toLowerCase())
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="{plan} Updated"
                      values={{
                        plan: startCase(history.plan.toLowerCase())
                      }}
                    />
                  )}
                </Typography>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Box>
      ))}
      {(trust?.isOwner ||
        lastWill?.isOwner ||
        petProtection?.isOwner) && <UpdateDocumentsButton />}
    </Box>
  );
};

export default DocumentHistory;
