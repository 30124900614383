import { TabPanelProps } from "./types";

const TabPanel: React.FC<TabPanelProps> = ({
  value,
  index,
  children
}) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && children}
  </div>
);

export default TabPanel;
