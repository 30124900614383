import { useIntl } from "react-intl";
import { useMemo } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const useListSituations = (): {
  title: string;
}[] => {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        title: intl.formatMessage({
          defaultMessage:
            "You are diagnosed with an incurable and irreversible condition that will result in your death in a relatively short time."
        })        
      },
      {
        title: intl.formatMessage({
          defaultMessage:
            "You become unconscious and, to a high degree of medical certainty, will not regain consciousness."
        })
      },
      {
        title: intl.formatMessage({
          defaultMessage:
            "The likely risks and burdens of treatment would outweigh the expected benefits."
        })
      }
    ],
    [intl]
  );
};

const ProlongLifeStep: React.FC = () => {
  const situations = useListSituations();

  return (
    <SingleSelectArchetype
      nullable
      name="users.0.healthcare.preferences.continueToProvideTreatment"
      stepLayout={{
        title: `Do you want your healthcare providers to continue to provide treatment if the following situations arise?`,
        orderedListItems: situations
      }}
      items={[
        {
          label: "I choose to prolong life under these conditions",
          value: "I choose to prolong life under these conditions"
        },
        {
          label:
            "I choose NOT to prolong life under these conditions",
          value: "I choose NOT to prolong life under these conditions"
        },
        {
          label: "Skip this question",
          value: null
        }
      ]}
    />
  );
};

export default ProlongLifeStep;
