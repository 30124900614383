import { Box, CircularProgress, Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import Error from "@components/Error";

import { useSharePlanForm } from "../../hooks";
import * as Styled from "../../styles";

import { SharePlanFormProps } from "./types";
import SharePlanFormElements from "./SharePlanFormElements";
import EmailSentMessageModal from "./EmailSentMessageModal";

const SharePlanForm = ({
  defaultValues,
  disableName = false,
  planId,
  drafterName = "",
  planName = "estate plan",
  onCompleted
}: SharePlanFormProps) => {
  const { methods, onSubmit, error, reset, loading } =
    useSharePlanForm({
      defaultValues,
      planId,
      onCompleted
    });
  const { formatMessage } = useIntl();

  const fullName = methods.watch("fullName") ?? "";

  useEffect(() => {
    const defaultMessage = formatMessage(
      {
        defaultMessage: `Hey {fullName} -- I'm writing to share details of my {planName} that I made with JIC Estates. Check it out and let me know if you have any questions. Thanks! {drafterName}`
      },
      {
        fullName,
        drafterName,
        planName
      }
    );

    methods.setValue("message", defaultMessage);
  }, [formatMessage, fullName, methods, drafterName, planName]);

  if (loading) {
    return (
      <Styled.LoadingWrapper>
        <CircularProgress />
      </Styled.LoadingWrapper>
    );
  }

  if (error) {
    return <Error onAction={reset} />;
  }

  return (
    <Box>
      <Box
        display="flex"
        gap="0.4rem"
        alignItems="center"
        sx={{
          marginTop: "2.5rem"
        }}
      >
        <PersonAddAlt1Icon />
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.375rem"
          }}
        >
          <FormattedMessage defaultMessage="SHARE DETAILS OF YOUR PLAN WITH SOMEONE ELSE" />
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SharePlanFormElements {...{ disableName }} />
        </form>
      </FormProvider>
      <EmailSentMessageModal />
    </Box>
  );
};

export default SharePlanForm;
