import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/PetPlan/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    title="Pet Care Instructions"
    {...{ sections }}
    stepLayout={{
      title:
        "Brilliant. In this next section, we'll capture any important health and care instructions for your pets."
    }}
  />
);

export default SectionsPreviewStep;
