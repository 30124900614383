import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton/IconButton";
import { useTheme } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import useCustomDocumentUpload, {
  FileUploadState
} from "@hooks/useCustomDocumentUpload";

import StateIcon from "./partials/StateIcon";
import { DocumentUploaderProps } from "./types";
import { FileLabel } from "./styles";

const DocumentUploader = ({ planId }: DocumentUploaderProps) => {
  const theme = useTheme();
  const { documents, uploadFiles, cancelUpload } =
    useCustomDocumentUpload(planId);

  const onSelectFiles = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = (event.target.files || []) as File[];

      uploadFiles(...files);
    },
    [uploadFiles]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
        margin: "0 auto",
        fontSize: "14px",
        marginTop: "1.1rem",
        marginBottom: "1.1rem"
      }}
    >
      <Button
        variant="outlined"
        component="label"
        color="inherit"
        sx={{
          fontWeight: "500"
        }}
      >
        <FormattedMessage defaultMessage="+ Add Custom Document" />
        <input
          hidden
          onChange={onSelectFiles}
          accept="application/pdf"
          multiple
          type="file"
        />
      </Button>
      {documents.map((document, idx) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            borderBottom:
              idx < documents.length - 1
                ? `1px solid ${theme.palette.divider}`
                : "none"
          }}
          key={document.id}
        >
          <StateIcon state={document.state} />
          <FileLabel>{document.fileName}</FileLabel>
          <IconButton
            onClick={() => cancelUpload(document.id)}
            disabled={
              ![
                FileUploadState.IDLE,
                FileUploadState.PENDING
              ].includes(document.state)
            }
            sx={{
              ml: "auto",
              visibility: ![
                FileUploadState.IDLE,
                FileUploadState.PENDING
              ].includes(document.state)
                ? "hidden"
                : "visible"
            }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default DocumentUploader;
