import { Typography, Box, Link } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";

import kymeeHeadshot from "@assets/images/kymeeHeadshot.png";
import { Image } from "@components/Image/styles";
import ModalStepFooter from "@components/ModalStepFooter";

import { AdvisorTutorialProps } from "../types";

const AdvisorTutorialEnd: React.FC<AdvisorTutorialProps> = ({
  onNext,
  onPrev
}) => (
  <Box>
    <Box
      display="flex"
      flexDirection="column"
      gap="1.25rem"
      alignItems="center"
      sx={{
        mb: "1.25rem"
      }}
    >
      <Image
        src={kymeeHeadshot}
        alt="JIC Estates member success team member"
        height="64px"
      />
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Our team is ready to support you and your clients." />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Access demos and learn everything you can do in JIC Estates Advisors on your 'Resources' tab." />
        <br /> <br />
        <FormattedMessage defaultMessage="If you want more personalized assistance, book a meeting with us directly from the 'Resources' tab (5/5)." />
      </Typography>
    </Box>
    <ModalStepFooter
      prevButtonProps={{ type: "button", onClick: onPrev }}
      nextButtonProps={{
        type: "submit",
        label: "Close",
        onClick: onNext
      }}
    />
  </Box>
);

export default AdvisorTutorialEnd;
