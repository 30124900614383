import {
  Box,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";

import { SectionLinkItem } from "./types";
import * as Styled from "./styles";

export const SectionQuickLinks = ({
  items
}: {
  items: SectionLinkItem[];
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return isMobile ? (
    <Box my={1} display="flex" flexDirection="row" gap="0.5rem">
      <Typography variant="body2">Jump to:</Typography>
      <Box>
        {items.map(({ href, title }) => (
          <Typography key={href} variant="body2">
            <Styled.Link href={href}>{title}</Styled.Link>
          </Typography>
        ))}
      </Box>
    </Box>
  ) : (
    <Box my={1} display="flex" flexDirection="row" gap="0.5rem">
      <Typography variant="body2">Jump to:</Typography>
      {items.map(({ href, title }, idx) => (
        <Typography
          key={href}
          variant="body2"
          display="inline-flex"
          gap="0.5rem"
        >
          <Styled.Link href={href}>{title}</Styled.Link>
          {idx !== items.length - 1 && " |"}
        </Typography>
      ))}
    </Box>
  );
};
