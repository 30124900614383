import InterstitialArchetype from "@components/archetypes/Interstitial";

const PrimaryGuardiansOnlyStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Sounds good. We'll go with just a primary guardian for now.`
    }}
  />
);

export default PrimaryGuardiansOnlyStep;
