import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanOption } from "@api/__generated__/globalTypes";

import { LAST_WILL_POWER_OF_ATTORNEY_ROUTES } from "../PowerOfAttorney/consts";
import ReviewTabsStepPage from "../../../Healthcare/steps/Reviews/ReviewTabs";
import SectionsPreviewStep from "../../../Healthcare/steps/Preview";
import CreateAHCDStep from "../../../Healthcare/steps/CreateAHCD";
import PartnerCreateAHCDStep from "../../../Healthcare/steps/PartnerCreateAHCD";
import NominateAgentStep from "../../../Healthcare/steps/NominateAgent";
import DesignateAgentStep from "../../../Healthcare/steps/DesignateAgent";
import NominateBackupAgentStep from "../../../Healthcare/steps/NominateBackupAgent";
import DesignateBackupAgentStep from "../../../Healthcare/steps/DesignateBackupAgent";
import PartnerNominateAgentStep from "../../../Healthcare/steps/PartnerNominateAgent";
import DesignatePartnerAsAgentStep from "../../../Healthcare/steps/DesignatePartnerAsAgent";
import PartnerDesignateAgentStep from "../../../Healthcare/steps/PartnerDesignateAgent";
import PartnerNominateBackupAgentStep from "../../../Healthcare/steps/PartnerNominateBackupAgent";
import PartnerDesignateBackupAgentStep from "../../../Healthcare/steps/PartnerDesignateBackupAgent";
import GrantMedicalAccessStep from "../../../Healthcare/steps/GrantMedicalAccess";
import GrantSameMedicalAccessStep from "../../../Healthcare/steps/GrantSameMedicalAccess";
import PartnerGrantMedicalAccessStep from "../../../Healthcare/steps/PartnerGrantMedicalAccess";
import PartnerDesignateMedicalAccessStep from "../../../Healthcare/steps/PartnerDesignateMedicalAccess";
import MedicalPOACompleteStep from "../../../Healthcare/steps/MedicalPOAComplete";
import LivingWillOverviewStep from "../../../Healthcare/steps/LivingWillOverview";
import CreateLivingWillStep from "../../../Healthcare/steps/CreateLivingWill";
import GeneralGuidanceStep from "../../../Healthcare/steps/GeneralGuidance";
import ProlongLifeStep from "../../../Healthcare/steps/ProlongLife";
import ArtificialNutritionStep from "../../../Healthcare/steps/ArtificialNutrition";
import PainReliefStep from "../../../Healthcare/steps/PainRelief";
import PainReliefExceptionStep from "../../../Healthcare/steps/PainReliefException";
import PainReliefExceptionListStep from "../../../Healthcare/steps/PainReliefExceptionList";
import OrganDonationStep from "../../../Healthcare/steps/OrganDonation";
import OrganDonationListStep from "../../../Healthcare/steps/OrganDonationList";
import OrganDonationPurposeStep from "../../../Healthcare/steps/OrganDonationPurpose";
import PartnerCreateLivingWillStep from "../../../Healthcare/steps/PartnerCreateLivingWill";
import PartnerSamePreferencesStep from "../../../Healthcare/steps/PartnerSamePreferences";
import PartnerPreferenceConfirmationStep from "../../../Healthcare/steps/PartnerPreferenceConfirmation";
import PartnerGeneralGuidanceStep from "../../../Healthcare/steps/PartnerGeneralGuidance";
import PartnerProlongLifeStep from "../../../Healthcare/steps/PartnerProlongLife";
import PartnerArtificialNutritionStep from "../../../Healthcare/steps/PartnerArtificialNutrition";
import PartnerPainReliefStep from "../../../Healthcare/steps/PartnerPainRelief";
import PartnerPainReliefExceptionStep from "../../../Healthcare/steps/PartnerPainReliefException";
import PartnerPainReliefExceptionListStep from "../../../Healthcare/steps/PartnerPainReliefExceptionList";
import PartnerOrganDonationStep from "../../../Healthcare/steps/PartnerOrganDonation";
import PartnerOrganDonationListStep from "../../../Healthcare/steps/PartnerOrganDonationList";
import PartnerOrganDonationPurposeStep from "../../../Healthcare/steps/PartnerOrganDonationPurpose";
import SpecialNoteStep from "../../../Healthcare/steps/SpecialNote";
import SpecialNoteDetailsStep from "../../../Healthcare/steps/SpecialNoteDetails";
import PartnerSpecialNoteDetailsStep from "../../../Healthcare/steps/PartnerSpecialNoteDetails";
import ReviewStep from "../../../Healthcare/steps/Reviews/Review";
import DesignateMedicalAccessStep from "../../../Healthcare/steps/DesignateMedicalAccess";
import PartnerSpecialNoteStep from "../../../Healthcare/steps/PartnerSpecialNote";

import { LAST_WILL_HEALTHCARE_ROUTES } from "./consts";

const healthcareSteps: PlanFormStep[] = [
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.CreateAHCD
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.CreateAHCD,
    element: <CreateAHCDStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateAHCD,
        {
          if: [
            {
              "==": [
                { var: "includeAdvanceHealthCareDirective" },
                true
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.NominateAgent,
            LAST_WILL_HEALTHCARE_ROUTES.Review
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateAHCD,
    element: <PartnerCreateAHCDStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeAdvanceHealthCareDirective" }, true]
        },
        LAST_WILL_HEALTHCARE_ROUTES.NominateAgent,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateAgent,
            LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs
          ]
        }
      ]
    }
  },
  // Start of Medical POA Section
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.NominateAgent,
    element: <NominateAgentStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                { var: "users.0.healthcare.authorizeAgents" },
                true
              ]
            },
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] }
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.DesignatePartnerAsAgent,
        {
          if: [
            {
              "==": [
                { var: "users.0.healthcare.authorizeAgents" },
                true
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.DesignateAgent,
            {
              if: [
                {
                  and: [
                    {
                      "==": [
                        {
                          var: "includePartnerAdvanceHealthCareDirective"
                        },
                        true
                      ]
                    },
                    {
                      "==": [{ var: "planOption" }, PlanOption.COUPLE]
                    }
                  ]
                },
                LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateAgent,
                LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.DesignatePartnerAsAgent,
    element: <DesignatePartnerAsAgentStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerAsHealthcareAgent" }, true] },
        LAST_WILL_HEALTHCARE_ROUTES.NominateBackupAgent,
        LAST_WILL_HEALTHCARE_ROUTES.DesignateAgent
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.DesignateAgent,
    element: <DesignateAgentStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.NominateBackupAgent
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.NominateBackupAgent,
    element: <NominateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.nominateBackupAgent" },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.DesignateBackupAgent,
        {
          if: [
            {
              and: [
                { "==": [{ var: "partnerAsHealthcareAgent" }, true] },

                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateBackupAgent,
            {
              if: [
                {
                  and: [
                    {
                      "==": [
                        {
                          var: "includePartnerAdvanceHealthCareDirective"
                        },
                        true
                      ]
                    },
                    {
                      "==": [{ var: "planOption" }, PlanOption.COUPLE]
                    }
                  ]
                },
                LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateAgent,
                LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.DesignateBackupAgent,
    element: <DesignateBackupAgentStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerAsHealthcareAgent" }, true] },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateBackupAgent,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateAgent,
            LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateAgent,
    element: <PartnerNominateAgentStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.1.healthcare.authorizeAgents" }, true]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateAgent,
        {
          if: [
            {
              "==": [
                { var: "includeAdvanceHealthCareDirective" },
                true
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateAgent,
    element: <PartnerDesignateAgentStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateBackupAgent
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateBackupAgent,
    element: <PartnerNominateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.nominateBackupAgent" },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateBackupAgent,
        {
          if: [
            {
              "==": [
                { var: "includeAdvanceHealthCareDirective" },
                true
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateBackupAgent,
    element: <PartnerDesignateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeAdvanceHealthCareDirective" }, true]
        },
        LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess,
        LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess,
    element: <GrantMedicalAccessStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.healthcare.medicalRecordAccess.authorizeOthers"
            },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.DesignateMedicalAccess,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess,
            LAST_WILL_HEALTHCARE_ROUTES.MedicalPOAComplete
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.DesignateMedicalAccess,
    element: <DesignateMedicalAccessStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                { var: "includePartnerAdvanceHealthCareDirective" },
                true
              ]
            },
            {
              "==": [{ var: "planOption" }, PlanOption.COUPLE]
            }
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.GrantSameMedicalAccess,
        LAST_WILL_HEALTHCARE_ROUTES.MedicalPOAComplete
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.GrantSameMedicalAccess,
    element: <GrantSameMedicalAccessStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.copyMedicalRecordAccess" },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.MedicalPOAComplete,
        LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess,
    element: <PartnerGrantMedicalAccessStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.healthcare.medicalRecordAccess.authorizeOthers"
            },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateMedicalAccess,
        LAST_WILL_HEALTHCARE_ROUTES.MedicalPOAComplete
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateMedicalAccess,
    element: <PartnerDesignateMedicalAccessStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.MedicalPOAComplete
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.MedicalPOAComplete,
    element: <MedicalPOACompleteStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.LivingWillOverview
  },
  // Start of Living Will Section
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.LivingWillOverview,
    element: <LivingWillOverviewStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeAdvanceHealthCareDirective" }, true]
        },
        LAST_WILL_HEALTHCARE_ROUTES.CreateLivingWill,
        LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateLivingWill
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.CreateLivingWill,
    element: <CreateLivingWillStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.preferences.specified" },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.GeneralGuidance,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateLivingWill,
            {
              if: [
                { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
                LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs,
                LAST_WILL_HEALTHCARE_ROUTES.Review
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.GeneralGuidance,
    element: <GeneralGuidanceStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.ProlongLife
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.ProlongLife,
    element: <ProlongLifeStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.ArtificialNutrition
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.ArtificialNutrition,
    element: <ArtificialNutritionStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PainRelief
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PainRelief,
    element: <PainReliefStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.preferences.reliefFromPain" },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PainReliefException,
        LAST_WILL_HEALTHCARE_ROUTES.OrganDonation
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PainReliefException,
    element: <PainReliefExceptionStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.healthcare.preferences.reliefFromPainAddNote"
            },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PainReliefExceptionList,
        LAST_WILL_HEALTHCARE_ROUTES.OrganDonation
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PainReliefExceptionList,
    element: <PainReliefExceptionListStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.OrganDonation
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.OrganDonation,
    element: <OrganDonationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.preferences.organDonation" },
            "Give only specific organs, tissues, or parts"
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.OrganDonationList,
        {
          if: [
            {
              "==": [
                {
                  var: "users.0.healthcare.preferences.organDonation"
                },
                "Give any needed organs, tissues, or parts"
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.OrganDonationPurpose,
            LAST_WILL_HEALTHCARE_ROUTES.SpecialNote
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.OrganDonationList,
    element: <OrganDonationListStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.OrganDonationPurpose
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.OrganDonationPurpose,
    element: <OrganDonationPurposeStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.SpecialNote
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.SpecialNote,
    element: <SpecialNoteStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeHealthcareNote" }, true]
        },
        LAST_WILL_HEALTHCARE_ROUTES.SpecialNoteDetails,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateLivingWill,
            {
              if: [
                { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
                LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs,
                LAST_WILL_HEALTHCARE_ROUTES.Review
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.SpecialNoteDetails,
    element: <SpecialNoteDetailsStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                { var: "includePartnerAdvanceHealthCareDirective" },
                true
              ]
            },
            {
              "==": [{ var: "planOption" }, PlanOption.COUPLE]
            }
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateLivingWill,
        {
          if: [
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
            LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs,
            LAST_WILL_HEALTHCARE_ROUTES.Review
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateLivingWill,
    element: <PartnerCreateLivingWillStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                { var: "users.1.healthcare.preferences.specified" },
                true
              ]
            },
            {
              "==": [
                { var: "users.0.healtchare.preferences.specified" },
                true
              ]
            }
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerSamePreferences,
        {
          if: [
            {
              "==": [
                { var: "users.1.healthcare.preferences.specified" },
                true
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerGeneralGuidance,
            LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerSamePreferences,
    element: <PartnerSamePreferencesStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerPreferenceConfirmation
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerPreferenceConfirmation,
    element: <PartnerPreferenceConfirmationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.preferences.specified" },
            { var: "uesrs.1.healthcare.preferences.copy" },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs,
        LAST_WILL_HEALTHCARE_ROUTES.PartnerGeneralGuidance
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerGeneralGuidance,
    element: <PartnerGeneralGuidanceStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerProlongLife
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerProlongLife,
    element: <PartnerProlongLifeStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerArtificialNutrition
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerArtificialNutrition,
    element: <PartnerArtificialNutritionStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerPainRelief
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerPainRelief,
    element: <PartnerPainReliefStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.preferences.reliefFromPain" },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerPainReliefException,
        LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonation
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerPainReliefException,
    element: <PartnerPainReliefExceptionStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.healthcare.preferences.reliefFromPainAddNote"
            },
            true
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerPainReliefExceptionList,
        LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonation
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerPainReliefExceptionList,
    element: <PartnerPainReliefExceptionListStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonation
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonation,
    element: <PartnerOrganDonationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.preferences.organDonation" },
            "Give only specific organs, tissues, or parts"
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonationList,
        {
          if: [
            {
              "==": [
                {
                  var: "users.1.healthcare.preferences.organDonation"
                },
                "Give any needed organs, tissues, or parts"
              ]
            },
            LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonationPurpose,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerSpecialNote
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonationList,
    element: <PartnerOrganDonationListStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonationPurpose
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonationPurpose,
    element: <PartnerOrganDonationPurposeStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.PartnerSpecialNote
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerSpecialNote,
    element: <PartnerSpecialNoteStep />,
    rules: {
      if: [
        {
          and: [
            { "==": [{ var: "includePartnerHealthcareNote" }, true] },
            {
              "==": [{ var: "planOption" }, PlanOption.COUPLE]
            }
          ]
        },
        LAST_WILL_HEALTHCARE_ROUTES.PartnerSpecialNoteDetails,
        LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs
      ]
    }
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.PartnerSpecialNoteDetails,
    element: <PartnerSpecialNoteDetailsStep />,
    rules: LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs
  },
  {
    path: LAST_WILL_HEALTHCARE_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.PowerOfAttorney}/${LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Preview}`
  },

  {
    path: LAST_WILL_HEALTHCARE_ROUTES.ReviewTabs,
    element: <ReviewTabsStepPage />,
    rules: `${LAST_WILL_SECTION_ROUTES.PowerOfAttorney}/${LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={LAST_WILL_HEALTHCARE_ROUTES.Preview} />
  }
];

export default healthcareSteps;
