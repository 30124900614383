import { Box } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Tabs from "@components/Tabs";
import { TabsItem } from "@components/Tabs/types";
import { useMemberPlansState } from "@providers/memberPlansState/context";

import BlockedPeopleList from "../components/ShareTab/BlockedInvitationList";
import PeopleWithAccessList from "../components/ShareTab/PeopleWithAccessList";
import SharePlanForm from "../components/ShareTab/SharePlanForm";
import TabNotAvailableMessage from "../components/TabNotAvailableMessage";
import TabRestrictedMessage from "../components/TabRestrictedMessage";
import { getEstatePlanTitle } from "../utils";

const ShareTab = () => {
  const { formatMessage } = useIntl();

  const theme = useTheme();

  const { myRoles, trust, lastWill, petProtection, planCompleted } =
    useMemberPlansState();

  const sharingDrafterName =
    `${myRoles?.accountOwner?.firstName} ${myRoles?.accountOwner?.lastName}` ||
    myRoles?.accountOwner?.fullName;

  const pageTitle = getEstatePlanTitle(
    trust?.plan || lastWill?.plan || null,
    myRoles
  );

  if (
    myRoles &&
    !trust?.isOwner &&
    !lastWill?.isOwner &&
    !petProtection?.isOwner
  ) {
    return (
      <>
        <Typography
          variant="h1"
          textAlign="center"
          margin={theme.spacing(2)}
        >
          {pageTitle}
        </Typography>
        <TabRestrictedMessage
          tabName="Plan Sharing"
          message={`Plan Sharing is only available for Account Owners. Only ${sharingDrafterName} can share this estate plan.`}
        />
      </>
    );
  }

  if (!planCompleted) {
    return (
      <>
        <Typography
          variant="h1"
          textAlign="center"
          margin={theme.spacing(2)}
        >
          {pageTitle}
        </Typography>
        <TabNotAvailableMessage
          tabName="Plan Sharing"
          planDependencies="Revocable Living Trust, Last Will, or Pet Estate Plan"
          unlockWithPet
        />
      </>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const items = useMemo(() => {
    const tabItems: TabsItem[] = [];

    if (trust && trust.isOwner) {
      tabItems.push({
        label: formatMessage({
          defaultMessage: "Revocable Living Trust"
        }),
        content: (
          <Box key="trust">
            <Typography
              variant="h1"
              textAlign="center"
              margin={theme.spacing(2)}
            >
              {pageTitle}
            </Typography>
            <PeopleWithAccessList plan={trust.plan} />
            <BlockedPeopleList plan={trust.plan} />
            <SharePlanForm
              planId={trust.plan.id}
              drafterName={trust.plan.primaryDrafter?.fullName || ""}
              planName="Revocable Living Trust"
            />
          </Box>
        )
      });
    }

    if (!trust && lastWill && lastWill.isOwner) {
      tabItems.push({
        label: formatMessage({
          defaultMessage: "Last Will"
        }),
        content: (
          <Box key="lastWill">
            <Typography
              variant="h1"
              textAlign="center"
              margin={theme.spacing(2)}
            >
              {pageTitle}
            </Typography>
            <PeopleWithAccessList plan={lastWill.plan} />
            <BlockedPeopleList plan={lastWill.plan} />
            <SharePlanForm
              planId={lastWill.plan.id}
              drafterName={
                lastWill.plan.primaryDrafter?.fullName || ""
              }
              planName="Last Will & Testament"
            />
          </Box>
        )
      });
    }

    if (petProtection && petProtection.isOwner) {
      tabItems.push({
        label: formatMessage({
          defaultMessage: "Pet Estate Plan"
        }),
        content: (
          <Box key="petProtection">
            <Typography
              variant="h1"
              textAlign="center"
              margin={theme.spacing(2)}
            >
              {pageTitle}
            </Typography>
            <PeopleWithAccessList plan={petProtection.plan} />
            <BlockedPeopleList plan={petProtection.plan} />
            <SharePlanForm
              planId={petProtection.plan.id}
              drafterName={
                petProtection.plan.primaryDrafter?.fullName || ""
              }
              planName="Pet Estate Plan"
            />
          </Box>
        )
      });
    }

    return tabItems;
  }, [
    formatMessage,
    trust,
    pageTitle,
    theme,
    lastWill,
    petProtection
  ]);

  if (items.length === 0) return undefined;

  if (items.length === 1) return items[0].content;

  return (
    <Box>
      <Tabs {...{ items }} />
    </Box>
  );
};

export default ShareTab;
