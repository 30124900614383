import { useIntl } from "react-intl";
import { Grid } from "@mui/material";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import ReviewBox from "@components/ReviewBox";
import { usePlanForm } from "@providers/planForm/context";

import { ReviewArchetypeProps } from "./types";

const ReviewArchetype: React.FC<ReviewArchetypeProps> = ({
  items,
  stepLayout
}) => {
  const intl = useIntl();
  const { goBack, saveAndContinue } = usePlanForm();

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage: "Let’s confirm the info is correct"
      })}
      isWide
      {...stepLayout}
    >
      <Grid container spacing={{ xs: 3, md: 5.5 }}>
        {items.map(({ title, isFullWidth, ...item }) => (
          <Grid item xs={12} md={12} key={title}>
            <ReviewBox {...{ title, ...item }} />
          </Grid>
        ))}
      </Grid>
      <FormStepFooter
        prevButtonProps={{ type: "button", onClick: goBack }}
        nextButtonProps={{
          type: "button",
          onClick: () => saveAndContinue(),
          label: intl.formatMessage({ defaultMessage: "Confirm" })
        }}
      />
    </StepLayout>
  );
};

export default ReviewArchetype;
