import React, { PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<
    Omit<LinkProps, "to"> & {
      href: LinkProps["to"];
      isExternal?: boolean;
    }
  >
>(({ href, isExternal, children, ...rest }, ref) =>
  isExternal ? (
    <MaterialLink
      {...{ ref, ...rest }}
      href={typeof href === "string" ? href : href.pathname}
      rel="noopener noreferrer"
    >
      {children}
    </MaterialLink>
  ) : (
    <Link to={href} {...{ ref, ...rest }}>
      {children}
    </Link>
  )
);

export default LinkBehavior;
