import { useIntl } from "react-intl";
import { Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormInput from "@components/FormFields/FormInput";
import FormAutocomplete from "@components/FormFields/FormAutocomplete";

import {
  useBreedTypes,
  usePetPhysicalTraitsArchetype
} from "./hooks";
import { PetPhysicalTraitsArchetypeProps } from "./types";

const PetPhysicalTraitsArchetype: React.FC<
  PetPhysicalTraitsArchetypeProps
> = ({ name, stepLayout, onSubmit: onFormSubmit, breed }) => {
  const intl = useIntl();

  const { controls, goBack, onSubmit } =
    usePetPhysicalTraitsArchetype(name, onFormSubmit);
  const breedOptions = useBreedTypes(breed);

  return (
    <StepLayout {...stepLayout}>
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <Stack spacing={4}>
            <FormAutocomplete
              textFieldProps={{
                label: "Breed (choose one or write your own)"
              }}
              name={`${name}.breed`}
              options={breedOptions}
              freeSolo
            />
            <FormInput
              name={`${name}.weight`}
              label={intl.formatMessage({
                defaultMessage: "Weight (lbs)"
              })}
              type="number"
            />
            <FormInput
              name={`${name}.characteristics`}
              label={intl.formatMessage({
                defaultMessage: "Identifying Characteristics"
              })}
              variant="outlined"
              multiline
              minRows={5}
            />
          </Stack>
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default PetPhysicalTraitsArchetype;
