/* eslint-disable react/no-unescaped-entities */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import MultiSelectArchetype from "@components/archetypes/MultiSelect";
import { MultiSelectOption } from "@components/archetypes/MultiSelect/partials/MultiSelectForm/types";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPowersToGrantStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );
  const { formatMessage } = useIntl();

  const options: MultiSelectOption[] = useMemo(
    () => [
      {
        value: "Real Property",
        label: formatMessage({
          defaultMessage: "Real Property (e.g., Real Estate)"
        })
      },
      {
        value: "Tangible Personal Property",
        label: formatMessage({
          defaultMessage:
            "Tangible Personal Property (e.g., Cars, Boats and Other Personal Items)"
        })
      },
      {
        value: "Stocks and Bonds",
        label: formatMessage({
          defaultMessage: "Stocks and Bonds"
        })
      },
      {
        value: "Commodities and Options",
        label: formatMessage({
          defaultMessage: "Commodities and Options"
        })
      },
      {
        value: "Banks and Other Financial Institutions",
        label: formatMessage({
          defaultMessage: "Banks and Other Financial Institutions"
        })
      },
      {
        value: "Operation of Entity or Business",
        label: formatMessage({
          defaultMessage: "Operation of Entity or Business"
        })
      },
      {
        value: "Insurance and Annuities",
        label: formatMessage({
          defaultMessage: "Insurance and Annuities"
        })
      },
      {
        value: "Estates, Trusts, and Other Beneficial Interests",
        label: formatMessage({
          defaultMessage:
            "Estates, Trusts, and Other Beneficial Interests"
        })
      },
      {
        value: "Claims and Litigation",
        label: formatMessage({
          defaultMessage: "Claims and Litigation"
        })
      },
      {
        value: "Personal and Family Maintenance",
        label: formatMessage({
          defaultMessage: "Personal And Family Maintenance"
        })
      },
      {
        value:
          "Benefits from Governmental Programs or Civil / Military Service",
        label: formatMessage({
          defaultMessage:
            "Benefits from Governmental Programs or Civil / Military Service"
        })
      },
      {
        value: "Retirement Plans",
        label: formatMessage({
          defaultMessage: "Retirement Plans"
        })
      },
      {
        value: "Taxes",
        label: formatMessage({
          defaultMessage: "Taxes"
        })
      }
    ],
    [formatMessage]
  );

  return (
    <MultiSelectArchetype
      valueAsKey
      {...{ options }}
      name="users.1.poa.agentPowers"
      stepLayout={{
        title: `Which of the following powers does ${partnerFirstName} want to provide ${partnerFirstName}'s agent?`,
        subText: `Choose as many as you like.`
      }}
    />
  );
};

export default PartnerPowersToGrantStep;
