export const TRUST_MANAGEMENT_ROUTES = {
  Preview: "",
  // Create or Modify
  CreateOrModify: "create-or-modify",
  // Trust Name
  TrustName: "trust-name",
  TrustNameCustom: "trust-name-custom",
  // Existing Trust Date
  TrustDate: "trust-date",
  // Start Nominations for Trust
  TrusteeOverview: "trustee-overview",
  NominateCoTrustee: "nominate-cotrustee",
  DesignateCoTrustee: "designate-cotrustee",
  NominateBackupTrustee: "nominate-backup-trustee",
  DesignateBackupTrustee: "designate-backup-trustee",
  // Start Nominations for Will
  RepresentativeOverview: "representative-overivew",
  DesignateTrusteeSameReps: "trustees-as-reps",
  DesignateRepresentative: "designate-rep",
  NominateBackupRepresentative: "nominate-backup-rep",
  DesignateBackupRepresentative: "designate-backup-rep",
  // Trustee Comp
  TrusteeCompensation: "trustee-comp",
  TrusteeCompensationDollar: "trustee-comp-amt",
  TrusteeCompensationPercent: "trustee-comp-pct",
  // Rep Comp
  RepCompensation: "rep-comp",
  RepCompensationDollar: "rep-comp-amt",
  RepCompensationPercent: "rep-comp-pct",

  // Partner Management Starts Here
  PrimaryEnd: "primary-complete",

  // Create or Modify
  PartnerCreateOrModify: "partner-create-or-modify",

  // Partner Trust Name
  PartnerTrustName: "partner-trust-name",
  PartnerTrustNameCustom: "partner-trust-name-custom",

  // Existing Trust Date
  PartnerTrustDate: "partner-trust-date",
  // Partner Trustee FYI
  PartnerTrusteeStart: "partner-start",

  // Partner Duplicate
  PartnerSameTrusteesAndReps: "partner-same-trustees",
  PartnerSameComp: "partner-same-comp",

  // Partner Custom Nominations
  PartnerNominateCoTrustee: "partner-nominate-cotrustee",
  PartnerDesignateCoTrustee: "partner-designate-cotrustee",
  PartnerNominateBackupTrustee: "partner-nominate-backup-trustee",
  PartnerDesignateBackupTrustee: "partner-designate-backup-trustee",
  PartnerRepresentativeOverview: "partner_representative-overview",
  PartnerDesignateTrusteeSameReps: "partner-trustees-as-reps",
  PartnerDesignateRepresentative: "partner-designate-rep",
  PartnerNominateBackupRepresentative: "partner-nominate-backup-rep",
  PartnerDesignateBackupRepresentative:
    "partner-designate-backup-rep",
  // Partner Trustee Comp
  PartnerTrusteeCompensation: "partner-trustee-comp",
  PartnerTrusteeCompensationDollar: "partner-trustee-comp-amt",
  PartnerTrusteeCompensationPercent: "partner-trustee-comp-pct",
  // Partner Rep Comp
  PartnerRepCompensation: "partner-rep-comp",
  PartnerRepCompensationDollar: "partner-rep-comp-amt",
  PartnerRepCompensationPercent: "partner-rep-comp-pct",

  // Review
  Review: "review",
  ReviewTabs: "review-tabs"
};
