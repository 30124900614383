/* eslint-disable camelcase */
import { groupBy } from "lodash";

import { PetProtection } from "@api/plans/__generated__/PetProtection";

const areSameGuardians = (pets: PetProtection | null): boolean => {
  const guardianIds = pets?.pets?.flatMap((pet) =>
    pet?.guardians?.flatMap((guardian) => guardian?.guardianId)
  );
  const groupedGuardians = groupBy(guardianIds);

  // TODO : validate this function; copied from jic-api, but doesn't look like it will work in all cases that I want it to work
  return Object.values(groupedGuardians).every(
    (guardians) => guardians.length === pets?.pets?.length
  );
};

export default areSameGuardians;
