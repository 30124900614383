import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

const PartnerMakeCashGiftStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [, partner]
  } = values;

  const { setValue: setCashGifts } = useFormField(
    "users.1.assets.cash"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const isCashGifts = get(data, "users.1.gifts.makeCashGifts");

      if (!parseBoolean(isCashGifts)) {
        setCashGifts([]);
      }
    },
    [setCashGifts]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Last one! Does ${partner.firstName} want to make any cash gifts?`
      }}
      {...{ onSubmit }}
      name="users.1.gifts.makeCashGifts"
    />
  );
};

export default PartnerMakeCashGiftStep;
