import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  UseFormProps,
  UseFormReturn
} from "react-hook-form";
import * as yup from "yup";

export const useFormWithYup = <T>(
  schema: yup.AnyObjectSchema,
  formProps: UseFormProps<T> = {}
): UseFormReturn<T> => {
  const resolver = yupResolver(schema);

  return useForm<T>({ resolver, ...formProps });
};
