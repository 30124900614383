/* eslint-disable react/no-unescaped-entities */
import PersonDetailsArchetype from "@components/archetypes/PersonDetails";
import { BlogLinks } from "@setup/consts/articleLinks";

const DesignateRepresentativeStep: React.FC = () => (
  <PersonDetailsArchetype
    name="users.0.lastWill.personalRepresentatives.0"
    stepLayout={{
      title: `Who do you want to serve as your Personal Representative?`,
      subText: "See our tips for choosing a Personal Representative.",
      moreInfoModalProps: {
        title: "Tips for Choosing a Personal Representative",
        togglerLabel: "Learn more",
        content: (
          <p>
            Your Personal Representative is a trusted individual who
            is responsible for winding up your earthly affairs after
            you pass and following the instructions that you provide
            in your will.
            <br />
            <br />
            There are six characteristics that you should look for in
            a personal representative:
            <ol>
              <li>Trustworthy</li>
              <li>Demonstrates attention to detail</li>
              <li>Patient, yet deliberate</li>
              <li>
                Qualifies under your state's rules for serving as a
                personal representative
              </li>
              <li>Checks and opens the mail</li>
              <li>Not afraid to ask for help</li>
            </ol>
            For a more comprehensive discussion of these
            characteristics, check out our blog post using the link
            below.
          </p>
        ),
        actions: [
          {
            label: "Go to Article",
            href: BlogLinks.howToChooseRepresentative
          }
        ],
        closeLabel: "Got it"
      }
    }}
    personRole="Representative"
  />
);

export default DesignateRepresentativeStep;
