import { useFormField } from "@components/FormFields/hooks";
import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { DistribType } from "@api/__generated__/globalTypes";

// TODO: I should probably add some more callbacks or steps in to capture how the user wants to set up the separate trusts (if going that route);

const RemainderPooledFinalDistributionStep: React.FC = () => {
  const { value: trustType } = useFormField<DistribType>(
    "users.0.assets.estateBalance.0.beneficiaries.subTrust.trustType"
  );
  const humanizedTrustType =
    trustType === DistribType.POOLED_CHILDREN
      ? "Pooled Children's Trust"
      : "Pooled Family Trust";

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `After the ${humanizedTrustType} terminates, how do you want to make the final distribution to your children?`,
        subText: "Learn more about these distribution types.",
        moreInfoModalProps: {
          title: "Distribution Types Explained",
          content: (
            <p>
              <strong>Outright Distributions.</strong> Outright
              distributions are gifts with no strings attached. They
              are made as soon after your death as practical, and your
              beneficiary can use the gift for any purpose.
              <br />
              <br />
              <strong>Separate Trusts.</strong> You can make gifts
              subject to greater protection or eligibility
              requirements by giving in separate trusts -- one for
              each of your beneficiaries.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.0.assets.estateBalance.0.beneficiaries.0.subTrust.terminationDistribution"
      items={[
        {
          value: DistribType.OUTRIGHT,
          label: `Give outright in equal shares`
        },
        {
          value: DistribType.SEPARATE_CHILDREN,
          label: `Give equally in separate children's trusts`
        }
      ]}
    />
  );
};

export default RemainderPooledFinalDistributionStep;
