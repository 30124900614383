import DateSelectArchetype from "@components/archetypes/DateSelect";
import { useFormField } from "@components/FormFields/hooks";

const ExistingTrustDateStep: React.FC = () => {
  const { value: trustName } = useFormField("users.0.trust.name");

  return (
    <DateSelectArchetype
      stepLayout={{
        title: `What is the exact date that ${trustName} was created?`,
        subText:
          "This date will typically be specified in the first section of your trust agreement. If it's not specified there, use the date that you signed the agreement from the signature pages."
      }}
      name="users.0.trust.createdAt"
    />
  );
};

export default ExistingTrustDateStep;
