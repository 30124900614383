import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewTabsArchetype from "@components/archetypes/ReviewTabs";
import ReviewBox from "@components/ReviewBox";
import { usePlanForm } from "@providers/planForm/context";
import {
  ExcludedPerson,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import { PartnerType } from "@api/__generated__/globalTypes";
import { RelationshipStatus } from "@setup/enums";

import { usePartnerGiftsReviewTab } from "./Reviews/usePartnerGiftsReviewTab";
import { usePrimaryDrafterGiftsReviewTab } from "./Reviews/usePrimaryDrafterGiftsReviewTab";

const ReviewTabsStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter, partner]
  } = values;

  const { value: relationshipStatus } =
    useFormField<RelationshipStatus>("users.0.relationshipStatus");
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");

  const { value: excludePersons } = useFormField<ExcludedPerson[]>(
    "users.0.gifts.excludePersons"
  );

  const primaryDrafterItem = usePrimaryDrafterGiftsReviewTab({
    values,
    relationshipStatus,
    partnerType,
    excludePersons
  });

  const partnerItems = usePartnerGiftsReviewTab({
    values,
    partnerType,
    relationshipStatus,
    excludePersons
  });

  const items = useMemo(
    () => [
      {
        label:
          primaryDrafter?.firstName ??
          formatMessage({ defaultMessage: "You" }),
        content: primaryDrafterItem && (
          <Grid container spacing={{ xs: 3, md: 5.5 }}>
            {primaryDrafterItem.map(({ title, ...item }) => (
              <Grid item xs={12} md={12} key={title}>
                <ReviewBox {...{ title, ...item }} />
              </Grid>
            ))}
          </Grid>
        )
      },
      {
        label:
          partner?.firstName ??
          formatMessage({ defaultMessage: "Partner" }),
        content: partnerItems &&
          relationshipStatus !== RelationshipStatus.SINGLE &&
          relationshipStatus !== RelationshipStatus.DIVORCED && (
            <Grid container spacing={{ xs: 3, md: 5.5 }}>
              {partnerItems.map(({ title, ...item }) => (
                <Grid item xs={12} md={12} key={title}>
                  <ReviewBox {...{ title, ...item }} />
                </Grid>
              ))}
            </Grid>
          )
      }
    ],
    [
      primaryDrafter?.firstName,
      formatMessage,
      primaryDrafterItem,
      partner?.firstName,
      partnerItems,
      relationshipStatus
    ]
  );

  return (
    <ReviewTabsArchetype
      {...{ items }}
      stepLayout={{
        title: `Nice stuff. Here's the recap of what you shared:`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewTabsStep;
