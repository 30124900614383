import { useContext } from "react";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { LastWillPlanValues } from "@pages/LastWill/types";
import {
  PlanFormContext,
  usePlanForm
} from "@providers/planForm/context";
import { PlanType } from "@api/__generated__/globalTypes";

import { usePrimaryDrafterGuardiansReviewTab } from "./Reviews/usePrimaryDrafterGuardiansReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { type } = useContext(PlanFormContext);

  const items = usePrimaryDrafterGuardiansReviewTab({ values, type });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Still looking good?`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
