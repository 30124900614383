import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/Trust/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    title="Create My Trust"
    stepLayout={{
      title: `Let's keep rolling with a discussion of who and how you want to manage your estate after you pass.`
    }}
  />
);

export default SectionsPreviewStep;
