import { useMemo } from "react";
import { useIntl } from "react-intl";

import { FileType, PlanOption } from "@api/__generated__/globalTypes";
import { Plans_plans_files as File } from "@api/plans/__generated__/Plans";
import { useMemberPlansState } from "@providers/memberPlansState/context";

import {
  GenericSlide,
  HealthCareSlide,
  TrustSlide,
  JointTrustSlide,
  PourOverWillSlide,
  CertificateOfTrustSlide,
  LastWillSlide,
  PetProtectionSlide,
  PetHIPPASlide,
  PetPOASlide,
  PowerOfAttorneySlide,
  PartnerTrustSlide,
  PartnerPourOverWillSlide,
  PartnerCertificateOfTrustSlide,
  PartnerLastWillSlide,
  PartnerHealthCareSlide,
  PartnerPowerOfAttorneySlide
} from "../Slides";
import PartnerHIPPASlide from "../Slides/PartnerHIPPASlide";
import HIPPASlide from "../Slides/HIPPASlide";

const useDocSlides = () => {
  const { trust, lastWill, petProtection } = useMemberPlansState();
  const { formatMessage } = useIntl();

  const trustPlanFiles = trust?.plan.files || [];

  const lastWillPlanFiles = lastWill?.plan.files || [];

  const petProtectionPlanFiles = petProtection?.plan.files || [];

  const planOption = trust
    ? trust.plan.planOption
    : lastWill?.plan.planOption;

  const customDocuments = [
    ...trustPlanFiles,
    ...lastWillPlanFiles,
    ...petProtectionPlanFiles
  ].filter((file) => file.type === FileType.CUSTOM_DOCUMENT);

  const getPrimaryDrafterAndPartner = () => {
    if (trust) {
      return {
        primaryDrafter: trust.plan.primaryDrafter,
        partner: trust.plan.partner
      };
    }

    if (lastWill) {
      return {
        primaryDrafter: lastWill.plan.primaryDrafter,
        partner: lastWill.plan.partner
      };
    }

    return {
      primaryDrafter: petProtection?.plan.primaryDrafter
    };
  };

  const { primaryDrafter, partner } = getPrimaryDrafterAndPartner();

  const primaryDrafterJointTrust = trustPlanFiles.find(
    (file: File) =>
      file.ownerId === primaryDrafter.id &&
      file.type === FileType.JOINT_TRUST_DOCUMENT
  );
  const primaryDrafterSeparateTrust = trustPlanFiles.find(
    (file: File) =>
      file.ownerId === primaryDrafter.id &&
      file.type === FileType.SEPARATE_TRUST_DOCUMENT
  );
  const partnerSeparateTrust = partner?.id
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.SEPARATE_TRUST_DOCUMENT
      )
    : null;
  const primaryDrafterPourOverWill = trustPlanFiles.find(
    (file: File) =>
      file.ownerId === primaryDrafter.id &&
      file.type === FileType.POUR_OVER_WILL_DOCUMENT
  );
  const partnerPourOverWill = partner?.id
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.POUR_OVER_WILL_DOCUMENT
      )
    : null;
  const primaryDrafterCertificateOfTrust = trustPlanFiles.find(
    (file: File) =>
      file.ownerId === primaryDrafter.id &&
      file.type === FileType.TRUST_CERTIFICATE_DOCUMENT
  );
  const partnerCertificateOfTrust = partner?.id
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.TRUST_CERTIFICATE_DOCUMENT
      )
    : null;

  const trustSlides = useMemo(
    () =>
      trust
        ? [
            primaryDrafterJointTrust ? (
              <JointTrustSlide />
            ) : undefined,
            primaryDrafterSeparateTrust ? <TrustSlide /> : undefined,
            primaryDrafterPourOverWill ? (
              <PourOverWillSlide />
            ) : undefined,
            primaryDrafterCertificateOfTrust ? (
              <CertificateOfTrustSlide />
            ) : undefined,
            partnerSeparateTrust ? <PartnerTrustSlide /> : undefined,
            partnerPourOverWill ? (
              <PartnerPourOverWillSlide />
            ) : undefined,
            partnerCertificateOfTrust ? (
              <PartnerCertificateOfTrustSlide />
            ) : undefined
          ].filter(Boolean)
        : [],
    [
      trust,
      primaryDrafterJointTrust,
      primaryDrafterSeparateTrust,
      partnerSeparateTrust,
      primaryDrafterPourOverWill,
      partnerPourOverWill,
      primaryDrafterCertificateOfTrust,
      partnerCertificateOfTrust
    ]
  );

  const primaryDrafterLastWillPlan = lastWillPlanFiles.find(
    (file: File) =>
      file.ownerId === primaryDrafter.id &&
      file.type === FileType.LAST_WILL_DOCUMENT
  );
  const partnerLastWillPlan = lastWillPlanFiles.find(
    (file: File) =>
      file.ownerId === partner?.id &&
      file.type === FileType.LAST_WILL_DOCUMENT
  );

  const lastWillSlides = useMemo(
    () =>
      lastWill
        ? [
            primaryDrafterLastWillPlan ? (
              <LastWillSlide />
            ) : undefined,
            partnerLastWillPlan ? <PartnerLastWillSlide /> : undefined
          ].filter(Boolean)
        : [],
    [lastWill, primaryDrafterLastWillPlan, partnerLastWillPlan]
  );

  const primaryDrafterHealthcarePlan = trust
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === primaryDrafter.id &&
          file.type === FileType.HEALTH_CARE_DOCUMENT
      )
    : lastWillPlanFiles.find(
        (file: File) =>
          file.ownerId === primaryDrafter.id &&
          file.type === FileType.HEALTH_CARE_DOCUMENT
      );

  const partnerHealthCarePlan = trust
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.HEALTH_CARE_DOCUMENT
      )
    : lastWillPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.HEALTH_CARE_DOCUMENT
      );

  const primaryDrafterPowerOfAttorneyPlan = trust
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === primaryDrafter.id &&
          file.type === FileType.POA_DOCUMENT
      )
    : lastWillPlanFiles.find(
        (file: File) =>
          file.ownerId === primaryDrafter.id &&
          file.type === FileType.POA_DOCUMENT
      );

  const partnerPowerOfAttorneyPlan = trust
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.POA_DOCUMENT
      )
    : lastWillPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.POA_DOCUMENT
      );

  const primaryDrafterHIPPAPlan = trust
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === primaryDrafter.id &&
          file.type === FileType.HIPAA_DOCUMENT
      )
    : lastWillPlanFiles.find(
        (file: File) =>
          file.ownerId === primaryDrafter.id &&
          file.type === FileType.HIPAA_DOCUMENT
      );

  const partnerHIPPAPlan = trust
    ? trustPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.HIPAA_DOCUMENT
      )
    : lastWillPlanFiles.find(
        (file: File) =>
          file.ownerId === partner?.id &&
          file.type === FileType.HIPAA_DOCUMENT
      );

  const healthAndPOASlides = useMemo(
    () =>
      trust || lastWill
        ? [
            <PowerOfAttorneySlide />,
            <HealthCareSlide />,
            primaryDrafterHIPPAPlan ? <HIPPASlide /> : undefined,
            (planOption === PlanOption.COUPLE ||
              planOption === PlanOption.JOINT) &&
            (planOption === PlanOption.COUPLE ||
              planOption === PlanOption.JOINT) ? (
              <PartnerPowerOfAttorneySlide />
            ) : undefined,
            planOption === PlanOption.COUPLE ||
            planOption === PlanOption.JOINT ? (
              <PartnerHealthCareSlide />
            ) : undefined,

            (planOption === PlanOption.COUPLE ||
              planOption === PlanOption.JOINT) &&
            partnerHIPPAPlan ? (
              <PartnerHIPPASlide />
            ) : undefined
          ].filter(Boolean)
        : [],
    [
      trust,
      lastWill,
      planOption,
      partnerHIPPAPlan,
      partnerHealthCarePlan,
      partnerPowerOfAttorneyPlan,
      primaryDrafterHIPPAPlan,
      primaryDrafterHealthcarePlan,
      primaryDrafterPowerOfAttorneyPlan
    ]
  );

  const petProtectionSlides = useMemo(
    () =>
      petProtection
        ? [
            <PetProtectionSlide />,
            petProtection.petProtection
              .grantGuardianAccessToHealthInformation ? (
              <PetHIPPASlide />
            ) : (
              []
            ),
            petProtection.petProtection
              .allowGuardianIntoTransactions ? (
              <PetPOASlide />
            ) : (
              []
            )
          ].filter(Boolean)
        : [],
    [petProtection]
  );

  const slides = useMemo(() => {
    let mySlides: any[] = [];

    if (trust && trustSlides.length > 0) {
      mySlides = [...trustSlides];
    }
    if (!trust && lastWillSlides.length > 0) {
      mySlides = [...lastWillSlides];
    }

    mySlides.push(...healthAndPOASlides);
    mySlides.push(...petProtectionSlides);
    mySlides.push(
      ...customDocuments.map((file) => (
        <GenericSlide
          key={file?.path}
          planFile={file}
          name={
            file.name ||
            formatMessage({ defaultMessage: "Custom document" })
          }
          customDocument
          description={formatMessage({
            defaultMessage:
              "This is a custom document uploaded by you."
          })}
        />
      ))
    );

    return mySlides.filter((slide) => !!slide);
  }, [
    customDocuments,
    formatMessage,
    trust,
    trustSlides,
    lastWillSlides,
    healthAndPOASlides,
    petProtectionSlides
  ]);

  return slides;
};

export default useDocSlides;
