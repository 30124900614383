/* eslint-disable react/no-unescaped-entities */
import { Container, Typography, Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import MemberSuccessHeadshot from "@assets/images/kymeeHeadshot.png";
import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import { EXPERT_MATCHING_ROUTES } from "@pages/EstateAdminQuiz/consts";

import { EXPERT_MATCHING_QUIZ_ROUTES } from "../consts";

const WhatToExpectStep: React.FC = () => {
  const intl = useIntl();

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: { xs: 2.5, md: 6.25 }
      }}
    >
      <StepLayout
        title="Hi there! I'm Kymee."
        imageProps={{
          src: MemberSuccessHeadshot,
          alt: "Handdrawn headshot of Kymee from JIC Estates Member Success team"
        }}
        isWide
      />
      <Typography>
        <FormattedMessage
          defaultMessage={
            "We'll work together to discover what you can expect with administration of this estate."
          }
        />
        <br /> <br />
        <FormattedMessage defaultMessage="Then, you can decide to connect with one of our expert attorney or financial advisor partners to help you sail through estate administration." />
        <br /> <br />
        <strong>It takes less than 3 minutes, </strong> and we won't
        share any information with any of the partners unless you
        decide to match with them.
      </Typography>
      <FormStepFooter
        nextButtonProps={{
          href: `${EXPERT_MATCHING_QUIZ_ROUTES.ExpertType}`,
          label: intl.formatMessage({ defaultMessage: "Continue" })
        }}
      />
    </Container>
  );
};

export default WhatToExpectStep;
