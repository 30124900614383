import { PlanDocument } from "@api/__generated__/globalTypes";

export const PET_PROTECTION_DOCUMENTS = [
  PlanDocument.PET_PROTECTION_DOCUMENT
];
export const WILL_DOCUMENTS = [PlanDocument.LAST_WILL_DOCUMENT];
export const HEALTHCARE_DOCUMENTS = [
  PlanDocument.HEALTH_CARE_DOCUMENT,
  PlanDocument.HIPAA_DOCUMENT
];
export const POA_DOCUMENTS = [PlanDocument.POA_DOCUMENT];

export const TRUST_DOCUMENTS = [
  PlanDocument.JOINT_TRUST_DOCUMENT,
  PlanDocument.SEPARATE_TRUST_DOCUMENT,
  PlanDocument.POUR_OVER_WILL_DOCUMENT,
  PlanDocument.TRUST_CERTIFICATE_DOCUMENT
];
