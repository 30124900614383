import { capitalize, startCase } from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import getSkippableAnswer from "@utils/getSkippableAnswer";
import {
  ARE_ORGANS_DONATED_KEYS,
  LAST_WILL_HEALTHCARE_ROUTES
} from "@pages/LastWill/sections/Healthcare/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { OrganDonationPurpose } from "@api/__generated__/globalTypes";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { ROUTES } from "@setup/consts/routes";
import { HEALTHCARE_ROUTES } from "@pages/Healthcare/consts";
import { HEALTHCARE_MEDICAL_POA_ROUTES } from "@pages/Healthcare/sections/medicalPOA/consts";
import { HEALTHCARE_LIVINGWILL_ROUTES } from "@pages/Healthcare/sections/livingWill/consts";

import { getLinkFullUrl } from "../utils";

export const usePartnerHealthcareReview = () => {
  const intl = useIntl();

  const { trust, lastWill } = useMemberPlansState();

  const canEdit = trust ? trust.canEdit : lastWill?.canEdit;

  const partnerLastWill = trust
    ? trust.partnerLastWill
    : lastWill?.partnerLastWill;

  const {
    specifiedPreferences,
    generalGuidance,
    continueToProvideTreatment,
    artificialNutrition,
    reliefFromPain,
    reliefFromPainCondition,
    organDonation,
    organDonationPurposes,
    specifiedOrgans,
    authorizedPerson,
    additionalNote
  } = partnerLastWill?.healthcare || {};

  const primaryAgents = authorizedPerson?.filter(
    (person) => !person?.isBackup && person?.isAgent
  );
  const backupAgents = authorizedPerson?.filter(
    (person) => person?.isBackup && person?.isAgent
  );
  const medicalAccessPeople = authorizedPerson?.filter(
    (person) => !person?.isAgent
  );

  // Check for Any Healthcare Info Entered
  const makeHealthcare = useMemo<ReviewItem | undefined>(
    () =>
      partnerLastWill?.healthcare
        ? undefined
        : {
            title: intl.formatMessage({
              defaultMessage: "Advance Health Care Directive:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.MedicalPOA,
                  HEALTHCARE_MEDICAL_POA_ROUTES.PartnerCreateAHCD
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateAHCD
                ]),
            items: [
              [
                intl.formatMessage({
                  defaultMessage: `🚫 You chose not to create an Advance Healthcare Directive at this time.`
                })
              ]
            ]
          },
    [intl, trust, canEdit, partnerLastWill?.healthcare]
  );

  // Medical POA ROLLUP STARTS HERE
  const primaryAgentInfo = useMemo<ReviewItem | undefined>(
    () =>
      primaryAgents?.length
        ? {
            title: intl.formatMessage({
              defaultMessage: "Primary Healthcare Agent:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.MedicalPOA,
                  HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateAgent
                ]),
            items: [
              [
                `${primaryAgents
                  ?.map(
                    (agent) =>
                      agent?.agent?.fullName ??
                      agent?.agent?.firstName
                  )
                  .join(", ")}`
              ]
            ]
          }
        : undefined,
    [intl, canEdit, trust, primaryAgents]
  );

  const backupAgentInfo = useMemo<ReviewItem | undefined>(
    () =>
      backupAgents?.length
        ? {
            title: intl.formatMessage({
              defaultMessage: "Backup Healthcare Agent:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.MedicalPOA,
                  HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateBackupAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerDesignateBackupAgent
                ]),
            items: [
              [
                `${backupAgents
                  ?.map(
                    (agent) =>
                      agent?.agent?.fullName ??
                      agent?.agent?.firstName
                  )
                  .join(", ")}`
              ]
            ]
          }
        : {
            title: intl.formatMessage({
              defaultMessage: "Backup Healthcare Agent:"
            }),
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.MedicalPOA,
                  HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateBackupAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateBackupAgent
                ]),
            items: [
              ["You chose not to nominate a backup healthcare agent."]
            ]
          },
    [intl, canEdit, trust, backupAgents]
  );

  const medicalPOANestedInfo = useMemo<ReviewItem | undefined>(
    () =>
      primaryAgents?.length
        ? {
            title: intl.formatMessage({
              defaultMessage: "Medical Power of Attorney"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.MedicalPOA,
                  HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateAgent
                ]),
            items: [primaryAgentInfo, backupAgentInfo].filter(
              (item): item is ReviewItem => Boolean(item)
            )
          }
        : {
            title: intl.formatMessage({
              defaultMessage: "Medical Power of Attorney"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.MedicalPOA,
                  HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerNominateAgent
                ]),
            items: [
              [
                "🚫 You chose not to create a medical power of attorney at this time."
              ]
            ]
          },
    [
      primaryAgentInfo,
      trust,
      backupAgentInfo,
      canEdit,
      intl,
      primaryAgents
    ]
  );

  // GRANT MEDICAL ACCESS ROLLUP STARTS HERE

  const medicalAccessInfo = useMemo<ReviewItem | undefined>(() => {
    if (!medicalAccessPeople && primaryAgents) {
      return {
        title: intl.formatMessage({
          defaultMessage: "HIPAA Authorized Persons:"
        }),
        canEdit,
        href: trust
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.MedicalPOA,
              HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess
            ]),
        items: [
          [
            intl.formatMessage({
              defaultMessage: `Only your healthcare agents will receive access to your healthcare information.`
            })
          ]
        ]
      };
    }

    if (medicalAccessPeople) {
      return {
        title: intl.formatMessage({
          defaultMessage: "HIPAA Authorized Persons:"
        }),
        canEdit,
        href: trust
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.MedicalPOA,
              HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess
            ]),
        items: [
          medicalAccessPeople
            .map((person) => person?.agent?.fullName)
            .filter((item): item is string => Boolean(item))
        ]
      };
    }

    return {
      title: intl.formatMessage({
        defaultMessage: "HIPAA Authorized Persons:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Healthcare,
            HEALTHCARE_ROUTES.MedicalPOA,
            HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Healthcare,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerGrantMedicalAccess
          ]),
      items: [
        [
          intl.formatMessage({
            defaultMessage: `🚫 You are not providing anyone access to your health information.`
          })
        ]
      ]
    };
  }, [medicalAccessPeople, trust, primaryAgents, canEdit, intl]);

  // LIVING WILL ROLLUP STARTS HERE

  const generalGuidanceInfo = useMemo<ReviewItem>(
    () => ({
      title: intl.formatMessage({
        defaultMessage: "General Guidance:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Healthcare,
            HEALTHCARE_ROUTES.LivingWill,
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerGeneralGuidance
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Healthcare,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerGeneralGuidance
          ]),
      items: [
        [`- ${capitalize(getSkippableAnswer(generalGuidance, intl))}`]
      ]
    }),
    [generalGuidance, trust, canEdit, intl]
  );

  const choiceToProlongLife = useMemo<ReviewItem>(
    () => ({
      title: intl.formatMessage({
        defaultMessage: "Choice to Prolong Life:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Healthcare,
            HEALTHCARE_ROUTES.LivingWill,
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerProlongLife
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Healthcare,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerProlongLife
          ]),
      items: [
        [`- ${getSkippableAnswer(continueToProvideTreatment, intl)}`]
      ]
    }),
    [intl, canEdit, trust, continueToProvideTreatment]
  );

  const artificialNutritionAndHydration = useMemo<ReviewItem>(
    () => ({
      title: intl.formatMessage({
        defaultMessage: "Artificial Nutrition & Hydration:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Healthcare,
            HEALTHCARE_ROUTES.LivingWill,
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerArtificialNutrition
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Healthcare,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerArtificialNutrition
          ]),
      items: [[`- ${getSkippableAnswer(artificialNutrition, intl)}`]]
    }),
    [artificialNutrition, trust, canEdit, intl]
  );

  const receiveMaximumPainReliefInAllCases = useMemo<ReviewItem>(
    () => ({
      title: intl.formatMessage({
        defaultMessage: "Receive Maximum Pain Relief in All Cases:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Healthcare,
            HEALTHCARE_ROUTES.LivingWill,
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainRelief
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Healthcare,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerPainRelief
          ]),
      items: [[`- ${getSkippableAnswer(reliefFromPain, intl)}`]]
    }),
    [intl, canEdit, trust, reliefFromPain]
  );

  const exceptionToReceivingMaximumPainRelief = useMemo<
    ReviewItem | undefined
  >(
    () =>
      reliefFromPainCondition
        ? {
            title: "Exception to Receiving Maximum Pain Relief:",
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainReliefException
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerPainReliefException
                ]),
            items: [
              [
                `- ${getSkippableAnswer(
                  reliefFromPainCondition,
                  intl
                )}`
              ]
            ]
          }
        : undefined,
    [intl, trust, reliefFromPainCondition]
  );

  const areOrgansDonated = ARE_ORGANS_DONATED_KEYS.includes(
    organDonation || ""
  );

  const organDonationInfo = useMemo<ReviewItem>(
    () => ({
      title: intl.formatMessage({
        defaultMessage: "Organ Donation:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Healthcare,
            HEALTHCARE_ROUTES.LivingWill,
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonation
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Healthcare,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonation
          ]),
      items: [[`- ${getSkippableAnswer(organDonation, intl)}`]]
    }),
    [intl, trust, canEdit, organDonation]
  );

  const organDonationPurposesItems = useMemo(() => {
    if (!areOrgansDonated) {
      return [];
    }

    return [
      ...Array.from(
        new Set(
          Object.values(organDonationPurposes || []).map((purpose) =>
            startCase(purpose?.toString().toLowerCase())
          )
        )
      )
    ];
  }, [areOrgansDonated, organDonationPurposes]);

  const organDonationPurposesInfo = useMemo<ReviewItem | undefined>(
    () =>
      organDonationPurposesItems?.length
        ? {
            title: intl.formatMessage({
              defaultMessage: "Organ Donation Purposes:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonationPurpose
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonationPurpose
                ]),
            items: [
              [
                ...organDonationPurposesItems.filter(
                  (item): item is OrganDonationPurpose =>
                    Boolean(item)
                )
              ]
            ]
          }
        : undefined,
    [intl, canEdit, trust, organDonationPurposesItems]
  );

  const specificOrgansToDonate = useMemo(() => {
    if (!areOrgansDonated) {
      return [];
    }

    return [...(specifiedOrgans || []).map((organs) => organs?.name)];
  }, [areOrgansDonated, specifiedOrgans]);

  const specificOrgansToDonateInfo = useMemo<ReviewItem | undefined>(
    () =>
      specificOrgansToDonate?.length
        ? {
            title: intl.formatMessage({
              defaultMessage: "Specific Organs to Donate:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonationList
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerOrganDonationList
                ]),
            items: [
              specificOrgansToDonate?.filter((item): item is string =>
                Boolean(item)
              ) ?? []
            ]
          }
        : undefined,
    [intl, canEdit, trust, specificOrgansToDonate]
  );

  const additionalNoteInfo = useMemo<ReviewItem>(
    () => ({
      title: "Additional Note:",
      href: trust
        ? getLinkFullUrl([
            ROUTES.Healthcare,
            HEALTHCARE_ROUTES.LivingWill,
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerSpecialNote
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Healthcare,
            LAST_WILL_HEALTHCARE_ROUTES.PartnerSpecialNote
          ]),
      items: [
        [
          `${
            additionalNote
              ? getSkippableAnswer(additionalNote, intl)
              : "- None provided"
          }`
        ]
      ]
    }),
    [intl, additionalNote, trust]
  );

  const livingWillNestedInfo = useMemo<ReviewItem | undefined>(
    () =>
      specifiedPreferences
        ? {
            title: intl.formatMessage({
              defaultMessage: "Living Will"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.PartnerCreateLivingWill
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateLivingWill
                ]),
            items: [
              generalGuidanceInfo,
              choiceToProlongLife,
              artificialNutritionAndHydration,
              receiveMaximumPainReliefInAllCases,
              exceptionToReceivingMaximumPainRelief,
              organDonationInfo,
              specificOrgansToDonateInfo,
              organDonationPurposesInfo,
              additionalNoteInfo
            ].filter((item): item is ReviewItem => Boolean(item))
          }
        : {
            title: intl.formatMessage({
              defaultMessage: "Living Will"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.PartnerCreateLivingWill
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Healthcare,
                  LAST_WILL_HEALTHCARE_ROUTES.PartnerCreateLivingWill
                ]),
            items: [
              [
                intl.formatMessage({
                  defaultMessage:
                    "You chose not to create a living will at this time."
                })
              ]
            ]
          },
    [
      canEdit,
      specifiedPreferences,
      intl,
      generalGuidanceInfo,
      choiceToProlongLife,
      trust,
      artificialNutritionAndHydration,
      receiveMaximumPainReliefInAllCases,
      exceptionToReceivingMaximumPainRelief,
      specificOrgansToDonateInfo,
      organDonationInfo,
      organDonationPurposesInfo,
      additionalNoteInfo
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      (!makeHealthcare
        ? [
            medicalPOANestedInfo,
            medicalAccessInfo,
            livingWillNestedInfo
          ]
        : [makeHealthcare]
      ).filter((item): item is ReviewItem => Boolean(item)),
    [
      makeHealthcare,
      medicalPOANestedInfo,
      medicalAccessInfo,
      livingWillNestedInfo
    ]
  );

  return items;
};
