import { FormControl, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import { BeneficiaryFormProps } from "@pages/LastWill/types";
import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";

const BeneficiaryForm: React.FC<BeneficiaryFormProps> = ({
  prefix,
  index
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <Typography variant="body1" color="grey.700" />
      </FormControl>
      <BasicFormInput
        label={formatMessage(
          {
            defaultMessage: "Beneficiary #{number} Full name"
          },
          { number: index + 1 }
        )}
        name={`${prefix}.name`}
      />
      <BasicFormInput
        name={`${prefix}.percentageValue`}
        label={formatMessage({
          defaultMessage: "% Ownership You Are Gifting"
        })}
        valuePrefix="%"
      />
    </>
  );
};

export default BeneficiaryForm;
