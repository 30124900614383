/* eslint-disable react/no-unescaped-entities */
import { useIntl } from "react-intl";
import { useMemo } from "react";
import {
  FormControl,
  FormLabel,
  Stack,
  Typography
} from "@mui/material";
import { FormProvider } from "react-hook-form";

import {
  VestingType,
  Restrictions
} from "@api/__generated__/globalTypes";
import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormRadioButtons from "@components/FormFields/FormRadioButtons";
import InfoModal from "@components/InfoModal";

import { useTrustSetupArchetype } from "./hooks";
import { TrustSetupArchetypeProps } from "./types";
import getTrustDistributionStandardsContent from "./getTrustDistributionStandardsContent";
import getAgeVestingContent from "./getAgeVestingContent";

const TrustSetupArchetype: React.FC<TrustSetupArchetypeProps> = ({
  name,
  stepLayout,
  onSubmit: onFormSubmit
}) => {
  const intl = useIntl();
  const restrictionItems = useMemo(
    () => [
      {
        label: intl.formatMessage({
          defaultMessage: "Standard"
        }),
        value: Restrictions.STANDARD_RESTRICTIONS
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Low (Free Access)"
        }),
        value: Restrictions.LIMITED_RESTRICTIONS
      }
    ],
    [intl]
  );
  const vestingTypeItems = useMemo(
    () => [
      {
        label: intl.formatMessage({
          defaultMessage: "No Vesting (Lifetime Trust)"
        }),
        value: VestingType.ON_DEATH
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Age-Based Vesting"
        }),
        value: VestingType.AGE_BASED
      }
    ],
    [intl]
  );

  const distributionContent = getTrustDistributionStandardsContent();
  const ageVestingContent = getAgeVestingContent();

  const { controls, goBack, onSubmit } = useTrustSetupArchetype(
    name,
    onFormSubmit
  );

  return (
    <StepLayout {...stepLayout}>
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>
                {intl.formatMessage({
                  defaultMessage: "Standard for Distributions "
                })}
              </FormLabel>
              <Typography component="legend" variant="body2" p={0}>
                <InfoModal
                  title="Standard Distributions vs. Free Access"
                  togglerLabel="Learn more"
                  content={distributionContent}
                />
              </Typography>
              <FormRadioButtons
                name={`${name}.restrictions`}
                defaultValue={Restrictions.STANDARD_RESTRICTIONS}
                items={restrictionItems}
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                {intl.formatMessage({
                  defaultMessage: "Type of Vesting"
                })}
              </FormLabel>
              <Typography component="legend" variant="body2" p={0}>
                <InfoModal
                  title="Understanding Your Different Vesting Options"
                  togglerLabel="Learn more"
                  content={ageVestingContent}
                />
              </Typography>
              <FormRadioButtons
                name={`${name}.vestingType`}
                defaultValue={VestingType.ON_DEATH}
                items={vestingTypeItems}
              />
            </FormControl>
          </Stack>
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default TrustSetupArchetype;
