import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  whiteSpace: "nowrap",
  alignItems: "center",
  padding: "0.5rem",
  gap: "0.5rem",
  transition: "all 300ms ease-in-out"
}));
