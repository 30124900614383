import { gql } from "@apollo/client";

import { PERSON_FRAGMENT, PLAN_DRAFT_FRAGMENT } from "./fragments";

export const GET_PLAN_DRAFT = gql`
  ${PLAN_DRAFT_FRAGMENT}
  query GetPlanDraft($type: PlanType!) {
    planDraft(type: $type) {
      ...PlanDraft
    }
  }
`;

export const FIND_PEOPLE = gql`
  ${PERSON_FRAGMENT}
  query FindPeople($token: String!) {
    findPeople(token: $token) {
      ...Person
    }
  }
`;
