import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";
import isRightsOrContest from "@pages/EstateAdminQuiz/results/utils";
import { EstateData } from "@pages/EstateAdminQuiz/types";

const LegalEndStep: React.FC = () => {
  const { value: estateData } =
    useFormField<EstateData>("estateData");
  const { focusAreas } = estateData;

  const description = isRightsOrContest(focusAreas)
    ? "Let's continue to see if our expert estate administration attorneys can help with your case!"
    : "Let's continue to see which type of estate administration is best for this estate!";

  return (
    <InterstitialArchetype
      stepLayout={{
        title:
          "Great! That covers the quick background that we need.",
        subText: description,
        muiIconHeader: <ThumbUpAltOutlinedIcon />
      }}
    />
  );
};

export default LegalEndStep;
