import { Locale } from "./types";
import { DEFAULT_LOCALE, translations } from "./consts";

const isCorrectLocale = (locale: string): locale is Locale =>
  !!Object.keys(translations).includes(locale);

const getLocale = (): Locale => {
  const [locale] = navigator.language.split(/[-_]/);

  return isCorrectLocale(locale) ? locale : DEFAULT_LOCALE;
};

export { isCorrectLocale, getLocale };
