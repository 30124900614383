import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    role
    firstName
    lastName
    fullName
    birthdate
    email
    phone
    personId
    address {
      id
      address1
      address2
      city
      state {
        id
        name
      }
      zip
      county {
        id
        name
      }
      city
    }
  }
`;
