export const deceasedSettlorShareModalProps = {
  title: "Deceased Settlor Share",
  content: (
    <p>
      When one of you passes away, your joint trust will be divided
      into two shares:
      <ol>
        <li>The Deceased Settlor Share</li>
        <li>The Surviving Settlor Share</li>
      </ol>
      The person who passes away first is known as the Deceased
      Settlor. The Deceased Settlor Share of property represents:
      <ul>
        <li>The Deceased Settlor&apos;s separate property</li>
        <li>One half of all your community property.</li>
      </ul>
      The visualization on your Distributions tab shows how the
      Deceased Settlor share will be distributed, depending on which
      of you is the Deceased Settlor.
    </p>
  )
};

export const survivorTrustModalProps = {
  title: "Survivor Trust",
  content: (
    <p>
      When one of you passes away, your joint trust will be divided
      into two shares:
      <ol>
        <li>The Deceased Settlor Share</li>
        <li>The Surviving Settlor Share</li>
      </ol>
      The person who survives is known as the Surviving Settlor. The
      Surviving Settlor Share of property is transferred to the
      Survivor Trust and represents the sum of:
      <ul>
        <li>The Surviving Settlor&apos;s separate property</li>
        <li>One half of all your community property</li>
        <li>
          Any gifts made to the Surviving Settlor by the Deceased
          Settlor following the Deceased Settlor&apos;s death
        </li>
      </ul>
      The Surviving Settlor will continue to manage and retain
      complete control of all assets in the Survivor Trust.
    </p>
  )
};
