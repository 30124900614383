import { Navigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { PlanFormSection } from "@providers/planForm/types";

import { POWER_OF_ATTORNEY_ROUTES } from "./consts";
import PowerOfAttorneyMainSection from "./steps/main";
import powerOfAttorneySteps from "./steps/main/routes";

const sections: PlanFormSection[] = [
  {
    path: POWER_OF_ATTORNEY_ROUTES.Home,
    element: <PowerOfAttorneyMainSection />,
    children: powerOfAttorneySteps,
    icon: <AccountCircleIcon />
  },
  {
    path: "*",
    element: <Navigate to={POWER_OF_ATTORNEY_ROUTES.Home} />
  }
];

export default sections;
