import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanOption } from "@api/__generated__/globalTypes";

import { LAST_WILL_GIFTS_ROUTES } from "../Gifts/consts";

import { LAST_WILL_MANAGEMENT_ROUTES } from "./consts";
import ReviewStep from "./steps/Review";
import ReviewTabsStepPage from "./steps/ReviewTabs";
import DesignateRepresentativeStep from "./steps/DesignateRepresentative";
import SectionsPreviewStep from "./steps/Preview";
import InterstitialM1Step from "./steps/RepresentativeOverview";
import DesignateBackupRepresentativeStep from "./steps/DesignateBackupRepresentative";
import CopyPersonalRepresentativesStep from "./steps/PartnerSameReps";
import NominateBackupRepresentativeStep from "./steps/NominateBackupRepresentative";
import RepCompensationStep from "./steps/RepCompensation";
import RepCompensationPercentStep from "./steps/RepCompensationPercent";
import RepCompensationDollarStep from "./steps/RepCompensationDollar";
import DesignatePartnerRepresentativeStep from "./steps/DesignatePartnerRepresentative";
import NominatePartnerBackupRepresentativeStep from "./steps/NominatePartnerBackupRepresentative";
import DesignatePartnerBackupRepresentativeStep from "./steps/DesignatePartnerBackupRepresentative";
import PartnerRepCompensationStep from "./steps/PartnerRepCompensation";
import PartnerRepCompensationDollarStep from "./steps/PartnerRepCompensationDollar";
import PartnerRepCompensationPercentStep from "./steps/PartnerRepCompensationPercent";
import CopyPersonalRepresentativesCompStep from "./steps/PartnerSameRepComp";

const managementSteps: PlanFormStep[] = [
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.RepresentativeOverview
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.RepresentativeOverview,
    element: <InterstitialM1Step />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.DesignateRepresentative
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.DesignateRepresentative,
    element: <DesignateRepresentativeStep />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.NominateBackupRepresentative
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.NominateBackupRepresentative,
    element: <NominateBackupRepresentativeStep />,
    rules: {
      if: [
        {
          "==": [{ var: "nominateBackupRep" }, true]
        },
        LAST_WILL_MANAGEMENT_ROUTES.DesignateBackupRepresentative,
        LAST_WILL_MANAGEMENT_ROUTES.RepCompensation
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.DesignateBackupRepresentative,
    element: <DesignateBackupRepresentativeStep />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.RepCompensation
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.RepCompensation,
    element: <RepCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `users.0.lastWill.representativesCompensationType`
            },
            "A specific percent of my estate and reimbursement of expenses"
          ]
        },
        LAST_WILL_MANAGEMENT_ROUTES.RepCompensationPercent,
        {
          if: [
            {
              "==": [
                {
                  var: `users.0.lastWill.representativesCompensationType`
                },
                "A specific dollar amount and reimbursement of expenses"
              ]
            },
            LAST_WILL_MANAGEMENT_ROUTES.RepCompensationDollar,
            {
              if: [
                { "==": [{ var: `planOption` }, PlanOption.COUPLE] },
                LAST_WILL_MANAGEMENT_ROUTES.PartnerSameReps,
                LAST_WILL_MANAGEMENT_ROUTES.Review
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.RepCompensationPercent,
    element: <RepCompensationPercentStep />,
    rules: {
      if: [
        { "==": [{ var: `planOption` }, PlanOption.COUPLE] },
        LAST_WILL_MANAGEMENT_ROUTES.PartnerSameReps,
        LAST_WILL_MANAGEMENT_ROUTES.Review
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.RepCompensationDollar,
    element: <RepCompensationDollarStep />,
    rules: {
      if: [
        { "==": [{ var: `planOption` }, PlanOption.COUPLE] },
        LAST_WILL_MANAGEMENT_ROUTES.PartnerSameReps,
        LAST_WILL_MANAGEMENT_ROUTES.Review
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.PartnerSameReps,
    element: <CopyPersonalRepresentativesStep />,
    rules: {
      if: [
        { "==": [{ var: "copyPersonalRepresentatives" }, true] },
        LAST_WILL_MANAGEMENT_ROUTES.PartnerSameRepComp,
        LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerRepresentative
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.PartnerSameRepComp,
    element: <CopyPersonalRepresentativesCompStep />,
    rules: {
      if: [
        { "==": [{ var: "copyPersonalRepresentativesComp" }, true] },
        LAST_WILL_MANAGEMENT_ROUTES.ReviewTabs,
        LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensation
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerRepresentative,
    element: <DesignatePartnerRepresentativeStep />,
    rules:
      LAST_WILL_MANAGEMENT_ROUTES.NominatePartnerBackupRepresentative
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.NominatePartnerBackupRepresentative,
    element: <NominatePartnerBackupRepresentativeStep />,
    rules: {
      if: [
        { "==": [{ var: "nominatePartnerBackupRep" }, true] },
        LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerBackupRepresentative,
        LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensation
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerBackupRepresentative,
    element: <DesignatePartnerBackupRepresentativeStep />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensation
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerBackupRepresentative,
    element: <DesignatePartnerBackupRepresentativeStep />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensation
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensation,
    element: <PartnerRepCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `users.1.lastWill.representativesCompensationType`
            },
            "A specific percent of my estate and reimbursement of expenses"
          ]
        },
        LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensationPercent,
        {
          if: [
            {
              "==": [
                {
                  var: `users.1.lastWill.representativesCompensationType`
                },
                "A specific dollar amount and reimbursement of expenses"
              ]
            },
            LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensationDollar,
            LAST_WILL_MANAGEMENT_ROUTES.ReviewTabs
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensationPercent,
    element: <PartnerRepCompensationPercentStep />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.ReviewTabs
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensationDollar,
    element: <PartnerRepCompensationDollarStep />,
    rules: LAST_WILL_MANAGEMENT_ROUTES.ReviewTabs
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.Gifts}/${LAST_WILL_GIFTS_ROUTES.Preview}`
  },
  {
    path: LAST_WILL_MANAGEMENT_ROUTES.ReviewTabs,
    element: <ReviewTabsStepPage />,
    rules: `${LAST_WILL_SECTION_ROUTES.Gifts}/${LAST_WILL_GIFTS_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={LAST_WILL_MANAGEMENT_ROUTES.Preview} />
  }
];

export default managementSteps;
