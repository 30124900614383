import { FormControl } from "@mui/material";
import { memo } from "react";

import FormAutocomplete from "@components/FormFields/FormAutocomplete";
import usePersonAutocomplete from "@hooks/usePersonAutocomplete";
import usePersonId from "@hooks/usePersonId";

import FormInput from "../FormInput";

import { PersonAutocompleteFieldProps } from "./types";

const PersonAutocompleteField: React.FC<
  PersonAutocompleteFieldProps
> = ({ name, personNameKeyPath, personRole }) => {
  const { randomizePersonId } = usePersonId(name);
  const { autocompleteOptions, onSelect } =
    usePersonAutocomplete(name);

  return (
    <>
      <FormInput
        name={`${name}.id`}
        type="hidden"
        style={{ visibility: "hidden" }}
      />
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <FormAutocomplete
          textFieldProps={{
            label: personRole
              ? `${personRole}'s Full Name`
              : "Full Name"
          }}
          name={`${name}.${personNameKeyPath}`}
          options={autocompleteOptions}
          freeSolo
          onChange={(_, value) => {
            if (value && typeof value !== "string") {
              onSelect(value.value);
            } else {
              randomizePersonId();
            }
          }}
        />
      </FormControl>
    </>
  );
};

export default memo(PersonAutocompleteField);
