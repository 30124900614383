import { Grid, Box, Card, Typography } from "@mui/material";
import React from "react";

import { BeneCardData, BeneCardProps } from "./types";

const AssetCardLayout: React.FC<BeneCardProps> = ({ benes = [] }) => (
  <Grid container spacing={2}>
    {benes.map((beneficiary: BeneCardData, index: number) => {
      const { beneName, gifts } = beneficiary;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item xs={12} xl={6} key={`${beneName}_${index}`}>
          <Card
            sx={{
              py: "1.25rem",
              px: "1rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.625rem"
              }}
            >
              <Typography>Beneficiary:</Typography>
              <Typography>{beneName}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Typography style={{ textDecoration: "underline" }}>
                Gifts:
              </Typography>
              {gifts.map((gift) => {
                const {
                  assetIcon,
                  benePercentageValue,
                  assetName,
                  beneGiftValue
                } = gift;

                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.625rem"
                    }}
                  >
                    {benePercentageValue && (
                      <>
                        <Typography>{assetIcon}</Typography>
                        <Typography>
                          {benePercentageValue}% of {assetName}; est.
                          value {beneGiftValue}
                        </Typography>
                      </>
                    )}
                    {!benePercentageValue && (
                      <>
                        <Typography>{assetIcon}</Typography>
                        <Typography>
                          {assetName}; est. value {beneGiftValue}
                        </Typography>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Card>
        </Grid>
      );
    })}
  </Grid>
);

export default AssetCardLayout;
