import { Pet } from "@pages/PetPlan/types";

const getGuardiansInfo = (
  pets: Pet[],
  sameGuardian: boolean | undefined
) => {
  if (sameGuardian) {
    return [
      [
        pets[0].guardians
          ? `Guardian: ${pets[0].guardians
              .map((guardian) => [guardian.name])
              .join(", ")}`
          : "You are not nominating a Guardian",

        pets[0].backupGuardians && pets[0].backupGuardians.length > 0
          ? `Backup Guardian: ${pets[0].backupGuardians
              .map((backup) => backup.name)
              .join(", ")}`
          : "You are not nominating a backup guardian for your pets.",

        "✅ Same Guardians for all my pets"
      ]
    ].filter(Boolean);
  }

  if (pets.length === 1) {
    return pets
      .map((pet) => [
        pet.guardians && pet.guardians.length > 0
          ? `Guardian: ${pet.guardians.map(
              (guardian) => guardian.name
            )}`
          : ``,
        pet.backupGuardians && pet.backupGuardians.length > 0
          ? `Backup Guardian: ${pet.backupGuardians.map(
              (backup) => backup.name
            )}`
          : `You are not nominating a backup guardian for ${pet.name}.`
      ])
      .filter(Boolean);
  }

  return pets
    .map((pet) => [
      `${pet.name}:`,
      pet.guardians && pet.guardians.length > 0
        ? `Guardian: ${pet.guardians.map(
            (guardian) => guardian.name
          )}`
        : ``,
      pet.backupGuardians && pet.backupGuardians.length > 0
        ? `Backup Guardian: ${pet.backupGuardians.map(
            (backup) => backup.name
          )}`
        : `You are not nominating a backup guardian for ${pet.name}.`
    ])
    .filter(Boolean);
};

export default getGuardiansInfo;
