import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { LAST_WILL_FINAL_ARRANGEMENT_ROUTES } from "@pages/LastWill/sections/FinalArrangements/consts";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanType } from "@api/__generated__/globalTypes";
import { TRUST_FINAL_ARRANGEMENT_ROUTES } from "@pages/Trust/sections/FinalArrangements/consts";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";

export const usePrimaryDrafterFinalArrangementsReviewTab = ({
  values,
  type
}: {
  values: LastWillPlanValues;
  type: PlanType;
}) => {
  const { formatMessage } = useIntl();

  const {
    users: [primaryDrafter]
  } = values;

  const {
    bodyRestingPlace = "",
    ceremonyType = [],
    specialRequest = ""
  } = primaryDrafter?.finalArrangements || {};

  const bodyRestingPlaceInfoPrimaryDrafter = useMemo<
    ReviewItem | undefined
  >(
    () =>
      bodyRestingPlace
        ? {
            title: formatMessage({
              defaultMessage: "Final Resting Place:"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.RestingPlace
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.RestingPlace
                  ]
            ),
            items: [
              [`I would like my body to be ${bodyRestingPlace}.`]
            ]
          }
        : undefined,
    [formatMessage, bodyRestingPlace, type]
  );

  const ceremonyTypeInfoPrimaryDrafter = useMemo<
    ReviewItem | undefined
  >(
    () =>
      ceremonyType.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Ceremony Type:"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.Ceremony
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Ceremony
                  ]
            ),
            items: [ceremonyType.map((kind) => kind)]
          }
        : undefined,
    [formatMessage, ceremonyType, type]
  );

  const specialRequestInfoPrimaryDrafter = useMemo<
    ReviewItem | undefined
  >(
    () =>
      specialRequest
        ? {
            title: formatMessage({
              defaultMessage: "Additional Requests:"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.OtherRequests
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.OtherRequests
                  ]
            ),
            isFullWidth: true,
            items: [[specialRequest]]
          }
        : undefined,
    [formatMessage, specialRequest, type]
  );

  const noInstructionsDisclosure = useMemo<ReviewItem | undefined>(
    () =>
      !bodyRestingPlaceInfoPrimaryDrafter &&
      !ceremonyTypeInfoPrimaryDrafter &&
      !specialRequestInfoPrimaryDrafter
        ? {
            title: formatMessage({
              defaultMessage: "No Instructions Provided"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.Preview
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Preview
                  ]
            ),
            isFullWidth: true,
            items: [
              [
                "👀 You are not providing instructions for your Final Arrangements to your Personal Representative."
              ],
              [
                "This means that your Personal Representative will make these decisions on your behalf based on your Personal Representative's understanding of your values and preferences."
              ],
              [
                "If this is not your intent, please provide instructions."
              ]
            ]
          }
        : undefined,
    [
      formatMessage,
      bodyRestingPlaceInfoPrimaryDrafter,
      ceremonyTypeInfoPrimaryDrafter,
      specialRequestInfoPrimaryDrafter,
      type
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        bodyRestingPlaceInfoPrimaryDrafter,
        ceremonyTypeInfoPrimaryDrafter,
        specialRequestInfoPrimaryDrafter,
        noInstructionsDisclosure
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      bodyRestingPlaceInfoPrimaryDrafter,
      ceremonyTypeInfoPrimaryDrafter,
      specialRequestInfoPrimaryDrafter,
      noInstructionsDisclosure
    ]
  );

  return items;
};
