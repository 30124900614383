import { useEffect, useMemo } from "react";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { useFormField } from "@components/FormFields/hooks";
import { useDisableForm } from "@providers/disableForm/context";
import { DisableFormItems } from "@providers/disableForm/type";

const AllowFinanceAmountStep: React.FC = () => {
  const { value } = useFormField<number>(
    "guardianTransactionsMaxAmount"
  );

  const error = useMemo(
    () => +value <= 0 || Number.isInteger(value) === false,
    [value]
  );

  const { setDisableItems: setExceededItem } = useDisableForm();

  useEffect(() => {
    setExceededItem((prev: DisableFormItems) => ({
      ...prev,
      asideFundsAmount: error
    }));
  }, [error, setExceededItem]);

  return (
    <SingleResponseArchetype
      label="Max Emergency Authorization $"
      fieldProps={{
        valuePrefix: "$",
        error,
        type: "number",
        helperText:
          value &&
          error &&
          "Amount authorized must be a whole number greater than $0"
      }}
      stepLayout={{
        title: `Ok. Up to what amount should your Pet Guardian have authorization?`,
        subText: `A 1-2 vet hospitalization for a dog or cat generally costs $600-1500, while emergency surgery can cost $3,000+.`
      }}
      // {...{ onSubmit }}
      name="guardianTransactionsMaxAmount"
    />
  );
};

export default AllowFinanceAmountStep;
