import { RulesLogic } from "json-logic-js";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";

export const isAnyObject = (
  value: unknown
): value is Record<string, unknown> =>
  !!value && typeof value === "object" && !Array.isArray(value);

export const isUnknownArray = (value: unknown): value is unknown[] =>
  !!value && Array.isArray(value);

export const traverse = <T extends Record<string, unknown>>(
  obj: T,
  callback: (
    obj: unknown,
    key: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    value: string | number | boolean | null | undefined | Function
  ) => void
): void => {
  // eslint-disable-next-line no-restricted-syntax
  for (const k of Object.keys(obj)) {
    const childObj = obj[k];

    if (isAnyObject(childObj)) {
      traverse(childObj, callback);
    } else if (isUnknownArray(childObj)) {
      childObj.forEach((childObjItem) => {
        if (!isAnyObject(childObjItem)) {
          return;
        }

        traverse(childObjItem, callback);
      });
    } else {
      callback(
        obj,
        k,
        childObj as
          | string
          | number
          | boolean
          | null
          | undefined
          // eslint-disable-next-line @typescript-eslint/ban-types
          | Function
      );
    }
  }
};

export const replaceJsonLogicStepKeysWithFieldKeys = (
  jsonLogic: RulesLogic,
  replaceFn: (key: string) => string
): RulesLogic => {
  if (typeof jsonLogic !== "object") {
    return jsonLogic;
  }

  const jsonLogicClone = cloneDeep(jsonLogic);

  traverse(jsonLogicClone, (parentObject) => {
    if (!parentObject || typeof parentObject !== "object") {
      return;
    }

    const stepKey = get(parentObject, "var");

    if (typeof stepKey !== "string") {
      return;
    }

    set(parentObject, "var", replaceFn(stepKey));
  });

  return jsonLogicClone;
};
