import { useCallback } from "react";
import { lowerCase, get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { PartnerType } from "@api/__generated__/globalTypes";

const PartnerPowersMakeGiftsPeopleStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const { setValue: setGiftPeopleListValue } = useFormField(
    "users.1.poa.giftRecipients.list"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const giftRecipientsType = get(
        data,
        "users.1.poa.giftRecipients.type"
      );

      if (giftRecipientsType !== "certainPeople") {
        setGiftPeopleListValue([]);
      }
    },
    [setGiftPeopleListValue]
  );

  const lowerCasePartnerType = lowerCase(partnerType);

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Who does ${partnerFirstName} want to include as a permissible recipient of gifts?`
      }}
      name="users.1.poa.giftRecipients.type"
      items={[
        {
          value: "partnerAndChildrenOnly",
          label: `My ${lowerCasePartnerType}, children, and descendants ONLY`
        },
        {
          value: "certainPeople",
          label: "Certain individuals or charities ONLY"
        },
        { value: "Anyone", label: "Anyone" }
      ]}
      {...{ onSubmit }}
    />
  );
};

export default PartnerPowersMakeGiftsPeopleStep;
