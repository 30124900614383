import { useMemo } from "react";

import { AssetType } from "@api/__generated__/globalTypes";

import BusinessInterestForm from "../components/GiftForms/BusinessInterestForm";
import CashForm from "../components/GiftForms/CashForm";
import FinancialAccountForm from "../components/GiftForms/FinancialAccountForm";
import OtherAssetForm from "../components/GiftForms/OtherAssetForm";
import PersonalPropertyForm from "../components/GiftForms/PersonalPropertyForm";
import RealEstateForm from "../components/GiftForms/RealEstateForm";
import LifeInsurancePolicy from "../components/GiftForms/LifeInsuranceForm";

export const useAssetForms = (showIsFunded?: boolean, isQuestionnaire?: boolean) =>
  useMemo(
    () =>
      ({
        [AssetType.BUSINESS_INTEREST]: (
          <BusinessInterestForm showIsFunded={showIsFunded} />
        ),
        [AssetType.CASH]: <CashForm />,
        [AssetType.FINANCIAL_ACCOUNT]: (
          <FinancialAccountForm showIsFunded={showIsFunded} />
        ),
        [AssetType.OTHER]: (
          <OtherAssetForm showIsFunded={showIsFunded} />
        ),
        [AssetType.PERSONAL_PROPERTY]: (
          <PersonalPropertyForm showIsFunded={showIsFunded} />
        ),
        [AssetType.REAL_ESTATE]: (
          <RealEstateForm showIsFunded={showIsFunded} />
        ),
        [AssetType.LIFE_INSURANCE_POLICY]: (
          <LifeInsurancePolicy showIsFunded={showIsFunded} isQuestionnaire={isQuestionnaire}/>
        )
      } as Partial<Record<AssetType, React.ReactNode>>),
    [showIsFunded, isQuestionnaire]
  );
