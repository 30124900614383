import { Address_state as State } from "@api/plans/__generated__/Address";

const isCommunityPropertyState = (state: State): boolean => {
  const communityPropertyStates = [
    "Arizona",
    "California",
    "Idaho",
    "Louisiana",
    "Nevada",
    "New Mexico",
    "Texas",
    "Washington",
    "Wisconsin"
  ];

  const name = `${state}`;

  return communityPropertyStates.includes(name);
};

export default isCommunityPropertyState;
