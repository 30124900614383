import { useMemo } from "react";
import { useIntl } from "react-intl";

import { HierarchyGiftItem } from "@components/HierarchyGifts/types";
import { LastWill_assets as Asset } from "@api/plans/__generated__/LastWill";

import useSpecificGiftModalProps from "./useSpecificGiftModalProps";

const useLastWillDistributionHierarchy = (
  specificGifts: (Asset | null)[] | undefined
): HierarchyGiftItem[] => {
  const { formatMessage } = useIntl();

  const specificGiftModalProps =
    useSpecificGiftModalProps(specificGifts);

  return useMemo(
    () => [
      {
        name: formatMessage({
          defaultMessage: "Non-Probate Assets*"
        })
      },
      {
        name: formatMessage({
          defaultMessage: "Probate Assets"
        }),
        subItems: [
          {
            name: formatMessage({
              defaultMessage: "Last Will"
            }),
            subItems: [
              {
                name: formatMessage({
                  defaultMessage: "Specific Gifts"
                }),
                moreInfoModalProps: specificGiftModalProps,
                giftNote: specificGiftModalProps
                  ? undefined
                  : "You are not making any specific gifts"
              },
              {
                name: formatMessage({
                  defaultMessage: "Gift of Estate Balance"
                }),
                giftNote: "(see details below)"
              }
            ]
          }
        ]
      }
    ],
    [formatMessage, specificGiftModalProps]
  );
};

export default useLastWillDistributionHierarchy;
