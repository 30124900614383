import { Navigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import WorkIcon from "@mui/icons-material/Work";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import GavelIcon from "@mui/icons-material/Gavel";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

import { PlanFormSection } from "@providers/planForm/types";

import { LAST_WILL_SECTION_ROUTES } from "./consts";
import LastWillAllAboutYouSection from "./sections/AllAboutYou";
import allAboutYouSteps from "./sections/AllAboutYou/routes";
import LastWillGuardiansSection from "./sections/Guardians";
import guardiansSteps from "./sections/Guardians/routes";
import LastWillManagementSection from "./sections/Management";
import managementSteps from "./sections/Management/routes";
import LastWillGiftsSection from "./sections/Gifts";
import giftsSteps from "./sections/Gifts/routes";
import LastWillFinalArrangementsSection from "./sections/FinalArrangements";
import finalArrangementsSteps from "./sections/FinalArrangements/routes";
import LastWillHealthcareSection from "./sections/Healthcare";
import healthcareSteps from "./sections/Healthcare/routes";
import LastWillPowerOfAttorneySection from "./sections/PowerOfAttorney";
import powerOfAttorneySteps from "./sections/PowerOfAttorney/routes";
import DocumentPreviewPage from "./sections/DocumentPreview";

const sections: PlanFormSection[] = [
  {
    path: LAST_WILL_SECTION_ROUTES.AllAboutYou,
    element: <LastWillAllAboutYouSection />,
    children: allAboutYouSteps,
    icon: <AccountCircleIcon />
  },
  {
    path: LAST_WILL_SECTION_ROUTES.Guardians,
    element: <LastWillGuardiansSection />,
    children: guardiansSteps,
    icon: <EscalatorWarningIcon />,
    dependency: [LAST_WILL_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: LAST_WILL_SECTION_ROUTES.Management,
    element: <LastWillManagementSection />,
    children: managementSteps,
    icon: <WorkIcon />,
    dependency: [LAST_WILL_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: LAST_WILL_SECTION_ROUTES.Gifts,
    element: <LastWillGiftsSection />,
    children: giftsSteps,
    icon: <VolunteerActivismIcon />,
    dependency: [LAST_WILL_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: LAST_WILL_SECTION_ROUTES.FinalArrangements,
    element: <LastWillFinalArrangementsSection />,
    children: finalArrangementsSteps,
    icon: <LocalFloristIcon />,
    dependency: [LAST_WILL_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: LAST_WILL_SECTION_ROUTES.Healthcare,
    element: <LastWillHealthcareSection />,
    children: healthcareSteps,
    icon: <HealthAndSafetyIcon />,
    isOptional: true,
    dependency: [LAST_WILL_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
    element: <LastWillPowerOfAttorneySection />,
    children: powerOfAttorneySteps,
    icon: <GavelIcon />,
    isOptional: true,
    dependency: [LAST_WILL_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: LAST_WILL_SECTION_ROUTES.DocumentPreview,
    element: <DocumentPreviewPage />,
    hideInMenu: true
  },
  {
    path: "*",
    element: <Navigate to={LAST_WILL_SECTION_ROUTES.AllAboutYou} />
  }
];

export default sections;
