import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerDistAllToPartnerStep: React.FC = () => {
  const { value: primaryFirstName } =
    useFormField(`users.0.firstName`);

  const { setValue: setPercentGaveToPartnerValue } = useFormField(
    "users.1.gifts.percentGaveToPartner"
  );

  // Note: If not giving everything to partner as primary, reset the percent that we're storing
  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const everythingToPartner = get(
        data,
        "users.1.gifts.everythingGoToPartner"
      );

      if (parseBoolean(everythingToPartner)) {
        setPercentGaveToPartnerValue(100);
      }
    },
    [setPercentGaveToPartnerValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `If ${primaryFirstName} survives you, do you want everything to go to ${primaryFirstName}?`,
        subText: `This means that if you pass away first, all your assets will be transfered to ${primaryFirstName}.`
      }}
      {...{ onSubmit }}
      name="users.1.gifts.everythingGoToPartner"
    />
  );
};

export default PartnerDistAllToPartnerStep;
