import { useIntl } from "react-intl";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { usePlanForm } from "@providers/planForm/context";
import { Pet } from "@pages/PetPlan/types";
import { useFormField } from "@components/FormFields/hooks";

const ExerciseStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: pet } = useFormField<Pet>(`pets.${iteration}`);

  /* eslint-disable react/no-unescaped-entities */
  return (
    <SingleResponseArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "What should your Pet Guardian know about {name}'s exercise and other care instructions?"
          },
          {
            name: pet.name
          }
        ),
        subText: `See an example.`,
        moreInfoModalProps: {
          title: "Example Exercise & Other Care Instructions",
          content: (
            <p>
              "We walk Clifford at least 15 minutes in the morning and
              again in the afternoon. Sometimes, we shorten the
              afternoon walk if he's had a good run or a puppy
              playdate."
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      label="Exercise & Other Care Instructions"
      fieldProps={{
        multiline: true,
        minRows: 3
      }}
      name={`pets.${iteration}.careInstructions`}
    />
  );
};

export default ExerciseStep;
