import React, { useState } from "react";
import { Tab, Tabs as MaterialTabs } from "@mui/material";

import TabPanel from "./partials/TabPanel";
import { TabsProps } from "./types";

const Tabs: React.FC<TabsProps> = ({
  items,
  color = "secondary"
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setValue(newValue);
  };

  return (
    <>
      <MaterialTabs
        {...{ value }}
        onChange={handleChange}
        centered
        indicatorColor={color}
        textColor="inherit"
        sx={{
          mb: 2.5
        }}
      >
        {items.map(({ label }) => (
          <Tab key={label} {...{ label }} />
        ))}
      </MaterialTabs>
      {items.map(({ label, content }, index) => (
        <TabPanel key={label} {...{ value, index }}>
          {content}
        </TabPanel>
      ))}
    </>
  );
};

export default Tabs;
