import { useIntl } from "react-intl";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import { Delete as DeleteIcon } from "@mui/icons-material";

import { ArrayFormFieldSingleItemProps } from "@components/FormFields/ArrayForm/types";

import {
  ActionWrapper,
  FormWrapper,
  NumberInput,
  SharesInput
} from "./styles";

const AgeVestingForm: React.FC<ArrayFormFieldSingleItemProps> = ({
  prefix,
  fieldsCount,
  removeField
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormWrapper>
      <SharesInput
        name={`${prefix}.percentageValue`}
        placeholder={formatMessage({ defaultMessage: "0" })}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 100,
            style: { textAlign: "right" }
          },
          endAdornment: (
            <InputAdornment position="end">%</InputAdornment>
          )
        }}
      />
      <NumberInput
        name={`${prefix}.age`}
        placeholder={formatMessage({
          defaultMessage: "21"
        })}
        type="number"
      />
      <ActionWrapper>
        {fieldsCount > 1 && (
          <DeleteIcon
            onClick={removeField}
            cursor="pointer"
            fontSize="small"
          />
        )}
      </ActionWrapper>
    </FormWrapper>
  );
};

export default AgeVestingForm;
