/* eslint-disable react/no-unescaped-entities */
import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const PowersTransferToTrustStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `Do you want to enable your agent to transfer property to a revocable living trust that you have created or may create in the future?`,
      subText: `Learn more about transferring property to a trust.`,
      moreInfoModalProps: {
        title:
          "Authority to Transfer Property to a Revocable Living Trust",
        togglerLabel: "Learn more",
        content: (
          <p>
            Transferring title of property to a trust is known as
            "funding the trust" and a necessary step for your trust to
            be effective. The process to transfer title for most
            property is generally not very complicated, but it can be
            a bit tedious and you may wish to have your agent assist.
            <br />
            <br />
            Grant your agent authority to transfer property to a
            revocable living trust if you are creating or plan to
            create a revocable living trust and wish your agent to
            help with the process.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    name="users.0.poa.additionalPowers.transferToTrust"
  />
);

export default PowersTransferToTrustStep;
