import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/LastWill/routes";
import { useFormField } from "@components/FormFields/hooks";

const SectionsPreviewStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);

  return (
    <SectionsPreview
      {...{ sections }}
      stepLayout={{
        title: `Nice job, ${firstName}! You're cruising your way through this Will!`
      }}
    />
  );
};

export default SectionsPreviewStep;
