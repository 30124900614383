import Typography from "@mui/material/Typography";
import React from "react";

import theme from "@setup/theme";

import { INavItem, NavSectionNameProps } from "./type";
import NavItem from "./NavItem";

const NavSectionItem: React.FC<NavSectionNameProps> = ({
  section
}) => {
  const { title, items } = section ?? {};

  return (
    <Typography
      variant="h3"
      sx={{
        lineHeight: 1.6,
        color: theme.palette.text.secondary,
        fontWeight: 500,
        mt: 1
      }}
    >
      {title}
      {items &&
        items.map((item: INavItem) => (
          <NavItem key={item.path} {...{ item }} />
        ))}
    </Typography>
  );
};

export default NavSectionItem;
