import { useMemo } from "react";

import { Children } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import { PartnerType } from "@api/__generated__/globalTypes";

const usePrimaryDraftersChildren = (
  partnerFirstName?: string,
  children: Children[] = []
) => {
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);

  const areChildrenPrimaryDrafters = useMemo(
    () => [
      "Child of Someone Else and Me",
      `Child of ${partnerFirstName} and Me`
    ],
    [partnerFirstName]
  );

  const primaryDraftersChildren = useMemo(
    () =>
      children?.filter((child) =>
        areChildrenPrimaryDrafters?.includes(child.parentNames)
      ),
    [areChildrenPrimaryDrafters, children]
  );

  if (partnerType === PartnerType.SINGLE) {
    return children;
  }

  return primaryDraftersChildren;
};

export default usePrimaryDraftersChildren;
