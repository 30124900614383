import {
  Typography,
  Box,
  CircularProgress,
  Stack,
  FormControl
} from "@mui/material";
import React from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";
import JICLogo from "@assets/images/image-jicLogo.png";
import { Image } from "@components/Image/styles";
import ModalStepFooter from "@components/ModalStepFooter";

import * as Styled from "../../../styles";
import { AdvisorTutorialProps } from "../types";

import { useAdvisorInfoForm } from "./hooks";

const AdvisorInfoForm: React.FC<AdvisorTutorialProps> = ({
  onNext
}) => {
  const { loading, methods, handleSubmit } = useAdvisorInfoForm({
    onNext
  });

  if (loading) {
    return (
      <Styled.LoadingWrapper>
        <CircularProgress />
      </Styled.LoadingWrapper>
    );
  }

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="1.25rem"
        alignItems="center"
        sx={{
          mb: "1.25rem"
        }}
      >
        <Image src={JICLogo} alt="JIC Estates Logo" />
        <Typography variant="h3">
          <FormattedMessage defaultMessage="Welcome to JIC Estates Advisors!" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage defaultMessage="Please fill in your first and last name so that we can personalize your experience." />
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit}>
          <FormControl variant="standard" sx={{ display: "flex" }}>
            <Stack mb="2.25rem" spacing={4}>
              <BasicFormInput name="firstName" label="First Name" />
              <BasicFormInput name="lastName" label="Last Name" />
            </Stack>
          </FormControl>
          <ModalStepFooter
            nextButtonProps={{
              type: "submit",
              label: "Save & Continue"
            }}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default AdvisorInfoForm;
