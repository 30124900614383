import * as yup from "yup";
import { useCallback, useMemo } from "react";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  StateSelectFormValues,
  UseStateSelectArchetypeReturnType
} from "./types";

const useStateSelectSchema = (name: string): yup.AnyObjectSchema =>
  useMemo(
    () =>
      makeYupSchema(
        yup
          .string()
          .typeError("Please select a state from the dropdown")
          .required(),
        name
      ),
    [name]
  );

const useStateSelectArchetype = (
  name: string,
  onFormSubmit?: (data: StateSelectFormValues) => void
): UseStateSelectArchetypeReturnType => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useStateSelectSchema(name);
  const controls = useFormWithYup<StateSelectFormValues>(schema);

  const onSubmit = useCallback(
    (data: StateSelectFormValues) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useStateSelectArchetype };
