/* eslint-disable camelcase */
import { useMemo } from "react";
import { camelCase, startCase } from "lodash";

import formatDate from "@utils/formatDate";
import { Plans_plans } from "@api/plans/__generated__/Plans";
import getPermissionHumanizedTitle from "@hooks/getPermissionHumanizedTitle";

export const useSharedWithMeSummary = (
  plansSharedWithMe: Plans_plans[]
) => {
  const rows = useMemo(
    () =>
      plansSharedWithMe.map((plan) => ({
        id: plan.id,
        primaryDrafter: plan.primaryDrafter,
        planType: startCase(camelCase(plan.type)),
        created: formatDate(plan?.timeline?.[0]),
        updated: formatDate(
          plan?.timeline?.[
            Number.parseFloat(plan?.timeline?.length.toString()) - 1
          ]
        ),
        myRole: plan?.membership.roles
          .map((role) => startCase(camelCase(role)))
          .join(", "),
        permissionLevel: getPermissionHumanizedTitle(
          plan?.membership.permissionLevel
        )
      })),
    [plansSharedWithMe]
  );

  return rows;
};
