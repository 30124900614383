/* eslint-disable no-nested-ternary */
import { isBoolean } from "lodash";
import { IntlShape } from "react-intl";

const getSkippableAnswer = (
  value: string | boolean | undefined | null,
  intl: IntlShape
): string =>
  isBoolean(value)
    ? value
      ? "Yes"
      : "No"
    : value || intl.formatMessage({ defaultMessage: "Skipped" });

export default getSkippableAnswer;
