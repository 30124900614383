import { useEffect } from "react";
import { useIntl } from "react-intl";
import MenuIcon from "@mui/icons-material/Menu";

import TutorialLayout from "@pages/Tutorial/partials/TutorialLayout";
import { TUTORIAL_ROUTES } from "@pages/Tutorial/consts";
import { ROUTES } from "@setup/consts/routes";
import useArrowAnimationContext from "@providers/arrowAnimation/hooks";

const QuickActionPage: React.FC = () => {
  const intl = useIntl();
  const { setShowLeftArrow } = useArrowAnimationContext();

  useEffect(() => {
    setShowLeftArrow(true);

    return () => setShowLeftArrow(false);
  }, [setShowLeftArrow]);

  return (
    <TutorialLayout
      heading={intl.formatMessage({
        defaultMessage: "Quick Action Menu"
      })}
      description={intl.formatMessage({
        defaultMessage:
          "You can jump to different Sections of your plan and monitor overall progress at any time in the Quick Action Menu, found here."
      })}
      title={intl.formatMessage(
        {
          defaultMessage:
            "tutorial ({currentStep} of {allStepsCount})"
        },
        {
          allStepsCount: 5,
          currentStep: 4
        }
      )}
      progress={80}
      withSkipButton
      nextButtonProps={{
        href: `${ROUTES.Tutorial}/${TUTORIAL_ROUTES.Team}`,
        label: intl.formatMessage({
          defaultMessage: "Continue"
        })
      }}
    >
      <MenuIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
    </TutorialLayout>
  );
};

export default QuickActionPage;
