import React, { PropsWithChildren, useMemo, useState } from "react";

import { ArrowAnimationContext } from "./context";

export const { Provider: ArrowAnimationProvider } =
  ArrowAnimationContext;

export const ArrowAnimationContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const arrowAnimationContextValue = useMemo(
    () => ({
      showLeftArrow,
      setShowLeftArrow,
      showRightArrow,
      setShowRightArrow
    }),
    [showLeftArrow, showRightArrow]
  );

  return (
    <ArrowAnimationProvider value={arrowAnimationContextValue}>
      {children}
    </ArrowAnimationProvider>
  );
};
