import { useEffect, useMemo } from "react";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { useFormField } from "@components/FormFields/hooks";
import { useDisableForm } from "@providers/disableForm/context";
import { DisableFormItems } from "@providers/disableForm/type";

const PartnerTrusteeCompensationPercentStep: React.FC = () => {
  const { value } = useFormField<number>(
    "users.1.trust.trusteesCompensationPercent"
  );

  const error = useMemo(() => +value > 100 || +value < 0, [value]);

  const { setDisableItems: setExceededItem } = useDisableForm();

  useEffect(() => {
    setExceededItem((prev: DisableFormItems) => ({
      ...prev,
      "users.1.trust.trusteesCompensationPercent": error
    }));
  }, [error, setExceededItem]);

  return (
    <SingleResponseArchetype
      label="Compensation"
      fieldProps={{
        type: "number",
        defaultValue: "number",
        InputProps: {
          endAdornment: `%`,
          type: "number"
        },
        error,
        helperText: error ? "Value should be between 0 and 100" : ""
      }}
      stepLayout={{
        title: `What percent of your estate would you like to offer your Trustee as compensation?`
      }}
      name="users.1.trust.trusteesCompensationPercent"
    />
  );
};

export default PartnerTrusteeCompensationPercentStep;
