import chartColors from "@setup/theme/partials/chartColors";

import { DataPoint } from "./types";

const getChartColors = (data: DataPoint[]): DataPoint[] => {
  const themeColors = chartColors;

  return data.map((item, index) => ({
    name: item.name,
    value: item.value,
    color: themeColors[index % themeColors.length]
  }));
};

export default getChartColors;
