import { useCallback } from "react";

import {
  useDeleteAssetMutation,
  useUpdateAssetMutation
} from "@api/assets";
import { AssetUpdateInput } from "@api/__generated__/globalTypes";
import useToggle from "@hooks/useToggle";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { useGetPlansQuery } from "@api/plans";

export const useEditAssetModal = (id: number) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { trust, lastWill } = useMemberPlansState();
  const canEdit = trust ? trust.canEdit : lastWill?.canEdit;
  const { refetch } = useGetPlansQuery();
  const [updateAsset, { loading, error }] = useUpdateAssetMutation({
    onCompleted: () => {
      refetch();
      toggleOff();
    }
  });
  const [
    deleteAsset,
    { loading: deleteAssetLoading, error: deleteAssetError }
  ] = useDeleteAssetMutation({
    onCompleted: () => {
      refetch();
      toggleOff();
    }
  });

  const onSubmit = useCallback(
    (input: AssetUpdateInput) => {
      updateAsset({
        variables: {
          id,
          input
        }
      });
    },
    [id, updateAsset]
  );

  const onDeleteAsset = useCallback(() => {
    deleteAsset({
      variables: {
        id
      }
    });
  }, [deleteAsset, id]);

  return {
    isOpen,
    canEdit: !!canEdit,
    loading,
    error,
    toggleOn,
    toggleOff,
    onSubmit,
    onDeleteAsset,
    deleteAssetLoading,
    deleteAssetError
  };
};
