const getTrustDistributionStandardsContent = () => (
  <p>
    <strong>Standard Distribution.</strong> A common distribution
    standard is to give the trustee discretion to distribute funds to
    the Trust beneficiary for the beneficiary&apos;s health, support,
    maintenance, and education.
    <br />
    <br />
    Providing the trustee authority solely for these purposes creates
    an &quot;ascertainable standard&quot; that allows a beneficiary to
    be named as the trustee of their own trust without risking that
    the trust assets are included in their estate for estate tax
    purposes.
    <br />
    <br />
    <strong>Free Access.</strong> If you&apos;d like to provide your
    beneficiary with broader potential use of trust funds, you can
    make the trust a Free Access trust. This allows the beneficiary to
    appoint an &quot;Independent Trustee&quot; to work in tandem with
    the Trustee. The Independent Trustee can authorize distributions
    in the beneficiary&apos;s &quot;best interests,&quot; which
    practically speaking, can be for most any reason at all.
  </p>
);

export default getTrustDistributionStandardsContent;
