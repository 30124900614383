import { useMemo } from "react";
import { sortBy } from "lodash";

import { Asset } from "@api/plans/__generated__/Asset";
import { useMemberPlansState } from "@providers/memberPlansState/context";

import { UseAssetsParams } from "../types";
import { ASSETS_TYPE_ORDER } from "../consts";

import isAssetOutsideLastWill from "./isAssetOutsideLastWill";

export const useAssets = ({
  assets = [],
  excludeAssets = []
}: UseAssetsParams) => {
  const { lastWill, trust } = useMemberPlansState();

  const canEdit = !!trust?.canEdit || !!lastWill?.canEdit;

  const filteredAssets = useMemo(() => {
    const filteredOutAssets = assets.filter(
      (asset): asset is Asset =>
        !!asset?.type && !excludeAssets.includes(asset?.type)
    );

    return sortBy(
      filteredOutAssets,
      (item) => item.type && ASSETS_TYPE_ORDER.indexOf(item.type)
    );
  }, [excludeAssets, assets]);

  const { assetsOutsideLastWill, assetsThroughLastWill } = useMemo(
    () =>
      filteredAssets.reduce<{
        assetsOutsideLastWill: Asset[];
        assetsThroughLastWill: Asset[];
      }>(
        (prev, asset) => {
          if (isAssetOutsideLastWill(asset)) {
            return {
              assetsOutsideLastWill: [
                ...prev.assetsOutsideLastWill,
                asset
              ],
              assetsThroughLastWill: prev.assetsThroughLastWill
            };
          }

          return {
            assetsThroughLastWill: [
              ...prev.assetsThroughLastWill,
              asset
            ],
            assetsOutsideLastWill: prev.assetsOutsideLastWill
          };
        },
        { assetsOutsideLastWill: [], assetsThroughLastWill: [] }
      ),
    [filteredAssets]
  );

  const { fundedAssets, notFundedAssets } = useMemo(
    () =>
      filteredAssets.reduce<{
        fundedAssets: Asset[];
        notFundedAssets: Asset[];
      }>(
        (prev, asset) => {
          if (asset.isFunded) {
            return {
              fundedAssets: [...prev.fundedAssets, asset],
              notFundedAssets: prev.notFundedAssets
            };
          }

          return {
            notFundedAssets: [...prev.notFundedAssets, asset],
            fundedAssets: prev.fundedAssets
          };
        },
        { fundedAssets: [], notFundedAssets: [] }
      ),
    [filteredAssets]
  );

  return {
    filteredAssets,
    assetsOutsideLastWill,
    assetsThroughLastWill,
    canEdit: !!canEdit,
    fundedAssets,
    notFundedAssets
  };
};
