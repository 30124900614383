import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";

import { LegalFocus } from "../enums";
import { EXPERT_MATCHING_RESULTS } from "../results/consts";
import { EXPERT_MATCHING_ROUTES } from "../consts";

import AssetValueStep from "./steps/AssetValue";
import { EXPERT_MATCHING_QUIZ_ROUTES } from "./consts";
import ExpertTypeStep from "./steps/ExpertType";
import HHAssetsStep from "./steps/HHAssets";
import HHIncomeStep from "./steps/HHIncome";
import HHStateStep from "./steps/HHState";
import DocumentTypeStep from "./steps/DocumentType";
import LegalFocusStep from "./steps/LegalFocus";
import AssetValueProbateStep from "./steps/AssetValueProbate";
import InterstitialStep from "./steps/Interstitial";
import StateJurisdictionStep from "./steps/StateJurisdiction";
import WhatToExpectStep from "./steps/WhatToExpect";
import HHFinancialAdviceStep from "./steps/HHInvestmentAdvice";
import ChallengeInterstitialStep from "./steps/ChallengeInterstitial";
import FinanceEndStep from "./steps/FinanceEnd";
import LegalEndStep from "./steps/LegalEnd";

const expertMatchingQuizSteps: PlanFormStep[] = [
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.WhatToExpect,
    element: <WhatToExpectStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.ExpertType
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.ExpertType,
    element: <ExpertTypeStep />,
    rules: {
      if: [
        {
          or: [
            { "==": [{ var: "expertType" }, "LEGAL"] },
            { "==": [{ var: "expertType" }, "BOTH"] }
          ]
        },
        EXPERT_MATCHING_QUIZ_ROUTES.LegalFocus,
        EXPERT_MATCHING_QUIZ_ROUTES.HHAssets
      ]
    }
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.LegalFocus,
    element: <LegalFocusStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.StateJurisdiction
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.StateJurisdiction,
    element: <StateJurisdictionStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.DocumentType
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.DocumentType,
    element: <DocumentTypeStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.AssetValue
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.AssetValue,
    element: <AssetValueStep />,
    rules: {
      if: [
        {
          none: [
            {
              var: "estateData.focusAreas"
            },
            {
              "==": [{ var: "" }, LegalFocus.ESTATE_ADMINISTRATION]
            },
            {
              "==": [{ var: "" }, LegalFocus.OTHER]
            }
          ]
        },
        EXPERT_MATCHING_QUIZ_ROUTES.ChallengeInterstitial,
        EXPERT_MATCHING_QUIZ_ROUTES.AssetValueProbate
      ]
    }
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.AssetValueProbate,
    element: <AssetValueProbateStep />,
    rules: {
      if: [
        {
          "==": [{ var: "expertType" }, "BOTH"]
        },
        EXPERT_MATCHING_QUIZ_ROUTES.Interstitial,
        EXPERT_MATCHING_QUIZ_ROUTES.LegalEnd
      ]
    }
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.ChallengeInterstitial,
    element: <ChallengeInterstitialStep />,
    rules: {
      if: [
        {
          "==": [{ var: "expertType" }, "BOTH"]
        },
        EXPERT_MATCHING_QUIZ_ROUTES.Interstitial,
        EXPERT_MATCHING_QUIZ_ROUTES.LegalEnd
      ]
    }
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.Interstitial,
    element: <InterstitialStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.HHAssets
  },
  // FINANCIAL SECTION STARTS HERE
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.HHAssets,
    element: <HHAssetsStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.HHInvestmentAdvice
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.HHInvestmentAdvice,
    element: <HHFinancialAdviceStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "householdData.personalInvestmentHelp" },
            true
          ]
        },
        EXPERT_MATCHING_QUIZ_ROUTES.HHIncome,
        EXPERT_MATCHING_QUIZ_ROUTES.HHState
      ]
    }
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.HHIncome,
    element: <HHIncomeStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.HHState
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.HHState,
    element: <HHStateStep />,
    rules: EXPERT_MATCHING_QUIZ_ROUTES.FinanceEnd
  },
  // LEGAL SOLO END
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.LegalEnd,
    element: <LegalEndStep />,
    rules: `${EXPERT_MATCHING_ROUTES.Results}/${EXPERT_MATCHING_RESULTS.Results}` // Send to Results
  },
  {
    path: EXPERT_MATCHING_QUIZ_ROUTES.FinanceEnd,
    element: <FinanceEndStep />,
    rules: {
      if: [
        {
          "==": [{ var: "expertType" }, "BOTH"]
        },
        `${EXPERT_MATCHING_ROUTES.Results}/${EXPERT_MATCHING_RESULTS.ResultsTab}`,
        `${EXPERT_MATCHING_ROUTES.Results}/${EXPERT_MATCHING_RESULTS.Results}`
      ]
    }
  },
  {
    path: "*",
    element: (
      <Navigate to={EXPERT_MATCHING_QUIZ_ROUTES.WhatToExpect} />
    )
  }
];

export default expertMatchingQuizSteps;
