import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const MakeCashGiftStep: React.FC = () => {
  const { setValue: setCashGifts } = useFormField(
    "users.0.assets.cash"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const isCashGifts = get(data, "users.0.gifts.makeCashGifts");

      if (!parseBoolean(isCashGifts)) {
        setCashGifts([]);
      }
    },
    [setCashGifts]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title:
          "Last one! Do you want to make any other cash gifts from your Trust?"
      }}
      {...{ onSubmit }}
      name="users.0.gifts.makeCashGifts"
    />
  );
};

export default MakeCashGiftStep;
