import { useFormField } from "@components/FormFields/hooks";
import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const PartnerDesignateBackupAgentStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <PersonDetailsArrayArchetype
      stepLayout={{
        title: `Who does ${partnerFirstName} want to serve as their backup agent?`
      }}
      personRole="Backup Agent"
      name="users.1.healthcare.backupAgents"
      addBtnLabel="Add next backup agent"
      removeBtnLabel="Remove backup agent"
    />
  );
};

export default PartnerDesignateBackupAgentStep;
