import { Box } from "@mui/material";

import { useModalTogglerLabelSize } from "./hooks";
import * as Styled from "./styles";
import { ModalTogglerProps } from "./types";

const ModalToggler: React.FC<ModalTogglerProps> = ({
  icon,
  iconPlacement = "right",
  label,
  ...rest
}) => {
  const { togglerRef, labelHeight } = useModalTogglerLabelSize();
  const isIconOnTheRight = iconPlacement === "right";
  const iconComponent = (
    <Box
      mr={!isIconOnTheRight && label ? 1 : undefined}
      ml={isIconOnTheRight && label ? 1 : undefined}
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: labelHeight || "inherit"
      }}
    >
      {icon}
    </Box>
  );

  return (
    <Box sx={{ display: "inline-block" }} ref={togglerRef}>
      <Styled.Button {...rest}>
        {!isIconOnTheRight && iconComponent}
        {label}
        {isIconOnTheRight && iconComponent}
      </Styled.Button>
    </Box>
  );
};

export default ModalToggler;
