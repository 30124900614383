import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { PlanOption } from "@api/__generated__/globalTypes";

import TrustReviewTabsStep from "../../steps/Reviews/TrustReviewTabs";
import LivingWillPreviewStep from "../../steps/LivingWillPreview";
import LivingWillOverviewStep from "../../steps/LivingWillOverview";
import CreateLivingWillStep from "../../steps/CreateLivingWill";
import GeneralGuidanceStep from "../../steps/GeneralGuidance";
import ProlongLifeStep from "../../steps/ProlongLife";
import ArtificialNutritionStep from "../../steps/ArtificialNutrition";
import PainReliefStep from "../../steps/PainRelief";
import PainReliefExceptionStep from "../../steps/PainReliefException";
import PainReliefExceptionListStep from "../../steps/PainReliefExceptionList";
import OrganDonationStep from "../../steps/OrganDonation";
import OrganDonationListStep from "../../steps/OrganDonationList";
import OrganDonationPurposeStep from "../../steps/OrganDonationPurpose";
import PartnerCreateLivingWillStep from "../../steps/PartnerCreateLivingWill";
import PartnerSamePreferencesStep from "../../steps/PartnerSamePreferences";
import PartnerPreferenceConfirmationStep from "../../steps/PartnerPreferenceConfirmation";
import PartnerGeneralGuidanceStep from "../../steps/PartnerGeneralGuidance";
import PartnerProlongLifeStep from "../../steps/PartnerProlongLife";
import PartnerArtificialNutritionStep from "../../steps/PartnerArtificialNutrition";
import PartnerPainReliefStep from "../../steps/PartnerPainRelief";
import PartnerPainReliefExceptionStep from "../../steps/PartnerPainReliefException";
import PartnerPainReliefExceptionListStep from "../../steps/PartnerPainReliefExceptionList";
import PartnerOrganDonationStep from "../../steps/PartnerOrganDonation";
import PartnerOrganDonationListStep from "../../steps/PartnerOrganDonationList";
import PartnerOrganDonationPurposeStep from "../../steps/PartnerOrganDonationPurpose";
import SpecialNoteStep from "../../steps/SpecialNote";
import SpecialNoteDetailsStep from "../../steps/SpecialNoteDetails";
import PartnerSpecialNoteDetailsStep from "../../steps/PartnerSpecialNoteDetails";
import TrustReviewStep from "../../steps/Reviews/TrustReview";
import HealthcareCompleteStep from "../../steps/HealthcareComplete";
import PartnerSpecialNoteStep from "../../steps/PartnerSpecialNote";

import { HEALTHCARE_LIVINGWILL_ROUTES } from "./consts";

const livingWillSteps: PlanFormStep[] = [
  // Start of Living Will Section
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.Preview,
    element: <LivingWillPreviewStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.LivingWillOverview
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.LivingWillOverview,
    element: <LivingWillOverviewStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeAdvanceHealthCareDirective" }, true]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.CreateLivingWill,
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerCreateLivingWill
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.CreateLivingWill,
    element: <CreateLivingWillStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.preferences.specified" },
            true
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.GeneralGuidance,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerCreateLivingWill,
            {
              if: [
                { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
                HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs,
                HEALTHCARE_LIVINGWILL_ROUTES.Review
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.GeneralGuidance,
    element: <GeneralGuidanceStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.ProlongLife
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.ProlongLife,
    element: <ProlongLifeStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.ArtificialNutrition
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.ArtificialNutrition,
    element: <ArtificialNutritionStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PainRelief
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PainRelief,
    element: <PainReliefStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.preferences.reliefFromPain" },
            true
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PainReliefException,
        HEALTHCARE_LIVINGWILL_ROUTES.OrganDonation
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PainReliefException,
    element: <PainReliefExceptionStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.healthcare.preferences.reliefFromPainAddNote"
            },
            true
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PainReliefExceptionList,
        HEALTHCARE_LIVINGWILL_ROUTES.OrganDonation
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PainReliefExceptionList,
    element: <PainReliefExceptionListStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.OrganDonation
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.OrganDonation,
    element: <OrganDonationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.preferences.organDonation" },
            "Give only specific organs, tissues, or parts"
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.OrganDonationList,
        {
          if: [
            {
              "==": [
                {
                  var: "users.0.healthcare.preferences.organDonation"
                },
                "Give any needed organs, tissues, or parts"
              ]
            },
            HEALTHCARE_LIVINGWILL_ROUTES.OrganDonationPurpose,
            HEALTHCARE_LIVINGWILL_ROUTES.SpecialNote
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.OrganDonationList,
    element: <OrganDonationListStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.OrganDonationPurpose
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.OrganDonationPurpose,
    element: <OrganDonationPurposeStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.SpecialNote
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.SpecialNote,
    element: <SpecialNoteStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeHealthcareNote" }, true]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.SpecialNoteDetails,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerCreateLivingWill,
            {
              if: [
                { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
                HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs,
                HEALTHCARE_LIVINGWILL_ROUTES.Review
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.SpecialNoteDetails,
    element: <SpecialNoteDetailsStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                {
                  var: "includePartnerAdvanceHealthCareDirective"
                },
                true
              ]
            },
            {
              "==": [{ var: "planOption" }, PlanOption.COUPLE]
            }
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerCreateLivingWill,
        {
          if: [
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
            HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs,
            HEALTHCARE_LIVINGWILL_ROUTES.Review
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerCreateLivingWill,
    element: <PartnerCreateLivingWillStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                { var: "users.1.healthcare.preferences.specified" },
                true
              ]
            },
            {
              "==": [
                { var: "users.0.healtchare.preferences.specified" },
                true
              ]
            }
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerSamePreferences,
        {
          if: [
            {
              "==": [
                { var: "users.1.healthcare.preferences.specified" },
                true
              ]
            },
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerGeneralGuidance,
            HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerSamePreferences,
    element: <PartnerSamePreferencesStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PartnerPreferenceConfirmation
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerPreferenceConfirmation,
    element: <PartnerPreferenceConfirmationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.preferences.specified" },
            { var: "uesrs.1.healthcare.preferences.copy" },
            true
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs,
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerGeneralGuidance
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerGeneralGuidance,
    element: <PartnerGeneralGuidanceStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PartnerProlongLife
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerProlongLife,
    element: <PartnerProlongLifeStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PartnerArtificialNutrition
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerArtificialNutrition,
    element: <PartnerArtificialNutritionStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainRelief
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainRelief,
    element: <PartnerPainReliefStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.preferences.reliefFromPain" },
            true
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainReliefException,
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonation
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainReliefException,
    element: <PartnerPainReliefExceptionStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.healthcare.preferences.reliefFromPainAddNote"
            },
            true
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainReliefExceptionList,
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonation
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerPainReliefExceptionList,
    element: <PartnerPainReliefExceptionListStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonation
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonation,
    element: <PartnerOrganDonationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.preferences.organDonation" },
            "Give only specific organs, tissues, or parts"
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonationList,
        {
          if: [
            {
              "==": [
                {
                  var: "users.1.healthcare.preferences.organDonation"
                },
                "Give any needed organs, tissues, or parts"
              ]
            },
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonationPurpose,
            HEALTHCARE_LIVINGWILL_ROUTES.PartnerSpecialNote
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonationList,
    element: <PartnerOrganDonationListStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonationPurpose
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerOrganDonationPurpose,
    element: <PartnerOrganDonationPurposeStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.PartnerSpecialNote
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerSpecialNote,
    element: <PartnerSpecialNoteStep />,
    rules: {
      if: [
        {
          and: [
            { "==": [{ var: "includePartnerHealthcareNote" }, true] },
            {
              "==": [{ var: "planOption" }, PlanOption.COUPLE]
            }
          ]
        },
        HEALTHCARE_LIVINGWILL_ROUTES.PartnerSpecialNoteDetails,
        HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs
      ]
    }
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.PartnerSpecialNoteDetails,
    element: <PartnerSpecialNoteDetailsStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.Review,
    element: <TrustReviewStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.HealthcareComplete
  },
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs,
    element: <TrustReviewTabsStep />,
    rules: HEALTHCARE_LIVINGWILL_ROUTES.HealthcareComplete
  },
  // Healthcare Complete -> Return to Member Dashboard
  {
    path: HEALTHCARE_LIVINGWILL_ROUTES.HealthcareComplete,
    element: <HealthcareCompleteStep />
  },
  // TODO: @LUKE UPDATE THIS...
  {
    path: "*",
    element: <Navigate to={HEALTHCARE_LIVINGWILL_ROUTES.Preview} />
  }
];

export default livingWillSteps;
