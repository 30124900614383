import {
  Box,
  Typography,
  Container,
  CircularProgress,
  useTheme
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import { Image } from "@components/Image/styles";
import JICLogo from "@assets/images/image-jicLogo.png";

import { AuthLayoutProps } from "./types";

const AuthLayout: React.FC<AuthLayoutProps> = ({
  title,
  subTitle,
  children,
  isFullWidth,
  maxWidth,
  isLoading,
  showJICLgo
}) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={maxWidth || "sm"}
      sx={{
        py: { xs: 2.5, md: 6.25 }
      }}
    >
      {showJICLgo && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb="0.5rem"
        >
          <Image src={JICLogo} alt="JIC Estates Logo" />
        </Box>
      )}
      <Box textAlign="center">
        <Typography
          variant="h1"
          lineHeight="1.5"
          letterSpacing="0"
          mb="1.25rem"
        >
          {title}
        </Typography>
        {subTitle && (
          <>
            <Typography mb="0.625rem" variant="body1">
              {subTitle}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                mb: "1.25rem",
                color: theme.palette.grey[600]
              }}
            >
              <Box paddingRight="0.625rem">
                <StarIcon fontSize="small" />
                <StarIcon fontSize="small" />
                <StarIcon fontSize="small" />
                <StarIcon fontSize="small" />
                <StarIcon fontSize="small" />
              </Box>
              <Typography variant="body2">
                4.9/5.0 on TrustPilot
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box
        maxWidth={isFullWidth ? "100%" : "28.25rem"}
        width="100%"
        m="0 auto"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {children}
      </Box>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255,255,255,0.5)"
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default AuthLayout;
