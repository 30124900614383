import {
  ApolloClient,
  InMemoryCache,
  createHttpLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";

import { GRAPHQL_API_URL } from "@setup/environment";

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();

    return token
      ? {
          headers: { ...headers, Authorization: `Bearer ${token}` }
        }
      : { headers };
  } catch (error) {
    return { headers };
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});

export default client;
