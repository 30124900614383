import {
  makeLazyQueryHook,
  makeMutationHook,
  makeQueryHook
} from "@utils/apollo-utils";

import {
  CreatePlanDraft,
  CreatePlanDraftVariables
} from "./__generated__/CreatePlanDraft";
import {
  UpdatePlanDraft,
  UpdatePlanDraftVariables
} from "./__generated__/UpdatePlanDraft";
import {
  GetPlanDraft,
  GetPlanDraftVariables
} from "./__generated__/GetPlanDraft";
import { FIND_PEOPLE, GET_PLAN_DRAFT } from "./queries";
import { CREATE_PLAN_DRAFT, UPDATE_PLAN_DRAFT } from "./mutations";
import {
  FindPeople,
  FindPeopleVariables
} from "./__generated__/FindPeople";

export const useGetPlanDraftLazyQuery = makeLazyQueryHook<
  GetPlanDraft,
  GetPlanDraftVariables
>(GET_PLAN_DRAFT);

export const useGetPlanDraftQuery = makeQueryHook<
  GetPlanDraft,
  GetPlanDraftVariables
>(GET_PLAN_DRAFT);

export const useCreatePlanDraftMutation = makeMutationHook<
  CreatePlanDraft,
  CreatePlanDraftVariables
>(CREATE_PLAN_DRAFT);

export const useUpdatePlanDraftMutation = makeMutationHook<
  UpdatePlanDraft,
  UpdatePlanDraftVariables
>(UPDATE_PLAN_DRAFT);

export const useFindPeopleQuery = makeQueryHook<
  FindPeople,
  FindPeopleVariables
>(FIND_PEOPLE);

export const useFindPeopleLazyQuery = makeLazyQueryHook<
  FindPeople,
  FindPeopleVariables
>(FIND_PEOPLE);
