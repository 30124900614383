import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/PetPlan/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    title="Create My Pet Plan"
    {...{ sections }}
    stepLayout={{
      title:
        "We'll build your Pet Estate Plan in 4 sections."
    }}
  />
);

export default SectionsPreviewStep;
