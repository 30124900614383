import { gql } from "@apollo/client";

import { PLAN_DRAFT_FRAGMENT } from "@api/planDrafts";
import { INVITATION_FRAGMENT } from "@api/invitation";

export const FINANCIAL_ADVISOR_METRICS_FRAGMENT = gql`
  fragment FinancialAdvisorMetrics on FinancialAdvisorMetrics {
    invitationsSent
    invitationsSentData {
      ...Invitation
    }
    completedPlans
    draftPlans
    draftPlansData {
      ...PlanDraft
    }
  }
  ${PLAN_DRAFT_FRAGMENT}
  ${INVITATION_FRAGMENT}
`;
