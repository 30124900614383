import en from "../../translations/locales/en.json";

import { Locale } from "./types";

const LOCALES: Locale[] = ["en"];

const DEFAULT_LOCALE: Locale = "en";

const translations = {
  en
};

export { LOCALES, DEFAULT_LOCALE, translations };
