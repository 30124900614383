import { Grid, Box, Typography, useTheme } from "@mui/material";
import React from "react";

import {
  IClientInviteMetric,
  ClientInviteMetricProps
} from "./types";

const ClientInviteMetrics: React.FC<ClientInviteMetricProps> = ({
  metrics = []
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      item
      xs={12}
      mt="1.5rem"
      justifyContent="space-around"
    >
      {metrics.map((metric: IClientInviteMetric) => (
        <Box
          key={metric.label}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1.25rem",
            fontSize: "h5",
            color: theme.palette.text.secondary
          }}
        >
          {metric.icon}
          <Typography variant="h2">{metric.value}</Typography>
          <Typography
            variant="body2"
            color={theme.palette.text.disabled}
          >
            {metric.label}
          </Typography>
        </Box>
      ))}
    </Grid>
  );
};

export default ClientInviteMetrics;
