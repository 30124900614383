import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominateBackupRepresentativeStep: React.FC = () => {
  const { setValue: setBackupReps } = useFormField(
    "users.0.lastWill.backupPersonalRepresentatives"
  );

  const onSubmit = useCallback(
    ({ nominateBackupRep }: SingleSelectFormValues) => {
      if (!parseBoolean(nominateBackupRep)) {
        setBackupReps([]);
      }
    },
    [setBackupReps]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title:
          "Do you want to nominate a backup Personal Representative?",
        subText:
          "Your backup Personal Representative can serve if your primary Personal Representative is unavailable."
      }}
      {...{ onSubmit }}
      name="nominateBackupRep"
    />
  );
};

export default NominateBackupRepresentativeStep;
