import { capitalize, lowerCase } from "lodash";

import {
  PartnerType,
  PlanOption
} from "@api/__generated__/globalTypes";
import UserDetailsArchetype from "@components/archetypes/UserDetails";
import { useFormField } from "@components/FormFields/hooks";

const PartnerDetailsStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);
  const { value: planOption } =
    useFormField<PlanOption>(`planOption`);

  const lowerCasePartnerType = lowerCase(partnerType);

  if (planOption === PlanOption.COUPLE) {
    return (
      <UserDetailsArchetype
        name="users.1"
        stepLayout={{
          title: `Awesome! Let's go ahead and get your ${lowerCasePartnerType}'s details, too.`
        }}
      />
    );
  }

  return (
    <UserDetailsArchetype
      name="users.1"
      stepLayout={{
        title: `Sounds good, ${firstName}. We'll grab your ${lowerCasePartnerType}'s details and move on to creating a plan just for you.`
      }}
    />
  );
};

export default PartnerDetailsStep;
