import { useIntl } from "react-intl";

import DocumentPreviewArchetype from "@components/archetypes/DocumentPreview";

const DocumentPreview: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <DocumentPreviewArchetype
      previewActionButtonText={formatMessage({
        defaultMessage: "Finish"
      })}
    />
  );
};

export default DocumentPreview;
