import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormMultiSelectCheckboxGroup from "@components/FormFields/FormMultiSelectCheckboxGroup";
import { useFormWithYup } from "@hooks/useFormWithYup";

import {
  useEstatePlansMatchmakingItems,
  useEstatePlansMatchmakingSchema,
  useEstatePlansMatchmakingSubmit
} from "./hooks";
import { EstatePlansMatchmakingFormValues } from "./types";

const ChooseOptionsStep: React.FC = () => {
  const intl = useIntl();
  const items = useEstatePlansMatchmakingItems();
  const vaildationSchema = useEstatePlansMatchmakingSchema();
  const methods = useFormWithYup<EstatePlansMatchmakingFormValues>(
    vaildationSchema,
    {
      defaultValues: {
        matchmakingOptions: []
      }
    }
  );

  const { onSubmit } = useEstatePlansMatchmakingSubmit();

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage: "It’s like matchmaking for estate plans"
      })}
      isWide
      hideImageOnMobile
    >
      <Typography variant="body1" mb={2.5}>
        <FormattedMessage defaultMessage="Select all that apply to your household" />
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormMultiSelectCheckboxGroup
            {...{ items }}
            name="matchmakingOptions"
          />
          <FormStepFooter
            nextButtonProps={{
              type: "submit",
              label: "GET RESULTS"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default ChooseOptionsStep;
