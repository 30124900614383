import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";

const PartnerAgentCompensationStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const { setValue: setPOACompAmountValue } = useFormField(
    "users.1.poa.compensationLimit"
  );
  const { setValue: setPOACompTypeValue } = useFormField(
    "users.1.poa.compensationType"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const compensation = get(
        data,
        "users.1.poa.compensation"
      ) as string;

      if (
        compensation ===
          "Reimbursement of reasonable expenses ONLY" ||
        compensation ===
          "Reasonable compensation and reimbursement of expenses"
      ) {
        setPOACompAmountValue(null);
        setPOACompTypeValue(null);
      }
    },
    [setPOACompAmountValue, setPOACompTypeValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How does ${partnerFirstName} want to compensate ${partnerFirstName}'s agent?`
      }}
      name="users.1.poa.compensation"
      items={[
        {
          value: "Reimbursement of reasonable expenses ONLY",
          label: "Reimbursement of reasonable expenses ONLY"
        },
        {
          value:
            "Reasonable compensation and reimbursement of expenses",
          label:
            "Reasonable compensation and reimbursement of expenses"
        },
        {
          value:
            "Specify a compensation amount and reimbursement of expenses",
          label:
            "Specify a compensation amount and reimbursement of expenses"
        }
      ]}
      {...{ onSubmit }}
    />
  );
};

export default PartnerAgentCompensationStep;
