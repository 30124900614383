import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { UseAddressFieldsReturnType } from "./types";
import { useFormField } from "../hooks";

export const useAddressFields = (
  name: string
): UseAddressFieldsReturnType => {
  const { setValue } = useFormField(name);
  const { getValues, setValue: setRHFValue } = useFormContext();
  const handleAddressAutofill = useCallback(
    (
      addressComponents?: google.maps.GeocoderAddressComponent[]
    ): void => {
      const values = {
        address1: "",
        zip: "",
        state: "",
        stateCode: "",
        county: "",
        city: "",
        country: ""
      };

      if (addressComponents) {
        addressComponents.forEach((component) => {
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number": {
              values.address1 = `${component.long_name} ${values.address1}`;
              break;
            }

            case "route": {
              values.address1 += component.long_name;
              break;
            }

            case "postal_code": {
              values.zip = component.long_name;
              break;
            }

            case "postal_town":
            case "neighborhood":
            case "locality":
              values.city = component.long_name;
              break;

            case "administrative_area_level_1": {
              values.state = component.long_name;
              if (component.short_name !== component.long_name) {
                values.stateCode = component.short_name;
              }
              break;
            }

            case "country": {
              values.country = component.long_name;
              break;
            }

            case "administrative_area_level_2":
              values.county = component.long_name;
              break;

            default:
              break;
          }
        });
      }

      setValue(values);
      setRHFValue(name, values, { shouldValidate: true });

    },
    [name, setValue, setRHFValue]
  );

  return {
    handleAddressAutofill
  };
};
