import {
  Grid,
  Chip,
  Box,
  Card,
  Typography,
  Link
} from "@mui/material";
import React from "react";
import { generatePath } from "react-router-dom";
import { startCase, camelCase } from "lodash";

import { ROUTES } from "@setup/consts/routes";
import { FinancialAdvisorMetricsQuery_financialAdvisorMetrics_draftPlansData as DraftPlanInfoItem } from "@api/financialAdvisor/__generated__/FinancialAdvisorMetricsQuery";
import { Invitation } from "@api/invitation/__generated__/Invitation";

import { getLinkFullUrl } from "../../utils";

import { SharedPlanInfoItem, SharedPlanDetailProps } from "./types";
import ClientLink from "../../components/AdvisorDashTools/ClientLink";

const SharedPlanCardLayout: React.FC<SharedPlanDetailProps> = ({
  plans = [],
  draftPlans = [],
  invitations = []
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem",
      variant: "body1"
    }}
  >
    {plans.map((plan: SharedPlanInfoItem) => {
      const {
        id,
        primaryDrafter,
        planType,
        updated,
        myRole,
        permissionLevel
      } = plan;

      const path = generatePath(":pathname?planId=:planId", {
        planId: id.toString(),
        pathname: getLinkFullUrl([ROUTES.Dashboard])
      });

      return (
        <Card
          key={id}
          sx={{
            py: "1.25rem",
            px: "1rem"
          }}
        >
          <Grid container gridTemplateColumns="1fr 2fr">
            <Grid item xs={6}>
              <Typography>Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Link href={path}>
                <Typography>{primaryDrafter?.fullName}</Typography>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Typography>Email:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "100%"
                }}
              >
                {primaryDrafter?.email ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Plan Type:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{planType}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Status:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                size="small"
                label="Complete"
                color="success"
                sx={{
                  borderRadius: "0.25rem",
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: "#ffffff"
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Updated:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{updated}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Your Role:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{myRole}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Permissions:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{permissionLevel}</Typography>
            </Grid>
          </Grid>
        </Card>
      );
    })}
    {draftPlans &&
      draftPlans.map((draftPlan: DraftPlanInfoItem, index) => {
        const { data = {}, type, lastPath, user } = draftPlan;

        const clientName = user?.fullName
          ? user?.fullName
          : data?.users?.length && data?.users[0]?.firstName && data?.users[0]?.lastName
          ? `${data?.users[0]?.firstName} ${data?.users[0]?.lastName}`
          : "New Client";
        const clientEmail = user?.email
          ? user?.email
          : data?.users[0]?.email ?? "--";

        return (
          <Card
            // eslint-disable-next-line react/no-array-index-key
            key={`${clientName}_${index}`}
            sx={{
              py: "1.25rem",
              px: "1rem"
            }}
          >
            <Grid container gridTemplateColumns="1fr 2fr">
              <Grid item xs={6}>
                <Typography>Name:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{clientName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Email:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%"
                  }}
                >
                  {clientEmail ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Plan Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{startCase(camelCase(type))}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Status:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Chip
                  size="small"
                  label="Draft"
                  color="info"
                  sx={{
                    borderRadius: "0.25rem",
                    fontWeight: "bold",
                    fontSize: "0.75rem",
                    color: "#ffffff"
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Updated:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{lastPath}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Your Role:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Financial Advisor</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Permissions:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>--</Typography>
              </Grid>
            </Grid>
          </Card>
        );
      })}

    {invitations &&
      invitations.map((invite: Invitation) => {
        const { clientName, token, recommendedPlan, clientEmail } =
          invite;

        return (
          <Card
            key={token}
            sx={{
              py: "1.25rem",
              px: "1rem"
            }}
          >
            <Grid container gridTemplateColumns="1fr 2fr">
              <Grid item xs={6}>
                <Typography>Name:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{clientName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Email:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%"
                  }}
                >
                  {clientEmail ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Plan Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {recommendedPlan
                    ? startCase(camelCase(recommendedPlan))
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Status:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Chip
                  size="small"
                  label="Invited"
                  color="warning"
                  sx={{
                    borderRadius: "0.25rem",
                    fontWeight: "bold",
                    fontSize: "0.75rem",
                    color: "#ffffff"
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Updated:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>--</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Your Role:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Financial Advisor</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Permissions:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>--</Typography>
              </Grid>
              <Grid item xs={12}>
                <ClientLink token={token} />
              </Grid>
            </Grid>
          </Card>
        );
      })}
  </Box>
);

export default SharedPlanCardLayout;
