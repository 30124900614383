import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormattedMessage } from "react-intl";

import { AssetType } from "@api/__generated__/globalTypes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import ImportAssetsModal from "@pages/EstatePlanView/Partials/components/ImportAssetsModal";

import AddAssetsModal from "../../../components/AddAssetModal";
import { useAssets } from "../utils/useAssets";
import { useAssetReviewItems } from "../utils/useAssetReviewItems";
import { AssetViewProps } from "../types";

import AssetsPartial from "./AssetsPartial";

const TrustAssetView: React.FC<AssetViewProps> = ({
  lastWill: userLastWill,
  assets = []
}) => {
  const { fundedAssets, notFundedAssets, canEdit } = useAssets({
    assets,
    excludeAssets: [
      AssetType.CASH,
      AssetType.PERSONAL_PROPERTY_BALANCE,
      AssetType.ESTATE_BALANCE
    ]
  });

  const { trust } = useMemberPlansState();

  const fundedAssetReviewItems = useAssetReviewItems(
    fundedAssets,
    true // showIsFunded
  );
  const notFundedAssetReviewItems = useAssetReviewItems(
    notFundedAssets,
    true // showIsFunded
  );

  return (
    <>
      {fundedAssetReviewItems.length === 0 &&
        notFundedAssetReviewItems.length === 0 &&
        trust?.isOwner && (
          <>
            <Typography variant="h3" textAlign="center">
              <InfoOutlinedIcon />
            </Typography>
            <Typography variant="body1" textAlign="center">
              <FormattedMessage defaultMessage="You haven't added any assets to your plan yet. Add an asset by clicking the 'Add Asset' button below." />
            </Typography>
          </>
        )}
      {fundedAssetReviewItems.length === 0 &&
        notFundedAssetReviewItems.length === 0 &&
        !trust?.isOwner && (
          <>
            <Typography variant="h3" textAlign="center">
              <InfoOutlinedIcon />
            </Typography>
            <Typography variant="body1" textAlign="center">
              <FormattedMessage defaultMessage="No asset information added to this plan yet." />
            </Typography>
          </>
        )}
      {fundedAssetReviewItems.length === 0 &&
        notFundedAssetReviewItems.length > 0 && (
          <>
            <AssetsPartial
              canEdit={canEdit}
              reviewItems={fundedAssetReviewItems}
              title={
                <FormattedMessage defaultMessage="Assets Funded In Your Revocable Living Trust" />
              }
            />
            <Typography variant="h3" textAlign="center">
              <InfoOutlinedIcon />
            </Typography>
            <Typography variant="body1" textAlign="center">
              <FormattedMessage defaultMessage="You haven't funded any assets into your trust yet." />
            </Typography>
          </>
        )}
      {fundedAssetReviewItems.length > 0 && (
        <AssetsPartial
          canEdit={canEdit}
          reviewItems={fundedAssetReviewItems}
          title={
            <FormattedMessage defaultMessage="Assets Funded In Your Revocable Living Trust" />
          }
          description={
            <FormattedMessage defaultMessage="These are assets titled in the name of your revocable trust." />
          }
        />
      )}
      {notFundedAssetReviewItems.length > 0 && (
        <AssetsPartial
          canEdit={canEdit}
          reviewItems={notFundedAssetReviewItems}
          title={
            <FormattedMessage defaultMessage="Assets Not Yet Funded to Your Trust" />
          }
          description={
            <FormattedMessage defaultMessage="You don't have to fund all your assets into the trust right away, but you should make funding the trust a priority over the next few weeks in order to get the most out of your Trust." />
          }
        />
      )}
      {userLastWill && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <AddAssetsModal lastWill={userLastWill} showIsFunded />
          <ImportAssetsModal lastWill={userLastWill} showIsFunded />
        </Box>
      )}
    </>
  );
};

export default TrustAssetView;
