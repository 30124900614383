import { Box, Button, Grid, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { FileType } from "@api/__generated__/globalTypes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { LAST_WILL_HEALTHCARE_ROUTES } from "@pages/LastWill/sections/Healthcare/consts";
import { ROUTES } from "@setup/consts/routes";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import Image from "@components/Image";
import AHCDImage from "@assets/images/docIcons/DocumentIconAHCD.png";
import { File } from "@api/files/__generated__/File";
import { User } from "@api/me/__generated__/User";
import { HEALTHCARE_MEDICAL_POA_ROUTES } from "@pages/Healthcare/sections/medicalPOA/consts";
import { HEALTHCARE_ROUTES } from "@pages/Healthcare/consts";

import { useSlideActions } from "../hooks";
import { getLinkFullUrl } from "../utils";

const HealthCareSlide = () => {
  const { trust, lastWill } = useMemberPlansState();

  const data = useMemo(() => {
    let planFile;
    let editLink;
    let activePlan;
    let primaryDrafter: User;

    if (trust) {
      primaryDrafter = trust?.plan?.primaryDrafter;

      planFile = trust?.plan?.files?.find(
        (f: File) =>
          f.type === FileType.HEALTH_CARE_DOCUMENT &&
          f.ownerId === primaryDrafter.id
      );

      editLink = getLinkFullUrl([
        ROUTES.Healthcare,
        HEALTHCARE_ROUTES.MedicalPOA,
        HEALTHCARE_MEDICAL_POA_ROUTES.Preview
      ]);

      activePlan = trust;
    } else {
      primaryDrafter = lastWill?.plan?.primaryDrafter;
      planFile = lastWill?.plan?.files?.find(
        (f: File) =>
          f.type === FileType.HEALTH_CARE_DOCUMENT &&
          f.ownerId === primaryDrafter.id
      );

      editLink = getLinkFullUrl([
        ROUTES.LastWill,
        LAST_WILL_SECTION_ROUTES.Healthcare,
        LAST_WILL_HEALTHCARE_ROUTES.Preview
      ]);

      activePlan = lastWill;
    }

    return {
      primaryDrafter,
      planFile,
      editLink,
      activePlan
    };
  }, [trust, lastWill]);

  const actions = useSlideActions({
    planFile: data.planFile,
    editLink: data.editLink,
    memberPlan: data.activePlan
  });

  const navigate = useNavigate();

  const handleGoToEdit = useCallback(() => {
    if (data.editLink) {
      navigate(data.editLink);
    }
  }, [data.editLink, navigate]);

  const renderTop = useCallback(
    () =>
      (trust || lastWill) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography align="center" variant="h5">
            <FormattedMessage defaultMessage="Advance Health Care Directive" />
          </Typography>
          <Typography align="center" variant="h5">
            <FormattedMessage
              defaultMessage="of {name}"
              values={{
                name: data.primaryDrafter?.fullName ?? ""
              }}
            />{" "}
          </Typography>
          <Box sx={{ mt: "1rem" }}>
            <Image
              height="64px"
              width="64px"
              src={AHCDImage}
              alt="Advance Healthcare Directive icon"
            />
          </Box>
          <Typography mt="1.2rem" align="center" fontSize="1rem">
            <FormattedMessage
              defaultMessage="Specify your health care preferences in advance so that your
            loved ones and medical team abide by your wishes if
            you’re later unable to communicate them."
            />
          </Typography>
        </Box>
      ),
    [trust, lastWill, data.primaryDrafter]
  );

  if (
    !trust?.primaryDrafterLastWill.healthcare &&
    !lastWill?.primaryDrafterLastWill?.healthcare
  ) {
    return (
      <>
        {renderTop()}
        <Typography mt="1.5rem" align="center" fontSize="15px">
          <FormattedMessage defaultMessage="You haven’t created an Advance Healthcare Directive yet." />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleGoToEdit}
            variant="contained"
            sx={{
              flex: "1",
              borderWidth: "2px",
              fontWeight: "500",
              fontSize: "14px",
              marginTop: "1.1rem",
              cursor: "pointer"
            }}
          >
            <FormattedMessage defaultMessage="Create Advanced Healthcare Directive" />
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      {renderTop()}
      <Grid
        container
        item
        xs={12}
        mt="1.5rem"
        justifyContent="space-around"
      >
        {actions.map(({ icon, label }) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer"
            }}
            key={label}
          >
            {icon}
            <Typography fontSize="0.875rem">{label}</Typography>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default HealthCareSlide;
