import { Auth } from "aws-amplify";

const getSessionToken = async () => {
  try {
    const session = await Auth.currentSession();

    return session.getAccessToken();
  } catch {
    return null;
  }
};

export default getSessionToken;
