import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Divider,
  FormControl,
  TextField,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ISignUpResult } from "amazon-cognito-identity-js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import { useSendMarketingDataMutation } from "@api/marketing/hooks";
import AuthLayout from "@components/AuthLayout";
import ButtonsList from "@components/ButtonsList";
import useCognitoAuth from "@hooks/useCognitoAuth";
import { ROUTES } from "@setup/consts/routes";
import PageTitle from "@components/PageTitle";

import {
  useButtonsListItem,
  useSignUpFormValidationSchema
} from "./hooks";
import { SignUpFormDataProps } from "./types";
import SignUpSuccess from "./partials/SignUpSuccess";

const AdvisorSignUpPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const buttonsListItems = useButtonsListItem(true);
  const theme = useTheme();
  const validationSchema = useSignUpFormValidationSchema();
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting, errors },
    getValues
  } = useForm<SignUpFormDataProps>({
    resolver: yupResolver(validationSchema)
  });
  const { signUp } = useCognitoAuth();

  const [callMarketingData] = useSendMarketingDataMutation();
  const sendAdvisorData = useCallback(
    (email: string): Promise<boolean> =>
      callMarketingData({
        variables: {
          email,
          data: {
            role: "FINANCIAL_ADVISOR"
          },
          klaviyoListId: "RFdexc"
        }
      }).then((response) => !!response.data?.sendMarketingData),
    [callMarketingData]
  );

  const createAccount = async ({
    email,
    password
  }: SignUpFormDataProps): Promise<ISignUpResult> => {
    try {
      const signUpResult = await signUp(email, password, true);

      sendAdvisorData(email);

      return signUpResult;
    } catch (error) {
      const formError = error as Error;

      if (formError.name === "UsernameExistsException") {
        navigate(ROUTES.SignInExistingAccount);
      }

      return Promise.reject(error);
    }
  };

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          defaultMessage: "Advisor Sign Up | JIC Estates"
        })}
      />
      <AuthLayout
        title={
          isSubmitSuccessful
            ? intl.formatMessage({
                defaultMessage: "Almost there!"
              })
            : intl.formatMessage({
                defaultMessage:
                  "Sign up for JIC Estates Advisors — Free Forever"
              })
        }
        maxWidth={isSubmitSuccessful ? "md" : undefined}
        isFullWidth={isSubmitSuccessful}
        isLoading={isSubmitting}
        subTitle={
          isSubmitSuccessful
            ? ""
            : intl.formatMessage({
                defaultMessage:
                  "Get your clients the #1 rated estate planning platform."
              })
        }
      >
        {isSubmitSuccessful ? (
          <SignUpSuccess email={getValues().email} />
        ) : (
          <Box>
            <ButtonsList items={buttonsListItems} />
            <Divider
              sx={{
                width: "100%",
                mt: 2.5,
                color: theme.palette.grey["400"]
              }}
            >
              <FormattedMessage defaultMessage="or" />
            </Divider>
            <Typography
              variant="body2"
              sx={{
                textAlign: {
                  xs: "left",
                  sm: "center"
                }
              }}
              m="1.25rem 0"
            >
              <FormattedMessage defaultMessage="Sign up using your work email and creating a new password." />
            </Typography>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%"
              }}
              onSubmit={handleSubmit(createAccount)}
            >
              <FormControl sx={{ width: "100%", mb: "1.25rem" }}>
                <TextField
                  label={
                    <FormattedMessage defaultMessage="Work Email Address" />
                  }
                  variant="standard"
                  type="email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...register("email", { required: true })}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", mb: "1.25rem" }}>
                <TextField
                  label={
                    <FormattedMessage defaultMessage="Password" />
                  }
                  variant="standard"
                  type="password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  {...register("password", { required: true })}
                />
              </FormControl>
              <Button variant="contained" size="medium" type="submit">
                <FormattedMessage defaultMessage="Create account" />
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "2rem"
              }}
            >
              <Typography
                variant="body2"
                textAlign="center"
                mb="2rem"
              >
                <FormattedMessage defaultMessage="Already have an account?" />
              </Typography>
              <Button
                variant="outlined"
                href={ROUTES.SignIn}
                color="inherit"
                size="medium"
                sx={{ fontSize: "0.875rem" }}
              >
                <FormattedMessage defaultMessage="Log in" />
              </Button>
            </Box>
          </Box>
        )}
      </AuthLayout>
    </>
  );
};

export default AdvisorSignUpPage;
