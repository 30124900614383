import React from "react";
import { ReactSVG } from "react-svg";

import { SvgImageProps } from "./types";

const SvgImage: React.FC<SvgImageProps> = ({
  src,
  alt,
  className,
  content
}) => {
  if (content) {
    return (
      <div
        title={alt}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }

  if (!src || typeof src !== "string" || !src.includes(".svg")) {
    return null;
  }

  return <ReactSVG {...{ src, className }} title={alt} role="img" />;
};

export default SvgImage;
