import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { PetPlanValues } from "@pages/PetPlan/types";
import getVetInfo from "@utils/getVetInfo";
import { useFormField } from "@components/FormFields/hooks";
import { ROUTES } from "@setup/consts/routes";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ITERATION_QUERY_KEY } from "@providers/planForm/consts";

import { PET_CARE_ROUTES } from "../../consts";

import getCareInstructions from "./getCareInstructions";

export const usePrimaryDrafterPetCareReviewTab = ({
  values
}: {
  values: PetPlanValues;
}) => {
  const { formatMessage } = useIntl();
  const { pets, sameVeterinarian, sameCareInstructions } = values;

  const { value: petAddMedicalNote } = useFormField(
    "petAddMedicalNote"
  );

  const iterationQueryKey = ITERATION_QUERY_KEY;
  const careInstructionsPath = getLinkFullUrl([
    ROUTES.PetPlan,
    PET_SECTION_ROUTES.Care,
    PET_CARE_ROUTES.CareInstructionsLoop,
    PET_CARE_ROUTES.Food,
    `?${iterationQueryKey}=1`
  ]);
  const vetInfoPath = getLinkFullUrl([
    ROUTES.PetPlan,
    PET_SECTION_ROUTES.Care,
    PET_CARE_ROUTES.VetLoop,
    PET_CARE_ROUTES.Vet,
    `?${iterationQueryKey}=1`
  ]);

  // TODO: @Haimi -- can we make this nested items with each pet for Food, Exercise, Vet, Medical Note?
  const petsCareInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Care Instructions"
            }),
            href: careInstructionsPath,
            isFullWidth: true,
            items: getCareInstructions(pets, sameCareInstructions)
          }
        : undefined,
    [pets, sameCareInstructions, careInstructionsPath, formatMessage]
  );

  const vetInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets.length
        ? {
            title: formatMessage({
              defaultMessage: "Vet Information"
            }),
            href: vetInfoPath,
            isFullWidth: true,
            items: getVetInfo(pets, sameVeterinarian)
          }
        : undefined,
    [pets, sameVeterinarian, vetInfoPath, formatMessage]
  );

  const petsMedicalInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length && petAddMedicalNote
        ? {
            title: formatMessage({
              defaultMessage: "Medical Information"
            }),
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.Care,
              PET_CARE_ROUTES.Medical
            ]),
            isFullWidth: true,
            items: pets.map((pet) => [
              pet.medicalInstructions
                ? `${pet.name}: ${pet.medicalInstructions}`
                : ``
            ])
          }
        : undefined,
    [pets, petAddMedicalNote, formatMessage]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [petsCareInfo, vetInfo, petsMedicalInfo].filter(
        (item): item is ReviewItem => Boolean(item)
      ),
    [petsCareInfo, vetInfo, petsMedicalInfo]
  );

  return items;
};
