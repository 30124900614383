import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { LAST_WILL_GUARDIANS_ROUTES } from "@pages/LastWill/sections/Guardians/consts";
import { LAST_WILL_ALL_ABOUT_YOU_ROUTES } from "@pages/LastWill/sections/AllAboutYou/consts";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { TRUST_ALL_ABOUT_YOU_ROUTES } from "@pages/Trust/sections/AllAboutYou/consts";
import { TRUST_GUARDIANS_ROUTES } from "@pages/Trust/sections/Guardians/consts";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { ROUTES } from "@setup/consts/routes";

import {
  getLinkFullUrl,
  getChildPrimaryGuardianInfo
} from "../utils";

export const usePartnerGuardiansReview = () => {
  const { formatMessage } = useIntl();
  const { trust, lastWill } = useMemberPlansState();

  const canEdit = !!trust?.canEdit || !!lastWill?.canEdit;

  const childs = trust
    ? trust.partnerLastWill?.childs
    : lastWill?.partnerLastWill?.childs;

  const partnerType = trust
    ? trust.plan.partnerType
    : lastWill?.plan.partnerType;

  const partnerAsChildGuardian = trust
    ? trust.partnerLastWill?.partnerAsChildGuardian
    : lastWill?.primaryDrafterLastWill.partnerAsChildGuardian;

  const childrenGuardiansInfo = useMemo<
    ReviewItem | undefined
  >(() => {
    if (!childs?.length) {
      return {
        title: formatMessage({
          defaultMessage: "Children's Guardian Info"
        }),
        canEdit,
        href: trust
          ? getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.AllAboutYou,
              TRUST_ALL_ABOUT_YOU_ROUTES.ChildrenDetails
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.AllAboutYou,
              LAST_WILL_ALL_ABOUT_YOU_ROUTES.ChildrenDetails
            ]),
        isFullWidth: true,
        items: [
          [
            formatMessage({
              defaultMessage:
                "Since children aren't in the mix, you did not nominate any child guardians. If that changes later, you can always come back here and edit your estate plan."
            })
          ]
        ]
      };
    }

    return {
      title: formatMessage({
        defaultMessage: "Children's Guardian Info"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Guardians,
            TRUST_GUARDIANS_ROUTES.DesignateGuardians
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Guardians,
            LAST_WILL_GUARDIANS_ROUTES.DesignateGuardians
          ]),
      isFullWidth: true,
      items: childs?.map((child) => {
        const primaryGuardians =
          child?.guardians?.filter(
            (guardian) => !guardian?.isBackup
          ) ?? [];

        const backupGuardians =
          child?.guardians?.filter(
            (guardian) => guardian?.isBackup
          ) ?? [];

        const prohibitedGuardians = child?.prohibitedGuardians ?? [];

        return [
          child?.user?.fullName ?? "",
          getChildPrimaryGuardianInfo(
            primaryGuardians,
            partnerAsChildGuardian,
            partnerType
          ),
          backupGuardians?.length
            ? `Backup Guardians: ${backupGuardians
                .map((backup) => backup?.guardian?.fullName)
                .join(", ")}`
            : `Backup Guardians: 🚫 You chose not to nominate any backup guardians.`,
          prohibitedGuardians.filter((guardian) =>
            Boolean(guardian?.reason)
          )?.length
            ? `Improper Guardians: ${prohibitedGuardians
                .map((person) => person?.guardian?.fullName)
                .join(", ")}`
            : ``
        ].filter((item): item is string => Boolean(item));
      })
    };
  }, [
    trust,
    childs,
    partnerAsChildGuardian,
    formatMessage,
    canEdit,
    partnerType
  ]);

  const items = useMemo<ReviewItem[]>(
    () =>
      [childrenGuardiansInfo].filter((item): item is ReviewItem =>
        Boolean(item)
      ),
    [childrenGuardiansInfo]
  );

  return items;
};
