import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import LockIcon from "@mui/icons-material/Lock";

interface TabRestrictedProps {
  tabName: string;
  message: string;
}

const TabRestrictedMessage: React.FC<TabRestrictedProps> = ({
  tabName,
  message
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      alignItems: "center"
    }}
  >
    <LockIcon
      sx={{
        display: "flex",
        justifyContent: "center",
        mx: "auto",
        fontSize: "2.875rem"
      }}
    />
    <Typography fontWeight="bold" fontSize="1.375rem">
      {`${tabName} locked`}
    </Typography>
    <Typography>
      <FormattedMessage
        defaultMessage="{message}"
        values={{ message }}
      />
    </Typography>
  </Box>
);

export default TabRestrictedMessage;
