import { useIntl } from "react-intl";
import TimerIcon from "@mui/icons-material/Timer";

import TutorialLayout from "@pages/Tutorial/partials/TutorialLayout";
import { TUTORIAL_ROUTES } from "@pages/Tutorial/consts";
import { ROUTES } from "@setup/consts/routes";

const TimingPage: React.FC = () => {
  const intl = useIntl();

  return (
    <TutorialLayout
      heading={intl.formatMessage({
        defaultMessage: "Timing"
      })}
      description={intl.formatMessage({
        defaultMessage:
          "Our members typically complete their Last Will in 10-15 minutes. We encourage you to take your time. Unlike a lawyer, we don't charge by the hour 😉."
      })}
      title={intl.formatMessage(
        {
          defaultMessage:
            "tutorial ({currentStep} of {allStepsCount})"
        },
        {
          allStepsCount: 5,
          currentStep: 2
        }
      )}
      progress={60}
      withSkipButton
      nextButtonProps={{
        href: `${ROUTES.Tutorial}/${TUTORIAL_ROUTES.QuickAction}`,
        label: intl.formatMessage({
          defaultMessage: "Continue"
        })
      }}
    >
      <TimerIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
    </TutorialLayout>
  );
};

export default TimingPage;
