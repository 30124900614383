import Redeem from "@mui/icons-material/Redeem";
import House from "@mui/icons-material/House";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DiamondIcon from "@mui/icons-material/Diamond";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";

import { useSpecificGiftsWorkContent } from "../utils";

const PartnerSpecificGiftsStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter, partner]
  } = values;

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `We'll now review any specific gifts that ${partner.firstName} wants to make.`,
        muiIconHeader: (
          <>
            <House /> <DirectionsCarIcon />
            <DiamondIcon />
            <Redeem />
          </>
        ),
        subText:
          "Examples of specific gifts might include specifying who should receive your house, car, or jewelry. Any specific gifts you make will be distributed prior to your residuary estate.",
        moreInfoModalProps: {
          title: "How Specific Gifts Work",
          togglerLabel: "Learn more.",
          content: useSpecificGiftsWorkContent()
        }
      }}
    />
  );
};

export default PartnerSpecificGiftsStep;
