import { ExternalLinks } from "@setup/consts/articleLinks";
import MultiSelectArchetype from "@components/archetypes/MultiSelect";

const OrganDonationListStep: React.FC = () => (
  <MultiSelectArchetype
    name="users.0.healthcare.preferences.specifiedOrgans"
    stepLayout={{
      title: `Ok. Which organs, tissues, or parts do you wish to donate (if possible)?`,
      subText: `Select all that you wish to donate.`,
      moreInfoModalProps: {
        title: "Choosing Which Organs to Donate",
        togglerLabel: "Learn more",
        content: (
          <p>
            Whether and which organs you donate is a personal
            decision, but every offer helps close the gap between
            organs donated and patients waiting on the national
            transplant list. A single donor may save as many as 8
            lives by donating multiple organs.
            <br />
            <br />
            The below graph summarizes organs donated and patients
            waiting for a transplant.
            <br />
            <br />
            <img
              src={ExternalLinks.organDonorStatistics}
              alt="Organs donated and patients waiting for transplant"
              style={{ width: "100%" }}
            />
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    options={[
      { label: "Kidney", value: "kidney" },
      { label: "Liver", value: "liver" },
      { label: "Heart", value: "heart" },
      { label: "Lung", value: "lung" },
      { label: "Pancreas", value: "pancreas" },
      { label: "Intestines", value: "intestines" },
      { label: "Body tissues", value: "body tissues" }
    ]}
  />
);

export default OrganDonationListStep;
