import { makeMutationHook } from "@utils/apollo-utils";

import {
  CREATE_FILE,
  CREATE_FILE_UPLOAD_URL,
  DELETE_FILE,
  REGENERATE_PLAN_DOCUMENTS,
  UPDATE_FILE
} from "./mutations";
import {
  CreateFile,
  CreateFileVariables
} from "./__generated__/CreateFile";
import {
  CreateFileUploadUrl,
  CreateFileUploadUrlVariables
} from "./__generated__/CreateFileUploadUrl";
import {
  DeleteFile,
  DeleteFileVariables
} from "./__generated__/DeleteFile";
import {
  RegeneratePlanDocuments,
  RegeneratePlanDocumentsVariables
} from "./__generated__/RegeneratePlanDocuments";
import {
  UpdateFile,
  UpdateFileVariables
} from "./__generated__/UpdateFile";

export const useCreateFileUploadUrlMutation = makeMutationHook<
  CreateFileUploadUrl,
  CreateFileUploadUrlVariables
>(CREATE_FILE_UPLOAD_URL);

export const useCreateFileMutation = makeMutationHook<
  CreateFile,
  CreateFileVariables
>(CREATE_FILE);

export const useDeleteFileMutation = makeMutationHook<
  DeleteFile,
  DeleteFileVariables
>(DELETE_FILE);

export const useUpdateFileMutation = makeMutationHook<
  UpdateFile,
  UpdateFileVariables
>(UPDATE_FILE);

export const useRegeneratePlanDocuments = makeMutationHook<
  RegeneratePlanDocuments,
  RegeneratePlanDocumentsVariables
>(REGENERATE_PLAN_DOCUMENTS);
