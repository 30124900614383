import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { NEXT_ITERATION_RULE } from "@providers/planForm/consts";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";

import { PET_GUARDIANS_ROUTES } from "../Guardians/consts";

import { PET_ALL_ABOUT_YOU_ROUTES } from "./consts";
import SectionsPreviewStep from "./steps/Preview";
import BasicsStep from "./steps/Basics";
import PetDetailsStep from "./steps/PetDetails";
import PhysicalTraitsStep from "./steps/PhysicalTraits/PhysicalTraits";
import InterstitialStep from "./steps/Interstitial";
import ReviewStep from "./steps/Review";

const allAboutYouSteps: PlanFormStep[] = [
  {
    path: PET_ALL_ABOUT_YOU_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: PET_ALL_ABOUT_YOU_ROUTES.Basics
  },
  {
    path: PET_ALL_ABOUT_YOU_ROUTES.Basics,
    element: <BasicsStep />,
    rules: PET_ALL_ABOUT_YOU_ROUTES.Interstitial
  },
  {
    path: PET_ALL_ABOUT_YOU_ROUTES.Interstitial,
    element: <InterstitialStep />,
    rules: PET_ALL_ABOUT_YOU_ROUTES.PetDetails
  },
  {
    path: PET_ALL_ABOUT_YOU_ROUTES.PetDetails,
    element: <PetDetailsStep />,
    rules: PET_ALL_ABOUT_YOU_ROUTES.PhysicalTraitsLoop
  },
  {
    path: PET_ALL_ABOUT_YOU_ROUTES.PhysicalTraitsLoop,
    loop: {
      enabled: true,
      iterationCountSource: "pets"
    },
    children: [
      {
        path: PET_ALL_ABOUT_YOU_ROUTES.PhysicalTraits,
        element: <PhysicalTraitsStep />,
        rules: NEXT_ITERATION_RULE
      }
    ],
    rules: PET_ALL_ABOUT_YOU_ROUTES.Review
  },
  {
    path: PET_ALL_ABOUT_YOU_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${PET_SECTION_ROUTES.Guardians}/${PET_GUARDIANS_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={PET_ALL_ABOUT_YOU_ROUTES.Preview} />
  }
];

export default allAboutYouSteps;
