import { ArrayFormFieldSingleItemProps } from "@components/FormFields/ArrayForm/types";

import PersonDetailsForm from "../../PersonDetailsForm";
import { usePersonDetailsArrayContext } from "../context";

const PersonDetailsItem: React.FC<ArrayFormFieldSingleItemProps> = ({
  prefix,
  index
}) => {
  const {
    personRole,
    isAddressVerifiedModalDisplayed,
    onProceed,
    toggleOff
  } = usePersonDetailsArrayContext();

  return (
    <PersonDetailsForm
      name={prefix}
      {...{ personRole }}
      isAddressWarningDisplayed={
        isAddressVerifiedModalDisplayed && index === 0
      }
      onCancel={toggleOff}
      onProceed={onProceed}
    />
  );
};

export default PersonDetailsItem;
