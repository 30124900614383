import { Grid, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewTabsArchetype from "@components/archetypes/ReviewTabs";
import ReviewBox from "@components/ReviewBox";
import {
  PlanFormContext,
  usePlanForm
} from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

import { usePrimaryDrafterFinalArrangementsReviewTab } from "./Reviews/usePrimaryDrafterFinalArrangementsReviewTab";
import { usePartnerFinalArrangementsReviewTab } from "./Reviews/usePartnerFinalArrangementsReviewTab";

const ReviewTabStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { values } = usePlanForm<LastWillPlanValues>();
  const { type } = useContext(PlanFormContext);

  const {
    users: [primaryDrafter, partner]
  } = values;

  const primaryDrafterInfo =
    usePrimaryDrafterFinalArrangementsReviewTab({
      values,
      type
    });

  const partnerInfo = usePartnerFinalArrangementsReviewTab({
    values,
    type
  });

  const items = useMemo(
    () => [
      {
        label:
          primaryDrafter?.firstName ??
          formatMessage({ defaultMessage: "You" }),
        content: (
          <Grid container spacing={{ xs: 3, md: 5.5 }}>
            {primaryDrafterInfo.map(({ title, ...item }) => (
              <Grid item xs={12} key={title}>
                <ReviewBox {...{ title, ...item }} />
              </Grid>
            ))}
          </Grid>
        )
      },
      {
        label:
          partner?.firstName ??
          formatMessage({ defaultMessage: "Partner" }),
        content: partnerInfo ? (
          <Grid container spacing={{ xs: 3, md: 5.5 }}>
            {partnerInfo.map(({ title, ...item }) => (
              <Grid item xs={12} key={title}>
                <ReviewBox {...{ title, ...item }} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>
            <FormattedMessage defaultMessage="No Partner" />
          </Typography>
        )
      }
    ],
    [
      formatMessage,
      partnerInfo,
      partner.firstName,
      primaryDrafter.firstName,
      primaryDrafterInfo
    ]
  );

  return (
    <ReviewTabsArchetype
      {...{ items }}
      stepLayout={{
        title: `Nice stuff. Here's the recap of what you shared:`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewTabStep;
