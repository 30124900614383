import { gql } from "@apollo/client";

export const CREATE_PAYMENT_CHECKOUT = gql`
  mutation CreatePaymentCheckout(
    $token: String!
    $successReturnPath: String
    $cancelReturnPath: String
  ) {
    createPaymentCheckout(
      token: $token
      successReturnPath: $successReturnPath
      cancelReturnPath: $cancelReturnPath
    ) {
      url
      paymentId
    }
  }
`;
