import { EstateAdminResults } from "@pages/EstateAdminQuiz/types";

import getFedEstateTax from "../estateTax/getFedEstateTax";
import getStateEstateTax from "../estateTax/getStateEstateTax";

const getDefaultTrustResults = (
  isSmallEstate: boolean,
  assetValue: string,
  state: string
): EstateAdminResults => {
  const bulletRecs = [];

  bulletRecs.push(
    <li>
      <strong>Timing.</strong> Trust administration in {state} can
      take several months to upwards of a year, depending on the
      specifics of the Trust.
    </li>
  );
  bulletRecs.push(
    <li>
      <strong>Cost.</strong> The cost to administer a trust in {state}{" "}
      can vary widely based on a number of different factors. Your
      estate administration expert can help estimate the applicable
      cost to this estate.
    </li>
  );
  bulletRecs.push(
    isSmallEstate ? (
      <li>
        <strong>Probate.</strong> You noted that the deceased may have
        owned some assets that were not transferred to the trust
        during their life. Probate may be necessary to transfer those
        assets to the trust.
      </li>
    ) : (
      <li>
        <strong>Probate.</strong> It sounds like the deceased did a
        great job transferring assets to the trust during their life,
        and so probate should not be necessary.
      </li>
    )
  );
  bulletRecs.push(getStateEstateTax(state, assetValue));
  bulletRecs.push(getFedEstateTax(assetValue));

  return {
    recommendation: "Trust Adminstration",
    rationale:
      "Since the deceased had a trust, we’ll look to the trust document to understand the applicable rules on trust administration and distributions.",
    bullets: bulletRecs
  };
};

export default getDefaultTrustResults;
