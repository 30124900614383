import { gql } from "@apollo/client";

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Sanity_ImageWithAlt {
    _key
    _type
    asset {
      url
      metadata {
        dimensions {
          width
          height
        }
      }
      source {
        _key
      }
    }
    alt
  }
`;

export const LINK_FRAGMENT = gql`
  fragment LinkFragment on Sanity_Link {
    _key
    type
    resource {
      ... on Sanity_Page {
        slug {
          current
        }
      }
      ... on Sanity_Post {
        slug {
          current
        }
      }
      ... on Sanity_BlogListingPage {
        slug {
          current
        }
      }
      ... on Sanity_Category {
        slug {
          current
        }
      }
      ... on Sanity_Plan {
        slug {
          current
        }
      }
    }
    url
    media {
      asset {
        url
      }
    }
  }
`;
