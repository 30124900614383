import { useIntl } from "react-intl";
import { Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormAutocomplete from "@components/FormFields/FormAutocomplete";
import { useFiftyStateOptions } from "@hooks/useFiftyStateItems";

import { useStateSelectArchetype } from "./hooks";
import { StateSelectArchetypeProps } from "./types";

const StateSelectArchetype: React.FC<StateSelectArchetypeProps> = ({
  name,
  onSubmit: onFormSubmit,
  stepLayout
}) => {
  const intl = useIntl();
  const { controls, goBack, onSubmit } = useStateSelectArchetype(
    name,
    onFormSubmit
  );

  return (
    <StepLayout {...stepLayout}>
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <Stack spacing={4}>
            <FormAutocomplete
              textFieldProps={{
                label: intl.formatMessage({
                  defaultMessage: "State"
                })
              }}
              name={name}
              options={useFiftyStateOptions()}
            />
          </Stack>
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default StateSelectArchetype;
