import { Asset } from "@api/plans/__generated__/Asset";
import { AssetType } from "@api/__generated__/globalTypes";
import buildAddress from "@utils/buildAddress";

const getAssetNickname = (asset: Asset): string | null => {
  switch (asset.type) {
    case AssetType.PERSONAL_PROPERTY:
    case AssetType.FINANCIAL_ACCOUNT:
    case AssetType.BUSINESS_INTEREST:
    case AssetType.OTHER:
      return asset.name;
    case AssetType.REAL_ESTATE:
      return buildAddress(asset.address);
    default:
      return asset.name || null;
  }
};

export default getAssetNickname;
