import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";

const PowersMakeGiftsLimitTypeStep: React.FC = () => {
  const { setValue: setGiftLimitAmountValue } = useFormField(
    "users.0.poa.giftRecipients.limitAmount"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const giftLimitType = get(
        data,
        "users.0.poa.giftRecipients.limitType"
      );

      if (giftLimitType !== "Limit up to a certain amount") {
        setGiftLimitAmountValue(null);
      }
    },
    [setGiftLimitAmountValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How do you want to limit the amount your agent may gift to an individual or entity?`
      }}
      name="users.0.poa.giftRecipients.limitType"
      items={[
        {
          value: "Limit up to the Federal Gift Tax Threshold",
          label:
            "Limit up to the Federal Gift Tax Threshold ($18,000 per individual in 2024)"
        },
        {
          value: "Limit up to a certain amount",
          label: "Limit up to a certain amount"
        },
        { value: "none", label: "No limit" }
      ]}
      {...{ onSubmit }}
    />
  );
};

export default PowersMakeGiftsLimitTypeStep;
