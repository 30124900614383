import { Alert, Box, Button, Snackbar } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useCallback, useState } from "react";
import { Link as LinkIcon } from "@mui/icons-material";

import useToggle from "@hooks/useToggle";
import Modal from "@components/Modal";
import useUserContext from "@providers/user/hooks";
import { useEmailSentState } from "@providers/emailSentState/context";

import EmailSentMessageModal from "../ShareTab/EmailSentMessageModal";
import { useLoggedInUserName } from "../../utils";

import InviteClientForm from "./InviteClientForm";

import { InviteClientProps } from "./types";

const InviteClient: React.FC<InviteClientProps> = ({ refetch }) => {
  const { user } = useUserContext();
  const loggedInName = useLoggedInUserName();

  const [openResend, { toggleOn, toggleOff }] = useToggle();
  const { setShowEmailSentMessage } = useEmailSentState();

  const [ snackbarOpen, setSnackbarOpen ] = useState(false);

  const onEmailInvite = useCallback(() => {
    toggleOff();
    setShowEmailSentMessage(true);
    refetch();
  }, [refetch, setShowEmailSentMessage, toggleOff]);

  const onCreateAndCopy = useCallback(() => {
    toggleOff();
    setSnackbarOpen(true);
    refetch();
  }, [refetch, toggleOff]);

  return (
    <Box style={{ position: "relative" }}>
      <Button variant="contained" onClick={toggleOn}>
        <FormattedMessage defaultMessage="+ Invite Client" />
      </Button>
      <EmailSentMessageModal />
      <Modal
        fullWidth
        open={openResend}
        onClose={toggleOff}
        content={
          <InviteClientForm
            {...{
              advisorName: user?.fullName ?? loggedInName ?? "",
              onEmailInvite,
              onCreateAndCopy
            }}
          />
        }
      />
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={1500}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        sx={{
          position: "absolute",
          bottom: "100%",
          left: "0%",
          transform: "translateY(-10px)"
        }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          sx={{ width: "100%", alignItems: "center" }}
        >
          <LinkIcon sx={{ transform: "rotate(-45deg)" }} />
          Copied to clipboard
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InviteClient;
