import {
  AppBar,
  Box,
  Typography,
  CircularProgress,
  Container,
  useMediaQuery
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import useToggle from "@hooks/useToggle";
import useUserContext from "@providers/user/hooks";
import useArrowAnimationContext from "@providers/arrowAnimation/hooks";

import * as Styled from "./styles";
import { HeaderProps } from "./types";
import Burger from "./partials/BurgerMenu";
import { useHeaderMenu } from "./hooks";
import Logo from "./partials/Logo";
import Dropdown from "./partials/Dropdown";
import UserActions from "./partials/UserActions";
import Arrow from "./partials/Arrow";

const Header: React.FC<HeaderProps> = ({
  navItems = [],
  dashboardItems = [],
  logoMobile,
  logoDesktop,
  isLoading,
  showAssistant
}) => {
  const { cognitoUser } = useUserContext();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { showLeftArrow } = useArrowAnimationContext();
  const { mainMenuItems, mobileMainMenuItems, isMenuVisible } =
    useHeaderMenu(navItems, dashboardItems, !!cognitoUser);
  const [openedSubMenu, setOpenedSubMenu] = useState<
    string | undefined
  >(mobileMainMenuItems[0]?.id);
  const [isNavOpen, { toggle }] = useToggle({
    onToggle: useCallback(() => {
      setOpenedSubMenu(mobileMainMenuItems[0]?.id);
    }, [mobileMainMenuItems])
  });

  const burgerToggler = (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        position: "relative"
      }}
    >
      <Burger open={isNavOpen} setOpen={toggle} />
      {showLeftArrow && <Arrow isReversed />}
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#E8E8E8",
        color: "#575757",
        padding: "0.35rem 0"
      }}
    >
      <Container
        sx={{
          maxWidth: "calc(81rem + (1.25rem * 2))",
          padding: "0 1.25rem"
        }}
      >
        <Styled.TopBar
          disableGutters
          withGridOnMobile={!!cognitoUser}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  gridArea: "logo"
                }}
              >
                <Logo {...{ logoMobile, logoDesktop }} />
                {isDesktop && isMenuVisible && (
                  <Styled.LinksContainer>
                    {mainMenuItems?.map(
                      ({
                        href,
                        label,
                        id,
                        items,
                        isExternal,
                        isDisabled
                      }) => (
                        <Styled.LinkItem key={id}>
                          <Dropdown
                            {...{
                              label,
                              href,
                              items,
                              isExternal,
                              isDisabled
                            }}
                          />
                        </Styled.LinkItem>
                      )
                    )}
                    {showLeftArrow && isDesktop && (
                      <Arrow isReversed />
                    )}
                  </Styled.LinksContainer>
                )}
                {!isDesktop && (
                  <Styled.MobileNav isOpened={isNavOpen}>
                    <Styled.MobileLinksContainer>
                      {mobileMainMenuItems?.map(
                        ({
                          id,
                          label,
                          href,
                          items,
                          isExternal,
                          isDisabled,
                          onClick
                        }) => (
                          <Styled.MobileLinkItem key={id}>
                            <Styled.MobileMenuLink
                              {...{ href, isExternal }}
                              isOpened={id === openedSubMenu}
                              disabled={isDisabled}
                              onClick={(event): void => {
                                if (onClick) {
                                  onClick();
                                  toggle();
                                } else {
                                  if (
                                    id === openedSubMenu ||
                                    !items ||
                                    !items.length
                                  ) {
                                    return;
                                  }

                                  event.preventDefault();

                                  setOpenedSubMenu(id);
                                }
                              }}
                            >
                              {label}
                              {items && !!items.length && (
                                <ChevronRightIcon className="chevron" />
                              )}
                            </Styled.MobileMenuLink>
                            {items && !!items.length && (
                              <Styled.MobileSubMenuList
                                isOpened={id === openedSubMenu}
                              >
                                {items.map(
                                  ({
                                    id: subId,
                                    label: subLabel,
                                    href: subHref,
                                    onClick: subOnClick,
                                    isExternal: isSubExternal
                                  }) => (
                                    <li key={subId}>
                                      <Styled.DesktopSubMenuLink
                                        href={subHref}
                                        onClick={subOnClick}
                                        isExternal={isSubExternal}
                                      >
                                        {subLabel}
                                      </Styled.DesktopSubMenuLink>
                                    </li>
                                  )
                                )}
                              </Styled.MobileSubMenuList>
                            )}
                          </Styled.MobileLinkItem>
                        )
                      )}
                    </Styled.MobileLinksContainer>
                  </Styled.MobileNav>
                )}
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: cognitoUser
                    ? "flex"
                    : { xs: "flex", md: "none" },
                  justifyContent: "flex-end",
                  gridArea: "burger"
                }}
              >
                {cognitoUser && burgerToggler}
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: cognitoUser
                    ? "flex"
                    : { xs: "flex", md: "none" },
                  gridArea: "user",
                  justifyContent: "flex-end"
                }}
              >
                {cognitoUser ? (
                  <UserActions
                    {...{ dashboardItems }}
                    showAssistant={showAssistant}
                  />
                ) : (
                  burgerToggler
                )}
              </Box>
            </>
          )}
        </Styled.TopBar>
      </Container>
    </AppBar>
  );
};

export default Header;
