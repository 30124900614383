import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { BlogLinks } from "@setup/consts/articleLinks";

const PartnerPowersStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const { value: backupAgents } = useFormField("backupAgents", "");
  const agentText =
    backupAgents?.length > 0 ? "agent and successor agent" : "agent";

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to provide ${partnerFirstName}'s ${agentText} with general authority to act over all common subjects?`,
        subText: `Learn more about granting general authority.`,
        moreInfoModalProps: {
          title: "Granting General Authority in a Power of Attorney",
          togglerLabel: "Learn more",
          content: (
            <p>
              You can authorize your agent to act on your behalf in a
              few specific subjects or across a wider range of general
              subjects.
              <br />
              <br />
              Unless you are creating your power of attorney for a
              specific, time-limited purpose, many estate planning
              attorneys recommend granting general powers to an agent
              you highly trust, because transactions often involve
              dealing with multiple subjects simultaneously.
              <ul>
                A general authority includes all the below common
                subjects:
                <li>Real Property (such as a house)</li>
                <li>
                  Tangible Personal Property (cars, boats, etc.)
                </li>
                <li>Stocks and Bonds</li>
                <li>Commodities and Options</li>
                <li>Banks and Other Financial Institutions</li>
                <li>Operation of Entity or Business</li>
                <li>Insurance and Annuities</li>
                <li>
                  Estates, Trusts, and Other Beneficial Interests
                </li>
                <li>Claims and Litigation</li>
                <li>Personal Family Maintenance</li>
                <li>
                  Benefits from Governmental Programs or civil or
                  military service
                </li>
                <li>Retirement Plans</li>
                <li>Taxes</li>
              </ul>
            </p>
          ),
          actions: [
            {
              label: "Learn more about POAs",
              href: BlogLinks.whatisFinancialPOA
            }
          ],
          closeLabel: "Got it"
        }
      }}
      name="users.1.poa.generalAuthority"
    />
  );
};

export default PartnerPowersStep;
