import { Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { MessageProps } from "./types";

export const ChatAssistantButton = styled(Button)(({ theme }) => ({
  borderRadius: "50%",
  width: "6rem",
  height: "6rem",
  minWidth: "0",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.secondary.main,
  color: "#ffffff",
  position: "fixed",
  bottom: "1.25rem",
  right: "1.25rem",
  zIndex: 100,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark
  }
}));

export const MessageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(1.5)
}));

export const ChatWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  right: 0,
  width: "21rem",
  minWidth: "50%",
  height: "auto",
  minHeight: "60%",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid #ccc",
  borderRadius: "8px 8px 0 0",
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column"
}));


export const Message = styled(Typography)<MessageProps>(
  ({ theme, isuser }) => ({
    alignSelf: isuser ? "flex-end" : "flex-start",
    backgroundColor: isuser
      ? theme.palette.primary.main
      : theme.palette.grey[200],
    color: isuser
      ? theme.palette.common.white
      : theme.palette.text.primary,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1.5),
    maxWidth: "75%",
    wordWrap: "break-word"
  })
);
