import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LAST_WILL_FINAL_ARRANGEMENT_ROUTES } from "@pages/LastWill/sections/FinalArrangements/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { ROUTES } from "@setup/consts/routes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_FINAL_ARRANGEMENT_ROUTES } from "@pages/Trust/sections/FinalArrangements/consts";

import { getLinkFullUrl } from "../utils";

export const usePartnerFinalArrangementsReview = () => {
  const { formatMessage } = useIntl();
  const { trust, lastWill } = useMemberPlansState();
  const { partnerLastWill } = lastWill || {};

  const canEdit = !!trust?.canEdit || !!lastWill?.canEdit;

  const bodyRestingPlace = trust
    ? trust.partnerLastWill?.bodyRestingPlace
    : partnerLastWill?.bodyRestingPlace;

  const ceremonyType = trust
    ? trust.partnerLastWill?.ceremonyType
    : partnerLastWill?.ceremonyType;

  const specialRequest = trust
    ? trust.partnerLastWill?.specialRequest
    : partnerLastWill?.specialRequest;

  const bodyRestingPlaceInfo = useMemo<ReviewItem | undefined>(
    () =>
      bodyRestingPlace
        ? {
            title: formatMessage({
              defaultMessage: "Final Resting Place:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Gifts,
                  TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace
                ]),
            items: [
              [
                formatMessage(
                  {
                    defaultMessage: `I would like my body to be {bodyRestingPlace}.`
                  },
                  {
                    bodyRestingPlace
                  }
                )
              ]
            ]
          }
        : undefined,
    [formatMessage, canEdit, bodyRestingPlace, trust]
  );

  const ceremonyTypeInfo = useMemo<ReviewItem | undefined>(
    () =>
      ceremonyType?.length
        ? {
            title: formatMessage({
              defaultMessage: "Ceremony Type:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Gifts,
                  TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
                ]),
            items: [
              (ceremonyType ?? []).filter((item): item is string =>
                Boolean(item)
              )
            ]
          }
        : undefined,
    [formatMessage, canEdit, ceremonyType, trust]
  );

  const specialRequestInfo = useMemo<ReviewItem | undefined>(
    () =>
      specialRequest
        ? {
            title: formatMessage({
              defaultMessage: "Additional Requests:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Gifts,
                  TRUST_FINAL_ARRANGEMENT_ROUTES.OtherRequests
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_FINAL_ARRANGEMENT_ROUTES.OtherRequests
                ]),
            isFullWidth: true,
            items: [[specialRequest]]
          }
        : undefined,
    [formatMessage, canEdit, specialRequest, trust]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        bodyRestingPlaceInfo,
        ceremonyTypeInfo,
        specialRequestInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [bodyRestingPlaceInfo, ceremonyTypeInfo, specialRequestInfo]
  );

  return items;
};
