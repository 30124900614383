import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/LastWill/routes";
import { useFormField } from "@components/FormFields/hooks";

const SectionsPreviewStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);

  return (
    <SectionsPreview
      {...{ sections }}
      stepLayout={{
        title: `Here comes the fun part!`,
        subText: `In this next section, we'll review how you want to distribute your assets. Your distributions will go into effect after you pass away based on what you've determined here.`
      }}
    />
  );
};

export default SectionsPreviewStep;
