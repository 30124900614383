import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const ArtificialNutritionStep: React.FC = () => (
  <SingleSelectArchetype
    nullable
    name="users.0.healthcare.preferences.artificialNutrition"
    stepLayout={{
      title: `If you are receiving artificial nutrition and hydration, do you want to continue to receive artificial nutrition and hydration in all cases?`,
      subText: `If you select "No", your agent and healthcare providers will decide whether to continue this care based on your choice to prolong or not prolong life in the preceding question.`
    }}
    items={[
      {
        label: "Yes",
        value: true
      },
      {
        label: "No",
        value: false
      },
      {
        label: "Skip this question",
        value: null
      }
    ]}
  />
);

export default ArtificialNutritionStep;
