import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { PET_CARE_ROUTES } from "@pages/PetPlan/sections/Care/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";
import { ROUTES } from "@setup/consts/routes";
import { ITERATION_QUERY_KEY } from "@providers/planForm/consts";

import { getLinkFullUrl } from "../utils";

import getVetEstateViewInfo from "./partials/getVetEstateViewInfo";
import includeMedicalNote from "./partials/includeMedicalNote";

export const usePetCareReview = () => {
  const { formatMessage } = useIntl();
  const { petProtection: petProtectionPlan } = useMemberPlansState();
  const { petProtection, canEdit = false } = petProtectionPlan || {};

  const pets = petProtection?.pets;
  const iterationQueryKey = ITERATION_QUERY_KEY;
  const careInstructionsPath = getLinkFullUrl([
    ROUTES.PetPlan,
    PET_SECTION_ROUTES.Care,
    PET_CARE_ROUTES.CareInstructionsLoop,
    PET_CARE_ROUTES.Food,
    `?${iterationQueryKey}=1`
  ]);
  const vetInfoPath = getLinkFullUrl([
    ROUTES.PetPlan,
    PET_SECTION_ROUTES.Care,
    PET_CARE_ROUTES.VetLoop,
    PET_CARE_ROUTES.Vet,
    `?${iterationQueryKey}=1`
  ]);

  const petsCareInfo = useMemo<ReviewItem | undefined>(
    () =>
      petProtection?.pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Care Instructions"
            }),
            canEdit,
            href: careInstructionsPath,
            isFullWidth: true,
            items: pets?.map((pet) => [
              `${pet?.name}:`,
              pet?.feedingInstructions
                ? `Food: ${pet.feedingInstructions}`
                : "Food: No instructions provided",
              pet?.careInstructions
                ? `Exercise & Other Care: ${pet.careInstructions}`
                : "Exercise & Other Care: No instructions provided"
            ])
          }
        : undefined,
    [
      petProtection?.pets?.length,
      canEdit,
      careInstructionsPath,
      formatMessage,
      pets
    ]
  );

  const vetInfo = useMemo<ReviewItem | undefined>(
    () =>
      petProtection
        ? {
            title: formatMessage({
              defaultMessage: "Vet Information"
            }),
            canEdit,
            href: vetInfoPath,
            isFullWidth: true,
            items: getVetEstateViewInfo(petProtection)
          }
        : undefined,
    [petProtection, canEdit, vetInfoPath, formatMessage]
  );

  const petsMedicalInfo = useMemo<ReviewItem | undefined>(
    () =>
      petProtection && includeMedicalNote(petProtection)
        ? {
            title: formatMessage({
              defaultMessage: "Medical Information"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.Care,
              PET_CARE_ROUTES.Medical
            ]),
            isFullWidth: true,
            items: pets?.map((pet) => [
              pet?.medicalInstructions
                ? `${pet?.name}: ${pet?.medicalInstructions}`
                : ``
            ])
          }
        : {
            title: formatMessage({
              defaultMessage: "Medical Information"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.Care,
              PET_CARE_ROUTES.Medical
            ]),
            isFullWidth: true,
            items: [["- None provided"]]
          },
    [petProtection, pets, canEdit, formatMessage]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [petsCareInfo, vetInfo, petsMedicalInfo].filter(
        (item): item is ReviewItem => Boolean(item)
      ),
    [petsCareInfo, vetInfo, petsMedicalInfo]
  );

  return items;
};
