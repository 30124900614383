import { useIntl } from "react-intl";
import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { usePlanForm } from "@providers/planForm/context";
import { Pet } from "@pages/PetPlan/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominateBackupGuardianStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: pet } = useFormField<Pet>(`pets.${iteration}`);

  const { setValue: setBackupGuardians } = useFormField(
    `pets.${iteration}.backupGuardians`
  );

  const onSubmit = useCallback(
    ({ nominateBackupGuardian }: SingleSelectFormValues) => {
      if (parseBoolean(nominateBackupGuardian) === false) {
        setBackupGuardians([]);
      }
    },
    [setBackupGuardians]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "Do you want to nominate a backup Pet Guardian for {name}?"
          },
          {
            name: pet.name
          }
        ),
        subText:
          "Your backup Guardian can serve if your primary Guardian is unavailable."
      }}
      name={`pets.${iteration}.nominateBackupGuardian`}
      {...{ onSubmit }}
    />
  );
};

export default NominateBackupGuardianStep;
