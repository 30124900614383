import { useMemo } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import WorkIcon from "@mui/icons-material/Work";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { useIntl } from "react-intl";

import { ReviewReturnType } from "../types";

import { usePartnerAllAboutYouReview } from "./usePartnerAllAboutYouReview";
import { usePartnerGuardiansReview } from "./usePartnerGuardiansReview";
import { usePartnerManagementReview } from "./usePartnerManagementReview";
import { usePartnerFinalArrangementsReview } from "./usePartnerFinalArrangementsReview";
import { usePartnerGiftsReview } from "./usePartnerGiftsReview";

export const useAllPartnerReviews = (): ReviewReturnType => {
  const allAboutYouItems = usePartnerAllAboutYouReview();
  const guardiansItems = usePartnerGuardiansReview();
  const managementItems = usePartnerManagementReview();
  const giftsItems = usePartnerGiftsReview({
    showExcludedPersons: true
  });
  const finalArrangementsItems = usePartnerFinalArrangementsReview();

  const { formatMessage } = useIntl();

  return useMemo<ReviewReturnType>(
    () => [
      {
        tab: formatMessage({
          defaultMessage: "All About You"
        }),
        items: allAboutYouItems,
        icon: <AccountCircleIcon />
      },
      {
        tab: formatMessage({
          defaultMessage: "Guardian"
        }),
        items: guardiansItems,
        icon: <EscalatorWarningIcon />
      },
      {
        tab: formatMessage({
          defaultMessage: "Management"
        }),
        items: managementItems,
        icon: <WorkIcon />
      },
      {
        tab: formatMessage({
          defaultMessage: "Gifts"
        }),
        items: giftsItems,
        icon: <VolunteerActivismIcon />
      },
      {
        tab: formatMessage({
          defaultMessage: "Final Arrangements"
        }),
        items: finalArrangementsItems,
        icon: <LocalFloristIcon />
      }
    ],
    [
      formatMessage,
      allAboutYouItems,
      guardiansItems,
      managementItems,
      giftsItems,
      finalArrangementsItems
    ]
  );
};
