import { gql } from "@apollo/client";

import { LAST_WILL_FRAGMENT, PLAN_TODO_FRAGMENT } from "./fragments";

export const CREATE_TODO = gql`
  ${PLAN_TODO_FRAGMENT}
  mutation CreatePlanTodo(
    $title: String!
    $order: Int
    $planId: Int!
  ) {
    createPlanTodo(title: $title, order: $order, planId: $planId) {
      ...PlanTodo
    }
  }
`;

export const UPDATE_TODO = gql`
  ${PLAN_TODO_FRAGMENT}
  mutation UpdatePlanTodo(
    $id: Int!
    $title: String!
    $order: Int
    $completed: Boolean
  ) {
    updatePlanTodo(
      id: $id
      title: $title
      order: $order
      completed: $completed
    ) {
      ...PlanTodo
    }
  }
`;

export const DELETE_TODO = gql`
  mutation DeletePlanTodo($id: Int!) {
    deletePlanTodo(id: $id)
  }
`;

export const TOGGLE_PLAN_TODO_STATUS_MUTATION = gql`
  ${PLAN_TODO_FRAGMENT}
  mutation TogglePlanTodoStatus($id: Int!, $completed: Boolean!) {
    togglePlanTodoStatus(id: $id, completed: $completed) {
      ...PlanTodo
    }
  }
`;

export const UPDATE_NETWORTH = gql`
  ${LAST_WILL_FRAGMENT}
  mutation UpdateNetWorth($id: Int!, $netWorth: Float!) {
    updateNetWorth(id: $id, netWorth: $netWorth) {
      ...LastWill
    }
  }
`;
