import { useCallback } from "react";
import { get, lowerCase } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { PartnerType } from "@api/__generated__/globalTypes";

const PartnerAsGuardianStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);

  const { setValue: clearAllGuardians } = useFormField(`guardians`);

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const partnerAsChildGuardian = get(
        data,
        "partnerAsChildGuardian"
      );

      if (parseBoolean(partnerAsChildGuardian)) {
        clearAllGuardians([]);
      }
    },
    [clearAllGuardians]
  );

  const lowerCasePartnerType = lowerCase(partnerType);

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `In the event that one of you or ${partnerFirstName} dies, would you like the surviving ${lowerCasePartnerType} to serve as primary guardian?`
      }}
      {...{ onSubmit }}
      name="partnerAsChildGuardian"
    />
  );
};

export default PartnerAsGuardianStep;
