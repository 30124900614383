export const LAST_WILL_GIFTS_ROUTES = {
  // PrimaryDrafter Starts
  Preview: "",
  GiftProcessOverview: "overview",
  TransitionPrimaryStart: "primary-start",
  DistAllToPartner: "distribute-to-partner",
  DistAllToPartnerConfirmed: "distribute-to-partner-next",

  // Residue Section
  ResidueOverview: "residue-overview",
  ResidueToPartner: "residue-to-partner",
  ResidueToChildren: "residue-to-children",

  ResidueAllocate: "residue-allocate",
  ResidueAllocateBeneficiariesLoop: "residue-allocate/beneficiaries",
  ResidueAllocateBeneficiaryFails: "fails",
  ResidueAllocateBeneficiaryBackups: "backups",
  ResidueAllocateBeneficiaryCharity: "charity",

  ResidueComplete: "residue-complete",

  // SpecificGifts Section
  SpecificGifts: "specific-gifts",
  AddSpecificGifts: "specific-gifts-add",
  PersonalPropertyBalanceOverview:
    "personal-property-balance-overview",
  PersonalPropertyBalance: "personal-property-balance",
  PersonalPropertyBalancePeople: "personal-property-list",
  MakeCashGift: "cash-gift",
  ListCash: "cash-gift-add",

  // ExcludePersons
  ExcludePerson: "excluded-persons",
  DesignateExcludedPersons: "designate-excluded-persons",

  // NoContest
  IncludeNoContest: "include-no-contest",

  // Partner Starts

  TransitionPartnerStart: "partner-start",
  PartnerDistAllToPartner: "partner-distribute-to-partner",
  PartnerDistAllToPartnerConfirmed:
    "partner-distribute-to-partner-next",

  // Residue Section
  PartnerResidueOverview: "partner-residue-overview",
  PartnerResidueToPartner: "partner-residue-to-partner",
  PartnerResidueToChildren: "partner-residue-to-children",

  PartnerResidueAllocate: "partner-residue-allocate",
  PartnerResidueAllocateBeneficiariesLoop:
    "partner-residue-allocate/beneficiaries",
  PartnerResidueAllocateBeneficiaryFails: "partner-fails",
  PartnerResidueAllocateBeneficiaryBackups: "partner-backups",
  PartnerResidueAllocateBeneficiaryCharity: "partner-charity",
  PartnerResidueComplete: "partner-residue-complete",

  // SpecificGifts Section
  PartnerSpecificGifts: "partner-specific-gifts",
  PartnerAddSpecificGifts: "partner-specific-gifts-add",
  PartnerPersonalPropertyBalanceOverview:
    "partner-personal-property-balance-overview",
  PartnerPersonalPropertyBalance: "partner-personal-property-balance",
  PartnerPersonalPropertyBalancePeople:
    "partner-personal-property-list",
  PartnerMakeCashGift: "partner-cash-gift",
  PartnerListCash: "partner-cash-gift-add",

  // ExcludePersons
  PartnerExcludePerson: "partner-excluded-persons",
  PartnerDesignateExcludedPersons:
    "partner-designate-excluded-persons",

  // NoContest
  PartnerIncludeNoContest: "partner-include-no-contest",

  // Partner Make Gifts Section
  PartnerMirrorDist: "mirror-distributions",

  // Review
  Review: "review",
  ReviewTabs: "review-tabs"
};
