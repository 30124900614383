export const LAST_WILL_MANAGEMENT_ROUTES = {
  Preview: "",
  RepresentativeOverview: "representative-overview",
  DesignateRepresentative: "designate-rep",
  NominateBackupRepresentative: "nominate-backup-rep",
  DesignateBackupRepresentative: "designate-backup-rep",
  RepCompensation: "rep-comp",
  RepCompensationDollar: "rep-comp-amt",
  RepCompensationPercent: "rep-comp-pct",

  // Partner Management Starts Here
  PartnerSameReps: "partner-same-reps",
  PartnerSameRepComp: "partner-same-reps-comp",
  DesignatePartnerRepresentative: "designate-partner-rep",
  NominatePartnerBackupRepresentative: "nominate-partner-backup-rep",
  DesignatePartnerBackupRepresentative:
    "designate-partner-backup-rep",
  PartnerRepCompensation: "partner-rep-comp",
  PartnerRepCompensationDollar: "partner-rep-comp-amt",
  PartnerRepCompensationPercent: "partner-rep-comp-pct",
  // Review
  Review: "review",
  ReviewTabs: "review-tabs"
};
