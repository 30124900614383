import BasicFormCharitiesAutocomplete from "@components/FormFields/FormCharitiesAutocomplete/BasicFormCharitiesAutocomplete";
import { BeneficiaryFormProps } from "@pages/LastWill/types";

const BasicCharityBeneficiaryForm: React.FC<BeneficiaryFormProps> = ({
  prefix
}) => (
  <BasicFormCharitiesAutocomplete
    beneficiaryPrefix={prefix}
    name={`${prefix}.charity`}
  />
);

export default BasicCharityBeneficiaryForm;
