import { useMemo } from "react";
import {
  SmsOutlined,
  PhoneOutlined,
  VideoCallOutlined,
  AssistantOutlined,
  EmailOutlined
} from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

import { NavigationItem } from "@components/Header/types";
import { useAIAssistant } from "@providers/aiAssistant/context";

import { Wrapper } from "./styles";

const useHelpMenuItems = (showAssistant = false) => {
  const { toggleChat } = useAIAssistant();

  const menuItems = useMemo(() => {
    const items: NavigationItem[] = [
      {
        id: "helpTextJIC",
        label: (
          <Wrapper>
            <SmsOutlined />
            <FormattedMessage defaultMessage="Text Us" />
          </Wrapper>
        ),
        href: "sms:+1-203-392-1951?body=Hey%20JIC%20Team%20--%20can%20you%20please%20help%20me%20with...",
        isExternal: true
      },
      {
        id: "helpCallJIC",
        label: (
          <Wrapper>
            <PhoneOutlined />
            <FormattedMessage defaultMessage="Call Us" />
          </Wrapper>
        ),
        href: "tel:+1-203-392-1951",
        isExternal: true
      },
      {
        id: "helpEmailJIC",
        label: (
          <Wrapper>
            <EmailOutlined />
            <FormattedMessage defaultMessage="Email Us" />
          </Wrapper>
        ),
        href: "mailto:support@jicestates.com",
        isExternal: true
      },
      {
        id: "helpVideoCallJIC",
        label: (
          <Wrapper>
            <VideoCallOutlined />
            <FormattedMessage defaultMessage="Video Meeting" />
          </Wrapper>
        ),
        href: "https://calendly.com/jic-luke",
        isExternal: true
      }
    ];

    if (showAssistant) {
      items.unshift({
        id: "helpOpenChatAssistant",
        label: (
          <Wrapper>
            <AssistantOutlined />
            <FormattedMessage defaultMessage="Launch AI Assistant" />
          </Wrapper>
        ),
        onClick: toggleChat
      });
    }

    return items;
  }, [showAssistant, toggleChat]);

  return menuItems;
};

export default useHelpMenuItems;
