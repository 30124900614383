import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Tooltip
} from "@mui/material";
import React, { useState } from "react";

import { ClientLinkProps } from "./types";

import {
  MEMBERS_PORTAL_URL,
  INVITATION_PATH
} from "@setup/environment";
import { copyTextToClipBoard } from "@utils/copyTextToClipboard";
import { Link as LinkIcon } from "@mui/icons-material";

const ClientLink: React.FC<ClientLinkProps> = ({ token = "" }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleClick = () => {
    copyTextToClipBoard(
      `${MEMBERS_PORTAL_URL}${INVITATION_PATH}${token}`
    );
    setSnackbarOpen(true);
  };

  return (
    <Tooltip title="Copy invite link to clipboard">
      <Box style={{ position: "relative" }}>
        <Button
          startIcon={
            <LinkIcon sx={{ transform: "rotate(-45deg)" }} />
          }
          onClick={() => handleClick()}
        >
          <Typography sx={{ textTransform: "none" }}>
            Copy Invite Link
          </Typography>
        </Button>
        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          autoHideDuration={1500}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          sx={{
            position: "absolute",
            bottom: "100%",
            left: "0%",
            transform: "translateY(-10px)"
          }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            sx={{ width: "100%", alignItems: "center" }}
          >
            <LinkIcon sx={{ transform: "rotate(-45deg)" }} />
            Copied to clipboard
          </Alert>
        </Snackbar>
      </Box>
    </Tooltip>
  );
};

export default ClientLink;
