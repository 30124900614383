import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeadersWrapper = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  marginBottom: 20,
  display: "flex",
  gap: "0.75rem"
}));

export const SharesColumnHeader = styled(Box)(({ theme }) => ({
  minWidth: "5ch",
  width: "40%",
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey[300],
  lineHeight: "1.5rem"
}));

export const NumberColumnHeader = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  marginRight: "calc(24px + 0.75rem)",
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey[300],
  lineHeight: "1.5rem"
}));
