import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import get from "lodash/get";

const RepCompensationStep: React.FC = () => {
  const { setValue: setRepCompensationDollar } = useFormField(
    "users.0.lastWill.representativesCompensationDollar"
  );
  const { setValue: setRepCompensationPercent } = useFormField(
    "users.0.lastWill.representativesCompensationPercent"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const repCompensation = get(
        data,
        "users.0.lastWill.representativesCompensationType"
      );

      if (
        repCompensation !==
        "A specific percent of my estate and reimbursement of expenses"
      ) {
        setRepCompensationPercent(null);
      }
      if (
        repCompensation !==
        "A specific dollar amount and reimbursement of expenses"
      ) {
        setRepCompensationDollar(null);
      }
    },
    [setRepCompensationPercent, setRepCompensationDollar]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How would you like to compensate your Personal Representative?`,
        subText:
          "Learn more about Personal Representative compensation.",
        moreInfoModalProps: {
          title: "Personal Representative Compensation",
          togglerLabel: "Learn more",
          content: (
            <p>
              Serving as a Personal Representative can be a lot of
              work. State law recognizes this, and as a default, your
              Personal Representative is entitled to seek reasonable
              compensation for his or her services.
              <br />
              <br />
              Even if you&apos;d like the default rule to apply,
              expressing this to be your intent in your will is
              important because doing so can help mitigate potential
              conflict among your beneficiaries.
              <br />
              <br />
              If you do not want to compensate your Personal
              Representative, you need to state that intent in your
              will. You should also speak with your Personal
              Representative and make sure that your Personal
              Representative is still willing to serve in that
              capacity without payment.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="users.0.lastWill.representativesCompensationType"
      items={[
        {
          label:
            "No compensation. Reimbursement of reasonable expenses only",
          value:
            "No compensation. Reimbursement of reasonable expenses only"
        },
        {
          label:
            "Reasonable compensation and reimbursement of expenses (most common)",
          value:
            "Reasonable compensation and reimbursement of expenses"
        },
        {
          label:
            "A specific dollar amount and reimbursement of expenses",
          value:
            "A specific dollar amount and reimbursement of expenses"
        },
        {
          label:
            "A specific percent of my estate and reimbursement of expenses",
          value:
            "A specific percent of my estate and reimbursement of expenses"
        }
      ]}
    />
  );
};

export default RepCompensationStep;
