import React from "react";
import noop from "lodash/noop";

import { ArrowAnimationContextValue } from "./types";

export const ArrowAnimationContext =
  React.createContext<ArrowAnimationContextValue>({
    showLeftArrow: undefined,
    setShowLeftArrow: noop,
    showRightArrow: undefined,
    setShowRightArrow: noop
  });
