import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanOption } from "@api/__generated__/globalTypes";

import { LAST_WILL_MANAGEMENT_ROUTES } from "../Management/consts";

import SectionsPreviewStep from "./steps/Preview";
import NominateGuardianStep from "./steps/NominateGuardians";
import NoChildrenNoGuardiansStep from "./steps/NoChildrenNoGuardians";
import PartnerAsGuardianStep from "./steps/PartnerAsGuardian";
import DesignateGuardianStep from "./steps/DesignateGuardians";
import NominateBackupGuardianStep from "./steps/NominateBackupGuardians";
import DesignateBackupGuardianStep from "./steps/DesignateBackupGuardians";
import PrimaryGuardianOnlyStep from "./steps/PrimaryGuardiansOnly";
import NominateAntiGuardianStep from "./steps/NominateAntiGuardian";
import DesignateAntiGuardianStep from "./steps/DesignateAntiGuardians";
import ReviewStep from "./steps/Review";
import ReviewTabsStep from "./steps/ReviewTabs";
import { LAST_WILL_GUARDIANS_ROUTES } from "./consts";

const guardiansSteps: PlanFormStep[] = [
  {
    path: LAST_WILL_GUARDIANS_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: {
      if: [
        { "==": [{ var: "haveChildren" }, true] },
        LAST_WILL_GUARDIANS_ROUTES.NominateGuardians,
        LAST_WILL_GUARDIANS_ROUTES.NoChildrenNoGuardians
      ]
    }
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.NominateGuardians,
    element: <NominateGuardianStep />,
    rules: {
      if: [
        {
          and: [
            { "==": [{ var: "nominateGuardians" }, true] },
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] }
          ]
        },
        LAST_WILL_GUARDIANS_ROUTES.PartnerAsGuardian,
        {
          if: [
            { "==": [{ var: "nominateGuardians" }, true] },
            LAST_WILL_GUARDIANS_ROUTES.DesignateGuardians,
            LAST_WILL_GUARDIANS_ROUTES.NoChildrenNoGuardians
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.NoChildrenNoGuardians,
    element: <NoChildrenNoGuardiansStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.Management}/${LAST_WILL_MANAGEMENT_ROUTES.Preview}`
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.PartnerAsGuardian,
    element: <PartnerAsGuardianStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerAsChildGuardian" }, true] },
        LAST_WILL_GUARDIANS_ROUTES.NominateBackupGuardians,
        LAST_WILL_GUARDIANS_ROUTES.DesignateGuardians
      ]
    }
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.DesignateGuardians,
    element: <DesignateGuardianStep />,
    rules: LAST_WILL_GUARDIANS_ROUTES.NominateBackupGuardians
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.NominateBackupGuardians,
    element: <NominateBackupGuardianStep />,
    rules: {
      if: [
        { "==": [{ var: "nominateBackupGuardians" }, true] },
        LAST_WILL_GUARDIANS_ROUTES.DesignateBackupGuardians,
        LAST_WILL_GUARDIANS_ROUTES.PrimaryGuardiansOnly
      ]
    }
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.DesignateBackupGuardians,
    element: <DesignateBackupGuardianStep />,
    rules: LAST_WILL_GUARDIANS_ROUTES.NominateAntiGuardian
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.PrimaryGuardiansOnly,
    element: <PrimaryGuardianOnlyStep />,
    rules: LAST_WILL_GUARDIANS_ROUTES.NominateAntiGuardian
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.NominateAntiGuardian,
    element: <NominateAntiGuardianStep />,
    rules: {
      if: [
        { "==": [{ var: "nominateProhibitedGuardians" }, true] },
        LAST_WILL_GUARDIANS_ROUTES.DesignateAntiGuardian,
        LAST_WILL_GUARDIANS_ROUTES.Review
      ]
    }
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.DesignateAntiGuardian,
    element: <DesignateAntiGuardianStep />,
    rules: LAST_WILL_GUARDIANS_ROUTES.Review
  },

  {
    path: LAST_WILL_GUARDIANS_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.Management}/${LAST_WILL_MANAGEMENT_ROUTES.Preview}`
  },
  {
    path: LAST_WILL_GUARDIANS_ROUTES.ReviewTabs,
    element: <ReviewTabsStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.Management}/${LAST_WILL_MANAGEMENT_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={LAST_WILL_GUARDIANS_ROUTES.Preview} />
  }
];

export default guardiansSteps;
