import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  Container,
  FormControl,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import { videoTutorials } from "@setup/consts/videoTutorials";
import { ExternalLinks } from "@setup/consts/articleLinks";
import LinkBehavior from "@setup/theme/partials/Link";
import { isExternalAppLink, tryGetProtocol } from "@utils/misc";

import { CSVUploadResultsProps } from "./types";

const UploadOverview: React.FC<CSVUploadResultsProps> = ({
  handleFileUpload
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { title, src } = videoTutorials.howToUseCsvImport;

  return (
    <Box display="flex" flexDirection="column">
      <Container>
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0
          }}
        >
          <iframe
            src={src}
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
            title={title}
          />
        </div>
      </Container>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        gap="0.5rem"
        justifyContent="center"
        alignItems="center"
        my={1}
      >
        <FormControl>
          <label htmlFor="csv-file-input">
            <input
              style={{ display: "none" }}
              id="csv-file-input"
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
            />

            <Button
              variant="contained"
              color="primary"
              component="span"
            >
              <FormattedMessage defaultMessage="Select File" />
            </Button>
          </label>
        </FormControl>
        <Button
          variant="outlined"
          color="inherit"
          href={ExternalLinks.csvImportInstructions}
          // eslint-disable-next-line react/no-unstable-nested-components
          LinkComponent={(props) => (
            <LinkBehavior
              {...props}
              {...(tryGetProtocol(props.href) ||
              isExternalAppLink(props.href)
                ? { isExternal: true, target: "_blank" }
                : {})}
            />
          )}
        >
          <FormattedMessage defaultMessage="Download Template" />
        </Button>
      </Box>
      <Typography variant="caption" align="center">
        <FormattedMessage defaultMessage="Upload a file in .csv format using the template provided" />
      </Typography>
    </Box>
  );
};

export default UploadOverview;
