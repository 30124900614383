import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import {
  ITERATION_QUERY_KEY,
  NEXT_ITERATION_RULE
} from "@providers/planForm/consts";
import { RemainderStrategy } from "@hooks/useTrustEnums";
import {
  PartnerType,
  PlanOption,
  DistribType,
  DistributionType,
  VestingType
} from "@api/__generated__/globalTypes";

import { TRUST_ASSETS_ROUTES } from "../Assets/consts";

import { TRUST_GIFTS_ROUTES } from "./consts";
import ReviewTabsStepPage from "./steps/ReviewTabs";
import SectionsPreviewStep from "./steps/Preview";
import GiftProcessOverviewStep from "./steps/GiftProcessOverview";
import TransitionPrimaryStartStep from "./steps/TransitionPrimaryStart";
import ResidueCompleteStep from "./steps/RemainderComplete";
import ExcludedPersonsStep from "./steps/ExcludePerson";
import BackupBeneficiariesStep from "./steps/RemainderAllocate/BackupBeneficiaries";
import CharityBackup from "./steps/RemainderAllocate/CharityBackup";
import BeneficiaryDistribution from "./steps/RemainderAllocate/BeneficiaryDistribution";
import ReviewStep from "./steps/Review";
import DesignateExcludedPersonsStep from "./steps/DesignateExcludedPersons";
import RemainderAllocateStep from "./steps/RemainderAllocate";
import RemainderStrategyStep from "./steps/RemainderStrategy";
import RemainderDistribTypeStep from "./steps/RemainderDistribType";
import RemainderPooledOverviewStep from "./steps/RemainderPooledOverview";
import RemainderPooledRestrictionsStep from "./steps/RemainderPooledRestrictions";
import RemainderPooledFamilyInterstititalStep from "./steps/RemainderPooledFamilyInterstitial";
import RemainderPooledAgeThresholdStep from "./steps/RemainderPooledAgeThreshold";
import RemainderPooledFinalDistributionStep from "./steps/RemainderPooledFinalDistribution";
import RemainderDistribTypeLoopedStep from "./steps/RemainderAllocate/DistribTypeLooped";
import RemainderSeparateTrustAgeReqStep from "./steps/RemainderAllocate/SeparateTrustAgeReqs";
import RemainderSeparateTrustCopyStep from "./steps/RemainderAllocate/SeparateTrustCopy";
import RemainderSeparateTrustSetupStep from "./steps/RemainderAllocate/SeparateTrustSetupLooped";
import PartnerRemainderStrategyStep from "./steps/PartnerRemainderStrategy";
import PartnerTransitionStartStep from "./steps/TransitionPartnerStart";
import PartnerRemainderDistribTypeStep from "./steps/PartnerRemainderDistribType";
import PartnerRemainderPooledOverviewStep from "./steps/PartnerRemainderPooledOverview";
import PartnerRemainderPooledRestrictionsStep from "./steps/PartnerRemainderPooledRestrictions";
import PartnerRemainderPooledFamilyInterstitialStep from "./steps/PartnerRemainderPooledFamilyInterstitial";
import PartnerRemainderPooledAgeThresholdStep from "./steps/PartnerRemainderPooledAgeThreshold";
import PartnerRemainderPooledFinalDistributionStep from "./steps/PartnerRemainderPooledFinalDistribution";
import PartnerRemainderAllocateStep from "./steps/PartnerRemainderAllocate";
import PartnerRemainderSeparateTrustSetupStep from "./steps/PartnerRemainderAllocate/PartnerSeparateTrustSetupLooped";
import PartnerBeneficiaryDistribution from "./steps/PartnerRemainderAllocate/PartnerBeneficiaryDistribution";
import PartnerRemainderSeparateTrustCopyStep from "./steps/PartnerRemainderAllocate/PartnerSeparateTrustCopy";
import PartnerRemainderDistribTypeLoopedStep from "./steps/PartnerRemainderAllocate/PartnerDistribTypeLooped";
import PartnerSeparateTrustAgeReqsStep from "./steps/PartnerRemainderAllocate/PartnerSeparateTrustAgeReqs";
import PartnerCharityBackup from "./steps/PartnerRemainderAllocate/PartnerCharityBackup";
import PartnerRemainderBackupBeneficiariesStep from "./steps/PartnerRemainderAllocate/PartnerBackupBeneficiaries";
import PartnerRemainderCompleteStep from "./steps/PartnerRemainderComplete";
import PartnerExcludedPersonsStep from "./steps/PartnerExcludePerson";
import PartnerDesignateExcludedPersonsStep from "./steps/PartnerDesignateExcludedPersons";
import SeparateTrustSetupFinalDistroStep from "./steps/SeparateTrustSetupFinalDistro";
import SeparateTrustAgeReqsFinalDistroStep from "./steps/SeparateTrustAgeReqsFinalDistro";
import PartnerSeparateTrustSetupFinalDistroStep from "./steps/PartnerSeparateTrustSetupFinalDistro";
import PartnerSeparateTrustAgeReqsFinalDistroStep from "./steps/PartnerSeparateTrustAgeReqsFinalDistro";
import TrustRemainderStartStep from "./steps/TrustRemainderStart";
import EverythingToPartnerStep from "./steps/EverythingToPartner";
import EverythingToPartnerConfirmedStep from "./steps/EverythingToPartnerConfirmed";
import PartnerEverythingToPartnerStep from "./steps/PartnerEverythingToPartner";
import PartnerEverythingToPartnerConfirmedStep from "./steps/PartnerEverythingToPartnerConfirmed";
import IncludeNoContestStep from "./steps/IncludeNoContest";
import PartnerIncludeNoContestStep from "./steps/PartnerIncludeNoContest";

const giftsSteps: PlanFormStep[] = [
  {
    path: TRUST_GIFTS_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: TRUST_GIFTS_ROUTES.GiftProcessOverview
  },
  {
    path: TRUST_GIFTS_ROUTES.GiftProcessOverview,
    element: <GiftProcessOverviewStep />,
    rules: {
      if: [
        {
          "==": [{ var: "partnerType" }, PartnerType.SINGLE]
        },
        TRUST_GIFTS_ROUTES.TrustRemainderStartStep,
        TRUST_GIFTS_ROUTES.EverythingToPartner
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.EverythingToPartner,
    element: <EverythingToPartnerStep />,
    rules: TRUST_GIFTS_ROUTES.EverythingToPartnerConfirmed
  },
  {
    path: TRUST_GIFTS_ROUTES.EverythingToPartnerConfirmed,
    element: <EverythingToPartnerConfirmedStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.trust.isJointTrust" }, true] },
        TRUST_GIFTS_ROUTES.TrustRemainderStartStep,
        {
          if: [
            {
              "==": [
                { var: "users.0.gifts.everythingGoToPartner" },
                true
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiariesLoop,
            TRUST_GIFTS_ROUTES.TrustRemainderStartStep
          ]
        }
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.TrustRemainderStartStep,
    element: <TrustRemainderStartStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [{ var: "partnerType" }, PartnerType.SINGLE]
            },
            { "==": [{ var: "haveChildren" }, false] }
          ]
        },
        TRUST_GIFTS_ROUTES.RemainderAllocate,
        TRUST_GIFTS_ROUTES.RemainderStrategy
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.TransitionPrimaryStart,
    element: <TransitionPrimaryStartStep />,
    rules: TRUST_GIFTS_ROUTES.RemainderStrategy
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderStrategy,
    element: <RemainderStrategyStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.gifts.remainderStrategy" },
            "nameIndividually"
          ]
        },
        TRUST_GIFTS_ROUTES.RemainderAllocate,
        {
          if: [
            {
              "==": [
                { var: "users.0.gifts.remainderStrategy" },
                RemainderStrategy.PartnerOnly
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiariesLoop,
            TRUST_GIFTS_ROUTES.RemainderDistribType
          ]
        }
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderDistribType,
    element: <RemainderDistribTypeStep />,
    rules: {
      if: [
        {
          or: [
            {
              "==": [
                { var: "users.0.gifts.remainderDistribType" },
                DistribType.POOLED_FAMILY
              ]
            },
            {
              "==": [
                { var: "users.0.gifts.remainderDistribType" },
                DistribType.POOLED_CHILDREN
              ]
            }
          ]
        },
        TRUST_GIFTS_ROUTES.RemainderPooledOverview, // TBU
        TRUST_GIFTS_ROUTES.RemainderAllocate // TBU
      ]
    }
  },
  // Remainder Pooled Sequence
  {
    path: TRUST_GIFTS_ROUTES.RemainderPooledOverview,
    element: <RemainderPooledOverviewStep />,
    rules: TRUST_GIFTS_ROUTES.RemainderPooledRestrictions
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderPooledRestrictions,
    element: <RemainderPooledRestrictionsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.gifts.remainderDistribType" },
            DistribType.POOLED_FAMILY
          ]
        },
        TRUST_GIFTS_ROUTES.RemainderPooledFamilyIntersitital,
        TRUST_GIFTS_ROUTES.RemainderPooledAgeThreshold
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderPooledFamilyIntersitital,
    element: <RemainderPooledFamilyInterstititalStep />,
    rules: TRUST_GIFTS_ROUTES.RemainderPooledAgeThreshold
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderPooledAgeThreshold,
    element: <RemainderPooledAgeThresholdStep />,
    rules: TRUST_GIFTS_ROUTES.RemainderPooledFinalDistribution
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderPooledFinalDistribution,
    element: <RemainderPooledFinalDistributionStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.assets.estateBalance.0.beneficiaries.0.subTrust.terminationDistribution"
            },
            DistribType.SEPARATE_CHILDREN
          ]
        },
        TRUST_GIFTS_ROUTES.SeparateTrustSetupFinalDistro,
        TRUST_GIFTS_ROUTES.RemainderComplete
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.SeparateTrustSetupFinalDistro,
    element: <SeparateTrustSetupFinalDistroStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust.vestingType"
            },
            VestingType.AGE_BASED
          ]
        },
        TRUST_GIFTS_ROUTES.SeparateTrustAgeReqsFinalDistro,
        TRUST_GIFTS_ROUTES.RemainderComplete
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.SeparateTrustAgeReqsFinalDistro,
    element: <SeparateTrustAgeReqsFinalDistroStep />,
    rules: TRUST_GIFTS_ROUTES.RemainderComplete
  },
  // Set Individual %s and Run Through Loop
  // TODO add additional handling for RemainderAllocate tailored for the diff't groups we are sending there
  {
    path: TRUST_GIFTS_ROUTES.RemainderAllocate,
    element: <RemainderAllocateStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.gifts.remainderDistribType"
            },
            DistribType.SEPARATE_CHILDREN
          ]
        },
        TRUST_GIFTS_ROUTES.RemainderSeparateChildrenTrustLoop,
        TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiariesLoop
      ]
    }
  },
  // LOOP FOR SEPARATE CHILDREN'S TRUST STARTS HERE
  {
    path: TRUST_GIFTS_ROUTES.RemainderSeparateChildrenTrustLoop,
    loop: {
      enabled: true,
      iterationCountSource:
        "users.0.assets.estateBalance.0.beneficiaries"
    },
    children: [
      {
        path: TRUST_GIFTS_ROUTES.RemainderChildrenSeparateTrustSetup,
        element: <RemainderSeparateTrustSetupStep />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.0.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingType" // TODO: Check this variable
                },
                VestingType.AGE_BASED
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderChildrenSeparateTrustAgeReq,
            TRUST_GIFTS_ROUTES.RemainderChildrenBeneficiaryFails
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderChildrenSeparateTrustAgeReq,
        element: <RemainderSeparateTrustAgeReqStep />,
        rules: TRUST_GIFTS_ROUTES.RemainderChildrenBeneficiaryFails
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderChildrenBeneficiaryFails,
        element: <BeneficiaryDistribution />,
        // TODO: Break if not the first one...
        rules: {
          if: [
            {
              and: [
                { "==": [{ var: ITERATION_QUERY_KEY }, "0"] },
                {
                  ">": [
                    {
                      var: "users.0.assets.estateBalance.0.beneficiaries.length"
                    },
                    "1"
                  ]
                }
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderChildrenSeparateTrustCopy,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderChildrenSeparateTrustCopy,
        element: <RemainderSeparateTrustCopyStep />,
        // TODO: If copy true, break and move on...
        // rules: NEXT_ITERATION_RULE
        rules: {
          if: [
            {
              "==": [
                { var: "users.0.gifts.remainderCopyTrustSetup" },
                true
              ]
            },
            {
              path: TRUST_GIFTS_ROUTES.RemainderComplete,
              break: true
            },
            NEXT_ITERATION_RULE
          ]
        }
      }
    ],
    rules: TRUST_GIFTS_ROUTES.RemainderComplete
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiariesLoop,
    loop: {
      enabled: true,
      iterationCountSource:
        "users.0.assets.estateBalance.0.beneficiaries"
    },
    // TODO -- should I have an interstitial here that walks through questions? Also serves as cheat that can let me use this same loop for non-individuals where I already know distribType.
    children: [
      {
        path: TRUST_GIFTS_ROUTES.RemainderDistribTypeLooped,
        element: <RemainderDistribTypeLoopedStep />,
        /* TODO
        If outright, beneficiaryFails
        If trust, set up trust restrictions + age-vesting
        */
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.0.assets.estateBalance.0.beneficiaries.${iteration}.distribType"
                },
                DistribType.OUTRIGHT
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryFails,
            TRUST_GIFTS_ROUTES.RemainderSeparateTrustSetupLooped
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderSeparateTrustSetupLooped,
        element: <RemainderSeparateTrustSetupStep />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.0.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingType" // TODO: Check this variable
                },
                VestingType.AGE_BASED
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderSeparateTrustAgeReq,
            TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryFails
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderSeparateTrustAgeReq,
        element: <RemainderSeparateTrustAgeReqStep />,
        rules: TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryFails
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryFails,
        element: <BeneficiaryDistribution />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.CHARITY
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryCharity,
            {
              "==": [
                {
                  var: "users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.BACKUP_BENEFICIARIES
              ]
            },
            TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryBackups,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryBackups,
        element: <BackupBeneficiariesStep />,
        rules: NEXT_ITERATION_RULE
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderAllocateBeneficiaryCharity,
        element: <CharityBackup />,
        // if all to partner OR only one beneficiary, break
        rules: NEXT_ITERATION_RULE
      },
      {
        path: TRUST_GIFTS_ROUTES.RemainderSeparateTrustCopy,
        element: <RemainderSeparateTrustCopyStep />,
        // If true, break; if false, repeat loop
        rules: NEXT_ITERATION_RULE
      }
    ],
    rules: TRUST_GIFTS_ROUTES.RemainderComplete
  },
  {
    path: TRUST_GIFTS_ROUTES.RemainderComplete,
    element: <ResidueCompleteStep />,
    rules: TRUST_GIFTS_ROUTES.ExcludePerson
  },
  {
    path: TRUST_GIFTS_ROUTES.ExcludePerson,
    element: <ExcludedPersonsStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.gifts.excludePersons" }, true] },
        TRUST_GIFTS_ROUTES.DesignateExcludedPersons,
        TRUST_GIFTS_ROUTES.IncludeNoContest
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.DesignateExcludedPersons,
    element: <DesignateExcludedPersonsStep />,
    rules: TRUST_GIFTS_ROUTES.IncludeNoContest
  },
  {
    path: TRUST_GIFTS_ROUTES.IncludeNoContest,
    element: <IncludeNoContestStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        TRUST_GIFTS_ROUTES.PartnerTransitionStart,
        TRUST_GIFTS_ROUTES.Review
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerTransitionStart,
    element: <PartnerTransitionStartStep />,
    rules: TRUST_GIFTS_ROUTES.PartnerEverythingToPartner
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerEverythingToPartner,
    element: <PartnerEverythingToPartnerStep />,
    rules: TRUST_GIFTS_ROUTES.PartnerEverythingToPartnerConfirmed
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerEverythingToPartnerConfirmed,
    element: <PartnerEverythingToPartnerConfirmedStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.trust.isJointTrust" }, true] },
        TRUST_GIFTS_ROUTES.PartnerRemainderStrategy,
        {
          if: [
            {
              "==": [
                { var: "users.1.gifts.everythingGoToPartner" },
                true
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiariesLoop,
            TRUST_GIFTS_ROUTES.PartnerRemainderStrategy
          ]
        }
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderStrategy,
    element: <PartnerRemainderStrategyStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.gifts.remainderStrategy" },
            "nameIndividually"
          ]
        },
        TRUST_GIFTS_ROUTES.PartnerRemainderAllocate,
        {
          if: [
            {
              "==": [
                { var: "users.1.gifts.remainderStrategy" },
                RemainderStrategy.PartnerOnly
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiariesLoop,
            TRUST_GIFTS_ROUTES.PartnerRemainderDistribType
          ]
        }
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderDistribType,
    element: <PartnerRemainderDistribTypeStep />,
    rules: {
      if: [
        {
          or: [
            {
              "==": [
                { var: "users.1.gifts.remainderDistribType" },
                DistribType.POOLED_FAMILY
              ]
            },
            {
              "==": [
                { var: "users.1.gifts.remainderDistribType" },
                DistribType.POOLED_CHILDREN
              ]
            }
          ]
        },
        TRUST_GIFTS_ROUTES.PartnerRemainderPooledOverview, // TBU
        TRUST_GIFTS_ROUTES.PartnerRemainderAllocate // TBU
      ]
    }
  },
  // Remainder Pooled Sequence
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderPooledOverview,
    element: <PartnerRemainderPooledOverviewStep />,
    rules: TRUST_GIFTS_ROUTES.PartnerRemainderPooledRestrictions
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderPooledRestrictions,
    element: <PartnerRemainderPooledRestrictionsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.gifts.remainderDistribType" },
            DistribType.POOLED_FAMILY
          ]
        },
        TRUST_GIFTS_ROUTES.PartnerRemainderPooledFamilyIntersitital,
        TRUST_GIFTS_ROUTES.PartnerRemainderPooledAgeThreshold
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderPooledFamilyIntersitital,
    element: <PartnerRemainderPooledFamilyInterstitialStep />,
    rules: TRUST_GIFTS_ROUTES.PartnerRemainderPooledAgeThreshold
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderPooledAgeThreshold,
    element: <PartnerRemainderPooledAgeThresholdStep />,
    rules: TRUST_GIFTS_ROUTES.PartnerRemainderPooledFinalDistribution
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderPooledFinalDistribution,
    element: <PartnerRemainderPooledFinalDistributionStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.assets.estateBalance.0.beneficiaries.0.subTrust.terminationDistribution"
            },
            DistribType.SEPARATE_CHILDREN
          ]
        },
        TRUST_GIFTS_ROUTES.PartnerSeparateTrustSetupFinalDistro,
        TRUST_GIFTS_ROUTES.PartnerRemainderComplete
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerSeparateTrustSetupFinalDistro,
    element: <PartnerSeparateTrustSetupFinalDistroStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust.vestingType"
            },
            VestingType.AGE_BASED
          ]
        },
        TRUST_GIFTS_ROUTES.PartnerSeparateTrustAgeReqsFinalDistro,
        TRUST_GIFTS_ROUTES.PartnerRemainderComplete
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerSeparateTrustAgeReqsFinalDistro,
    element: <PartnerSeparateTrustAgeReqsFinalDistroStep />,
    rules: TRUST_GIFTS_ROUTES.PartnerRemainderComplete
  },
  // Set Individual %s and Run Through Loop
  // TODO add additional handling for RemainderAllocate tailored for the diff't groups we are sending there
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderAllocate,
    element: <PartnerRemainderAllocateStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.gifts.remainderDistribType"
            },
            DistribType.SEPARATE_CHILDREN
          ]
        },
        TRUST_GIFTS_ROUTES.PartnerRemainderSeparateChildrenTrustLoop,
        TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiariesLoop
      ]
    }
  },
  // LOOP FOR SEPARATE CHILDREN'S TRUST STARTS HERE
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderSeparateChildrenTrustLoop,
    loop: {
      enabled: true,
      iterationCountSource:
        "users.1.assets.estateBalance.0.beneficiaries"
    },
    children: [
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderChildrenSeparateTrustSetup,
        element: <PartnerRemainderSeparateTrustSetupStep />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingType"
                },
                VestingType.AGE_BASED
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderChildrenSeparateTrustAgeReq,
            TRUST_GIFTS_ROUTES.PartnerRemainderChildrenBeneficiaryFails
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderChildrenSeparateTrustAgeReq,
        element: <PartnerSeparateTrustAgeReqsStep />,
        rules:
          TRUST_GIFTS_ROUTES.PartnerRemainderChildrenBeneficiaryFails
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderChildrenBeneficiaryFails,
        element: <PartnerBeneficiaryDistribution />,
        // Break if not the first one...
        rules: {
          if: [
            {
              and: [
                { "==": [{ var: ITERATION_QUERY_KEY }, "0"] },
                {
                  ">": [
                    {
                      var: "users.1.assets.estateBalance.0.beneficiaries.length"
                    },
                    "1"
                  ]
                }
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderChildrenSeparateTrustCopy,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderChildrenSeparateTrustCopy,
        element: <PartnerRemainderSeparateTrustCopyStep />,
        // If copy true, break and move on...
        rules: {
          if: [
            {
              "==": [
                { var: "users.1.gifts.remainderCopyTrustSetup" },
                true
              ]
            },
            {
              path: TRUST_GIFTS_ROUTES.PartnerRemainderComplete,
              break: true
            },
            NEXT_ITERATION_RULE
          ]
        }
      }
    ],
    rules: TRUST_GIFTS_ROUTES.PartnerRemainderComplete
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiariesLoop,
    loop: {
      enabled: true,
      iterationCountSource:
        "users.1.assets.estateBalance.0.beneficiaries"
    },
    children: [
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderDistribTypeLooped,
        element: <PartnerRemainderDistribTypeLoopedStep />,
        /* 
        If outright, beneficiaryFails
        If trust, set up trust restrictions + age-vesting
        */
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.1.assets.estateBalance.0.beneficiaries.${iteration}.distribType"
                },
                DistribType.OUTRIGHT
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryFails,
            TRUST_GIFTS_ROUTES.PartnerRemainderSeparateTrustSetupLooped
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderSeparateTrustSetupLooped,
        element: <PartnerRemainderSeparateTrustSetupStep />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingType"
                },
                VestingType.AGE_BASED
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderSeparateTrustAgeReq,
            TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryFails
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderSeparateTrustAgeReq,
        element: <PartnerSeparateTrustAgeReqsStep />,
        rules:
          TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryFails
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryFails,
        element: <PartnerBeneficiaryDistribution />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.CHARITY
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryCharity,
            {
              "==": [
                {
                  var: "users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.BACKUP_BENEFICIARIES
              ]
            },
            TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryBackups,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryBackups,
        element: <PartnerRemainderBackupBeneficiariesStep />,
        rules: NEXT_ITERATION_RULE
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderAllocateBeneficiaryCharity,
        element: <PartnerCharityBackup />,
        // if all to partner OR only one beneficiary, break
        rules: NEXT_ITERATION_RULE
      },
      {
        path: TRUST_GIFTS_ROUTES.PartnerRemainderSeparateTrustCopy,
        element: <PartnerRemainderSeparateTrustCopyStep />,
        // If true, break; if false, repeat loop
        rules: NEXT_ITERATION_RULE
      }
    ],
    rules: TRUST_GIFTS_ROUTES.PartnerRemainderComplete
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerRemainderComplete,
    element: <PartnerRemainderCompleteStep />,
    rules: TRUST_GIFTS_ROUTES.PartnerExcludePerson
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerExcludePerson,
    element: <PartnerExcludedPersonsStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.gifts.excludePersons" }, true] },
        TRUST_GIFTS_ROUTES.PartnerDesignateExcludedPersons,
        {
          if: [
            {
              or: [
                { "==": [{ var: "planOption" }, PlanOption.JOINT] },
                {
                  "==": [{ var: "users.0.trust.isJointTrust" }, true]
                }
              ]
            },
            TRUST_GIFTS_ROUTES.ReviewTabs,
            TRUST_GIFTS_ROUTES.PartnerIncludeNoContest
          ]
        }
      ]
    }
  },
  {
    path: TRUST_GIFTS_ROUTES.PartnerDesignateExcludedPersons,
    element: <PartnerDesignateExcludedPersonsStep />,
    rules: {
      if: [
        {
          or: [
            { "==": [{ var: "planOption" }, PlanOption.JOINT] },
            { "==": [{ var: "users.0.trust.isJointTrust" }, true] }
          ]
        },
        TRUST_GIFTS_ROUTES.ReviewTabs,
        TRUST_GIFTS_ROUTES.PartnerIncludeNoContest
      ]
    }
  },
  // Partner No Contest
  {
    path: TRUST_GIFTS_ROUTES.PartnerIncludeNoContest,
    element: <PartnerIncludeNoContestStep />,
    rules: TRUST_GIFTS_ROUTES.ReviewTabs
  },
  // REVIEW SECTIONS HERE...
  {
    path: TRUST_GIFTS_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${TRUST_SECTION_ROUTES.Assets}/${TRUST_ASSETS_ROUTES.Preview}`
  },
  {
    path: TRUST_GIFTS_ROUTES.ReviewTabs,
    element: <ReviewTabsStepPage />,
    rules: `${TRUST_SECTION_ROUTES.Assets}/${TRUST_ASSETS_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={TRUST_GIFTS_ROUTES.Preview} />
  }
];

export default giftsSteps;
