import { useMemo } from "react";
import HandshakeIcon from "@mui/icons-material/Handshake";
import TimerIcon from "@mui/icons-material/Timer";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import IconList from "@components/IconList";
import { PlanType } from "@api/__generated__/globalTypes";

import { WhatToExpectPartialProps } from "../types";

const WhatToExpectPartial: React.FC<WhatToExpectPartialProps> = ({
  planType
}) => {
  const items = useMemo(() => {
    if (planType === PlanType.TRUST) {
      return [
        {
          icon: <HandshakeIcon />,
          label:
            "Types of questions. We’ll ask some questions to better get to know you, the loved ones in your plan, and your end of life wishes."
        },
        {
          icon: <DynamicFormIcon />,
          label:
            "Answers are saved as you go. Your answers are saved at each question, so if your celebrity crush knocks on your door unexpectedly, you can always pick up where you left off."
        },

        {
          icon: <TimerIcon />,
          label:
            "Timing. Our members typically complete their Revocable Living Trust in 15-20 minutes."
        },
        {
          icon: <QuestionAnswerIcon />,
          label:
            "Support. We know that creating an estate plan is not an every day thing. If you are unsure about a question or would like some extra help, engage with our member success team. Even if we’re not in-office, we always respond as soon as possible."
        }
      ];
    }

    if (planType === PlanType.PET_PROTECTION) {
      return [
        {
          icon: <HandshakeIcon />,
          label:
            "Types of questions. We’ll ask some questions to better get to know you and your pet."
        },
        {
          icon: <DynamicFormIcon />,
          label:
            "Answers are saved as you go. Your answers are saved at each question, so if your celebrity crush knocks on your door unexpectedly, you can always pick up where you left off."
        },
        {
          icon: <TimerIcon />,
          label:
            "Timing. Our members typically complete their Pet Estate Plan in 2-5 minutes."
        },
        {
          icon: <QuestionAnswerIcon />,
          label:
            "Support. We know that creating an estate plan is not an every day thing. If you are unsure about a question or would like some extra help, engage with our member success team. Even if we’re not in-office, we always respond as soon as possible."
        }
      ];
    }

    return [
      {
        icon: <HandshakeIcon />,
        label:
          "Types of questions. We’ll ask some questions to better get to know you, the loved ones in your plan, and your end of life wishes."
      },
      {
        icon: <DynamicFormIcon />,
        label:
          "Answers are saved as you go. Your answers are saved at each question, so if your celebrity crush knocks on your door unexpectedly, you can always pick up where you left off."
      },

      {
        icon: <TimerIcon />,
        label:
          "Timing. Our members typically complete their Last Will in 10-15 minutes."
      },
      {
        icon: <QuestionAnswerIcon />,
        label:
          "Support. We know that creating an estate plan is not an every day thing. If you are unsure about a question or would like some extra help, engage with our member success team. Even if we’re not in-office, we always respond as soon as possible."
      }
    ];
  }, [planType]);

  return <IconList items={items} />;
};

export default WhatToExpectPartial;
