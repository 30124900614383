import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { numberWithCommas } from "@utils/misc";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { PartnerType } from "@api/__generated__/globalTypes";
import { ReviewCardProps } from "@components/ReviewCard/types";

import { TRUST_ASSETS_ROUTES } from "../../consts";

import {
  getHumanizedPersonalPropertyType,
  useAssetReviewCardItems,
  useFundedAndNotFundedAssets
} from "./utils";

export const usePrimaryDrafterAssetsReviewTab = ({
  values,
  partnerType
}: {
  values: LastWillPlanValues;
  partnerType: PartnerType;
}): [ReviewItem[], ReviewCardProps[], ReviewCardProps[]] => {
  const { formatMessage } = useIntl();

  const {
    users: [primaryDrafter]
  } = values;

  const lowerPartnerType = partnerType.toLowerCase();
  const { personalPropertyBalanceType } = primaryDrafter?.gifts || {};

  const { fundedAssets, notFundedAssets } =
    useFundedAndNotFundedAssets(primaryDrafter.assets);

  const fundedReviewItems = useAssetReviewCardItems(
    fundedAssets,
    "users.0"
  );
  const notFundedReviewItems = useAssetReviewCardItems(
    notFundedAssets,
    "users.0"
  );

  const cashGifts = primaryDrafter?.assets?.cash;

  // Personal Property Balance
  const personalPropertyBalanceReviewItem = useMemo<
    ReviewItem | undefined
  >(
    () =>
      personalPropertyBalanceType
        ? {
            title: formatMessage({
              defaultMessage:
                "Gift of Your Personal Property Balance:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Assets,
              TRUST_ASSETS_ROUTES.PersonalPropertyBalance
            ]),
            items: [
              [
                getHumanizedPersonalPropertyType(
                  personalPropertyBalanceType,
                  lowerPartnerType,
                  primaryDrafter.assets?.personalPropertyBalance
                )
              ].filter(Boolean)
            ]
          }
        : undefined,
    [
      formatMessage,
      personalPropertyBalanceType,
      primaryDrafter.assets?.personalPropertyBalance,
      lowerPartnerType
    ]
  );

  // Cash Gifts
  const cashGiftReviewItem = useMemo<ReviewItem | undefined>(
    () =>
      cashGifts &&
      cashGifts.flatMap((gift) => gift.beneficiaries).length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Cash Gifts:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Assets,
              TRUST_ASSETS_ROUTES.ListCash
            ]),
            items: cashGifts.map(({ beneficiaries = [] }) =>
              [
                ...beneficiaries.map(
                  (beneficiary) =>
                    `$${numberWithCommas(
                      beneficiary.dollarValue
                    )} to ${
                      beneficiary.name ?? beneficiary?.charity?.name
                    }`
                )
              ].filter(Boolean)
            )
          }
        : undefined,
    [formatMessage, cashGifts]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [personalPropertyBalanceReviewItem, cashGiftReviewItem].filter(
        (item): item is ReviewItem => Boolean(item)
      ),
    [personalPropertyBalanceReviewItem, cashGiftReviewItem]
  );

  return [items, fundedReviewItems, notFundedReviewItems];
};
