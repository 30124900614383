import { useCallback, useMemo } from "react";
import * as yup from "yup";

import { UpdateFileInput } from "@api/__generated__/globalTypes";
import useToggle from "@hooks/useToggle";
import { useGetPlansQuery } from "@api/plans";
import { useUpdateFileMutation } from "@api/files/hooks";
import { useFormWithYup } from "@hooks/useFormWithYup";

import {
  UseUpdateDocumentFormParams,
  UseUpdateDocumentParams
} from "./types";

export const useUpdateDocument = ({
  id
}: UseUpdateDocumentParams) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { refetch } = useGetPlansQuery();
  const [updateFile, { loading, error }] = useUpdateFileMutation({
    onCompleted: () => {
      toggleOff();
      refetch();
    }
  });

  const onSubmit = useCallback(
    async (data: UpdateFileInput) => {
      if (!id) return;
      await updateFile({
        variables: {
          id,
          input: {
            name: data.name
          }
        }
      });
    },
    [id, updateFile]
  );

  return {
    isOpen,
    loading,
    error,
    toggleOn,
    toggleOff,
    onSubmit
  };
};

export const useValidationSchema = () =>
  useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required()
      }),
    []
  );

export const useUpdateDocumentForm = ({
  onSubmit,
  defaultValues
}: UseUpdateDocumentFormParams) => {
  const validationSchema = useValidationSchema();
  const methods = useFormWithYup<UpdateFileInput>(validationSchema, {
    defaultValues
  });

  const onFormSubmit = useCallback(
    (data: UpdateFileInput) => {
      onSubmit(data);
    },
    [onSubmit]
  );

  return {
    methods,
    handleSubmit: methods.handleSubmit(onFormSubmit)
  };
};
