import { PowerOfAttorneyAgent } from "@pages/LastWill/types";

const getPoaAgentInfo = (agent: PowerOfAttorneyAgent) =>
  [
    `${agent?.name ?? ""}`,
    `${agent?.address?.address1 ?? ""}`,
    `${agent?.address?.address2 ?? ""}`,
    agent.address
      ? `${agent?.address?.city}, ${agent?.address?.state} ${agent?.address?.zip}`
      : ""
  ].filter(Boolean);

export default getPoaAgentInfo;
