import { capitalize, startCase } from "lodash";

import { PlanOption, PlanType } from "@api/__generated__/globalTypes";
import { MembershipRole } from "@setup/enums";
import { Plans_plans as Plans } from "@api/plans/__generated__/Plans";
import { LastWill_childs_guardians as Guardian } from "@api/plans/__generated__/LastWill";
import { BasicRoles } from "@providers/memberPlansState/type";
import { useGetPlanDraftQuery } from "@api/planDrafts/hooks";
import useUserContext from "@providers/user/hooks";
import { User } from "@api/me/__generated__/User";

export const humanizeMembershipRoles = (role: MembershipRole) =>
  startCase(role.replace(/_/g, " ").toLowerCase());

export const getEstatePlanUserName = (
  plans: Plans | null,
  myRoles: BasicRoles | undefined
): string => {
  if (!plans && !myRoles) return "";

  if (plans) {
    const partnerFullName =
      `${plans.partner?.firstName} ${plans.partner?.lastName}` ||
      plans.partner?.fullName;

    const primaryDrafterFullName =
      `${plans.primaryDrafter?.firstName} ${plans.primaryDrafter?.lastName}` ||
      plans.primaryDrafter?.fullName;

    if (plans.planOption === PlanOption.SINGLE) {
      const title = primaryDrafterFullName
        ? `${primaryDrafterFullName}`
        : "Your";

      return `${title}`;
    }

    if (plans.primaryDrafter?.lastName === plans.partner?.lastName) {
      return `${plans.primaryDrafter?.firstName} and ${partnerFullName}`;
    }

    return `${primaryDrafterFullName} and ${partnerFullName}`;
  }

  return (
    `${myRoles?.accountOwner?.firstName} ${myRoles?.accountOwner?.lastName}` ||
    `${myRoles?.accountOwner?.fullName}`
  );
};

export const useLoggedInUserName = (): string => {
  const draftUserData: User[] = useGetPlanDraftQuery({
    variables: {
      type: PlanType.TRUST || PlanType.LAST_WILL || PlanType.PET_PROTECTION
    }
  }).data?.planDraft?.data?.users;

  const userDraftFullName =
    draftUserData?.length > 0 &&
    draftUserData[0].firstName &&
    draftUserData[0].lastName
      ? `${draftUserData[0].firstName} ${draftUserData[0].lastName}`
      : "New Member";

  return useUserContext().user?.fullName || userDraftFullName;
};

export const getEstatePlanTitle = (
  plans: Plans | null,
  myRoles: BasicRoles | undefined
): string => {
  const name = getEstatePlanUserName(plans, myRoles);

  if (
    plans?.trusts ||
    plans?.lastWills ||
    plans?.petProtection ||
    myRoles
  ) {
    return `${name}'s Estate Plan`;
  }

  return "Your Estate Plan";
};

export const getLinkFullUrl = (routes: string[]) => routes.join("/");

export const getChildPrimaryGuardianInfo = (
  guardians: (Guardian | null)[],
  partnerAsChildGuardian: boolean | undefined,
  partnerType: string | null | undefined
) => {
  if (partnerAsChildGuardian && partnerType) {
    return `Primary Guardian: Surviving ${capitalize(partnerType)}`;
  }

  if (guardians?.length) {
    return `Primary Guardian: ${guardians
      ?.map((guardian) => guardian?.guardian?.fullName)
      .join(", ")}`;
  }

  return "";
};
