import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthLayout from "@components/AuthLayout";
import ButtonsList from "@components/ButtonsList";
import { ROUTES } from "@setup/consts/routes";
import PageTitle from "@components/PageTitle";

import {
  useButtonsListItem,
  useSignInForm,
  useSignInFormValidationSchema
} from "./hooks";
import { SignInFormDataProps } from "./types";
import * as Styled from "./styles";

const SignIn: React.FC = () => {
  const intl = useIntl();
  const buttonsListItems = useButtonsListItem();
  const theme = useTheme();
  const validationSchema = useSignInFormValidationSchema();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError
  } = useForm<SignInFormDataProps>({
    resolver: yupResolver(validationSchema)
  });
  const { errorMessage, handleLogin } = useSignInForm(setError);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ defaultMessage: "Sign In" })}
      />
      <AuthLayout
        title={intl.formatMessage({
          defaultMessage: "Sign in to JIC Estates"
        })}
        showJICLgo
        isLoading={isSubmitting}
      >
        <ButtonsList items={buttonsListItems} />
        <Divider
          sx={{
            width: "100%",
            my: 2.5,
            color: theme.palette.grey["400"]
          }}
        >
          <FormattedMessage defaultMessage="or" />
        </Divider>
        <Box
          component="form"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
          onSubmit={handleSubmit(handleLogin)}
        >
          <FormControl
            error={!!errorMessage}
            sx={{ display: "flex", width: "100%" }}
          >
            <FormControl sx={{ width: "100%", mb: "1.25rem" }}>
              <TextField
                label={
                  <FormattedMessage defaultMessage="Email Address" />
                }
                variant="standard"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email", { required: true })}
              />
            </FormControl>
            <FormControl sx={{ width: "100%", mb: "1.25rem" }}>
              <TextField
                label={<FormattedMessage defaultMessage="Password" />}
                variant="standard"
                type="password"
                error={!!errors.password}
                helperText={errors.password?.message}
                {...register("password", { required: true })}
              />
            </FormControl>
            {errorMessage && (
              <FormHelperText sx={{ ml: 0, mb: 2.5 }}>
                {errorMessage}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            variant="contained"
            size="medium"
            type="submit"
            sx={{
              width: {
                xs: "100%",
                md: "auto"
              }
            }}
          >
            <FormattedMessage defaultMessage="Sign In" />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "2rem"
          }}
        >
          <Typography variant="body2" textAlign="center" mb="2rem">
            <FormattedMessage defaultMessage="Don't have an account yet?" />
            <Styled.Link to={ROUTES.SignUp}>
              <FormattedMessage defaultMessage="Register now." />
            </Styled.Link>
          </Typography>
          <Typography variant="body2" textAlign="center" mb="2rem">
            <FormattedMessage defaultMessage="or, " />
            <Styled.Link to={ROUTES.ResetPassword}>
              <FormattedMessage defaultMessage="reset password." />
            </Styled.Link>
          </Typography>
        </Box>
      </AuthLayout>
    </>
  );
};

export default SignIn;
