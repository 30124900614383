import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerMakeCashGiftStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );
  const { setValue: setCashGifts } = useFormField(
    "users.1.assets.cash"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const isCashGifts = get(data, "users.1.gifts.makeCashGifts");

      if (!parseBoolean(isCashGifts)) {
        setCashGifts([]);
      }
    },
    [setCashGifts]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Last one! Does ${partnerFirstName} want to make any other cash gifts from ${partnerFirstName}'s Trust?`
      }}
      {...{ onSubmit }}
      name="users.1.gifts.makeCashGifts"
    />
  );
};

export default PartnerMakeCashGiftStep;
