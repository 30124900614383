import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useAdditionalPowersInfoLabels = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      digitalAccess: formatMessage({
        defaultMessage:
          "Access your digital assets and communications"
      }),
      makeGifts: formatMessage({
        defaultMessage:
          "Make gifts to people and causes you care about"
      }),
      relatedTx: formatMessage({
        defaultMessage: "Engage in related-party transactions"
      }),
      transferToTrust: formatMessage({
        defaultMessage:
          "Transfer property to your revocable living trust"
      }),
      delegate: formatMessage({
        defaultMessage: "Delegate powers under this POA"
      }),
      fiduciary: formatMessage({
        defaultMessage: "Exercise fiduciary powers that you may hold"
      })
    }),
    [formatMessage]
  );
};
