import { FormProvider } from "react-hook-form";
import { FormControl, Stack } from "@mui/material";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormInput from "@components/FormFields/FormInput";
import { useDisableForm } from "@providers/disableForm/context";

import { useSingleResponseArchetype } from "./hooks";
import { SingleResponseArchetypeProps } from "./types";

const SingleResponseArchetype: React.FC<
  SingleResponseArchetypeProps
> = ({
  name,
  label,
  onSubmit: onFormSubmit,
  stepLayout,
  fieldProps = {}
}) => {
  const { controls, goBack, onSubmit } = useSingleResponseArchetype(
    name,
    onFormSubmit
  );

  const { isDisabled: isFormDisabled } = useDisableForm();

  return (
    <StepLayout {...stepLayout}>
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <Stack spacing={4}>
            <FormControl variant="standard" sx={{ display: "flex" }}>
              <FormInput
                {...{ name, label }}
                variant="outlined"
                {...fieldProps}
              />
            </FormControl>
          </Stack>
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit",
              disabled: isFormDisabled
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default SingleResponseArchetype;
