import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from "react";
import { useIntl } from "react-intl";
import noop from "lodash/noop";

import ProgressBarSection from "@components/ProgressBarSection";
import Header from "@components/Header";
import useProgressBarContext from "@providers/progressBar/hooks";
import PageTitle from "@components/PageTitle";
import ChatModal from "@components/ChatModal";

import { useMainNavigation } from "./hooks";
import { LayoutContextProvider } from "./types";
import PaymentSuccessModal from "./Partials/PaymentSuccessModal";

const LayoutContext = createContext<LayoutContextProvider>({
  accessiblePlanPaths: {},
  setAccessiblePlanPaths: noop
});

export const useLayoutContext = (): LayoutContextProvider =>
  useContext(LayoutContext);

const LayoutProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const intl = useIntl();
  const { progress, title } = useProgressBarContext();
  const { headerProps, loading } = useMainNavigation();

  const [accessiblePlanPaths, setAccessiblePlanPaths] = useState({});

  const layoutContextValues = useMemo(
    () => ({
      accessiblePlanPaths,
      setAccessiblePlanPaths
    }),
    [accessiblePlanPaths]
  );

  const showAssistant = !!progress && !!title;

  return (
    <LayoutContext.Provider value={layoutContextValues}>
      <PageTitle
        title={intl.formatMessage({
          defaultMessage: "JIC Estates"
        })}
      />
      <Header
        {...headerProps}
        isLoading={loading}
        showAssistant={showAssistant}
      />
      <div id="au" />
      {progress && title ? (
        <div>
          <ProgressBarSection {...{ progress, title }} />
          <ChatModal />
        </div>
      ) : null}

      {children}
      <PaymentSuccessModal />
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
