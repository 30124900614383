import {
  Box,
  useTheme,
  CircularProgress,
  useMediaQuery
} from "@mui/material";

import { SharedPlanDetailProps } from "../../Tabs/SharedWithMeSummary/types";
import SharedPlansTitleSection from "../../Tabs/SharedWithMeSummary/SharedPlansTitleSection";
import SharedPlanCardLayout from "../../Tabs/SharedWithMeSummary/SharedPlanCardLayout";
import SharedPlanTable from "../../Tabs/SharedWithMeSummary/SharedPlanTable";
import NoSharedPlansMessage from "../../Tabs/SharedWithMeSummary/NoSharedPlansMessage";

import useClientInviteMetrics from "./useClientInviteMetrics";
import ClientInviteMetrics from "./ClientInviteMetrics";
import InviteClient from "./InviteClient";

const AdvisorDashboard: React.FC<SharedPlanDetailProps> = ({
  plans = []
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  
  const {
    metrics,
    invitationsData,
    draftPlansData,
    loading,
    refetch
  } = useClientInviteMetrics();
  
  return (
    <Box>
      <SharedPlansTitleSection />
      <InviteClient refetch={refetch}/>
      {loading ? (
        <CircularProgress size={16} sx={{ ml: 2 }} />
      ) : (
        <Box>
          <Box
            sx={{
              mt: "2.5rem",
              py: "1.25rem",
              borderRadius: "2px",
              boxShadow: `0px 2px 12px ${theme.palette.grey[500]}`,
              overflow: "hidden"
            }}
          >
            <ClientInviteMetrics metrics={metrics} />
          </Box>
          <Box>
            {plans.length === 0 && draftPlansData.length === 0 && (
              <Box my="2.25rem">
                <NoSharedPlansMessage />
              </Box>
            )}
            {(plans.length > 0 ||
              draftPlansData.length > 0 ||
              invitationsData.length > 0) && (
              <Box my="2.25rem">
                {!isDesktop && (
                  <SharedPlanCardLayout
                    plans={plans}
                    draftPlans={draftPlansData}
                    invitations={invitationsData}
                  />
                )}
                {isDesktop && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      minHeight: "37.5rem",
                      width: "100%"
                    }}
                  >
                    <SharedPlanTable
                      plans={plans}
                      draftPlans={draftPlansData}
                      invitations={invitationsData}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AdvisorDashboard;
