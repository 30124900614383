import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";

const ExpertTypeStep: React.FC = () => {
  /*
   TODO: Next iteration to have pic for legal vs. financial help with following info (see atticus):
   - who's it for:
   - what you get:
  */

  const { setValue: setHouseholdDataValue } =
    useFormField("householdData");
  const { setValue: setEstateDataValue } = useFormField("estateData");

  const onSubmit = useCallback(
    ({ expertType }: SingleSelectFormValues) => {
      if (expertType === "LEGAL") {
        setHouseholdDataValue({});
      }
      if (expertType === "FINANCIAL") {
        setEstateDataValue({});
      }
    },
    [setHouseholdDataValue, setEstateDataValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `What type of expert help are you interested in?`
      }}
      items={[
        { label: "Expert Legal Help", value: "LEGAL" },
        {
          label: "Expert Financial Planning and Management",
          value: "FINANCIAL"
        },
        { label: "Both Legal and Financial Help", value: "BOTH" }
      ]}
      name="expertType"
      onSubmit={onSubmit}
    />
  );
};

export default ExpertTypeStep;
