import { IntlShape } from "react-intl";
import uniqueId from "lodash/uniqueId";

import {
  PlanDocument,
  PlanType
} from "@api/__generated__/globalTypes";
import {
  DocumentsListGroupItem,
  DocumentsListItem
} from "@components/Documentslist/types";
import { GetDraftDocuments_planDraftDocuments as DocumentDataType } from "@api/planDraftDocuments/__generated__/GetDraftDocuments";

import { GetDocumentSectionCopyReturnType } from "./types";

export const getDocumentSectionCopy = (
  documentType: PlanDocument,
  intl: IntlShape,
  planType?: PlanType
): GetDocumentSectionCopyReturnType => {
  switch (true) {
    case documentType === PlanDocument.LAST_WILL_DOCUMENT:
      return {
        title: intl.formatMessage({
          defaultMessage: "Last Will & Testament"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Specify your wishes for the care of dependents and how you would like your assets distributed"
        })
      };
    case documentType === PlanDocument.JOINT_TRUST_DOCUMENT:
      return {
        title: intl.formatMessage({
          defaultMessage: "Joint Revocable Trust"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Specify how you would like your assets distributed to loved ones or causes you care about"
        })
      };
    case documentType === PlanDocument.SEPARATE_TRUST_DOCUMENT:
      return {
        title: intl.formatMessage({
          defaultMessage: "Revocable Living Trust"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Specify how you would like your assets distributed to loved ones or causes you care about"
        })
      };
    case documentType === PlanDocument.POUR_OVER_WILL_DOCUMENT:
      return {
        title: intl.formatMessage({
          defaultMessage: "Pour Over Will"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Specify your wishes for the care of dependents and fund all your remaining assets to your Trust"
        })
      };
    case documentType === PlanDocument.TRUST_CERTIFICATE_DOCUMENT:
      return {
        title: intl.formatMessage({
          defaultMessage: "Certificate of Trust"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "A brief document outlining the existence of your trust and the trustee powers. Useful for interacting with financial institutions on the trust's behalf."
        })
      };
    case documentType === PlanDocument.PET_PROTECTION_DOCUMENT:
      return {
        title: intl.formatMessage({
          defaultMessage: "Pet Protection Plan"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Make sure your pet continues to receive proper care and comfort -- no matter what"
        })
      };
    case documentType === PlanDocument.HEALTH_CARE_DOCUMENT:
      return {
        title: intl.formatMessage({
          defaultMessage: "Advance Healthcare Directive"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Express your healthcare wishes in advance and designate someone to be your agent in the event you are unable to communicate decisions about your healthcare"
        })
      };
    case documentType === PlanDocument.HIPAA_DOCUMENT &&
      planType === PlanType.LAST_WILL:
      return {
        title: intl.formatMessage({
          defaultMessage: "HIPAA Release & Authorization"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Grant trusted individuals access to your medical information"
        })
      };
    case documentType === PlanDocument.HIPAA_DOCUMENT &&
      planType === PlanType.PET_PROTECTION:
      return {
        title: intl.formatMessage({
          defaultMessage: "Limited HIPAA Release & Authorization"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Grant trusted individuals highly limited access to your medical information for the specific purpose of determining if you are able to continue to care for your pet"
        })
      };
    case documentType === PlanDocument.POA_DOCUMENT &&
      planType === PlanType.LAST_WILL:
      return {
        title: intl.formatMessage({
          defaultMessage: "Financial Power of Attorney"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Designate someone to be your agent to manage your personal, business, or other financial affairs"
        })
      };
    case documentType === PlanDocument.POA_DOCUMENT &&
      planType === PlanType.PET_PROTECTION:
      return {
        title: intl.formatMessage({
          defaultMessage: "Limited Financial Power of Attorney"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "Grant trusted individuals limited access to conduct financial transactions in your name for the specific purpose of supporting the wellbeing of your pet"
        })
      };
    default:
      return {
        title: "",
        description: ""
      };
  }
};

export const mapPlanDocumentsToUserDocuments = (
  intl: IntlShape,
  planType: PlanType,
  data?: DocumentDataType["primaryDrafterDocuments"],
  owner?: string
): DocumentsListGroupItem[] => {
  if (!data) return [];

  return data.reduce<DocumentsListGroupItem[]>(
    (result, currentItem) => {
      if (!currentItem) return result;

      return [
        ...result,
        {
          key: uniqueId(),
          owner,
          ...getDocumentSectionCopy(currentItem, intl, planType),
          planType,
          documentType: currentItem
        }
      ];
    },
    []
  );
};

export const getUserDocumentsByType = (
  documents: DocumentsListGroupItem[],
  type: PlanDocument[],
  title: string
): DocumentsListItem[] => {
  const filteredDocuments = documents
    ? documents.filter(({ documentType }) =>
        type.includes(documentType)
      )
    : undefined;

  return filteredDocuments?.length
    ? [
        {
          key: uniqueId(),
          title,
          items: [...(filteredDocuments ?? [])]
        }
      ]
    : [];
};
