import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";

import {
  Asset,
  AssetList,
  Beneficiary,
  LastWillMember
} from "@pages/LastWill/types";
import { ReviewCardProps } from "@components/ReviewCard/types";
import { useAssetHumanizedTitle } from "@hooks/useAssetHumanizedTitle";
import { usePersonalPropertyTypeIcons } from "@pages/EstatePlanView/Partials/hooks";
import {
  AssetType,
  PersonalPropertyBalanceDistributionType
} from "@api/__generated__/globalTypes";
import { ASSET_TYPE_ICONS } from "@setup/consts/assetTypeIcons";
import isAssetOutsideLastWill from "@pages/EstatePlanView/Partials/Tabs/AssetsTab/utils/isAssetOutsideLastWill";
import { numberWithCommas } from "@utils/misc";
import EditAssetModal from "@components/QuestionnaireAssetModal/EditAssetModal";
import { EditAssetButton } from "@pages/EstatePlanView/Partials/Tabs/AssetsTab/styles";

export const getAssetBeneficiaryInfo = (
  beneficiaries: Beneficiary[],
  isFunded: boolean | undefined
) =>
  beneficiaries && beneficiaries.length > 0
    ? `${
        isFunded
          ? "🎁  This asset is a specific gift "
          : "🎁  If this asset is funded into your Trust, it will be distributed as a specific gift "
      } for the following beneficiaries: ${beneficiaries
        .map(
          (beneficiary) =>
            `${beneficiary.percentageValue}% to ${
              beneficiary.name ?? beneficiary?.charity?.name
            }`
        )
        .join(", ")}`
    : `${
        isFunded
          ? "🎁  This asset"
          : "🎁  If this asset is funded into your Trust, it "
      } will be distributed with your Trust Remainder.`;

export const getNotFundedDistributionText = (asset: Asset) =>
  isAssetOutsideLastWill(asset)
    ? [
        "👀 You haven't funded this non-probate asset to your trust.",
        "👀 Unless you fund to your trust, it will pass outside of your Trust based on the beneficiary designation on the account."
      ]
    : [
        "👀 You haven't funded this asset to your trust yet.",
        "👀 Unless you fund to your trust during your life, this asset will need to pass through probate before it becomes part of and distributed by your trust (except if it has a different built-in beneficiary designation on the title)."
      ];

export const useFundedAndNotFundedAssets = (
  assetList: AssetList | undefined
) =>
  useMemo(() => {
    const fundedAssets: Asset[] = [];
    const notFundedAssets: Asset[] = [];

    // Check personalProperty assets

    if (assetList?.personalProperty) {
      const personalPropertyAssets =
        assetList.personalProperty.filter((asset) => asset);

      fundedAssets.push(
        ...personalPropertyAssets.filter((asset) => asset.isFunded)
      );
      notFundedAssets.push(
        ...personalPropertyAssets.filter((asset) => !asset.isFunded)
      );
    }

    // Check realEstates assets
    if (assetList?.realEstates) {
      const realEstateAssets = assetList.realEstates.filter(
        (asset) => asset
      );

      fundedAssets.push(
        ...realEstateAssets.filter((asset) => asset.isFunded)
      );
      notFundedAssets.push(
        ...realEstateAssets.filter((asset) => !asset.isFunded)
      );
    }

    // Check financialAccounts assets
    if (assetList?.financialAccounts) {
      const financialAccountAssets =
        assetList.financialAccounts.filter((asset) => asset);

      fundedAssets.push(
        ...financialAccountAssets.filter((asset) => asset.isFunded)
      );
      notFundedAssets.push(
        ...financialAccountAssets.filter((asset) => !asset.isFunded)
      );
    }

    // Check businessInterests assets
    if (assetList?.businessInterests) {
      const businessInterestAssets =
        assetList.businessInterests.filter((asset) => asset);

      fundedAssets.push(
        ...businessInterestAssets.filter((asset) => asset.isFunded)
      );
      notFundedAssets.push(
        ...businessInterestAssets.filter((asset) => !asset.isFunded)
      );
    }

    // Check lifeInsurancePolicies assets
    if (assetList?.lifeInsurancePolicies) {
      const lifeInsurancePolicies =
        assetList.lifeInsurancePolicies.filter((asset) => asset);

      fundedAssets.push(
        ...lifeInsurancePolicies.filter((asset) => asset.isFunded)
      );
      notFundedAssets.push(
        ...lifeInsurancePolicies.filter((asset) => !asset.isFunded)
      );
    }

    // Check otherAssets assets
    if (assetList?.otherAssets) {
      const otherAssets = assetList.otherAssets.filter(
        (asset) => asset
      );

      fundedAssets.push(
        ...otherAssets.filter((asset) => asset.isFunded)
      );
      notFundedAssets.push(
        ...otherAssets.filter((asset) => !asset.isFunded)
      );
    }

    return {
      fundedAssets,
      notFundedAssets
    };
  }, [assetList]);

export const useAssetReviewCardItems = (
  assets: Asset[],
  userIndex: string
): ReviewCardProps[] => {
  const humanizedTitles = useAssetHumanizedTitle();
  const personalPropertyIcons = usePersonalPropertyTypeIcons();

  return useMemo(
    () =>
      assets.map<ReviewCardProps>((asset, idx): ReviewCardProps => {
        const assetNickname =
          asset.type === AssetType.REAL_ESTATE
            ? asset.address?.address1
            : asset.name;
        const assetType = get(humanizedTitles, asset.type || "", "");
        const isPersonalProperty =
          asset.type === AssetType.PERSONAL_PROPERTY;
        const showSubTypeDetail =
          asset.type === AssetType.PERSONAL_PROPERTY ||
          asset.type === AssetType.FINANCIAL_ACCOUNT;

        const icon = isPersonalProperty
          ? get(personalPropertyIcons, asset.subType || "", undefined)
          : get(ASSET_TYPE_ICONS, asset.type || "", undefined);

        // eslint-disable-next-line no-nested-ternary
        const description = showSubTypeDetail
          ? `${assetType} > ${asset.subType}`
          : assetNickname
          ? assetType
          : undefined;

        return {
          href: "",
          icon,
          title: assetNickname || assetType,
          description,
          items: [
            [
              `💰 Estimated Value: ${
                asset.grossValue
                  ? `$${numberWithCommas(asset.grossValue)}`
                  : "Not Specified"
              }`
            ],
            asset.isFunded
              ? [
                  "✅  This asset is funded to your Trust and will be distributed based on your Trust provisions."
                ]
              : getNotFundedDistributionText(asset),
            [
              getAssetBeneficiaryInfo(
                asset.beneficiaries || [],
                asset.isFunded
              )
            ]
          ].filter((item) => item.length > 0),
          editComponent: (
            <EditAssetModal
              trigger={({ onClick }) => (
                <EditAssetButton {...{ onClick }}>
                  <FormattedMessage defaultMessage="Edit" />
                </EditAssetButton>
              )}
              {...{
                asset,
                userIndex,
                targetIndex: idx,
                showIsFunded: true
              }}
            />
          )
        };
      }),
    [assets, userIndex, humanizedTitles, personalPropertyIcons]
  );
};

export const getHumanizedPersonalPropertyType = (
  balanceType: PersonalPropertyBalanceDistributionType | undefined,
  partnerType: string,
  assets?: Asset[]
): string => {
  const beneficiaries = assets?.flatMap(
    (asset) => asset.beneficiaries
  );
  const recipients = beneficiaries?.flatMap((bene) => bene?.name);

  if (
    balanceType ===
    PersonalPropertyBalanceDistributionType.PARTNER_AND_CHILDREN
  ) {
    return `Distribute among my ${partnerType} and children as they may agree.`;
  }

  if (
    balanceType === PersonalPropertyBalanceDistributionType.PARTNER
  ) {
    return `Distribute to my ${partnerType}`;
  }

  if (
    balanceType === PersonalPropertyBalanceDistributionType.CHILDREN
  ) {
    return "Distribute among my children as they may agree";
  }

  if (
    balanceType ===
      PersonalPropertyBalanceDistributionType.OTHER_PEOPLE &&
    assets
  ) {
    return `Distribute among the following people as they may agree: ${recipients
      ?.map((name) => name)
      .join(", ")}`;
  }

  return "Distribute among my named beneficiaries";
};

export const getWhyTransferAssetsContent = (): React.ReactElement => (
  <p>
    To make your revocable living trust most efficient and avoid
    probate, you should transfer ownership of your assets to the
    trust. You can do so by changing the title (i.e., deed) of the
    asset to the name of the trust.
    <br />
    <br />
    As the Trustmaker and Trustee of your trust, you&apos;ll continue
    to have full control over the assets and maintain, improve, or
    sell them as you would normally.
    <br />
    <br />
    By changing the title on the asset to the name of the trust, you
    effectively designate the trust as the built-in beneficiary of the
    asset. That way, when you pass away, the asset can pass directly
    without the need to go through probate based on the terms of your
    trust.
    <br />
    <br />
    The process to transfer title of an asset to your trust varies
    depending on the type of asset. You can read more in our guide to
    trust funding, or reach out to our member success team for
    assistance.
  </p>
);

export const getHowToTransferAssetsContent = (
  user: LastWillMember
): React.ReactElement => (
  <p>
    Keep in mind that it is the actual title on the asset -- not the
    designation in your JIC Estates account -- that identifies whether
    an asset has been successfully transferred to your revocable
    living trust.
    <br />
    <br />
    When transferring title to your assets, the standard way to refer
    to your trust is &quot;[Trust Name] dated [Trust Creation Date]
    and any amendments thereto.&quot; Sign for your trust in the form
    &quot;[Trustee Name], as Trustee for the [Trust Name] dated [Trust
    Creation Date], and any amendments thereto.&quot;
    <br />
    <br />
    For example, &quot;{user.firstName} {user.lastName}, as Trustee
    for the {user.firstName} {user.lastName} Revocable Living Trust
    dated MM/DD/YYYY, and any amendments thereto.&quot;
  </p>
);
