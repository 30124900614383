const getFedEstateTax = (assetValue: string): JSX.Element =>
  assetValue === ">$10,000,000" ? (
    <li>
      <strong>Federal Estate Tax. </strong>This estate may be subject
      to federal estate tax.
    </li>
  ) : (
    <li>
      <strong>Federal Estate Tax. </strong>This estate is not subject
      to federal estate tax.
    </li>
  );

export default getFedEstateTax;
