import * as yup from "yup";
import { useCallback, useMemo } from "react";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  SingleResponseFormValues,
  SingleResponseArchetypeReturnType
} from "./types";

const useSingleResponseSchema = (name: string): yup.AnyObjectSchema =>
  useMemo(() => makeYupSchema(yup.string().nullable(), name), [name]);

const useSingleResponseArchetype = (
  name: string,
  onFormSubmit?: (data: SingleResponseFormValues) => void
): SingleResponseArchetypeReturnType => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useSingleResponseSchema(name);
  const controls = useFormWithYup<SingleResponseFormValues>(schema);

  const onSubmit = useCallback(
    (data: SingleResponseFormValues) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useSingleResponseArchetype };
