import { FormattedMessage } from "react-intl";
import { Button, Grid } from "@mui/material";

import { FormStepFooterProps } from "../FormStepFooter/types";

const ModalStepFooter: React.FC<FormStepFooterProps> = ({
  prevButtonProps,
  nextButtonProps,
  topSpacing
}) => {
  const { label: prevButtonLabel, ...restPrevButtonProps } =
    prevButtonProps || {};
  const { label: nextButtonLabel, ...restNextButtonProps } =
    nextButtonProps || {};

  return prevButtonProps || nextButtonProps ? (
    <Grid
      container
      spacing={2.5}
      mt={topSpacing || { xs: 3.75, md: 2.5 }}
      sx={{
        "> .MuiGrid-root": {
          paddingTop: 0
        }
      }}
    >
      {prevButtonProps && (
        <Grid item xs={12} md={6}>
          <Button
            color="inherit"
            sx={{
              width: "100%",
              minWidth: "auto"
            }}
            {...restPrevButtonProps}
          >
            {prevButtonLabel || (
              <FormattedMessage defaultMessage="Previous" />
            )}
          </Button>
        </Grid>
      )}
      {nextButtonProps && (
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              minWidth: "auto"
            }}
            {...restNextButtonProps}
          >
            {nextButtonLabel || (
              <FormattedMessage defaultMessage="Save &amp; Continue" />
            )}
          </Button>
        </Grid>
      )}
    </Grid>
  ) : null;
};

export default ModalStepFooter;
