import * as yup from "yup";
import { useMemo } from "react";
import get from "lodash/get";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import useToggle from "@hooks/useToggle";
import makeYupSchema from "@utils/makeYupSchema";

import {
  PersonFormValues,
  PersonValues,
  UsePersonDetailsArchetypeReturnType
} from "./types";
import { buildFormAddress, verifyAddress } from "./utils";
import { BASIC_PERSON_DETAILS_SCHEMA } from "./consts";

const usePersonDetailsSchema = (
  name: string,
  defaultArray = false
): yup.SchemaOf<PersonValues> =>
  useMemo(
    () =>
      makeYupSchema(BASIC_PERSON_DETAILS_SCHEMA, name, defaultArray),
    [defaultArray, name]
  );
 
const usePersonDetailsArchetype = (
  name: string,
  onFormSubmit?: (data: PersonFormValues) => void
): UsePersonDetailsArchetypeReturnType => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = usePersonDetailsSchema(name);
  const controls = useFormWithYup<PersonFormValues>(schema);
  const [isModalOpened, { toggleOn, toggleOff }] = useToggle();

  const onSubmit = async (data: PersonFormValues): Promise<void> => {
    try {
      const personData = get(data, name);

      const formAddress = buildFormAddress(personData);
      const isAddressCorrect = await verifyAddress(formAddress);

      if (isAddressCorrect) {
        onFormSubmit?.(data);
        saveAndContinue(true);
      } else {
        toggleOn();
      }
    } catch (reason) {
      // eslint-disable-next-line no-console
      console.log(`Handle rejected promise (${reason}) here.`);
    }
  };

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit),
    isModalOpened,
    toggleOff,
    saveAndContinue
  };
};

export { usePersonDetailsSchema, usePersonDetailsArchetype };
