import { get, noop } from "lodash";
import {
  useContext,
  createContext,
  useMemo,
  ProviderProps,
  useCallback
} from "react";

import { ArrayFormValues } from "@components/archetypes/ArrayForm/types";
import useToggle from "@hooks/useToggle";
import { usePlanForm } from "@providers/planForm/context";

import { PersonValues } from "../types";
import { buildFormAddress, verifyAddress } from "../utils";

import {
  PersonDetailsArrayContextValue,
  PersonDetailsArrayProviderProps
} from "./types";

const PersonDetailsArrayContext =
  createContext<PersonDetailsArrayContextValue>({
    name: "",
    isAddressVerifiedModalDisplayed: false,
    toggleOff: noop,
    toggleOn: noop,
    onSubmit: noop,
    onProceed: noop
  } as PersonDetailsArrayContextValue);

const PersonDetailsArrayProvider: React.FC<
  ProviderProps<PersonDetailsArrayProviderProps>
> = ({
  children,
  value: { name, personRole, onSubmit: onFormSubmit }
}) => {
  const { saveAndContinue } = usePlanForm();
  const [isAddressVerifiedModalDisplayed, { toggleOn, toggleOff }] =
    useToggle();

  const onSubmit = useCallback(
    async (data: ArrayFormValues<PersonValues>): Promise<boolean> => {
      try {
        const peopleData = get(data, name) as PersonValues[];

        const addressValidation = await Promise.all(
          peopleData.map((personData) => {
            const formAddress = buildFormAddress(personData);

            return verifyAddress(formAddress);
          })
        );

        if (addressValidation.some((isValid) => isValid === false)) {
          toggleOn();

          return false;
        }

        onFormSubmit?.(data);

        return true;
      } catch (reason) {
        // eslint-disable-next-line no-console
        console.log(`Handle rejected promise (${reason}) here.`);

        return false;
      }
    },
    [name, onFormSubmit, toggleOn]
  );

  const onProceed = useCallback(() => {
    saveAndContinue(true);
    toggleOff();
  }, [saveAndContinue, toggleOff]);

  const contextValues = useMemo<PersonDetailsArrayContextValue>(
    () => ({
      name,
      personRole,
      isAddressVerifiedModalDisplayed,
      toggleOn,
      toggleOff,
      onSubmit,
      onProceed
    }),
    [
      isAddressVerifiedModalDisplayed,
      name,
      personRole,
      onSubmit,
      toggleOff,
      toggleOn,
      onProceed
    ]
  );

  return (
    <PersonDetailsArrayContext.Provider value={contextValues}>
      {children}
    </PersonDetailsArrayContext.Provider>
  );
};

export default PersonDetailsArrayProvider;

export const usePersonDetailsArrayContext = () =>
  useContext(PersonDetailsArrayContext);
