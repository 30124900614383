import { Navigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { PlanFormSection } from "@providers/planForm/types";

import { HEALTHCARE_ROUTES } from "./consts";
import ahcdSteps from "./sections/medicalPOA/routes";
import HealthcareAHCDSection from "./sections/medicalPOA";
import livingWillSteps from "./sections/livingWill/routes";
import HealthcareLivingWillSection from "./sections/livingWill";

const sections: PlanFormSection[] = [
  {
    path: HEALTHCARE_ROUTES.MedicalPOA,
    element: <HealthcareAHCDSection />,
    children: ahcdSteps,
    icon: <AccountCircleIcon />
  },
  {
    path: HEALTHCARE_ROUTES.LivingWill,
    element: <HealthcareLivingWillSection />,
    children: livingWillSteps,
    icon: <AccountCircleIcon />
  },
  {
    path: "*",
    element: <Navigate to={HEALTHCARE_ROUTES.MedicalPOA} />
  }
];

export default sections;
