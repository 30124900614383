import SingleResponseArchetype from "@components/archetypes/SingleResponse";

const PartnerTrusteeCompensationDollarStep: React.FC = () => (
  <SingleResponseArchetype
    label="Compensation"
    fieldProps={{
      valuePrefix: "$",
      type: "number"
    }}
    stepLayout={{
      title: `What dollar amount would you like to offer your trustee as compensation?`
    }}
    name="users.1.trust.trusteesCompensationDollar"
  />
);

export default PartnerTrusteeCompensationDollarStep;
