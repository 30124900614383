import { FormattedMessage, useIntl } from "react-intl";
import { startCase } from "lodash";
import { Typography } from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";

import TutorialLayout from "@pages/Tutorial/partials/TutorialLayout";
import { TUTORIAL_ROUTES } from "@pages/Tutorial/consts";
import { ROUTES } from "@setup/consts/routes";
import { enumValueToString } from "@utils/misc";
import usePlanTypeContext from "@providers/planType/hooks";

const ExpectationsPage: React.FC = () => {
  const intl = useIntl();
  const { planTypeEnum } = usePlanTypeContext();
  const planType = startCase(enumValueToString(planTypeEnum));

  return (
    <TutorialLayout
      heading={intl.formatMessage({
        defaultMessage: "Types of questions"
      })}
      description={intl.formatMessage({
        defaultMessage:
          "We’ll ask some questions to better get to know you, the loved ones in your plan, and your end of life wishes."
      })}
      title={intl.formatMessage(
        {
          defaultMessage:
            "tutorial ({currentStep} of {allStepsCount})"
        },
        {
          allStepsCount: 5,
          currentStep: 1
        }
      )}
      progress={20}
      withSkipButton
      nextButtonProps={{
        href: `${ROUTES.Tutorial}/${TUTORIAL_ROUTES.Answers}`,
        label: intl.formatMessage({
          defaultMessage: "Continue"
        })
      }}
    >
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb="2.25rem"
      >
        <FormattedMessage
          defaultMessage="Here’s what you can expect building your {planType} plan"
          values={{ planType }}
        />
      </Typography>
      <HandshakeIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
    </TutorialLayout>
  );
};

export default ExpectationsPage;
