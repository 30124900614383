import { useMemo } from "react";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { RESOURCE_PAGE_BASE_URL } from "@setup/consts/misc";
import { BlogLinks } from "@setup/consts/articleLinks";

import { IResourceItem } from "./types";

const useResourceRecs = (showSampleResources = false) => {
  // showSampleResources used for advisors
  const { trust, lastWill, petProtection, planCompleted } =
    useMemberPlansState();

  const resourceRecs = useMemo<IResourceItem[]>(() => {
    if ((trust && planCompleted) || showSampleResources) {
      return [
        {
          key: "1.guide",
          title: "Guide to Picking Good Witnesses",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/a18c7c28345fdf548ef5956cc3116109da86a7a5-640x427.jpg?w=1920&q=75&auto=format",
            alt: "magnifying glass"
          },
          path: BlogLinks.guideToGoodWitnesses,
          description:
            "Not all witnesses are created equal. Learn who you should select to maximize the efficacy of your plan.",
          readTime: 3
        },
        {
          key: "2.fund",
          title: "How to Fund Your Trust",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/be6b7e185047cc0266da9c8f9214da3b28e5d8cc-640x426.gif?w=1000&h=1000&fit=max",
            alt: "coin depositing to trust piggy bank"
          },
          path: BlogLinks.howToFundTrust,
          description:
            "Learn the basics of how to fund a trust by correctly re-titling assets in the trust's name.",
          readTime: 5
        },
        {
          key: "3.nail",
          title: "What is a Notary Public?",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/baabd4d829aa1c1aab0731c60c16f7dcbab0870a-640x426.png?w=1920&q=75&auto=format",
            alt: "notary public stamp"
          },
          path: BlogLinks.whatIsNotaryPublic,
          description:
            "Learn what a notary public does and how you can strengthen your Last Will by inviting one to your signing ceremony.",
          readTime: 3
        },
        {
          key: "4.whatIsTrust",
          title: "Guide to Revocable Living Trusts",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/355bd08854d0020db654ca9887914b0bb4e4cc46-640x426.png?w=1000&h=1000&fit=max",
            alt: "banner hanging letters spelling Trust"
          },
          path: BlogLinks.whatIsRevocableTrust,
          description:
            "Learn everything that you need to know about revocable living trusts to determine whether a revocable living trust is right for you. ",
          readTime: 8
        }
      ].filter((item): item is IResourceItem => Boolean(item.title));
    }

    if (lastWill && planCompleted) {
      return [
        {
          key: "1.guide",
          title: "Guide to Picking Good Witnesses",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/a18c7c28345fdf548ef5956cc3116109da86a7a5-640x427.jpg?w=1920&q=75&auto=format",
            alt: "magnifying glass"
          },
          path: BlogLinks.guideToGoodWitnesses,
          description:
            "Not all witnesses are created equal. Learn who you should select to maximize the efficacy of your plan.",
          readTime: 3
        },
        {
          key: "2.nail",
          title: "How to Nail Your Last Will Signing Ceremony",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/382a604f46a13a5a6a073a23b55927026a820193-640x426.gif?w=1920&q=75&auto=format",
            alt: "document signing gif"
          },
          path: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/how-to-nail-your-last-will-and-testament-signing-ceremony`,
          description:
            "Don't overlook these six crucial steps to making your Last Will official!",
          readTime: 2
        },
        {
          key: "3.nail",
          title: "What is a Notary Public?",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/baabd4d829aa1c1aab0731c60c16f7dcbab0870a-640x426.png?w=1920&q=75&auto=format",
            alt: "notary public stamp"
          },
          path: BlogLinks.whatIsNotaryPublic,
          description:
            "Learn what a notary public does and how you can strengthen your Last Will by inviting one to your signing ceremony.",
          readTime: 3
        },
        {
          key: "4.nail",
          title: "What is a Self-Proved Will?",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/046650682daeb610788c29ab4b6e57d3c6ad7b3a-640x480.jpg?w=1920&q=75&auto=format",
            alt: "two people signing document"
          },
          path: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/what-is-a-self-proved-will`,
          description:
            "Learn how to use a self-proving affidavit to strengthen your last will and avoid probate court delays.",
          readTime: 4
        }
      ].filter((item): item is IResourceItem => Boolean(item.title));
    }

    if (petProtection && planCompleted) {
      return [
        {
          key: "4.nail",
          title: "How to Nail Your Pet Estate Plan Signing Ceremony",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/e9db6e2695a458aeb8848420ab779a436c83eb0b-640x426.gif?w=1920&q=75&auto=format",
            alt: "pet signing document gif"
          },
          path: `${RESOURCE_PAGE_BASE_URL}/pet-estate-plan/how-to-nail-your-pet-estate-plan-signing-ceremony-5-things-to-know`,
          description:
            "Your Pet Estate Plan is not complete until you complete these next steps!",
          readTime: 3
        },
        {
          key: "5.nail",
          title: "Understanding a Limited Pet Power of Attorney",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/79b2039bce18259229a84fdfe287614a8d802691-640x426.png?w=1920&q=75&auto=format",
            alt: "dog holding a gavel making a pet power of attorney"
          },
          path: `${RESOURCE_PAGE_BASE_URL}/pet-estate-plan/what-is-a-limited-pet-power-of-attorney`,
          description:
            "Discover what your Pet Guardian can and cannot do under a Limited Pet Power of Attorney.",
          readTime: 2
        },
        {
          key: "6.guide",
          title: "Guide to Picking Good Witnesses",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/a18c7c28345fdf548ef5956cc3116109da86a7a5-640x427.jpg?w=1920&q=75&auto=format",
            alt: "magnifying glass"
          },
          path: BlogLinks.guideToGoodWitnesses,
          description:
            "Not all witnesses are created equal. Learn who you should select to maximize the efficacy of your plan.",
          readTime: 3
        },
        {
          key: "8.petprotectiongap",
          title:
            "Will you Fight for Your Pet and Close the Pet Protection Gap?",
          thumbNail: {
            src: "https://cdn.sanity.io/images/pe3ne8xc/production/9acf2eb171607f2c91ba6b701d71ef20492cbe73-640x427.jpg?w=1920&q=75&auto=format",
            alt: "dog licking woman"
          },
          path: `${RESOURCE_PAGE_BASE_URL}/pet-estate-plan/will-you-fight-for-your-pet-and-close-the-pet-protection-gap`,
          description:
            "Congrats on completing your Pet Estate Plan! Learn more about how you are helping close the Pet Protection Gap.",
          readTime: 2
        }
      ].filter((item): item is IResourceItem => Boolean(item.title));
    }

    return [];
  }, [
    showSampleResources,
    trust,
    lastWill,
    petProtection,
    planCompleted
  ]);

  return resourceRecs;
};

export default useResourceRecs;
