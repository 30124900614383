export enum MembershipRole {
  OWNER = "OWNER",
  EXECUTOR = "EXECUTOR",
  ATTORNEY = "ATTORNEY",
  FINANCIAL_ADVISOR = "FINANCIAL_ADVISOR",
  GUARDIAN = "GUARDIAN",
  BENEFICIARY = "BENEFICIARY",
  HEALTHCARE_AGENT = "HEALTHCARE_AGENT",
  AUTHORIZED_PERSON = "AUTHORIZED_PERSON",
  AGENT = "AGENT",
  VETERINARIAN = "VETERINARIAN",
  PETGUARDIAN = "PETGUARDIAN",
  TRUSTEE = "TRUSTEE",
  OTHER_KEY_PERSON = "OTHER_KEY_PERSON"
}

export enum RelationshipStatus {
  SINGLE = "SINGLE",
  MARRIED = "MARRIED",
  REGISTERED_DOMESTIC_PARTNERSHIP = "REGISTERED_DOMESTIC_PARTNERSHIP",
  CIVIL_UNION = "CIVIL_UNION",
  ENGAGED = "ENGAGED",
  DIVORCED = "DIVORCED"
}
