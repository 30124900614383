import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ROUTES } from "@setup/consts/routes";
import { EXPERT_MATCHING_ROUTES } from "@pages/EstateAdminQuiz/consts";
import { EstateData } from "@pages/EstateAdminQuiz/types";

import { EXPERT_MATCHING_RESULTS } from "../../consts";

const EstateChallengeResults: React.FC<EstateData> = ({
  assetValue
}) => {
  const isMatch =
    assetValue === "$250,001 - $1,000,000" ||
    assetValue === "$1,000,001 - $5,000,000" ||
    assetValue === "$5,000,001 - $10,000,000" ||
    assetValue === ">$10,000,000";

  return isMatch ? (
    <>
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        <FormattedMessage defaultMessage="Let's set you up with our legal experts for a closer look." />
        <br />
        <SearchIcon />
      </Typography>
      <Box maxWidth="29.375rem" width="100%" m="0 auto">
        <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
          <FormattedMessage defaultMessage="Based on the preliminary data you shared with us, our experts might be able to help you with this case." />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            minWidth: "auto"
          }}
          href={getLinkFullUrl([
            ROUTES.EstateAdminQuiz,
            EXPERT_MATCHING_ROUTES.Results,
            EXPERT_MATCHING_RESULTS.CollectUserInfo
          ])}
        >
          <FormattedMessage defaultMessage="Get My Expert" />
        </Button>
      </Box>
    </>
  ) : (
    <>
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        <FormattedMessage defaultMessage="Shoot! Our legal experts are not a fit for you at this time." />
      </Typography>
      <Box maxWidth="29.375rem" width="100%" m="0 auto">
        <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
          <FormattedMessage defaultMessage="If you like, you can sign up to be notified when we make additional services available." />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            minWidth: "auto"
          }}
          href={getLinkFullUrl([
            ROUTES.EstateAdminQuiz,
            EXPERT_MATCHING_ROUTES.Results,
            EXPERT_MATCHING_RESULTS.CollectFailedUserInfo
          ])}
        >
          <FormattedMessage defaultMessage="Sign Up For Updates" />
        </Button>
      </Box>
    </>
  );
};

export default EstateChallengeResults;
