import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import FormInput from "@components/FormFields/FormInput";

export const FormWrapper = styled(Box)({
  display: "flex",
  gap: "0.75rem"
});

export const SharesInput = styled(FormInput)({
  minWidth: "5ch",
  width: "40%",
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
    {
      display: "none"
    },
  "& input[type=number]": {
    MozAppearance: "textfield"
  },
  flexShrink: 0
});

export const NumberInput = styled(FormInput)({
  flexGrow: 1
});

export const ActionWrapper = styled(Box)({
  width: "24px",
  display: "flex",
  alignItems: "flex-end"
});
