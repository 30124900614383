import { get } from "lodash";
import { Box, Card, Typography, Stack } from "@mui/material";

import { LastWill_assets as Asset } from "@api/plans/__generated__/LastWill";
import { AssetType } from "@api/__generated__/globalTypes";
import { useAssetHumanizedTitle } from "@hooks/useAssetHumanizedTitle";
import { usePersonalPropertyTypeIcons } from "@pages/EstatePlanView/Partials/hooks";
import { ASSET_TYPE_ICONS } from "@setup/consts/assetTypeIcons";
import { numberWithCommas } from "@utils/misc";

const useSpecificGiftModalProps = (
  specificGifts: (Asset | null)[] | undefined
) => {
  const humanizedTitles = useAssetHumanizedTitle();
  const personalPropertyIcons = usePersonalPropertyTypeIcons();
  const assetTypeIcons = ASSET_TYPE_ICONS;

  return specificGifts && specificGifts.length > 0
    ? {
        title: "Specific Gifts",
        togglerLabel: `${
          specificGifts[0]?.name ??
          get(humanizedTitles, specificGifts[0]?.type || "", "")
        }${
          specificGifts.length > 1
            ? ` and ${specificGifts.length - 1} more`
            : ""
        }`,
        content: (
          <Box>
            {specificGifts.map((gift) => {
              const isPersonalProperty =
                gift?.type === AssetType.PERSONAL_PROPERTY;

              const icon = isPersonalProperty
                ? get(
                    personalPropertyIcons,
                    gift?.subtype || "",
                    undefined
                  )
                : get(assetTypeIcons, gift?.type || "", undefined);

              return (
                <Card sx={{ p: "0.5rem", my: "1.25rem" }}>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <Typography>{icon}</Typography>
                    <Typography ml="0.5rem">
                      {gift?.name ??
                        get(humanizedTitles, gift?.type || "", "")}
                    </Typography>
                  </Stack>
                  {gift?.grossValue && (
                    <Typography>
                      Est. Value:{" "}
                      {gift.grossValue
                        ? `$${numberWithCommas(gift?.grossValue)}`
                        : "Not specified"}
                    </Typography>
                  )}
                  {gift?.beneficiaries &&
                    gift?.beneficiaries.map((bene) =>
                      gift?.type === AssetType.CASH ? (
                        <Typography>
                          {`$${numberWithCommas(bene?.dollarValue)}`}{" "}
                          to{" "}
                          {bene?.user?.fullName ??
                            bene?.institution?.name}
                        </Typography>
                      ) : (
                        <Typography>
                          {bene?.percentageValue}% to{" "}
                          {bene?.user?.fullName ??
                            bene?.institution?.name}
                        </Typography>
                      )
                    )}
                </Card>
              );
            })}
          </Box>
        )
      }
    : undefined;
};

export default useSpecificGiftModalProps;
