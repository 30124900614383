import { useMemo } from "react";
import { uniq } from "lodash";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import formatDate from "@utils/formatDate";
import { DOCUMENT_HISTORY_DATE_FORMAT } from "@setup/consts/misc";
import { useRegeneratePlanDocuments } from "@api/files/hooks";

const useDocumentHistory = () => {
  const { plans } = useMemberPlansState();

  return useMemo(
    () =>
      plans
        .map((plan) => ({
          plan: plan.type,
          timeline: plan.timeline
        }))
        .map((plan) => ({
          ...plan,
          timeline: uniq(
            (plan.timeline ?? []).map((time) =>
              formatDate(time, DOCUMENT_HISTORY_DATE_FORMAT)
            )
          )
        })),
    [plans]
  );
};

export default useDocumentHistory;

export const useUpdateDocuments = () => {
  const [regeneratePlanDocuments, { loading, data, error }] =
    useRegeneratePlanDocuments();

  return {
    regeneratePlanDocuments,
    loading,
    data,
    error
  };
};
