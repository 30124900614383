import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerCreatePOAStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setPOAValues } = useFormField("users.1.poa");

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const includePoa = get(data, "users.1.poa.include");

      if (!parseBoolean(includePoa)) {
        setPOAValues({});
        setPOAValues({
          include: false
        });
      }
    },
    [setPOAValues]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to create a Power of Attorney?`
      }}
      name="users.1.poa.include"
      {...{ onSubmit }}
    />
  );
};

export default PartnerCreatePOAStep;
