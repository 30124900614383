import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const HHIncomeStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `You got it! What's your annual household income?`,
      subText:
        "We use this information to match you with the right advisor.",
      moreInfoModalProps: {
        togglerLabel: "Learn more.",
        title:
          "Household Income as Qualification Criteria for Financial Advisors",
        content: (
          <>
            The most common qualification standard for working with a
            financial or registered investment advisor is investible
            household assets.
            <br /> <br />
            However, in many cases, advisors lower their qualification
            thresholds for high income earners who are early in their
            careers and may have not yet built up a large asset base.
            <br /> <br />
            Providing your household income helps us better match you
            with the right advisor.
          </>
        ),
        closeLabel: "Got it"
      }
    }}
    items={[
      { label: "<$50,000", value: "<$50,000" },
      { label: "$50,001 - $100,000", value: "$50,001 - $100,000" },
      {
        label: "$100,001 - $250,000",
        value: "$100,001 - $250,000"
      },
      {
        label: "$250,001 - $500,000",
        value: "$250,001 - $500,000"
      },
      {
        label: ">$500,000",
        value: ">$500,000"
      }
    ]}
    name="householdData.income"
  />
);

export default HHIncomeStep;
