import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import { useCallback } from "react";

import { useEmailSentState } from "@providers/emailSentState/context";
import Modal from "@components/Modal";

const EmailSentMessageModal = () => {
  const { showEmailSentMessage, setShowEmailSentMessage } =
    useEmailSentState();

  const onCloseModal = useCallback(() => {
    setShowEmailSentMessage(false);
  }, [setShowEmailSentMessage]);

  const { formatMessage } = useIntl();

  return (
    <Modal
      open={showEmailSentMessage}
      title={formatMessage({
        defaultMessage: "Email sent perfectly"
      })}
      content={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="1.5rem"
          color="#0DC0B4"
        >
          <CheckCircleIcon />
        </Box>
      }
      actions={[
        {
          label: "Close",
          variant: "outlined",
          color: "inherit",
          onClick: onCloseModal
        }
      ]}
    />
  );
};

export default EmailSentMessageModal;
