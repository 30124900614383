import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";
import { PartnerType } from "@api/__generated__/globalTypes";

const PartnerRemainderPooledFamilyInterstitialStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");

  return (
    <InterstitialArchetype
      stepLayout={{
        title:
          "A Quick Note On How Your Pooled Family Trust Will Work",
        subText: `We'll set up your Family Trust to continue until the later to occur of (i) your ${partnerType.toLowerCase()}'s passing and (ii) the date your youngest child reaches a certain minimum age.`,
        muiIconHeader: <InfoOutlinedIcon />,
        moreInfoModalProps: {
          title: "How Your Family Trust Will Work",
          togglerLabel: "Learn more.",
          content: (
            <p>
              Your {partnerType.toLowerCase()} will be the primary
              beneficiary of the Family Trust. This means that the
              Trustee will first consider the needs of your{" "}
              {partnerType.toLowerCase()} before the needs of any
              other trust beneficiary.
              <br />
              <br />
              During your {partnerType.toLowerCase()}&apos;s life, the
              Trustee may distribute to any one or more of your{" "}
              {partnerType.toLowerCase()} and children such amounts as
              your Trustee deems necessary to support their continued
              wellbeing and comfort.
              <br />
              <br />
              After your {partnerType.toLowerCase()}&apos;s passing,
              your children will share the pooled trust funds until
              your youngest child reaches a certain minimum age.
              We&apos;ll set this minimum age on the next question.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
    />
  );
};

export default PartnerRemainderPooledFamilyInterstitialStep;
