import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { Trust } from "@pages/LastWill/types";
import { User } from "@api/me/__generated__/User";

const PartnerDesignateTrusteeSameRepsStep: React.FC = () => {
  const { value: partner } = useFormField<User>("users.1");
  const { value: trust } = useFormField<Trust>("users.0.trust");
  const { value: partnerTrust } =
    useFormField<Trust>("users.1.trust");

  const {
    coTrustees: primaryCoTrustees,
    successorTrustees: primarySuccessorTrustees
  } = trust;

  const coTrustees = trust.isJointTrust
    ? primaryCoTrustees
    : partnerTrust.coTrustees; // Do not use destructuring in case of joint trust, where users.1.trust may be undefined to this point...

  const successorTrustees = trust.isJointTrust
    ? primarySuccessorTrustees
    : partnerTrust.successorTrustees;

  const filteredCoTrustees = coTrustees?.filter(
    (person) => person.id !== partner.personId
  );

  const { setValue: setPrimaryReps } = useFormField(
    "users.1.lastWill.personalRepresentatives"
  );
  const { setValue: setBackupReps } = useFormField(
    "users.1.lastWill.backupPersonalRepresentatives"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const nominateTrusteesAsReps = get(
        data,
        "users.1.trust.nominateTrusteesAsReps"
      );

      const trusteeArray = filteredCoTrustees;

      if (successorTrustees) {
        trusteeArray?.push(...successorTrustees);
      }

      if (
        parseBoolean(nominateTrusteesAsReps) &&
        trusteeArray &&
        trusteeArray?.length > 0
      ) {
        setPrimaryReps([trusteeArray[0]]);
        setBackupReps(trusteeArray.slice(1));
      }
    },

    [
      setPrimaryReps,
      setBackupReps,
      filteredCoTrustees,
      successorTrustees
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title:
          `Does ${partner?.firstName} want to designate ${partner?.firstName}'s Trustees to also serve as Personal Representatives?`,
        subText:
          "You can choose to have your Trustee serve in both roles, or nominate other individuals.",
        moreInfoModalProps: {
          title:
            "What is the Difference Between a Personal Representative and a Trustee?",
          togglerLabel: "Learn more.",
          content: (
            <p>
              When you create your Revocable Living Trust, you create
              not just the trust agreement but also a pour over will
              that works in tandem with the trust.
              <br />
              <br />
              Whereas your Trustee is responsible for managing all the
              trust&apos;s affairs, your Personal Representative is
              responsible for handling those duties within the pour
              over will. That may include:
              <ul>
                <li>
                  Identifying, collecting, and distributing to the
                  Trust any probate assets that weren&apos;t funded to
                  the trust during your lifetime
                </li>
                <li>
                  Ensuring that your minor children (if you have them)
                  are placed with the appropriate child guardian you
                  designate
                </li>
                <li>
                  Organizing your funeral, cremation, or other final
                  arrangements
                </li>
              </ul>
              Typically, these pour over will duties are fairly
              limited and Trustmakers often appoint the same people to
              act as Personal Representative as they do Trustee.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="users.1.trust.nominateTrusteesAsReps"
      items={[
        { value: true, label: "Yes (most common)" },
        { value: false, label: "No" }
      ]}
    />
  );
};

export default PartnerDesignateTrusteeSameRepsStep;
