import { useEffect } from "react";

import useUserContext from "@providers/user/hooks";
import {
  checkFinancialAdvisorTokenValidity,
  removeFinancialAdvisorToken
} from "@utils/financial-advisor-utils";
import { useConvertToFinancialAdvisorMutation } from "@api/financialAdvisor";

export const useFinancialAdvisorConverter = () => {
  const { user, refreshUserData } = useUserContext();
  const [convertToFinancialAdvisor] =
    useConvertToFinancialAdvisorMutation();

  useEffect(() => {
    if (!user) {
      return;
    }

    const tryToConvertFinancialAdvisor = async () => {
      const isValid = await checkFinancialAdvisorTokenValidity();

      if (!isValid) {
        removeFinancialAdvisorToken();

        return;
      }

      try {
        await convertToFinancialAdvisor();
        await refreshUserData();
      } finally {
        removeFinancialAdvisorToken();
      }
    };

    tryToConvertFinancialAdvisor();
  }, [convertToFinancialAdvisor, refreshUserData, user]);
};
