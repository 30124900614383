import { Box, styled } from "@mui/system";
import MuiAccordion, {
  AccordionProps
} from "@mui/material/Accordion";

export const ReviewTitle = styled(Box)(() => ({
  position: "relative",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "20px",
  zIndex: 1
}));

export const LoadingWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  height: `calc(100vh - 5rem)`
}));

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  boxShadow: "none",
  "&:not(:last-child)": {
    borderBottom: "1px solid #ddd"
  },
  "&:before": {
    display: "none"
  }
}));
