import { Address } from "@api/plans/__generated__/Address";
import { Asset } from "@api/plans/__generated__/Asset";
import { Beneficiary } from "@api/plans/__generated__/Beneficiary";
import {
  AddressCreateInput,
  AssetType
} from "@api/__generated__/globalTypes";
import buildAddress from "@utils/buildAddress";

import { AssetFormValues } from "../../AssetForm/types";

export const mapAddressToFormValues = (
  address: Address
): AddressCreateInput => ({
  address1: address.address1 || undefined,
  address2: address.address2 || undefined,
  city: address.city || undefined,
  county: address.county?.name || undefined,
  state: address.state?.name || undefined,
  zip: address.zip || undefined,
  country: address.country?.name || undefined
});

const mapAssetToFormValues = (asset: Asset): AssetFormValues => ({
  beneficiaries: (asset.beneficiaries || [])
    .filter((beneficiary): beneficiary is Beneficiary =>
      Boolean(beneficiary)
    )
    .map((beneficiary) => ({
      name: beneficiary.user?.fullName || undefined,
      personId: beneficiary.user?.personId || undefined,
      backupDistribution: beneficiary.backupDistribution || undefined,
      percentageValue: beneficiary.percentageValue || undefined,
      dollarValue: beneficiary.dollarValue || undefined,
      charity: beneficiary.institution
        ? {
            name: beneficiary.institution.name || "",
            ein: beneficiary.institution.ein || undefined,
            fullAddress: beneficiary.institution.address
              ? mapAddressToFormValues(
                  beneficiary.institution.address
                )
              : undefined,
            address: buildAddress(beneficiary.institution.address)
          }
        : undefined
    })),
  type: asset.type as AssetType,
  grossValue: asset.grossValue || undefined,
  institution: asset.institution || undefined,
  isGift: asset.isGift || undefined,
  liabilityValue: asset.liabilityValue || undefined,
  name: asset.name || undefined,
  subtype: asset.subtype || undefined,
  address: asset.address
    ? mapAddressToFormValues(asset.address)
    : undefined
});

export default mapAssetToFormValues;
