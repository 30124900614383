import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerPainReliefStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setPainReliefException } = useFormField(
    "users.1.healthcare.preferences.reliefFromPainAddNote"
  );
  const { setValue: setPainReliefExceptionList } = useFormField(
    "users.1.healthcare.preferences.reliefFromPainCondition"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const reliefFromPain = get(
        data,
        "users.1.healthcare.preferences.reliefFromPain"
      );

      if (!parseBoolean(reliefFromPain)) {
        setPainReliefException(false);
        setPainReliefExceptionList(null);
      }
    },
    [setPainReliefException, setPainReliefExceptionList]
  );

  return (
    <SingleSelectArchetype
      nullable
      name="users.1.healthcare.preferences.reliefFromPain"
      stepLayout={{
        title: `Does ${partnerFirstName} wish to to receive maximum pain and comfort care, even if it hastens ${partnerFirstName}'s death?`
      }}
      {...{ onSubmit }}
      items={[
        {
          label: "Yes",
          value: true
        },
        {
          label: "No",
          value: false
        },
        {
          label: "Skip this question",
          value: null
        }
      ]}
    />
  );
};

export default PartnerPainReliefStep;
