import { Stack, FormControl, Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";
import BasicFormAutocomplete from "@components/FormFields/FormAutocomplete/BasicAutocomplete";
import BasicCurrencyFormInput from "@components/FormFields/FormInput/BasicCurrencyFormInput";
import ModalToggler from "@components/Modal/partials/ModalToggler";
import { BlogLinks } from "@setup/consts/articleLinks";

import { useFinancialAccountSubTypeOptions } from "../../../../../hooks/useFinancialAccountSubTypeOptions";
import { useBankOptions } from "../../../../../hooks/useBankOptions";
import { ACCOUNT_TYPE_OUTSIDE_LAST_WILL } from "../../Tabs/AssetsTab/utils/isAssetOutsideLastWill";
import { AssetFormValues } from "../AssetForm/types";

import SpecificGift from "./SpecificGift";
import FundedToTrust from "./FundedToTrust";

const FinancialAccountForm = ({ showIsFunded = false }) => {
  const { watch } = useFormContext<AssetFormValues>();
  const subTypeOptions = useFinancialAccountSubTypeOptions();
  const bankOptions = useBankOptions();

  const subtype = watch("subtype", "");
  const isOutsideLastWill = !!ACCOUNT_TYPE_OUTSIDE_LAST_WILL.find(
    (type) => type.toLowerCase() === (subtype || "").toLowerCase()
  );

  const { formatMessage } = useIntl();

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <BasicFormInput name="name" label="Account Nickname" />
        <BasicFormAutocomplete
          textFieldProps={{
            label: "Bank Name"
          }}
          name="institution"
          options={bankOptions}
          freeSolo
        />
        <BasicFormAutocomplete
          textFieldProps={{
            label: "Account Type"
          }}
          name="subtype"
          options={subTypeOptions}
        />
        <BasicCurrencyFormInput
          name="grossValue"
          label={formatMessage({
            defaultMessage: "Approximate Value ($USD)"
          })}
          InputProps={{
            startAdornment: `$`
          }}
        />
        {showIsFunded && <FundedToTrust />}
        <SpecificGift
          label={
            isOutsideLastWill ? (
              <Box sx={{ mb: 2 }}>
                {!showIsFunded && (
                  <>
                    <Typography variant="body2" color="grey.700">
                      <FormattedMessage defaultMessage="Add Notes On Beneficiary Designations for this Asset" />
                    </Typography>
                    <Typography variant="caption" color="grey.700">
                      <FormattedMessage defaultMessage="IMPORTANT. Any beneficiary designations you add here for this account are for reference purposes only. You must update the beneficiary designations with your financial institution for those changes to be effective." />
                    </Typography>
                  </>
                )}
                {showIsFunded && (
                  <>
                    <Typography variant="body2" color="grey.700">
                      <FormattedMessage defaultMessage="Make this Asset a Specific Gift" />
                    </Typography>
                    <Typography variant="caption" color="grey.700">
                      <FormattedMessage defaultMessage="IMPORTANT. If you want to gift this asset through your trust, you must title the asset in the name of the trust or list the trust as beneficiary." />{" "}
                      <ModalToggler
                        icon={
                          <OpenInNewIcon
                            fontSize="small"
                            color="inherit"
                          />
                        }
                        label="Learn more"
                        onClick={() =>
                          window.open(
                            BlogLinks.howToFundTrust,
                            "_blank"
                          )
                        }
                      />
                    </Typography>
                  </>
                )}
              </Box>
            ) : undefined
          }
        />
      </FormControl>
    </Stack>
  );
};

export default FinancialAccountForm;
