import {  useCallback } from "react";

import { useInviteClientMutation } from "@api/invitation";

import {
  InviteClientFormValues,
  UseInviteClientFormProps
} from "../components/AdvisorDashTools/types";

export const useInviteClientMemberForm = ({
  methods,
  onCompleted,
}: UseInviteClientFormProps) => {
  

  const [invite, { loading, error, reset }] = useInviteClientMutation(
    {
      onCompleted: () => {
        onCompleted?.();
        reset();
        methods.reset();
      }
    }
  );

  const onInviteClient = useCallback(
    (data: InviteClientFormValues) => {
      invite({
        variables: {
          input: {
            clientName: data.fullName,
            clientEmail: data.email,
            recommendedPlan: data.recommendedPlan || undefined,
            message: data.message
          }
        }
      });
    },
    [invite]
  );

  return {
    methods,
    onInviteClient,
    loading: loading || methods.formState.isSubmitting,
    error,
    reset
  };
};
