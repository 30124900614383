export const POWER_OF_ATTORNEY_ROUTES = {
  POAOverview: "overview", // LW Overview
  POADedicatedOverviewStep: "preview", // Dedicated Overview

  CreatePOA: "create",
  DesignateAgent: "designate-agent",
  NominateBackupAgent: "nominate-backup-agent",
  DesignateBackupAgent: "designate-backup-agent",
  // Powers enumeration starts here
  Powers: "powers",
  PowersToGrant: "powers-list",
  PowersOptionalPreview: "special-powers-overview",
  PowersDigitalAccess: "digital-access",
  PowersMakeGifts: "make-gifts",
  PowersMakeGiftsPeople: "make-gifts-people",
  PowersMakeGiftsPeopleList: "make-gifts-people-list",
  PowersMakeGiftsSelf: "make-gifts-self",
  PowersMakeGiftsLimitType: "make-gifts-limit",
  PowersMakeGiftsLimitAmount: "make-gifts-limit-amount",
  PowersRelatedTx: "related-tx",
  PowersTransferToTrust: "transfer-to-trust",
  PowersDelegatePowers: "delegate-powers",
  PowersFiduciaryPowers: "fiduciary-powers",
  AgentCompensation: "agent-compensation",
  AgentCompensationAmount: "agent-compensation-amount",
  TransitionPrimaryComplete: "primary-poa-complete",
  // Partner POA starts here
  PartnerCreatePOA: "partner-create",
  PartnerSamePOA: "partner-same-poa",
  PartnerDesignateAgent: "partner-designate-agent",
  PartnerNominateBackupAgent: "partner-nominate-backup-agent",
  PartnerDesignateBackupAgent: "partner-designate-backup-agent",
  // Partner enumeration of powers starts here
  PartnerPowers: "partner-powers",
  PartnerPowersToGrant: "partner-powers-list",
  PartnerPowersOptionalPreview: "partner-special-powers-overview",
  PartnerPowersDigitalAccess: "partner-digital-access",
  PartnerPowersMakeGifts: "partner-make-gifts",
  PartnerPowersMakeGiftsPeople: "partner-make-gifts-people",
  PartnerPowersMakeGiftsPeopleList: "partner-make-gifts-people-list",
  PartnerPowersMakeGiftsSelf: "partner-make-gifts-self",
  PartnerPowersMakeGiftsLimitType: "partner-make-gifts-limit",
  PartnerPowersMakeGiftsLimitAmount:
    "partner-make-gifts-limit-amount",
  PartnerPowersRelatedTx: "partner-related-tx",
  PartnerPowersTransferToTrust: "partner-transfer-to-trust",
  PartnerPowersDelegatePowers: "partner-delegate-powers",
  PartnerPowersFiduciaryPowers: "partner-fiduciary-powers",
  PartnerAgentCompensation: "partner-agent-compensation",
  PartnerAgentCompensationAmount: "partner-agent-compensation-amount",
  // Review
  Review: "review",
  ReviewTabs: "review-tabs",
  // Complete
  POAComplete: "finish"
};
