import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { useFormField } from "@components/FormFields/hooks";

const TrustNameCustomizeStep: React.FC = () => {
  const { value: isExistingTrust } = useFormField(
    "users.0.trust.isExistingTrust"
  );

  if (isExistingTrust) {
    return (
      <SingleResponseArchetype
        stepLayout={{
          title: `What is the exact legal name of the trust you wish to modify?`,
          subText:
            "Please enter the name exactly as it appears on your existing trust documents.",
          moreInfoModalProps: {
            title: "Modifying an Existing Trust",
            togglerLabel: "Learn more.",
            content: (
              <p>
                To avoid having to retitle assets you&apos;ve already
                funded into your trust, we&apos;ll modify your
                existing trust and use the same name.
                <br />
                <br />
                The rest of your trust document will be restated in
                its entirety so that only the terms of this new
                document apply.
              </p>
            ),
            closeLabel: "Got it"
          }
        }}
        name="users.0.trust.name"
        label="Trust Name"
        fieldProps={{
          type: "string"
        }}
      />
    );
  }

  return (
    <SingleResponseArchetype
      stepLayout={{
        title: `Ok. What do you want the custom name for your trust to be?`,
        subText: "Learn more about naming your trust.",
        moreInfoModalProps: {
          title: "Tips for Naming Your Trust",
          togglerLabel: "Learn more.",
          content: (
            <p>
              You can name your trust most anything you like. The
              trust name is merely for identification and
              administrative purposes and does not affect the
              trust&apos;s function.
              <br />
              <br />
              However, you should generally avoid names that could
              potentially be misleading or imply an affiliation with a
              public or government entity.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.0.trust.name"
      label="Trust Name"
      fieldProps={{
        type: "string"
      }}
    />
  );
};

export default TrustNameCustomizeStep;
