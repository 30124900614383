import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";

const PartnerNominateBackupAgentStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );
  const { value: primaryAgentName } = useFormField(
    `users.1.poa.agents.0.name`
  );

  const { setValue: setBackupAgentValue } = useFormField(
    "users.1.poa.successorAgents"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const nominateBackupAgent = get(
        data,
        "users.1.poa.nominateBackupAgent"
      );

      if (!parseBoolean(nominateBackupAgent)) {
        setBackupAgentValue([]);
      }
    },
    [setBackupAgentValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to nominate a successor agent in case ${primaryAgentName} is unable or unwilling to serve?`
      }}
      name="users.1.poa.nominateBackupAgent"
      {...{ onSubmit }}
    />
  );
};

export default PartnerNominateBackupAgentStep;
