/* eslint-disable react/no-unescaped-entities */
import { Typography, Box, useTheme } from "@mui/material";
import { useMemo } from "react";
import { AccountCircleOutlined } from "@mui/icons-material";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { MembershipRole } from "@setup/enums";

import { roleDescription } from "../consts";
import { getEstatePlanTitle } from "../utils";

const MyRoleDetails = () => {
  const { myRoles, trust, lastWill, petProtection } =
    useMemberPlansState();
  const theme = useTheme();

  const { plan, isOwner } = trust || lastWill || petProtection || {};

  const sharingAccountOwner = getEstatePlanTitle(
    trust?.plan || lastWill?.plan || petProtection?.plan || null,
    myRoles
  );

  const roleDetails: Array<{
    label: string;
    description: string;
    icon: React.ReactNode;
  }> = useMemo(
    () =>
      plan?.membership?.roles
        ? plan?.membership?.roles.map((role: string) => {
            if (!Object.keys(MembershipRole).includes(role)) {
              return {
                label: role,
                description: "",
                icon: <AccountCircleOutlined />
              };
            }
            const key = role as unknown as MembershipRole;

            return roleDescription[key];
          })
        : myRoles?.myRole.map((altRole) => {
            if (!Object.keys(MembershipRole).includes(altRole)) {
              return {
                label: altRole,
                description: "",
                icon: <AccountCircleOutlined />
              };
            }
            const key = altRole as unknown as MembershipRole;

            return roleDescription[key];
          }),
    [plan?.membership?.roles, myRoles]
  );

  if (isOwner) {
    return null;
  }

  return (
    <Box
      sx={{
        marginBottom: "0.7rem"
      }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          marginBottom: "0.5rem"
        }}
      >
        Details of Your Role in {sharingAccountOwner}:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem"
        }}
      >
        {roleDetails &&
          roleDetails.map(({ description, icon, label }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1.5rem",
                padding: "0.5rem",
                border: `1px solid ${theme.palette.grey["500"]}`
              }}
            >
              {icon}
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px"
                  }}
                >
                  {label}
                </Typography>
                {description.length > 0 && (
                  <Typography>{description}</Typography>
                )}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default MyRoleDetails;
