import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";

const POAOverviewStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `What's a Power of Attorney?`,
      isWide: true,
      muiIconHeader: <InfoOutlinedIcon />,
      subText: `In addition to the Personal Representative who will help manage your affairs after you pass, you can grant someone a Power of Attorney to help manage things while you're alive.`,
      moreInfoModalProps: {
        title: "Why create a Power of Attorney?",
        togglerLabel: "Learn more",
        content: (
          <p>
            A Power of Attorney is a legal document through which you
            authorize another person, called your agent, to act on
            your behalf.
            <br />
            <br />
            Granting authority to a trusted individual can be helpful
            to facilitate transactions under ordinary circumstances,
            and all the more so if you temporarily or permanently lose
            the ability to make or communicate your decisions.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
  />
);

export default POAOverviewStep;
