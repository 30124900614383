import { Navigate } from "react-router-dom";

import { PlanFormSection } from "@providers/planForm/types";

import { EXPERT_MATCHING_ROUTES } from "./consts";
import ExpertMatchingQuizSection from "./Quiz";
import expertMatchingQuizSteps from "./Quiz/routes";
import ExpertMatchingResults from "./results";
import expertMatchingResultsSteps from "./results/routes";

const sections: PlanFormSection[] = [
  {
    path: EXPERT_MATCHING_ROUTES.Quiz,
    element: <ExpertMatchingQuizSection />,
    children: expertMatchingQuizSteps
  },
  {
    path: EXPERT_MATCHING_ROUTES.Results,
    element: <ExpertMatchingResults />,
    children: expertMatchingResultsSteps
  },
  {
    path: "*",
    element: <Navigate to={EXPERT_MATCHING_ROUTES.Quiz} />
  }
];

export default sections;
