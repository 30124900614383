import { EstateAdminResults } from "@pages/EstateAdminQuiz/types";

const getErrorMessage = (): EstateAdminResults => ({
  recommendation:
    "Hmm...let's hop on a call to figure out which option", // ... is the best fit
  rationale:
    "Our member success team will walk you one-on-one through the different estate administration options and identify the information that you'll need to determine which proceeding fits your case."
});

export default getErrorMessage;
