import { HTMLAttributes } from "react";
import {
  Button,
  ButtonProps,
  Toolbar,
  ToolbarProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { StyledHeaderProps, StyledHeaderTopBarProps } from "./types";

export const Link = styled(
  (props: ButtonProps & { isExternal?: boolean }) => (
    <Button {...props} />
  )
)(({ theme }) => ({
  display: "inline-block",
  opacity: 1,
  fontWeight: 600,
  textDecoration: "none",
  color: theme.palette.grey[500],
  whiteSpace: "nowrap",
  fontSize: "1rem",
  lineHeight: 1.4,
  fontFamily: '"Inter", sans-serif',
  textTransform: "none",
  minWidth: "auto",
  minHeight: "auto",
  padding: 0,
  textAlign: "left",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  }
}));

export const LinksContainer = styled("ul")(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  margin: 0,
  padding: 0,
  position: "relative",
  [theme.breakpoints.up("lg")]: {
    marginLeft: "4.1rem",
    "& > *:not(:last-child)": {
      marginRight: "1.875rem"
    }
  },
  [theme.breakpoints.up("md")]: {
    alignItems: "center",
    marginLeft: "2.5rem",
    "& > *:not(:last-child)": {
      marginRight: "0.625rem"
    }
  }
}));

export const MobileNav = styled(
  ({
    isOpened,
    ...restProps
  }: StyledHeaderProps & HTMLAttributes<HTMLElement>) => (
    <nav {...restProps} />
  )
)<StyledHeaderProps>(({ isOpened }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  padding: "1.385rem 1.25rem 1.625rem 1.25rem",
  backgroundColor: "#E8E8E8", // This stays without a variable because it is not used in other components
  transform: isOpened ? "translateX(0)" : "translateX(-200%)",
  width: "100%",
  height: "100vh",
  transition: "all 300ms ease-in-out"
}));

export const MobileLinksContainer = styled("ul")(() => ({
  display: "flex",
  listStyle: "none",
  flexDirection: "column",
  marginTop: "4.25rem",
  marginLeft: 0,
  padding: 0,
  paddingRight: "0.65rem",
  "& > *": {
    fontSize: "1.5rem"
  },
  "& > *:not(:last-child)": {
    marginBottom: "0.5rem"
  }
}));

export const DesktopSubMenuLink = styled(Link)`
  padding: 0.5rem 1rem;
  display: block;
`;

export const LinkItem = styled("li")(() => ({}));

export const MobileSubMenuList = styled(
  ({
    isOpened,
    ...restProps
  }: StyledHeaderProps & HTMLAttributes<HTMLUListElement>) => (
    <ul {...restProps} />
  )
)<StyledHeaderProps>(({ isOpened }) => ({
  listStyle: "none",
  margin: 0,
  padding: 0,
  overflow: "hidden",
  maxHeight: isOpened ? "1000px" : "0",
  transition: isOpened
    ? "max-height 1s ease-in-out"
    : "max-height 0.5s cubic-bezier(0, 1, 0, 1)"
}));

export const MobileLinkItem = styled("li")(() => ({}));

export const MobileMenuLink = styled(
  ({ isOpened, ...restProps }: StyledHeaderProps & ButtonProps) => (
    <Link {...restProps} />
  )
)<StyledHeaderProps>(({ isOpened }) => ({
  display: "block",
  position: "relative",
  width: "100%",
  "& .chevron": {
    top: "50%",
    right: 0,
    position: "absolute",
    transform: isOpened
      ? "translateY(-50%) rotate(-90deg)"
      : "translateY(-50%) rotate(90deg)",
    fontSize: "2.25rem",
    transition: "all 300ms ease-in-out"
  }
}));

export const TopBar = styled(
  ({
    withGridOnMobile,
    ...restProps
  }: StyledHeaderTopBarProps & ToolbarProps) => (
    <Toolbar {...restProps} />
  )
)<StyledHeaderTopBarProps>(({ theme, withGridOnMobile }) =>
  withGridOnMobile
    ? {
        display: "grid",
        gridTemplateColumns: "5fr 1fr 1fr",
        gridTemplateAreas: '"logo user burger"',
        gridColumnGap: "0.5rem",
        [theme.breakpoints.up("md")]: {
          display: "flex"
        }
      }
    : {}
);
