import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { Beneficiary, VestingSchedule } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";
import { usePlanForm } from "@providers/planForm/context";

import VestingScheduleForm from "../RemainderAllocate/AgeVestingSchedule/VestingScheduleForm";
import useAgeVestingScheduleSchema from "../RemainderAllocate/AgeVestingSchedule/VestingScheduleForm/hooks";
import FormHeader from "../RemainderAllocate/AgeVestingSchedule/FormHeader";
// Memo: This Allocation Message is a shared resource across multiple array designations
import AllocationMessage from "../RemainderAllocate/partials/AllocationMessage";

const PartnerSeparateTrustAgeReqsStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();
  const singleItemSchema = useAgeVestingScheduleSchema();
  const { value } = useFormField<VestingSchedule[]>(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingSchedule`
  );
  const { value: currentBeneficiary } = useFormField<Beneficiary>(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}`
  );

  const { success } = useAllocationSum(value, {
    path: "percentageValue"
  });

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `Please note the percentage distributions and age for which the Trustee should make distributions on ${currentBeneficiary.name}'s Trust?`
      }}
      addBtnLabel={formatMessage({
        defaultMessage: "Add Another Age Threshold"
      })}
      {...{ singleItemSchema }}
      customRemoveBtn
      SingleItem={VestingScheduleForm}
      name={`users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingSchedule`}
      topContent={<FormHeader />}
      bottomContent={
        <AllocationMessage
          name={`users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingSchedule`}
        />
      }
      disabled={!success}
    />
  );
};

export default PartnerSeparateTrustAgeReqsStep;
