import { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";
import produce from "immer";
import { unset } from "lodash";

import {
  useCreateFileMutation,
  useCreateFileUploadUrlMutation
} from "@api/files/hooks";
import { useGetPlansQuery } from "@api/plans";

export enum FileUploadState {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR
}

export interface FileUpload {
  fileName: string;
  state: FileUploadState;
  controller?: AbortController;
}

const useCustomDocumentUpload = (planId: number) => {
  const [documents, setDocuments] = useState<
    Record<string, FileUpload>
  >({});
  const { refetch } = useGetPlansQuery();

  const [createFileUploadUrl] = useCreateFileUploadUrlMutation();
  const [createFile] = useCreateFileMutation({
    onCompleted: () => {
      refetch();
    }
  });

  const uploadFile = useCallback(
    async (file: File) => {
      const uniqueId = v4();
      const controller = new AbortController();

      try {
        setDocuments((prev) => ({
          ...prev,
          [uniqueId]: {
            fileName: file.name,
            state: FileUploadState.PENDING,
            controller
          }
        }));

        const { data, errors } = await createFileUploadUrl({
          variables: {
            planId,
            fileName: file.name,
            contentLength: file.size
          }
        });

        const { url, path } = data?.createFileUploadUrl || {};

        if (!url || !path) {
          throw errors;
        }

        const response = await fetch(url, {
          method: "PUT",
          body: file,
          signal: controller.signal
        });

        if (!response.ok) {
          const errorText = await response.text();

          throw new Error(errorText);
        }

        await createFile({
          variables: {
            path,
            planId,
            name: file.name
          }
        });

        setDocuments((prev) => ({
          ...prev,
          [uniqueId]: {
            fileName: file.name,
            state: FileUploadState.SUCCESS
          }
        }));
      } catch {
        if (controller.signal.aborted) return;

        setDocuments((prev) => ({
          ...prev,
          [uniqueId]: {
            fileName: file.name,
            state: FileUploadState.ERROR
          }
        }));
      }
    },
    [createFile, createFileUploadUrl, planId]
  );

  const uploadFiles = useCallback(
    async (...files: File[]) => {
      files.forEach(uploadFile);
    },
    [uploadFile]
  );

  const { uploading, error } = useMemo(() => {
    const fileStates = Object.values(documents).map(
      (fileUpload) => fileUpload.state
    );

    return {
      uploading: fileStates.some(
        (state) => state === FileUploadState.PENDING
      ),
      error: fileStates.some(
        (state) => state === FileUploadState.ERROR
      )
    };
  }, [documents]);

  const cancelUpload = useCallback(
    (id: string) => {
      const doc = documents[id];

      if (!doc) return;

      doc.controller?.abort();

      setDocuments((prev) =>
        produce(prev, (draft) => {
          if (!draft) {
            return;
          }

          unset(draft, id);
        })
      );
    },
    [documents]
  );

  return {
    uploading,
    error,
    documents: Object.entries(documents).map(([key, value]) => ({
      ...value,
      id: key
    })),
    uploadFiles,
    cancelUpload
  };
};

export default useCustomDocumentUpload;
