import { Trust } from "@api/trusts/__generated__/Trust";
import {
  LastWill,
  LastWillPlan,
  MemberBasicPlan,
  TrustPlan
} from "@providers/memberPlansState/type";
import { videoTutorials } from "@setup/consts/videoTutorials";

export const getClientTutorials = (
  trust?: MemberBasicPlan<TrustPlan> & {
    primaryDrafterTrust: Trust;
    partnerTrust?: Trust;
    primaryDrafterLastWill: LastWill;
    partnerLastWill?: LastWill;
  },
  lastWill?: MemberBasicPlan<LastWillPlan> & {
    primaryDrafterLastWill: LastWill;
    partnerLastWill?: LastWill;
  }
) => {
  const primaryDrafterLastWill = trust
    ? trust.primaryDrafterLastWill
    : lastWill?.primaryDrafterLastWill;

  const partnerLastWill = trust
    ? trust.partnerLastWill
    : lastWill?.partnerLastWill;

  const videos = [];

  if (lastWill) {
    videos.push(videoTutorials.howToSignYourWill);
  }

  if (
    primaryDrafterLastWill?.powerOfAttorney ||
    partnerLastWill?.powerOfAttorney
  ) {
    videos.push(videoTutorials.howToSignYourPOA);
  }

  if (
    primaryDrafterLastWill?.healthcare ||
    partnerLastWill?.healthcare
  ) {
    videos.push(videoTutorials.howToSignYourAHCD);
  }

  return videos;
};
