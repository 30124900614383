import { useIntl } from "react-intl";
import { useMemo } from "react";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import getPrimaryGuardianInfo from "@utils/getPrimaryGuardianInfo";
import { useFormField } from "@components/FormFields/hooks";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import {
  PartnerType,
  PlanType
} from "@api/__generated__/globalTypes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_GUARDIANS_ROUTES } from "@pages/Trust/sections/Guardians/consts";

import { LAST_WILL_GUARDIANS_ROUTES } from "../../consts";

export const usePrimaryDrafterGuardiansReviewTab = ({
  values,
  type
}: {
  values: LastWillPlanValues;
  type: PlanType;
}) => {
  const { formatMessage } = useIntl();

  const {
    children = [],
    guardians = [],
    backupGuardians = [],
    prohibitedGuardians = [],
    partnerAsChildGuardian = false
  } = values;

  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);
  const guardianInfo = useMemo<ReviewItem | undefined>(
    () =>
      children?.length
        ? {
            title: formatMessage({
              defaultMessage: "Children's Guardian Info"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.Guardians,
                    TRUST_GUARDIANS_ROUTES.DesignateGuardians
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Guardians,
                    LAST_WILL_GUARDIANS_ROUTES.DesignateGuardians
                  ]
            ),
            isFullWidth: true,
            items: children.map((child) => [
              child.name,
              getPrimaryGuardianInfo(
                guardians,
                partnerAsChildGuardian,
                partnerType
              ),
              guardians && backupGuardians.length > 0
                ? `Backup Guardians: ${backupGuardians
                    .map((backup) => backup.name)
                    .join(", ")}`
                : `Backup Guardians: You chose not to nominate any backup guardians.`,
              prohibitedGuardians.length > 0
                ? `Improper Guardians: ${prohibitedGuardians
                    .map((person) => person.name)
                    .join(", ")}`
                : ``
            ])
          }
        : {
            title: formatMessage({
              defaultMessage: "Children's Guardian Info"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Guardians,
              LAST_WILL_GUARDIANS_ROUTES.NominateGuardians
            ]),
            isFullWidth: true,
            items: [["🚫 You chose not to nominate Guardians"]]
          },
    [
      children,
      formatMessage,
      guardians,
      partnerAsChildGuardian,
      partnerType,
      backupGuardians,
      prohibitedGuardians,
      type
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [guardianInfo].filter((item): item is ReviewItem =>
        Boolean(item)
      ),
    [guardianInfo]
  );

  return items;
};
