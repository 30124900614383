/* eslint-disable react/no-unescaped-entities */
import { Container, Typography, Box, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useFormField } from "@components/FormFields/hooks";
import MemberSuccessHeadshot from "@assets/images/kymeeHeadshot.png";
import StepLayout from "@components/StepLayout";

const InfoSubmittedStep: React.FC = () => {
  const { value: firstName } = useFormField("user.firstName");

  const onClick = () => {
    window.location.href = "https://www.jicestates.com";
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: { xs: 2.5, md: 6.25 }
      }}
    >
      <StepLayout
        title={`Thanks, ${firstName}!`}
        imageProps={{
          src: MemberSuccessHeadshot,
          alt: "Handdrawn headshot of Kymee from JIC Estates Member Success team"
        }}
        isWide
      />
      <Typography>
        <FormattedMessage defaultMessage="We successfully received your information." />
        <br /> <br />
        <FormattedMessage defaultMessage="Our Member Success team will follow up shortly." />
      </Typography>
      <Box mt="1.25rem">
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          sx={{ width: "100%" }}
        >
          <FormattedMessage defaultMessage="Back to Home" />
        </Button>
      </Box>
    </Container>
  );
};

export default InfoSubmittedStep;
