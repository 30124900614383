/* eslint-disable react/no-unescaped-entities */
import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const AllowHealthAccessStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `Do you want to grant your Pet Guardian limited access to your health information?`,
      subText: `This access would be strictly limited to such information necessary to determine whether you are incapable of caring for your pets.`,
      moreInfoModalProps: {
        title:
          "Limited Health Information Access in a Pet Estate Plan",
        togglerLabel: "Learn more",
        content: (
          <p>
            By federal law, healthcare providers cannot disclose your
            health or medical information to most other individuals
            and entities without your knowledge or consent.
            <br />
            <br />
            While ordinarily you can provide this authorization or
            information directly yourself, in rare instances you may
            temporarily or permanently lose the ability to
            communicate.
            <br />
            <br />
            A Limited HIPAA Authorization in a Pet Estate Plan acts as
            a backstop by allowing your Pet Guardian to receive
            limited updates on your health sufficient to prove whether
            you are able to care for your pet.
            <br />
            <br />
            When you provide a Limited HIPAA Authorization and Release
            to your Pet Guardian, your Pet Guardian:
            <br />
            ✅ Can receive the minimal information or medical opinion
            necessary to determine whether you are capable of caring
            for your pets.
            <br />
            🚫 Cannot receive any extraneous health records or other
            information unrelated to the question of whether you are
            capable of caring for your pets, unless you&apos;ve
            separately granted them a broader HIPAA Release outside of
            your Pet Estate Plan.
            <br />
            🚫 Cannot make any healthcare decisions for you, unless
            you&apos;ve separately made them your healthcare agent
            under a medical power of attorney.
            <br />
            <br />
            You may revoke a person&apos;s authorization at any time
            by providing notice to your healthcare and health
            information service providers.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    name="grantGuardianAccessToHealthInformation"
  />
);

export default AllowHealthAccessStep;
