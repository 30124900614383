import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewBox from "@components/ReviewBox";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import AssetReviewPartial from "@components/AssetReviewPartial";
import { useFormField } from "@components/FormFields/hooks";
import { PartnerType } from "@api/__generated__/globalTypes";
import { BlogLinks } from "@setup/consts/articleLinks";

import { usePrimaryDrafterAssetsReviewTab } from "./Reviews/usePrimaryDrafterAssetsReviewTab";
import {
  getHowToTransferAssetsContent,
  getWhyTransferAssetsContent
} from "./Reviews/utils";

const ReviewStep: React.FC = () => {
  const intl = useIntl();
  const { goBack, saveAndContinue } = usePlanForm();
  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter]
  } = values;
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");
  const [items, fundedReviewItems, notFundedReviewItems] =
    usePrimaryDrafterAssetsReviewTab({
      values,
      partnerType
    });

  return (
    <StepLayout
      title="Let's confirm we got this right."
      subText="Please review this summary of how each asset you identified will be distributed."
      muiIconHeader={<AssignmentTurnedInIcon />}
      isWide
    >
      {(fundedReviewItems.length > 0 ||
        notFundedReviewItems.length > 0) && (
        <AssetReviewPartial
          title="Assets You Are Funding to Your Trust"
          href="#ignore"
          description="These are assets titled in the name of your revocable trust."
          moreInfoModalProps={{
            togglerLabel: "Learn more.",
            title: "Transferring Assets to Your Trust",
            content: getHowToTransferAssetsContent(primaryDrafter),
            actions: [
              {
                label: "Guide on Trust Funding",
                href: BlogLinks.howToFundTrust
              }
            ],
            closeLabel: "Got it"
          }}
          reviewItems={fundedReviewItems}
        />
      )}
      {(fundedReviewItems.length > 0 ||
        notFundedReviewItems.length > 0) && (
        <AssetReviewPartial
          title="Assets Not Yet Funded to Your Trust"
          href="#ignore"
          description="You don't have to fund all your assets into the trust right away, but you should make funding the trust a priority over the next few weeks in order to get the most out of your Trust."
          moreInfoModalProps={{
            togglerLabel: "Learn more.",
            title: "Transferring Assets to Your Trust",
            content: getWhyTransferAssetsContent(),
            actions: [
              {
                label: "Guide on Trust Funding",
                href: BlogLinks.howToFundTrust
              }
            ],
            closeLabel: "Got it"
          }}
          reviewItems={notFundedReviewItems}
        />
      )}
      {items.map(({ title, isFullWidth, ...item }) => (
        <Box mb="2.5rem">
          <ReviewBox {...{ title, ...item }} />
        </Box>
      ))}
      <FormStepFooter
        prevButtonProps={{ type: "button", onClick: goBack }}
        nextButtonProps={{
          type: "button",
          onClick: () => saveAndContinue(),
          label: intl.formatMessage({ defaultMessage: "Confirm" })
        }}
      />
    </StepLayout>
  );
};

export default ReviewStep;
