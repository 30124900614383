import { House, Leaderboard } from "@mui/icons-material";
import BookIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SendIcon from "@mui/icons-material/Send";
import ArticleIcon from "@mui/icons-material/Article";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import { ROUTES } from "@setup/consts/routes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { isOwner } from "@utils/permission-utils";

import { DASHBOARD_SECTION_ROUTES } from "../consts";
import { getLinkFullUrl } from "../utils";
import { NavSectionName, INavItem } from "../components/SideNav/type";

const useDashboardNavItems = () => {
  const { formatMessage } = useIntl();
  const { plans } = useMemberPlansState();

  const plansSharedWithMe = useMemo(
    () => plans?.filter((plan) => !isOwner(plan?.membership)),
    [plans]
  );

  const plansSharedWithMeMenuItems = useMemo<INavItem[]>(() => {
    const planItems =
      (plansSharedWithMe ?? [])?.map((plan) => ({
        title: plan?.primaryDrafter?.fullName ?? "",
        path: generatePath(":pathname?planId=:planId", {
          planId: plan?.id.toString(),
          pathname: getLinkFullUrl([ROUTES.Dashboard])
        }),
        subItems: [
          {
            title: formatMessage({
              defaultMessage: "Docs & Data"
            }),
            path: generatePath(":pathname?planId=:planId", {
              planId: plan?.id.toString(),
              pathname: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.DocsAndData
              ])
            })
          },
          {
            title: formatMessage({
              defaultMessage: "Assets"
            }),
            path: generatePath(":pathname?planId=:planId", {
              planId: plan?.id.toString(),
              pathname: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Assets
              ])
            })
          },
          {
            title: formatMessage({
              defaultMessage: "Visualize"
            }),
            path: generatePath(":pathname?planId=:planId", {
              planId: plan?.id.toString(),
              pathname: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Visual
              ])
            })
          }
        ]
      })) ?? [];

    return [
      {
        title: formatMessage({
          defaultMessage: "Summary"
        }),
        path: getLinkFullUrl([
          ROUTES.Dashboard,
          DASHBOARD_SECTION_ROUTES.SharedWithMeSummary
        ]),
        icon: <ArticleIcon />
      },
      ...planItems
    ].filter((item): item is INavItem => Boolean(item.title));
  }, [formatMessage, plansSharedWithMe]);

  const navItems = useMemo<NavSectionName[]>(
    () =>
      [
        {
          title: formatMessage({
            defaultMessage: "My Plan:"
          }),
          items: [
            {
              title: formatMessage({
                defaultMessage: "Docs & Data"
              }),
              path: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.DocsAndData
              ]),
              icon: <DashboardIcon />
            },
            {
              title: formatMessage({
                defaultMessage: "Assets"
              }),
              path: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Assets
              ]),
              icon: <House />
            },
            {
              title: formatMessage({
                defaultMessage: "Visualize"
              }),
              path: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Visual
              ]),
              icon: <Leaderboard />
            },
            {
              title: formatMessage({
                defaultMessage: "Share"
              }),
              path: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Share
              ]),
              icon: <SendIcon />
            },
            {
              title: formatMessage({
                defaultMessage: "Resources"
              }),
              path: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Resources
              ]),
              icon: <BookIcon />
            }
          ]
        },
        {
          title: formatMessage({
            defaultMessage: "Shared With Me:"
          }),
          items: plansSharedWithMeMenuItems
        }
      ].filter((item): item is NavSectionName =>
        Boolean(Object.keys(item).length)
      ),
    [formatMessage, plansSharedWithMeMenuItems]
  );

  return navItems;
};

export default useDashboardNavItems;
