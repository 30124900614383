import { Asset } from "@api/plans/__generated__/Asset";

import { numberWithCommas } from "./misc";

const getAssetNetValue = (asset: Asset): string => {
  if (asset.grossValue && asset.liabilityValue) {
    return `$${numberWithCommas(
      asset.grossValue - asset.liabilityValue
    )}`;
  }
  if (asset.liabilityValue) {
    return `$${numberWithCommas(asset.liabilityValue)}`;
  }

  if (asset.grossValue) {
    return `$${numberWithCommas(asset.grossValue)}`;
  }

  return "Not specified";
};

export default getAssetNetValue;
