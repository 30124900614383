import { HTMLAttributes } from "react";
import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { Box } from "@mui/system";

import { StyledDropdownProps } from "./types";

export const Link = styled(
  (
    props: ButtonProps & {
      isExternal?: boolean;
    }
  ) => <Button {...props} />
)(({ theme }) => ({
  display: "inline-block",
  opacity: 1,
  fontWeight: 600,
  textDecoration: "none",
  color: theme.palette.grey[500],
  whiteSpace: "nowrap",
  fontSize: "1rem",
  lineHeight: 1.4,
  fontFamily: '"Inter", sans-serif',
  textTransform: "none",
  minWidth: "auto",
  minHeight: "auto",
  padding: 0,
  "& .MuiTouchRipple-root": {
    display: "none"
  },
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent"
    }
  }
}));

export const DesktopSubMenuListWrapper = styled(
  ({
    isRight,
    ...restProps
  }: StyledDropdownProps & HTMLAttributes<HTMLElement>) => (
    <div {...restProps} />
  )
)<StyledDropdownProps>(({ isRight }) => ({
  position: "absolute",
  display: "none",
  left: isRight ? "auto" : 0,
  right: isRight ? 0 : "auto",
  top: "100%"
}));

export const DesktopSubMenuListContent = styled("div")(
  ({ theme }) => ({
    borderTop: `3px solid ${theme.palette.grey[500]}`,
    boxShadow: "rgb(0 0 0 / 15%) 0px 1px 20px",
    backgroundColor: "#E8E8E8",
    marginTop: "1rem"
  })
);

export const DesktopSubMenuList = styled("ul")(() => ({
  listStyle: "none",
  margin: 0,
  padding: "0.5rem 0"
}));

export const DesktopSubMenuListItem = styled("li")(() => ({
  minWidth: "14.5rem",
  "> ul": {
    paddingTop: 0,
    paddingLeft: "0.5rem"
  }
}));

export const DesktopSubMenuLink = styled(Link, {
  shouldForwardProp: (props) => props !== "isExternal"
})(() => ({
  padding: "0.5rem 1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  svg: {
    fontSize: "1.25rem",
    marginLeft: "0.75rem"
  }
}));

export const Wrapper = styled(Box)(() => ({
  position: "relative",
  transition: "all 300ms ease-in-out",
  display: "inline-flex",
  "&:hover": {
    "& .desktop-submenu": {
      display: "block"
    }
  }
}));
