import React from "react";
import { Box, Stack } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";

import { GOOGLE_MAPS_API_KEY } from "@setup/environment";
import {
  GOOGLE_MAPS_LANGUAGE,
  PLACES_RESTRICTIONS_COUNTRY
} from "@setup/consts/misc";
import { useFiftyStateOptions } from "@hooks/useFiftyStateItems";

import FormInput from "../FormInput";
import FormAutocomplete from "../FormAutocomplete";

import { FormAddressAutocompleteProps } from "./types";
import { useAddressFields } from "./hooks";
import FormAddressAutocompleteModal from "./partials/FormAddressAutocompleteModal";

const FormAddressAutocomplete: React.FC<
  FormAddressAutocompleteProps
> = ({ name, modalProps }) => {
  const intl = useIntl();
  const theme = useTheme();
  const { handleAddressAutofill } = useAddressFields(name);
  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: ({
      address_components: addressComponents,
      formatted_address: formattedAddress
    }) => {
      handleAddressAutofill(addressComponents, formattedAddress);
    },
    language: GOOGLE_MAPS_LANGUAGE,
    options: {
      componentRestrictions: { country: PLACES_RESTRICTIONS_COUNTRY },
      types: ["address"],
      fields: ["address_components", "geometry", "formatted_address"]
    }
  });

  const FIELD_WIDTH = {
    xs: "100%",
    md: `calc(50% - ${theme.spacing(2)})`
  };

  return (
    <>
      <Stack spacing={4}>
        <FormInput
          inputRef={ref}
          name={`${name}.address1`}
          label={intl.formatMessage({
            defaultMessage: "Address Line 1"
          })}
        />
        <FormInput
          name={`${name}.address2`}
          label={intl.formatMessage({
            defaultMessage: "Address Line 2"
          })}
        />
        <FormInput
          name={`${name}.city`}
          label={intl.formatMessage({
            defaultMessage: "City"
          })}
        />
        <Stack spacing={4} direction={{ xs: "column", md: "row" }}>
          <FormAutocomplete
            textFieldProps={{
              label: intl.formatMessage({
                defaultMessage: "State"
              })
            }}
            name={`${name}.state`}
            options={useFiftyStateOptions()}
            sx={{
              width: FIELD_WIDTH
            }}
          />
          <FormInput
            name={`${name}.zip`}
            label={intl.formatMessage({
              defaultMessage: "Postal Code"
            })}
            sx={{
              width: FIELD_WIDTH
            }}
          />
        </Stack>
        <FormInput
          name={`${name}.county`}
          label={intl.formatMessage({
            defaultMessage: "County"
          })}
        />
      </Stack>
      <Box
        sx={{
          appearance: "none"
        }}
      >
        <FormInput name={`${name}.country`} type="hidden" />
        <FormInput name={`${name}.stateCode`} type="hidden" />
      </Box>
      <FormAddressAutocompleteModal {...modalProps} />
    </>
  );
};

export default FormAddressAutocomplete;
