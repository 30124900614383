import { useIntl } from "react-intl";

import {
  HeadersWrapper,
  NumberColumnHeader,
  SharesColumnHeader
} from "./styles";

const ResidueAllocateStep: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <HeadersWrapper>
      <SharesColumnHeader textAlign="center">%</SharesColumnHeader>
      <NumberColumnHeader>
        {formatMessage({ defaultMessage: "Age" })}
      </NumberColumnHeader>
    </HeadersWrapper>
  );
};

export default ResidueAllocateStep;
