import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerResidueOverviewStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `We'll now cover distributing the balance of ${partnerFirstName}'s estate.`,
        muiIconHeader: <InfoOutlinedIcon />,
        subText:
          "Your Residuary Estate represents what's left over after making any specific gifts of assets or cash. Don't worry about those specific gifts yet -- we'll get to those in a bit. We're starting with your Residuary Estate because (for most people) the Residuary Estate is the largest portion of their gifts."
      }}
    />
  );
};

export default PartnerResidueOverviewStep;
