import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { SyntheticEvent, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import { useFormField } from "../hooks";

import {
  AutocompleteBaseOption,
  FormAutocompleteProps
} from "./types";

// eslint-disable-next-line react/function-component-definition
function FormAutocomplete<
  T extends string | AutocompleteBaseOption = string
>({
  textFieldProps = {},
  onChange: onChangeProp,
  name,
  freeSolo,
  ...restProps
}: FormAutocompleteProps<T>): ReactComponentReturnType {
  const defaultValue = get(restProps, "defaultValue", null);
  const { value, setValue } = useFormField(name, defaultValue);

  const {
    control,
    formState: { errors },
    setValue: setRHFValue
  } = useFormContext();

  const onChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      newValue: string | T | NonNullable<T> | null
    ) => {
      const fieldValue =
        typeof newValue === "string" ? newValue : newValue?.value;

      setValue(fieldValue);
      setRHFValue(name, fieldValue, { shouldValidate: true });

      onChangeProp?.(event, newValue);
    },
    [setValue, setRHFValue, name, onChangeProp]
  );

  const fieldError = get(errors, `${name}.message`);

  return (
    <Controller
      defaultValue={value}
      {...{ control, name }}
      render={({ field }): JSX.Element => (
        <Autocomplete<T, false, boolean, boolean>
          disableClearable
          autoSelect={freeSolo}
          {...restProps}
          {...field}
          getOptionLabel={(option): string =>
            typeof option === "string" ? option : option?.label
          }
          renderOption={(params, option): JSX.Element => (
            <li
              {...params}
              key={
                typeof option === "string"
                  ? option
                  : option.key || option.value
              }
            >
              {typeof option === "string" ? option : option.label}
            </li>
          )}
          renderInput={(params): JSX.Element => (
            <TextField
              {...(fieldError
                ? { error: true, helperText: fieldError }
                : {})}
              {...params}
              variant="standard"
              {...textFieldProps}
            />
          )}
          {...{ value, onChange, freeSolo }}
        />
      )}
    />
  );
}

export default FormAutocomplete;
