import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { usePlanForm } from "@providers/planForm/context";
import { PetPlanValues, Pet } from "@pages/PetPlan/types";

const DesignateSameGuardiansStep: React.FC = () => {
  const { values } = usePlanForm<PetPlanValues>();
  const {
    pets: [firstPet, secondPet, ...otherPets]
  } = values;

  const { setValue: setPets, value: petsValue } =
    useFormField<Pet[]>("pets");

  const onSubmit = useCallback(
    ({ sameGuardian }: SingleSelectFormValues) => {
      if (parseBoolean(sameGuardian)) {
        setPets(
          petsValue.map((petValue) => ({
            ...petValue,
            guardians: firstPet.guardians,
            backupGuardians: firstPet.backupGuardians
          }))
        );
      }
    },
    [setPets, petsValue, firstPet.guardians, firstPet.backupGuardians]
  );

  let guardianAdjQty = "Guardian";

  if (
    firstPet &&
    firstPet.backupGuardians &&
    firstPet.backupGuardians?.length > 0
  ) {
    guardianAdjQty = "Guardians";
  }

  if (secondPet && otherPets.length === 0)
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `Would you like to nominate the same ${guardianAdjQty} for ${secondPet.name}, too?`
        }}
        {...{ onSubmit }}
        name="sameGuardian"
      />
    );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Would you like to nominate the same ${guardianAdjQty} for your other pets, too?`
      }}
      {...{ onSubmit }}
      name="sameGuardian"
    />
  );
};

export default DesignateSameGuardiansStep;
