import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { numberWithCommas } from "@utils/misc";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";

import { LAST_WILL_MANAGEMENT_ROUTES } from "../../consts";

export const usePrimaryDrafterManagementReviewTab = ({
  values
}: {
  values: LastWillPlanValues;
}): ReviewItem[] => {
  const { formatMessage } = useIntl();

  const {
    users: [primaryDrafter]
  } = values;

  const {
    personalRepresentatives = [],
    backupPersonalRepresentatives = []
  } = primaryDrafter?.lastWill || {};

  const representativesInfo = useMemo<ReviewItem | undefined>(
    () =>
      personalRepresentatives.length > 0
        ? {
            title: `Primary Representative:`,
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Management,
              LAST_WILL_MANAGEMENT_ROUTES.DesignateRepresentative
            ]),
            items: [
              [
                `${personalRepresentatives[0].name}`,
                `Relationship: ${personalRepresentatives[0].relationship}`
              ]
            ]
          }
        : undefined,
    [personalRepresentatives]
  );

  const backupRepresentativesInfo = useMemo<ReviewItem | undefined>(
    () =>
      backupPersonalRepresentatives.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Backup Representative:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Management,
              LAST_WILL_MANAGEMENT_ROUTES.DesignateBackupRepresentative
            ]),
            description:
              "(Backup Representatives will serve in the order named)",
            isFullWidth: true,
            items: backupPersonalRepresentatives.map((backupRep) => [
              backupRep.name,
              `Relationship: ${backupRep.relationship}`
            ])
          }
        : undefined,
    [backupPersonalRepresentatives, formatMessage]
  );

  const representativeCompensationInfo = useMemo<
    ReviewItem | undefined
  >(
    () =>
      primaryDrafter.lastWill?.personalRepresentatives?.length
        ? {
            title: formatMessage({
              defaultMessage: "Representative Compensation:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Management,
              LAST_WILL_MANAGEMENT_ROUTES.RepCompensation
            ]),
            isFullWidth: true,
            items: [
              [
                primaryDrafter.lastWill?.representativesCompensationType?.toString() ===
                  "Reasonable compensation and reimbursement of expenses" ||
                primaryDrafter.lastWill?.representativesCompensationType?.toString() ===
                  "No compensation. Reimbursement of reasonable expenses only"
                  ? primaryDrafter.lastWill?.representativesCompensationType?.toString()
                  : "",
                primaryDrafter.lastWill
                  ?.representativesCompensationDollar
                  ? `Compensation of $${numberWithCommas(
                      primaryDrafter.lastWill?.representativesCompensationDollar?.toString()
                    )}, plus reimbursement of reasonable expenses`
                  : "",
                primaryDrafter.lastWill
                  ?.representativesCompensationPercent
                  ? `Compensation up to ${primaryDrafter.lastWill?.representativesCompensationPercent?.toString()}% of my estate, plus reimbursement of reasonable expenses.`
                  : ""
              ].filter((item): item is string => Boolean(item))
            ]
          }
        : undefined,
    [primaryDrafter, formatMessage]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        representativesInfo,
        backupRepresentativesInfo,
        representativeCompensationInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      representativeCompensationInfo,
      backupRepresentativesInfo,
      representativesInfo
    ]
  );

  return items;
};
