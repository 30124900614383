import { HTMLAttributes } from "react";
import { keyframes, styled } from "@mui/material/styles";

import Image from "@components/Image";

import { StyledHeaderArrowProps } from "./types";

const bounceAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ImageWrapper = styled(
  ({
    isReversed,
    ...restProps
  }: StyledHeaderArrowProps & HTMLAttributes<HTMLElement>) => (
    <div {...restProps} />
  )
)<StyledHeaderArrowProps>(({ isReversed }) => ({
  position: "absolute",
  top: "100%",
  right: isReversed ? "auto" : "-1.3rem",
  left: isReversed ? "-1.3rem" : "auto",
  animation: `${bounceAnimation} 1s ease infinite`
}));

export const Img = styled(Image, {
  shouldForwardProp: (props) => props !== "isReversed"
})<StyledHeaderArrowProps>(({ isReversed }) => ({
  width: "8.125rem",
  height: "8.125rem",
  transform: isReversed
    ? "rotate(-90deg) scale(1, -1)"
    : "rotate(-90deg)"
}));
