import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";
import { useFormField } from "@components/FormFields/hooks";

const PartnerDesignateBackupRepresentativeStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <PersonDetailsArrayArchetype
      stepLayout={{
        title: `Who would ${partnerFirstName} like to serve as backup Representative?`,
        subText:
          "You can add additional backup Representatives by hitting the 'Add Another Backup Representative' button."
      }}
      personRole="Backup Representative"
      addBtnLabel="Add Another Backup Representative"
      removeBtnLabel="Remove Backup Representative"
      name="users.1.lastWill.backupPersonalRepresentatives"
    />
  );
};

export default PartnerDesignateBackupRepresentativeStep;
