import { useCallback, useEffect, useState } from "react";

import {
  useFindPeopleQuery,
  useCreatePlanDraftMutation,
  useGetPlanDraftQuery,
  useUpdatePlanDraftMutation
} from "@api/planDrafts/hooks";
import { FindPeople_findPeople as FoundPerson } from "@api/planDrafts/__generated__/FindPeople";
import { UpdatePlanDraftVariables } from "@api/planDrafts/__generated__/UpdatePlanDraft";
import { GetPlanDraft_planDraft as PlanDraft } from "@api/planDrafts/__generated__/GetPlanDraft";

import { UsePlanDataStorageOptions } from "./types";
import { generateStorageKey } from "./utils";

export const usePlanDataStorage = <T>({
  mode,
  planType,
  onFetchDraftData
}: UsePlanDataStorageOptions) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string>();
  const [planDraft, setPlanDraft] = useState<PlanDraft>();
  const [suggestedPeople, setSuggestedPeople] = useState<
    FoundPerson[]
  >([]);

  const [updatePlanDraft] = useUpdatePlanDraftMutation();
  const { refetch: callFindPeopleQuery } = useFindPeopleQuery({
    skip: !planType || mode !== "online" || !token,
    variables: { token: token || "" },
    onCompleted: (result) => {
      setSuggestedPeople(result?.findPeople ?? []);
    }
  });

  const [createPlanDraft] = useCreatePlanDraftMutation({
    onCompleted: (data) => {
      setToken(data?.createPlanDraft?.token);
    }
  });

  useGetPlanDraftQuery({
    skip: !planType || mode !== "online",
    variables: {
      type: planType
    },
    onCompleted: (response) => {
      setLoading(false);
      if (!response.planDraft) {
        createPlanDraft({ variables: { type: planType, data: {} } });
      } else {
        onFetchDraftData?.(response.planDraft);
        setPlanDraft(response.planDraft);
        setToken(response.planDraft.token);
      }
    }
  });

  const refreshSuggestedPeople = useCallback(async () => {
    if (!token) {
      return;
    }

    if (mode === "online") {
      await callFindPeopleQuery();
    }
  }, [callFindPeopleQuery, mode, token]);

  const saveOnline = useCallback(
    async (data: UpdatePlanDraftVariables) => {
      await updatePlanDraft({
        variables: data
      });
    },
    [updatePlanDraft]
  );

  const saveToLocalStorage = useCallback(
    async (data: UpdatePlanDraftVariables) => {
      const storageKey = generateStorageKey(planType);

      await localStorage.setItem(storageKey, JSON.stringify(data));
    },
    [planType]
  );

  const saveData = useCallback(
    async (data: T, lastPath: string) => {
      const updatedData: UpdatePlanDraftVariables = {
        type: planType,
        data,
        lastPath
      };

      switch (mode) {
        case "localStorage":
          return saveToLocalStorage(updatedData);
        case "online":
          return saveOnline(updatedData);
        default:
          // eslint-disable-next-line no-console
          console.error("Storage mode not implemented");

          return false;
      }
    },
    [planType, mode, saveToLocalStorage, saveOnline]
  );

  const initializeLocalStorageData = useCallback(() => {
    const storageKey = generateStorageKey(planType);
    const storageData = localStorage.getItem(storageKey);

    if (storageData) {
      const parsedData = JSON.parse(storageData);

      onFetchDraftData?.(parsedData);
      setPlanDraft(parsedData);
    }

    setLoading(false);
  }, [onFetchDraftData, planType]);

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (mode) {
      case "localStorage":
        initializeLocalStorageData();
        break;
    }
  }, [initializeLocalStorageData, mode]);

  return {
    token,
    loading,
    suggestedPeople,
    planDraft,
    saveData,
    refreshSuggestedPeople
  };
};
