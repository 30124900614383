import { FormattedMessage } from "react-intl";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useCallback, useEffect, memo } from "react";
import omit from "lodash/omit";
import Stack from "@mui/material/Stack/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";

import { useFormField } from "@components/FormFields/hooks";

import { ArrayFormFieldProps } from "./types";

const ArrayFormField = ({
  name,
  addBtnLabel,
  removeBtnLabel,
  SingleItem,
  customRemoveBtn = false
}: ArrayFormFieldProps): React.ReactElement => {
  const controls = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: controls.control,
    name
  });

  const { setValue } = useFormField(name);
  const onRemoveClick = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }

    setValue(fields.map((field) => omit(field, ["id"])));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, append]);

  return (
    <>
      <Stack
        component="ul"
        sx={{ listStyle: "none", margin: 0, padding: 0 }}
        spacing={4}
      >
        {fields.map((item, index) => (
          <Stack component="li" key={item.id} spacing={4}>
            <SingleItem
              fieldsCount={fields.length}
              removeField={(): void => onRemoveClick(index)}
              prefix={`${name}.${index}`}
              {...{ index }}
            />
            {!customRemoveBtn && fields.length > 1 && (
              <Button
                color="inherit"
                sx={{ width: "100%" }}
                onClick={(): void => onRemoveClick(index)}
              >
                {removeBtnLabel || (
                  <FormattedMessage defaultMessage="- Remove" />
                )}
              </Button>
            )}
          </Stack>
        ))}
      </Stack>
      <Box mt={5}>
        <Button
          color="inherit"
          sx={{ width: "100%" }}
          onClick={(): void => append({})}
        >
          {addBtnLabel || (
            <FormattedMessage defaultMessage="+ Add another" />
          )}
        </Button>
      </Box>
    </>
  );
};

export default memo(ArrayFormField);
