import { Typography, Box, CircularProgress } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import Error from "@components/Error";
import getPlanHumanizedTitle from "@hooks/getPlanHumanizedTitle";

import {
  InviteClientFormProps,
  InviteClientFormValues
} from "./types";
import InviteClientFormElements from "./InviteClientFormElements";
import { useFormWithYup } from "@hooks/useFormWithYup";

import { useInviteClientMemberSchema } from "../../hooks/useInviteClientMemberSchema";
import { useInviteClientMemberForm } from "../../hooks";
import * as Styled from "../../styles";
import { useCopyClientInviteMemberForm } from "../../hooks/useCopyClientInviteMemberForm";

const InviteClientForm = ({
  defaultValues,
  onEmailInvite,
  onCreateAndCopy,
  advisorName
}: InviteClientFormProps) => {
  const [action, setAction] = useState("");

  const validationSchema = useInviteClientMemberSchema();
  const methods = useFormWithYup<InviteClientFormValues>(
    validationSchema,
    {
      defaultValues
    }
  );

  const { onInviteClient, error, reset, loading } =
    useInviteClientMemberForm({
      methods,
      onCompleted: onEmailInvite
    });

  const {
    onCreateAndCopyClientInvite,
    error: copyError,
    loading: copyLoading
  } = useCopyClientInviteMemberForm({
    methods,
    onCompleted: onCreateAndCopy
  });

  const { formatMessage } = useIntl();

  const [clientFullName = "", recommendedPlan] = methods.watch([
    "fullName",
    "recommendedPlan"
  ]);

  useEffect(() => {
    const defaultMessage = formatMessage(
      {
        defaultMessage: `{intro}\n\nI'm inviting you to create your {recommendation} with JIC Estates. After accepting the invite, simply follow the step-by-step questionnaire to generate your plan documents. If you have any questions along the way, please don't hesitate to reach out to me or the JIC Estates member success team.\n\nBest regards,\n\n{advisorName}`
      },
      {
        intro: clientFullName ? `${clientFullName} -- ` : ``,
        recommendation: recommendedPlan
          ? `${getPlanHumanizedTitle(recommendedPlan)} estate plan`
          : "estate plan",
        advisorName
      }
    );

    methods.setValue("message", defaultMessage);
  }, [
    formatMessage,
    clientFullName,
    recommendedPlan,
    methods,
    advisorName
  ]);

  if (loading || copyLoading) {
    return (
      <Styled.LoadingWrapper>
        <CircularProgress />
      </Styled.LoadingWrapper>
    );
  }

  if (error || copyError) {
    return <Error onAction={reset} />;
  }

  const handleSubmit = (data: InviteClientFormValues) => {
    if (action === "invite") {
      onInviteClient(data);
    } else if (action === "copy") {
      onCreateAndCopyClientInvite(data);
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        gap="1.25rem"
        alignItems="center"
        sx={{
          mt: "2.5rem",
          mb: "1.25rem"
        }}
      >
        <PersonAddAlt1Icon />
        <Typography variant="h5">
          <FormattedMessage defaultMessage="Invite Client" />
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <InviteClientFormElements
            onCopyInviteLink={() => setAction("copy")}
            onInviteClient={() => setAction("email")}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default InviteClientForm;
