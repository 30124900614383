import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { BlogLinks } from "@setup/consts/articleLinks";

const AssetOverviewStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Funding Your Trust`,
      muiIconHeader: <InfoOutlinedIcon />,
      subText: `In order to be effective, your revocable trust must be "funded" with assets titled in the name of the trust. We'll follow standard convention and automatically fund your trust with $10 in cash. If you like, you can also take this time to add information on other assets you'd like held in your trust.`,
      moreInfoModalProps: {
        togglerLabel: "Learn more.",
        title: "Funding Your Trust",
        content: (
          <p>
            <strong>Initial Funding Requirement.</strong> A revocable
            trust must be funded with assets in order to be legally
            valid.
            <br />
            <br />
            Since titling assets in the name of the trust at the same
            time as creating it is not always practical, the standard
            convention is to fund your trust with a nominal cash
            amount, usually $10.
            <br />
            <br />
            You can keep this nominal cash amount on hand until
            you&apos;ve had a chance to transfer your bank account or
            other cash holding account to the trust.
            <br />
            <br />
            <strong>Adding Assets to Your Trust.</strong> There are
            two primary reasons to add assets to your trust:
            <ol>
              <li>
                Convert an asset that normally must pass through a
                last will to one that passes through the trust (and
                outside the probate process)
              </li>
              <li>
                Make a gift subject to the distribution considerations
                and other protections in your trust
              </li>
            </ol>
            For a more in depth discussion of these reasons, click
            &apos;Learn More&apos; to check out our blog.
          </p>
        ),
        actions: [
          {
            label: "Learn More",
            href: BlogLinks.whatIsRevocableTrust
          }
        ],
        closeLabel: "Got it"
      }
    }}
  />
);

export default AssetOverviewStep;
