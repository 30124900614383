import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { BlogLinks } from "@setup/consts/articleLinks";

const PartnerIncludeNoContestStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );
  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to include a No Contest Provision in ${partnerFirstName}'s trust?`,
        subText:
          "A No Contest Provision says that if one of your beneficiaries unsuccessfully disputes your trust, that person forfeits all the gifts he or she would have otherwise received.",
          moreInfoModalProps: {
            title:
              "Should You Include a No Contest Provision?",
            togglerLabel: "Learn more.",
            content: (
              <p>
                A No Contest Provision says that if one of your
                beneficiaries unsuccessfully disputes your estate plan,
                that person forfeits all the gifts he or she would have
                otherwise received.
                <br />
                <br />
                The goal of a No Contest Provision is to disincentivize
                beneficiaries from challenging the administration and
                validity of your estate plan. You're essentially telling
                your beneficiaries "take it or leave it" or "deal or no
                deal."
                <br />
                <br />
                <strong>Why Include a No Contest Provision.</strong>{" "}
                Defending a contested estate plan can be a time-consuming,
                stressful, and costly endeavor for your estate and
                trustees or executors. Even if an estate challenge fails
                (as most do), the extra legal expenses may leave your
                beneficiares waiting longer for distributions and
                receiving less.
                <br />
                <br />
                <strong>Important Considerations.</strong> In order to be
                effective, No Contest Provisions must be <em>funded</em>.
                A funded no contest provision is one where beneficiaries
                have sufficiently large stakes in the existing estate plan
                that they are unlikely to risk losing them. A beneficiary
                set to receive $100 who believes he or she should be
                entitled to $100,000 is unlikely to be dissauded by a no
                contest provision.
                <br />
                <br />
                For an even more depth discussion of no contest
                provisions, check out our JIC Estates blog.
              </p>
            ),
          actions: [
            {
              label: "Read More",
              href: BlogLinks.whatIsNoContest
            }
          ],
          closeLabel: "Got it"
        }
      }}
      name="users.1.lastWill.includeNoContest"
    />
  );
};

export default PartnerIncludeNoContestStep;
