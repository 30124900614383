import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { DocumentType } from "@pages/EstateAdminQuiz/enums";
import { BlogLinks } from "@setup/consts/articleLinks";

const AssetValueProbateStep: React.FC = () => {
  const { value: assetValue } = useFormField<string>(
    "estateData.assetValue"
  );
  const { value: docType } = useFormField<DocumentType>(
    "estateData.documentType"
  );

  const probateAssetValuesArray = [
    "<$50,000",
    "$50,001 - $100,000",
    "$100,001 - $250,000",
    "$250,001 - $1,000,000",
    "$1,000,001 - $5,000,000",
    "$5,000,001 - $10,000,000",
    ">$10,000,000"
  ];

  let indexValue = probateAssetValuesArray.indexOf(assetValue);

  // If n/a or if something goes wrong, give all the options
  if (indexValue === -1) {
    indexValue = probateAssetValuesArray.length - 1;
  }

  const getProbateAssetValues = (): {
    value: string;
    label: string;
  }[] => {
    const valuesUpToIndex = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= indexValue; i++) {
      const obj = {
        value: probateAssetValuesArray[i],
        label: probateAssetValuesArray[i]
      };

      valuesUpToIndex.push(obj);
    }

    return valuesUpToIndex;
  };

  const options = [
    { label: "I don't know", value: "n/a" },
    ...getProbateAssetValues()
  ];

  if (
    docType === DocumentType.REVOCABLE_TRUST ||
    docType === DocumentType.IRREVOCABLE_TRUST
  ) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `What's the value of non-trust assets without a built-in beneficiary designation?`,
          subText:
            "These are assets that are not titled in the name of the trust or that don't have their own beneficiary designation. ",
          moreInfoModalProps: {
            togglerLabel: "Learn more.",
            title:
              "Understanding the Impact of Non-Trust Assets Without A Built-In Beneficiary Designation",
            content: (
              <>
                In order to be most effective, trusts be
                &quot;funded&quot; which is the process of titling
                assets in the name of the trust.
                <br /> <br />
                Any assets that weren&apos;t funded into the Trust
                during the Trustmaker&apos;s life will transfer one of
                two different ways:
                <ol>
                  <li>
                    If the asset has a built-in beneficiary
                    designation, such as a 401k or investment account,
                    the asset will transfer directly to the
                    beneficiary or beneficiaries listed on the
                    account.
                  </li>
                  <li>
                    If the asset does not have a built-in beneficiary
                    designation, the asset will transfer to the Trust
                    and then to the beneficiaries via a Pour Over
                    Will, provided that the Trustmaker created one.
                  </li>
                </ol>
                Depending on the value of assets that sit outside of
                the trust without a beneficiary designation, that part
                of the estate may need to go through the probate
                process.
                <br /> <br />
                For a more comprehensive discussion of these probate
                and non-probate assets, check out our resource
                library.
              </>
            ),
            actions: [
              {
                label: "Go to Resource Library",
                href: BlogLinks.operationOfLawVsProbate
              }
            ],
            closeLabel: "Got it"
          }
        }}
        items={options}
        name="estateData.probateAssetValue"
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `What's the value of estate assets that don't have a built-in beneficiary designation?`,
        subText:
          "Examples of such assets often include solely owned real estate, vehicles, and checking accounts.",
        moreInfoModalProps: {
          togglerLabel: "Learn more.",
          title: "How Probate Assets Transfer",
          content: (
            <p>
              Non-titled assets and other assets without their own
              built-in beneficiary designations are called probate
              assets.
              <br /> <br />
              In order to know who should inherit those assets, we
              have to look at the last will or (if deceased person
              died without a will) at the intestacy provisions of the
              state&apos;s legal statutes.
              <br /> <br />
              The court-supervised process to do this is called
              Probate.
              <br /> <br />
              Some states have special thresholds under which an
              estate qualifies for an expedited probate proceeding.
              We&apos;ll compare the value of this estate&apos;s
              probate assets to the state statutes to determine which
              type of probate proceeding this estate needs.
              <br /> <br />
              For a more comprehensive discussion of probate and
              non-probate assets, check out our resource library.
            </p>
          ),
          actions: [
            {
              label: "Go to Resource Library",
              href: BlogLinks.operationOfLawVsProbate
            }
          ],
          closeLabel: "Got it"
        }
      }}
      items={options}
      name="estateData.probateAssetValue"
    />
  );
};

export default AssetValueProbateStep;
