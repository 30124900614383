import { Box } from "@mui/material";
import { useIntl } from "react-intl";

import Error from "@components/Error";
import Modal from "@components/Modal";

import AddAssetButton from "../AddAssetButton";
import AssetForm from "../AssetForm";

import { useAddAssetModal } from "./hooks";
import { AddAssetModalProps } from "./types";

const AddAssetsModal: React.FC<AddAssetModalProps> = ({
  lastWill,
  showIsFunded = false
}) => {
  const intl = useIntl();
  const {
    isOpen,
    canEdit,
    loading,
    error,
    toggleOn,
    toggleOff,
    onSubmit,
    reset
  } = useAddAssetModal(lastWill);

  if (error) {
    return (
      <Box mt="1.5rem">
        <Error
          onAction={() => {
            toggleOff();
            reset();
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: "1.5rem"
      }}
    >
      {canEdit && (
        <AddAssetButton
          onClick={toggleOn}
          variant="contained"
          color="primary"
        />
      )}
      <Modal
        fullWidth
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Add Asset"
        })}
        content={
          <AssetForm {...{ onSubmit, loading, showIsFunded }} />
        }
      />
    </Box>
  );
};

export default AddAssetsModal;
