import { useCallback } from "react";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup
} from "@mui/material";

import { useFormField } from "../hooks";

import { FormRadioButtonsProps, RadioValue } from "./types";

const FormRadioButtons: React.FC<FormRadioButtonsProps> = ({
  items,
  name,
  ...restProps
}) => {
  const defaultValue = get(
    restProps,
    "defaultValue",
    restProps.defaultValue || undefined
  );
  const { value, setValue } = useFormField(name, defaultValue);

  const {
    control,
    setValue: setRHFValue,
    formState: { errors }
  } = useFormContext();

  const fieldError = get(errors, `${name}.message`);

  const onChange = useCallback(
    (newValue: RadioValue) => {
      setValue(newValue);
      setRHFValue(name, newValue, {
        shouldValidate: true
      });
    },
    [setValue, setRHFValue, name]
  );

  return (
    <Controller
      {...{ control, name }}
      defaultValue={value !== undefined ? value : defaultValue}
      render={({ field: { onChange: _, ...field } }): JSX.Element => (
        <FormControl error={!!fieldError} variant="standard">
          <RadioGroup
            {...field}
            {...{ defaultValue }}
            value={value === null ? "" : value}
          >
            {items.map(({ label, value: itemValue }) => (
              <FormControlLabel
                onChange={(): void => onChange(itemValue)}
                key={label}
                value={itemValue === null ? "" : itemValue}
                control={<Radio size="small" {...restProps} />}
                {...{ label }}
              />
            ))}
          </RadioGroup>
          {!!fieldError && (
            <FormHelperText>{fieldError}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormRadioButtons;
