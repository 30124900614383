import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { Trust } from "@pages/LastWill/types";

const PartnerNominateBackupTrusteeStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );
  const { setValue: setSuccessorTrustees } = useFormField(
    "users.1.trust.successorTrustees"
  );

  const { value: trust } = useFormField<Trust>("users.1.trust");
  const { coTrustees } = trust;

  const trusteeText =
    coTrustees && coTrustees.length > 1
      ? "Co-Trustees are"
      : "Co-Trustee is";

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const nominateSuccessorTrustee = get(
        data,
        "users.1.trust.nominateSuccessorTrustee"
      );

      if (!parseBoolean(nominateSuccessorTrustee)) {
        setSuccessorTrustees([]);
      }
    },
    [setSuccessorTrustees]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to nominate a Successor Trustee?`,
        subText: `Your Successor Trustee can serve as a backup if your ${trusteeText} unavailable or unwilling to serve.`,
        moreInfoModalProps: {
          title: "Trustee Succession",
          togglerLabel: "Learn more.",
          content: (
            <p>
              As general guidance, you should designate at least two
              Co-Trustees and/or Successor Trustees who are reasonably
              likely to survive you.
              <br />
              <br />
              Trustees serve in the following order:
              <ol>
                <li>You and Any Co-Trustee(s)</li>
                <li>1st Successor Trustee</li>
                <li>2nd Successor Trustee</li>
                <li>
                  3rd Successor Trustee, and so forth for all
                  additional Successor Trustees
                </li>
              </ol>
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="users.1.trust.nominateSuccessorTrustee"
    />
  );
};

export default PartnerNominateBackupTrusteeStep;
