/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LAST_WILL_POWER_OF_ATTORNEY_ROUTES } from "@pages/LastWill/sections/PowerOfAttorney/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { CompensationTypes } from "@setup/consts/misc";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { ROUTES } from "@setup/consts/routes";
import { POWER_OF_ATTORNEY_ROUTES } from "@pages/PowerOfAttorney/steps/main/consts";
import { numberWithCommas } from "@utils/misc";

import { getLinkFullUrl } from "../utils";

export const usePartnerPoaReview = (): ReviewItem[] => {
  const { formatMessage } = useIntl();
  const { trust, lastWill } = useMemberPlansState();

  const partnerLastWill = trust
    ? trust.partnerLastWill
    : lastWill?.partnerLastWill;

  const canEdit = trust ? trust.canEdit : lastWill?.canEdit;

  const {
    agents = [],
    generalAuthority = false,
    powers,
    compensation = "",
    compensationLimit,
    compensationType
  } = partnerLastWill?.powerOfAttorney ?? {};

  const successorAgents = useMemo(
    () => agents?.filter((agent) => agent?.isSuccessor),
    [agents]
  );

  const primaryAgents = useMemo(
    () => agents?.filter((agent) => !agent?.isSuccessor),
    [agents]
  );

  const makePOA = useMemo<ReviewItem | undefined>(
    () =>
      partnerLastWill?.powerOfAttorney
        ? undefined
        : {
            title: formatMessage({
              defaultMessage: "Power of Attorney:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA
                ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `🚫 You chose to not create a Power of Attorney at this time.`
                })
              ]
            ]
          },
    [formatMessage, trust, canEdit, partnerLastWill?.powerOfAttorney]
  );

  const agentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      primaryAgents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Primary Agent:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateAgent
                ]),
            items: [
              ...primaryAgents.map((agent) => [
                `${agent?.agent?.fullName ?? ""}`,
                `${agent?.agent?.address?.address1 ?? ""}`,
                `${agent?.agent?.address?.address2 ?? ""}`,
                agent?.agent?.address
                  ? `${agent?.agent?.address?.city}, ${agent?.agent?.address?.state?.name} ${agent?.agent?.address?.zip}`
                  : ""
              ])
            ]
          }
        : undefined,
    [formatMessage, trust, canEdit, primaryAgents]
  );

  const successorAgentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      successorAgents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent
                ]),
            items: [
              ...successorAgents.map((agent) => [
                `${agent?.agent?.fullName ?? ""}`,
                `${agent?.agent?.address?.address1 ?? ""}`,
                `${agent?.agent?.address?.address2 ?? ""}`,
                agent?.agent?.address
                  ? `${agent?.agent?.address?.city}, ${agent?.agent?.address?.state?.name} ${agent?.agent?.address?.zip}`
                  : ""
              ])
            ]
          }
        : agents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent
                ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not nominating a successor agent.`
                })
              ]
            ]
          }
        : undefined,
    [agents, formatMessage, trust, canEdit, successorAgents]
  );

  const agentPowersInfo = useMemo<ReviewItem | undefined>(
    () =>
      powers || generalAuthority
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Authority:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant
                ]),
            items: [
              [
                formatMessage({
                  defaultMessage:
                    "Your agent's authority to act is limited to the following subjects:"
                }),
                generalAuthority
                  ? "- ✅ General authority to act over all common subjects."
                  : "",
                powers
                  ? `- ${powers
                      .map((power) => `${power?.toString()}`)
                      .join(", ")}`
                  : ""
              ].filter((item): item is string => Boolean(item))
            ]
          }
        : undefined,
    [canEdit, formatMessage, trust, generalAuthority, powers]
  );

  const compensationInfo = useMemo<ReviewItem | undefined>(
    () =>
      compensation
        ? {
            title: formatMessage({
              defaultMessage: "Agent Compensation:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation
                ]),

            items: [
              [
                compensation,
                `${
                  compensation ===
                  CompensationTypes.specifyCompensationAmount
                    ? [
                        [
                          `$${numberWithCommas(compensationLimit)}`,
                          compensationType
                        ].join(" ")
                      ]
                    : []
                }`
              ]
            ]
          }
        : undefined,
    [
      compensation,
      trust,
      canEdit,
      formatMessage,
      compensationLimit,
      compensationType
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        makePOA,
        agentsInfo,
        successorAgentsInfo,
        agentPowersInfo,
        compensationInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      makePOA,
      agentsInfo,
      successorAgentsInfo,
      agentPowersInfo,
      compensationInfo
    ]
  );

  return items;
};
