import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";

const PrimaryTrustEndStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { value: isJointTrust } = useFormField(
    "users.0.trust.isJointTrust"
  );

  const {
    users: [primaryDrafter, partner]
  } = values;

  return isJointTrust ? (
    <InterstitialArchetype
      stepLayout={{
        title: `Great work, ${primaryDrafter.firstName}! Let's move on to ${partner.firstName}'s selection for Personal Representatives.`,
        muiIconHeader: <ThumbUpAltOutlinedIcon />
      }}
    />
  ) : (
    <InterstitialArchetype
      stepLayout={{
        title: `Great work, ${primaryDrafter.firstName}! Let's move on to ${partner.firstName}'s Trust now.`,
        subText: `We'll walk through the same questions on how ${partner.firstName} wants to set up ${partner.firstName}'s trust.`,
        muiIconHeader: <ThumbUpAltOutlinedIcon />
      }}
    />
  );
};

export default PrimaryTrustEndStep;
