import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { parseBoolean } from "@utils/parseBoolean";
import { PlanOption } from "@api/__generated__/globalTypes";

const DesignatePartnerAsAgentStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter, partner]
  } = values;

  const { value: planOption } =
    useFormField<PlanOption>("planOption");
  const { value: includePartnerAdvanceHealthCareDirective } =
    useFormField<boolean>("includePartnerAdvanceHealthCareDirective");
  const { value: includeAdvanceHealthCareDirective } =
    useFormField<boolean>("includeAdvanceHealthCareDirective");

  const { setValue: setDrafterAgentValue } = useFormField(
    "users.0.healthcare.agents.0"
  );
  const { setValue: setPartnerAuthorizeAgentsValue } = useFormField(
    "users.1.healthcare.authorizeAgents"
  );
  const { setValue: setPartnerAgentValue } = useFormField(
    "users.1.healthcare.agents.0"
  );

  const onSubmit = useCallback(
    ({ partnerAsHealthcareAgent }: SingleSelectFormValues) => {
      if (
        partnerAsHealthcareAgent &&
        planOption === PlanOption.COUPLE
      ) {
        setDrafterAgentValue({
          name: `${partner.firstName} ${partner.lastName}`,
          address: partner.address,
          phone: partner.phone
        });
        setPartnerAgentValue({
          name: `${primaryDrafter.firstName} ${primaryDrafter.lastName}`,
          address: primaryDrafter.address,
          phone: primaryDrafter.phone
        });
        setPartnerAuthorizeAgentsValue(true);
      }
      if (
        partnerAsHealthcareAgent &&
        planOption === PlanOption.SINGLE
      ) {
        setDrafterAgentValue({
          name: `${partner.firstName} ${partner.lastName}`,
          address: partner.address,
          phone: partner.phone
        });
      }
    },
    [
      partner,
      primaryDrafter,
      planOption,
      setDrafterAgentValue,
      setPartnerAgentValue,
      setPartnerAuthorizeAgentsValue
    ]
  );

  if (
    parseBoolean(includeAdvanceHealthCareDirective) === true &&
    parseBoolean(includePartnerAdvanceHealthCareDirective) === true
  ) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `Do you and ${partner.firstName} want to designate each other as your primary healthcare agents?`,
          subText: `A healthcare agent is a trusted individual who is authorized to access your medical information and make certain healthcare decisions on your behalf, should you be unable to do so personally.`
        }}
        {...{ onSubmit }}
        name="partnerAsHealthcareAgent"
      />
    );
  }

  if (
    parseBoolean(includeAdvanceHealthCareDirective) === false &&
    parseBoolean(includePartnerAdvanceHealthCareDirective) === true
  ) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `Does ${partner.firstName} want ${primaryDrafter.firstName} to serve as ${partner.firstName}'s primary healthcare agent?`,
          subText: `A healthcare agent is a trusted individual who is authorized to access your medical information and make certain healthcare decisions on your behalf, should you be unable to do so personally.`
        }}
        {...{ onSubmit }}
        name="partnerAsHealthcareAgent"
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to designate ${partner.firstName} as your primary healthcare agent?`,
        subText: `A healthcare agent is a trusted individual who is authorized to access your medical information and make certain healthcare decisions on your behalf, should you be unable to do so personally.`
      }}
      {...{ onSubmit }}
      name="partnerAsHealthcareAgent"
    />
  );
};

export default DesignatePartnerAsAgentStep;
