import { startCase } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import { RemainderStrategy } from "@hooks/useTrustEnums";
import {
  DistribType,
  Restrictions,
  TerminationDistribution,
  VestingType,
  DistributionType,
  PartnerType
} from "@api/__generated__/globalTypes";
import {
  SubTrust,
  // eslint-disable-next-line camelcase
  SubTrust_vestingSchedule
} from "@api/trusts/__generated__/SubTrust";
import { VestingSchedule } from "@api/trusts/__generated__/VestingSchedule";
import {
  Asset_beneficiaries as Beneficiary,
  Asset_beneficiaries_backupBeneficiaries as BackupBeneficiary
} from "@api/plans/__generated__/Asset";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_GIFTS_ROUTES } from "@pages/Trust/sections/Gifts/consts";
import { ROUTES } from "@setup/consts/routes";

export const getAssetBeneficiaryInfo = (
  beneficiaries: Beneficiary[],
  backupBeneficiaries: Beneficiary[]
) => [
  ...beneficiaries.map(
    (beneficiary) =>
      `${beneficiary.percentageValue}% to ${
        beneficiary.user?.fullName ?? beneficiary?.institution?.name
      }`
  ),
  ...backupBeneficiaries.map(
    (beneficiary) =>
      `${beneficiary.percentageValue}% to ${
        beneficiary.user?.fullName ?? beneficiary?.institution?.name
      }`
  )
];

export const getRemainderStrategyText = (
  partnerType: string,
  strategy: string | undefined,
  distribType: string | undefined,
  copyTrustSetup: boolean | undefined
) => {
  const description = [];

  if (strategy === RemainderStrategy.PartnerOnly) {
    description.push(
      `✅  My ${partnerType} is the primary beneficiary of my Trust Remainder.`
    );
  }

  if (strategy === RemainderStrategy.ChildrenOnly) {
    description.push(
      "✅  My children are the primary beneficiaries of my Trust Remainder."
    );
  }

  if (
    strategy === RemainderStrategy.ChildrenOnly &&
    (distribType === DistribType.SEPARATE_CHILDREN ||
      DistribType.POOLED_CHILDREN)
  ) {
    description.push(
      `✅  Gifts to my children will be made in ${
        distribType === DistribType.POOLED_CHILDREN
          ? "a Pooled Children's Trust"
          : "separate children's trusts as described below"
      }.`
    );
  }

  if (
    strategy === RemainderStrategy.ChildrenOnly &&
    distribType === DistribType.SEPARATE_CHILDREN &&
    copyTrustSetup
  ) {
    description.push(
      "✅  We'll set up the children's trusts with the same distribution standards, vesting, and backup contingencies."
    );
  }

  if (strategy === RemainderStrategy.PartnerAndChildren) {
    description.push(
      `✅  My ${partnerType} and children are the primary beneficiaries of my Trust Remainder.`
    );
  }

  if (
    strategy === RemainderStrategy.PartnerAndChildren &&
    distribType === DistribType.POOLED_FAMILY
  ) {
    description.push(
      "✅  My Trust Remainder gift will be made in a Pooled Family Trust"
    );
  }

  if (strategy === RemainderStrategy.NameIndividually) {
    description.push(
      `✅  I am gifting my Trust Remainder to the below individuals and/or organizations`
    );
  }

  return description;
};

const getDistribTypeText = (
  name: string | undefined,
  distribType: string | undefined
) => {
  if (distribType === DistribType.SEPARATE_INDIVIDUAL) {
    return `subject to the ${name ?? "terms of the"} Trust`;
  }

  if (distribType === DistribType.POOLED_CHILDREN) {
    return `subject to the terms of the Pooled Children's Trust`;
  }

  if (distribType === DistribType.POOLED_FAMILY) {
    return `subject to the terms of the Family Trust`;
  }

  if (distribType === DistribType.SEPARATE_CHILDREN) {
    return `subject to the terms of the Separate Children's Trust`;
  }

  return "outright";
};

const getVestingSchedule = (
  vestingSchedule: VestingSchedule[] | undefined
) =>
  vestingSchedule && vestingSchedule.length > 0
    ? [...vestingSchedule]
        .sort((a, b) => a.age - b.age)
        .map(
          (interval) =>
            `${interval.percentageValue}% at age ${interval.age}`
        )
        .join(", ")
    : "";

export const getVestingTypeText = (
  beneficiaryName: string | undefined,
  trustType: string | null,
  vestingType: VestingType | undefined,
  vestingSchedule: VestingSchedule[] | undefined,
  partnerType: PartnerType | undefined
) => {
  if (
    trustType === DistribType.POOLED_FAMILY &&
    vestingSchedule &&
    vestingSchedule.length > 0 &&
    partnerType
  ) {
    return `The Pooled Family Trust will terminate upon the later to occur of (i) my ${partnerType.toLowerCase()}'s death and (ii) my youngest child reaching the age of ${
      vestingSchedule[0].age ? vestingSchedule[0].age : "majority"
    }.`;
  }
  if (
    trustType === DistribType.POOLED_CHILDREN &&
    vestingSchedule &&
    vestingSchedule.length > 0
  ) {
    return `The Pooled Children's Trust will terminate when my youngest child reaches the age of ${
      vestingSchedule[0].age ? vestingSchedule[0].age : "majority"
    }`;
  }

  if (vestingType === VestingType.ON_DEATH && beneficiaryName) {
    return `The trust will be set up as a lifetime trust and continue for so long as ${beneficiaryName} is alive and the trust's funds remain.`;
  }

  if (vestingType === VestingType.AGE_BASED) {
    return `The trust will vest on the following schedule: ${getVestingSchedule(
      vestingSchedule
    )}`;
  }

  return "";
};

const getSubTrustFeaturesText = (
  beneficiaryName: string | undefined,
  subTrust: SubTrust,
  partnerType?: PartnerType | undefined
) => {
  const { trustType, restrictions, vestingType, vestingSchedule } =
    subTrust;

  const adjVestingSchedule = vestingSchedule?.filter(
    (schedule) => schedule !== null
    // eslint-disable-next-line camelcase
  ) as SubTrust_vestingSchedule[] | undefined;

  return [
    restrictions === Restrictions.LIMITED_RESTRICTIONS
      ? `This trust is a free access trust (low restrictions on distributions).`
      : `Distributions under the trust are subject to standard restrictions.`,
    getVestingTypeText(
      beneficiaryName,
      trustType,
      vestingType ?? undefined,
      adjVestingSchedule ?? undefined,
      partnerType
    )
  ];
};

const getBackupDistribText = (
  name: string | undefined,
  backups: (BackupBeneficiary | null)[] | undefined,
  backupDistribution: string
) => {
  if (
    backupDistribution === DistributionType.CO_PRIMARY_BENEFICIARIES
  ) {
    return `If ${
      name ?? "this beneficiary"
    } predeceases me, this share will be given to the other co-primary beneficiaries of this gift`;
  }

  if (backups && backupDistribution === DistributionType.CHARITY) {
    return `If ${
      name ?? "this beneficiary"
    } predeceases me, this share will be given to ${backups
      .map((backup) =>
        startCase(backup?.institution?.name?.toLowerCase())
      )
      .join(", ")}`;
  }

  if (
    backups &&
    backupDistribution === DistributionType.BACKUP_BENEFICIARIES
  ) {
    return `If ${
      name ?? "this beneficiary"
    } predeceases me, this share will be given 
      ${backups
        .map(
          (backup) =>
            `${backup?.percentageValue}% to ${
              backup?.user?.fullName ?? undefined
            }`
        )
        .join(", ")}`;
  }

  // Default : DistributionType.DESCENDANTS
  return `If ${
    name ?? "this beneficiary"
  } predeceases me, this share will be distributed to ${
    name ? `${name}'s` : "their"
  } descendants`;
};

export const getIndividualRemainderGifts = (
  beneficiaries: Beneficiary[],
  subTrusts: SubTrust[] | undefined,
  everythingToPartner = false,
  isPartner = false
): ReviewItem[] =>
  beneficiaries
    .filter((beneficiary) => beneficiary.isBackup === false)
    .map((beneficiary) => {
      const {
        id,
        user,
        institution: charity,
        percentageValue,
        distribType,
        backupBeneficiaries,
        backupDistribution
      } = beneficiary;

      const subTrust = subTrusts?.filter(
        (trust) => trust.id === id
      )[0];
      const giftDetails = [];

      giftDetails.push([
        `${percentageValue}% to ${
          user?.fullName ?? charity?.name
        }, to be distributed ${getDistribTypeText(
          user?.fullName ?? undefined,
          distribType ?? undefined
        )}.`
      ]);

      if (
        distribType &&
        distribType !== DistribType.OUTRIGHT &&
        subTrust &&
        user?.fullName
      ) {
        giftDetails.push(
          getSubTrustFeaturesText(user?.fullName, subTrust)
        );
      }
      if (user?.fullName && backupDistribution) {
        giftDetails.push([
          `${getBackupDistribText(
            user.fullName,
            backupBeneficiaries ?? undefined,
            backupDistribution
          )}.`
        ]);
      }

      return {
        title: `${
          everythingToPartner ? "Contingent " : ""
        }Remainder Gift to ${user?.fullName}:`,
        href: isPartner
          ? getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Gifts,
              TRUST_GIFTS_ROUTES.PartnerRemainderAllocate
            ])
          : getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Gifts,
              TRUST_GIFTS_ROUTES.RemainderAllocate
            ]),
        items: giftDetails
      };
    });

const getPooledTrustTerminationText = (subTrust: SubTrust) => {
  const { terminationDistribution } = subTrust;

  return `Upon termination of the Pooled Trust, any remaining trust funds will be distributed to the surviving trust beneficiaries ${
    terminationDistribution ===
    TerminationDistribution.SEPARATE_CHILDREN
      ? "in separate trusts"
      : "outright and free of trust"
  }.`;
};

export const getPooledTrustGift = (
  partnerType: PartnerType,
  beneficiaries: Beneficiary[],
  isPartner = false
): ReviewItem => {
  const beneWithSubTrust = beneficiaries.filter((beneficiary) => beneficiary.subTrust && Object.keys(beneficiary.subTrust).length > 0);
  const firstSubTrust = beneWithSubTrust[0].subTrust;

  return {
    title: `The ${firstSubTrust?.name ?? "Pooled Trust"}:`,
    href: isPartner
      ? getLinkFullUrl([
          ROUTES.Trust,
          TRUST_SECTION_ROUTES.Gifts,
          TRUST_GIFTS_ROUTES.PartnerRemainderPooledOverview
        ])
      : getLinkFullUrl([
          ROUTES.Trust,
          TRUST_SECTION_ROUTES.Gifts,
          TRUST_GIFTS_ROUTES.RemainderPooledOverview
        ]),
    items: firstSubTrust
      ? [
          getSubTrustFeaturesText(undefined, firstSubTrust, partnerType),
          [getPooledTrustTerminationText(firstSubTrust)]
        ]
      : undefined
  };
};
