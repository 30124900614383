export enum LegalFocus {
  ESTATE_ADMINISTRATION = "ESTATE_ADMINISTRATION",
  BENEFICIARY_RIGHTS = "BENEFICIARY_RIGHTS",
  ESTATE_CHALLENGE = "ESTATE_CHALLENGE",
  OTHER = "OTHER"
}

export enum DocumentType {
  LAST_WILL = "LAST_WILL",
  REVOCABLE_TRUST = "REVOCABLE_TRUST",
  IRREVOCABLE_TRUST = "IRREVOCABLE_TRUST",
  INTESTATE = "INTESTATE",
  UNKNOWN = "UNKNOWN"
}
