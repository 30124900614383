import { makeMutationHook } from "@utils/apollo-utils";

import {
  CREATE_ASSET,
  DELETE_ASSET,
  UPDATE_ASSET
} from "./mutations";
import {
  CreateAsset,
  CreateAssetVariables
} from "./__generated__/CreateAsset";
import {
  DeleteAsset,
  DeleteAssetVariables
} from "./__generated__/DeleteAsset";
import {
  UpdateAsset,
  UpdateAssetVariables
} from "./__generated__/UpdateAsset";

export const useCreateAssetMutation = makeMutationHook<
  CreateAsset,
  CreateAssetVariables
>(CREATE_ASSET);

export const useUpdateAssetMutation = makeMutationHook<
  UpdateAsset,
  UpdateAssetVariables
>(UPDATE_ASSET);

export const useDeleteAssetMutation = makeMutationHook<
  DeleteAsset,
  DeleteAssetVariables
>(DELETE_ASSET);
