import { useCallback, useMemo } from "react";
import { get, lowerCase } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import {
  PartnerType,
  PlanOption
} from "@api/__generated__/globalTypes";
import { Address_state as State } from "@api/plans/__generated__/Address";
import isCommunityPropertyState from "@utils/isCommunityPropertyState";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { RelationshipStatus } from "@setup/enums";
import { LastWillMember } from "@pages/LastWill/types";

const PlanSelectionStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);

  const lowerCasePartnerType = lowerCase(partnerType);

  const { value: primaryDrafter } =
    useFormField<LastWillMember>("users.0");
  const { value: homeState } = useFormField<State>(
    "users.0.address.state"
  );

  const { setValue: setJointTrust } = useFormField(
    "users.0.trust.isJointTrust"
  );

  const isCommunityProperty = isCommunityPropertyState(homeState);
  const meetsMaritalRequirement =
    primaryDrafter.relationshipStatus ===
      RelationshipStatus.MARRIED ||
    primaryDrafter.relationshipStatus ===
      RelationshipStatus.REGISTERED_DOMESTIC_PARTNERSHIP;

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const planOption = get(data, "planOption");

      if (
        isCommunityProperty &&
        planOption === PlanOption.COUPLE &&
        meetsMaritalRequirement
      ) {
        setJointTrust(true);
      } else {
        setJointTrust(false);
      }
    },
    [setJointTrust, meetsMaritalRequirement, isCommunityProperty]
  );

  const planOptions = useMemo(
    () => [
      {
        label: "Both of Us (Couple's Plan - $398)",
        value: PlanOption.COUPLE
      },
      {
        label: "Just Me (Individual Trust - $348)",
        value: PlanOption.SINGLE
      }
    ],
    []
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to make a plan for you and your ${lowerCasePartnerType}, or just for you?`
      }}
      name="planOption"
      items={planOptions}
      {...{ onSubmit }}
    />
  );
};

export default PlanSelectionStep;
