import * as yup from "yup";
import { useCallback, useMemo } from "react";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  AgentCompensationAmountArchetypeValue,
  UseAgentCompensationAmountArchetypeReturnTypes
} from "./types";

const useAgentCompensationAmountArchetype = (
  name: string,
  onFormSubmit?: (data: AgentCompensationAmountArchetypeValue) => void
): UseAgentCompensationAmountArchetypeReturnTypes => {
  const { goBack, saveAndContinue } = usePlanForm();

  const schema: yup.SchemaOf<AgentCompensationAmountArchetypeValue> =
    useMemo(
      () =>
        makeYupSchema(
          yup.object().shape({
            compensationLimit: yup.string().required(),
            compensationType: yup.string().required()
          }),
          name
        ),
      [name]
    );

  const controls =
    useFormWithYup<AgentCompensationAmountArchetypeValue>(schema);

  const onSubmit = useCallback(
    (data: AgentCompensationAmountArchetypeValue) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useAgentCompensationAmountArchetype };
