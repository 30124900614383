import { FormattedMessage, useIntl } from "react-intl";
import { Button, FormControl, Stack, FormGroup } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useCallback } from "react";

import { usePlanForm } from "@providers/planForm/context";
import StepLayout from "@components/StepLayout";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { EXPERT_MATCHING_ROUTES } from "@pages/EstateAdminQuiz/consts";
import { ROUTES } from "@setup/consts/routes";
import { EXPERT_MATCHING_RESULTS } from "@pages/EstateAdminQuiz/results/consts";
import FormPhoneInput from "@components/FormFields/FormPhoneInput";
import FormInput from "@components/FormFields/FormInput";

import { ArchetypeProps } from "../types";

import { useUserDetails } from "./hooks";
import { UserDetailsFormValues } from "./types";

const UserDetailsArchetype: React.FC<
  ArchetypeProps<UserDetailsFormValues>
> = ({ name, stepLayout, onSubmit: onFormSubmit }) => {
  const intl = useIntl();
  const { controls, goBack } = useUserDetails(
    name
    // onFormSubmit
  );

  const { sendMarketingData } = usePlanForm();

  const onClick = useCallback(() => {
    const email = controls.getValues("user.email");
    const klaviyoListId = "RFdexc";

    sendMarketingData(email, klaviyoListId);
  }, [sendMarketingData, controls]);

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage:
          "Enter your contact information to get paired with one of our estate planning experts."
      })}
      {...stepLayout}
    >
      <FormProvider {...controls}>
        <form>
          <Stack spacing={4}>
            <FormControl
              component="fieldset"
              variant="standard"
              sx={{ display: "flex", mb: 4 }}
            >
              <FormGroup>
                <Stack spacing={4}>
                  <FormInput
                    name="user.firstName"
                    label={intl.formatMessage({
                      defaultMessage: "First Name"
                    })}
                  />
                  <FormInput
                    name="user.lastName"
                    label={intl.formatMessage({
                      defaultMessage: "Last Name"
                    })}
                  />
                  <FormInput
                    name="user.email"
                    label={intl.formatMessage({
                      defaultMessage: "Email"
                    })}
                  />
                  <FormPhoneInput
                    name="user.phone"
                    label={intl.formatMessage({
                      defaultMessage: "Phone Number"
                    })}
                  />
                </Stack>
              </FormGroup>
            </FormControl>
          </Stack>

          <Button
            variant="contained"
            color="primary"
            href={getLinkFullUrl([
              ROUTES.EstateAdminQuiz,
              EXPERT_MATCHING_ROUTES.Results,
              EXPERT_MATCHING_RESULTS.InfoSubmitted
            ])}
            onClick={onClick}
            sx={{ width: "100%" }}
          >
            <FormattedMessage defaultMessage="Submit" />
          </Button>
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default UserDetailsArchetype;
