import { useMemo } from "react";
import { useIntl } from "react-intl";
import { capitalize } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import {
  ExcludedPerson,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { numberWithCommas } from "@utils/misc";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { RelationshipStatus } from "@setup/enums";
import { PartnerType } from "@api/__generated__/globalTypes";
import { getHumanizedPersonalPropertyType } from "@pages/Trust/sections/Assets/steps/Reviews/utils";

import { LAST_WILL_GIFTS_ROUTES } from "../../consts";

import {
  getAssetBeneficiaryInfo,
  useSpecificGiftsAssets
} from "./utils";

export const usePrimaryDrafterGiftsReviewTab = ({
  values,
  relationshipStatus,
  partnerType = PartnerType.SINGLE,
  excludePersons
}: {
  values: LastWillPlanValues;
  relationshipStatus: RelationshipStatus;
  partnerType: PartnerType;
  excludePersons: ExcludedPerson[];
}): ReviewItem[] => {
  const { formatMessage } = useIntl();

  const {
    users: [primaryDrafter, partner]
  } = values;

  const lowerPartnerType = partnerType.toLowerCase();

  const {
    everythingGoToPartner = false,
    personalPropertyBalanceType
  } = primaryDrafter?.gifts || {};

  const { excludedPersons = [], includeNoContest } = primaryDrafter?.lastWill || {};

  const {
    personalProperty = [],
    realEstates = [],
    financialAccounts = [],
    businessInterests = [],
    cash = [],
    otherAssets = [],
    estateBalance = []
  } = primaryDrafter?.assets || {};

  const { specificGiftAssets } = useSpecificGiftsAssets(
    primaryDrafter.assets
  );

  const giveSpecificGifts =
    specificGiftAssets.length > 0 || cash.length > 0;

  const capitalPartnerType = capitalize(partnerType);

  const everythingGoToPartnerInfo = useMemo<ReviewItem | undefined>(
    () =>
      relationshipStatus !== RelationshipStatus.SINGLE &&
      relationshipStatus !== RelationshipStatus.DIVORCED
        ? {
            title: formatMessage(
              {
                defaultMessage: `Distributions to Your {partnerType}:`
              },
              {
                partnerType: capitalPartnerType
              }
            ),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.DistAllToPartner
            ]),
            items: [
              [
                everythingGoToPartner
                  ? formatMessage(
                      {
                        defaultMessage: `If {partner} survives you, everything will go to {partner}.`
                      },
                      {
                        partner: partner?.firstName
                      }
                    )
                  : formatMessage(
                      {
                        defaultMessage: `{partner} will be the beneficiary only for those gifts which you've designated {partner} to receive below.`
                      },
                      {
                        partner: partner?.firstName
                      }
                    )
              ]
            ]
          }
        : undefined,
    [
      everythingGoToPartner,
      formatMessage,
      partner?.firstName,
      capitalPartnerType,
      relationshipStatus
    ]
  );

  const personalPropertyBalanceInfo = useMemo<ReviewItem | undefined>(
    () =>
      personalPropertyBalanceType
        ? {
            title: formatMessage({
              defaultMessage: "Personal Property Balance:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.PersonalPropertyBalance
            ]),
            items: [
              [
                getHumanizedPersonalPropertyType(
                  personalPropertyBalanceType,
                  lowerPartnerType,
                  primaryDrafter.assets?.personalPropertyBalance
                )
              ].filter(Boolean)
            ]
          }
        : undefined,
    [
      formatMessage,
      personalPropertyBalanceType,
      primaryDrafter.assets?.personalPropertyBalance,
      lowerPartnerType
    ]
  );

  const personalPropertyInfo = useMemo<ReviewItem | undefined>(
    () =>
      personalProperty?.length
        ? {
            title: formatMessage({
              defaultMessage: "Personal Property:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.AddSpecificGifts
            ]),
            items: personalProperty?.map(
              ({
                name,
                subType,
                beneficiaries = [],
                backupBeneficiaries = []
              }) =>
                [
                  name ?? "",
                  subType ?? "",
                  ...getAssetBeneficiaryInfo(beneficiaries)
                ].filter(Boolean)
            )
          }
        : undefined,
    [personalProperty, formatMessage]
  );

  const realEstatesInfo = useMemo<ReviewItem | undefined>(
    () =>
      realEstates?.length
        ? {
            title: formatMessage({
              defaultMessage: "Real Estate"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.AddSpecificGifts
            ]),
            items: realEstates.map(
              ({
                address,
                beneficiaries = [],
                backupBeneficiaries = []
              }) =>
                [
                  address?.address1 ?? "",
                  ...getAssetBeneficiaryInfo(beneficiaries)
                ].filter(Boolean)
            )
          }
        : undefined,
    [realEstates, formatMessage]
  );

  const financialAccountInfo = useMemo<ReviewItem | undefined>(
    () =>
      financialAccounts?.length
        ? {
            title: formatMessage({
              defaultMessage: "Financial Account"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.AddSpecificGifts
            ]),
            items: financialAccounts.map(
              ({
                name,
                institution,
                grossValue,
                beneficiaries = [],
                backupBeneficiaries = []
              }) =>
                [
                  name ?? "",
                  institution ?? "",
                  `Approximate Value: $${numberWithCommas(
                    grossValue
                  )}` ?? "",
                  ...getAssetBeneficiaryInfo(beneficiaries)
                ].filter(Boolean)
            )
          }
        : undefined,
    [formatMessage, financialAccounts]
  );

  const businessInterestsInfo = useMemo<ReviewItem | undefined>(
    () =>
      businessInterests?.length
        ? {
            title: formatMessage({
              defaultMessage: "Business Interest"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.AddSpecificGifts
            ]),
            items: businessInterests.map(
              ({
                name,
                address,
                beneficiaries = [],
                backupBeneficiaries = []
              }) =>
                [
                  name ?? "",
                  address?.state ?? "",
                  ...getAssetBeneficiaryInfo(beneficiaries)
                ].filter(Boolean)
            )
          }
        : undefined,
    [formatMessage, businessInterests]
  );

  const otherGiftInfo = useMemo<ReviewItem | undefined>(
    () =>
      otherAssets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Other Assets"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.AddSpecificGifts
            ]),
            items: otherAssets?.map(
              ({
                name,
                subType,
                beneficiaries = [],
                backupBeneficiaries = []
              }) =>
                [
                  name ?? "",
                  subType ?? "",
                  ...getAssetBeneficiaryInfo(beneficiaries)
                ].filter(Boolean)
            )
          }
        : undefined,
    [otherAssets, formatMessage]
  );

  const cashGiftInfo = useMemo<ReviewItem | undefined>(
    () =>
      cash?.length
        ? {
            title: formatMessage({
              defaultMessage: "Cash"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.ListCash
            ]),
            items: cash.map(({ beneficiaries = [] }) =>
              [
                ...beneficiaries.map(
                  (beneficiary) =>
                    `$${numberWithCommas(
                      beneficiary.dollarValue
                    )} to ${
                      beneficiary.name ?? beneficiary?.charity?.name
                    }`
                )
              ].filter(Boolean)
            )
          }
        : undefined,
    [formatMessage, cash]
  );

  const residuaryGiftsInfo = useMemo<ReviewItem | undefined>(
    () =>
      estateBalance?.length
        ? {
            title: formatMessage({
              defaultMessage: "Estate Balance:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.ResidueOverview
            ]),
            items: [
              everythingGoToPartner
                ? [
                    formatMessage(
                      {
                        defaultMessage: `Your estate will make the following distributions if {partnerName} does not survive you:`
                      },
                      {
                        partnerName: partner?.firstName
                      }
                    )
                  ]
                : [],
              ...estateBalance.map(
                ({
                  backupDistribution,
                  beneficiaries = [],
                  backupBeneficiaries = []
                }) =>
                  [
                    ...(backupDistribution ===
                    "Designate other backup beneficiaries"
                      ? backupBeneficiaries.map(
                          (beneficiary) =>
                            `${beneficiary.percentageValue}% to ${
                              beneficiary.name ??
                              beneficiary?.charity?.name
                            }`
                        )
                      : []),
                    ...beneficiaries.map(
                      (beneficiary) =>
                        `${beneficiary.percentageValue}% to ${
                          beneficiary.name ??
                          beneficiary?.charity?.name
                        }`
                    )
                  ].filter(Boolean)
              )
            ].filter(Boolean)
          }
        : undefined,
    [
      estateBalance,
      formatMessage,
      everythingGoToPartner,
      partner?.firstName
    ]
  );

  const specificGiftsInfo = useMemo<ReviewItem | undefined>(
    () =>
      giveSpecificGifts
        ? {
            title: formatMessage({
              defaultMessage: "Specific Gifts:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.SpecificGifts
            ]),
            items: [
              ...[
                {
                  title: "",
                  href: "",
                  items: [
                    [
                      formatMessage({
                        defaultMessage:
                          "You are making the following specific gifts:"
                      })
                    ]
                  ]
                },
                personalPropertyInfo,
                realEstatesInfo,
                financialAccountInfo,
                businessInterestsInfo,
                otherGiftInfo,
                cashGiftInfo
              ].filter((item): item is ReviewItem => Boolean(item))
            ]
          }
        : {
            title: formatMessage({
              defaultMessage: "Specific Gifts:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.SpecificGifts
            ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not making any specific gifts.`
                })
              ]
            ]
          },
    [
      giveSpecificGifts,
      formatMessage,
      personalPropertyInfo,
      realEstatesInfo,
      financialAccountInfo,
      businessInterestsInfo,
      otherGiftInfo,
      cashGiftInfo
    ]
  );

  const excludedPersonsInfo = useMemo<ReviewItem | undefined>(
    () =>
      excludePersons
        ? {
            title: formatMessage({
              defaultMessage: "Individuals Excluded From Your Will:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.DesignateExcludedPersons
            ]),
            items: excludedPersons?.map((person) => [
              [person.name, person.relationship]
                ?.filter(Boolean)
                .join(",")
            ])
          }
        : {
            title: formatMessage({
              defaultMessage: "Individuals Excluded From Your Will:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.ExcludePerson
            ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not explicitly excluding individuals from inheriting under your will.`
                })
              ]
            ]
          },
    [formatMessage, excludePersons, excludedPersons]
  );

  const noContestInfo = useMemo<ReviewItem>(
    () => ({
      title: formatMessage({
        defaultMessage: "No Contest Provision:"
      }),
      href: getLinkFullUrl([
        ROUTES.Trust,
        LAST_WILL_SECTION_ROUTES.Gifts,
        LAST_WILL_GIFTS_ROUTES.IncludeNoContest
      ]),
      items: includeNoContest
        ? [
            [
              formatMessage({
                defaultMessage: `You are including a no contest provision in your last will.`
              })
            ]
          ]
        : [
            [
              formatMessage({
                defaultMessage: `You are not including a no contest provision in your last will.`
              })
            ]
          ]
    }),
    [formatMessage, includeNoContest]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        everythingGoToPartnerInfo,
        personalPropertyBalanceInfo,
        specificGiftsInfo,
        residuaryGiftsInfo,
        excludedPersonsInfo,
        noContestInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      everythingGoToPartnerInfo,
      personalPropertyBalanceInfo,
      specificGiftsInfo,
      residuaryGiftsInfo,
      excludedPersonsInfo,
      noContestInfo
    ]
  );

  return items;
};
