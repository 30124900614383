import { makeMutationHook, makeQueryHook } from "@utils/apollo-utils";

import {
  CREATE_TODO,
  DELETE_TODO,
  TOGGLE_PLAN_TODO_STATUS_MUTATION,
  UPDATE_TODO,
  UPDATE_NETWORTH
} from "./mutations";
import { GET_PLANS, PLAN_TODOS } from "./queries";
import {
  CreatePlanTodo,
  CreatePlanTodoVariables
} from "./__generated__/CreatePlanTodo";
import {
  DeletePlanTodo,
  DeletePlanTodoVariables
} from "./__generated__/DeletePlanTodo";
import { Plans } from "./__generated__/Plans";
import { PlanTodos } from "./__generated__/PlanTodos";
import {
  TogglePlanTodoStatus,
  TogglePlanTodoStatusVariables
} from "./__generated__/TogglePlanTodoStatus";
import {
  UpdatePlanTodo,
  UpdatePlanTodoVariables
} from "./__generated__/UpdatePlanTodo";
import {
  UpdateNetWorth,
  UpdateNetWorthVariables
} from "./__generated__/UpdateNetWorth";

export const useGetPlansQuery = makeQueryHook<Plans>(GET_PLANS);
export const useGetPlanTodos = makeQueryHook<PlanTodos>(PLAN_TODOS);

export const useCreatePlanTodosMutation = makeMutationHook<
  CreatePlanTodo,
  CreatePlanTodoVariables
>(CREATE_TODO);

export const useUpdatePlanTodosMutation = makeMutationHook<
  UpdatePlanTodo,
  UpdatePlanTodoVariables
>(UPDATE_TODO);

export const useTogglePlanTodoStatusMutation = makeMutationHook<
  TogglePlanTodoStatus,
  TogglePlanTodoStatusVariables
>(TOGGLE_PLAN_TODO_STATUS_MUTATION);

export const useDeletePlanTodosMutation = makeMutationHook<
  DeletePlanTodo,
  DeletePlanTodoVariables
>(DELETE_TODO);

export const useUpdateNetWorthMutation = makeMutationHook<
  UpdateNetWorth,
  UpdateNetWorthVariables
>(UPDATE_NETWORTH);
