import { useMemo } from "react";
import { CheckCircleOutline } from "@mui/icons-material";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";
import isCommunityPropertyState from "@utils/isCommunityPropertyState";
import { Address_state as State } from "@api/plans/__generated__/Address";
import { BlogLinks } from "@setup/consts/articleLinks";
import { LastWillMember } from "@pages/LastWill/types";
import { RelationshipStatus } from "@setup/enums";
import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const ConfirmTrustTypeStep: React.FC = () => {
  const { value: primaryDrafter } =
    useFormField<LastWillMember>("users.0");

  const { value: homeState } = useFormField<State>(
    "users.0.address.state"
  );
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const isCommunityProperty = isCommunityPropertyState(homeState);
  const meetsMaritalRequirement =
    primaryDrafter.relationshipStatus ===
      RelationshipStatus.MARRIED ||
    primaryDrafter.relationshipStatus ===
      RelationshipStatus.REGISTERED_DOMESTIC_PARTNERSHIP;

  const communityTrustOptions = useMemo(
    () => [
      {
        label: "Continue with Joint Trust",
        value: true
      },
      {
        label: "Create two separate trusts instead",
        value: false
      }
    ],
    []
  );

  const separateStateTrustOptions = useMemo(
    () => [
      {
        label: "Continue with two separate trusts",
        value: false
      },
      {
        label: "Create one joint trust instead",
        value: true
      }
    ],
    []
  );

  const communityPropertyContent = (
    <p>
      <strong>Community Property.</strong> Community Property refers
      to the assets that a married couple acquires together during
      their marriage.
      <br />
      <br />
      The default rule in Community Property states is that any wealth
      generated during the marriage is equally owned by both spouses.
      <br />
      <br />
      If the married couple decides to divorce or if one of the
      spouses passes away, the community property is divided evenly
      between them.
      <br />
      <br />
      <strong>Separate Property.</strong> Property owned before the
      marriage, inherited by one of the spouses, or property acquired
      during the marriage that is legally agreed to be owned by only
      one of the spouses is considered separate property. Separate
      property remains the sole property of the individual spouse and
      will be passed according to that spouse&apos;s estate plan
      distributions.
      <br />
      <br />
      <strong>
        Should You Create a Joint Trust or Two Separate Trusts?
      </strong>{" "}
      Most married couples in community property states choose to
      create one joint trust. In cases where the couple has moderate
      assets and envisions similar gift distributions, a joint trust
      offers simplicity, easier administration, and unified control of
      assets.
    </p>
  );

  const jointVsSeparateTrustContent = (
    <p>
      Choosing to create one joint or two separate trusts in a
      separate property state is a personal decision.
      <br />
      <br />
      <strong>When a Joint Trust Makes Sense.</strong> Couples who
      value simplicity, unified control over their assets, and
      envision similar gift distributions may choose to create a joint
      trust. A joint trust is simpler because funding all assets into
      one joint trust avoids the need to split up and/or equalize
      asset values between two separate trusts.
      <br />
      <br />
      <strong>When Separate Trusts Are Preferable.</strong> For
      couples with blended families, significant inheritance or
      separate property coming into marriage, or different creditor
      exposure, creating two separate trusts may offer the better
      route. Properly structured separate trusts also tend to be more
      tax-efficient for minimizing state and federal estate taxes,
      where applicable.
    </p>
  );

  if (isCommunityProperty && !meetsMaritalRequirement) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `We're going to build two separate trusts for you and ${partnerFirstName}.`,
          muiIconHeader: <CheckCircleOutline />,
          subText: `Although ${homeState} is a Community Property State, this treatment is only available to married couples or couples in a registered domestic partnership.`,
          moreInfoModalProps: {
            title: "What is Community Property?",
            togglerLabel: "Learn more",
            content: communityPropertyContent,
            actions: [
              {
                label: "Read More",
                href: BlogLinks.whichStatesAreCommunityProperty
              }
            ],
            closeLabel: "Got it"
          }
        }}
      />
    );
  }

  return isCommunityProperty ? (
    <SingleSelectArchetype
      stepLayout={{
        title: `Since ${homeState} is a Community Property State, we'll build a joint trust for you and ${partnerFirstName}.`,
        muiIconHeader: <CheckCircleOutline />,
        subText:
          "If you like, you can learn more about community property and your choice for creating a joint or two separate trusts here.",
        moreInfoModalProps: {
          title: "What is Community Property?",
          togglerLabel: "Learn more",
          content: communityPropertyContent,
          actions: [
            {
              label: "Read More",
              href: BlogLinks.whichStatesAreCommunityProperty
            }
          ],
          closeLabel: "Got it"
        }
      }}
      name="users.0.trust.isJointTrust"
      items={communityTrustOptions}
      defaultValue={true}
    />
  ) : (
    <SingleSelectArchetype
      stepLayout={{
        title: `${homeState} is a Separate Property State. We'll build two separate trusts for you and ${partnerFirstName}.`,
        muiIconHeader: <CheckCircleOutline />,
        subText:
          "If you like, you can learn more about your choice for creating a joint or two separate trusts here.",
        moreInfoModalProps: {
          title:
            "What is the Difference Between a Joint Trust and Two Separate Trusts?",
          togglerLabel: "Learn more",
          content: jointVsSeparateTrustContent,
          actions: [
            /*
            TODO: @LUKE -- create article to describe this in more d
            {
              label: "Read More",
              href: BlogLinks.
            }
              */
          ],
          closeLabel: "Got it"
        }
      }}
      name="users.0.trust.isJointTrust"
      items={separateStateTrustOptions}
      defaultValue={false}
    />
  );
};

export default ConfirmTrustTypeStep;
