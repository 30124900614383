import { useEffect, useMemo } from "react";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { useFormField } from "@components/FormFields/hooks";
import { useDisableForm } from "@providers/disableForm/context";
import { DisableFormItems } from "@providers/disableForm/type";

const PartnerRepCompensationPercentStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const { value } = useFormField<number>(
    "users.1.lastWill.representativesCompensationPercent"
  );

  const error = useMemo(() => +value > 100 || +value < 0, [value]);

  const { setDisableItems: setExceededItem } = useDisableForm();

  useEffect(() => {
    setExceededItem((prev: DisableFormItems) => ({
      ...prev,
      "users.1.lastWill.representativesCompensationPercent": error
    }));
  }, [error, setExceededItem]);

  return (
    <SingleResponseArchetype
      stepLayout={{
        title: `What percent of ${partnerFirstName}'s estate would ${partnerFirstName} like to offer their Personal Representative as compensation?`
      }}
      name="users.1.lastWill.representativesCompensationPercent"
      label="Compensation"
      fieldProps={{
        type: "number",
        defaultValue: "number",
        InputProps: {
          endAdornment: `%`,
          type: "number"
        },
        error,
        helperText: error ? "Value should be between 0 and 100" : ""
      }}
    />
  );
};

export default PartnerRepCompensationPercentStep;
