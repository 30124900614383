import React from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";

import * as Styled from "./styles";
import { RoadmapItemProps } from "./types";

const RoadmapItem: React.FC<RoadmapItemProps> = ({
  label,
  state,
  icon,
  isOptional,
  ...rest
}) => {
  const statusText = (text: JSX.Element): JSX.Element => (
    <Typography
      variant="caption"
      fontSize={{ md: "1rem" }}
      ml={2}
      component="span"
      fontStyle="italic"
      textAlign="right"
      whiteSpace="nowrap"
    >
      {text}
    </Typography>
  );
  const statusIndicator =
    (state === "current" &&
      statusText(<FormattedMessage defaultMessage="Up Next" />)) ||
    (state === "prev" && <Styled.DoneIcon />) ||
    (isOptional &&
      statusText(<FormattedMessage defaultMessage="optional" />)) ||
    null;

  return (
    <Styled.Link {...{ state, ...rest }}>
      <Styled.Content component="span">
        {icon}
        {label}
      </Styled.Content>
      {statusIndicator}
    </Styled.Link>
  );
};

export default RoadmapItem;
