const chartColors = [
  "#3695DD", // 0. Darkened Havelock Blue
  "#2C165A", // 1. Lucky Point Blue
  "#0DC0B4", // 2. Robbins Egg
  "#F6BD22", // 3. Saffron
  "#45166A", // 4. Purple Mattress
  "#F51720", // 5. Chili Pepper
  "#57A773", // 6. Zomp Green
  "#5A4FCF", // 7. Royal Purple
  "#F29727" // 8. Sunshade Orange
];

// #45166A Unknown Gray --> use this for any unknown categories

export default chartColors;
