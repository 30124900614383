import { useCallback } from "react";

import useToggle from "@hooks/useToggle";
import { useFormField } from "@components/FormFields/hooks";
import { Asset } from "@pages/LastWill/types";

import { AssetFormValues } from "../AssetForm/types";

import getAssetParentPath from "./utils/getAssetParentPath";

export const useEditAssetModal = (
  asset: Asset,
  userIndex: string
) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const parentPath = `${userIndex}.assets.${getAssetParentPath(
    asset.type
  )}`;

  const {
    value: parentAssetTypeArray,
    setValue: setParentAssetTypeArray
  } = useFormField<Asset[]>(parentPath);

  // Update the asset with the new values...
  const onSubmit = useCallback(
    (input: AssetFormValues) => {
      const { id } = input;

      const newArray = [...parentAssetTypeArray];

      const indexTarget = newArray.findIndex(
        (item) => item.id === id
      );

      if (indexTarget >= 0 && indexTarget < newArray.length) {
        newArray[indexTarget] = input;

        setParentAssetTypeArray(newArray);
        toggleOff();
      }
    },
    [toggleOff, setParentAssetTypeArray, parentAssetTypeArray]
  );

  // Delete the asset...
  const onDeleteAsset = useCallback(() => {
    const indexTarget = parentAssetTypeArray.findIndex(
      (item) => item.id === asset.id
    );

    if (
      indexTarget >= 0 &&
      indexTarget < parentAssetTypeArray.length
    ) {
      const newArray = parentAssetTypeArray.filter(
        (_, index) => index !== indexTarget
      );

      setParentAssetTypeArray(newArray);

      toggleOff();
    }
  }, [
    asset,
    toggleOff,
    setParentAssetTypeArray,
    parentAssetTypeArray
  ]);

  return {
    isOpen,
    canEdit: true,
    toggleOn,
    toggleOff,
    onSubmit,
    onDeleteAsset
  };
};
