import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominateBackupGuardianStep: React.FC = () => {
  const { setValue: setBackupGuardiansValue } =
    useFormField("backupGuardians");

  const onSubmit = useCallback(
    ({ nominateBackupGuardians }: SingleSelectFormValues) => {
      if (!parseBoolean(nominateBackupGuardians)) {
        setBackupGuardiansValue([]);
      }
    },
    [setBackupGuardiansValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: "Do you want to nominate a backup Guardian?",
        subText:
          "Your backup Guardian can serve if your primary Guardian is unavailable."
      }}
      {...{ onSubmit }}
      name="nominateBackupGuardians"
    />
  );
};

export default NominateBackupGuardianStep;
