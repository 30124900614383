import UserDetailsArchetype from "@components/archetypes/UserDetails";

const BasicsStep: React.FC = () => (
  <UserDetailsArchetype
    name="users.0"
    stepLayout={{
      title: "Let's start with the basics.",
      subText: "Please fill out the information below."
    }}
  />
);

export default BasicsStep;
