import { useMemo } from "react";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";
import { PlanType } from "@api/__generated__/globalTypes";
import getPlanHumanizedTitle from "@hooks/getPlanHumanizedTitle";

const usePlanTypeOptions = () =>
  useMemo<AutocompleteBaseOption[]>(
    () => [
      {
        key: PlanType.LAST_WILL,
        value: PlanType.LAST_WILL,
        label: getPlanHumanizedTitle(PlanType.LAST_WILL)
      },
      {
        key: PlanType.TRUST,
        value: PlanType.TRUST,
        label: getPlanHumanizedTitle(PlanType.TRUST)
      },
      {
        key: "NO_RECOMMENDATION",
        value: "",
        label: "No Recommendation"
      }
    ],
    []
  );

export default usePlanTypeOptions;
