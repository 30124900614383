import { ParentValues } from "@hooks/useTrustEnums";
import { LastWillMember } from "@pages/LastWill/types";

const getParentNames = (
  parentNames: ParentValues | string,
  primaryDrafter: LastWillMember,
  partner?: LastWillMember
) => {
  if (parentNames === ParentValues.PrimaryAndPartner) {
    return `Child of ${primaryDrafter.firstName} and ${partner?.firstName}`;
  }
  if (parentNames === ParentValues.PartnerOnly) {
    return `Child of ${partner?.firstName} and Someone Else`;
  }

  return `Child of ${primaryDrafter.firstName} and Someone Else`;
};

export default getParentNames;
