import { useMemo } from "react";
import { get } from "lodash";

import { AssetType } from "@api/__generated__/globalTypes";
import { numberWithCommas } from "@utils/misc";
import { usePersonalPropertyTypeIcons } from "@pages/EstatePlanView/Partials/hooks";
import { ASSET_TYPE_ICONS } from "@setup/consts/assetTypeIcons";
import { LastWill_assets as Asset } from "@api/plans/__generated__/LastWill";
import { useAssetHumanizedTitle } from "@hooks/useAssetHumanizedTitle";

import { BeneCardData, RawBene } from "./types";

const useBeneficiaries = (
  specificGifts: (Asset | null)[] | undefined,
  estateBalance: (Asset | null)[] | undefined
) => {
  const personalPropertyIcons = usePersonalPropertyTypeIcons();
  const humanizedTitles = useAssetHumanizedTitle();
  const assetTypeIcons = ASSET_TYPE_ICONS;

  const beneficiaries = useMemo(() => {
    const rawBenes = specificGifts
      ?.flatMap((asset) =>
        asset?.beneficiaries
          ?.filter((bene) => bene?.isBackup === false)
          .map((bene) => {
            let giftValue = "not specified";

            if (bene?.dollarValue) {
              giftValue = `$${numberWithCommas(bene.dollarValue)}`;
            }

            if (bene?.percentageValue && asset.grossValue) {
              giftValue = `$${numberWithCommas(
                (bene.percentageValue / 100) * asset.grossValue
              )}`;
            }

            const isPersonalProperty =
              asset.type === AssetType.PERSONAL_PROPERTY;

            const icon = isPersonalProperty
              ? get(
                  personalPropertyIcons,
                  asset.subtype || "",
                  undefined
                )
              : get(assetTypeIcons, asset.type || "", undefined);

            return {
              beneName: bene?.user
                ? bene.user.fullName
                : bene?.subTrust?.name,
              benePercentageValue: bene?.percentageValue,
              beneGiftValue: giftValue,
              assetName:
                asset.name ||
                get(humanizedTitles, asset.type || "", ""),
              assetIcon: icon
            };
          })
      )
      .filter((bene) => bene !== undefined) as RawBene[];

    const rawEstateBenes = estateBalance
      ?.flatMap((asset) =>
        asset?.beneficiaries
          ?.filter((bene) => bene?.isBackup === false)
          .map((bene) => {
            const icon = get(
              assetTypeIcons,
              asset.type || "",
              undefined
            );

            return {
              beneName: bene?.subTrust
                ? bene.subTrust.name
                : bene?.user?.fullName,
              benePercentageValue: bene?.percentageValue,
              beneGiftValue: "n/a",
              assetName: "Estate Balance",
              assetIcon: icon
            };
          })
      )
      .filter((bene) => bene !== undefined) as RawBene[];

    rawBenes.push(...rawEstateBenes);

    const consolidatedBenes: BeneCardData[] = [];

    // Iterate through each 'rawBene' and consolidate the data
    rawBenes.forEach((rawBene) => {
      const { beneName } = rawBene;

      // If beneficiary name doesn't exist in 'consolidatedBenes', create it
      const existingIndex = consolidatedBenes.findIndex(
        (entry) => entry.beneName === beneName
      );

      if (existingIndex !== -1) {
        consolidatedBenes[existingIndex].gifts.push({
          benePercentageValue: rawBene.benePercentageValue,
          beneGiftValue: rawBene.beneGiftValue,
          assetName: rawBene.assetName,
          assetIcon: rawBene.assetIcon
        });
      } else {
        consolidatedBenes.push({
          // eslint-disable-next-line object-shorthand
          beneName: beneName,
          gifts: [
            {
              benePercentageValue: rawBene.benePercentageValue,
              beneGiftValue: rawBene.beneGiftValue,
              assetName: rawBene.assetName,
              assetIcon: rawBene.assetIcon
            }
          ]
        });
      }
    });

    // Convert the 'consolidatedBenes' object into an array
    const groupedBenes: BeneCardData[] =
      Object.values(consolidatedBenes);

    return groupedBenes;
  }, [
    specificGifts,
    estateBalance,
    assetTypeIcons,
    humanizedTitles,
    personalPropertyIcons
  ]);

  return beneficiaries;
};

export default useBeneficiaries;
