import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerCreateAHCDStep: React.FC = () => {
  const { value: includeAdvanceHealthCareDirective } = useFormField(
    "includeAdvanceHealthCareDirective"
  );
  const { setValue: setAHCDValue } = useFormField(
    "users.1.healthcare"
  );

  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const onSubmit = useCallback(
    ({
      includePartnerAdvanceHealthCareDirective
    }: SingleSelectFormValues) => {
      if (!parseBoolean(includePartnerAdvanceHealthCareDirective)) {
        setAHCDValue({});
      }
    },
    [setAHCDValue]
  );

  return includeAdvanceHealthCareDirective ? (
    <SingleSelectArchetype
      stepLayout={{
        title: `And how about ${partnerFirstName}? Does ${partnerFirstName} want to set up an Advance Healthcare Directive too?`
      }}
      {...{ onSubmit }}
      name="includePartnerAdvanceHealthCareDirective"
    />
  ) : (
    <SingleSelectArchetype
      stepLayout={{
        title: `Ok. Does ${partnerFirstName} want to set up an Advance Healthcare Directive?`
      }}
      {...{ onSubmit }}
      name="includePartnerAdvanceHealthCareDirective"
    />
  );
};

export default PartnerCreateAHCDStep;
