import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";

import { Beneficiary } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";

import { AllocationMessageProps } from "./types";

const AllocationMessage: React.FC<AllocationMessageProps> = ({
  name
}) => {
  const { value } = useFormField<Beneficiary[]>(name);

  const { allocated, left, exceeded, success } = useAllocationSum(
    value,
    { path: "percentageValue" }
  );

  if (exceeded) {
    return (
      <Typography
        variant="body2"
        color={success ? "text.primary" : "error.light"}
        textAlign="center"
      >
        <FormattedMessage defaultMessage="You cannot allocate more than 100%." />
      </Typography>
    );
  }

  return (
    <Typography
      variant="body2"
      color={success ? "text.primary" : "error.light"}
      textAlign="center"
    >
      <FormattedMessage
        defaultMessage="{allocated}% allocated."
        values={{ allocated }}
      />
      <br />
      {success ? (
        <FormattedMessage defaultMessage="Looks great!" />
      ) : (
        <FormattedMessage
          defaultMessage="You have {left}% left to allocate."
          values={{ left }}
        />
      )}
    </Typography>
  );
};

export default AllocationMessage;
