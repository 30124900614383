import { useMemo } from "react";
import { useIntl } from "react-intl";

import { DistributionType } from "@api/__generated__/globalTypes";
import { RadioItem } from "@components/FormFields/FormRadioButtons/types";

export const useSharesDistribution = (
  name?: string,
  numBeneficiaries?: number | undefined
): RadioItem[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage(
          { defaultMessage: "Give to {name}’s descendants" },
          { name }
        ),
        value: DistributionType.DESCENDANTS
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Give to my other co-primary beneficiaries"
        }),
        value: DistributionType.CO_PRIMARY_BENEFICIARIES,
        hidden: numBeneficiaries && numBeneficiaries <= 1
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Designate other backup beneficiaries"
        }),
        value: DistributionType.BACKUP_BENEFICIARIES
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Designate a charity"
        }),
        value: DistributionType.CHARITY
      }
    ],
    [intl, name, numBeneficiaries]
  );

  if (!name) {
    return [];
  }

  return items.filter((item) => !item.hidden);
};
