import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { User } from "@api/me/__generated__/User";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerTrustNameStep: React.FC = () => {
  const { value: user } = useFormField<User>("users.1");
  const { value: trustName, setValue: setTrustName } = useFormField(
    "users.1.trust.name"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const useCustomName = get(data, "users.1.trust.useCustomName");

      if (!parseBoolean(useCustomName)) {
        setTrustName(
          `${user.firstName} ${user.lastName} Revocable Living Trust`
        );
      }
    },
    [setTrustName, user.firstName, user.lastName]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `What should we call ${user.firstName}'s trust?`,
        subText: "Learn more about naming your trust.",
        moreInfoModalProps: {
          title: "Naming Trusts",
          togglerLabel: "Learn more.",
          content: (
            <p>
              You can choose to follow the standard trust naming
              convention or pick a different name for your trust. The
              name is merely for identification and administrative
              purposes and does not affect the trust&apos;s function.
              <br />
              <br />
              <strong>Standard Naming Convention.</strong> The most
              common convention for naming trusts is to use your full
              name or last name followed by &apos;Revocable Living
              Trust&apos;. For example, &quot;The Jane Doe Revocable
              Living Trust&quot; or &quot;The Doe Family Revocable
              Living Trust.&quot;
              <br />
              <br />
              <strong>Custom Trust Names.</strong> Some trustmakers
              prefer to use more generic or less identifiable names
              for their trust, particularly in instances in which
              privacy is a concern. Instead of &quot;The Jane Doe
              Revocable Living Trust,&quot; you might name your trust
              &quot;The JED Peach Tree Trust&quot; or any number of
              different names. Keep in mind that it&apos;s important
              to avoid names that could potentially be misleading or
              imply an affiliation with a public or government entity.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.1.trust.useCustomName"
      {...{ onSubmit }}
      items={[
        {
          label: `"The ${user.firstName} ${user.lastName} Revocable Living Trust" (most common)`,
          value: false
        },
        {
          label: `I want to customize the name of my trust`,
          value: true
        }
      ]}
    />
  );
};

export default PartnerTrustNameStep;
