import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const TrusteeOverviewStep: React.FC = () => {
  const { value: trustName } = useFormField("users.0.trust.name");
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );
  const { value: isJointTrust } = useFormField(
    "users.0.trust.isJointTrust"
  );

  const titleMessage = isJointTrust
    ? `We're going to make you and ${partnerFirstName} Trustees of your ${trustName}.`
    : `We're going to make you Trustee of your ${trustName}.`;

  const descriptionMessage = isJointTrust
    ? "As Trustees, you'll have the authority to manage the trust and its assets. Making any changes to the trust will require your joint approval."
    : "As Trustee, you'll have the authority to manage the trust and its assets.";

  return (
    <InterstitialArchetype
      stepLayout={{
        title: titleMessage,
        subText: descriptionMessage,
        moreInfoModalProps: {
          title: "What Does a Trustee Do?",
          togglerLabel: "Learn more.",
          content: (
            <p>
              A Trustee is an individual or entity responsible for
              managing the trust and its affairs.
              <br />
              <br />
              During your (the Trustmaker&apos;s) life, there are two
              primary duties of the Trustee:
              <ol>
                <li>
                  <strong>Manage trust assets. </strong> This includes
                  &quot;funding&quot; the trust by transferring assets
                  into the trust, making investment decisions, and
                  ensuring any real or tangible property assets are
                  maintained
                </li>
                <li>
                  <strong>Keep accurate records. </strong> You&apos;ll
                  need to keep accurate and detailed records of trust
                  transactions, including income received, expenses
                  paid, and any changes in the trust&apos;s assets.
                </li>
              </ol>
              The Trustee is responsible for acting in the
              Trustmaker&apos;s best interest, supporting the
              Trustmaker&apos;s needs, and making decisions that are
              prudent, reasonable, and in line with the
              Trustmaker&apos;s intentions.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
    />
  );
};

export default TrusteeOverviewStep;
