import { useIntl } from "react-intl";
import { useCallback } from "react";
import { get } from "lodash";

import TrustSetupArchetype from "@components/archetypes/TrustSetupArchetype";
import { useFormField } from "@components/FormFields/hooks";
import { Beneficiary } from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import { VestingType } from "@api/__generated__/globalTypes";
import { TrustSetupArchetypeValue } from "@components/archetypes/TrustSetupArchetype/types";

const PartnerRemainderSeparateTrustSetupStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: beneficiary } = useFormField<Beneficiary>(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}`
  );
  const { setValue: setVestingSchedule } = useFormField(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingSchedule`
  );

  const onSubmit = useCallback(
    (data: TrustSetupArchetypeValue) => {
      const vestingType = get(
        data,
        `users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust.vestingType`
      );

      if (vestingType === VestingType.ON_DEATH) {
        setVestingSchedule([]);
      }
    },
    [setVestingSchedule, iteration]
  );

  return (
    <TrustSetupArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "Please designate how you would like to set up {name}'s Trust."
          },
          {
            name: beneficiary.name
          }
        )
      }}
      name={`users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust`}
      {...{ onSubmit }}
    />
  );
};

export default PartnerRemainderSeparateTrustSetupStep;
