import { useLayoutEffect, useRef, useState } from "react";

import { UseModalTogglerLabelSizeReturnType } from "./types";

export const useModalTogglerLabelSize =
  (): UseModalTogglerLabelSizeReturnType => {
    const togglerRef = useRef<HTMLDivElement | null>(null);
    const [labelHeight, setLabelHeight] = useState<
      string | undefined
    >(undefined);

    useLayoutEffect(() => {
      if (togglerRef && togglerRef.current) {
        const labelWrapper = togglerRef.current.querySelector(
          ".MuiTypography-root"
        );

        if (!labelWrapper) {
          return;
        }

        const { fontSize } = window.getComputedStyle(labelWrapper);

        setLabelHeight(fontSize);
      }
    }, []);

    return {
      labelHeight,
      togglerRef
    };
  };
