import { useMemo } from "react";
import {
  Box,
  Card,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import {
  Lock as LockIcon,
  InfoOutlined as InfoOutlinedIcon,
  South as SouthIcon
} from "@mui/icons-material";

import DynamicPieChart from "@pages/EstatePlanView/Partials/components/PieChart";
import getChartColors from "@pages/EstatePlanView/Partials/components/PieChart/hooks";
import AddAssetsModal from "@pages/EstatePlanView/Partials/components/AddAssetModal";

import AssetCardLayout from "./AssetCardLayout";
import AssetTable from "./AssetTable";
import useNetWorth from "./hooks";
import NetWorthInput from "./NetWorthInput";
import { NetWorthTabProps } from "./types";

const NetWorthTab = ({
  userLastWill,
  isTrustPlan,
  canEdit = false
}: NetWorthTabProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { pfNetWorth, assetCategories, assetDetails } = useNetWorth(
    userLastWill,
    isTrustPlan
  );

  const chartData = useMemo(
    () => getChartColors(assetCategories),
    [assetCategories]
  );

  const showPieChart = assetCategories.length > 0;

  return (
    <Box>
      <Box
        mb="1.25rem"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.625rem"
        }}
      >
        <InfoOutlinedIcon />
        <Typography>
          A visual representation of your net worth by asset class.
          This chart will automatically update if you submit new net
          worth info or update your asset information below.
        </Typography>
      </Box>
      {!showPieChart && (
        <Card>
          <Box
            sx={{
              padding: "2.25rem",
              display: "flex",
              flexDirection: "column",
              gap: "1.25rem",
              alignItems: "center"
            }}
          >
            <LockIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                mx: "auto",
                fontSize: "2.875rem"
              }}
            />
            <Typography fontWeight="bold" fontSize="1.375rem">
              Net worth visualization unavailable
            </Typography>
            <Typography>
              Unlock your net worth visualization by submitting
              estimated net worth data or at least one estimated asset
              value.
            </Typography>
            <SouthIcon />
          </Box>
        </Card>
      )}
      {showPieChart && <DynamicPieChart data={chartData} />}
      <Box my="2.5rem">
        <Typography variant="h3" mb="1.25rem">
          Net Worth Details
        </Typography>
        <NetWorthInput
          currentNetWorth={pfNetWorth}
          // eslint-disable-next-line react/destructuring-assignment
          lastWillId={userLastWill?.id}
          canEdit={canEdit}
        />
        {assetDetails.length > 0 && (
          <Box mt="2.25rem">
            {!isDesktop && <AssetCardLayout assets={assetDetails} />}
            {isDesktop && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%"
                }}
              >
                <AssetTable assets={assetDetails} />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {userLastWill && (
        <AddAssetsModal lastWill={userLastWill} showIsFunded />
      )}
    </Box>
  );
};

export default NetWorthTab;
