import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";
import {
  LastWillPlanValues,
  VestingSchedule
} from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";

import VestingScheduleForm from "./RemainderAllocate/AgeVestingSchedule/VestingScheduleForm";
import useAgeVestingScheduleSchema from "./RemainderAllocate/AgeVestingSchedule/VestingScheduleForm/hooks";
import FormHeader from "./RemainderAllocate/AgeVestingSchedule/FormHeader";
// Memo: This Allocation Message is a shared resource across multiple array designations
import AllocationMessage from "./RemainderAllocate/partials/AllocationMessage";

const PartnerSeparateTrustAgeReqsFinalDistroStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { children } = values;
  const { formatMessage } = useIntl();
  const singleItemSchema = useAgeVestingScheduleSchema();
  const { value } = useFormField<VestingSchedule[]>(
    "users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust.vestingSchedule"
  );

  const { success } = useAllocationSum(value, {
    path: "percentageValue"
  });

  return (
    <ArrayFormArchetype
      stepLayout={{
        title:
          children?.length === 1
            ? `Please note the percentage distributions and age for which the Trustee should make distributions on ${children[0].name}'s trust.`
            : `Please note the percentage distributions and age for which the Trustee should make distributions on the Separate Children's Trust.`
      }}
      addBtnLabel={formatMessage({
        defaultMessage: "Add Another Age Threshold"
      })}
      {...{ singleItemSchema }}
      customRemoveBtn
      SingleItem={VestingScheduleForm}
      name="users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust.vestingSchedule"
      topContent={<FormHeader />}
      bottomContent={
        <AllocationMessage name="users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust.vestingSchedule" />
      }
      disabled={!success}
    />
  );
};

export default PartnerSeparateTrustAgeReqsFinalDistroStep;
