import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSelectOption } from "@components/archetypes/MultiSelect/partials/MultiSelectForm/types";
import MultiSelectArchetype from "@components/archetypes/MultiSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerCeremonyStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { formatMessage } = useIntl();

  const options: MultiSelectOption[] = useMemo(
    () => [
      {
        value: "Memorial service",
        label: formatMessage({
          defaultMessage: "Memorial service"
        })
      },
      {
        value: "Cemetery service",
        label: formatMessage({
          defaultMessage: "Cemetery service"
        })
      },
      {
        value: "Funeral service",
        label: formatMessage({
          defaultMessage: "Funeral service"
        })
      }
    ],
    [formatMessage]
  );

  return (
    <MultiSelectArchetype
      {...{ options }}
      name="users.1.finalArrangements.ceremonyType"
      stepLayout={{
        title: `What type of ceremony would ${partnerFirstName} like?`,
        subText: `Select all ceremonies that ${partnerFirstName} would like. If ${partnerFirstName} doesn't want any of these ceremonies, leave unselected and click 'Save & Continue.'`
      }}
    />
  );
};

export default PartnerCeremonyStep;
