import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  DateSelectFormValues,
  DateSelectArchetypeReturnType
} from "./types";

const useDateSelectSchema = (name: string): yup.AnyObjectSchema => {
  const intl = useIntl();

  return useMemo(
    () =>
      makeYupSchema(
        yup
          .date()
          .transform((_, originalValue) =>
            originalValue ? new Date(originalValue) : undefined
          )
          .min(
            new Date("1900-01-01"),
            "Date must be later than 01/01/1900."
          )
          .typeError(
            intl.formatMessage({
              defaultMessage:
                "Please enter the date in MM/DD/YYYY format."
            })
          )
          .required()
          .nullable()
          .test(
            "date",
            intl.formatMessage({
              defaultMessage:
                "Date of existing trust must be earlier than today's date."
            }),
            (value) => (value ? new Date() > new Date(value) : false)
          ),
        name
      ),
    [name]
  );
};

const useDateSelectArchetype = (
  name: string,
  onFormSubmit?: (data: DateSelectFormValues) => void
): DateSelectArchetypeReturnType => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useDateSelectSchema(name);
  const controls = useFormWithYup<DateSelectFormValues>(schema);

  const onSubmit = useCallback(
    (data: DateSelectFormValues) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useDateSelectArchetype };
