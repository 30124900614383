import { useIntl } from "react-intl";
import { FormControl, FormLabel } from "@mui/material";
import { FormProvider } from "react-hook-form";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";

import { useDateSelectArchetype } from "./hooks";
import { DateSelectArchetypeProps } from "./types";
import FormMobileDatePicker from "@components/FormFields/FormMobileDatePicker";
import theme from "@setup/theme";

const DateSelectArchetype: React.FC<DateSelectArchetypeProps> = ({
  name,
  stepLayout,
  onSubmit: onFormSubmit,
}) => {
  const intl = useIntl();
  
  const { controls, goBack, onSubmit } = useDateSelectArchetype(
    name,
    onFormSubmit
  );

  return (
    <StepLayout
      title={intl.formatMessage(
        {
          defaultMessage: "What's the relevant date"
        },
      )}
      {...stepLayout}
    >
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <FormControl>
          <FormMobileDatePicker
              minDate={new Date("1900-01-01")}
              name={name}
              label={intl.formatMessage({
                defaultMessage: "Date"
              })}
              type="dateOnly"
              DialogProps={{
                sx: {
                  "& .MuiButton-root": {
                    minWidth: "auto",
                    [theme.breakpoints.up("md")]: {
                      minWidth: "13.438rem"
                    }
                  }
                }
              }}
            />
          </FormControl>
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default DateSelectArchetype;
