import { useCallback } from "react";
import { useIntl } from "react-intl";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import { useSharesDistribution } from "@hooks/useSharesDistribution";
import { DistributionType } from "@api/__generated__/globalTypes";
import { Beneficiary } from "@pages/LastWill/types";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";

const PartnerBeneficiaryDistribution: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: beneficiary } = useFormField<Beneficiary>(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}`
  );

  const { value: beneficiaries } = useFormField<Beneficiary[]>(
    `users.1.assets.estateBalance.0.beneficiaries`
  );

  const items = useSharesDistribution(
    beneficiary.name,
    beneficiaries.length
  );

  const { setValue: setBackupBeneficiaries } = useFormField(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const backupDistribution = get(
        data,
        `users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution`
      );

      if (
        backupDistribution === DistributionType.DESCENDANTS ||
        backupDistribution ===
          DistributionType.CO_PRIMARY_BENEFICIARIES
      ) {
        setBackupBeneficiaries([]);
      }
    },
    [setBackupBeneficiaries, iteration]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "If {name} fails to survive you, how do you want to distribute that {percentageValue}% share?"
          },
          {
            name: beneficiary.name,
            percentageValue: beneficiary.percentageValue
          }
        ),
        subText:
          "Learn more about these backup distribution options.",
        moreInfoModalProps: {
          title: "Backup Distribution Options",
          togglerLabel: "Learn more.",
          content: (
            <p>
              <strong>Gifting to Descendants.</strong>{" "}
              &quot;Desendants&apos; are the beneficiary&apos;s
              children and those children&apos;s descendants.
              <br />
              <br />
              <strong>
                Gifting to Your Other Co-Primary Beneficiaries.
              </strong>{" "}
              The share that would have gone to this person will
              instead be distributed to the other beneficiaries of
              this same gift.
              <br />
              <br />
              <strong>
                Designating Other Backup Beneficiaries.
              </strong>{" "}
              You can designate as backups any other individuals or
              groups that you wish.
              <br />
              <br />
              <strong>Designating a Charity.</strong> You can choose
              as backup a charitable organization to support.
            </p>
          )
        }
      }}
      name={`users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution`}
      {...{ items }}
      defaultValue={DistributionType.DESCENDANTS}
      onSubmit={onSubmit}
    />
  );
};

export default PartnerBeneficiaryDistribution;
