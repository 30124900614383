import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerArtificialNutritionStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <SingleSelectArchetype
      nullable
      name="users.1.healthcare.preferences.artificialNutrition"
      stepLayout={{
        title: `If ${partnerFirstName} is receiving artificial nutrition and hydration, does ${partnerFirstName} want to continue to receive artificial nutrition and hydration in all cases?`,
        subText: `If you select "No", ${partnerFirstName}'s agent and healthcare providers will decide whether to continue this care based on ${partnerFirstName}'s choice to prolong or not prolong life in the preceding question.`
      }}
      items={[
        {
          label: "Yes",
          value: true
        },
        {
          label: "No",
          value: false
        },
        {
          label: "Skip this question",
          value: null
        }
      ]}
    />
  );
};

export default PartnerArtificialNutritionStep;
