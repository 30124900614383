import { DocumentType } from "@pages/EstateAdminQuiz/enums";
import { EstateAdminResults } from "@pages/EstateAdminQuiz/types";

import getStateEstateTax from "../estateTax/getStateEstateTax";
import getFedEstateTax from "../estateTax/getFedEstateTax";

import getErrorMessage from "./getErrorMessage";
import getDefaultTrustResults from "./getDefaultTrustResults";
// import getDefaultProbateResults from "./getDefaultProbateResults";

const getNewHampshireResults = (
  state: string,
  documentType: DocumentType,
  assetValue: string,
  probateAssetValue?: string
): EstateAdminResults => {
  // Memo: Small Estate Threshold is the threshold below which the estate may qualify for expedited probate
  const isSmallEstate = false;
  // Memo: isSmallEstate connects that threshold to the closest assetValue in our quiz...
  // ~intentionally left blank~
  // Memo: This is the state-specific name for expedited estate administration and full probate in this state
  const probateRec = "Probate";
  // Memo: This is the state-specific name for intestate estate administration
  const intestateAdministrationName = "intestate administration";

  if (
    documentType === DocumentType.REVOCABLE_TRUST ||
    documentType === DocumentType.IRREVOCABLE_TRUST
  )
    return getDefaultTrustResults(isSmallEstate, assetValue, state);

  if (
    documentType === DocumentType.LAST_WILL ||
    documentType === DocumentType.INTESTATE
  ) {
    const bulletRecs = [];
    const probateRationale: string[] = [];

    probateRationale.push(
      documentType === DocumentType.LAST_WILL
        ? `Since the deceased had a last will, this estate will need to go through probate in ${state}.`
        : `Since the deceased passed away without an estate plan, the estate will need to go through the ${intestateAdministrationName} process in ${state}.`
    );

    probateRationale.push(
      "New Hampshire doesn't have a small estate administration process, however, you might be able to obtain a waiver of administration if the estate has a single beneficiary, or file for an expedited summary administration."
    );

    bulletRecs.push(
      isSmallEstate ? (
        <li>
          <strong>Timing. </strong>
          {probateRec} in {state} is an expedited type of probate
          proceeding that can usually be completed within several
          months.
        </li>
      ) : (
        <li>
          <strong>Timing. </strong>
          {probateRec} in {state} can take several months to upwards
          of a year, depending on the specifics of the estate.
        </li>
      )
    );
    bulletRecs.push(
      isSmallEstate ? (
        <li>
          <strong>Cost. </strong>
          Generally, cost for {probateRec} in {state} is much less
          than a formal (e.g., full) probate proceeding. If the
          executor is serving without compensation and is comfortable
          interacting with the court, you may only need to pay small
          court fees.
        </li>
      ) : (
        <li>
          <strong>Cost. </strong>
          The cost for {probateRec} in {state} can vary widely based
          on a number of different factors. Your estate administration
          expert can help estimate the applicable cost to this estate.
        </li>
      )
    );
    bulletRecs.push(getStateEstateTax(state, assetValue));
    bulletRecs.push(getFedEstateTax(assetValue));

    return {
      recommendation: probateRec,
      rationale: probateRationale.join(" "),
      bullets: bulletRecs
    };
  }

  return getErrorMessage();
};

export default getNewHampshireResults;
