import { useIntl } from "react-intl";

import PersonDetailsArchetype from "@components/archetypes/PersonDetails";
import { usePlanForm } from "@providers/planForm/context";
import { Pet } from "@pages/PetPlan/types";
import { useFormField } from "@components/FormFields/hooks";

const DesignateGuardianStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: pet } = useFormField<Pet>(`pets.${iteration}`);

  return (
    <PersonDetailsArchetype
      name={`pets.${iteration}.guardians.0`}
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage: "Who should be {name}'s Pet Guardian?"
          },
          {
            name: pet.name
          }
        )
      }}
      personRole="Guardian"
    />
  );
};

export default DesignateGuardianStep;
