import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const DistAllToPartnerStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setPercentGaveToPartnerValue } = useFormField(
    "users.0.gifts.percentGaveToPartner"
  );

  // Note: If not giving everything to partner as primary, reset the percent that we're storing
  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const everythingToPartner = get(
        data,
        "users.0.gifts.everythingGoToPartner"
      );

      if (parseBoolean(everythingToPartner)) {
        setPercentGaveToPartnerValue(100);
      }
    },
    [setPercentGaveToPartnerValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `If ${partnerFirstName} survives you, do you want everything to go to ${partnerFirstName}?`,
        subText: `This means that if you pass away first, all your assets will be transfered to ${partnerFirstName}.`
      }}
      {...{ onSubmit }}
      name="users.0.gifts.everythingGoToPartner"
    />
  );
};

export default DistAllToPartnerStep;
