import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid
} from "@mui/material";
import {
  Controller,
  useFormContext,
  ControllerRenderProps
} from "react-hook-form";
import get from "lodash/get";
import { useCallback } from "react";

import MultiSelectCheckbox from "./partials/MultiSelectCheckbox";
import {
  FormMultiSelectCheckboxGroupProps,
  MultiSelectCheckboxGroupItem
} from "./types";

const FormMultiSelectCheckboxGroup: React.FC<
  FormMultiSelectCheckboxGroupProps
> = ({ name, items, label: fieldsetLabel, disabled }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const fieldError = get(errors, `${name}.message`);

  const handleChangeMultiSelect = useCallback(
    (
      field: ControllerRenderProps,
      { value: itemValue }: MultiSelectCheckboxGroupItem
    ) => {
      const currentValue = field.value || [];

      if (!currentValue.includes(itemValue)) {
        field.onChange([...currentValue, itemValue]);

        return;
      }

      const newValue = currentValue.filter(
        (item: MultiSelectCheckboxGroupItem["value"]) =>
          item !== itemValue
      );

      field.onChange(newValue);
    },
    []
  );

  return (
    <Controller
      {...{ control, name }}
      render={({ field }): JSX.Element => (
        <FormControl
          component="fieldset"
          variant="standard"
          error={!!fieldError}
          sx={{ display: "flex" }}
        >
          {fieldsetLabel && (
            <FormLabel
              component="legend"
              sx={{ mb: { xs: 1.75, md: 3 } }}
            >
              {fieldsetLabel}
            </FormLabel>
          )}
          <FormGroup>
            <Grid container spacing={2.5}>
              {items.map((item) => (
                <Grid item xs={6} md={4} key={item.label}>
                  <MultiSelectCheckbox
                    {...field}
                    {...{ disabled, ...item }}
                    onChange={(): void => {
                      handleChangeMultiSelect(field, item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
          {fieldError && (
            <FormHelperText>{fieldError}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormMultiSelectCheckboxGroup;
