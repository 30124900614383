import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { NEXT_ITERATION_RULE } from "@providers/planForm/consts";
import {
  PartnerType,
  PlanOption,
  DistributionType,
  PersonalPropertyBalanceDistributionType
} from "@api/__generated__/globalTypes";

import { LAST_WILL_FINAL_ARRANGEMENT_ROUTES } from "../FinalArrangements/consts";

import { LAST_WILL_GIFTS_ROUTES } from "./consts";
import ReviewTabsStepPage from "./steps/ReviewTabs";
import SectionsPreviewStep from "./steps/Preview";
import GiftProcessOverviewStep from "./steps/GiftProcessOverview";
import DistributeAllToPartnerStep from "./steps/DistAllToPartner";
import DistributeAllToPartnerConfirmedStep from "./steps/DistAllToPartnerConfirmed";
import ResidueOverviewStep from "./steps/ResidueOverview";
import SpecificGiftsStep from "./steps/SpecificGifts";
import TransitionPrimaryStartStep from "./steps/TransitionPrimaryStart";
import CashStep from "./steps/ListCash";
import ResidueAllocateStep from "./steps/ResidueAllocate";
import ResidueCompleteStep from "./steps/ResidueComplete";
import ExcludedPersonsStep from "./steps/ExcludePerson";
import BackupBeneficiariesStep from "./steps/ResidueAllocate/BackupBeneficiaries";
import CharityBackup from "./steps/ResidueAllocate/CharityBackup";
import BeneficiaryDistribution from "./steps/ResidueAllocate/BeneficiaryDistribution";
import ResidueToChildrenStep from "./steps/ResidueToChildren";
import TransitionPartnerStartStep from "./steps/TransitionPartnerStart";
import PartnerDistAllToPartnerStep from "./steps/PartnerDistAllToPartner";
import PartnerDistAllToPartnerConfirmedStep from "./steps/PartnerDistAllToPartnerConfirmed";
import PartnerSpecificGiftsStep from "./steps/PartnerSpecificGifts";
import PartnerListCashStep from "./steps/PartnerListCash";
import PartnerResidueOverviewStep from "./steps/PartnerResidueOverview";
import PartnerResidueToChildrenStep from "./steps/PartnerResidueToChildren";
import PartnerBeneficiaryDistribution from "./steps/PartnerResidueAllocate/BeneficiaryDistribution";
import PartnerResidueAllocateStep from "./steps/PartnerResidueAllocate";
import PartnerCharityBackup from "./steps/PartnerResidueAllocate/CharityBackup";
import PartnerResidueCompleteStep from "./steps/PartnerResidueComplete";
import PartnerExcludedPersonsStep from "./steps/PartnerExcludePerson";
import PartnerBackupBeneficiariesStep from "./steps/PartnerResidueAllocate/BackupBeneficiaries";
import ReviewStep from "./steps/Review";
import DesignateExcludedPersonsStep from "./steps/DesignateExcludedPersons";
import PartnerDesignateExcludedPersonsStep from "./steps/PartnerDesignateExcludedPersons";
import ResidueToPartnerStep from "./steps/ResidueToPartner";
import PartnerResidueToPartnerStep from "./steps/PartnerResidueToPartner";
import PersonalPropertyBalanceTypeStep from "./steps/PersonalPropertyBalance";
import PersonalPropertyBalancePeopleStep from "./steps/PersonalPropertyBalancePeople";
import PartnerPersonalPropertyBalanceTypeStep from "./steps/PartnerPersonalPropertyBalance";
import PartnerPersonalPropertyBalancePeopleStep from "./steps/PartnerPersonalPropertyBalancePeople";
import AddSpecificGiftsStep from "./steps/AddSpecificGifts";
import PersonalPropertyBalanceOverviewStep from "./steps/PersonalPropertyBalanceOverview";
import MakeCashGiftStep from "./steps/MakeCashGift";
import PartnerAddSpecificGiftsStep from "./steps/PartnerAddSpecificGifts";
import PartnerPersonalPropertyBalanceOverviewStep from "./steps/PartnerPersonalPropertyBalanceOverview";
import PartnerMakeCashGiftStep from "./steps/PartnerMakeCashGift";
import IncludeNoContestStep from "./steps/IncludeNoContest";
import PartnerIncludeNoContestStep from "./steps/PartnerIncludeNoContest";

const giftsSteps: PlanFormStep[] = [
  {
    path: LAST_WILL_GIFTS_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: LAST_WILL_GIFTS_ROUTES.GiftProcessOverview
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.GiftProcessOverview,
    element: <GiftProcessOverviewStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerType" }, PartnerType.SINGLE] },
        LAST_WILL_GIFTS_ROUTES.ResidueOverview,
        {
          if: [
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
            LAST_WILL_GIFTS_ROUTES.TransitionPrimaryStart,
            LAST_WILL_GIFTS_ROUTES.DistAllToPartner
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.TransitionPrimaryStart,
    element: <TransitionPrimaryStartStep />,
    rules: LAST_WILL_GIFTS_ROUTES.DistAllToPartner
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.DistAllToPartner,
    element: <DistributeAllToPartnerStep />,
    rules: LAST_WILL_GIFTS_ROUTES.DistAllToPartnerConfirmed
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.DistAllToPartnerConfirmed,
    element: <DistributeAllToPartnerConfirmedStep />,
    rules: LAST_WILL_GIFTS_ROUTES.ResidueOverview
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ResidueOverview,
    element: <ResidueOverviewStep />,
    rules: LAST_WILL_GIFTS_ROUTES.ResidueAllocate
    /*
    {
    
      if: [
        {
          "==": [
            { var: "users.0.gifts.everythingGoToPartner" },
            false
          ]
        },
        LAST_WILL_GIFTS_ROUTES.ResidueToPartner,
        {
          if: [
            { "==": [{ var: "haveChildren" }, true] },
            LAST_WILL_GIFTS_ROUTES.ResidueToChildren,
            LAST_WILL_GIFTS_ROUTES.ResidueAllocate
          ]
        }
      ]
    }
    */
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ResidueToPartner,
    element: <ResidueToPartnerStep />,
    rules: {
      if: [
        { "==": [{ var: "residueBalanceToPartner" }, true] },
        LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiariesLoop,
        {
          if: [
            { "==": [{ var: "haveChildren" }, true] },
            LAST_WILL_GIFTS_ROUTES.ResidueToChildren,
            LAST_WILL_GIFTS_ROUTES.ResidueAllocate
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ResidueToChildren,
    element: <ResidueToChildrenStep />,
    rules: {
      if: [
        { "==": [{ var: "residueBalanceToChildren" }, true] },
        LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiariesLoop,
        LAST_WILL_GIFTS_ROUTES.ResidueAllocate
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ResidueAllocate,
    element: <ResidueAllocateStep />,
    rules: LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiariesLoop
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiariesLoop,
    loop: {
      enabled: true,
      iterationCountSource:
        "users.0.assets.estateBalance.0.beneficiaries"
    },
    children: [
      {
        path: LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiaryFails,
        element: <BeneficiaryDistribution />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.CHARITY
              ]
            },
            LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiaryCharity,
            {
              "==": [
                {
                  var: "users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.BACKUP_BENEFICIARIES
              ]
            },
            LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiaryBackups,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiaryBackups,
        element: <BackupBeneficiariesStep />,
        rules: NEXT_ITERATION_RULE
      },
      {
        path: LAST_WILL_GIFTS_ROUTES.ResidueAllocateBeneficiaryCharity,
        element: <CharityBackup />,
        rules: NEXT_ITERATION_RULE
      }
    ],
    rules: LAST_WILL_GIFTS_ROUTES.ResidueComplete
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ResidueComplete,
    element: <ResidueCompleteStep />,
    rules: LAST_WILL_GIFTS_ROUTES.SpecificGifts
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.SpecificGifts,
    element: <SpecificGiftsStep />,
    rules: LAST_WILL_GIFTS_ROUTES.AddSpecificGifts
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.AddSpecificGifts,
    element: <AddSpecificGiftsStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PersonalPropertyBalanceOverview
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PersonalPropertyBalanceOverview,
    element: <PersonalPropertyBalanceOverviewStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PersonalPropertyBalance
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PersonalPropertyBalance,
    element: <PersonalPropertyBalanceTypeStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.gifts.personalPropertyBalanceType"
            },
            PersonalPropertyBalanceDistributionType.OTHER_PEOPLE
          ]
        },
        LAST_WILL_GIFTS_ROUTES.PersonalPropertyBalancePeople,
        LAST_WILL_GIFTS_ROUTES.MakeCashGift
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PersonalPropertyBalancePeople,
    element: <PersonalPropertyBalancePeopleStep />,
    rules: LAST_WILL_GIFTS_ROUTES.MakeCashGift
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.MakeCashGift,
    element: <MakeCashGiftStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.gifts.makeCashGifts" }, true] },
        LAST_WILL_GIFTS_ROUTES.ListCash,
        LAST_WILL_GIFTS_ROUTES.ExcludePerson
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ListCash,
    element: <CashStep />,
    rules: LAST_WILL_GIFTS_ROUTES.ExcludePerson
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ExcludePerson,
    element: <ExcludedPersonsStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.gifts.excludePersons" }, true] },
        LAST_WILL_GIFTS_ROUTES.DesignateExcludedPersons,
        LAST_WILL_GIFTS_ROUTES.IncludeNoContest
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.DesignateExcludedPersons,
    element: <DesignateExcludedPersonsStep />,
    rules: LAST_WILL_GIFTS_ROUTES.IncludeNoContest
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.IncludeNoContest,
    element: <IncludeNoContestStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        LAST_WILL_GIFTS_ROUTES.TransitionPartnerStart,
        LAST_WILL_GIFTS_ROUTES.Review
      ]
    }
  },
  // Partner Starts Here
  {
    path: LAST_WILL_GIFTS_ROUTES.TransitionPartnerStart,
    element: <TransitionPartnerStartStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerDistAllToPartner
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerDistAllToPartner,
    element: <PartnerDistAllToPartnerStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerDistAllToPartnerConfirmed
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerDistAllToPartnerConfirmed,
    element: <PartnerDistAllToPartnerConfirmedStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerResidueOverview
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerResidueOverview,
    element: <PartnerResidueOverviewStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocate
    /*
    {
      if: [
        {
          "==": [
            { var: "users.1.gifts.everythingGoToPartner" },
            false
          ]
        },
        LAST_WILL_GIFTS_ROUTES.PartnerResidueToPartner,
        {
          if: [
            { "==": [{ var: "haveChildren" }, true] },
            LAST_WILL_GIFTS_ROUTES.PartnerResidueToChildren,
            LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocate
          ]
        }
      ]
    }
    */
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerResidueToPartner,
    element: <PartnerResidueToPartnerStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerResidueBalanceToPartner" }, true] },
        LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiariesLoop,
        {
          if: [
            { "==": [{ var: "haveChildren" }, true] },
            LAST_WILL_GIFTS_ROUTES.PartnerResidueToChildren,
            LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocate
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerResidueToChildren,
    element: <PartnerResidueToChildrenStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerResidueBalanceToChildren" }, true] },
        LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiariesLoop,
        LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocate
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocate,
    element: <PartnerResidueAllocateStep />,
    rules:
      LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiariesLoop
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiariesLoop,
    loop: {
      enabled: true,
      iterationCountSource:
        "users.1.assets.estateBalance.0.beneficiaries"
    },
    children: [
      {
        path: LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiaryFails,
        element: <PartnerBeneficiaryDistribution />,
        rules: {
          if: [
            {
              "==": [
                {
                  var: "users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.CHARITY
              ]
            },
            LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiaryCharity,
            {
              "==": [
                {
                  var: "users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution"
                },
                DistributionType.BACKUP_BENEFICIARIES
              ]
            },
            LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiaryBackups,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiaryBackups,
        element: <PartnerBackupBeneficiariesStep />,
        rules: NEXT_ITERATION_RULE
      },
      {
        path: LAST_WILL_GIFTS_ROUTES.PartnerResidueAllocateBeneficiaryCharity,
        element: <PartnerCharityBackup />,
        rules: NEXT_ITERATION_RULE
      }
    ],
    rules: LAST_WILL_GIFTS_ROUTES.PartnerResidueComplete
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerResidueComplete,
    element: <PartnerResidueCompleteStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerSpecificGifts
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerSpecificGifts,
    element: <PartnerSpecificGiftsStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerAddSpecificGifts
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerAddSpecificGifts,
    element: <PartnerAddSpecificGiftsStep />,
    rules:
      LAST_WILL_GIFTS_ROUTES.PartnerPersonalPropertyBalanceOverview
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerPersonalPropertyBalanceOverview,
    element: <PartnerPersonalPropertyBalanceOverviewStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerPersonalPropertyBalance
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerPersonalPropertyBalance,
    element: <PartnerPersonalPropertyBalanceTypeStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.gifts.personalPropertyBalanceType"
            },
            PersonalPropertyBalanceDistributionType.OTHER_PEOPLE
          ]
        },
        LAST_WILL_GIFTS_ROUTES.PartnerPersonalPropertyBalancePeople,
        LAST_WILL_GIFTS_ROUTES.PartnerMakeCashGift
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerPersonalPropertyBalancePeople,
    element: <PartnerPersonalPropertyBalancePeopleStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerMakeCashGift
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerMakeCashGift,
    element: <PartnerMakeCashGiftStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.gifts.makeCashGifts" }, true] },
        LAST_WILL_GIFTS_ROUTES.PartnerListCash,
        LAST_WILL_GIFTS_ROUTES.PartnerExcludePerson
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerListCash,
    element: <PartnerListCashStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerExcludePerson
  },
  // Partner Exclude
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerExcludePerson,
    element: <PartnerExcludedPersonsStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.gifts.excludePersons" }, true] },
        LAST_WILL_GIFTS_ROUTES.PartnerDesignateExcludedPersons,
        LAST_WILL_GIFTS_ROUTES.PartnerIncludeNoContest
      ]
    }
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerDesignateExcludedPersons,
    element: <PartnerDesignateExcludedPersonsStep />,
    rules: LAST_WILL_GIFTS_ROUTES.PartnerIncludeNoContest
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.PartnerIncludeNoContest,
    element: <PartnerIncludeNoContestStep />,
    rules: LAST_WILL_GIFTS_ROUTES.ReviewTabs
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.FinalArrangements}/${LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Preview}`
  },
  {
    path: LAST_WILL_GIFTS_ROUTES.ReviewTabs,
    element: <ReviewTabsStepPage />,
    rules: `${LAST_WILL_SECTION_ROUTES.FinalArrangements}/${LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={LAST_WILL_GIFTS_ROUTES.Preview} />
  }
];

export default giftsSteps;
