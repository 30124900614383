import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import RedeemIcon from "@mui/icons-material/Redeem"; // Leave Gifts for People or Charity
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest"; // Maximum Control Over Gift Distributions
import SecurityIcon from "@mui/icons-material/Security"; // Keep Gifts Private
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom"; // Children Under 18
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety"; // Specify Healthcare Wishes
import HouseIcon from "@mui/icons-material/House"; // Own Real Estate
import WorkIcon from "@mui/icons-material/Work"; // Own a Business
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage"; // Own Property Multiple States
import PetsIcon from "@mui/icons-material/Pets"; // Care for Pets
import { SchemaOf } from "yup";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@setup/consts/routes";
import { PlanType } from "@api/__generated__/globalTypes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";

import {
  EstatePlansMatchmakingFormValues,
  EstatePlansMatchmakingItems,
  UseEstatePlansMatchmakingSubmitReturnType
} from "./types";
import { MATCHMAKING_ROUTES } from "./consts";

export const useEstatePlansMatchmakingItems =
  (): EstatePlansMatchmakingItems[] => {
    const intl = useIntl();

    return useMemo(
      () => [
        {
          label: intl.formatMessage({
            defaultMessage:
              "I want to leave gifts for people or charity"
          }),
          value: "I want to leave gifts for people or charity",
          icon: <RedeemIcon />,
          planType: PlanType.LAST_WILL
        },
        {
          label: intl.formatMessage({
            defaultMessage: "I have children under 18"
          }),
          value: "I have children under 18",
          icon: <FamilyRestroomIcon />,
          planType: PlanType.LAST_WILL
        },
        {
          label: intl.formatMessage({
            defaultMessage: "I want to specify my healthcare wishes"
          }),
          value: "I want to specify my healthcare wishes",
          icon: <HealthAndSafetyIcon />,
          planType: PlanType.LAST_WILL
        },
        {
          label: intl.formatMessage({
            defaultMessage:
              "I want maximum control over my gift distributions"
          }),
          value: "I want maximum control over my gift distributions",
          icon: <SettingsSuggestIcon />,
          planType: PlanType.TRUST
        },
        {
          label: intl.formatMessage({
            defaultMessage: "I want to keep my gifts private"
          }),
          value: "I want to keep my gifts private",
          icon: <SecurityIcon />,
          planType: PlanType.TRUST
        },
        {
          label: intl.formatMessage({
            defaultMessage: "I own real estate"
          }),
          value: "I own real estate",
          icon: <HouseIcon />
        },
        {
          label: intl.formatMessage({
            defaultMessage: "I own a business"
          }),
          value: "I own a business",
          icon: <WorkIcon />,
          planType: PlanType.TRUST
        },
        {
          label: intl.formatMessage({
            defaultMessage: "I own property in multiple states"
          }),
          value: "I own property in multiple states",
          icon: <HolidayVillageIcon />,
          planType: PlanType.TRUST
        },
        {
          label: intl.formatMessage({
            defaultMessage: "I want a plan to ensure care for my pets"
          }),
          value: "I want a plan to ensure care for my pets",
          icon: <PetsIcon />
        }
      ],
      [intl]
    );
  };

export const useEstatePlansMatchmakingSchema =
  (): SchemaOf<EstatePlansMatchmakingFormValues> =>
    useMemo(
      () =>
        yup
          .object()
          .shape({
            matchmakingOptions: yup.array().of(yup.string())
          })
          .required(),
      []
    );

export const useEstatePlansMatchmakingSubmit =
  (): UseEstatePlansMatchmakingSubmitReturnType => {
    const navigate = useNavigate();
    const editLink = getLinkFullUrl([
      ROUTES.EstatePlansMatchMaking,
      MATCHMAKING_ROUTES.Results
    ]);

    const onSubmit = useCallback(
      (data: EstatePlansMatchmakingFormValues) => {
        navigate(editLink, { state: data });
      },
      [editLink, navigate]
    );

    return { onSubmit };
  };
