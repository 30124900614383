import { useCallback } from "react";
import { get } from "lodash";

import TrustSetupArchetype from "@components/archetypes/TrustSetupArchetype";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import { TrustSetupArchetypeValue } from "@components/archetypes/TrustSetupArchetype/types";
import {
  DistribType,
  VestingType
} from "@api/__generated__/globalTypes";

const PartnerSeparateTrustSetupFinalDistroStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { children } = values;

  const { setValue: setBackupBeneficiary } = useFormField(
    "users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0"
  );

  const { setValue: setVestingSchedule } = useFormField(
    "users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust.vestingSchedule"
  );

  const onSubmit = useCallback(
    (data: TrustSetupArchetypeValue) => {
      const vestingType = get(
        data,
        "users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust.vestingType"
      );

      if (vestingType === VestingType.ON_DEATH) {
        setVestingSchedule([]);
      }

      const childrenTrust = get(
        data,
        "users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust"
      );

      setBackupBeneficiary({
        percentageValue: 100,
        distribType: DistribType.SEPARATE_CHILDREN,
        subTrust: {
          ...childrenTrust,
          name: "Separate Children's Trust",
          trustType: DistribType.SEPARATE_CHILDREN,
          terminationDistribution: DistribType.OUTRIGHT
        }
      });
    },
    [setBackupBeneficiary, setVestingSchedule]
  );

  return (
    <TrustSetupArchetype
      stepLayout={{
        title:
          children?.length === 1
            ? `Please designate how you would like to set up ${children[0].name}'s trust.`
            : "Please designate how you would like to set up each of your children's separate trust."
      }}
      name="users.1.assets.estateBalance.0.beneficiaries.0.backupBeneficiaries.0.subTrust"
      {...{ onSubmit }}
    />
  );
};

export default PartnerSeparateTrustSetupFinalDistroStep;
