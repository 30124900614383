export const EXPERT_MATCHING_QUIZ_ROUTES = {
  WhatToExpect: "",
  ExpertType: "type",
  LegalFocus: "legal-focus",
  DocumentType: "document-type",
  StateJurisdiction: "jurisdiction",
  AssetValue: "assets",
  AssetValueProbate: "assets-probate",
  ChallengeInterstitial: "note-on-contests",
  Interstitial: "interstitial",
  HHAssets: "assets-household",
  HHInvestmentAdvice: "personal-financial-advice",
  HHIncome: "income-household",
  HHState: "state-household",
  LegalEnd: "legal-review",
  FinanceEnd: "finance-review"
};
