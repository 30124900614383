import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerCreateLivingWillStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setHealthcarePreferencesValue } = useFormField(
    "users.1.healthcare.preferences"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const specifyPreferences = get(
        data,
        "users.1.healthcare.preferences.specified"
      );

      if (!parseBoolean(specifyPreferences)) {
        setHealthcarePreferencesValue({});
        setHealthcarePreferencesValue({
          specified: false
        });
      }
    },
    [setHealthcarePreferencesValue]
  );

  return (
    <SingleSelectArchetype
      name="users.1.healthcare.preferences.specified"
      stepLayout={{
        title: `Does ${partnerFirstName} want to specify their healthcare decisions?`
      }}
      {...{ onSubmit }}
      items={[
        {
          label: `Yes, specify ${partnerFirstName}'s preferences (7 questions)`,
          value: true
        },
        {
          label: `No. Allow ${partnerFirstName}'s agent to decide for ${partnerFirstName}.`,
          value: false
        }
      ]}
    />
  );
};

export default PartnerCreateLivingWillStep;
