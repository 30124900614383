import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import BugReportOutlined from "@mui/icons-material/BugReportOutlined";

import { usePlanForm } from "@providers/planForm/context";
import DocumentsList from "@components/Documentslist";
import FormStepFooter from "@components/FormStepFooter";
import PageTitle from "@components/PageTitle";
import StepLayout from "@components/StepLayout";

import {
  DocumentPreviewArchetypeProps,
  PlanFormUsersValues
} from "./types";
import { useDocumentsPreview } from "./hooks";

const DocumentPreviewArchetype: React.FC<
  DocumentPreviewArchetypeProps
> = ({
  stepLayout,
  title,
  loadingTitle,
  loadingSubtitle,
  previewActionButtonText
}) => {
  const intl = useIntl();
  const { values: formValues, goBack } =
    usePlanForm<PlanFormUsersValues>();
  const userName = formValues.users?.[0].firstName;
  const {
    error,
    items,
    loading,
    handleGoToCheckout,
    paymentLoading
  } = useDocumentsPreview();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Added to show loading screen long enough to see what's diplayed
    if (loading) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 3000);
    }
  }, [loading]);

  return (
    <>
      <PageTitle
        title={
          title ||
          intl.formatMessage({
            defaultMessage: "Document Preview"
          })
        }
      />
      {error && !isLoading && (
        <StepLayout
          {...stepLayout}
          title="Shoot! We can't create your plan at this time."
          subText={
            error.message ||
            intl.formatMessage({
              defaultMessage:
                "One or more of your plan details is incorrect. Please contact JIC Estates Member Success using one of the 'Help' menu options for further assistance."
            })
          }
          muiIconHeader={<BugReportOutlined />}
        />
      )}
      {isLoading && (
        <>
          <StepLayout
            {...stepLayout}
            title={
              loadingTitle ||
              intl.formatMessage(
                {
                  defaultMessage:
                    "One sec, {userName}. Getting your plan just right."
                },
                { userName }
              )
            }
            isWide
          />
          <Box py={{ xs: 2.5, md: 6.25 }} mx="auto">
            <Box mx={-2}>
              <LinearProgress
                color="secondary"
                variant="indeterminate"
              />
            </Box>
            <Typography
              variant="h2"
              lineHeight="1.5"
              letterSpacing="0"
              textAlign="center"
              color="grey.700"
              my="3.375rem"
            >
              {loadingSubtitle || (
                <FormattedMessage defaultMessage="Reviewing your selections..." />
              )}
            </Typography>
          </Box>
        </>
      )}
      {!error && !isLoading && (
        <StepLayout
          {...stepLayout}
          title={
            stepLayout?.title ||
            intl.formatMessage({
              defaultMessage: "You built the perfect plan."
            })
          }
          isWide={false}
        >
          <Box pb={{ xs: 8, md: 4 }}>
            <Button
              variant="contained"
              fullWidth
              sx={{ mb: 5 }}
              disabled={paymentLoading || !!error}
              onClick={handleGoToCheckout}
            >
              {previewActionButtonText ?? (
                <FormattedMessage defaultMessage="Proceed to checkout" />
              )}
              {paymentLoading && (
                <CircularProgress size={16} sx={{ ml: 2 }} />
              )}
            </Button>
            <DocumentsList {...{ items }} />
          </Box>
        </StepLayout>
      )}
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(196, 196, 196, 0.8)"
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: 1.25
          }}
        >
          <FormStepFooter
            prevButtonProps={{
              label: intl.formatMessage({
                defaultMessage: "Go back"
              }),
              type: "button",
              onClick: goBack
            }}
            nextButtonProps={{
              label:
                previewActionButtonText ??
                intl.formatMessage({
                  defaultMessage: "Checkout"
                }),
              onClick: handleGoToCheckout,
              disabled: paymentLoading || !!error,
              type: "button"
            }}
            topSpacing={{ xs: 0, md: 0 }}
          />
        </Container>
      </Paper>
    </>
  );
};

export default DocumentPreviewArchetype;
