import { Box } from "@mui/material";
import { useIntl } from "react-intl";

import Error from "@components/Error";
import Modal from "@components/Modal";
import CsvUploader from "@components/CSVParser";

import AddAssetButton from "../AddAssetButton";

import { AddAssetModalProps } from "../AddAssetModal/types";

import { useImportAssetModal } from "./hooks";

const ImportAssetsModal: React.FC<AddAssetModalProps> = ({
  lastWill,
  showIsFunded = false
}) => {
  const intl = useIntl();
  const {
    isOpen,
    canEdit,
    loading,
    error,
    toggleOn,
    toggleOff,
    onSubmit,
    reset
  } = useImportAssetModal(lastWill);

  return (
    <Box
      sx={{
        marginTop: "1.5rem"
      }}
    >
      {canEdit && (
        <AddAssetButton
          onClick={toggleOn}
          variant="outlined"
          color="inherit"
          title="+ CSV Import"
        />
      )}
      <Modal
        fullWidth
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Import Multiple Assets with a CSV Import"
        })}
        content={<CsvUploader onSubmit={onSubmit} />}
        actions={[
          {
            label: intl.formatMessage({ defaultMessage: "Close" }),
            onClick: toggleOff,
            variant: "outlined",
            color: "inherit"
          }
        ]}
      />
    </Box>
  );
};

export default ImportAssetsModal;
