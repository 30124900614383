import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { CompensationType } from "@hooks/useTrustEnums";
import { BlogLinks } from "@setup/consts/articleLinks";

const PartnerTrusteeCompensationStep: React.FC = () => {
  const { setValue: setTrusteesCompensationDollar } = useFormField(
    "users.1.trust.trusteesCompensationDollar"
  );
  const { setValue: setTrusteesCompensationPercent } = useFormField(
    "users.1.trust.trusteesCompensationPercent"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const trusteeCompensation = get(
        data,
        "users.1.trust.trusteesCompensationType"
      );

      if (trusteeCompensation !== CompensationType.Percent) {
        setTrusteesCompensationPercent(null);
      }
      if (trusteeCompensation !== CompensationType.Dollar) {
        setTrusteesCompensationDollar(null);
      }
    },
    [setTrusteesCompensationPercent, setTrusteesCompensationDollar]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How would you like to compensate your Trustee?`,
        subText: "Learn more about Trustee compensation.",
        moreInfoModalProps: {
          title: "Trustee Compensation",
          togglerLabel: "Learn more.",
          content: (
            <p>
              Serving as a Trustee can be a lot of work. State law
              recognizes this, and as a default, your Trustee is
              entitled to seek reasonable compensation for his or her
              services.
              <br />
              <br />
              Regardless of whether you want these default rules to
              apply, specifying your wishes when it comes to
              compensating your Trustee is important because doing so
              can help mitigate potential conflict among your Trustee
              and beneficiaries.
              <br />
              <br />
              How should you compensate your trustees? There is no
              right or wrong answer -- it depends on your personal
              preferences and whether you have a professional or
              Family Member Trustee.
              <br />
              <br />
              <strong>Professional Trustees. </strong>Most
              professional Trustees have pre-established compensation
              structures to which you&apos;ll need to agree if you
              want them to serve as your Trustee. Typically, this
              compensation is set as a percentage of the trust assets.
              <br />
              <br />
              <strong>Family Member Trustees. </strong>If you&apos;re
              nominating a family member to serve as Trustee,
              you&apos;ll have more flexibility to set a custom
              compensation structure.
              <br />
              <br />
              While most family member trustees serve with no
              compensation and only reasonable reimbursement of
              expenses, that strategy might not be best for your
              personal situation.
              <br />
              <br />
              For a more comprehensive discussion on Family Member
              Trustee compensation, check out our blog post using the
              link below.
            </p>
          ),
          actions: [
            {
              label: "Learn More",
              href: BlogLinks.familyMemberTrusteeCompensation
            }
          ],
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="users.1.trust.trusteesCompensationType"
      items={[
        {
          label: CompensationType.None,
          value: CompensationType.None
        },
        {
          label: CompensationType.Reasonable,
          value: CompensationType.Reasonable
        },
        {
          label: CompensationType.Dollar,
          value: CompensationType.Dollar
        },
        {
          label: CompensationType.Percent,
          value: CompensationType.Percent
        }
      ]}
    />
  );
};

export default PartnerTrusteeCompensationStep;
