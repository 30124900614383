import useUserContext from "@providers/user/hooks";

import ClientResources from "./ClientResources";
import AdvisorResources from "./AdvisorResources";

const ResourcesTab = () => {
  const userInfo = useUserContext();
  const { isAdvisor } = userInfo;

  if (isAdvisor) {
    return <AdvisorResources />;
  }

  return <ClientResources />;
};

export default ResourcesTab;
