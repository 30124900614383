import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { PetPlanValues } from "@pages/PetPlan/types";
import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";

import { usePrimaryDrafterAllAboutYouReviewTab } from "./Reviews/usePrimaryDrafterAllAboutYouReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<PetPlanValues>();
  const { value: firstName } = useFormField("users.0.firstName");

  const items = usePrimaryDrafterAllAboutYouReviewTab({
    values
  });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `You've got a great looking pack, ${firstName}!`,
        subText: `Let's confirm that we got your details right 😉.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
