import { Outlet } from "react-router-dom";

import PlanFormProvider from "@providers/planForm";
import { ROUTES } from "@setup/consts/routes";
import { PlanType } from "@api/__generated__/globalTypes";
import { LastWillPlanValues } from "@pages/LastWill/types";

import routes from "./routes";

const TrustPlan: React.FC = () => (
  <PlanFormProvider<LastWillPlanValues>
    value={{
      parentPath: ROUTES.Trust,
      steps: routes,
      planType: PlanType.TRUST
    }}
  >
    <Outlet />
  </PlanFormProvider>
);

export default TrustPlan;
