import { PowerOfAttorney_agents as PoaAgent } from "@api/plans/__generated__/PowerOfAttorney";

import getAgentHierarchy from "./getAgentHierarchy";

const usePoaAgentHierarchy = (poaAgents: PoaAgent[]) => {
  if (!poaAgents || poaAgents.length === 0) {
    return [];
  }

  const agents = poaAgents.map((agent) => ({
    isBackup: agent.isSuccessor,
    order: agent.order,
    name: agent.agent?.fullName
  }));

  const hierarchy = getAgentHierarchy(agents);

  return hierarchy;
};

export default usePoaAgentHierarchy;
