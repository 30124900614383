import {
  Box,
  CircularProgress,
  Typography,
  Button,
  FormControl
} from "@mui/material";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";

import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";
import { LoadingWrapper } from "@pages/EstatePlanView/Partials/styles";
import { useFormWithYup } from "@hooks/useFormWithYup";
import { numberWithCommas } from "@utils/misc";
import { UpdateNetWorth } from "@api/plans/__generated__/UpdateNetWorth";
import { useUpdateNetWorthMutation } from "@api/plans";

import { NetWorthInputProps } from "./types";

const NetWorthInput: React.FC<NetWorthInputProps> = ({
  currentNetWorth,
  lastWillId,
  canEdit = false
}) => {
  const placeholderNetWorth = `$${numberWithCommas(currentNetWorth)}`;
  const validationSchema = yup.object().shape({
    updateNetWorth: yup
      .object()
      .shape({
        netWorth: yup
          .number()
          .typeError("Net worth must be entered as a number")
          .required("Please enter a number")
      })
      .typeError("Net worth must be entered as a number")
      .required("Please enter a number")
  });

  const methods = useFormWithYup<UpdateNetWorth>(validationSchema);
  const [updateNetWorth, { loading, reset }] =
    useUpdateNetWorthMutation();

  const onFormSubmit = useCallback(
    (data: UpdateNetWorth) => {
      if (data.updateNetWorth && lastWillId) {
        updateNetWorth({
          variables: {
            id: lastWillId,
            netWorth: data.updateNetWorth.netWorth
          }
        });
        reset();
        methods.reset();
      }
    },
    [updateNetWorth, reset, methods, lastWillId]
  );

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <Typography>
        <FormattedMessage defaultMessage="Net Worth" />
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <FormControl>
              <BasicFormInput
                name="updateNetWorth.netWorth"
                placeholder={placeholderNetWorth}
                type="number"
                variant="standard"
                sx={{ paddingRight: "0.625rem" }}
              />
            </FormControl>
            {canEdit && (
              <Button
                type="submit"
                variant="outlined"
                color="inherit"
              >
                <FormattedMessage defaultMessage="Update" />
              </Button>
            )}
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default NetWorthInput;
