import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominateCoTrusteeStep: React.FC = () => {
  const { setValue: setCoTrustees } = useFormField(
    "users.0.trust.coTrustees"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const nominateCoTrustee = get(
        data,
        "users.0.trust.nominateCoTrustee"
      );

      if (!parseBoolean(nominateCoTrustee)) {
        setCoTrustees([]);
      }
    },
    [setCoTrustees]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: "Do you want to nominate a Co-Trustee?",
        subText:
          "Your Co-Trustee can help you manage the Trust while you are alive. Only you, the Trustmaker, will be authorized to change or revoke the Trust.",
        moreInfoModalProps: {
          title: "Nominating a Co-Trustee",
          togglerLabel: "Learn more.",
          content: (
            <p>
              A Co-Trustee is an individual or entity who serves as
              Trustee alongside you. They are responsible for managing
              the trust&apos;s affairs and generally have all the same
              powers that you do as Trustmaker, with the exception
              that only the Trustmaker can change or revoke the trust.
              <br />
              <br />
              <strong>Nominating a Co-Trustee. </strong>
              <br />
              During your life, your Co-Trustee will have all the same
              powers that you do as Trustmaker (the
              &quot;Settlor&quot;), except only you will be authorized
              to change or revoke the trust. This shared power can
              help share the workload of managing the trust&apos;s
              affairs.
              <br />
              <br />
              After you pass away or if you become incapacitated, your
              Co-Trustee will be responsible for winding up your
              earthly affairs and carrying out your instructions in
              the trust agreement.
              <br />
              <br />
              <strong>Deciding Not to Nominate Co-Trustee. </strong>
              <br />
              If you choose not to nominate a Co-Trustee, only you
              will be authorized to manage the trust for so long as
              you have capacity (i.e., the ability to make and
              understand important decisions).
              <br />
              <br />
              Your Successor Trustee (who we&apos;ll discuss later)
              will step in only in the event that you pass away or
              become incapacitated.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="users.0.trust.nominateCoTrustee"
    />
  );
};

export default NominateCoTrusteeStep;
