/* eslint-disable react/no-unescaped-entities */
import { useEffect, useMemo } from "react";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { BlogLinks } from "@setup/consts/articleLinks";
import { useFormField } from "@components/FormFields/hooks";
import { useDisableForm } from "@providers/disableForm/context";
import { DisableFormItems } from "@providers/disableForm/type";

const SetAsideFundsAmountStep: React.FC = () => {
  const { value } = useFormField<number>("asideFundsAmount");

  const error = useMemo(
    () => +value <= 0 || Number.isInteger(value) === false,
    [value]
  );

  const { setDisableItems: setExceededItem } = useDisableForm();

  useEffect(() => {
    setExceededItem((prev: DisableFormItems) => ({
      ...prev,
      asideFundsAmount: error
    }));
  }, [error, setExceededItem]);

  return (
    <SingleResponseArchetype
      label="$ Amount Per Pet"
      fieldProps={{
        valuePrefix: "$",
        error,
        type: "number",
        helperText:
          value &&
          error &&
          "Amount set aside must be a whole number greater than $0"
      }}
      stepLayout={{
        title: `Ok. What amount should be set aside for your each of your pets?`,
        subText: `Find our tips for estimating long-term pet ownership costs here.`,
        moreInfoModalProps: {
          title: "How Much Money Should You Leave for Your Pet?",
          togglerLabel: "Learn more",
          content: (
            <p>
              According to Synchrony Financial's "Lifetime of Care"
              study, nearly half of dog parents admit to
              underestimating the cost of providing for their pet.
              <br />
              <br />
              As a back-of-the-envelope approach for estimating how
              much money to leave for your pet dog or cat:
              <ul>
                <li>
                  Multiply $2,000 by the expected remaining life of
                  your dog
                </li>
                <li>
                  Multiply $1,700 by the expected remaining life of
                  your cat
                </li>
              </ul>
              For a more comprehensive discusion on how much money you
              should leave for your pet along with other methodologies
              to consider, check out our blog using the link below.
            </p>
          ),
          closeLabel: "Got it",
          actions: [
            {
              label: "Go to Article",
              href: BlogLinks.howMuchToLeavePet
            }
          ]
        }
      }}
      // {...{ onSubmit }}
      name="asideFundsAmount"
    />
  );
};

export default SetAsideFundsAmountStep;
