import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { FileUploadState } from "@hooks/useCustomDocumentUpload";

import { StateIconProps } from "./types";
import { SyncIcon } from "./styles";

const StateIcon = ({ state }: StateIconProps) => {
  if (state === FileUploadState.ERROR) {
    return <ErrorOutlineIcon fontSize="small" color="error" />;
  }

  if (state === FileUploadState.SUCCESS) {
    return (
      <CheckCircleOutlineIcon fontSize="small" color="success" />
    );
  }

  return <SyncIcon fontSize="small" color="info" />;
};

export default StateIcon;
