import { Button as MaterialButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Button = styled(MaterialButton)(({ theme }) => ({
  padding: 0,
  borderRadius: 0,
  minHeight: "auto",
  minWidth: "auto",
  textTransform: "none",
  justifyContent: "flex-start",
  color: theme.palette.grey[500],
  font: "inherit",
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.caption.fontWeight,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent"
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.1em"
  }
}));
