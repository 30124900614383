import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const DesignateBackupRepresentativeStep: React.FC = () => (
  <PersonDetailsArrayArchetype
    stepLayout={{
      title: `Who would you like to serve as backup Representative?`,
      subText:
        "You can add additional backup Representatives by hitting the 'Add Another Backup Representative' button."
    }}
    personRole="Backup Representative"
    addBtnLabel="Add Another Backup Representative"
    removeBtnLabel="Remove Backup Representative"
    name="users.0.lastWill.backupPersonalRepresentatives"
  />
);

export default DesignateBackupRepresentativeStep;
