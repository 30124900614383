import { useCallback } from "react";

import { useGenerateChatResponseMutation, useCreateThreadMutation } from "@api/ai/hooks";

export const useGenerateChatResponse = () => {
    const [callGenerateChatResponse] = useGenerateChatResponseMutation();
  
    const generateChatResponse = useCallback(
      async (threadId: string, content: string) => {
        try {
          const response = await callGenerateChatResponse({
            variables: {
              threadId,
              content
            }
          });
  
          return response;
        } catch (error) {
          console.error(
            "Error generating chat response:",
            error
          );
        }
      },
      [callGenerateChatResponse]
    );
  
    return generateChatResponse;
  };

  export const useCreateThreadAndResponse = () => {
    const [callCreateThread] = useCreateThreadMutation();
  
    const createThreadAndResponse = useCallback(
      async (content: string) => {
        try {
          const response = await callCreateThread({
            variables: {
              content
            }
          });
  
          const threadId = response.data?.aiCreateThread.threadId;
          const message = response.data?.aiCreateThread.message;
  
          if (!!threadId && !!message) {
            return { threadId, message };
          }
        } catch (error) {
          console.error(
            "Error creating thread:",
            error
          );
        }
  
        throw new Error("Error creating the thread");
      },
      [callCreateThread]
    );
  
    return createThreadAndResponse;
  };