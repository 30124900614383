import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  PartnerType,
  DistribType,
  TrustType
} from "@api/__generated__/globalTypes";
import { Asset_beneficiaries as Beneficiary } from "@api/plans/__generated__/Asset";
import { User } from "@api/me/__generated__/User";
import { getBeneficiaryInfo } from "@utils/getBeneficiaryInfo";

import { getLinkFullUrl } from "../utils";

export const useLastWillRemainder = (
  beneficiaries: Beneficiary[] | undefined,
  partner: User | undefined,
  partnerType = PartnerType.SINGLE
): string[][] => {
  const { formatMessage } = useIntl();

  const primaryBeneficiaries = beneficiaries?.filter(
    (beneficiary) => beneficiary.isBackup === false
  );

  return primaryBeneficiaries && primaryBeneficiaries?.length > 0
    ? [[getBeneficiaryInfo(primaryBeneficiaries)]]
    : [];
};
