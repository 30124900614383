import { startCase } from "lodash";
import { useMemo } from "react";

import { AssetType } from "@api/__generated__/globalTypes";

export const useAssetsOptions = () =>
  useMemo(
    () =>
      Object.keys(AssetType)
        .filter(
          (asset) =>
            ![
              AssetType.ESTATE_BALANCE,
              AssetType.PERSONAL_PROPERTY_BALANCE,
              AssetType.CASH
            ].includes(asset as AssetType)
        )
        .map((asset) => {
          const key = startCase(asset.toLowerCase());

          return {
            key,
            label: key,
            value: asset
          };
        }),
    []
  );
