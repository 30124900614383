import { Container, Box } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SailingIcon from "@mui/icons-material/Sailing";

import EstateAdminFormCapture from "@components/archetypes/EstateAdminFormCapture";
import IconList from "@components/IconList";

const UserInfo: React.FC = () => {
  const items = [
    {
      icon: <MessageIcon />,
      label:
        "Our team will get in touch to learn more about your needs and answer any initial quesitons you have via phone, text, or email"
    },
    {
      icon: <HandshakeIcon />,
      label:
        "After we get in touch, we'll connect you with the right expert for a free consult"
    },
    {
      icon: <SailingIcon />,
      label:
        "Sail worry-free through estate administration while your expert takes care of everything"
    }
  ];

  // TODO : UPDATE ME(!)
  // isBothMatch
  // isLegalMatch
  // isFinancialMatch

  return (
    <Container maxWidth="sm">
      <EstateAdminFormCapture
        name="user"
        stepLayout={{
          title: "You are a great fit for our experts!",
          subText:
            "Let's connect to get you expert estate administration help today."
        }}
      />
      <Box mt="1.5rem">
        <IconList title="What's Next?" items={items} />
      </Box>
    </Container>
  );
};

export default UserInfo;
