import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AssetType } from "@api/__generated__/globalTypes";

export const useAssetHumanizedTitle = (): Record<
  AssetType,
  string
> => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      [AssetType.PERSONAL_PROPERTY_BALANCE]: formatMessage({
        defaultMessage: "Personal Property Balance"
      }),
      [AssetType.PERSONAL_PROPERTY]: formatMessage({
        defaultMessage: "Personal Property"
      }),
      [AssetType.REAL_ESTATE]: formatMessage({
        defaultMessage: "Real Estate"
      }),
      [AssetType.FINANCIAL_ACCOUNT]: formatMessage({
        defaultMessage: "Financial Account"
      }),
      [AssetType.BUSINESS_INTEREST]: formatMessage({
        defaultMessage: "Business Interest"
      }),
      [AssetType.OTHER]: formatMessage({
        defaultMessage: "Other Assets"
      }),
      [AssetType.CASH]: formatMessage({
        defaultMessage: "Cash"
      }),
      [AssetType.ESTATE_BALANCE]: formatMessage({
        defaultMessage: "Estate Balance"
      }),
      [AssetType.LIFE_INSURANCE_POLICY]: formatMessage({
        defaultMessage: "Life insurance"
      })
    }),
    [formatMessage]
  );
};
