import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import AssetReviewPartial from "@components/AssetReviewPartial";
import AddAssetsModal from "@components/QuestionnaireAssetModal/AddAssetModal";

import { useSpecificGiftsWorkContent } from "../utils";

import {
  useGiftReviewCardItems,
  useSpecificGiftsAssets
} from "./Reviews/utils";

const PartnerAddSpecificGiftsStep = () => {
  const { values, goBack, saveAndContinue } =
    usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter, partner]
  } = values;

  const { specificGiftAssets, notSpecificGiftAssets } =
    useSpecificGiftsAssets(partner.assets);

  const specificGiftReviewItems = useGiftReviewCardItems(
    specificGiftAssets,
    "users.1"
  );
  const notSpecificGiftReviewItems = useGiftReviewCardItems(
    notSpecificGiftAssets,
    "users.1"
  );

  return (
    <StepLayout
      title={`Please add all the assets that ${partner.firstName} would like to designate as specific gifts.`}
      subText="If you don't want to leave any specific gifts, leave blank and click 'Save and Continue.' "
      moreInfoModalProps={{
        title: "How Specific Gifts Work",
        togglerLabel: "Learn more.",
        content: useSpecificGiftsWorkContent()
      }}
      isWide
    >
      {(specificGiftReviewItems.length > 0 ||
        notSpecificGiftReviewItems.length > 0) && (
        <AssetReviewPartial
          title="Specific Gifts"
          href="#ignore"
          description="These are assets that you've designated as a specific gift."
          reviewItems={specificGiftReviewItems}
        />
      )}
      {(specificGiftReviewItems.length > 0 ||
        notSpecificGiftReviewItems.length > 0) && (
        <AssetReviewPartial
          title="Other Identified Assets"
          href="#ignore"
          description="These are assets that you have identified and not marked as a specific gift. They will pass through your Residuary Estate."
          reviewItems={notSpecificGiftReviewItems}
        />
      )}
      <AddAssetsModal userIndex="users.1" />
      <FormStepFooter
        prevButtonProps={{ type: "button", onClick: goBack }}
        nextButtonProps={{
          type: "submit",
          onClick: () => saveAndContinue()
        }}
      />
    </StepLayout>
  );
};

export default PartnerAddSpecificGiftsStep;
