import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import { BlogLinks } from "@setup/consts/articleLinks";
import ReviewTabsArchetype from "@components/archetypes/ReviewTabs";
import ReviewBox from "@components/ReviewBox";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import AssetReviewPartial from "@components/AssetReviewPartial";
import { useFormField } from "@components/FormFields/hooks";
import {
  PartnerType,
  PlanOption
} from "@api/__generated__/globalTypes";

import { usePrimaryDrafterAssetsReviewTab } from "./Reviews/usePrimaryDrafterAssetsReviewTab";
import { usePartnerAssetsReviewTab } from "./Reviews/usePartnerAssetsReviewTab";
import {
  getHowToTransferAssetsContent,
  getWhyTransferAssetsContent
} from "./Reviews/utils";

const ReviewTabsStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter, partner]
  } = values;

  const { value: planOption } =
    useFormField<PlanOption>("planOption");
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");

  const primaryDrafterItems = usePrimaryDrafterAssetsReviewTab({
    values,
    partnerType
  });
  const [
    primaryItems,
    primaryFundedReviewItems,
    primaryNotFundedReviewItems
  ] = primaryDrafterItems;

  const partnerReviewItems = usePartnerAssetsReviewTab({
    values,
    partnerType
  });

  const [
    partnerItems,
    partnerFundedReviewItems,
    partnerNotFundedReviewItems
  ] = partnerReviewItems;

  const items = useMemo(
    () => [
      {
        label:
          primaryDrafter?.firstName ??
          formatMessage({ defaultMessage: "You" }),
        content: primaryDrafterItems && (
          <Grid container spacing={{ xs: 3, md: 5.5 }}>
            {(primaryFundedReviewItems.length > 0 ||
              primaryNotFundedReviewItems.length > 0) && (
              <Grid item xs={12} md={12} key="primaryFundedItems">
                <AssetReviewPartial
                  title="Assets You Are Funding to Your Trust"
                  href="#ignore"
                  description="These are assets titled in the name of your revocable trust."
                  moreInfoModalProps={{
                    togglerLabel: "Learn more.",
                    title: "Transferring Assets to Your Trust",
                    content:
                      getHowToTransferAssetsContent(primaryDrafter),
                    actions: [
                      {
                        label: "Guide on Trust Funding",
                        href: BlogLinks.howToFundTrust
                      }
                    ],
                    closeLabel: "Got it"
                  }}
                  reviewItems={primaryFundedReviewItems}
                />
              </Grid>
            )}
            {(primaryFundedReviewItems.length > 0 ||
              primaryNotFundedReviewItems.length > 0) && (
              <Grid item xs={12} md={12} key="primaryNotFundedItems">
                <AssetReviewPartial
                  title="Assets Not Yet Funded to Your Trust"
                  href="#ignore"
                  description="You don't have to fund all your assets into the trust right away, but you should make funding the trust a priority over the next few weeks in order to get the most out of your Trust."
                  moreInfoModalProps={{
                    togglerLabel: "Learn more.",
                    title: "Transferring Assets to Your Trust",
                    content: getWhyTransferAssetsContent(),
                    actions: [
                      {
                        label: "Guide on Trust Funding",
                        href: BlogLinks.howToFundTrust
                      }
                    ],
                    closeLabel: "Got it"
                  }}
                  reviewItems={primaryNotFundedReviewItems}
                />
              </Grid>
            )}
            {primaryItems &&
              primaryItems.map(({ title, ...item }) => (
                <Grid item xs={12} md={12} key={title}>
                  <ReviewBox {...{ title, ...item }} />
                </Grid>
              ))}
          </Grid>
        )
      },
      {
        label:
          partner?.firstName ??
          formatMessage({ defaultMessage: "Partner" }),
        content: partnerReviewItems &&
          planOption === PlanOption.COUPLE && (
            <Grid container spacing={{ xs: 3, md: 5.5 }}>
              {(partnerFundedReviewItems.length > 0 ||
                partnerNotFundedReviewItems.length > 0) && (
                <Grid item xs={12} md={12} key="partnerFundedItems">
                  <AssetReviewPartial
                    title="Assets You Are Funding to Your Trust"
                    href="#ignore"
                    description="These are assets titled in the name of your revocable trust."
                    moreInfoModalProps={{
                      togglerLabel: "Learn more.",
                      title: "Transferring Assets to Your Trust",
                      content: getHowToTransferAssetsContent(partner),
                      actions: [
                        {
                          label: "Guide on Trust Funding",
                          href: BlogLinks.howToFundTrust
                        }
                      ],
                      closeLabel: "Got it"
                    }}
                    reviewItems={partnerFundedReviewItems}
                  />
                </Grid>
              )}
              {(partnerFundedReviewItems.length > 0 ||
                partnerNotFundedReviewItems.length > 0) && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  key="partnerNotFundedItems"
                >
                  <AssetReviewPartial
                    title="Assets Not Yet Funded to Your Trust"
                    href="#ignore"
                    description="You don't have to fund all your assets into the trust right away, but you should make funding the trust a priority over the next few weeks in order to get the most out of your Trust."
                    moreInfoModalProps={{
                      togglerLabel: "Learn more.",
                      title: "Transferring Assets to Your Trust",
                      content: getWhyTransferAssetsContent(),
                      actions: [
                        {
                          label: "Guide on Trust Funding",
                          href: BlogLinks.howToFundTrust
                        }
                      ],
                      closeLabel: "Got it"
                    }}
                    reviewItems={partnerNotFundedReviewItems}
                  />
                </Grid>
              )}
              {partnerItems &&
                partnerItems.map(({ title, ...item }) => (
                  <Grid item xs={12} md={12} key={title}>
                    <ReviewBox {...{ title, ...item }} />
                  </Grid>
                ))}
            </Grid>
          )
      }
    ],
    [
      primaryDrafter,
      formatMessage,
      primaryDrafterItems,
      primaryItems,
      partner,
      partnerReviewItems,
      partnerItems,
      planOption,
      primaryFundedReviewItems,
      primaryNotFundedReviewItems,
      partnerFundedReviewItems,
      partnerNotFundedReviewItems
    ]
  );

  return (
    <ReviewTabsArchetype
      stepLayout={{
        title: "Let's confirm we got this right.",
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
      {...{ items }}
    />
  );
};

export default ReviewTabsStep;
