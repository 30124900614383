import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PainReliefStep: React.FC = () => {
  const { setValue: setPainReliefException } = useFormField(
    "users.0.healthcare.preferences.reliefFromPainAddNote"
  );
  const { setValue: setPainReliefExceptionList } = useFormField(
    "users.0.healthcare.preferences.reliefFromPainCondition"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const reliefFromPain = get(
        data,
        "users.0.healthcare.preferences.reliefFromPain"
      );

      if (!parseBoolean(reliefFromPain)) {
        setPainReliefException(false);
        setPainReliefExceptionList(null);
      }
    },
    [setPainReliefException, setPainReliefExceptionList]
  );

  return (
    <SingleSelectArchetype
      nullable
      name="users.0.healthcare.preferences.reliefFromPain"
      stepLayout={{
        title: `Do you wish to receive maximum pain and comfort care, even if it hastens your death?`
      }}
      {...{ onSubmit }}
      items={[
        {
          label: "Yes",
          value: true
        },
        {
          label: "No",
          value: false
        },
        {
          label: "Skip this question",
          value: null
        }
      ]}
    />
  );
};

export default PainReliefStep;
