import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/Trust/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    stepLayout={{
      title: `Here comes the fun part!`,
      subText: `In these next two sections, we'll review how you want to distribute your assets after you pass away. We'll start with your Trust Remainder.`
    }}
  />
);

export default SectionsPreviewStep;
