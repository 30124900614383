import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";

const PersonalPropertyBalanceOverviewStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Great work! Now we're going to decide how you want to gift your personal property balance.`,
      muiIconHeader: <InfoOutlinedIcon />,
      subText: `Your Personal Property Balance represents the rest of your personal property items (cars, boats, jewelry, furniture, etc.) that you didn't already designate as a specific gift.`
    }}
  />
);

export default PersonalPropertyBalanceOverviewStep;
