import {
  useLocation,
  useNavigate,
  useRoutes
} from "react-router-dom";
import { useEffect } from "react";

import useUserContext from "@providers/user/hooks";
import { RETURN_URL_LOCAL_STORAGE_KEY } from "@setup/consts/misc";
import { ROUTES } from "@setup/consts/routes";
import { useFinancialAdvisorConverter } from "@hooks/use-financial-advisor-converter";

import { APP_ROUTES } from "./consts";

const Routes: React.FC = () => {
  useFinancialAdvisorConverter();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { cognitoUser } = useUserContext();
  const matchedRoute = useRoutes(
    APP_ROUTES.filter((route) =>
      cognitoUser ? route : !route.isProtected
    )
  );

  useEffect(() => {
    const localStorageReturnUrl = localStorage.getItem(
      RETURN_URL_LOCAL_STORAGE_KEY
    );

    if (!cognitoUser || !localStorageReturnUrl) {
      return;
    }

    if (pathname !== ROUTES.Root) {
      localStorage.removeItem(RETURN_URL_LOCAL_STORAGE_KEY);

      return;
    }

    localStorage.removeItem(RETURN_URL_LOCAL_STORAGE_KEY);
    navigate(localStorageReturnUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return matchedRoute;
};

export default Routes;
