import {
  AccordionSummary,
  Typography,
  Button,
  AccordionDetails,
  Grid
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { useState, useCallback } from "react";
import { useIntl } from "react-intl";

import ReviewBox from "@components/ReviewBox";

import { ReviewReturnType } from "../types";
import * as Styled from "../styles";

export const ReviewSection: React.FC<ReviewReturnType[0]> = ({
  tab,
  icon,
  items
}) => {
  const [expanded, setExpanded] = useState(false);
  const { formatMessage } = useIntl();
  const onChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <Styled.Accordion {...{ onChange, expanded }}>
      <AccordionSummary>
        <Box display="flex" flexDirection="column">
          <Box display="flex" gap=".5rem">
            <Styled.ReviewTitle
              display="flex"
              alignItems="center"
              gap="0.5rem"
            >
              {icon}
              <Typography>{tab}</Typography>
            </Styled.ReviewTitle>
            <ExpandMoreIcon
              color="inherit"
              sx={{
                transform: expanded ? "rotate(0)" : "rotate(180deg)",
                transition: "transform .5s"
              }}
            />
          </Box>
          {!expanded && (
            <Button
              sx={{
                textAlign: "left"
              }}
              color="inherit"
            >
              {formatMessage({ defaultMessage: "+ Show Details" })}
            </Button>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            "&:not(:last-child)": {
              borderBottom: "1px solid #ccc"
            }
          }}
          key={tab}
        >
          {items.map(({ title, canEdit, ...item }) => (
            <Grid mt="1.5rem" item xs={12} md={12} key={title}>
              <ReviewBox
                canEdit={canEdit ?? false}
                {...{ title, ...item }}
              />
            </Grid>
          ))}
        </Box>
      </AccordionDetails>
    </Styled.Accordion>
  );
};
