import { useMemo } from "react";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

import { roleDescription } from "../consts";

export const useMembershipRoleOptions = () =>
  useMemo<AutocompleteBaseOption[]>(
    () =>
      Object.entries(roleDescription).map(([key, value]) => ({
        key,
        label: value.label,
        value: key
      })),
    []
  );
