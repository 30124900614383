import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { User } from "@api/me/__generated__/User";
import { parseBoolean } from "@utils/parseBoolean";

const TrustNameStep: React.FC = () => {
  const { value: user } = useFormField<User>("users.0");
  const { value: partner } = useFormField<User | undefined>(
    "users.1"
  );
  const { setValue: setTrustName } = useFormField(
    "users.0.trust.name"
  );
  const { value: isJointTrust } = useFormField<boolean>(
    "users.0.trust.isJointTrust"
  );
  const getGenericTrustName = (
    isJointTrust: boolean,
    user: User,
    partner: User | undefined
  ) => {
    if (!isJointTrust) {
      return `${user.firstName} ${user.lastName} Revocable Living Trust`;
    }

    if (
      isJointTrust &&
      partner &&
      partner?.lastName === user.lastName
    ) {
      return `${user.lastName} Family Trust`;
    }

    // isJointTrust and different last names
    return `${user.lastName} and ${partner?.lastName} Family Trust`;
  };

  const genericTrustName = getGenericTrustName(
    isJointTrust,
    user,
    partner
  );


  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const useCustomName = get(data, "users.0.trust.useCustomName");

      if (!parseBoolean(useCustomName)) {
        setTrustName(
          genericTrustName
        );
      }
    },
    [setTrustName, genericTrustName]
  );

  const infoModalContent = {
    title: "Naming Trusts",
    togglerLabel: "Learn more.",
    content: (
      <p>
        You can choose to follow the standard trust naming convention
        or pick a different name for your trust. The name is merely
        for identification and administrative purposes and does not
        affect the trust&apos;s function.
        <br />
        <br />
        <strong>Standard Naming Convention.</strong> The most common
        convention for naming trusts is to use your full name or last
        name followed by &apos;Revocable Living Trust&apos;. For
        example, &quot;The Jane Doe Revocable Living Trust&quot; or
        &quot;The Doe Family Revocable Living Trust.&quot;
        <br />
        <br />
        <strong>Custom Trust Names.</strong> Some trustmakers prefer
        to use more generic or less identifiable names for their
        trust, particularly in instances in which privacy is a
        concern. Instead of &quot;The Jane Doe Revocable Living
        Trust,&quot; you might name your trust &quot;The JED Peach
        Tree Trust&quot; or any number of different names. Keep in
        mind that it&apos;s important to avoid names that could
        potentially be misleading or imply an affiliation with a
        public or government entity.
      </p>
    ),
    closeLabel: "Got it"
  };

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `What should we call your trust?`,
        subText: "Learn more about naming your trust.",
        moreInfoModalProps: infoModalContent
      }}
      name="users.0.trust.useCustomName"
      {...{ onSubmit }}
      items={[
        {
          label: `"The ${genericTrustName}" (most common)`,
          value: false
        },
        {
          label: `I want to customize the name of my trust`,
          value: true
        }
      ]}
    />
  );
};

export default TrustNameStep;
