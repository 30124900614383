export const videoTutorials = {
  howToSignYourWill: {
    src: "https://www.loom.com/embed/572478d62b474f3a9cac3cc022426a36?sid=966a10ba-e71e-4650-825e-1ce676b13fc5",
    title: "How to Review and Sign Your Last Will",
    caption:
      "Get a guided step by step walkthrough of your Last Will, and understand your options for signing."
  },
  howToSignYourPOA: {
    src: "https://www.loom.com/embed/beb26f93a3234412866c4a2c956fd695?sid=575c9be5-f96f-444b-9c10-8baad144bb5c",
    title: "How to Review and Sign Your POA",
    caption:
      "Watch this video to learn how to complete your financial Power of Attorney."
  },
  howToSignYourAHCD: {
    src: "https://www.loom.com/embed/c4825af4097d446fa489be4a18202d2c?sid=29ca958e-b64d-4409-9545-ff63ad0062ea",
    title: "How to Review and Complete Your AHCD",
    caption:
      "Walk through a sample Advance Health Care Directive with our member success team."
  },
  hopwJointTrustGiftsWork: {
    src: "https://www.loom.com/embed/307ae1bbd97546d5b58b210c8c254bf1?sid=f1cafe6b-9b81-45be-8155-98a0ac35a5db",
    title: "How Gifts in Your Joint Trust Work",
    caption: "See how gifts flow in your joint trust."
  },
  howSeparateTrustGiftsWork: {
    src: "https://www.loom.com/embed/480097eb6cb6420ebbad1ad19a041df9?sid=59fd87de-6330-414c-a9d0-2839facefca9",
    title: "How Gifts in Your Revocable Living Trust Work",
    caption: "See how gifts flow through your trust."
  },
  howToUseCsvImport: {
    src: "https://www.loom.com/embed/24347934c1c54366bdca25eed9c85b58?sid=9327fdf0-82f9-47cd-93d9-b7150b7c9526",
    title: "Adding Multiple Assets with CSV Import",
    caption: "Import all your assets at once with a .csv import"
  },
  lukeWelcomeVideo: {
    src: "https://www.loom.com/embed/3432769211e14c7d9ad132d6c242af5c?sid=2dc16618-986f-4704-9afc-84183ca484fd",
    title: "Welcome to JIC Estates Advisors",
    caption: "A special welcome from our JIC Estates Co-Founder."
  },
  advisorPortalOverview: {
    src: "https://www.loom.com/embed/1a635ff6827a4eef81ddcf97132dfa68?sid=64fcf719-4472-4b44-9056-639337eccb3d",
    title: "JIC Estates Advisor Portal Overview",
    caption: "Watch the full overview of JIC Estates Advisors."
  },
  planTierOverview: {
    src: "https://www.loom.com/embed/6bde106948b54fef88bed5ef25def13f?sid=0972680b-a3bf-4aba-afd3-0dbe7d4db086",
    title: "Overview of JIC Estates' 3 Plan Tiers",
    caption:
      "Learn more about our Autonomous, Attorney Assist, and JIC Estates Ultimate Pro offerings."
  },
  howToLinkClientAccount: {
    src: "https://www.loom.com/embed/32ed5f1780a4456eb663e918de096269?sid=5ef7791d-ac7f-4d99-875a-6871479439ee",
    title: "How to Link a Client Account",
    caption:
      "There are two ways to link a client account to your advisor account."
  },
  howDocGenWorks: {
    src: "https://www.loom.com/embed/2ea76a88b3694cfe9eae0a0a130b0dd8?sid=d752f48d-5ddd-4f1a-becc-0cdab783f9d4",
    title: "How Document Generation Works",
    caption:
      "Documents created with our autonomous plans are typically available in the members' portal within 30 to 60 seconds after completing the questionnaire."
  },
  howToAccessClientFiles: {
    src: "https://www.loom.com/embed/b083c9086f05489cb955793113087bb7?sid=c4366c73-9271-44dc-a513-9985308b2c72",
    title: "How to Access Client Docs",
    caption:
      "Learn how to quickly reference client documents in the JIC Estates Advisor portal."
  }
};
