/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { lowerCase } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import {
  LastWillPlanValues,
  PowerOfAttorney
} from "@pages/LastWill/types";
import getPoaAgentInfo from "@utils/getPoaAgentInfo";
import { useAdditionalPowersInfoLabels } from "@hooks/useAdditionalPowersInfoLabels";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import {
  PartnerType,
  PlanType
} from "@api/__generated__/globalTypes";
import { numberWithCommas } from "@utils/misc";

import { LAST_WILL_POWER_OF_ATTORNEY_ROUTES } from "../../../LastWill/sections/PowerOfAttorney/consts";
import { POWER_OF_ATTORNEY_ROUTES } from "../main/consts";

export const usePartnerPOAReviewTab = ({
  values,
  partnerType,
  planType = PlanType.LAST_WILL
}: {
  partnerType: PartnerType;
  values: LastWillPlanValues;
  planType?: PlanType;
}): ReviewItem[] => {
  const { formatMessage } = useIntl();
  const {
    users: [, partner]
  } = values;

  const {
    include: partnerInclude,
    agents: partnerAgents,
    successorAgents: partnerSuccessorAgents,
    generalAuthority: partnerGeneralAuthority = false,
    agentPowers: partnerAgentPowers,
    compensation: partnerCompensation = "",
    compensationLimit: partnerCompensationLimit,
    compensationType: partnerCompensationType,
    giftRecipients: partnerGiftRecipients,
    additionalPowers: partnerAdditionalPowers
  } = partner?.poa || ({} as PowerOfAttorney);

  const lowerCasePartnerType = lowerCase(partnerType);

  const additionalPowersInfoLabels = useAdditionalPowersInfoLabels();

  type AdditionalPowersKeys = keyof typeof additionalPowersInfoLabels;

  const partnerMakePOA = useMemo<ReviewItem | undefined>(
    () =>
      partnerInclude
        ? undefined
        : {
            title: formatMessage({
              defaultMessage: "Power of Attorney:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `🚫 You chose not to create a Power of Attorney at this time.`
                })
              ]
            ]
          },
    [formatMessage, planType, partnerInclude]
  );

  const partnerAgentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      partnerAgents && partnerAgents.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Primary Agent:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerDesignateAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateAgent
                  ]),
            items: [...partnerAgents.map(getPoaAgentInfo)]
          }
        : undefined,
    [formatMessage, planType, partnerAgents]
  );

  const partnerSuccessorAgentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      partnerSuccessorAgents && partnerSuccessorAgents.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent
                  ]),
            items: [...partnerSuccessorAgents.map(getPoaAgentInfo)]
          }
        : partnerAgents && partnerAgents.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not nominating a successor agent.`
                })
              ]
            ]
          }
        : undefined,
    [formatMessage, partnerAgents, planType, partnerSuccessorAgents]
  );

  const partnerAgentPowersInfo = useMemo<ReviewItem | undefined>(
    () =>
      // eslint-disable-next-line no-nested-ternary
      partnerGeneralAuthority
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Authority:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerPowers
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowers
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage:
                    "✅ You are granting your agent general authority to act over all common subjects."
                })
              ]
            ]
          }
        : partnerAgentPowers
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Authority:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage:
                    "Your agent's authority to act is limited to the following subjects:"
                })
              ],
              partnerAgentPowers.map((power) => power.toString())
            ]
          }
        : undefined,

    [
      formatMessage,
      partnerGeneralAuthority,
      planType,
      partnerAgentPowers
    ]
  );

  const partnerAdditionalPowersInfo = useMemo<ReviewItem | undefined>(
    () =>
      partnerAdditionalPowers
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Special Authority:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview
                  ]),
            items: [
              Object.entries(partnerAdditionalPowers)
                .filter(([, value]) => Boolean(value))
                .map(([key]) => {
                  if (key in additionalPowersInfoLabels) {
                    const additionalPowersInfoLabelsKey =
                      key as AdditionalPowersKeys;

                    return additionalPowersInfoLabels[
                      additionalPowersInfoLabelsKey
                    ];
                  }

                  return key;
                })
            ]
          }
        : undefined,
    [
      formatMessage,
      planType,
      partnerAdditionalPowers,
      additionalPowersInfoLabels
    ]
  );

  const partnerGiftPowersDetailsInfo = useMemo<
    ReviewItem | undefined
  >(
    () =>
      partnerAdditionalPowers?.makeGifts
        ? {
            title: formatMessage({
              defaultMessage: "Restrictions on Gift Making:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGifts
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGifts
                  ]),
            items: [
              [
                `Permissible Gift Recipients:`,
                `- ${
                  partnerGiftRecipients?.type === "certainPeople"
                    ? /*
                    TODO: We will eventually want to refactor API to support list array
                        partnerGiftRecipients.listArray
                          .map((person: Beneficiary) => person.name)
                          .join(", ")
                    */
                      partnerGiftRecipients.list
                    : partnerGiftRecipients?.type ===
                      "partnerAndChildrenOnly"
                    ? `My ${lowerCasePartnerType}, children, and descendants ONLY`
                    : partnerGiftRecipients?.type === "childrenOnly"
                    ? "My children only"
                    : partnerGiftRecipients?.type
                }`,
                `${
                  partnerGiftRecipients?.type === "Anyone"
                    ? [
                        `${
                          partnerGiftRecipients?.allowToAgent
                            ? "- Includes ability to gift to people my agent supports"
                            : "- Does not include ability to gift to people my agent supports"
                        }`
                      ]
                    : []
                }`
              ],
              [
                `Gift Limit Per Year, Per Individual:`,
                `- ${partnerGiftRecipients?.limitType}
                ${
                  partnerGiftRecipients?.limitType ===
                  "Limit up to a certain amount"
                    ? `: $${numberWithCommas(
                        partnerGiftRecipients?.limitAmount
                      )}`
                    : []
                }`
              ]
            ].filter(Boolean)
          }
        : undefined,
    [
      partnerAdditionalPowers?.makeGifts,
      formatMessage,
      planType,
      partnerGiftRecipients?.type,
      partnerGiftRecipients?.list,
      partnerGiftRecipients?.allowToAgent,
      partnerGiftRecipients?.limitType,
      partnerGiftRecipients?.limitAmount,
      lowerCasePartnerType
    ]
  );

  const partnerCompensationInfo = useMemo<ReviewItem | undefined>(
    () =>
      partnerCompensation
        ? {
            title: formatMessage({
              defaultMessage: "Agent Compensation:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation
                  ]),
            items: [
              [
                `${partnerCompensation}`,
                `${
                  partnerCompensation ===
                  "Specify a compensation amount and reimbursement of expenses"
                    ? [
                        `$${numberWithCommas(
                          partnerCompensationLimit
                        )}`,
                        partnerCompensationType
                      ].join(" ")
                    : ""
                }`
              ].filter(Boolean)
            ]
          }
        : undefined,
    [
      partnerCompensation,
      formatMessage,
      planType,
      partnerCompensationLimit,
      partnerCompensationType
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        partnerMakePOA,
        partnerAgentsInfo,
        partnerSuccessorAgentsInfo,
        partnerAgentPowersInfo,
        partnerAdditionalPowersInfo,
        partnerGiftPowersDetailsInfo,
        partnerCompensationInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      partnerMakePOA,
      partnerAgentsInfo,
      partnerSuccessorAgentsInfo,
      partnerAgentPowersInfo,
      partnerAdditionalPowersInfo,
      partnerGiftPowersDetailsInfo,
      partnerCompensationInfo
    ]
  );

  return items;
};
