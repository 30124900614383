import { Box, Typography, useTheme } from "@mui/material";

import { ErrorProps } from "./types";

const Error: React.FC<ErrorProps> = ({ children, onAction }) => {
  const theme = useTheme();

  return (
    <Box>
      {children ?? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <Typography align="center">
            We were unable to process your request.
            <br />
            Please review the data you are submitting for errors and
            then{" "}
            <Typography
              component="span"
              sx={{
                color: theme.palette.primary.main,
                cursor: "Pointer"
              }}
              onClick={() => onAction?.()}
            >
              try again.
            </Typography>
            <br />
            If the issue persists, please contact JIC Member Success
            for assistance.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Error;
