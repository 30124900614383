import { noop } from "lodash";
import { createContext, useContext } from "react";

import { PlanTodosState } from "./types";

const PlanTodosContext = createContext<PlanTodosState>({
  todos: [],
  loading: false,
  togglePlanTodoStatus: noop,
  updatePlanTodoTitle: noop,
  deletePlanTodo: noop,
  createPlanTodo: noop
});

const usePlanTodosContext = (): PlanTodosState =>
  useContext(PlanTodosContext);

export { PlanTodosContext, usePlanTodosContext };
