import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const RestingPlaceStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `After you pass, what would you like as a final resting place for your body?`,
      subText: `I request that my body be...`
    }}
    nullable
    name="users.0.finalArrangements.bodyRestingPlace"
    items={[
      {
        label: "cremated",
        value: "cremated"
      },
      {
        label: "donated to science",
        value: "donated to science"
      },
      {
        label: "buried",
        value: "buried"
      },
      {
        label: "Skip this question",
        value: null
      }
    ]}
  />
);

export default RestingPlaceStep;
