import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

import { useGetMeQuery } from "@api/me/hooks";
import { UserRole } from "@api/__generated__/globalTypes";

import { UserContext } from "./context";

export const { Provider: UserProvider } = UserContext;

export const UserContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [isInitialUserLoading, setIsInitialUserLoading] =
    useState(true);
  const [cognitoUser, setCognitoUser] = useState<
    CognitoUser | undefined
  >(undefined);

  const { data, refetch } = useGetMeQuery({
    skip: !cognitoUser
  });

  const refreshUserData = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const userContextValue = useMemo(
    () => ({
      cognitoUser,
      setCognitoUser,
      user: data?.me || undefined,
      isAdvisor: data?.me?.role === UserRole.FINANCIAL_ADVISOR,
      refreshUserData
    }),
    [cognitoUser, data?.me, refreshUserData]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setCognitoUser(await Auth.currentUserPoolUser());
      } catch {
        setCognitoUser(undefined);
      } finally {
        setIsInitialUserLoading(false);
      }
    })();
  }, []);

  return (
    <UserProvider value={userContextValue}>
      {isInitialUserLoading ? null : children}
    </UserProvider>
  );
};
