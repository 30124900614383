import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { UnpackNestedValue } from "react-hook-form";
import get from "lodash/get";
import { unflatten } from "flat";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  ArrayFormValues,
  UnknownRecord,
  UseArrayFormArchetypeReturnType
} from "./types";

const useArrayFormArchetype = <T extends UnknownRecord>(
  name: string,
  singleItemSchema: yup.SchemaOf<T>,
  onFormSubmit?: (data: ArrayFormValues<T>) => Promise<boolean> | void
): UseArrayFormArchetypeReturnType<T> => {
  const { goBack, saveAndContinue, values } = usePlanForm();
  const schema = useMemo(
    () => makeYupSchema(singleItemSchema, name, true),
    [singleItemSchema, name]
  );

  const controls = useFormWithYup<ArrayFormValues<T>>(schema, {
    defaultValues: unflatten({
      [name]: get(values, name, [])
    })
  });

  const onSubmit = useCallback(
    async (data: UnpackNestedValue<ArrayFormValues<T>>) => {
      const result =
        (await onFormSubmit?.(data as ArrayFormValues<T>)) ?? true;

      if (!result) {
        return;
      }

      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useArrayFormArchetype };
