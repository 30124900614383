import {
  Box,
  Link,
  Button,
  Typography,
  useTheme
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { ExternalLinks } from "@setup/consts/articleLinks";

interface NeedHelpProps {
  message?: string;
  message2?: string;
}

const BookAMeetingMessage: React.FC<NeedHelpProps> = ({
  message,
  message2
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}
    >
      <Typography
        color={theme.palette.grey["500"]}
        sx={{
          fontSize: "1rem",
          fontWeight: "500"
        }}
      >
        {message && (
          <FormattedMessage
            defaultMessage="{message}"
            values={{ message }}
          />
        )}
        {!message && (
          <FormattedMessage defaultMessage="Need something else?" />
        )}
      </Typography>
      {message2 && (
        <Typography>
          <FormattedMessage
            defaultMessage="{message2}"
            values={{ message2 }}
          />
        </Typography>
      )}
      <Link sx={{ mt: "1.25rem" }} href={ExternalLinks.lukeCalendly}>
        <Button variant="contained" color="primary">
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontWeight: "500"
            }}
          >
            <FormattedMessage defaultMessage="BOOK MEETING" />
          </Typography>
        </Button>
      </Link>
    </Box>
  );
};

export default BookAMeetingMessage;
