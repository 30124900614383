import noop from "lodash/noop";
import { createContext, useContext } from "react";

import { PlanType } from "@api/__generated__/globalTypes";
import { Person } from "@api/planDrafts/__generated__/Person";

import { PlanFormState } from "./types";

const PlanFormContext = createContext<PlanFormState<any>>({
  values: {},
  isBackAvailable: false,
  goBack: noop,
  setValues: noop,
  saveAndContinue: noop,
  iteration: undefined,
  accessiblePaths: {},
  type: PlanType.LAST_WILL,
  people: [] as Person[],
  sendMarketingData: (_email: string, _klaviyoListId?: string) =>
    Promise.resolve(true)
});

const usePlanForm = <
  T extends Record<string, unknown>
>(): PlanFormState<T> => useContext(PlanFormContext);

export { PlanFormContext, usePlanForm };
