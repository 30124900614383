/* eslint-disable react/no-unescaped-entities */
import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { BlogLinks } from "@setup/consts/articleLinks";

const SetAsideFundsStep: React.FC = () => {
  const { setValue: setAsideFundsAmount } = useFormField(
    "asideFundsAmount"
  );

  const onSubmit = useCallback(
    ({ areAsideFundsSet }: SingleSelectFormValues) => {
      if (parseBoolean(areAsideFundsSet) === false) {
        setAsideFundsAmount("0");
      }
    },
    [setAsideFundsAmount]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to set aside funds to care for your pets in the event of your prolonged inability to care for them yourself?`,
        subText: `This might include your death or injury that impairs your ability to care for your pets.`,
        moreInfoModalProps: {
          title: "How Much Money Should You Leave for Your Pet?",
          togglerLabel: "Learn more",
          content: (
            <p>
              According to Synchrony Financial's "Lifetime of Care"
              study, nearly half of dog parents admit to
              underestimating the cost of providing for their pet.
              <br />
              <br />
              As a back-of-the-envelope approach for estimating how
              much money to leave for your pet dog or cat:
              <ul>
                <li>
                  Multiply $2,000 by the expected remaining life of
                  your dog
                </li>
                <li>
                  Multiply $1,700 by the expected remaining life of
                  your cat
                </li>
              </ul>
              For a more comprehensive discusion on how much money you
              should leave for your pet along with other methodologies
              to consider, check out our blog using the link below.
            </p>
          ),
          closeLabel: "Got it",
          actions: [
            {
              label: "Go to Article",
              href: BlogLinks.howMuchToLeavePet
            }
          ]
        }
      }}
      {...{ onSubmit }}
      name="areAsideFundsSet"
    />
  );
};

export default SetAsideFundsStep;
