import { useMemo } from "react";
import * as yup from "yup";

export const useInviteClientMemberSchema = () =>
  useMemo(
    () =>
      yup.object().shape({
        fullName: yup.string().required(),
        email: yup.string().email().required(),
        recommendedPlan: yup.string().nullable(true),
        message: yup.string()
      }),
    []
  );
