/* eslint-disable no-irregular-whitespace */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePetBreedsReptile = (): AutocompleteBaseOption[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({
          defaultMessage: `Abaco Island Boa`
        }),
        value: "Abaco Island Boa"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Agama` }),
        value: "Agama"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Alabama Red-Bellied Turtle`
        }),
        value: "Alabama Red-Bellied Turtle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Amazon Tree Boa`
        }),
        value: "Amazon Tree Boa"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Alligator`
        }),
        value: "American Alligator"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Crocodile`
        }),
        value: "American Crocodile"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Anole` }),
        value: "Anole"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Ball Python` }),
        value: "Ball Python"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Bamboo Viper` }),
        value: "Bamboo Viper"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bearded Dragon`
        }),
        value: "Bearded Dragon"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Blue-Tongued Skink`
        }),
        value: "Blue-Tongued Skink"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Boa` }),
        value: "Boa"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Boa Constrictor`
        }),
        value: "Boa Constrictor"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Box Turtle` }),
        value: "Box Turtle"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Brown Anole` }),
        value: "Brown Anole"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Brown Snake` }),
        value: "Brown Snake"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Carpet Python`
        }),
        value: "Carpet Python"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Chameleon` }),
        value: "Chameleon"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Chinese Water Dragon`
        }),
        value: "Chinese Water Dragon"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Coal Skink` }),
        value: "Coal Skink"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Cooter Turtles`
        }),
        value: "Cooter Turtles"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Corn Snake` }),
        value: "Corn Snake"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Crested Gecko`
        }),
        value: "Crested Gecko"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Crocodile` }),
        value: "Crocodile"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Desert Tortoise`
        }),
        value: "Desert Tortoise"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Diamondback Terrapin`
        }),
        value: "Diamondback Terrapin"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Eastern Box Turtle`
        }),
        value: "Eastern Box Turtle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Eyelash Viper`
        }),
        value: "Eyelash Viper"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Frog` }),
        value: "Frog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Garter Snake` }),
        value: "Garter Snake"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Gecko` }),
        value: "Gecko"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Green Anole` }),
        value: "Green Anole"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Green Iguana` }),
        value: "Green Iguana"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Hieroglyphic River Cooter`
        }),
        value: "Hieroglyphic River Cooter"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Iguana` }),
        value: "Iguana"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Kingsnake` }),
        value: "Kingsnake"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Leopard Gecko`
        }),
        value: "Leopard Gecko"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Lizard` }),
        value: "Lizard"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Northern Water Snake`
        }),
        value: "Northern Water Snake"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Painted Turtle`
        }),
        value: "Painted Turtle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Panther Chameleon`
        }),
        value: "Panther Chameleon"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Rainbow Boa` }),
        value: "Rainbow Boa"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Red Tail Boa` }),
        value: "Red Tail Boa"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Red-Eared Slider Turtle`
        }),
        value: "Red-Eared Slider Turtle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Red-Footed Tortoise`
        }),
        value: "Red-Footed Tortoise"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Rosy Boa` }),
        value: "Rosy Boa"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Russian Tortoise`
        }),
        value: "Russian Tortoise"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Savannah Monitor`
        }),
        value: "Savannah Monitor"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Snake` }),
        value: "Snake"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Three lined skink / Blue Tailed Skink`
        }),
        value: "Three lined skink / Blue Tailed Skink"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Tortoise` }),
        value: "Tortoise"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Turtle` }),
        value: "Turtle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Veiled Chameleon`
        }),
        value: "Veiled Chameleon"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Water Dragon` }),
        value: "Water Dragon"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Western Pond Turtle`
        }),
        value: "Western Pond Turtle"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Wood Turtle` }),
        value: "Wood Turtle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Yellow Anaconda`
        }),
        value: "Yellow Anaconda"
      }
    ],
    [intl]
  );

  return items;
};
