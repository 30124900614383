import React from "react";
import noop from "lodash/noop";

import { DEFAULT_PLAN_TYPE } from "@setup/consts/misc";

import { PlanTypeContextValue } from "./types";

export const PlanTypeContext =
  React.createContext<PlanTypeContextValue>({
    planTypeEnum: DEFAULT_PLAN_TYPE,
    setPlanTypeEnum: noop
  });
