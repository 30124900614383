import WorkIcon from "@mui/icons-material/Work";
import SendIcon from "@mui/icons-material/Send";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useFinancialAdvisorMetricsQuery } from "@api/financialAdvisor";
import { FinancialAdvisorMetricsQuery_financialAdvisorMetrics_draftPlansData as DraftPlanInfoItem } from "@api/financialAdvisor/__generated__/FinancialAdvisorMetricsQuery";
import { Invitation } from "@api/invitation/__generated__/Invitation";

import { IClientInviteMetric } from "./types";

const useClientInviteMetrics = () => {
  const { formatMessage } = useIntl();
  const { data, loading, refetch } =
    useFinancialAdvisorMetricsQuery();

  const draftPlans =
    data?.financialAdvisorMetrics?.draftPlansData?.filter(
      (draft): draft is DraftPlanInfoItem => draft !== null
    ) ?? [];

  const invitations =
    data?.financialAdvisorMetrics?.invitationsSentData?.filter(
      (invite): invite is Invitation => invite !== null
    ) ?? [];

  const clientInviteMetricItems = useMemo<IClientInviteMetric[]>(
    () => [
      {
        icon: <AutoAwesomeIcon />,
        value: data?.financialAdvisorMetrics?.completedPlans ?? 0,
        label: formatMessage({
          defaultMessage: "Plans Completed"
        })
      },
      {
        icon: <WorkIcon />,
        value: data?.financialAdvisorMetrics?.draftPlans ?? 0,
        label: formatMessage({
          defaultMessage: "Plans In Progress"
        })
      },
      {
        icon: <SendIcon />,
        value: data?.financialAdvisorMetrics?.invitationsSent ?? 0,
        label: formatMessage({
          defaultMessage: "Invites Pending"
        })
      }
    ],
    [formatMessage, data]
  );

  return {
    metrics: clientInviteMetricItems,
    invitationsData: invitations,
    draftPlansData: draftPlans,
    loading,
    refetch
  };
};

export default useClientInviteMetrics;
