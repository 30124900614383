import { useIntl } from "react-intl";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import { usePlanForm } from "@providers/planForm/context";

import { InterstitialArchetypeProps } from "./types";

const InterstitialArchetype: React.FC<InterstitialArchetypeProps> = ({
  stepLayout
}) => {
  const intl = useIntl();
  const { goBack, saveAndContinue } = usePlanForm();

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage: "Keep it up! You're doing great!"
      })}
      {...stepLayout}
    >
      <FormStepFooter
        prevButtonProps={{ type: "button", onClick: goBack }}
        nextButtonProps={{
          type: "button",
          onClick: () => saveAndContinue(),
          label: intl.formatMessage({ defaultMessage: "Continue" })
        }}
      />
    </StepLayout>
  );
};

export default InterstitialArchetype;
