import { Theme } from "@mui/material";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const breakpoints = createBreakpoints({});

const typography: Partial<Theme["typography"]> = {
  fontSize: 18,
  [breakpoints.up("md")]: {
    fontSize: 20
  },
  h1: {
    fontFamily: '"Inter", sans-serif',
    fontSize: "2rem",
    fontWeight: "600",
    [breakpoints.up("md")]: {
      fontSize: "2.5rem"
    }
  },
  h2: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: "1.375rem",
    fontWeight: "700",
    [breakpoints.up("md")]: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "2.0rem",
      fontWeight: "600"
    }
  },
  h3: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: "1.25rem",
    fontWeight: "500",
    [breakpoints.up("md")]: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "1.75rem",
      fontWeight: "600"
    }
  },
  h5: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: "1.125rem",
    [breakpoints.up("md")]: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "1.375rem",
      fontWeight: "600"
    }
  },
  body1: {
    fontSize: "1.125rem",
    fontWeight: "400",
    [breakpoints.up("md")]: {
      fontSize: "1.25rem"
    }
  },
  body2: {
    fontSize: "1.125rem",
    fontWeight: "400"
  },
  caption: {
    fontSize: "0.938rem",
    fontWeight: "400"
  },
  button: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: "0.875rem",
    fontWeight: "500",
    lineHeight: "1.57",
    textTransform: "uppercase"
  }
};

export default typography;
