import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ReadMoreButton = styled(Typography)`
  display: flex;
  align-items: left;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: "1.25rem";
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
`;
