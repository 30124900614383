import { get } from "lodash";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { ReviewCardProps } from "@components/ReviewCard/types";
import { useAssetHumanizedTitle } from "@hooks/useAssetHumanizedTitle";
import { usePersonalPropertyTypeIcons } from "@pages/EstatePlanView/Partials/hooks";
import { Asset, AssetList, Beneficiary } from "@pages/LastWill/types";
import { numberWithCommas } from "@utils/misc";
import { AssetType } from "@api/__generated__/globalTypes";
import { ASSET_TYPE_ICONS } from "@setup/consts/assetTypeIcons";
import EditAssetModal from "@components/QuestionnaireAssetModal/EditAssetModal";
import { EditAssetButton } from "@pages/EstatePlanView/Partials/Tabs/AssetsTab/styles";
import isAssetOutsideLastWill from "@pages/EstatePlanView/Partials/Tabs/AssetsTab/utils/isAssetOutsideLastWill";

export const getAssetBeneficiaryInfo = (
  beneficiaries: Beneficiary[]
) => [
  "🎁  This asset is a specific gift that will be distributed based on the following schedule:",
  ...beneficiaries.map(
    (beneficiary) =>
      `${beneficiary.percentageValue}% to ${
        beneficiary.name ?? beneficiary?.charity?.name
      }`
  )
];

export const useSpecificGiftsAssets = (
  assetList: AssetList | undefined
) =>
  useMemo(() => {
    const specificGiftAssets: Asset[] = [];
    const notSpecificGiftAssets: Asset[] = [];

    // Check personalProperty assets

    if (assetList?.personalProperty) {
      const personalPropertyAssets =
        assetList.personalProperty.filter((asset) => asset);

      specificGiftAssets.push(
        ...personalPropertyAssets.filter(
          (asset) =>
            asset.isGift &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
        )
      );
      notSpecificGiftAssets.push(
        ...personalPropertyAssets.filter(
          (asset) =>
            !asset.isGift ||
            !asset.beneficiaries ||
            asset.beneficiaries.length === 0
        )
      );
    }

    // Check realEstates assets
    if (assetList?.realEstates) {
      const realEstateAssets = assetList.realEstates.filter(
        (asset) => asset
      );

      specificGiftAssets.push(
        ...realEstateAssets.filter(
          (asset) =>
            asset.isGift &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
        )
      );
      notSpecificGiftAssets.push(
        ...realEstateAssets.filter(
          (asset) =>
            !asset.isGift ||
            !asset.beneficiaries ||
            asset.beneficiaries.length === 0
        )
      );
    }

    // Check financialAccounts assets
    if (assetList?.financialAccounts) {
      const financialAccountAssets =
        assetList.financialAccounts.filter((asset) => asset);

      specificGiftAssets.push(
        ...financialAccountAssets.filter(
          (asset) =>
            asset.isGift &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
        )
      );
      notSpecificGiftAssets.push(
        ...financialAccountAssets.filter(
          (asset) =>
            !asset.isGift ||
            !asset.beneficiaries ||
            asset.beneficiaries.length === 0
        )
      );
    }

    // Check businessInterests assets
    if (assetList?.businessInterests) {
      const businessInterestAssets =
        assetList.businessInterests.filter((asset) => asset);

      specificGiftAssets.push(
        ...businessInterestAssets.filter(
          (asset) =>
            asset.isGift &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
        )
      );
      notSpecificGiftAssets.push(
        ...businessInterestAssets.filter(
          (asset) =>
            !asset.isGift ||
            !asset.beneficiaries ||
            asset.beneficiaries.length === 0
        )
      );
    }

    // Check lifeInsurancePolicies assets
    /*
    if (assetList?.lifeInsurancePolicies) {
      const lifeInsurancePolicies =
        assetList.lifeInsurancePolicies.filter((asset) => asset);

      fundedAssets.push(
        ...lifeInsurancePolicies.filter((asset) => asset.isFunded)
      );
      notFundedAssets.push(
        ...lifeInsurancePolicies.filter((asset) => !asset.isFunded)
      );
    }
    */

    // Check otherAssets assets
    if (assetList?.otherAssets) {
      const otherAssets = assetList.otherAssets.filter(
        (asset) => asset
      );

      specificGiftAssets.push(
        ...otherAssets.filter(
          (asset) =>
            asset.isGift &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
        )
      );
      notSpecificGiftAssets.push(
        ...otherAssets.filter(
          (asset) =>
            !asset.isGift ||
            !asset.beneficiaries ||
            asset.beneficiaries.length === 0
        )
      );
    }

    return {
      specificGiftAssets,
      notSpecificGiftAssets
    };
  }, [assetList]);

export const useGiftReviewCardItems = (
  assets: Asset[],
  userIndex: string
): ReviewCardProps[] => {
  const humanizedTitles = useAssetHumanizedTitle();
  const personalPropertyIcons = usePersonalPropertyTypeIcons();

  return useMemo(
    () =>
      assets.map<ReviewCardProps>((asset, idx): ReviewCardProps => {
        const assetNickname =
          asset.type === AssetType.REAL_ESTATE
            ? asset.address?.address1
            : asset.name;
        const assetType = get(humanizedTitles, asset.type || "", "");
        const isPersonalProperty =
          asset.type === AssetType.PERSONAL_PROPERTY;
        const showSubTypeDetail =
          asset.type === AssetType.PERSONAL_PROPERTY ||
          asset.type === AssetType.FINANCIAL_ACCOUNT;

        const icon = isPersonalProperty
          ? get(personalPropertyIcons, asset.subType || "", undefined)
          : get(ASSET_TYPE_ICONS, asset.type || "", undefined);

        // eslint-disable-next-line no-nested-ternary
        const description = showSubTypeDetail
          ? `${assetType} > ${asset.subType}`
          : assetNickname
          ? assetType
          : undefined;

        return {
          href: "",
          icon,
          title: assetNickname || assetType,
          description,
          items: [
            [
              `💰 Estimated Value: ${
                asset.grossValue
                  ? `$${numberWithCommas(asset.grossValue)}`
                  : "Not Specified"
              }`
            ],
            asset.isGift &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
              ? getAssetBeneficiaryInfo(asset.beneficiaries || [])
              : [
                  "🎁  This asset will be distributed with your Residuary Estate."
                ]
          ].filter((item) => item.length > 0),
          editComponent: (
            <EditAssetModal
              trigger={({ onClick }) => (
                <EditAssetButton {...{ onClick }}>
                  <FormattedMessage defaultMessage="Edit" />
                </EditAssetButton>
              )}
              {...{
                asset,
                userIndex,
                targetIndex: idx,
                showIsFunded: false
              }}
            />
          )
        };
      }),
    [assets, userIndex, humanizedTitles, personalPropertyIcons]
  );
};
