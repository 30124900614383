import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useMemo } from "react";

import Modal from "@components/Modal";

import AssetForm from "../AssetForm";
import { AssetFormValues } from "../AssetForm/types";

import { useEditAssetModal } from "./hooks";
import { EditAssetModalProps } from "./types";
import mapAssetToFormValues from "./utils/mapAssetToFormValues";

const EditAssetModal: React.FC<EditAssetModalProps> = ({
  trigger: Trigger,
  asset,
  showIsFunded = false
}) => {
  const intl = useIntl();
  const {
    isOpen,
    canEdit,
    loading,
    toggleOn,
    toggleOff,
    onSubmit,
    onDeleteAsset,
    deleteAssetLoading
  } = useEditAssetModal(asset.id);

  const defaultValues = useMemo<AssetFormValues>(
    () => mapAssetToFormValues(asset),
    [asset]
  );

  return (
    <Box>
      {canEdit && <Trigger onClick={toggleOn} />}
      <Modal
        fullWidth
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Add Asset"
        })}
        content={
          <AssetForm
            editMode
            showIsFunded={showIsFunded}
            {...{
              onSubmit,
              onDeleteAsset,
              loading: loading || deleteAssetLoading,
              defaultValues
            }}
          />
        }
      />
    </Box>
  );
};

export default EditAssetModal;
