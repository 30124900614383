import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominateAgentStep: React.FC = () => {
  const { setValue: setAgentValues } = useFormField(
    "users.0.healthcare.agents"
  );
  const { setValue: setBackupAgentValues } = useFormField(
    "users.0.healthcare.backupAgents"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const authorizeAgents = get(
        data,
        "users.0.healthcare.authorizeAgents"
      );

      if (!parseBoolean(authorizeAgents)) {
        setAgentValues([]);
        setBackupAgentValues([]);
      }
    },
    [setAgentValues, setBackupAgentValues]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to authorize someone to act as your healthcare agent?`,
        subText: `A healthcare agent is a trusted individual who is authorized to access your medical information and make certain healthcare decisions on your behalf, should you be unable to do so personally.`
      }}
      {...{ onSubmit }}
      name="users.0.healthcare.authorizeAgents"
    />
  );
};

export default NominateAgentStep;
