import {
  Box,
  Typography,
  Tooltip,
  Card,
  Avatar,
  Link
} from "@mui/material";
import React from "react";

import { UserTooltipProps } from "./types";

import theme from "@setup/theme";

const UserTooltip: React.FC<UserTooltipProps> = ({
  name = "",
  email = "",
  child,
  path
}) => {
  const initial = name.charAt(0);

  return (
    <Tooltip
      placement="top"
      enterDelay={500}
      title={
        <Card
          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0.625rem",
            minWidth: "21.5rem"
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            gap="1rem"
            alignItems="center"
          >
            <Avatar>{initial}</Avatar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5">{name}</Typography>
              <Typography variant="caption">{email}</Typography>
              {path && <Link href={path}>Go to Plan</Link>}
            </Box>
          </Box>
        </Card>
      }
    >
      {child}
    </Tooltip>
  );
};

export default UserTooltip;
