import { gql } from "@apollo/client";

export const GET_DRAFT_DOCUMENTS = gql`
  query GetDraftDocuments($token: String!) {
    planDraftDocuments(token: $token) {
      partnerDocuments
      primaryDrafterDocuments
    }
  }
`;
