import { createTheme } from "@mui/material";
import { Shadows } from "@mui/material/styles/shadows";

import components from "./partials/components";
import palette from "./partials/palette";
import typography from "./partials/typography";

const theme = createTheme(
  {
    palette,
    typography,
    components,
    shadows: Array(25).fill(
      "0px 1px 0.5rem rgba(0, 0, 0, 0.15)"
    ) as Shadows
  },
  {
    breakpoints: {
      values: { sm: 736 }
    }
  }
);

const providerColors = {
  GOOGLE: "#4285F4",
  FACEBOOK: "#4267b2",
  AMAZON: "#ff9900"
};

export default theme;
export { providerColors };
