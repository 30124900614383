import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { BlogLinks } from "@setup/consts/articleLinks";

const GeneralGuidanceStep: React.FC = () => (
  <SingleSelectArchetype
    nullable
    name="users.0.healthcare.preferences.generalGuidance"
    stepLayout={{
      title: `As general guidance for your healthcare agent, which of the following best captures your healthcare preferences?`,
      subText: `Click "Learn more" to learn more about the difference between these options.`,
      moreInfoModalProps: {
        title: "Providing General Guidance to Your Agent",
        togglerLabel: "Learn more.",
        content: (
          <p>
            Select{" "}
            <strong>&quot;receive care in all cases&quot;</strong> if
            you want to continue to pursue all possible medical
            interventions to maintain or prolong life regardless of
            the underlying circumstances or potential outcomes.
            <br />
            <br />
            By selecting{" "}
            <strong>
              &quot;receive care if expected benefits outweigh the
              burdens&quot;
            </strong>
            , you instruct your agent to pursue treatment if he or she
            believes, after discussions with your medical team and
            careful review of the circumstances, that the likely
            benefits from the treatment merit the potential adverse
            effects or discomfort (if any) in pursuing it.
            <br />
            <br />
            Select{" "}
            <strong>
              &quot;receive care only if it will improve my
              condition,&quot;
            </strong>{" "}
            if you want your healthcare agent to authorize care only
            if that care is expected to improve or resolve your
            underlying health issue. For example, if you are diagnosed
            with a serious disease with low success rates of available
            treatments, the healthcare agent may decide against
            pursuing aggressive treatments that are unlikely to result
            in a substantial improvement and instead focus on
            palliative care measures.
            <br />
            <br />
            At the end of the day, making healthcare decisions based
            on general guidance will never be totally black and white.
            That&apos;s why the most important decision that you can
            make in your advance healthcare directive is designating a
            healthcare agent who knows you and your values well. For a
            deeper discussion on these topics, check out our Guide to
            Advance Healthcare Directives in the JIC Estates resource
            library.
          </p>
        ),
        actions: [
          {
            label: "Go to Resource Library",
            href: BlogLinks.guideToAHCD
          }
        ]
      }
    }}
    items={[
      {
        label: "Receive care in all cases",
        value: "receive care in all cases"
      },
      {
        label:
          "Receive care if expected benefits outweigh the expected burdens",
        value:
          "receive care if expected benefits outweigh the expected burdens"
      },
      {
        label: "Receive care only if it will improve my condition",
        value: "receive care only if it will improve my condition"
      },
      {
        label: "Skip this question",
        value: null
      }
    ]}
  />
);

export default GeneralGuidanceStep;
