import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import AdvisorWelcomeGuide from "./AdvisorDashTools/WelcomeGuide";
import { AdvisorWelcomeProps } from "./AdvisorDashTools/types";

const AdvisorTitleContent: React.FC<AdvisorWelcomeProps> = ({
  user
}) => {
  const theme = useTheme();

  return (
    <Box margin={theme.spacing(2)}>
      <Typography variant="h1" textAlign="center" mb="2.25rem">
        JIC Estates Advisors
      </Typography>
      <AdvisorWelcomeGuide user={user} />
    </Box>
  );
};

export default AdvisorTitleContent;
