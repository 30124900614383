import { useCallback } from "react";
import get from "lodash/get";

import { ExternalLinks } from "@setup/consts/articleLinks";
import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";

const OrganDonationStep: React.FC = () => {
  const { setValue: setOrganDonationList } = useFormField(
    "users.0.healthcare.preferences.specifiedOrgans"
  );
  const { setValue: setOrganDonationPurposes } = useFormField(
    "users.0.healthcare.preferences.organDonationPurposes"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const organDonation = get(
        data,
        "users.0.healthcare.preferences.organDonation"
      );

      if (
        organDonation !==
        "Give only specific organs, tissues, or parts"
      ) {
        setOrganDonationList([]);
      }
      if (
        organDonation === "No. Do not donate any organs" ||
        organDonation === null
      ) {
        setOrganDonationPurposes([]);
      }
    },
    [setOrganDonationPurposes, setOrganDonationList]
  );

  return (
    <SingleSelectArchetype
      nullable
      name="users.0.healthcare.preferences.organDonation"
      stepLayout={{
        title: `Upon your death, do you want to donate your organs?`,
        subText: `Learn more about organ donation`,
        moreInfoModalProps: {
          title: "Organ Donation",
          togglerLabel: "Learn more",
          content: (
            <p>
              Declaring your willingness to donate your organs in your
              living will is one of several ways that you may become
              an organ donor, and potentially, save a life.
              <br />
              <br />
              Nearly 170 million Americans are already registered as
              organ donors, but only 3 in 1000 deaths accommodate the
              organ donation process. With more than 100,000 patients
              on the national transplant waiting list, every
              additional donor registration may help.
              <br />
              <br />
              For a more comprehensive discussion on the benefits and
              process of organ donation, please visit organdonor.gov
              using the link below.
            </p>
          ),
          actions: [
            {
              label: "Visit organdonor.gov",
              href: ExternalLinks.organDonor
            }
          ],
          closeLabel: "Got it"
        }
      }}
      items={[
        {
          label: "No. Do not donate any organs",
          value: "No. Do not donate any organs"
        },
        {
          label: "Give any needed organs, tissues, or parts",
          value: "Give any needed organs, tissues, or parts"
        },
        {
          label: "Give only specific organs, tissues, or parts",
          value: "Give only specific organs, tissues, or parts"
        },
        {
          label: "Skip this question",
          value: null
        }
      ]}
      {...{ onSubmit }}
    />
  );
};

export default OrganDonationStep;
