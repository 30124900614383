import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPreferenceConfirmationStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);
  const { value: copyPreferences } = useFormField(
    `users.1.healthcare.preferences.copy`
  );

  if (copyPreferences) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `You got it. We'll record those same preferences for ${partnerFirstName}.`
        }}
      />
    );
  }

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Everyone's a little different. Let's make sure that we get ${partnerFirstName}'s medical preferences just right.`
      }}
    />
  );
};

export default PartnerPreferenceConfirmationStep;
