import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import FormAutocomplete from "../FormAutocomplete";
import FormInput from "../FormInput";

import { useCharities } from "./hooks";
import {
  FormCharitiesAutocompleteOption,
  FormCharitiesAutocompleteProps
} from "./types";

const FormCharitiesAutocomplete: React.FC<
  FormCharitiesAutocompleteProps
> = ({ name, beneficiaryPrefix, forDollarValue = false }) => {
  const intl = useIntl();
  const {
    items,
    loading,
    open,
    setOpen,
    value,
    handleOpen,
    handleInputChange,
    handleChange
  } = useCharities(name);

  return (
    <Stack spacing={4}>
      <FormAutocomplete<FormCharitiesAutocompleteOption>
        name={`${name}.name`}
        textFieldProps={{
          label: intl.formatMessage({
            defaultMessage: "Name of Charity"
          })
        }}
        options={items || []}
        filterOptions={(option): FormCharitiesAutocompleteOption[] =>
          option
        }
        renderOption={(
          props,
          { label, address, ein }
        ): JSX.Element => (
          <Box component="li" {...props} key={props.id}>
            <Typography
              variant="body2"
              fontWeight={600}
              color="primary"
            >
              {label}
            </Typography>
            <Typography component="p" variant="caption" pl={1}>
              <FormattedMessage
                defaultMessage="EIN {ein}"
                values={{ ein }}
              />{" "}
              <br />
              {address}
            </Typography>
          </Box>
        )}
        onOpen={handleOpen}
        onClose={(): void => setOpen(false)}
        inputValue={value}
        onInputChange={(_, newValue): void =>
          handleInputChange(newValue)
        }
        onChange={(_, fieldValue): void => handleChange(fieldValue)}
        {...{ loading, open }}
      />
      {forDollarValue ? (
        <FormInput
          name={`${beneficiaryPrefix ?? name}.dollarValue`}
          label="Dollar Value ($USD)"
          type="number"
          InputProps={{
            startAdornment: `$`,
            type: "number"
          }}
        />
      ) : (
        <FormInput
          name={`${beneficiaryPrefix ?? name}.percentageValue`}
          label={intl.formatMessage({
            defaultMessage: "% Ownership You Are Gifting"
          })}
          type="number"
          valuePrefix="%"
        />
      )}

      <FormInput
        name={`${name}.ein`}
        label={intl.formatMessage({
          defaultMessage: "EIN of Charity"
        })}
        disabled
      />
      <FormInput
        name={`${name}.address`}
        label={intl.formatMessage({
          defaultMessage: "Address of Charity"
        })}
        disabled
      />
    </Stack>
  );
};

export default FormCharitiesAutocomplete;
