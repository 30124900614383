import {
  Typography,
  Box,
  useTheme,
  CircularProgress
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { FormattedMessage } from "react-intl";

import { MembershipStatus } from "@api/__generated__/globalTypes";
import { useUpdateMembership } from "@api/memberships";
import Error from "@components/Error";
import { MembershipRole } from "@setup/enums";

import * as Styled from "../../styles";
import { roleDescription } from "../../consts";

import ChangePermission from "./ChangePermission";
import { PeopleWithAccessListProps } from "./types";

const PeopleWithAccessList = ({
  plan
}: PeopleWithAccessListProps) => {
  const acceptedPeopleList = plan?.memberships?.filter(
    (membership) => membership?.status !== MembershipStatus.BLOCKED
  );

  const theme = useTheme();
  const [_, { loading, error, reset }] = useUpdateMembership();

  if (loading) {
    return (
      <Styled.LoadingWrapper>
        <CircularProgress />
      </Styled.LoadingWrapper>
    );
  }
  if (error) {
    return <Error onAction={reset} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Box
        display="flex"
        gap="0.4rem"
        alignItems="center"
        sx={{
          marginTop: "2.5rem"
        }}
      >
        <LockOpenIcon />
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.375rem"
          }}
        >
          <FormattedMessage defaultMessage="PEOPLE WHO HAVE ACCESS TO YOUR PLAN" />
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          marginTop: ".5rem"
        }}
      >
        {(acceptedPeopleList || [])?.map((person) => (
          <Box
            key={person?.id}
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Box>
              <Typography>
                {!person?.user?.fullName?.includes("undefined")
                  ? person?.user?.fullName
                  : plan?.primaryDrafter?.fullName}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.disabled
                }}
              >
                {person?.user?.email
                  ? person?.user?.email
                  : plan?.primaryDrafter?.fullName}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.disabled
                }}
              >
                {person?.roles
                  .map((role) => {
                    const key = role as unknown as MembershipRole;

                    return roleDescription[key]?.label || "";
                  })
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            </Box>
            <ChangePermission
              {...{
                plan,
                permissionLevel: person?.permissionLevel,
                id: person!.id,
                userId: person!.userId
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PeopleWithAccessList;
