/* eslint-disable camelcase */
import { useMemo } from "react";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";

import { Plans_plans } from "@api/plans/__generated__/Plans";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { isOwner } from "@utils/permission-utils";
import useUserContext from "@providers/user/hooks";

import AdvisorDashboard from "../../components/AdvisorDashTools";

import SharedPlansTitleSection from "./SharedPlansTitleSection";
import { useSharedWithMeSummary } from "./hooks";
import NoSharedPlansMessage from "./NoSharedPlansMessage";
import SharedPlanCardLayout from "./SharedPlanCardLayout";
import SharedPlanTable from "./SharedPlanTable";

const SharedWithMeSummary = () => {
  const { plans } = useMemberPlansState();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { isAdvisor } = useUserContext();

  const plansSharedWithMe = useMemo(
    () => plans?.filter((plan) => !isOwner(plan?.membership)),
    [plans]
  );

  const sortedPlansSharedWithMe = plansSharedWithMe.sort(
    (a: Plans_plans, b: Plans_plans) => {
      let nameA =
        (a.primaryDrafter?.lastName ?? "") +
        (a.primaryDrafter?.firstName ?? "");

      nameA = nameA.toUpperCase() ?? "";

      let nameB =
        (b.primaryDrafter?.lastName ?? "") +
        (b.primaryDrafter?.firstName ?? "");

      nameB = nameB.toUpperCase() ?? "";

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    }
  );

  const sharedPlans = useSharedWithMeSummary(sortedPlansSharedWithMe);

  if (isAdvisor) {
    return (
      <Box sx={{ mb: "2rem" }}>
        <AdvisorDashboard plans={sharedPlans} />
      </Box>
    );
  }

  if (plansSharedWithMe.length === 0) {
    return (
      <Box>
        <SharedPlansTitleSection />
        <NoSharedPlansMessage />
      </Box>
    );
  }

  return (
    <Box>
      <SharedPlansTitleSection />
      {!isDesktop && <SharedPlanCardLayout plans={sharedPlans} />}
      {isDesktop && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "37.5rem",
            width: "100%"
          }}
        >
          <SharedPlanTable plans={sharedPlans} />
        </Box>
      )}
    </Box>
  );
};

export default SharedWithMeSummary;
