import { ChangeEvent, useCallback } from "react";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

import { numberWithCommas } from "@utils/misc";

import { FormInputProps } from "./types";

const BasicCurrencyFormInput: React.FC<FormInputProps> = (props) => {
  const { name, defaultValue } = props;

  const {
    control,
    watch,
    setValue: setRHFValue,
    formState: { errors }
  } = useFormContext();

  const value = numberWithCommas(watch(name) || "");

  const fieldError = get(errors, `${name}.message`);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = event.target;

      const newValue = inputValue.replaceAll(",", "");

      setRHFValue(name, newValue, {
        shouldValidate: true,
        shouldDirty: true
      });
    },
    [setRHFValue, name]
  );

  return (
    <Controller
      {...{ control, name, defaultValue }}
      render={({ field }): React.ReactElement => (
        <TextField
          variant="standard"
          {...(fieldError
            ? { error: true, helperText: fieldError }
            : {})}
          {...props}
          {...field}
          {...{ value, onChange }}
        />
      )}
    />
  );
};

export default BasicCurrencyFormInput;
