/* eslint-disable react/no-array-index-key */
import { Box, Button, Grid, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { FileType } from "@api/__generated__/globalTypes";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { LAST_WILL_ALL_ABOUT_YOU_ROUTES } from "@pages/LastWill/sections/AllAboutYou/consts";
import { ROUTES } from "@setup/consts/routes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import LastWillImage from "@assets/images/docIcons/DocumentIconLastWillpng.png";
import Image from "@components/Image";
import { File } from "@api/files/__generated__/File";

import { useSlideActions } from "../hooks";
import { getLinkFullUrl } from "../utils";

const LastWillSlide = () => {
  const { lastWill } = useMemberPlansState();

  const primaryDrafter = lastWill?.plan?.primaryDrafter;

  const planFile = useMemo(
    () =>
      lastWill?.plan?.files?.find(
        (f: File) =>
          f.type === FileType.LAST_WILL_DOCUMENT &&
          f.ownerId === primaryDrafter.id
      ),
    [lastWill?.plan?.files, primaryDrafter]
  );

  const editLink = getLinkFullUrl([
    ROUTES.LastWill,
    LAST_WILL_SECTION_ROUTES.AllAboutYou,
    LAST_WILL_ALL_ABOUT_YOU_ROUTES.Preview
  ]);

  const actions = useSlideActions({
    planFile,
    memberPlan: lastWill,
    editLink
  });
  const navigate = useNavigate();

  const handleGoToEdit = useCallback(() => {
    if (editLink) {
      navigate(editLink);
    }
  }, [editLink, navigate]);

  const renderTop = useCallback(
    () =>
      lastWill && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography align="center" variant="h5">
            <FormattedMessage defaultMessage="Last Will & Testament" />
          </Typography>
          <Typography align="center" variant="h5">
            <FormattedMessage
              defaultMessage="of {name}"
              values={{
                name: lastWill.plan.primaryDrafter?.fullName ?? ""
              }}
            />{" "}
          </Typography>

          <Box sx={{ mt: "1rem" }}>
            <Image
              height="64px"
              width="64px"
              src={LastWillImage}
              alt="Last Will icon"
            />
          </Box>
          <Typography mt="1.2rem" align="center" fontSize="1rem">
            <FormattedMessage
              defaultMessage="The core document in your plan. Designate who should care
                      for your minor children, inherit your assets, and administer
                      your estate."
            />
          </Typography>
        </Box>
      ),
    [lastWill]
  );

  if (!lastWill?.primaryDrafterLastWill) {
    return (
      <>
        {renderTop()}
        <Typography mt="1.5rem" align="center" fontSize="15px">
          <FormattedMessage defaultMessage="You haven’t created a Last Will yet." />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleGoToEdit}
            variant="contained"
            sx={{
              flex: "1",
              borderWidth: "2px",
              fontWeight: "500",
              fontSize: "14px",
              marginTop: "1.1rem"
            }}
          >
            <FormattedMessage defaultMessage="CREATE LAST WILL" />
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      {renderTop()}
      <Grid
        container
        item
        xs={12}
        mt="1.5rem"
        justifyContent="space-around"
      >
        {actions.map(({ icon, label }, index) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer"
            }}
            key={`${label}_${index}`}
          >
            {icon}
            <Typography fontSize="0.875rem">{label}</Typography>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default LastWillSlide;
