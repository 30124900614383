import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Typography, Box } from "@mui/material";
import HandshakeIconOutlined from "@mui/icons-material/HandshakeOutlined";

import ModalStepFooter from "@components/ModalStepFooter";
import useUserContext from "@providers/user/hooks";

import { AdvisorTutorialProps } from "../types";

const AdvisorTutorialGreeting: React.FC<AdvisorTutorialProps> = ({
  onNext,
  onPrev
}) => {
  const user = useUserContext();
  const firstName = useMemo(
    () => user.user?.firstName,
    [user.user?.firstName]
  );

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="1.25rem"
        alignItems="center"
        sx={{
          mb: "2.5rem"
        }}
      >
        <HandshakeIconOutlined />
        <Typography variant="h3">
          <FormattedMessage
            defaultMessage="Nice to meet you, {name}."
            values={{ name: firstName }}
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage defaultMessage="Let's take a quick overview to help you get the most out of your advisor account (1/5)." />
        </Typography>
      </Box>
      <ModalStepFooter
        prevButtonProps={{ type: "button", onClick: onPrev }}
        nextButtonProps={{
          type: "submit",
          label: "Continue",
          onClick: onNext
        }}
      />
    </Box>
  );
};

export default AdvisorTutorialGreeting;
