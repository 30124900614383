import { gql } from "@apollo/client";

import { ASSET_FRAGMENT } from "@api/plans/fragments";

export const CREATE_ASSET = gql`
  ${ASSET_FRAGMENT}
  mutation CreateAsset($input: AssetCreateInput!) {
    createAsset(input: $input) {
      ...Asset
    }
  }
`;

export const UPDATE_ASSET = gql`
  ${ASSET_FRAGMENT}
  mutation UpdateAsset($id: Int!, $input: AssetUpdateInput!) {
    updateAsset(id: $id, input: $input) {
      ...Asset
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation DeleteAsset($id: Int!) {
    deleteAsset(id: $id)
  }
`;
