import { LastWill_representatives as Representative } from "@api/plans/__generated__/LastWill";

import getAgentHierarchy from "./getAgentHierarchy";

const useRepresentativeHierarchy = (
  representatives: Representative[]
) => {
  const agents = representatives.map((agent) => ({
    isBackup: agent.isBackup,
    order: agent.order,
    name: agent.representative?.fullName
  }));

  const hierarchy = getAgentHierarchy(agents);

  return hierarchy;
};

export default useRepresentativeHierarchy;
