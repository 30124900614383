import { FormattedMessage, useIntl } from "react-intl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";

import useUserContext from "@providers/user/hooks";
import Modal from "@components/Modal";

const PaymentSuccessModal = () => {
  const [searchParams] = useSearchParams();
  const isPaymentSuccess =
    searchParams.get("paymentSuccess") === "true";
  const isPetPlan = searchParams.get("planType") === "petProtection";
  const { user } = useUserContext();

  const userName = user?.firstName || user?.fullName;

  const [showPaymentSuccessModal, setShowPaymentSuccessModal] =
    useState(false);

  useEffect(() => {
    setShowPaymentSuccessModal(isPaymentSuccess);

    return () => setShowPaymentSuccessModal(false);
  }, [isPaymentSuccess]);

  const { formatMessage } = useIntl();

  const title = useMemo(() => {
    if (isPetPlan) {
      return formatMessage({
        defaultMessage: "Plan Complete"
      });
    }

    return formatMessage({
      defaultMessage: "Payment Successful"
    });
  }, [formatMessage, isPetPlan]);

  const subText = useMemo(() => {
    if (isPetPlan) {
      return formatMessage({
        defaultMessage:
          "We completed drafting your pet estate plan, and a copy is on its way to your email inbox"
      });
    }

    return formatMessage({
      defaultMessage:
        "We successfully processed your payment, and your completed plan is on the way to your email inbox."
    });
  }, [formatMessage, isPetPlan]);

  return (
    <Modal
      open={showPaymentSuccessModal}
      {...{ title }}
      content={
        <>
          <Box
            component="span"
            mb={2.5}
            sx={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <CheckCircleIcon
              sx={{ fontSize: "2.5rem", color: "#0DC0B4" }}
            />
          </Box>
          {userName ? (
            <FormattedMessage
              defaultMessage="Great news, {userName}!"
              values={{ userName }}
            />
          ) : (
            <FormattedMessage defaultMessage="Great news!" />
          )}
          <br />
          <br />
          {subText}
        </>
      }
      actions={[
        {
          label: "Return to JIC Estates",
          variant: "outlined",
          color: "inherit",
          onClick: () => setShowPaymentSuccessModal(false)
        }
      ]}
    />
  );
};

export default PaymentSuccessModal;
