/* eslint-disable no-nested-ternary */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import {
  PartnerType,
  PlanType
} from "@api/__generated__/globalTypes";

import { usePrimaryDrafterPOAReviewTab } from "./usePrimaryDrafterPOAReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");

  const items = usePrimaryDrafterPOAReviewTab({
    values,
    partnerType,
    planType: PlanType.TRUST
  });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Let's confirm that we got this right.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
