import { User } from "@api/me/__generated__/User";
import { LastWillMember } from "@pages/LastWill/types";

const getTrustName = (
  name: string | null | undefined,
  primaryDrafter: User | LastWillMember,
  partner?: User | LastWillMember,
  isPartner = false,
  isJointTrust = false
) => {
  if (name) {
    return name;
  }

  if (isJointTrust && partner) {
    return primaryDrafter.lastName === partner.lastName
      ? `${primaryDrafter.lastName} Family Trust`
      : `${primaryDrafter.lastName} and ${partner.lastName} Family Trust`;
  }

  if (isPartner && partner) {
    return `${partner.lastName} Revocable Living Trust`;
  }

  return primaryDrafter.lastName
    ? `${primaryDrafter.lastName} Revocable Living Trust`
    : undefined;
};

export default getTrustName;
