const clearsIncomeThreshold = (income?: string): boolean =>
  income === "$250,001 - $500,000" || income === ">$500,000";

const clearsAssetThreshold = (assetValue?: string): boolean =>
  assetValue === "$250,001 - $1,000,000" ||
  assetValue === "$1,000,001 - $5,000,000" ||
  assetValue === "$5,000,001 - $10,000,000" ||
  assetValue === ">$10,000,000";

const checkFinancialMatch = (
  income?: string,
  assetValue?: string
): boolean =>
  clearsIncomeThreshold(income) || clearsAssetThreshold(assetValue);

export default checkFinancialMatch;
