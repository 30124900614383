import { FormProvider } from "react-hook-form";
import React from "react";
import { useIntl } from "react-intl";

import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";

import { useCreatePlanTodos } from "./hooks";
import * as Styled from "./styles";

const CreatePlanTodo: React.FC = () => {
  const intl = useIntl();
  const { onSubmit, methods } = useCreatePlanTodos();

  return (
    <FormProvider {...methods}>
      <Styled.CreatePlanTodoForm
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <BasicFormInput
          name="title"
          label={intl.formatMessage({
            defaultMessage: "+ Add a Task"
          })}
        />
      </Styled.CreatePlanTodoForm>
    </FormProvider>
  );
};

export default CreatePlanTodo;
