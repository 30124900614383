import { Grid, Box, Card, Typography } from "@mui/material";
import React from "react";

import { AssetNetWorthData, NetWorthTableProps } from "./types";

const AssetCardLayout: React.FC<NetWorthTableProps> = ({
  assets = []
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem",
      variant: "body1"
    }}
  >
    {assets.map((asset: AssetNetWorthData) => {
      const {
        id,
        name,
        editComponent,
        type,
        subtype,
        value,
        percentNetWorth
      } = asset;

      return (
        <Card
          key={id}
          sx={{
            py: "1.25rem",
            px: "1rem"
          }}
        >
          <Grid container gridTemplateColumns="1fr 2fr">
            <Grid style={{ textAlign: "right" }} item xs={12}>
              {editComponent}
            </Grid>
            <Grid item xs={6}>
              <Typography>Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Type:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{type}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Subtype:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{subtype}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Value:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{value}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>% Net Worth:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{percentNetWorth}</Typography>
            </Grid>
          </Grid>
        </Card>
      );
    })}
  </Box>
);

export default AssetCardLayout;
