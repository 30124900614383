export const PET_CARE_ROUTES = {
  Preview: "",
  // Begin Care Instructions Loop
  CareInstructionsLoop: "instructions",
  Food: "food",
  Exercise: "exercise",
  SameInstructions: "same-food-and-exercise",
  // Begin Vet Loop
  Interstitial: "interstitial",
  VetLoop: "vet",
  Vet: "vet-id",
  SameVet: "same-vet",
  // Begin Medical Loop
  Medical: "medical-instructions",
  MedicalNoteLoop: "add-medical-instructions",
  MedicalNote: "note",
  // Review
  Review: "review"
};
