import { styled } from "@mui/material/styles";

export const MobileContainer = styled("div")(({ theme }) => ({
  display: "block",
  zIndex: 10,
  [theme.breakpoints.up("md")]: {
    display: "none"
  }
}));

export const DesktopContainer = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block"
  }
}));

export const Link = styled("a")(({ theme }) => ({
  display: "inline-block",
  opacity: 1,
  fontWeight: 600,
  textDecoration: "none",
  color: theme.palette.grey[500],
  whiteSpace: "nowrap",
  fontSize: "1rem",
  lineHeight: 1.4,
  fontFamily: '"Inter", sans-serif',
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  }
}));

export const LogoWrapper = styled(Link)(() => ({
  display: "flex",
  "&:hover": {
    cursor: "pointer"
  },
  svg: {
    display: "block",
    fontSize: "2.21rem"
  }
}));
