import { PropsWithChildren, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useGetPlanDraftQuery } from "@api/planDrafts/hooks";
import { PlanType, PlanStatus } from "@api/__generated__/globalTypes";
import { useGetPlansQuery } from "@api/plans";
import {
  isTrustPlan,
  isLastWillPlan,
  isPetProtectionPlan
} from "@utils/planTypeGuards";
import { hasEditPermissions, isOwner } from "@utils/permission-utils";

import { MemberPlansStateContext } from "./context";
import { Plan } from "./type";

export const { Provider: MembersPlanStateProvider } =
  MemberPlansStateContext;

export const MembersPlansStateContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { data, loading } = useGetPlansQuery();
  const { data: lastWillPlanDraft } = useGetPlanDraftQuery({
    variables: {
      type: PlanType.LAST_WILL
    }
  });
  const { data: trustPlanDraft } = useGetPlanDraftQuery({
    variables: {
      type: PlanType.TRUST
    }
  });

  const { data: petProtectionPlanDraft } = useGetPlanDraftQuery({
    variables: {
      type: PlanType.PET_PROTECTION
    }
  });

  const selectedPlanId = useMemo(() => {
    const planId = searchParams.get("planId");
    const parsedPlanId = parseInt(planId ?? "", 10);

    if (Number.isNaN(parsedPlanId)) return undefined;

    return parsedPlanId;
  }, [searchParams]);

  const plans = useMemo(
    () =>
      (data?.plans || []).filter((plan): plan is Plan =>
        Boolean(plan?.id)
      ),
    [data]
  );

  const myRoles = useMemo(() => {
    const plan = plans.find(({ id }) => id === selectedPlanId);

    if (plan === undefined) {
      return undefined;
    }
    const myRole = plan.membership.roles;
    const accountOwner = plan.primaryDrafter;

    return {
      myRole,
      accountOwner
    };
  }, [plans, selectedPlanId]);

  const lastWill = useMemo(() => {
    const plan = plans.find(
      ({ id, type, membership }) =>
        type === PlanType.LAST_WILL &&
        ((!selectedPlanId && isOwner(membership)) ||
          id === selectedPlanId)
    );

    if (!plan || !plan.lastWills || !isLastWillPlan(plan)) {
      return undefined;
    }

    const primaryDrafterLastWill = plan.lastWills.find(
      (planData) => planData?.userId === plan.primaryDrafterId
    );

    if (!primaryDrafterLastWill) {
      return undefined;
    }

    return {
      plan,
      primaryDrafterLastWill,
      partnerLastWill:
        plan?.lastWills.find(
          (planData) => planData?.userId === plan?.partnerId
        ) ?? undefined,
      canEdit: hasEditPermissions(plan?.membership),
      isOwner: isOwner(plan?.membership)
    };
  }, [plans, selectedPlanId]);

  const trust = useMemo(() => {
    const plan = plans.find(
      ({ id, type, membership }) =>
        type === PlanType.TRUST &&
        ((!selectedPlanId && isOwner(membership)) ||
          id === selectedPlanId)
    );

    if (!plan || !isTrustPlan(plan)) {
      return undefined;
    }

    const primaryDrafterTrust = plan.trusts?.find(
      (planData) => planData?.userId === plan.primaryDrafterId
    );

    const primaryDrafterLastWill = plan.lastWills?.find(
      (planData) => planData?.userId === plan.primaryDrafterId
    );

    if (!primaryDrafterTrust || !primaryDrafterLastWill) {
      return undefined;
    }

    return {
      plan,
      primaryDrafterTrust,
      partnerTrust:
        plan.trusts?.find(
          (planData) => planData?.userId === plan.partnerId
        ) ?? undefined,
      primaryDrafterLastWill,
      partnerLastWill:
        plan.lastWills?.find(
          (planData) => planData?.userId === plan.partnerId
        ) ?? undefined,
      canEdit: hasEditPermissions(plan.membership),
      isOwner: isOwner(plan.membership)
    };
  }, [plans, selectedPlanId]);

  const petProtection = useMemo(() => {
    const plan = plans.find(
      ({ id, type, membership }) =>
        type === PlanType.PET_PROTECTION &&
        ((!selectedPlanId && isOwner(membership)) ||
          id === selectedPlanId)
    );

    if (!plan?.petProtection || !isPetProtectionPlan(plan)) {
      return undefined;
    }

    return {
      plan,
      petProtection: plan.petProtection,
      canEdit: hasEditPermissions(plan.membership),
      isOwner: isOwner(plan.membership)
    };
  }, [plans, selectedPlanId]);

  const planCompleted =
    (!!trust?.plan?.status &&
      trust?.plan?.status !== PlanStatus.CANCELLED) ||
    (!!lastWill?.plan?.status &&
      lastWill?.plan.status !== PlanStatus.CANCELLED) ||
    (!!petProtection?.plan?.status &&
      petProtection?.plan.status !== PlanStatus.CANCELLED);

  const trustStarted = !!trustPlanDraft?.planDraft;
  const trustLastPath = trustStarted
    ? trustPlanDraft.planDraft?.lastPath
    : "";
  const lastWillStarted = !!lastWillPlanDraft?.planDraft;
  const lwLastPath = lastWillStarted
    ? lastWillPlanDraft.planDraft?.lastPath
    : "";
  const petProtectionStarted = !!petProtectionPlanDraft?.planDraft;
  const petLastPath = petProtectionStarted
    ? petProtectionPlanDraft.planDraft?.lastPath
    : "";
  const isPlanStarted = planCompleted
    ? true
    : trustStarted || lastWillStarted || petProtectionStarted;

  return (
    <MembersPlanStateProvider
      value={{
        plans,
        trust,
        lastWill,
        petProtection,
        myRoles,
        loading,
        planCompleted,
        planStarted: {
          isStarted: isPlanStarted,
          trust: trustStarted,
          trustPath: trustLastPath,
          lastWill: lastWillStarted,
          lastWillPath: lwLastPath,
          petProtection: petProtectionStarted,
          petProtectionPath: petLastPath
        }
      }}
    >
      {children}
    </MembersPlanStateProvider>
  );
};
