import { useIntl } from "react-intl";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { usePlanForm } from "@providers/planForm/context";
import { Pet } from "@pages/PetPlan/types";
import { useFormField } from "@components/FormFields/hooks";

const FoodStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: pet } = useFormField<Pet>(`pets.${iteration}`);

  /* eslint-disable react/no-unescaped-entities */
  return (
    <SingleResponseArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "What should your Pet Guardian know about {name}'s feeding instructions?"
          },
          {
            name: pet.name
          }
        ),
        subText: `See an example.`,
        moreInfoModalProps: {
          title: "Example Feeding Instructions",
          content: (
            <p>
              "2 cups of Purina Pro dry kibble in the morning and 2
              cups at night."
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      label="Feeding Instructions"
      fieldProps={{
        multiline: true,
        minRows: 3
      }}
      name={`pets.${iteration}.feedingInstructions`}
    />
  );
};

export default FoodStep;
