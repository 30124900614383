import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { RelationshipStatus } from "@setup/enums";

import { LAST_WILL_GUARDIANS_ROUTES } from "../Guardians/consts";

import { LAST_WILL_ALL_ABOUT_YOU_ROUTES } from "./consts";
import SectionsPreviewStep from "./steps/Preview";
import BasicsStep from "./steps/Basics";
import HavingChildrenStep from "./steps/HavingChildren";
import ChildrenDetailsStep from "./steps/ChildrenDetails";
import Interstitial1Step from "./steps/Interstitial1";
import PartnerStep from "./steps/Partner";
import PartnerDetailsStep from "./steps/PartnerDetails";
import PlanSelectionStep from "./steps/PlanSelection";
import ReviewStep from "./steps/Review";

const allAboutYouSteps: PlanFormStep[] = [
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Basics
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Basics,
    element: <BasicsStep />,
    rules: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Interstitial1
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Interstitial1,
    element: <Interstitial1Step />,
    rules: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Partner
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Partner,
    element: <PartnerStep />,
    rules: {
      if: [
        {
          or: [
            {
              "==": [
                { var: "users.0.relationshipStatus" },
                RelationshipStatus.SINGLE
              ]
            },
            {
              "==": [
                { var: "users.0.relationshipStatus" },
                RelationshipStatus.DIVORCED
              ]
            }
          ]
        },
        LAST_WILL_ALL_ABOUT_YOU_ROUTES.HavingChildren,
        LAST_WILL_ALL_ABOUT_YOU_ROUTES.PlanSelection
      ]
    }
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.PlanSelection,
    element: <PlanSelectionStep />,
    rules: LAST_WILL_ALL_ABOUT_YOU_ROUTES.PartnerDetails
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.PartnerDetails,
    element: <PartnerDetailsStep />,
    rules: LAST_WILL_ALL_ABOUT_YOU_ROUTES.HavingChildren
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.HavingChildren,
    element: <HavingChildrenStep />,
    rules: {
      if: [
        { "==": [{ var: "haveChildren" }, true] },
        LAST_WILL_ALL_ABOUT_YOU_ROUTES.ChildrenDetails,
        LAST_WILL_ALL_ABOUT_YOU_ROUTES.Review
      ]
    }
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.ChildrenDetails,
    element: <ChildrenDetailsStep />,
    rules: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Review
  },
  {
    path: LAST_WILL_ALL_ABOUT_YOU_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.Guardians}/${LAST_WILL_GUARDIANS_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={LAST_WILL_ALL_ABOUT_YOU_ROUTES.Preview} />
  }
];

export default allAboutYouSteps;
