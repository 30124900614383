import { Dialog, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Modal = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    padding: "1.25rem"
  },
  "& .MuiDialogContentText-root": {
    color: "inherit"
  },
  "& .MuiDialogActions-root": {
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingBottom: "1.25rem"
  },
  "& .MuiButton-root": {
    minWidth: "9.875rem"
  }
}));

export const ModalTitle = styled(Typography)(() => ({
  padding: "1.125rem 1.75rem 0",
  lineheight: 1.5
}));
