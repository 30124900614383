import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const CopyPersonalRepresentativesCompStep: React.FC = () => {
  const { value: RepCompType } = useFormField(
    `users.0.lastWill.representativesCompensationType`
  );
  const { value: RepCompDollar } = useFormField(
    `users.0.lastWill.representativesCompensationDollar`
  );
  const { value: RepCompPercent } = useFormField(
    `users.0.lastWill.representativesCompensationPercent`
  );

  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setCompensationType } = useFormField(
    "users.1.lastWill.representativesCompensationType"
  );
  const { setValue: setCompensationDollar } = useFormField(
    "users.1.lastWill.representativesCompensationDollar"
  );
  const { setValue: setCompensationPercent } = useFormField(
    "users.1.lastWill.representativesCompensationPercent"
  );

  const onSubmit = useCallback(
    ({ copyPersonalRepresentativesComp }: SingleSelectFormValues) => {
      if (parseBoolean(copyPersonalRepresentativesComp)) {
        setCompensationType(RepCompType);
        setCompensationDollar(RepCompDollar);
        setCompensationPercent(RepCompPercent);
      }
    },
    [
      setCompensationType,
      setCompensationDollar,
      setCompensationPercent,
      RepCompType,
      RepCompDollar,
      RepCompPercent
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `And does ${partnerFirstName} want to compensate ${partnerFirstName}'s Personal Representatives in the same way, too?`
      }}
      {...{ onSubmit }}
      name="copyPersonalRepresentativesComp"
    />
  );
};

export default CopyPersonalRepresentativesCompStep;
