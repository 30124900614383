import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SelectItem } from "@components/FormFields/FormSelect/types";

export const usePersonalPropertyItems = (): SelectItem[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({
          defaultMessage: "Fixtures and Furniture"
        }),
        value: "Fixtures and Furniture"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Jewelry" }),
        value: "Jewelry"
      },
      {
        label: intl.formatMessage({
          defaultMessage: "China, Silverware and Household Items"
        }),
        value: "Household Items"
      },
      {
        label: intl.formatMessage({
          defaultMessage:
            "Motor Vehicles, Boats and Personal Watercraft"
        }),
        value: "Vehicle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Other Tangible Personal Property Items"
        }),
        value: "Other"
      }
    ],
    [intl]
  );

  return items;
};
