import InterstitialArchetype from "@components/archetypes/Interstitial";

const ResidueCompleteStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Super! You've finished allocating the balance of your estate.`
    }}
  />
);

export default ResidueCompleteStep;
