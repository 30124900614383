import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerCreateOrModifyTrustStep: React.FC = () => {
  const options = [
    {
      label: "Create new trust",
      value: false
    },
    {
      label: "Modify an existing trust built outside of JIC Estates",
      value: true
    }
  ];

  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Will ${partnerFirstName} be creating a new trust or modifying an existing one built outside of JIC Estates?`
      }}
      name="users.1.trust.isExistingTrust"
      items={options}
      defaultValue={false}
    />
  );
};

export default PartnerCreateOrModifyTrustStep;
