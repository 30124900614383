import { PropsWithChildren } from "react";
import { Box, Typography, Container, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Image from "@components/Image";
import InfoModal from "@components/InfoModal";
import Video from "@components/Video";

import { StepLayoutProps } from "./types";

const StepLayout: React.FC<PropsWithChildren<StepLayoutProps>> = ({
  title,
  subText,
  imageProps,
  videoProps,
  hideImageOnMobile: hideImageOnMobileProp,
  muiIconHeader,
  isWide,
  moreInfoModalProps,
  orderedListItems,
  children
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { alt, src, ...restImageProps } = imageProps || {};
  const {
    src: videoSrc,
    title: videoTitle,
    caption: videoCaption
  } = videoProps || {};
  const hideImageOnMobile = !isDesktop && hideImageOnMobileProp;

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: { xs: 2.5, md: 6.25 }
      }}
    >
      {title && (
        <Typography
          variant="h1"
          textAlign="center"
          mb={{ xs: 3, md: 2.5 }}
        >
          {title}
        </Typography>
      )}
      {!hideImageOnMobile && imageProps && src && (
        <Box
          sx={{
            maxWidth: "8rem",
            width: "100%",
            mx: "auto",
            mb: 2.5
          }}
        >
          <Image
            alt={alt || title || ""}
            objectFit="contain"
            {...{ src, ...restImageProps }}
          />
        </Box>
      )}
      {!hideImageOnMobile && videoProps && videoSrc && (
        <Box
          sx={{
            width: "100%",
            mx: "auto",
            mb: 2.5
          }}
        >
          <Video
            src={videoSrc}
            title={videoTitle}
            caption={videoCaption}
            showCaption={false}
          />
        </Box>
      )}
      {!hideImageOnMobile && muiIconHeader && (
        <Box
          sx={{
            maxWidth: "29.375rem",
            width: "100%",
            mx: "auto",
            mb: 2.5,
            textAlign: "center"
          }}
        >
          {" "}
          {muiIconHeader}
        </Box>
      )}
      {orderedListItems && orderedListItems.length > 0 && (
        <Stack spacing={2}>
          {orderedListItems.map(
            ({ title: itemTitle, content }, index) => (
              <Stack
                // eslint-disable-next-line react/no-array-index-key
                key={`${itemTitle}_${index}`}
                spacing={2}
                direction={{ xs: "row" }}
              >
                <Typography
                  sx={{
                    height: "1.5rem",
                    width: "1.61rem",
                    borderRadius: "50%",
                    border: "2px solid #0DC0B4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexShrink: 0
                  }}
                  variant="subtitle2"
                >
                  {index + 1}
                </Typography>
                <Box>
                  <Typography variant="body1">{itemTitle}</Typography>
                  {content && (
                    <InfoModal
                      togglerLabel="Learn More"
                      {...{ content, title: itemTitle }}
                    />
                  )}
                </Box>
              </Stack>
            )
          )}
        </Stack>
      )}
      {isWide ? (
        <>
          {subText && (
            <Box display="flex">
              <Typography
                variant="body1"
                mb={{
                  xs: 3,
                  md: 3.75
                }}
              >
                {subText}
                {moreInfoModalProps && (
                  <Box component="span" ml={0.5}>
                    <InfoModal {...moreInfoModalProps} />
                  </Box>
                )}
              </Typography>
            </Box>
          )}
          {children}
        </>
      ) : (
        <Box maxWidth="29.375rem" width="100%" m="0 auto">
          <Box display="flex">
            {subText && (
              <Typography
                variant="body2"
                mb={{
                  xs: 3,
                  md: 3.75
                }}
              >
                {subText}
                {moreInfoModalProps && (
                  <Box component="span" ml={0.5}>
                    <InfoModal {...moreInfoModalProps} />
                  </Box>
                )}
              </Typography>
            )}
          </Box>
          {children}
        </Box>
      )}
    </Container>
  );
};

export default StepLayout;
