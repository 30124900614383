import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/LastWill/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    stepLayout={{
      title: "Ready to take your estate plan to the next level?",
      subText:
        "Although not strictly necessary to complete your last will, most estate planning attorneys recommend that you create an Advance Healthcare Directive. And with JIC Estates, you can create yours for free."
    }}
  />
);

export default SectionsPreviewStep;
