import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const DesignateBackupGuardianStep: React.FC = () => (
  <PersonDetailsArrayArchetype
    stepLayout={{
      title: `Who would you like to serve as backup Guardian?`,
      subText:
        "You can add additional backup Guardians by hitting the 'Add Another Backup Guardian' button."
    }}
    personRole="Backup Guardian"
    addBtnLabel="Add Another Backup Guardian"
    removeBtnLabel="Remove Backup Guardian"
    name="backupGuardians"
  />
);

export default DesignateBackupGuardianStep;
