import { useMemo } from "react";
import { get, camelCase, groupBy, startCase } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import Typography from "@mui/material/Typography";

import { AssetType } from "@api/__generated__/globalTypes";
import { DataPoint } from "@pages/EstatePlanView/Partials/components/PieChart/types";
import { numberWithCommas } from "@utils/misc";
import { useAssetHumanizedTitle } from "@hooks/useAssetHumanizedTitle";
import EditAssetModal from "@pages/EstatePlanView/Partials/components/EditAssetModal";
import { LastWill } from "@providers/memberPlansState/type";

import { EditAssetButton } from "../../AssetsTab/styles";

import { AssetNetWorthData } from "./types";

const useNetWorth = (
  userLastWill: LastWill | undefined,
  isTrustPlan: boolean
) => {
  const { formatMessage } = useIntl();

  const assets = useMemo(() => {
    const filteredAssets = userLastWill?.assets?.filter(
      (asset) =>
        asset?.type &&
        ![AssetType.ESTATE_BALANCE, AssetType.CASH].includes(
          asset.type
        )
    );

    const sortedAssets = filteredAssets?.sort((a, b) =>
      a?.type && b?.type ? a.type.localeCompare(b.type) : 0
    );

    return sortedAssets;
  }, [userLastWill?.assets]);

  const calculatedNetWorth: number = useMemo(
    () =>
      assets?.reduce<number>(
        (acc, curr) => acc + (curr?.grossValue ?? 0),
        0
      ) || 0,
    [assets]
  );

  const pfNetWorth = useMemo(
    () => Math.max(calculatedNetWorth, userLastWill?.netWorth || 0),
    [calculatedNetWorth, userLastWill?.netWorth]
  );

  const assetCategories = useMemo(() => {
    const groupedAssets = groupBy(assets, (asset) => asset?.type);
    const data = Object.entries(groupedAssets).map(
      ([key, value]) => ({
        name: startCase(camelCase(key)),
        value: value?.reduce(
          (prev, curr) => prev + (curr?.grossValue || 0),
          0
        )
      })
    );

    const filteredData = data.filter(
      (category) => category.value > 0
    );

    const unknownAssetAvailable =
      userLastWill?.netWorth &&
      userLastWill.netWorth > calculatedNetWorth;

    return [
      ...filteredData,
      unknownAssetAvailable
        ? {
            name: formatMessage({
              defaultMessage: "Not Classified"
            }),
            value:
              userLastWill?.netWorth > calculatedNetWorth
                ? userLastWill.netWorth - calculatedNetWorth
                : 0,
            color: "#45166A"
          }
        : ({} as DataPoint)
    ].filter((value): value is DataPoint => Boolean(value?.name));
  }, [
    calculatedNetWorth,
    assets,
    formatMessage,
    userLastWill?.netWorth
  ]);

  const humanizedTitles = useAssetHumanizedTitle();

  const assetDetails = useMemo(() => {
    if (!assets) {
      return [];
    }

    return assets.map(
      (asset): AssetNetWorthData => ({
        id: asset?.id,
        name: asset?.name ?? "--",
        editComponent: asset ? (
          <EditAssetModal
            {...{ asset }}
            showIsFunded={isTrustPlan}
            trigger={({ onClick }) => (
              <EditAssetButton {...{ onClick }}>
                <FormattedMessage defaultMessage="Edit" />
              </EditAssetButton>
            )}
          />
        ) : (
          <Typography>--</Typography>
        ),
        type: get(humanizedTitles, asset?.type || "", "--"),
        subtype: asset?.subtype ?? "--",
        value: asset?.grossValue
          ? `$${numberWithCommas(asset.grossValue)}`
          : "--",
        percentNetWorth:
          asset?.grossValue && pfNetWorth
            ? `${Math.round((asset.grossValue / pfNetWorth) * 100)}%`
            : "--"
      })
    );
  }, [humanizedTitles, isTrustPlan, assets, pfNetWorth]);

  return {
    userLastWill,
    pfNetWorth,
    assetCategories,
    assetDetails
  };
};

export default useNetWorth;
