import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import {
  ITERATION_QUERY_KEY,
  NEXT_ITERATION_RULE
} from "@providers/planForm/consts";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";

import { PET_SPECIAL_POWERS_ROUTES } from "../SpecialPowers/consts";

import { PET_CARE_ROUTES } from "./consts";
import SectionsPreviewStep from "./steps/Preview";
import FoodStep from "./steps/Food";
import ExerciseStep from "./steps/Exercise";
import DesignateSameInstructionsStep from "./steps/SameInstructions";
import DesignateVetStep from "./steps/Vet";
import DesignateSameVetStep from "./steps/SameVet";
import MedicalStep from "./steps/Medical";
import MedicalNoteStep from "./steps/MedicalNote";
import ReviewStep from "./steps/Review";
import InterstitialStep from "./steps/Interstitial";

const petCareSteps: PlanFormStep[] = [
  {
    path: PET_CARE_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: PET_CARE_ROUTES.CareInstructionsLoop
  },
  {
    path: PET_CARE_ROUTES.CareInstructionsLoop,
    loop: {
      enabled: true,
      iterationCountSource: "pets"
    },
    children: [
      {
        path: PET_CARE_ROUTES.Food,
        element: <FoodStep />,
        rules: PET_CARE_ROUTES.Exercise
      },
      {
        path: PET_CARE_ROUTES.Exercise,
        element: <ExerciseStep />,
        rules: {
          if: [
            {
              and: [
                { "==": [{ var: ITERATION_QUERY_KEY }, "0"] },
                { ">": [{ var: "numberOfPets" }, "1"] }
              ]
            },
            PET_CARE_ROUTES.SameInstructions,
            PET_CARE_ROUTES.Interstitial
          ]
        }
      },
      {
        path: PET_CARE_ROUTES.SameInstructions,
        element: <DesignateSameInstructionsStep />,
        rules: {
          if: [
            { "==": [{ var: "sameCareInstructions" }, true] },
            { path: PET_CARE_ROUTES.Interstitial, break: true },
            NEXT_ITERATION_RULE
          ]
        }
      }
    ],
    rules: PET_CARE_ROUTES.Interstitial
  },
  {
    path: PET_CARE_ROUTES.Interstitial,
    element: <InterstitialStep />,
    rules: PET_CARE_ROUTES.VetLoop
  },
  {
    path: PET_CARE_ROUTES.VetLoop,
    loop: {
      enabled: true,
      iterationCountSource: "pets"
    },
    children: [
      {
        path: PET_CARE_ROUTES.Vet,
        element: <DesignateVetStep />,
        rules: {
          if: [
            {
              and: [
                { "==": [{ var: ITERATION_QUERY_KEY }, "0"] },
                { ">": [{ var: "numberOfPets" }, "1"] }
              ]
            },
            PET_CARE_ROUTES.SameVet,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: PET_CARE_ROUTES.SameVet,
        element: <DesignateSameVetStep />,
        rules: {
          if: [
            { "==": [{ var: "sameVeterinarian" }, true] },
            { path: PET_CARE_ROUTES.Medical, break: true },
            NEXT_ITERATION_RULE
          ]
        }
      }
    ],
    rules: PET_CARE_ROUTES.Medical
  },
  {
    path: PET_CARE_ROUTES.Medical,
    element: <MedicalStep />,
    rules: {
      if: [
        { "==": [{ var: "petAddMedicalNote" }, true] },
        PET_CARE_ROUTES.MedicalNoteLoop,
        PET_CARE_ROUTES.Review
      ]
    }
  },
  {
    path: PET_CARE_ROUTES.MedicalNoteLoop,
    loop: {
      enabled: true,
      iterationCountSource: "pets"
    },
    children: [
      {
        path: PET_CARE_ROUTES.MedicalNote,
        element: <MedicalNoteStep />,
        rules: NEXT_ITERATION_RULE
      }
    ],
    rules: PET_CARE_ROUTES.Review
  },
  {
    path: PET_CARE_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${PET_SECTION_ROUTES.SpecialPowers}/${PET_SPECIAL_POWERS_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={PET_CARE_ROUTES.Preview} />
  }
];

export default petCareSteps;
