import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CelebrationIcon from "@mui/icons-material/Celebration";

import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { EXPERT_MATCHING_ROUTES } from "@pages/EstateAdminQuiz/consts";
import { ROUTES } from "@setup/consts/routes";

import { EXPERT_MATCHING_RESULTS } from "../../consts";

const FinancialSuccessMessage: React.FC = () => (
  <>
    <Typography
      variant="h1"
      letterSpacing="0"
      textAlign="center"
      lineHeight="1.5"
      mb={{ xs: 2.5 }}
    >
      <FormattedMessage defaultMessage="Great news! You're an exellent fit for our financial experts." />
      <br />
      <CelebrationIcon />
    </Typography>
    <Box maxWidth="29.375rem" width="100%" m="0 auto">
      <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
        <FormattedMessage defaultMessage="The next step is to submit your info to get your expert match and a free consult." />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          width: "100%",
          minWidth: "auto"
        }}
        href={getLinkFullUrl([
          ROUTES.EstateAdminQuiz,
          EXPERT_MATCHING_ROUTES.Results,
          EXPERT_MATCHING_RESULTS.CollectUserInfo
        ])}
      >
        <FormattedMessage defaultMessage="Get My Expert" />
      </Button>
    </Box>
  </>
);

export default FinancialSuccessMessage;
