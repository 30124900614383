import { useFormField } from "@components/FormFields/hooks";
import SingleResponseArchetype from "@components/archetypes/SingleResponse";

const SpecialNoteDetailsStep: React.FC = () => {
  const { value: firstName } = useFormField("users.0.firstName");

  return (
    <SingleResponseArchetype
      fieldProps={{
        multiline: true,
        minRows: 5
      }}
      name="users.0.healthcare.preferences.additionalNote"
      label={`${firstName}'s Special Instructions...`}
      stepLayout={{
        title: `Sure thing. What should your note say?`
      }}
    />
  );
};

export default SpecialNoteDetailsStep;
