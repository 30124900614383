import { useMemo } from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";

import Tabs from "@components/Tabs";
import HierarchyGifts from "@components/HierarchyGifts";

import { DistributionTabProps } from "./types";
import useSpecificGiftModalProps from "./useSpecificGiftModalProps";
import useEstateBalanceHierarchy from "./useEstateBalanceHierarchy";

const SurvivorScenarios: React.FC<DistributionTabProps> = ({
  name,
  isTrust,
  specificGifts,
  estateBalanceGifts,
  partnerType,
  everythingToPartner,
  isJointTrust,
  partnerSpecificGifts,
  partnerEstateBalanceGifts,
  partnerEverythingToPartner,
  settlorNames
}) => {
  const { formatMessage } = useIntl();
  const [primaryName, partnerName] = settlorNames;

  const primarySpecificGiftModalProps =
    useSpecificGiftModalProps(specificGifts);

  const partnerSpecificGiftModalProps = useSpecificGiftModalProps(
    partnerSpecificGifts
  );

  const primaryEstateBalanceDistributions = useEstateBalanceHierarchy(
    estateBalanceGifts,
    partnerType
  );

  const partnerEstateBalanceDistributions = useEstateBalanceHierarchy(
    partnerEstateBalanceGifts,
    partnerType
  );

  const primaryDeceasedSettlorDistributions = useMemo(
    () => (
      <HierarchyGifts
        title="o"
        items={[
          {
            name: `Deceased Settlor Share (${primaryName})`,
            subItems: everythingToPartner
              ? [
                  {
                    name: `Gifts to Survivor Trust`,
                    moreInfoModalProps: {
                      title: "Gifts to Survivor Trust",
                      togglerLabel: "View Details",
                      content: (
                        <p>
                          {primaryName} gifts everything to{" "}
                          {partnerName}, to be held in the{" "}
                          {partnerName} Survivor Trust.
                        </p>
                      )
                    }
                  }
                ]
              : [
                  {
                    name: formatMessage({
                      defaultMessage: "Specific Gifts"
                    }),
                    moreInfoModalProps: primarySpecificGiftModalProps,
                    giftNote: primarySpecificGiftModalProps
                      ? undefined
                      : "You are not making any specific gifts"
                  },
                  {
                    name: formatMessage({
                      defaultMessage: "Trust Remainder"
                    }),
                    subItems: primaryEstateBalanceDistributions
                  }
                ]
          },
          {
            name: `Suvivor Trust (${partnerName})`,
            subItems: [
              {
                name: formatMessage({
                  defaultMessage: "Specific Gifts"
                }),
                moreInfoModalProps: partnerSpecificGiftModalProps,
                giftNote: partnerSpecificGiftModalProps
                  ? undefined
                  : "You are not making any specific gifts"
              },
              {
                name: formatMessage({
                  defaultMessage: "Trust Remainder"
                }),
                subItems: partnerEstateBalanceDistributions
              }
            ]
          }
        ]}
      />
    ),
    [
      everythingToPartner,
      formatMessage,
      primaryName,
      partnerName,
      partnerEstateBalanceDistributions,
      primaryEstateBalanceDistributions,
      partnerSpecificGiftModalProps,
      primarySpecificGiftModalProps
    ]
  );

  const partnerDeceasedSettlorDistributions = useMemo(
    () => (
      <HierarchyGifts
        title="o"
        items={[
          {
            name: `Deceased Settlor Share (${partnerName})`,
            subItems: partnerEverythingToPartner
              ? [
                  {
                    name: `Gifts to Survivor Trust`,
                    moreInfoModalProps: {
                      title: "Gifts to Survivor Trust",
                      togglerLabel: "View Details",
                      content: (
                        <p>
                          {partnerName} gifts everything to{" "}
                          {primaryName}, to be held in the{" "}
                          {primaryName} Survivor Trust.
                        </p>
                      )
                    }
                  }
                ]
              : [
                  {
                    name: formatMessage({
                      defaultMessage: "Specific Gifts"
                    }),
                    moreInfoModalProps: partnerSpecificGiftModalProps,
                    giftNote: partnerSpecificGiftModalProps
                      ? undefined
                      : "You are not making any specific gifts"
                  },
                  {
                    name: formatMessage({
                      defaultMessage: "Trust Remainder"
                    }),
                    subItems: partnerEstateBalanceDistributions
                  }
                ]
          },
          {
            name: `Suvivor Trust (${primaryName})`,
            subItems: [
              {
                name: formatMessage({
                  defaultMessage: "Specific Gifts"
                }),
                moreInfoModalProps: primarySpecificGiftModalProps,
                giftNote: primarySpecificGiftModalProps
                  ? undefined
                  : "You are not making any specific gifts"
              },
              {
                name: formatMessage({
                  defaultMessage: "Trust Remainder"
                }),
                subItems: primaryEstateBalanceDistributions
              }
            ]
          }
        ]}
      />
    ),
    [
      partnerEverythingToPartner,
      formatMessage,
      primaryName,
      partnerName,
      partnerEstateBalanceDistributions,
      primaryEstateBalanceDistributions,
      partnerSpecificGiftModalProps,
      primarySpecificGiftModalProps
    ]
  );

  const items = useMemo(
    () => [
      {
        label: `Deceased Settlor is ${primaryName}`,
        content: primaryDeceasedSettlorDistributions
      },
      {
        label: `Deceased Settlor is ${partnerName}`,
        content: partnerDeceasedSettlorDistributions
      }
    ],
    [
      primaryDeceasedSettlorDistributions,
      primaryName,
      partnerName,
      partnerDeceasedSettlorDistributions
    ]
  );

  return (
    <Box display="flex" flexDirection="column" mt="2.5rem">
      <Tabs {...{ items }} />
    </Box>
  );
};

export default SurvivorScenarios;
