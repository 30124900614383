import {
  Box,
  useTheme,
  useMediaQuery,
  Typography
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import LockIcon from "@mui/icons-material/Lock";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { ROUTES } from "@setup/consts/routes";

import { getLinkFullUrl } from "../utils";

import CreatePlanCard from "./CreatePlanCard";
import {
  lastWillPlanObject,
  trustPlanObject,
  petPlanObject
} from "./CreatePlanCard/consts";

interface TabNotAvailableProps {
  tabName: string;
  planDependencies: string;
  unlockWithPet?: boolean;
}

const TabNotAvailableMessage: React.FC<TabNotAvailableProps> = ({
  tabName,
  planDependencies,
  unlockWithPet
}) => {
  const { planStarted } = useMemberPlansState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const lwPath = planStarted.lastWillPath
    ? getLinkFullUrl([ROUTES.LastWill, planStarted.lastWillPath])
    : getLinkFullUrl([ROUTES.LastWillStart]);
  const petPath = planStarted.petProtectionPath
    ? getLinkFullUrl([ROUTES.PetPlan, planStarted.petProtectionPath])
    : getLinkFullUrl([ROUTES.PetStart]);
  const trustPath = planStarted.trustPath
    ? getLinkFullUrl([ROUTES.Trust, planStarted.trustPath])
    : getLinkFullUrl([ROUTES.TrustStart]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        alignItems: "center"
      }}
    >
      <LockIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
      <Typography fontWeight="bold" fontSize="1.375rem">
        {`${tabName} locked`}
      </Typography>
      <Typography>
        <FormattedMessage
          defaultMessage="Unlock {tabName} by completing your {planDependencies}."
          values={{ tabName, planDependencies }}
        />
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "1.25rem"
        }}
      >
        <CreatePlanCard
          name={trustPlanObject.name}
          image={trustPlanObject.image}
          path={trustPath}
          isStarted={planStarted.trust}
        />
        <CreatePlanCard
          name={lastWillPlanObject.name}
          image={lastWillPlanObject.image}
          path={lwPath}
          isStarted={planStarted.lastWill}
        />
        {unlockWithPet && (
          <CreatePlanCard
            name={petPlanObject.name}
            image={petPlanObject.image}
            path={petPath}
            isStarted={planStarted.petProtection}
          />
        )}
      </Box>
    </Box>
  );
};

export default TabNotAvailableMessage;
