export const TRUST_ALL_ABOUT_YOU_ROUTES = {
  Preview: "",
  Basics: "basics",
  Interstitial1: "interstitial-1",
  Partner: "relationship-status",
  PlanSelection: "plan-selection",
  ConfirmTrustType: "trust-type",
  PartnerDetails: "partner-details",
  HavingChildren: "having-children",
  ChildrenDetails: "children-details",
  Review: "review"
};
