import { ProviderProps, useCallback, useMemo } from "react";

import {
  useCreatePlanTodosMutation,
  useDeletePlanTodosMutation,
  useGetPlanTodos,
  useTogglePlanTodoStatusMutation,
  useUpdatePlanTodosMutation
} from "@api/plans/hooks";
import { PlanTodo } from "@api/plans/__generated__/PlanTodo";

import { PlanTodosContext } from "./context";
import { PlanTodosProviderProps } from "./types";

const PlanTodosProvider = ({
  children,
  value: { planId }
}: ProviderProps<PlanTodosProviderProps>): React.ReactElement => {
  const {
    data,
    loading,
    refetch: refetchTodos
  } = useGetPlanTodos({
    variables: {
      planId
    }
  });

  const todos = useMemo(() => data?.planTodos || [], [data]);

  const [callDeletePlanTodoMutation] = useDeletePlanTodosMutation({
    onCompleted: () => {
      refetchTodos();
    }
  });

  const [callCreatePlanTodoMutation] = useCreatePlanTodosMutation({
    onCompleted: () => {
      refetchTodos();
    },
    updateQueries: {
      PlanTodos: (previousQueryResult, { mutationResult }) => ({
        ...previousQueryResult,
        planTodos: [
          ...previousQueryResult.planTodos,
          mutationResult?.data?.createPlanTodo
        ]
      })
    }
  });

  const [callUpdatePlanTodoMutation] = useUpdatePlanTodosMutation({
    onCompleted: () => {
      refetchTodos();
    }
  });

  const [callTogglePlanTodoMutation] =
    useTogglePlanTodoStatusMutation({
      onCompleted: () => {
        refetchTodos();
      }
    });

  const updatePlanTodoTitle = useCallback(
    (planTodo: PlanTodo, title: string) => {
      callUpdatePlanTodoMutation({
        variables: {
          id: planTodo.id,
          title
        },
        optimisticResponse: {
          updatePlanTodo: {
            ...planTodo,
            title
          }
        }
      });
    },
    [callUpdatePlanTodoMutation]
  );

  const togglePlanTodoStatus = useCallback(
    (planTodo: PlanTodo, completed: boolean) => {
      callTogglePlanTodoMutation({
        variables: { id: planTodo.id, completed },
        optimisticResponse: {
          togglePlanTodoStatus: {
            ...planTodo,
            completedAt: completed ? new Date() : null
          }
        }
      });
    },
    [callTogglePlanTodoMutation]
  );

  const deletePlanTodo = useCallback(
    (planTodoId: number) => {
      callDeletePlanTodoMutation({
        variables: { id: planTodoId },
        optimisticResponse: {
          deletePlanTodo: true
        },
        updateQueries: {
          PlanTodos: (previousQueryResult) => ({
            ...previousQueryResult,
            planTodos: (previousQueryResult.planTodos ?? []).filter(
              (planTodo: PlanTodo) => planTodo.id !== planTodoId
            )
          })
        }
      });
    },
    [callDeletePlanTodoMutation]
  );

  const createPlanTodo = useCallback(
    (title: string) => {
      callCreatePlanTodoMutation({
        variables: { planId, title },
        optimisticResponse: {
          createPlanTodo: {
            __typename: "PlanTodo",
            completedAt: null,
            createdAt: new Date(),
            id: 0,
            order: todos.length,
            planId,
            title
          }
        }
      });
    },
    [callCreatePlanTodoMutation, planId, todos.length]
  );

  const contextValue = useMemo(
    () => ({
      todos,
      loading,
      togglePlanTodoStatus,
      updatePlanTodoTitle,
      deletePlanTodo,
      createPlanTodo
    }),
    [
      todos,
      loading,
      togglePlanTodoStatus,
      updatePlanTodoTitle,
      deletePlanTodo,
      createPlanTodo
    ]
  );

  return (
    <PlanTodosContext.Provider value={contextValue}>
      {children}
    </PlanTodosContext.Provider>
  );
};

export default PlanTodosProvider;
