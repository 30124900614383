import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";

const ResidueOverviewStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `We're going to review gifts from the balance of your estate, called the "Residuary Estate."`,
      muiIconHeader: <InfoOutlinedIcon />,
      subText:
        "Your Residuary Estate represents what's left over after making any specific gifts of assets or cash. Don't worry about those specific gifts yet -- we'll get to those in a bit. We're starting with your Residuary Estate because (for most people) the Residuary Estate is the largest portion of their gifts."
    }}
  />
);

export default ResidueOverviewStep;
