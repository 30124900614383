import {
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Divider
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";

import { ROUTES } from "@setup/consts/routes";

import { getLinkFullUrl } from "../utils";

import {
  lastWillPlanObject,
  trustPlanObject,
  petPlanObject
} from "./CreatePlanCard/consts";
import CreatePlanCard from "./CreatePlanCard";

const PlanNotStartedMessage = () => {
  const lwPath = getLinkFullUrl([ROUTES.LastWillStart]);
  const petPath = getLinkFullUrl([ROUTES.PetStart]);
  const trustPath = getLinkFullUrl([ROUTES.TrustStart]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        alignItems: "center"
      }}
    >
      <ScatterPlotIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
      <Typography variant="h3">
        <FormattedMessage defaultMessage="You haven't created your JIC Estates plan yet." />
      </Typography>
      <Typography>
        <FormattedMessage
          defaultMessage="
        Take the quiz to find the plan best for you."
        />
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.625rem"
        }}
      >
        <Button
          variant="contained"
          href={getLinkFullUrl([ROUTES.EstatePlansMatchMaking])}
        >
          <FormattedMessage defaultMessage="Take Quiz" />
        </Button>
        <Typography fontStyle="italic" align="center" variant="body2">
          <FormattedMessage defaultMessage="(< 1 minute)" />
        </Typography>
      </Box>

      <Divider
        sx={{
          width: "50%",
          color: theme.palette.grey["400"]
        }}
      >
        <FormattedMessage defaultMessage="or" />
      </Divider>
      <Typography>
        <FormattedMessage defaultMessage="Choose a plan below to get started." />
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "1.25rem"
        }}
      >
        <CreatePlanCard {...trustPlanObject} path={trustPath} />
        <CreatePlanCard {...lastWillPlanObject} path={lwPath} />

        <CreatePlanCard {...petPlanObject} path={petPath} />
      </Box>
    </Box>
  );
};

export default PlanNotStartedMessage;
