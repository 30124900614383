import { styled } from "@mui/material/styles";
import { Link as MaterialLink, LinkProps } from "@mui/material";

export const Link = styled(MaterialLink)<LinkProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  transition: "200ms ease",
  textDecoration: "none",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.text.secondary
    }
  }
}));
