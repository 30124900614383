import { gql } from "@apollo/client";

import { FINANCIAL_ADVISOR_METRICS_FRAGMENT } from "./fragments";

export const GET_FINANCIAL_ADVISOR_METRICS_QUERY = gql`
  ${FINANCIAL_ADVISOR_METRICS_FRAGMENT}
  query FinancialAdvisorMetricsQuery {
    financialAdvisorMetrics {
      ...FinancialAdvisorMetrics
    }
  }
`;
