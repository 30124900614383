import { Trust_trustees as Trustee } from "@api/trusts/__generated__/Trust";

import getAgentHierarchy from "./getAgentHierarchy";

const useTrusteeHierarchy = (
  trustees: Trustee[] | null | undefined
) => {
  if (!trustees || trustees.length === 0) {
    return [];
  }

  const agents = trustees
    .filter((agent) => agent)
    .map((agent) => ({
      isBackup: agent.isBackup,
      order: agent.order,
      name: agent.trustee?.fullName
    }));

  const hierarchy = getAgentHierarchy(agents);

  return hierarchy;
};

export default useTrusteeHierarchy;
