import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominateBackupAgentStep: React.FC = () => {
  const { setValue: setBackupAgentValues } = useFormField(
    "users.0.healthcare.backupAgents"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const nominateBackupAgent = get(
        data,
        "users.0.healthcare.nominateBackupAgent"
      );

      if (!parseBoolean(nominateBackupAgent)) {
        setBackupAgentValues([]);
      }
    },
    [setBackupAgentValues]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Would you like to designate a backup healthcare agent?`,
        subText: `A backup healthcare agent has all the same powers as the primary healthcare agent, but will only be called upon if the primary healthcare agent is otherwise unavaiable.`
      }}
      {...{ onSubmit }}
      name="users.0.healthcare.nominateBackupAgent"
    />
  );
};

export default NominateBackupAgentStep;
