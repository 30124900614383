import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanOption } from "@api/__generated__/globalTypes";
import { LAST_WILL_HEALTHCARE_ROUTES } from "@pages/LastWill/sections/Healthcare/consts";

import { LAST_WILL_FINAL_ARRANGEMENT_ROUTES } from "./consts";
import ReviewTabsStepPage from "./steps/ReviewTabs";
import SectionsPreviewStep from "./steps/Preview";
import RestingPlaceStep from "./steps/RestingPlace";
import CeremonyStep from "./steps/Ceremony";
import OtherRequestsStep from "./steps/OtherRequests";
import PartnerSameArrangementsStep from "./steps/PartnerSameArrangements";
import PartnerCeremonyStep from "./steps/PartnerCeremony";
import PartnerRestingPlaceStep from "./steps/PartnerRestingPlace";
import PartnerOtherRequestsStep from "./steps/PartnerOtherRequests";
import ReviewStep from "./steps/Review";

const finalArrangementsSteps: PlanFormStep[] = [
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.RestingPlace
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.RestingPlace,
    element: <RestingPlaceStep />,
    rules: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Ceremony
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Ceremony,
    element: <CeremonyStep />,
    rules: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.OtherRequests
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.OtherRequests,
    element: <OtherRequestsStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerSameArrangements,
        LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Review
      ]
    }
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerSameArrangements,
    element: <PartnerSameArrangementsStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.finalArrangements.copy" }, true] },
        LAST_WILL_FINAL_ARRANGEMENT_ROUTES.ReviewTabs,
        LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace
      ]
    }
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace,
    element: <PartnerRestingPlaceStep />,
    rules: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony,
    element: <PartnerCeremonyStep />,
    rules: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerOtherRequests
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerOtherRequests,
    element: <PartnerOtherRequestsStep />,
    rules: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.ReviewTabs
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${LAST_WILL_SECTION_ROUTES.Healthcare}/${LAST_WILL_HEALTHCARE_ROUTES.Preview}`
  },
  {
    path: LAST_WILL_FINAL_ARRANGEMENT_ROUTES.ReviewTabs,
    element: <ReviewTabsStepPage />,
    rules: `${LAST_WILL_SECTION_ROUTES.Healthcare}/${LAST_WILL_HEALTHCARE_ROUTES.Preview}`
  },
  {
    path: "*",
    element: (
      <Navigate to={LAST_WILL_FINAL_ARRANGEMENT_ROUTES.Preview} />
    )
  }
];

export default finalArrangementsSteps;
