import { useMemo } from "react";
import PetsIcon from "@mui/icons-material/Pets";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StarsIcon from "@mui/icons-material/Stars";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import { useIntl } from "react-intl";

import { ReviewReturnType } from "../types";

import { usePetCareReview } from "./usePetCareReview";
import { usePetGuardiansReview } from "./usePetGuardiansReview";
import { usePetSpecialPowersReview } from "./usePetSpecialPowersReview";
import { usePetAllAboutYouReview } from "./usePetAllAboutYouReview";

export const useAllPetPlanReviews = (): ReviewReturnType => {
  const allAboutYouItems = usePetAllAboutYouReview();
  const careReviewItems = usePetCareReview();
  const guardianReviewItems = usePetGuardiansReview();
  const specialPowersReviewItems = usePetSpecialPowersReview();

  const { formatMessage } = useIntl();

  return useMemo<ReviewReturnType>(
    () => [
      {
        tab: formatMessage({
          defaultMessage: "Your Pet Fam"
        }),
        items: allAboutYouItems,
        icon: <PetsIcon />
      },
      {
        tab: formatMessage({
          defaultMessage: "Guardian"
        }),
        items: guardianReviewItems,
        icon: <AssignmentIndIcon />
      },
      {
        tab: formatMessage({
          defaultMessage: "Care Review"
        }),
        items: careReviewItems,
        icon: <HealthAndSafetyIcon />
      },
      {
        tab: formatMessage({
          defaultMessage: "Special Powers"
        }),
        items: specialPowersReviewItems,
        icon: <StarsIcon />
      }
    ],
    [
      formatMessage,
      allAboutYouItems,
      guardianReviewItems,
      careReviewItems,
      specialPowersReviewItems
    ]
  );
};
