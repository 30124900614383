import { Plans_plans_lastWills_assets_beneficiaries as Beneficiary } from "@api/plans/__generated__/Plans";

export const getBeneficiaryInfo = (
  beneficiaries: (Beneficiary | null)[] | null | undefined,
  isFunded?: boolean | null,
  showIsFunded?: boolean
) => {
  if (showIsFunded && beneficiaries && beneficiaries.length > 0) {
    // Trust Gift with Beneficiaries
    return `${
      isFunded
        ? "🎁  This asset is a specific gift "
        : "🎁  If this asset is funded into your Trust, it will be distributed as a specific gift "
    } for the following beneficiaries: ${beneficiaries
      .filter((beneficiary) => beneficiary?.isBackup === false)
      .map(
        (beneficiary) =>
          `${beneficiary?.percentageValue}% to ${
            beneficiary?.user?.fullName ??
            beneficiary?.institution?.name
          }`
      )
      .join(", ")}`;
  }
  if (showIsFunded && beneficiaries?.length === 0) {
    return `${
      isFunded
        ? "🎁  This asset"
        : "🎁  If this asset is funded into your Trust, it "
    } will be distributed with your Trust Remainder.`;
  }

  if (beneficiaries && beneficiaries.length > 0) {
    // LastWill Gift with Beneficiaries
    return `🎁  This asset is a specific gift for the following beneficiaries: ${beneficiaries
      .filter((beneficiary) => beneficiary?.isBackup === false)
      .map(
        (beneficiary) =>
          `${beneficiary?.percentageValue}% to ${
            beneficiary?.user?.fullName ??
            beneficiary?.institution?.name
          }`
      )
      .join(", ")}`;
  }

  return "";
};
