/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DocumentNode,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery
} from "@apollo/client";

export const makeMutationHook =
  <TData = any, TVariables = OperationVariables>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>
  ): ((
    options?: MutationHookOptions<TData, TVariables>
  ) => MutationTuple<TData, TVariables>) =>
  (options?: MutationHookOptions<TData, TVariables>) =>
    useMutation<TData, TVariables>(mutation, options);

export const makeQueryHook =
  <TData = any, TVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>
  ): ((
    options?: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>) =>
  (options?: QueryHookOptions<TData, TVariables>) =>
    useQuery<TData, TVariables>(query, options);

export const makeLazyQueryHook =
  <TData = any, TVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>
  ): ((
    options?: LazyQueryHookOptions<TData, TVariables>
  ) => QueryTuple<TData, TVariables>) =>
  (options?: LazyQueryHookOptions<TData, TVariables>) =>
    useLazyQuery<TData, TVariables>(query, options);
