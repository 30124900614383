import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const NoChildrenNoGuardiansStep: React.FC = () => {
  const { value: haveChildren } = useFormField(`haveChildren`);

  if (!haveChildren) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `Welp! This one sure is easy 😉!`,
          isWide: true,
          subText:
            "Since children aren't in the picture right now, we'll skip appointing any child guardians. If that ever changes, you can always come back and update your documents."
        }}
      />
    );
  }

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `No guardians, no problem.`,
        subText: "That certainly makes this section pretty easy 😉."
      }}
    />
  );
};

export default NoChildrenNoGuardiansStep;
