/* eslint-disable react/no-unescaped-entities */
import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const PowersDelegatePowersStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `Do you want to enable your agent to delegate their powers under this financial power of attorney?`,
      subText: `Learn more about delegating powers.`,
      moreInfoModalProps: {
        title: "Authority to Delegate Powers",
        togglerLabel: "Learn more",
        content: (
          <p>
            Granting your agent the authority to delegate his or her
            powers means that your agent can entrust another person
            with your agent's responsibilities.
            <br />
            <br />
            This authority to delegate may be helpful if your primary
            agent is temporarily unavailable and needs someone else to
            serve for a period of time.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    name="users.0.poa.additionalPowers.delegate"
  />
);

export default PowersDelegatePowersStep;
