import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";

import { useUpdateDocument, useUpdateDocumentForm } from "./hooks";
import { UpdateDocumentFormProps } from "./types";

const UpdateDocumentForm: React.FC<UpdateDocumentFormProps> = ({
  id,
  name = ""
}) => {
  const { formatMessage } = useIntl();
  const { loading, onSubmit } = useUpdateDocument({ id });
  const { methods, handleSubmit } = useUpdateDocumentForm({
    onSubmit,
    defaultValues: {
      name
    }
  });

  const disableForm =
    !!methods.formState.errors.name || !methods.formState.isDirty;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <FormControl variant="standard" sx={{ display: "flex" }}>
          <BasicFormInput
            name="name"
            label={formatMessage({
              defaultMessage: "Name"
            })}
          />
        </FormControl>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            mt: "1.2rem"
          }}
        >
          <Button
            disabled={disableForm}
            type="submit"
            sx={{ width: "60%", margin: "0 auto" }}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Update" />
          </Button>
        </Box>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </FormProvider>
  );
};

export default UpdateDocumentForm;
