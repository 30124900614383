import { useMemo } from "react";
import { useIntl } from "react-intl";

import { HierarchyGiftItem } from "@components/HierarchyGifts/types";
import { LastWill_assets as Asset } from "@api/plans/__generated__/LastWill";

import useSpecificGiftModalProps from "./useSpecificGiftModalProps";
import {
  deceasedSettlorShareModalProps,
  survivorTrustModalProps
} from "./consts";

const useRevTrustDistributionHierarchy = (
  specificGifts: (Asset | null)[] | undefined,
  everythingToPartner?: boolean,
  isJointTrust?: boolean
): HierarchyGiftItem[] => {
  const { formatMessage } = useIntl();

  const specificGiftModalProps =
    useSpecificGiftModalProps(specificGifts);

  return useMemo(
    () => [
      {
        name: formatMessage({
          defaultMessage: "Unfunded Non-Probate Assets*"
        })
      },
      {
        name: formatMessage({
          defaultMessage: "Pour Over Will**"
        })
      },
      {
        name: formatMessage(
          {
            defaultMessage: "{trustType}"
          },
          {
            trustType: isJointTrust
              ? "Revocable Joint Trust"
              : "Revocable Trust"
          }
        ),
        subItems: isJointTrust
          ? [
              {
                name: formatMessage({
                  defaultMessage: "Deceased Settlor Distributions"
                }),
                moreInfoModalProps: deceasedSettlorShareModalProps,
                giftNote: "(see details below)"
              },
              {
                name: formatMessage({
                  defaultMessage: "Survivor Trust"
                }),
                moreInfoModalProps: survivorTrustModalProps,
                giftNote: "(see details below)"
              }
            ]
          : [
              {
                name: formatMessage({
                  defaultMessage: "Specific Gifts"
                }),
                moreInfoModalProps: specificGiftModalProps,
                giftNote: specificGiftModalProps
                  ? undefined
                  : "You are not making any specific gifts"
              },
              {
                name: formatMessage({
                  defaultMessage: "Trust Remainder"
                }),
                giftNote: "(see details below)"
              }
            ]
      }
    ],
    [formatMessage, isJointTrust, specificGiftModalProps]
  );
};

export default useRevTrustDistributionHierarchy;
