import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";

const InterstitialStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title:
        "Excellent! That's enough to get started on the legal side of the estate for now.",
      muiIconHeader: <ThumbUpAltOutlinedIcon />,
      subText:
        "Let's continue with a few quick questions on the finances you want help with.",
      isWide: true
    }}
  />
);

export default InterstitialStep;
