import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SelectItem } from "@components/FormFields/FormSelect/types";

export const useFiftyStatePlusInternationalItems =
  (): SelectItem[] => {
    const intl = useIntl();

    const items = useMemo(
      () => [
        {
          label: intl.formatMessage({
            defaultMessage: "Outside USA"
          }),
          value: "International"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Alabama" }),
          value: "Alabama"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Alaska" }),
          value: "Alaska"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Arizona" }),
          value: "Arizona"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Arkansas" }),
          value: "Arkansas"
        },
        {
          label: intl.formatMessage({ defaultMessage: "California" }),
          value: "California"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Colorado" }),
          value: "Colorado"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Connecticut"
          }),
          value: "Connecticut"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Delaware" }),
          value: "Delaware"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "District of Columbia"
          }),
          value: "District of Columbia"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Florida" }),
          value: "Florida"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Georgia" }),
          value: "Georgia"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Hawaii" }),
          value: "Hawaii"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Idaho" }),
          value: "Idaho"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Illinois" }),
          value: "Illinois"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Indiana" }),
          value: "Indiana"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Iowa" }),
          value: "Iowa"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Kansas" }),
          value: "Kansas"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Kentucky" }),
          value: "Kentucky"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Louisiana" }),
          value: "Louisiana"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Maine" }),
          value: "Maine"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Maryland" }),
          value: "Maryland"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Massachusetts"
          }),
          value: "Massachusetts"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Michigan" }),
          value: "Michigan"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Minnesota" }),
          value: "Minnesota"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Mississippi"
          }),
          value: "Mississippi"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Missouri" }),
          value: "Missouri"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Montana" }),
          value: "Montana"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Nebraska" }),
          value: "Nebraska"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Nevada" }),
          value: "Nevada"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "New Hampshire"
          }),
          value: "New Hampshire"
        },
        {
          label: intl.formatMessage({ defaultMessage: "New Jersey" }),
          value: "New Jersey"
        },
        {
          label: intl.formatMessage({ defaultMessage: "New Mexico" }),
          value: "New Mexico"
        },
        {
          label: intl.formatMessage({ defaultMessage: "New York" }),
          value: "New York"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "North Carolina"
          }),
          value: "North Carolina"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "North Dakota"
          }),
          value: "North Dakota"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Ohio" }),
          value: "Ohio"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Oklahoma" }),
          value: "Oklahoma"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Oregon" }),
          value: "Oregon"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Pennsylvania"
          }),
          value: "Pennsylvania"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Rhode Island"
          }),
          value: "Rhode Island"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "South Carolina"
          }),
          value: "South Carolina"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "South Dakota"
          }),
          value: "South Dakota"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Tennessee" }),
          value: "Tennessee"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Texas" }),
          value: "Texas"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Utah" }),
          value: "Utah"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Vermont" }),
          value: "Vermont"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Virginia" }),
          value: "Virginia"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Washington" }),
          value: "Washington"
        },
        {
          label: intl.formatMessage({
            defaultMessage: "West Virginia"
          }),
          value: "West Virginia"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Wisconsin" }),
          value: "Wisconsin"
        },
        {
          label: intl.formatMessage({ defaultMessage: "Wyoming" }),
          value: "Wyoming"
        }
      ],
      [intl]
    );

    return items;
  };
