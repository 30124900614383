import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const DesignateBackupTrusteeStep: React.FC = () => (
  <PersonDetailsArrayArchetype
    name="users.0.trust.successorTrustees"
    stepLayout={{
      title: "Who should be the Successor Trustee?",
      subText:
        "You can add additional Successor Trustees by hitting the 'Add Another Successor Trustee' button."
    }}
    personRole="Successor Trustee"
    addBtnLabel="Add Another Successor Trustee"
    removeBtnLabel="Remove Successor Trustee"
  />
);

export default DesignateBackupTrusteeStep;
