import {
  Button,
  CircularProgress,
  Typography,
  useTheme
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { useMemberPlansState } from "@providers/memberPlansState/context";

import { useUpdateDocuments } from "./hooks";

const UpdateDocumentsButton = () => {
  const theme = useTheme();

  const { regeneratePlanDocuments, loading } = useUpdateDocuments();
  const { trust, lastWill } = useMemberPlansState();
  const onUpdateDocuments = useCallback(() => {
    if (trust?.plan?.id) {
      regeneratePlanDocuments({
        variables: {
          planId: trust?.plan?.id
        }
      });
    }

    if (lastWill?.plan?.id) {
      regeneratePlanDocuments({
        variables: {
          planId: lastWill?.plan?.id
        }
      });
    }
  }, [lastWill, trust, regeneratePlanDocuments]);

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Button
        sx={{
          mt: "1.2rem"
        }}
        onClick={onUpdateDocuments}
        variant="outlined"
      >
        <FormattedMessage defaultMessage="Update Documents" />
      </Button>
      <Typography
        style={{
          fontSize: "1rem",
          color: theme.palette.grey["500"]
        }}
        sx={{
          mt: "1rem"
        }}
      >
        <FormattedMessage defaultMessage="Regenerate your documents with your most up-to-date information" />
      </Typography>
    </Box>
  );
};

export default UpdateDocumentsButton;
