import { useCallback, useMemo } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import {
  PartnerType,
  PlanOption
} from "@api/__generated__/globalTypes";
import { RelationshipStatus } from "@setup/enums";

const PartnerStep: React.FC = () => {
  const { setValue: setRelationshipStatusValue } =
    useFormField<RelationshipStatus>("users.0.relationshipStatus");

  const { setValue: setPartnerType } =
    useFormField<PartnerType>("partnerType");
  const { setValue: setPlanType } =
    useFormField<PlanOption>("planOption");
  const { setValue: setPartnerValue } = useFormField("users.1");

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const relationshipStatus = get(
        data,
        "users.0.relationshipStatus"
      ) as RelationshipStatus;

      if (!relationshipStatus) {
        setRelationshipStatusValue(RelationshipStatus.SINGLE);
        setPartnerType(PartnerType.SINGLE);
        setPlanType(PlanOption.SINGLE);
        setPartnerValue({});
      }
      if (
        relationshipStatus === RelationshipStatus.SINGLE ||
        relationshipStatus === RelationshipStatus.DIVORCED
      ) {
        setPartnerType(PartnerType.SINGLE);
        setPlanType(PlanOption.SINGLE);
        setPartnerValue({});
      }
      if (relationshipStatus === RelationshipStatus.MARRIED) {
        setPartnerType(PartnerType.SPOUSE);
      }
      if (
        relationshipStatus ===
          RelationshipStatus.REGISTERED_DOMESTIC_PARTNERSHIP ||
        relationshipStatus === RelationshipStatus.CIVIL_UNION
      ) {
        setPartnerType(PartnerType.PARTNER);
      }
      if (relationshipStatus === RelationshipStatus.ENGAGED) {
        setPartnerType(PartnerType.FIANCE);
      }
    },
    [
      setRelationshipStatusValue,
      setPlanType,
      setPartnerValue,
      setPartnerType
    ]
  );

  const relationshipStatusOptions = useMemo(
    () => [
      {
        label: "Single",
        value: RelationshipStatus.SINGLE
      },
      {
        label: "Married",
        value: RelationshipStatus.MARRIED
      },
      {
        label: "Registered Domestic Partnership",
        value: RelationshipStatus.REGISTERED_DOMESTIC_PARTNERSHIP
      },
      {
        label: "Civil Union",
        value: RelationshipStatus.CIVIL_UNION
      },
      {
        label: "Engaged",
        value: RelationshipStatus.ENGAGED
      },
      {
        label: "Divorced",
        value: RelationshipStatus.DIVORCED
      }
    ],
    []
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: "What's your marital status?"
      }}
      name="users.0.relationshipStatus"
      items={relationshipStatusOptions}
      onSubmit={onSubmit}
    />
  );
};

export default PartnerStep;
