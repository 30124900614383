import { gql } from "@apollo/client";

export const SEND_MARKETING_DATA = gql`
  mutation SendMarketingData(
    $email: String!
    $data: JSON!
    $klaviyoListId: String
  ) {
    sendMarketingData(
      email: $email
      data: $data
      klaviyoListId: $klaviyoListId
    )
  }
`;
