import { toNumber } from "lodash";

import { Beneficiary } from "@pages/LastWill/types";

import {
  AssetFormValues,
  AssetWithoutBackupBeneficiaries
} from "../types";

const mapFormValuesToAsset = (
  data: AssetFormValues
): AssetWithoutBackupBeneficiaries => {
  const timestamp = Date.now().toString();

  return {
    id: data.id ?? timestamp,
    type: data.type,
    name:
      data.type === "REAL_ESTATE" && data.address
        ? data.address.address1
        : data.name ?? undefined,
    subType: data.subtype,
    grossValue: data.grossValue
      ? toNumber(data.grossValue)
      : undefined,
    isGift: !!data.isGift,
    isFunded: !!data.isFunded,
    address: data.address,
    institution: data.institution,
    beneficiaries: data.isGift
      ? (data.beneficiaries || []).map(
          (beneficiary: Beneficiary) => ({
            backupDistribution: beneficiary.backupDistribution,
            dollarValue: beneficiary.dollarValue
              ? toNumber(beneficiary.dollarValue) || 0
              : undefined,
            percentageValue: beneficiary.percentageValue
              ? toNumber(beneficiary.percentageValue) || 0
              : undefined,
            name: beneficiary.name,
            charity: beneficiary.charity?.name
              ? {
                  name: beneficiary.charity.name,
                  ein: beneficiary.charity.ein,
                  address: beneficiary.charity.address,
                  isCharity: true
                }
              : undefined,
            // TODO: If UI will allow to add backup beneficiaries, update it.
            backupBeneficiaries: []
          })
        )
      : []
  };
};

export default mapFormValuesToAsset;
