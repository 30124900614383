import StateSelectArchetype from "@components/archetypes/StateSelect";

const StateJurisdictionStep: React.FC = () => (
  <StateSelectArchetype
    stepLayout={{
      title: `In which state did the deceased person live?`,
      subText:
        "Different states have different rules for estate administration. We'll use this information to make your recommendation state-specific."
    }}
    name="estateData.state"
  />
);

export default StateJurisdictionStep;
