import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/PetPlan/routes";
import { usePlanForm } from "@providers/planForm/context";
import { PetPlanValues } from "@pages/PetPlan/types";

const SectionsPreviewStep: React.FC = () => {
  const { values } = usePlanForm<PetPlanValues>();

  const {
    pets
  } = values

  let petQtyReferral = "those good fellas up with their Pet Guardians";
  if (pets && pets.length == 1) {
    petQtyReferral = "that good fella up with a Pet Guardian"
  }

  return (
  <SectionsPreview
    title="Pet Guardians"
    {...{ sections }}
    stepLayout={{
      title:
        `Sweet! Let's set ${petQtyReferral}.`,
    }}
  />
)
};

export default SectionsPreviewStep;
