import { gql } from "@apollo/client";

export const VESTING_SCHEDULE_FRAGMENT = gql`
  fragment VestingSchedule on VestingSchedule {
    subTrustId
    age
    percentageValue
  }
`;

export const TRUSTEE_FRAGMENT = gql`
  fragment Trustee on Trustee {
    trustId
    trusteeId
    isBackup
    order
    trustee {
      ...User
    }
  }
`;

export const SUBTRUST_FRAGMENT = gql`
  ${VESTING_SCHEDULE_FRAGMENT}
  fragment SubTrust on SubTrust {
    id
    planId
    trustId
    name
    trustType
    restrictions
    appointmentPower
    vestingType
    terminationDistribution
    vestingSchedule {
      ...VestingSchedule
    }
    userId
    beneficiaryId
  }
`;

export const TRUST_FRAGMENT = gql`
  ${SUBTRUST_FRAGMENT}
  fragment Trust on Trust {
    id
    planId
    createdAt
    restatedAt
    name
    trusteesCompensationType
    trusteesCompensationDollar
    trusteesCompensationPercent
    trustees {
      isBackup
      order
      trustee {
        ...User
      }
    }
    subTrusts {
      ...SubTrust
    }
    userId
  }
`;
