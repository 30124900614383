import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "@api/me";
import { SUBTRUST_FRAGMENT } from "@api/trusts";

export const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    id
    address1
    address2
    city
    state {
      id
      name
      code
    }
    zip
    county {
      id
      name
    }
    city
    country {
      id
      name
    }
  }
`;

export const HEALTHCARE_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment Healthcare on Healthcare {
    id
    specifiedPreferences
    generalGuidance
    continueToProvideTreatment
    artificialNutrition
    reliefFromPain
    reliefFromPainCondition
    organDonation
    additionalNote
    organDonationPurposes
    specifiedOrgans {
      id
      name
    }
    authorizedPerson {
      agentId
      isAgent
      isBackup
      order
      agent {
        ...User
      }
    }
  }
`;

export const POWER_OF_ATTORNEY_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment PowerOfAttorney on PowerOfAttorney {
    id
    agents {
      agentId
      isSuccessor
      order
      agent {
        ...User
      }
    }
    generalAuthority
    powers
    compensation
    compensationType
    compensationLimit
    giftRecipientsType
    giftRecipientsList
    giftRecipientsAllowtoAgent
    giftRecipientsLimitType
    giftRecipientsLimitAmount
  }
`;

export const INSTITUTION_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment Institution on Institution {
    id
    name
    ein
    isCharity
    addressId
    address {
      ...Address
    }
  }
`;

export const BENEFICIARY_FRAGMENT = gql`
  ${USER_FRAGMENT}
  ${INSTITUTION_FRAGMENT}
  ${SUBTRUST_FRAGMENT}
  fragment Beneficiary on Beneficiary {
    id
    user {
      ...User
    }
    isBackup
    order
    heir
    distribType
    backupDistribution
    primaryBeneficiaryId
    backupBeneficiaries {
      id
      user {
        ...User
      }
      institution {
        ...Institution
      }
      subTrust {
        ...SubTrust
      }
      dollarValue
      percentageValue
      isBackup
      primaryBeneficiaryId
      order
    }
    dollarValue
    percentageValue
    subTrust {
      ...SubTrust
    }
    institution {
      ...Institution
    }
  }
`;

export const ASSET_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  ${BENEFICIARY_FRAGMENT}
  fragment Asset on Asset {
    id
    lastWillId
    trustId
    name
    type
    subtype
    addressId
    ownerId
    institution
    grossValue
    liabilityValue
    isGift
    isFunded
    address {
      ...Address
    }
    owner {
      id
      fullName
    }
    beneficiaries {
      ...Beneficiary
    }
  }
`;

export const CHILD_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment Child on Child {
    id
    parents {
      ...User
    }
    prohibitedGuardians {
      guardianId
      reason
      guardian {
        ...User
      }
    }
    guardians {
      guardianId
      relationToParentId
      isBackup
      childId
      order
      guardian {
        ...User
      }
    }
    user {
      ...User
    }
  }
`;

export const LAST_WILL_FRAGMENT = gql`
  ${HEALTHCARE_FRAGMENT}
  ${POWER_OF_ATTORNEY_FRAGMENT}
  ${CHILD_FRAGMENT}
  ${ASSET_FRAGMENT}
  fragment LastWill on LastWill {
    id
    userId
    specialRequest
    ceremonyType
    bodyRestingPlace
    partnerAsHealthcarePrimaryAgent
    percentGaveToPartner
    partnerAsChildGuardian
    personalPropertyBalanceDistribution
    netWorth
    excludedPersons {
      id
      excludedPersonReason
      excludedPerson {
        ...User
      }
    }
    healthcare {
      ...Healthcare
    }
    powerOfAttorney {
      ...PowerOfAttorney
    }
    representativesCompensationType
    representativesCompensationDollar
    representativesCompensationPercent
    includeNoContest
    representatives {
      isBackup
      order
      representative {
        ...User
      }
    }
    childs {
      ...Child
    }
    assets {
      ...Asset
    }
  }
`;

export const MEMBERSHIPS_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment Membership on Membership {
    id
    planId
    userId
    permissionLevel
    roles
    status
    user {
      ...User
    }
  }
`;

export const VET_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment Vet on Vet {
    id
    userId
    user {
      firstName
      fullName
      lastName
      id
      phone
      email
      address {
        ...Address
      }
    }
  }
`;

export const GUARDIAN_FRAGMENT = gql`
  fragment Guardian on Guardian {
    id
    userId
    user {
      firstName
      fullName
      lastName
      id
      phone
      email
    }
  }
`;

export const PET_FRAGMENT = gql`
  ${VET_FRAGMENT}
  ${USER_FRAGMENT}
  fragment Pet on Pet {
    id
    name
    kind
    careInstructions
    medicalInstructions
    characteristics
    vetId
    petProtectionId
    feedingInstructions
    guardians {
      petId
      guardianId
      relationToOwnerId
      isBackup
      order
      guardian {
        ...User
      }
    }
    backupGuardians {
      petId
      guardianId
      relationToOwnerId
      isBackup
      order
      guardian {
        ...User
      }
    }
    vet {
      ...Vet
    }
  }
`;

export const PET_PROTECTION_FRAGMENT = gql`
  ${PET_FRAGMENT}
  fragment PetProtection on PetProtection {
    id
    planId
    areMorePets
    allowGuardianIntoTransactions
    guardianTransactionsMaxAmount
    areAsideFundsSet
    asideFundsAmount
    grantGuardianAccessToHealthInformation
    pets {
      ...Pet
    }
  }
`;

export const PLAN_TODO_FRAGMENT = gql`
  fragment PlanTodo on PlanTodo {
    id
    planId
    order
    title
    createdAt
    completedAt
  }
`;
