/* eslint-disable react/no-unescaped-entities */
import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { parseBoolean } from "@utils/parseBoolean";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";

const PowersMakeGiftsStep: React.FC = () => {
  const { setValue: setPOAGiftValues } = useFormField(
    "users.0.poa.giftRecipients"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const makeGifts = get(
        data,
        "users.0.poa.additionalPowers.makeGifts"
      );

      if (!parseBoolean(makeGifts)) {
        setPOAGiftValues({});
      }
    },
    [setPOAGiftValues]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to provide your agent the ability to make gifts on your behalf?`,
        subText: `Learn more giftmaking authorization.`,
        moreInfoModalProps: {
          title: "Authority to Make Gifts",
          togglerLabel: "Learn more",
          content: (
            <p>
              A "gift" is any transaction in which you give or
              exchange something to another person or organization for
              nothing or less than fair market value in return.
              <br />
              <br />
              Granting your agent the ability to make gifts on your
              behalf comes with certain default limitations on your
              agent's gift-making authority that you can choose to
              further enlarge or contract. If you authorize your agent
              to make gifts, we'll walk you through some of your
              options for these adjustments in the next few questions.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.0.poa.additionalPowers.makeGifts"
      {...{ onSubmit }}
    />
  );
};

export default PowersMakeGiftsStep;
