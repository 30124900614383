import { Navigate, RouteObject } from "react-router-dom";

import { MATCHMAKING_ROUTES } from "./consts";
import ChooseOptionsStep from "./ChooseOptions";
import MatchMakingResults from "./Results";

const matchmakingSteps: RouteObject[] = [
  {
    path: MATCHMAKING_ROUTES.Quiz,
    element: <ChooseOptionsStep />
  },
  {
    path: MATCHMAKING_ROUTES.Results,
    element: <MatchMakingResults />
  },
  {
    path: "*",
    element: <Navigate to={MATCHMAKING_ROUTES.Quiz} />
  }
];

export default matchmakingSteps;
