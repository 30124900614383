/* eslint-disable react/no-unescaped-entities */
import { useIntl } from "react-intl";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ModalToggler from "@components/Modal/partials/ModalToggler";
import Modal from "@components/Modal";
import useToggle from "@hooks/useToggle";

const RecommendedPlanInfoModal: React.FC = () => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <>
      <ModalToggler
        icon={<OpenInNewIcon fontSize="small" color="inherit" />}
        label={intl.formatMessage({
          defaultMessage: "Learn about recommending plans."
        })}
        onClick={toggleOn}
      />
      <Modal
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Recommending Plans"
        })}
        content={
          <p>
            You have the option to recommend that your client complete
            either a Last Will or Revocable Living Trust plan.
            <br />
            <br />
            When you choose to recommend one of these plans, Just In
            Case Estates automatically starts your client with that
            plan's questionnaire, and we can better customize support
            messaging for getting started.
            <br />
            <br />
            If your client chooses, your client can switch to a plan
            different than the one that you recommended.
            <br />
            <br />
            <strong>Last Will & Testament.</strong> The "core" estate
            planning vehicle. Your client will create a Last Will,
            nominate guardians for minor children (if applicable), and
            also have the opportunity to create a Durable Financial
            Power of Attorney and/or Advance Healthcare Directive.
            <br />
            <br />
            <strong>Revocable Living Trust.</strong> Everything in the
            Last Will & Testament module, except that your client will
            create a Pour Over Will to pair with the Revocable Living
            Trust.
          </p>
        }
        actions={[
          {
            label: intl.formatMessage({ defaultMessage: "Got it" }),
            onClick: toggleOff,
            variant: "outlined",
            color: "inherit"
          }
        ]}
      />
    </>
  );
};

export default RecommendedPlanInfoModal;
