export const ROUTES = {
  Root: "/dashboard",
  EstatePlansMatchMaking: "/quiz",
  EstateAdminQuiz: "/find-my-expert",
  SignUp: "/sign-up",
  AdvisorSignUp: "/advisor-sign-up",
  SignIn: "/sign-in",
  ResetPassword: "/reset-password",
  SignInExistingAccount: "/sign-in-existing-account",
  Tutorial: "/tutorial",
  SignUpConfirmation: "/confirm-sign-up",
  LastWill: "/last-will",
  LastWillStart: "/last-will/all-about-you",
  PowerOfAttorney: "/poa",
  Healthcare: "/ahcd",
  Trust: "/trust",
  TrustStart: "/trust/all-about-you",
  Dashboard: "/dashboard",
  Invitation: "/invitation",
  PetPlan: "/pet",
  PetStart: "/pet/all-about-you"
};
