/* eslint-disable no-irregular-whitespace */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePetBreedsFish = (): AutocompleteBaseOption[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({ defaultMessage: `Angelfish` }),
        value: "Angelfish"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Betta` }),
        value: "Betta"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Black Molly` }),
        value: "Black Molly"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Cherry Barb` }),
        value: "Cherry Barb"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Cory Catfish` }),
        value: "Cory Catfish"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Danio` }),
        value: "Danio"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Discus` }),
        value: "Discus"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Firemouth Cichlid`
        }),
        value: "Firemouth Cichlid"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `German Blue Ram`
        }),
        value: "German Blue Ram"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Goldfish` }),
        value: "Goldfish"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Guppy` }),
        value: "Guppy"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Harlequin Rasboras`
        }),
        value: "Harlequin Rasboras"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Koi` }),
        value: "Koi"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Kuhli Loach` }),
        value: "Kuhli Loach"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Lionhead Cichlid`
        }),
        value: "Lionhead Cichlid"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Marble Hatchetfish`
        }),
        value: "Marble Hatchetfish"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Neon Tetra` }),
        value: "Neon Tetra"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Pearl Gourami`
        }),
        value: "Pearl Gourami"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Platy` }),
        value: "Platy"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Red-Tailed Shark`
        }),
        value: "Red-Tailed Shark"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Royal Pleco` }),
        value: "Royal Pleco"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Swordtail` }),
        value: "Swordtail"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Tiger Barb` }),
        value: "Tiger Barb"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Upside-Down Catfish`
        }),
        value: "Upside-Down Catfish"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `White Cloud Mountain Minnow`
        }),
        value: "White Cloud Mountain Minnow"
      }
    ],
    [intl]
  );

  return items;
};
