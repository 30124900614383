import { createContext, useContext } from "react";

import { MemberPlansState } from "./type";

const MemberPlansStateContext = createContext<MemberPlansState>({
  plans: [],
  trust: undefined,
  lastWill: undefined,
  petProtection: undefined,
  myRoles: undefined,
  loading: false,
  planCompleted: false,
  planStarted: {
    isStarted: false,
    trust: false,
    lastWill: false,
    petProtection: false
  }
});

const useMemberPlansState = () => useContext(MemberPlansStateContext);

export { MemberPlansStateContext, useMemberPlansState };
