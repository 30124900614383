import { Typography, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import ArticleIconOutlined from "@mui/icons-material/ArticleOutlined";

import ModalStepFooter from "@components/ModalStepFooter";

import { AdvisorTutorialProps } from "../types";

const AdvisorTutorialSummaryOverview: React.FC<
  AdvisorTutorialProps
> = ({ onNext, onPrev }) => (
  <Box>
    <Box
      display="flex"
      flexDirection="column"
      gap="1.25rem"
      alignItems="center"
      sx={{
        mb: "1.25rem"
      }}
    >
      <ArticleIconOutlined />
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Client Summary Dashboard" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage defaultMessage='The "Summary" page allows you to see all your clients&apos; estate plans at a glance.' />
        <br /> <br />
        <FormattedMessage defaultMessage='Click the "+ Invite Client" button to invite a new client to connect their JIC Estates profile to yours.' />
        <br /> <br />
        <FormattedMessage defaultMessage="Your client will receive an email invite, complete their estate plan on their own time, and pay only after finishing their documents (3/5)." />
      </Typography>
    </Box>
    <ModalStepFooter
      prevButtonProps={{ type: "button", onClick: onPrev }}
      nextButtonProps={{
        type: "submit",
        label: "Continue",
        onClick: onNext
      }}
    />
  </Box>
);

export default AdvisorTutorialSummaryOverview;
