/* eslint-disable react/no-unescaped-entities */
import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/Healthcare/routes";
import { PlanOption } from "@api/__generated__/globalTypes";
import { useFormField } from "@components/FormFields/hooks";
import { BlogLinks } from "@setup/consts/articleLinks";

const LivingWillPreviewStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);
  const { value: planOption } =
    useFormField<PlanOption>("planOption");

  if (planOption === PlanOption.COUPLE) {
    return (
      <SectionsPreview
        {...{ sections }}
        stepLayout={{
          title: `Well done, ${firstName} and ${partnerFirstName}!`,
          isWide: true,
          subText: `We've noted your preferences for healthcare agents and other people authorized to access your health information. This next series of questions will cover any specific healthcare instructions that you have. We'll create what's called a "Living Will."`,
          moreInfoModalProps: {
            title: "What is a Living Will?",
            togglerLabel: "Learn more.",
            content: (
              <p>
                A living will is a legal document that allows you to
                state your healthcare preferences in advance.
                <br />
                <br />
                Documenting your preferences through a living will
                today prevents the burden of making these decisions
                from possibly falling on your loved ones or healthcare
                agent down the road.
                <br />
                <br />
                If you'd like to learn more about living wills, check
                out our blog article linked below.
              </p>
            ),
            actions: [
              {
                label: "Go to Article",
                href: BlogLinks.whatIsLivingWill
              }
            ],
            closeLabel: "Got it"
          }
        }}
      />
    );
  }

  return (
    <SectionsPreview
      {...{ sections }}
      stepLayout={{
        title: `Well done, ${firstName}!`,
        isWide: true,
        subText: `We've noted your preferences for healthcare agents and other people authorized to access your health information. This next series of questions will cover any specific healthcare instructions that you have. We'll create what's called a "Living Will."`,
        moreInfoModalProps: {
          title: "What is a Living Will?",
          togglerLabel: "Learn more.",
          content: (
            <p>
              A living will is a legal document that allows you to
              state your healthcare preferences in advance.
              <br />
              <br />
              Documenting your preferences through a living will today
              prevents the burden of making these decisions from
              possibly falling on your loved ones or healthcare agent
              down the road.
              <br />
              <br />
              If you'd like to learn more about living wills, check
              out our blog article linked below.
            </p>
          ),
          actions: [
            {
              label: "Go to Article",
              href: BlogLinks.whatIsLivingWill
            }
          ],
          closeLabel: "Got it"
        }
      }}
    />
  );
};

export default LivingWillPreviewStep;
