import PersonDetailsArchetype from "@components/archetypes/PersonDetails";

const DesignateAgentStep: React.FC = () => (
  <PersonDetailsArchetype
    name="users.0.poa.agents.0"
    personRole="Agent"
    stepLayout={{
      title:
        "Ok, great. What is the name and address of the person you want to be your agent with Power of Attorney?"
    }}
  />
);

export default DesignateAgentStep;
