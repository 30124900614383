import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const AssetValueStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `What's your best estimate of the value of the deceased person's assets?`,
      subText:
        "Select the approximate value of all assets, including any checking or savings accounts, cars, real estate, investment accounts, and other items."
    }}
    items={[
      { label: "I don't know", value: "n/a" },
      { label: "<$50,000", value: "<$50,000" },
      { label: "$50,001 - $100,000", value: "$50,001 - $100,000" },
      {
        label: "$100,001 - $250,000",
        value: "$100,001 - $250,000"
      },
      {
        label: "$250,001 - $1,000,000",
        value: "$250,001 - $1,000,000"
      },
      {
        label: "$1,000,001 - $5,000,000",
        value: "$1,000,001 - $5,000,000"
      },
      {
        label: "$5,000,001 - $10,000,000",
        value: "$5,000,001 - $10,000,000"
      },
      { label: ">$10,000,000", value: ">$10,000,000" }
    ]}
    name="estateData.assetValue"
  />
);

export default AssetValueStep;
