import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";
import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";
import { usePetBreedsCats } from "@hooks/usePetBreedsCats";
import { usePetBreedsDogs } from "@hooks/usePetBreedsDogs";
import { usePetBreedsFish } from "@hooks/usePetBreedsFish";
import { usePetBreedsHorse } from "@hooks/usePetBreedsHorse";
import { usePetBreedsReptile } from "@hooks/usePetBreedsReptile";
import { usePetBreedsBird } from "@hooks/usePetBreedsBird";
import { usePetBreedsOther } from "@hooks/usePetBreedsOther";

import {
  BreedTypes,
  PetPhysicalTraitsArchetypeValue,
  UsePetPhysicalTraitsArchetypeReturnTypes
} from "./types";

export const useBreedTypes = (breed: BreedTypes) => {
  const dog = usePetBreedsDogs();
  const cat = usePetBreedsCats();
  const fish = usePetBreedsFish();
  const horse = usePetBreedsHorse();
  const reptile = usePetBreedsReptile();
  const bird = usePetBreedsBird();
  const other = usePetBreedsOther();

  const breedTypeOptionsMap: Record<
    BreedTypes,
    AutocompleteBaseOption[]
  > = useMemo(
    () => ({
      dog,
      cat,
      fish,
      horse,
      reptile,
      bird,
      other
    }),
    [cat, dog, fish, horse, reptile, bird, other]
  );

  return breedTypeOptionsMap[breed];
};

const usePetPhysicalTraitsArchetype = (
  name: string,
  onFormSubmit?: (data: PetPhysicalTraitsArchetypeValue) => void
): UsePetPhysicalTraitsArchetypeReturnTypes => {
  const { goBack, saveAndContinue } = usePlanForm();

  const intl = useIntl();

  const schema: yup.SchemaOf<PetPhysicalTraitsArchetypeValue> =
    useMemo(
      () =>
        makeYupSchema(
          yup.object().shape({
            breed: yup
              .string()
              .required()
              .typeError(
                intl.formatMessage({
                  defaultMessage:
                    "Please select a breed from the dropdown menu, or type a different one"
                })
              ),
            weight: yup
              .number()
              .positive(
                "Please enter your pet's weight as a positive whole number (Examples: 1, 5, 100)"
              )
              .integer(
                "Please round your pet's weight to the nearest positive whole number (Examples: 1, 5, 100)"
              )
              .typeError(
                intl.formatMessage({
                  defaultMessage:
                    "Please enter your pet's weight as a positive whole number (Examples: 1, 5, 100)"
                })
              )
              .required(),
            characteristics: yup.string()
          }),
          name
        ),
      [name, intl]
    );

  const controls =
    useFormWithYup<PetPhysicalTraitsArchetypeValue>(schema);

  const onSubmit = useCallback(
    (data: PetPhysicalTraitsArchetypeValue) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { usePetPhysicalTraitsArchetype };
