import { useMemo } from "react";
import { useIntl } from "react-intl";

import { CompensationType } from "@hooks/useTrustEnums";
import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { numberWithCommas } from "@utils/misc";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ROUTES } from "@setup/consts/routes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import getTrustName from "@pages/EstatePlanView/Partials/hooks/getTrustName";

import { TRUST_MANAGEMENT_ROUTES } from "../../consts";

import { getCompensationInfo } from "./utils";

export const usePartnerManagementReviewTab = ({
  values
}: {
  values: LastWillPlanValues;
}): ReviewItem[] => {
  const { formatMessage } = useIntl();
  const intl = useIntl();
  const {
    users: [primaryDrafter, partner]
  } = values;

  const {
    isJointTrust,
    name: primaryTrustName,
    coTrustees: primaryCoTrustees = [],
    successorTrustees: primarySuccessorTrustees = [],
    trusteesCompensationType: primaryTrusteesCompensationType,
    trusteesCompensationDollar: primaryTrusteesCompensationDollar,
    trusteesCompensationPercent: primaryTrusteesCompensationPercent
  } = primaryDrafter?.trust || {};

  const {
    personalRepresentatives = [],
    backupPersonalRepresentatives = [],
    representativesCompensationType,
    representativesCompensationDollar,
    representativesCompensationPercent
  } = partner?.lastWill || {};

  const {
    coTrustees = [],
    successorTrustees = [],
    nominateTrusteesAsReps = false,
    trusteesCompensationType,
    trusteesCompensationDollar,
    trusteesCompensationPercent,
    name
  } = partner?.trust || {};

  const trustName = isJointTrust
    ? getTrustName(
        primaryTrustName,
        primaryDrafter,
        partner,
        false,
        isJointTrust
      )
    : partner.trust
    ? getTrustName(name, primaryDrafter, partner, true, isJointTrust)
    : "";

  // Trust Background Info
  const trustBackgroundInfo = useMemo<ReviewItem>(
    () => ({
      title: `Trust Overview:`,
      href: getLinkFullUrl([
        ROUTES.Trust,
        TRUST_SECTION_ROUTES.Management,
        TRUST_MANAGEMENT_ROUTES.PartnerTrusteeStart
      ]),
      items: [
        isJointTrust
          ? [
              `✅  We'll call your joint trust ${trustName}`,
              `✅  As the Trustmakers, only you and ${primaryDrafter.firstName} will have the power to change or revoke the trust, which will require your joint approval.`,
              `✅  You and ${primaryDrafter.firstName} will also be Trustees authorized to manage the trust's affairs.`
            ]
          : [
              `✅  We'll call your trust "${trustName}."`,
              `✅  As the Trustmaker, only you will have the power to change or revoke the trust.`,
              `✅  You'll also be a Trustee authorized to manage the trust's affairs.`
            ]
      ]
    }),
    [trustName, isJointTrust, primaryDrafter]
  );

  // Trustee Info starts here
  const coTrusteesInfo = useMemo<ReviewItem | undefined>(() => {
    if (isJointTrust) {
      return primaryCoTrustees.length > 0
        ? {
            title: `Co-Trustees:`,
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.DesignateCoTrustee
            ]),
            items: primaryCoTrustees.map((coTrustee) => [
              `${coTrustee.name}`,
              `Relationship: ${coTrustee.relationship ?? "Friend"}`
            ])
          }
        : {
            title: `Co-Trustees:`,
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.NominateCoTrustee
            ]),
            items: [
              [
                "👀 You are not nominating any Co-Trustees.",
                "As long as you have capacity, only you will have authority to act as Trustee."
              ]
            ]
          };
    }

    return coTrustees.length > 0
      ? {
          title: `Co-Trustees:`,
          href: getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Management,
            TRUST_MANAGEMENT_ROUTES.PartnerDesignateCoTrustee
          ]),
          items: coTrustees.map((coTrustee) => [
            `${coTrustee.name}`,
            `Relationship: ${coTrustee.relationship ?? "Friend"}`
          ])
        }
      : {
          title: `Co-Trustees:`,
          href: getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Management,
            TRUST_MANAGEMENT_ROUTES.PartnerNominateCoTrustee
          ]),
          items: [
            [
              "👀 You are not nominating any Co-Trustees.",
              "As long as you have capacity, only you will have authority to act as Trustee."
            ]
          ]
        };
  }, [isJointTrust, primaryCoTrustees, coTrustees]);

  const backupTrusteesInfo = useMemo<ReviewItem | undefined>(() => {
    if (isJointTrust && primarySuccessorTrustees.length > 0) {
      return {
        title: formatMessage({
          defaultMessage: "Successor Trustees:"
        }),
        href: getLinkFullUrl([
          ROUTES.Trust,
          TRUST_SECTION_ROUTES.Management,
          TRUST_MANAGEMENT_ROUTES.DesignateBackupTrustee
        ]),
        description:
          primarySuccessorTrustees.length > 1
            ? "If your Co-Trustees are unavailable, your Successor Trustees will serve in the order named below."
            : "Your Successor Trustees will serve in the order named below.",
        isFullWidth: true,
        items: primarySuccessorTrustees.map((backup) => [
          backup.name,
          `Relationship: ${backup.relationship ?? "Friend"}`
        ])
      };
    }

    return successorTrustees.length > 0
      ? {
          title: formatMessage({
            defaultMessage: "Successor Trustees:"
          }),
          href: getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Management,
            TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupTrustee
          ]),
          description:
            coTrustees.length > 1
              ? "If your Co-Trustees are unavailable, your Successor Trustees will serve in the order named below."
              : "Your Successor Trustees will serve in the order named below.",
          isFullWidth: true,
          items: successorTrustees.map((backup) => [
            backup.name,
            `Relationship: ${backup.relationship ?? "Friend"}`
          ])
        }
      : undefined;
  }, [
    isJointTrust,
    primarySuccessorTrustees,
    coTrustees,
    successorTrustees,
    formatMessage
  ]);

  const trusteeCompensationInfo = useMemo<ReviewItem | undefined>(
    () =>
      isJointTrust && primaryTrusteesCompensationType
        ? {
            title: formatMessage({
              defaultMessage: "Trustee Compensation:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.TrusteeCompensation
            ]),
            isFullWidth: true,
            items: [
              [
                primaryTrusteesCompensationType.toString() ===
                  CompensationType.Reasonable ||
                primaryTrusteesCompensationType.toString() ===
                  CompensationType.None
                  ? primaryTrusteesCompensationType.toString()
                  : "",
                primaryTrusteesCompensationDollar
                  ? `Compensation of $${numberWithCommas(
                      primaryTrusteesCompensationDollar.toString()
                    )}, plus reimbursement of reasonable expenses`
                  : "",
                primaryTrusteesCompensationPercent
                  ? `Compensation up to ${primaryTrusteesCompensationPercent.toString()}% of my estate, plus reimbursement of reasonable expenses.`
                  : ""
              ].filter((item) => Boolean(item))
            ]
          }
        : {
            title: formatMessage({
              defaultMessage: "Trustee Compensation:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensation
            ]),
            isFullWidth: true,
            items: [
              [
                trusteesCompensationType?.toString() ===
                  CompensationType.Reasonable ||
                trusteesCompensationType?.toString() ===
                  CompensationType.None
                  ? trusteesCompensationType.toString()
                  : "",
                trusteesCompensationDollar
                  ? `Compensation of $${numberWithCommas(
                      trusteesCompensationDollar.toString()
                    )}, plus reimbursement of reasonable expenses`
                  : "",
                trusteesCompensationPercent
                  ? `Compensation up to ${trusteesCompensationPercent.toString()}% of my estate, plus reimbursement of reasonable expenses.`
                  : ""
              ].filter((item) => Boolean(item))
            ]
          },
    [
      isJointTrust,
      primaryTrusteesCompensationType,
      primaryTrusteesCompensationDollar,
      primaryTrusteesCompensationPercent,
      trusteesCompensationType,
      trusteesCompensationDollar,
      trusteesCompensationPercent,
      formatMessage
    ]
  );

  const trusteesNestedInfo = useMemo<ReviewItem | undefined>(
    () => ({
      title: intl.formatMessage({
        defaultMessage: "Trustee Information"
      }),
      href: isJointTrust
        ? getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Management,
            TRUST_MANAGEMENT_ROUTES.NominateCoTrustee
          ])
        : getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Management,
            TRUST_MANAGEMENT_ROUTES.PartnerNominateCoTrustee
          ]),
      items: [
        coTrusteesInfo,
        backupTrusteesInfo,
        trusteeCompensationInfo
      ].filter((item): item is ReviewItem => Boolean(item))
    }),
    [
      intl,
      isJointTrust,
      coTrusteesInfo,
      backupTrusteesInfo,
      trusteeCompensationInfo
    ]
  );

  // Representatives Info starts here
  const representativesInfo = useMemo<ReviewItem | undefined>(
    () =>
      personalRepresentatives.length > 0
        ? {
            title: `Primary Representative:`,
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerDesignateRepresentative
            ]),
            items: [
              [
                `${personalRepresentatives[0].name}`,
                `Relationship: ${
                  personalRepresentatives[0].relationship ?? "Friend"
                }`
              ]
            ]
          }
        : undefined,
    [personalRepresentatives]
  );

  const backupRepresentativesInfo = useMemo<ReviewItem | undefined>(
    () =>
      backupPersonalRepresentatives.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Backup Representative:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupRepresentative
            ]),
            description:
              "(Backup Representatives will serve in the order named)",
            isFullWidth: true,
            items: backupPersonalRepresentatives.map((backupRep) => [
              backupRep.name,
              `Relationship: ${backupRep.relationship ?? "Friend"}`
            ])
          }
        : undefined,
    [backupPersonalRepresentatives, formatMessage]
  );

  const representativesCompensationInfo = useMemo<
    ReviewItem | undefined
  >(
    () =>
      personalRepresentatives?.length
        ? {
            title: formatMessage({
              defaultMessage: "Representative Compensation:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation
            ]),
            isFullWidth: true,
            items: [
              getCompensationInfo(
                representativesCompensationType,
                representativesCompensationDollar,
                representativesCompensationPercent
              )
            ]
          }
        : undefined,
    [
      personalRepresentatives.length,
      representativesCompensationType,
      representativesCompensationDollar,
      representativesCompensationPercent,
      formatMessage
    ]
  );

  const representativesNestedInfo = useMemo<ReviewItem | undefined>(
    () =>
      nominateTrusteesAsReps
        ? {
            title: intl.formatMessage({
              defaultMessage: "Personal Representatives Information"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerDesignateTrusteeSameReps
            ]),
            items: [
              [
                "✅ Your Trustees will also serve as your Personal Representatives for your Pour Over Will."
              ],
              [
                [
                  "They'll receive the following compensation for serving as Personal Representatives:",
                  getCompensationInfo(
                    representativesCompensationType,
                    representativesCompensationDollar,
                    representativesCompensationPercent
                  )
                ].join(" ")
              ]
            ]
          }
        : {
            title: intl.formatMessage({
              defaultMessage: "Personal Representatives Information:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerDesignateRepresentative
            ]),
            items: [
              representativesInfo,
              backupRepresentativesInfo,
              representativesCompensationInfo
            ].filter((item): item is ReviewItem => Boolean(item))
          },
    [
      intl,
      nominateTrusteesAsReps,
      representativesInfo,
      backupRepresentativesInfo,
      representativesCompensationInfo,
      representativesCompensationType,
      representativesCompensationDollar,
      representativesCompensationPercent
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        trustBackgroundInfo,
        trusteesNestedInfo,
        representativesNestedInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      trustBackgroundInfo,
      trusteesNestedInfo,
      representativesNestedInfo
    ]
  );

  return items;
};
