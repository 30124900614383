import {
  Link,
  Box,
  Typography,
  useTheme,
  Button
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { RESOURCE_PAGE_BASE_URL } from "@setup/consts/misc";

import { SectionQuickLinks } from "../../components/SectionQuickLinks";
import ResourceVideoListings from "../../components/ResourceListing/ResourceVIdeosListing";
import ResourceListing from "../../components/ResourceListing";
import BookAMeetingMessage from "../../components/BookAMeetingMessage";

import {
  advisorResourceItems,
  sampleClientVideos,
  advisorVideos
} from "./consts";

const AdvisorResources = () => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h1"
        textAlign="center"
        margin={theme.spacing(2)}
      >
        <FormattedMessage defaultMessage="Advisor Resources" />
      </Typography>
      <SectionQuickLinks items={advisorResourceItems} />
      <Typography
        id="advisor-tutorials"
        variant="h3"
        sx={{ mt: "2.5rem", mb: "1.25rem" }}
      >
        <FormattedMessage defaultMessage="Advisor Tutorials" />
      </Typography>
      <Box
        mb="1.25rem"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.625rem"
        }}
      >
        <InfoOutlinedIcon />
        <Typography>
          <FormattedMessage defaultMessage="See everything you can do with JIC Estates Advisors" />
        </Typography>
      </Box>
      <ResourceVideoListings videos={advisorVideos} />
      <Box mt="2.5rem">
        <BookAMeetingMessage
          message="Want Some 1:1 Time?"
          message2="Book a Meeting with Our Co-Founder"
        />
      </Box>
      <Typography
        id="tutorials"
        variant="h3"
        sx={{ mt: "2.5rem", mb: "1.25rem" }}
      >
        <FormattedMessage defaultMessage="Sample Client Tutorials" />
      </Typography>
      <Box
        mb="2.5rem"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.625rem"
        }}
      >
        <InfoOutlinedIcon />
        <Typography>
          <FormattedMessage defaultMessage="Take a peek at some of the client-side tutorials that will guide your clients through the estate planning process" />
        </Typography>
      </Box>
      <ResourceVideoListings videos={sampleClientVideos} />
      <Typography
        id="client-resources"
        variant="h3"
        sx={{ mt: "2.5rem", mb: "1.25rem" }}
      >
        <FormattedMessage defaultMessage="Sample Client Resources" />
      </Typography>
      <Box
        mb="2.5rem"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.625rem"
        }}
      >
        <InfoOutlinedIcon />
        <Typography>
          <FormattedMessage defaultMessage="Illustrative articles we'll recommend when your client completes their revocable living trust. Browse hundreds more estate planning articles in the JIC Estates resource library" />
        </Typography>
      </Box>
      <ResourceListing showSample />
      <Link
        sx={{
          textDecoration: "none",
          color: "inherit"
        }}
        href={RESOURCE_PAGE_BASE_URL}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            mt: "2.5rem"
          }}
        >
          <Typography
            color={theme.palette.grey["500"]}
            sx={{
              fontSize: "0.875rem",
              fontWeight: "500"
            }}
          >
            Want even more estate planning tips?
          </Typography>
          <Typography
            color={theme.palette.grey["500"]}
            sx={{
              fontSize: "0.875rem",
              fontWeight: "500"
            }}
          >
            Hundreds more estate planning resources available in our
            JIC Estates resource library.
          </Typography>
          <Button variant="contained" color="primary">
            <FormattedMessage defaultMessage="GO TO RESOURCES" />
          </Button>
        </Box>
      </Link>
    </Box>
  );
};

export default AdvisorResources;
