import { useIntl } from "react-intl";

import CharityArchetype from "@components/archetypes/Charity";
import { useFormField } from "@components/FormFields/hooks";
import { Beneficiary } from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";

const CharityBackup: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: beneficiary } = useFormField<Beneficiary>(
    `users.0.assets.estateBalance.0.beneficiaries.${iteration}`
  );

  return (
    <CharityArchetype
      name={`users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`}
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage: `Ok. Which charity should be the backup for {name}’s {percentageValue}% share?`
          },
          {
            name: beneficiary.name,
            percentageValue: beneficiary.percentageValue
          }
        )
      }}
    />
  );
};

export default CharityBackup;
