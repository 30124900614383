import { useIntl } from "react-intl";
import { get } from "lodash";
import { useMemo } from "react";

import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import { Pet } from "@pages/PetPlan/types";
import PetPhysicalTraitsArchetype from "@components/archetypes/PetPhysicalTraits";
import { BreedTypes } from "@components/archetypes/PetPhysicalTraits/types";

const PhysicalTraitsStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: pet } = useFormField<Pet>(`pets.${iteration}`);

  const breed = useMemo(() => {
    const breedType = get(pet, "type", "");

    if (!breedType) {
      return BreedTypes.OTHER;
    }

    return breedType.toLowerCase() as BreedTypes;
  }, [pet]);

  return (
    <PetPhysicalTraitsArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "Please note any identifying physical characteristics for {name}."
          },
          {
            name: pet.name
          }
        )
      }}
      {...{ breed }}
      name={`pets.${iteration}`}
    />
  );
};

export default PhysicalTraitsStep;
