import get from "lodash/get";
import { useCallback } from "react";
import { format, isValid } from "date-fns";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";

import { DEFAULT_DATE_FORMAT } from "@setup/consts/misc";
import formatTimezone from "@utils/formatTimeZone";

import { useFormField } from "../hooks";

import { FormMobileDatePickerProps } from "./types";

const FormMobileDatePicker: React.FC<FormMobileDatePickerProps> = (
  props
) => {
  const theme = useTheme();
  const { name, type } = props;
  const { value = null, setValue } = useFormField<Date | string>(
    name
  );

  const {
    control,
    formState: { errors },
    setValue: setRHFValue
  } = useFormContext();

  const onChange = useCallback(
    (date: Date | null) => {
      if (!date) return;
      if (type === "dateOnly" && date && isValid(date)) {
        const stringDate = formatTimezone(
          new Date(format(date, "yyyy-MM-dd"))
        );

        setValue(stringDate);
        setRHFValue(name, stringDate, { shouldValidate: true });

        return;
      }

      setValue(formatTimezone(date));
      setRHFValue(name, formatTimezone(date), {
        shouldValidate: true
      });
    },
    [type, setValue, setRHFValue, name]
  );

  const fieldError = get(errors, `${name}.message`);

  return (
    <Controller
      defaultValue={value}
      {...{ control, name }}
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      render={({ field }) => (
        <DatePicker
          inputFormat={DEFAULT_DATE_FORMAT}
          {...props}
          {...field}
          {...{ value, onChange }}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              {...(fieldError
                ? { error: true, helperText: fieldError }
                : {})}
              variant="standard"
            />
          )}
          DialogProps={{
            sx: {
              "& .MuiButton-root": {
                minWidth: "auto",
                [theme.breakpoints.up("md")]: {
                  minWidth: "13.438rem"
                }
              }
            }
          }}
        />
      )}
    />
  );
};

export default FormMobileDatePicker;
