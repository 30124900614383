import { useMemo } from "react";
import * as yup from "yup";

export const useInviteMemberSchema = () =>
  useMemo(
    () =>
      yup.object().shape({
        planId: yup.number(),
        userId: yup.number(),
        fullName: yup.string().required(),
        email: yup.string().required(),
        role: yup.string().required(),
        permissionLevel: yup.string().required(),
        message: yup.string()
      }),
    []
  );
