import { OrganDonationPurpose } from "@api/__generated__/globalTypes";
import MultiSelectArchetype from "@components/archetypes/MultiSelect";

const OrganDonationPurposeStep: React.FC = () => (
  <MultiSelectArchetype
    name="users.0.healthcare.preferences.organDonationPurposes"
    stepLayout={{
      title: `For which of the following purposes are you willing to donate?`,
      subText: `Choose all purposes that you wish to allow.`,
      moreInfoModalProps: {
        title: "Understanding Organ Donation Purposes",
        togglerLabel: "Learn more.",
        content: (
          <p>
            <strong>Transplant.</strong> Donating for transplant means
            that your organs, tissues, or parts might be used to
            replace damaged or non-functioning ones in another
            person&apos;s body. For example, if you choose to donate
            your heart for transplant, it could potentially be used to
            save the life of someone with severe heart disease.
            <br />
            <br />
            <strong>Therapy.</strong> Donating for therapy involves
            using your organs, tissues, or parts to develop new
            medical treatments or therapies. For example, your donated
            tissue might be used to develop treatments for cancers or
            genetic disorders.
            <br />
            <br />
            <strong>Research.</strong> Donating for research means
            contributing your ogans, tissues, or parts to scientific
            studies aimed at advancing medical knowledge or improving
            healthcare. For example, researchers might use your
            donated samples to investigate diseases, develop new
            diagnostic tools, or potential treatments.
            <br />
            <br />
            <strong>Education.</strong> Donating for education means
            contributing your samples for education and training
            purposes. For example, your donated body may be used in
            medical schools for educational purposes, helping to
            further educate healthcare professionals.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    valueAsKey
    options={[
      {
        label: "Transplant",
        value: OrganDonationPurpose.TRANSPLANT
      },
      {
        label: "Therapy",
        value: OrganDonationPurpose.THERAPY
      },
      {
        label: "Research",
        value: OrganDonationPurpose.RESEARCH
      },
      {
        label: "Education",
        value: OrganDonationPurpose.EDUCATION
      }
    ]}
  />
);

export default OrganDonationPurposeStep;
