import React from "react";
import noop from "lodash/noop";

import { ProgressBarContextValue } from "./types";

export const ProgressBarContext =
  React.createContext<ProgressBarContextValue>({
    progress: undefined,
    setProgress: noop,
    title: undefined,
    setTitle: noop
  });
