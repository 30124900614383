import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const DesignateBackupAgentStep: React.FC = () => (
  <PersonDetailsArrayArchetype
    name="users.0.poa.successorAgents"
    stepLayout={{
      title: "Who should be the successor agent?"
    }}
    personRole="Successor Agent"
    addBtnLabel="Add next successor agent"
    removeBtnLabel="Remove successor agent"
  />
);

export default DesignateBackupAgentStep;
