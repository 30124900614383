import { Container } from "@mui/material";

import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import {
  EstateData,
  HouseholdData
} from "@pages/EstateAdminQuiz/types";
import FormStepFooter from "@components/FormStepFooter";

import useFinancialResults from "./FinancialResults/useFinancialResults";
import useLegalResults from "./LegalResults/useLegalResults";
import ErrorStep from "./Error";

const ResultsStep: React.FC = () => {
  const { goBack } = usePlanForm();
  const { value: householdData } =
    useFormField<HouseholdData>("householdData");
  const { value: estateData } =
    useFormField<EstateData>("estateData");
  const { value: expertType } = useFormField("expertType");

  const legalResultItems = useLegalResults(estateData);
  const financeResultItems = useFinancialResults(householdData);

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: { xs: 2.5, md: 6.25 }
      }}
    >
      {expertType === "LEGAL" && legalResultItems}
      {expertType === "FINANCIAL" && financeResultItems}
      {expertType !== "LEGAL" && expertType !== "FINANCIAL" && (
        <ErrorStep />
      )}
      <FormStepFooter
        prevButtonProps={{
          label: "<< Back to Quiz",
          type: "button",
          onClick: goBack
        }}
      />
    </Container>
  );
};

export default ResultsStep;
