import { formatPhoneNumberIntl } from "react-phone-number-input";

import { Pet } from "@pages/PetPlan/types";

const getVetInfo = (
  pets: Pet[],
  sameVeterinarian: boolean | undefined
) => {
  if (pets.length === 1) {
    return [
      [
        `${pets[0].veterinarian?.name ?? ""}`,
        formatPhoneNumberIntl(pets[0].veterinarian?.phone ?? ""),
        `${pets[0].veterinarian?.address?.address1 ?? ""}`,
        `${pets[0].veterinarian?.address?.address2 ?? ""}`,
        pets[0].veterinarian?.address
          ? `${pets[0].veterinarian?.address?.city}, ${pets[0].veterinarian?.address?.state} ${pets[0].veterinarian?.address?.zip}`
          : ""
      ]
    ].filter(Boolean);
  }

  if (sameVeterinarian) {
    return [
      [
        `${pets[0].veterinarian?.name ?? ""}`,
        `${pets[0].veterinarian?.phone ?? ""}`,
        `${pets[0].veterinarian?.address?.address1 ?? ""}`,
        `${pets[0].veterinarian?.address?.address2 ?? ""}`,
        pets[0].veterinarian?.address
          ? `${pets[0].veterinarian?.address?.city}, ${pets[0].veterinarian?.address?.state} ${pets[0].veterinarian?.address?.zip}`
          : ""
      ],
      ["✅ Same Vet for all my pets"]
    ].filter(Boolean);
  }

  return pets
    .map((pet) => [
      `${pet.name}:`,
      `${pet.veterinarian?.name ?? ""}`,
      `${pet.veterinarian?.phone ?? ""}`,
      `${pet.veterinarian?.address?.address1 ?? ""}`,
      `${pet.veterinarian?.address?.address2 ?? ""}`,
      pet.veterinarian?.address
        ? `${pet.veterinarian?.address?.city}, ${pet.veterinarian?.address?.state} ${pet.veterinarian?.address?.zip}`
        : ""
    ])
    .filter(Boolean);
};

export default getVetInfo;
