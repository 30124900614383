import { FormattedMessage, useIntl } from "react-intl";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";

import image from "@assets/images/image-ourTeam.png";
import FormStepFooter from "@components/FormStepFooter";
import Image from "@components/Image";
import { TUTORIAL_ROUTES } from "@pages/Tutorial/consts";
import { enumValueToString } from "@utils/misc";
import usePlanTypeContext from "@providers/planType/hooks";

const HomePage: React.FC = () => {
  const intl = useIntl();
  const { planTypeEnum } = usePlanTypeContext();
  const planType = enumValueToString(planTypeEnum);

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: { xs: 2.5, md: 6.25 }
      }}
    >
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        <FormattedMessage
          defaultMessage="Welcome to the family"
          // TODO PHASE 3: Can we pull in name from API if they are doing social sign-on?
          // values={{ name: "Luke" }}
        />
      </Typography>
      <Box
        sx={{
          maxWidth: "29.375rem",
          display: "flex",
          width: "100%",
          mx: "auto",
          mb: 2.5
        }}
      >
        <Image
          src={image}
          alt="Headshots of 3 JIC Estates team members"
          objectFit="cover"
        />
      </Box>
      <Box maxWidth="29.375rem" width="100%" m="0 auto">
        <Typography variant="body2" textAlign="left">
          <FormattedMessage defaultMessage="From all of us here at JIC Estates, congratulations on taking this important step creating an estate plan benefitting you and your loved ones." />
        </Typography>
        <Typography variant="body2" textAlign="left" mt="1.5rem">
          <FormattedMessage
            defaultMessage="We’ll be with you every step of the way as we move one question at a time crafting your perfect {planType} plan."
            values={{ planType }}
          />
        </Typography>
        <FormStepFooter
          nextButtonProps={{
            href: TUTORIAL_ROUTES.Expectations,
            label: intl.formatMessage({ defaultMessage: "Continue" })
          }}
        />
      </Box>
    </Container>
  );
};

export default HomePage;
