import React from "react";
import {
  AccountCircleOutlined,
  EscalatorWarning,
  EmojiPeople,
  HealthAndSafety,
  VolunteerActivism,
  Gavel,
  Pets,
  Map,
  Work,
  Key
} from "@mui/icons-material";

import { PermissionLevel } from "@api/__generated__/globalTypes";
import { MembershipRole } from "@setup/enums";

import { humanizeMembershipRoles } from "./utils";

export const roleLabel: Record<PermissionLevel, string> = {
  [PermissionLevel.VIEW_ROLE]: "Can view role only",
  [PermissionLevel.VIEW]: "Can view all docs and plan data",
  [PermissionLevel.EDIT]: "Can edit",
  [PermissionLevel.OWNER]: "Owner"
};

export const roleDescription: Record<
  MembershipRole,
  {
    icon: React.ReactNode;
    label: string;
    description: string;
  }
> = {
  [MembershipRole.EXECUTOR]: {
    icon: <Map />,
    label: humanizeMembershipRoles(MembershipRole.EXECUTOR),
    description: `The Executor is the person entrusted with coordinating the Willmaker’s estate administration process and ensuring the Willmaker’s wishes are respected.`
  },
  [MembershipRole.TRUSTEE]: {
    icon: <Map />,
    label: humanizeMembershipRoles(MembershipRole.TRUSTEE),
    description: `The Trustee is the person(s) responsible for managing the trust's affairs. After the Trustmaker passes, acting trustees are responsible for carrying out the trust's administration provisions and ensuring the Trustmaker's wishes are respected.`
  },
  [MembershipRole.ATTORNEY]: {
    icon: <Gavel />,
    label: humanizeMembershipRoles(MembershipRole.ATTORNEY),
    description: `As an Attorney, you are engaged or may be engaged to represent the Principal or the Principal's estate in legal matters.`
  },
  [MembershipRole.FINANCIAL_ADVISOR]: {
    icon: <Work />,
    label: humanizeMembershipRoles(MembershipRole.FINANCIAL_ADVISOR),
    description: `Financial advisors may work on behalf of the Princiapl or the Principal's estate.`
  },
  [MembershipRole.AUTHORIZED_PERSON]: {
    icon: <EmojiPeople />,
    label: humanizeMembershipRoles(MembershipRole.AUTHORIZED_PERSON),
    description: `An Authorized Person may receive certain access to the Principal's health information and outcomes.`
  },
  [MembershipRole.BENEFICIARY]: {
    icon: <VolunteerActivism />,
    label: humanizeMembershipRoles(MembershipRole.BENEFICIARY),
    description: `A Beneficiary is a person who receives a gift under an estate plan.`
  },
  [MembershipRole.GUARDIAN]: {
    icon: <EscalatorWarning />,
    label: humanizeMembershipRoles(MembershipRole.GUARDIAN),
    description: `The Guardian is the person who will be responsible for the wellbeing of the Willmaker's minor children should the Willmaker pass away.`
  },
  [MembershipRole.HEALTHCARE_AGENT]: {
    icon: <HealthAndSafety />,
    label: humanizeMembershipRoles(MembershipRole.HEALTHCARE_AGENT),
    description: `The Healthcare Agent is the person authorized to make healthcare decisions for the Principal should the Principal be unable to make or communicate those decisions directly.`
  },
  [MembershipRole.AGENT]: {
    icon: <Gavel />,
    label: humanizeMembershipRoles(MembershipRole.AGENT),
    description: `The Agent in a Power of Attorney may represent and deal for the Principal in certain financial and other transactions.`
  },
  [MembershipRole.OWNER]: {
    icon: <AccountCircleOutlined />,
    label: humanizeMembershipRoles(MembershipRole.OWNER),
    description: `As Owner of this estate plan, you have complete ability to modify, delete, and share the plan details.`
  },
  [MembershipRole.VETERINARIAN]: {
    icon: <Pets />,
    label: humanizeMembershipRoles(MembershipRole.VETERINARIAN),
    description: `You have been designated as the pet parent's veterinarian for at least one of their pets.`
  },
  [MembershipRole.PETGUARDIAN]: {
    icon: <Pets />,
    label: "Pet Guardian",
    description: `You have been designated as the Pet Guardian for at least one of the Pet Parent's pets.`
  },
  [MembershipRole.OTHER_KEY_PERSON]: {
    icon: <Key />,
    label: humanizeMembershipRoles(MembershipRole.OTHER_KEY_PERSON),
    description: `You've been designated as a key person who should be in the know on this estate plan.`
  }
};

export const DASHBOARD_SECTION_ROUTES = {
  DocsAndData: "docs-and-data",
  Assets: "assets",
  Visual: "visualize",
  Share: "share",
  SharedWithMe: "shared-with-me",
  SharedWithMeSummary: "shared-with-me-summary",
  Resources: "resources"
};
