import { PaletteOptions } from "@mui/material";

const palette: PaletteOptions = {
  primary: {
    main: "#3695DD",
    dark: "#25679A"
  },
  secondary: {
    main: "#F6BD22"
  },
  text: {
    primary: "#0E0E10",
    secondary: "#2C165A",
    disabled: "#868B8E"
  },
  error: {
    light: "#F51720",
    main: "#B00120"
  },
  success: {
    main: "#0DC0B4"
  },
  background: {
    default: "#ffffff"
  },
  divider: "#0E0E10",
  grey: {
    "400": "#8D8C86",
    "500": "#575757",
    "700": "#757575"
  }
};

export default palette;
