import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";

const LivingWillOverviewStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Did you know?`,
      isWide: true,
      muiIconHeader: <InfoOutlinedIcon />,
      subText: `The instructions in your Living Will are only referenced if you are unable to speak for yourself. If you are capable of speaking for yourself, you'll retain complete control over your healthcare decisions, including changing any elections that you make here. If you do not wish to provide instructions, you can skip this part or any associated questions, and your healthcare agent will decide for you should the situation arise.`
    }}
  />
);

export default LivingWillOverviewStep;
