import { useMemo } from "react";
import { useIntl } from "react-intl";
import { capitalize } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { DEFAULT_DATE_FORMAT } from "@setup/consts/misc";
import formatDate from "@utils/formatDate";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanType } from "@api/__generated__/globalTypes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_ALL_ABOUT_YOU_ROUTES } from "@pages/Trust/sections/AllAboutYou/consts";
import getParentNames from "@utils/getParentNames";

import { LAST_WILL_ALL_ABOUT_YOU_ROUTES } from "../../consts";

export const usePrimaryDrafterAllAboutYouReviewTab = ({
  values,
  partnerType,
  type
}: {
  values: LastWillPlanValues;
  partnerType: string;
  type: PlanType;
}) => {
  const { formatMessage } = useIntl();

  const {
    users: [primaryDrafter, partner],
    children
  } = values;

  const capitalPartnerType = capitalize(partnerType);

  const personalInfo = useMemo<ReviewItem | undefined>(
    () =>
      primaryDrafter
        ? {
            title: formatMessage({ defaultMessage: "Personal Info" }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.AllAboutYou,
                    TRUST_ALL_ABOUT_YOU_ROUTES.Basics
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.AllAboutYou,
                    LAST_WILL_ALL_ABOUT_YOU_ROUTES.Basics
                  ]
            ),
            items: [
              [
                `${primaryDrafter?.firstName} ${primaryDrafter?.lastName}`,
                `Born ${formatDate(
                  primaryDrafter?.birthdate,
                  DEFAULT_DATE_FORMAT
                )}`,
                `${primaryDrafter?.address?.county}, ${primaryDrafter?.address?.state}`
              ]
            ]
          }
        : undefined,
    [formatMessage, primaryDrafter, type]
  );

  const partnerInfo = useMemo<ReviewItem | undefined>(
    () =>
      // Using firstName vs. partner here b/c partner may be reset to empty object
      partner.firstName
        ? {
            title: `Your ${capitalPartnerType}'s Info`,
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.AllAboutYou,
                    TRUST_ALL_ABOUT_YOU_ROUTES.PartnerDetails
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.AllAboutYou,
                    LAST_WILL_ALL_ABOUT_YOU_ROUTES.PartnerDetails
                  ]
            ),
            items: [
              [
                `${partner?.firstName} ${partner?.lastName}`,
                `Born ${formatDate(
                  partner?.birthdate,
                  DEFAULT_DATE_FORMAT
                )}`,
                `${partner?.address?.county}, ${partner?.address?.state}`
              ]
            ]
          }
        : undefined,
    [capitalPartnerType, partner, type]
  );

  /*  TODO NOW: 
  Reminder that prior to doc gen, I'll need to translate these parentsNames into a standardized field 
  so that I know whose child is whose when making the wills
  */

  const childrenInfo = useMemo<ReviewItem | undefined>(
    () =>
      children?.length
        ? {
            title: formatMessage({
              defaultMessage: "Your Children's Info"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.AllAboutYou,
                    TRUST_ALL_ABOUT_YOU_ROUTES.ChildrenDetails
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.AllAboutYou,
                    LAST_WILL_ALL_ABOUT_YOU_ROUTES.ChildrenDetails
                  ]
            ),
            isFullWidth: true,
            items: children.map((child) => [
              child.name,
              `Born ${formatDate(child.birthdate)}`,
              getParentNames(
                child.parentNames,
                primaryDrafter,
                partner
              )
            ])
          }
        : undefined,
    [children, formatMessage, type, primaryDrafter, partner]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [personalInfo, partnerInfo, childrenInfo].filter(
        (item): item is ReviewItem => Boolean(item)
      ),
    [childrenInfo, partnerInfo, personalInfo]
  );

  return items;
};
