import React from "react";
import { Container, Typography, Box } from "@mui/material";
import TextTruncate from "react-text-truncate";

import { VideoProps } from "./types";

const Video: React.FC<VideoProps> = ({
  src,
  title,
  caption,
  showCaption
}) => (
  <Container>
    <div
      style={{
        position: "relative",
        paddingBottom: "56.25%",
        height: 0
      }}
    >
      <iframe
        src={src}
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        title={title}
      />
    </div>
    {title && caption && showCaption && (
      <Box my="1.25rem">
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">
          <TextTruncate
            element="span"
            line={3}
            text={caption}
            truncateText=" [...]"
          />
        </Typography>
      </Box>
    )}
  </Container>
);

export default Video;
