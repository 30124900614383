import { CompensationType } from "@hooks/useTrustEnums";
import { numberWithCommas } from "@utils/misc";

export const getCompensationInfo = (
  compensationType: string | undefined,
  compensationDollar: number | undefined,
  compensationPercent: number | undefined
) => {
  const filteredItems = [
    compensationType?.toString() === CompensationType.Reasonable ||
    compensationType?.toString() === CompensationType.None
      ? compensationType.toString()
      : "",
    compensationDollar
      ? `Compensation of $${numberWithCommas(
          compensationDollar.toString()
        )}, plus reimbursement of reasonable expenses`
      : "",
    compensationPercent
      ? `Compensation up to ${compensationPercent.toString()}% of my estate, plus reimbursement of reasonable expenses.`
      : ""
  ].filter(Boolean);

  return filteredItems;
};
