import { Beneficiary } from "@pages/LastWill/types";
import { AssetType } from "@api/__generated__/globalTypes";

import {
  AssetFormValues,
  AssetWithoutBackupBeneficiaries
} from "../../AssetForm/types";

const mapAssetToFormValues = (
  asset: AssetWithoutBackupBeneficiaries
): AssetFormValues => ({
  id: asset.id || undefined,
  name: asset.name || undefined,
  type: asset.type as AssetType,
  subtype: asset.subType || undefined,
  grossValue: asset.grossValue ? `${asset.grossValue}` : undefined,
  institution: asset.institution || undefined,
  isGift: asset.isGift || undefined,
  isFunded: asset.isFunded || undefined,
  liabilityValue: asset.liabilityValue || undefined,
  address: asset.address ? asset.address : undefined,
  beneficiaries: (asset.beneficiaries || [])
    .filter((beneficiary: Beneficiary): beneficiary is Beneficiary =>
      Boolean(beneficiary)
    )
    .map((beneficiary: Beneficiary) => ({
      name: beneficiary.name || undefined,
      backupDistribution: beneficiary.backupDistribution || undefined,
      percentageValue: beneficiary.percentageValue || undefined,
      dollarValue: beneficiary.dollarValue || undefined,
      charity: beneficiary.charity?.name
        ? {
            name: beneficiary.charity.name || "",
            ein: beneficiary.charity.ein || undefined,
            // TODO: May need to fix this...
            fullAddress: beneficiary.charity.address
              ? beneficiary.charity.address
              : undefined,
            address: beneficiary.charity.address
          }
        : undefined
    }))
});

export default mapAssetToFormValues;
