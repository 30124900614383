import { Box, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormattedMessage } from "react-intl";

import { AssetType } from "@api/__generated__/globalTypes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import ImportAssetsModal from "@pages/EstatePlanView/Partials/components/ImportAssetsModal";

import AddAssetsModal from "../../../components/AddAssetModal";
import { useAssetReviewItems } from "../utils/useAssetReviewItems";
import { useAssets } from "../utils/useAssets";
import { AssetViewProps } from "../types";

import AssetsPartial from "./AssetsPartial";

const LastWillAssetView: React.FC<AssetViewProps> = ({
  lastWill: userLastWill,
  assets = []
}) => {
  const { assetsOutsideLastWill, assetsThroughLastWill, canEdit } =
    useAssets({
      assets,
      excludeAssets: [
        AssetType.CASH,
        AssetType.PERSONAL_PROPERTY_BALANCE,
        AssetType.ESTATE_BALANCE
      ]
    });

  const theme = useTheme();

  const { lastWill } = useMemberPlansState();

  const throughLastWillReviewItems = useAssetReviewItems(
    assetsThroughLastWill
  );
  const outsideLastWillReviewItems = useAssetReviewItems(
    assetsOutsideLastWill
  );

  return (
    <>
      {throughLastWillReviewItems.length === 0 &&
        outsideLastWillReviewItems.length === 0 &&
        lastWill?.isOwner && (
          <>
            <Typography variant="h3" textAlign="center">
              <InfoOutlinedIcon />
            </Typography>
            <Typography variant="body1" textAlign="center">
              <FormattedMessage defaultMessage="You haven't added any assets to your plan yet. Add an asset by clicking the 'Add Asset' button below." />
            </Typography>
          </>
        )}
      {throughLastWillReviewItems.length === 0 &&
        outsideLastWillReviewItems.length === 0 &&
        !lastWill?.isOwner && (
          <>
            <Typography variant="h3" textAlign="center">
              <InfoOutlinedIcon />
            </Typography>
            <Typography variant="body1" textAlign="center">
              <FormattedMessage defaultMessage="No asset information added to this plan yet." />
            </Typography>
          </>
        )}
      {throughLastWillReviewItems.length > 0 && (
        <AssetsPartial
          canEdit={canEdit}
          reviewItems={throughLastWillReviewItems}
          title={
            <FormattedMessage defaultMessage="Assets Passing Through Your Will" />
          }
          description={
            <FormattedMessage
              defaultMessage="These are assets without their own beneficiary designations
        that transfer through the probate process."
            />
          }
        />
      )}
      {outsideLastWillReviewItems.length > 0 && (
        <AssetsPartial
          canEdit={canEdit}
          reviewItems={outsideLastWillReviewItems}
          title={
            <FormattedMessage defaultMessage="Assets Passing Outside of Your Will" />
          }
          description={
            <FormattedMessage defaultMessage="These non-probate assets have their own beneficiary designations, which you can review and update with your bank or other account provider" />
          }
        />
      )}
      {userLastWill && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <AddAssetsModal lastWill={userLastWill} showIsFunded />
          <ImportAssetsModal lastWill={userLastWill} showIsFunded />
        </Box>
      )}
    </>
  );
};

export default LastWillAssetView;
