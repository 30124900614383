import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";
import { useFormField } from "@components/FormFields/hooks";

const PartnerDesignateMedicalAccessStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <PersonDetailsArrayArchetype
      name="users.1.healthcare.medicalRecordAccess.people"
      stepLayout={{
        title: `Who would ${partnerFirstName} like to have access to ${partnerFirstName}'s medical records?`
      }}
      personRole="Authorized Person"
    />
  );
};

export default PartnerDesignateMedicalAccessStep;
