import { PlanType } from "@api/__generated__/globalTypes";

export const FINANCIAL_ADVISOR_LOCAL_STORAGE_KEY =
  "jic_financial_advisor_token";
export const RETURN_URL_LOCAL_STORAGE_KEY = "jic_returnUrl";
export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";
export const PLACES_RESTRICTIONS_COUNTRY = "us";
export const ADDRESS_REQUIRED_FIELDS_NUMBER = 5;
export const MARKETING_PAGE_URL = "https://www.jicestates.com";
export const RESOURCE_PAGE_BASE_URL =
  "https://www.jicestates.com/resources";
export const DEFAULT_PLAN_TYPE = PlanType.LAST_WILL;
export const PASSWORD_REGEX =
  /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;
export const DOCUMENT_HISTORY_DATE_FORMAT = "dd LLL yyyy hh:MM a";

export const GOOGLE_MAPS_LANGUAGE = "en";

export const INVITATION_TOKEN_QUERY_PARAM_KEY = "token";
export const FINANCIAL_ADVISOR_INVITATION_TOKEN_QUERY_PARAM_KEY =
  "financialAdvisorToken";

export const CompensationTypes = {
  noCompensation:
    "No compensation. Reimbursement of reasonable expenses only",
  reasonableCompensation:
    "Reasonable compensation and reimbursement of expenses (most common)",
  specificDollar:
    "A specific dollar amount and reimbursement of expenses",
  specificPercent:
    "A specific percent of my estate and reimbursement of expenses",
  specifyCompensationAmount:
    "Specify a compensation amount and reimbursement of expenses"
};
