import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography/Typography";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import Tabs from "@components/Tabs";
import { usePlanForm } from "@providers/planForm/context";

import { ReviewTabsArchetypeProps } from "./types";

const ReviewTabsArchetype: React.FC<ReviewTabsArchetypeProps> = ({
  items,
  stepLayout
}) => {
  const intl = useIntl();
  const { goBack, saveAndContinue } = usePlanForm();

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage:
          "Nice stuff. Here’s the recap of what you shared:"
      })}
      isWide
      {...stepLayout}
    >
      <Tabs {...{ items }} />
      <FormStepFooter
        prevButtonProps={{ type: "button", onClick: goBack }}
        nextButtonProps={{
          type: "button",
          onClick: () => saveAndContinue(),
          label: intl.formatMessage({ defaultMessage: "Confirm" })
        }}
      />
    </StepLayout>
  );
};

export default ReviewTabsArchetype;
