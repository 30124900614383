import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";
import { PlanOption } from "@api/__generated__/globalTypes";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

const TrustRemainderStartStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter, partner]
  } = values;

  const { value: planOption } = useFormField("planOption");

  const isCouplesPlan =
    planOption === PlanOption.COUPLE ||
    planOption === PlanOption.JOINT;

  const { value: everythingToPartner } = useFormField(
    "users.0.gifts.everythingGoToPartner"
  );

  const isJointTrust = primaryDrafter.trust?.isJointTrust;

  if (isJointTrust && everythingToPartner) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `Next, we'll plan what should happen with your Trust Remainder if you survive ${partner.firstName}.`,
          muiIconHeader: <InfoOutlinedIcon />,
          subText:
            "Your Trust Remainder is what's left over after making any specific gifts of assets or cash. Don't worry about those specific gifts yet -- we'll get to those in a bit. We're starting with your Trust Remainder because (for most people) the Trust Remainder is the largest portion of their gifts."
        }}
      />
    );
  }

  return isCouplesPlan ? (
    <InterstitialArchetype
      stepLayout={{
        title: `Next, we'll plan how you want to gift your Trust Remainder.`,
        muiIconHeader: <InfoOutlinedIcon />,
        subText:
          "Your Trust Remainder is what's left over after making any specific gifts of assets or cash. Don't worry about those specific gifts yet -- we'll get to those in a bit. We're starting with your Trust Remainder because (for most people) the Trust Remainder is the largest portion of their gifts."
      }}
    />
  ) : (
    <InterstitialArchetype
      stepLayout={{
        title: `What's a Trust Remainder?`,
        muiIconHeader: <InfoOutlinedIcon />,
        subText:
          "Your Trust Remainder is what's left over after making any specific gifts of assets or cash. Don't worry about those specific gifts yet -- we'll get to those in a bit. We're starting with your Trust Remainder because (for most people) the Trust Remainder is the largest portion of their gifts."
      }}
    />
  );
};

export default TrustRemainderStartStep;
