import { Box, List } from "@mui/material";
import React from "react";

import NavSectionItem from "./NavSectionItem";
import AvatarMock from "./AvatarMock";
import { NavSectionName, SideNavSectionProps } from "./type";

const SideNavSection: React.FC<SideNavSectionProps> = ({
  accountOwner,
  sectionNames = [],
  ...restProps
}) => (
  <Box {...restProps}>
    <AvatarMock name={accountOwner} />
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {sectionNames.map((section: NavSectionName) => (
          <NavSectionItem key={section.title} {...{ section }} />
        ))}
      </List>
    </Box>
  </Box>
);

export default SideNavSection;
