import { Link as ReactLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

export const Link = styled(ReactLink)(({ theme }) => ({
  textDecoration: "none",
  lineHeight: 1.8,
  color: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: "1rem",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.text.secondary
    }
  }
}));
