import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerSameArrangementsStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { value: primaryBodyRestingPlace } = useFormField(
    "users.0.finalArrangements.bodyRestingPlace"
  );
  const { value: primaryCeremonyType } = useFormField(
    "users.0.finalArrangements.ceremonyType"
  );
  const { value: primarySpecialRequest } = useFormField(
    "users.0.finalArrangements.specialRequest"
  );

  const { setValue: setPartnerRestingPlace } = useFormField(
    `users.1.finalArrangements.bodyRestingPlace`
  );
  const { setValue: setPartnerCeremony } = useFormField(
    `users.1.finalArrangements.ceremonyType`
  );
  const { setValue: setPartnerOtherRequests } = useFormField(
    "users.1.finalArrangements.specialRequest"
  );

  const { value: copyAnswers } = useFormField<boolean>(
    "users.1.finalArrangements.copy"
  );

  const onSubmit = useCallback(() => {
    if (parseBoolean(copyAnswers)) {
      setPartnerRestingPlace(primaryBodyRestingPlace);
      setPartnerCeremony(primaryCeremonyType);
      setPartnerOtherRequests(primarySpecialRequest);
    }
  }, [
    copyAnswers,
    primaryBodyRestingPlace,
    primaryCeremonyType,
    primarySpecialRequest,
    setPartnerCeremony,
    setPartnerOtherRequests,
    setPartnerRestingPlace
  ]);

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want the same resting place, ceremony, and additional requests?`
      }}
      name="users.1.finalArrangements.copy"
      {...{ onSubmit }}
    />
  );
};

export default PartnerSameArrangementsStep;
