import { Asset } from "@api/plans/__generated__/Asset";
import { Asset as LastWillAsset } from "@pages/LastWill/types";
import { AssetType } from "@api/__generated__/globalTypes";

export const ACCOUNT_TYPE_OUTSIDE_LAST_WILL = [
  "Bank Account with Beneficiary Designation",
  "Investment Account",
  "Retirement Account",
  "Crypto Account",
  "Other Account With Beneficiary Designation"
];

const isAssetOutsideLastWill = (
  asset: Asset | LastWillAsset
): boolean => {
  if (asset.type === AssetType.LIFE_INSURANCE_POLICY) {
    return true;
  }

  if (asset.type === AssetType.FINANCIAL_ACCOUNT) {
    // Use type guard to narrow down the type to LastWillAsset
    if ("subType" in asset) {
      return !!ACCOUNT_TYPE_OUTSIDE_LAST_WILL.find(
        (subtype) =>
          subtype.toLowerCase() === asset.subType?.toLowerCase()
      );
    }
    // Use type guard to narrow down the type to Asset
    if ("subtype" in asset) {
      return !!ACCOUNT_TYPE_OUTSIDE_LAST_WILL.find(
        (subtype) =>
          subtype.toLowerCase() === asset.subtype?.toLowerCase()
      );
    }
  }

  return false;
};

export default isAssetOutsideLastWill;
