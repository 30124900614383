import { useCallback } from "react";

import { useCreatePaymentCheckoutMutation } from "@api/checkout/hooks";

interface UseCheckoutReturnType {
  getCheckoutUrl: () => Promise<string | undefined>;
  loading?: boolean;
}

export const useCheckout = (
  token?: string,
  successReturnPath?: string,
  cancelReturnPath?: string
): UseCheckoutReturnType => {
  const [createPayment, { loading }] =
    useCreatePaymentCheckoutMutation();

  const getCheckoutUrl = useCallback(async (): Promise<
    string | undefined
  > => {
    if (!token) {
      return undefined;
    }

    const { data: paymentData } = await createPayment({
      variables: { token, successReturnPath, cancelReturnPath }
    });
    const { url } = paymentData?.createPaymentCheckout || {};

    return url;
  }, [cancelReturnPath, createPayment, successReturnPath, token]);

  return {
    getCheckoutUrl,
    loading
  };
};
