import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import get from "lodash/get";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import { UserDetailsFormValues } from "./types";

export const useUserDetailsSchema = (
  name: string
): yup.AnyObjectSchema =>
  useMemo(() => {
    const basicShape: yup.AnySchema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phone: yup.string(),
      email: yup
        .string()
        .email("Please enter a valid email address")
        .required()
    });

    return makeYupSchema(basicShape, name);
  }, [name]);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useUserDetails = (
  name: string
  // onFormSubmit: (data: UserDetailsFormValues) => void
) => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useUserDetailsSchema(name);
  const controls = useFormWithYup<UserDetailsFormValues>(schema, {});

  return {
    controls,
    goBack,
    // onSubmit: controls.handleSubmit(onFormSubmit),
    saveAndContinue
  };
};

export { useUserDetails };
