export const HEALTHCARE_MEDICAL_POA_ROUTES = {
  Preview: "",
  CreateAHCD: "create-ahcd",
  PartnerCreateAHCD: "partner-create-ahcd",

  // Individual + Partner Medical POA starts here
  NominateAgent: "nominate-agent",
  DesignatePartnerAsAgent: "partner-as-agent",
  DesignateAgent: "designate-agent",
  NominateBackupAgent: "nominate-backup-agent",
  DesignateBackupAgent: "designate-backup-agent",
  PartnerNominateAgent: "partner-nominate-agent",
  PartnerDesignateAgent: "partner-designate-agent",
  PartnerNominateBackupAgent: "partner-nominate-backup-agent",
  PartnerDesignateBackupAgent: "partner-designate-backup-agent",

  // Medical Access Starts Here
  GrantMedicalAccess: "grant-medical-access",
  GrantSameMedicalAccess: "grant-same-medical-access",
  DesignateMedicalAccess: "designate-medical-access",
  PartnerGrantMedicalAccess: "partner-grant-medical-access",
  PartnerDesignateMedicalAccess: "partner-designate-medical-access",
  MedicalPOAComplete: "medical-poa-complete",

  // Not Creating AHCD (Fail)
  NoAHCD: "decline-ahcd"
};
