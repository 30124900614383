import { useIntl } from "react-intl";
import { FormProvider } from "react-hook-form";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import usePersonId from "@hooks/usePersonId";

import { ArchetypeProps } from "../types";

import { usePersonDetailsArchetype } from "./hooks";
import { PersonFormValues } from "./types";
import PersonDetailsForm from "./PersonDetailsForm";

const PersonDetailsArchetype: React.FC<
  ArchetypeProps<PersonFormValues>
> = ({
  name,
  onSubmit: onFormSubmit,
  stepLayout,
  personRole,
  hideRelationshipField
}) => {
  const intl = useIntl();
  const {
    controls,
    goBack,
    onSubmit,
    isModalOpened,
    toggleOff,
    saveAndContinue
  } = usePersonDetailsArchetype(name, onFormSubmit);
  const { randomizePersonId } = usePersonId(name);

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage:
          "Who would you like to serve as primary guardian?"
      })}
      {...stepLayout}
    >
      <FormProvider {...controls}>
        <form {...{ onSubmit }} onChange={randomizePersonId}>
          <PersonDetailsForm
            {...{ name, personRole, hideRelationshipField }}
            isAddressWarningDisplayed={isModalOpened}
            onCancel={toggleOff}
            onProceed={() => {
              saveAndContinue(true);
              toggleOff();
            }}
          />
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default PersonDetailsArchetype;
