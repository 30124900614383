import { useCallback } from "react";

import { useCreateAndCopyClientInviteMutation } from "@api/invitation";

import {
  InviteClientFormValues,
  UseInviteClientFormProps
} from "../components/AdvisorDashTools/types";
import { copyTextToClipBoard } from "@utils/copyTextToClipboard";

export const useCopyClientInviteMemberForm = ({
  methods,
  onCompleted
}: UseInviteClientFormProps) => {
  const [createAndCopyInvite, { loading, error, reset }] =
    useCreateAndCopyClientInviteMutation({
      onCompleted: () => {
        onCompleted?.();
        reset();
        methods.reset();
      }
    });

  const onCreateAndCopyClientInvite = useCallback(
    (data: InviteClientFormValues) => {
      createAndCopyInvite({
        variables: {
          input: {
            clientName: data.fullName,
            clientEmail: data.email,
            recommendedPlan: data.recommendedPlan || undefined,
            message: data.message
          }
        }
      }).then((response) => {
        const urlWithToken = response.data?.createClientInvite;

        urlWithToken
        ? copyTextToClipBoard(
          urlWithToken
        )
        : null
      });
    },
    [createAndCopyInvite, copyTextToClipBoard]
  );

  return {
    onCreateAndCopyClientInvite,
    loading: loading || methods.formState.isSubmitting,
    error,
    reset
  };
};
