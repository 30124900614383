import { useCallback } from "react";
import { omit } from "lodash";

import { LastWill } from "@api/plans/__generated__/LastWill";
import { useCreateAssetMutation } from "@api/assets";
import { AssetCreateInput } from "@api/__generated__/globalTypes";
import useToggle from "@hooks/useToggle";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { useGetPlansQuery } from "@api/plans";

export const useImportAssetModal = (userLastWill: LastWill) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { trust, lastWill } = useMemberPlansState();

  const [createAsset, { loading, error, reset }] =
    useCreateAssetMutation();
  const { refetch } = useGetPlansQuery();

  const canEdit = trust ? trust.canEdit : lastWill?.canEdit;

  const onSubmit = useCallback(
    async (data: Omit<AssetCreateInput, "lastWillId">[]) => {
      const lastWillId = userLastWill?.id;

      if (!lastWillId || !canEdit) {
        return {
          successImportAssets: [],
          failImportAssets: []
        };
      }

      const successImportAssets: Omit<
        AssetCreateInput,
        "lastWillId"
      >[] = [];
      const failImportAssets: Omit<AssetCreateInput, "lastWillId">[] =
        [];

      for (const asset of data) {
        const isGift =
          asset?.beneficiaries?.length > 0 && asset.isGift;
        const address = omit(asset.address, "stateCode");

        try {
          await createAsset({
            variables: {
              input: {
                lastWillId,
                ...{ ...asset, isGift, address }
              }
            }
          });
          successImportAssets.push(asset);
        } catch (error) {
          failImportAssets.push(asset);
        }
      }

      refetch(); // Pull in newly created assets

      return { successImportAssets, failImportAssets };
    },
    [canEdit, createAsset, userLastWill?.id]
  );

  return {
    isOpen,
    canEdit: !!canEdit,
    loading,
    error,
    toggleOn,
    toggleOff,
    onSubmit,
    reset
  };
};
