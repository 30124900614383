import { useIntl } from "react-intl";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import { Delete as DeleteIcon } from "@mui/icons-material";

import { ArrayFormFieldSingleItemProps } from "@components/FormFields/ArrayForm/types";

import {
  ActionWrapper,
  FormWrapper,
  NameInput,
  SharesInput
} from "./styles";

const BeneficiaryForm: React.FC<ArrayFormFieldSingleItemProps> = ({
  prefix,
  fieldsCount,
  removeField
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormWrapper>
      <SharesInput
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 100,
            style: { textAlign: "right" }
          },
          endAdornment: (
            <InputAdornment position="end">%</InputAdornment>
          )
        }}
        name={`${prefix}.percentageValue`}
        placeholder={formatMessage({ defaultMessage: "0" })}
      />
      <NameInput
        name={`${prefix}.name`}
        placeholder={formatMessage({
          defaultMessage: "Enter Beneficiary Name"
        })}
      />
      <ActionWrapper>
        {fieldsCount > 1 && (
          <DeleteIcon
            onClick={removeField}
            cursor="pointer"
            fontSize="small"
          />
        )}
      </ActionWrapper>
    </FormWrapper>
  );
};

export default BeneficiaryForm;
