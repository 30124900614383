import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { PlanOption } from "@api/__generated__/globalTypes";
import { Asset } from "@api/plans/__generated__/Asset";
import Tabs from "@components/Tabs";

import TabNotAvailableMessage from "../../components/TabNotAvailableMessage";
import TabRestrictedMessage from "../../components/TabRestrictedMessage";
import DocsAndDataTitle from "../../components/DocsAndDataTitle";

import LastWillAssetView from "./partials/LastWillAssetView";
import TrustAssetView from "./partials/TrustAssetView";

const AssetsTab = () => {
  const { myRoles, planCompleted, lastWill, trust } =
    useMemberPlansState();

  const isCouplePlan =
    (trust &&
      (trust.plan.planOption === PlanOption.COUPLE ||
        trust?.plan.planOption === PlanOption.JOINT)) ||
    (lastWill && lastWill.plan.planOption === PlanOption.COUPLE);

  const primaryDrafterLastWill = useMemo(() => {
    if (trust) {
      return trust.primaryDrafterLastWill;
    }

    if (lastWill) {
      return lastWill.primaryDrafterLastWill;
    }

    return undefined;
  }, [trust, lastWill]);

  const partnerLastWill = useMemo(() => {
    if (trust && isCouplePlan) {
      return trust.partnerLastWill;
    }

    if (lastWill && isCouplePlan) {
      return lastWill.partnerLastWill;
    }

    return undefined;
  }, [trust, lastWill, isCouplePlan]);

  const sharingDrafterName =
    `${myRoles?.accountOwner?.firstName} ${myRoles?.accountOwner?.lastName}` ||
    myRoles?.accountOwner?.fullName;

  const primaryDrafterFirstName = trust
    ? trust?.plan.primaryDrafter.firstName
    : lastWill?.plan.primaryDrafter.firstName;
  const partnerFirstName = trust
    ? trust?.plan.partner?.firstName
    : lastWill?.plan.partner?.firstName;

  const primaryDrafterAssetReviewItems = useMemo(
    () =>
      trust && primaryDrafterLastWill ? (
        <TrustAssetView
          lastWill={primaryDrafterLastWill}
          assets={
            primaryDrafterLastWill?.assets?.filter(Boolean) as Asset[]
          }
        />
      ) : (
        <LastWillAssetView
          lastWill={primaryDrafterLastWill}
          assets={
            primaryDrafterLastWill?.assets?.filter(Boolean) as Asset[]
          }
        />
      ),
    [trust, primaryDrafterLastWill]
  );

  const partnerAssetReviewItems = useMemo(
    () =>
      trust && partnerLastWill ? (
        <TrustAssetView
          lastWill={partnerLastWill}
          assets={partnerLastWill?.assets?.filter(Boolean) as Asset[]}
        />
      ) : (
        <LastWillAssetView
          lastWill={partnerLastWill}
          assets={partnerLastWill?.assets?.filter(Boolean) as Asset[]}
        />
      ),
    [trust, partnerLastWill]
  );

  const items = useMemo(
    () => [
      {
        label: primaryDrafterFirstName,
        content: primaryDrafterAssetReviewItems
      },
      {
        label: partnerFirstName,
        content: partnerAssetReviewItems
      }
    ],
    [
      primaryDrafterFirstName,
      partnerFirstName,
      primaryDrafterAssetReviewItems,
      partnerAssetReviewItems
    ]
  );

  if (myRoles && !lastWill && !trust) {
    return (
      <>
        <DocsAndDataTitle />
        <TabRestrictedMessage
          tabName="Assets"
          message={`You do not have access to view ${sharingDrafterName}'s Asset information.`}
        />
      </>
    );
  }

  if (!planCompleted || (!lastWill && !trust)) {
    return (
      <>
        <DocsAndDataTitle />
        <TabNotAvailableMessage
          tabName="Assets"
          planDependencies="Revocable Living Trust or Last Will"
        />
      </>
    );
  }

  return (
    <>
      <DocsAndDataTitle />
      <Typography variant="h3" sx={{ mt: "2.5rem" }}>
        <FormattedMessage defaultMessage="Assets" />
      </Typography>
      <Box display="flex" flexDirection="column" mt="2.5rem">
        {!isCouplePlan && primaryDrafterAssetReviewItems}
        {isCouplePlan && <Tabs {...{ items }} />}
      </Box>
    </>
  );
};

export default AssetsTab;
