import { Box, Typography, Link } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useCallback, useEffect, useState } from "react";

import useToggle from "@hooks/useToggle";
import Modal from "@components/Modal";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ROUTES } from "@setup/consts/routes";
import { DASHBOARD_SECTION_ROUTES } from "@pages/EstatePlanView/Partials/consts";

import { AdvisorWelcomeProps } from "../types";

import AdvisorInfoForm from "./AdvisorInfoForm";
import AdvisorTutorialGreeting from "./AdvisorTutorialGreeting";
import AdvisorTutorialSummaryOverview from "./AdvisorSummaryOverview";
import AdvisorTutorialClientDetails from "./AdvisorSummaryDetails";
import AdvisorTutorialEnd from "./AdvisorTutorialEnd";
import AdvisorWelcomeVideo from "./AdvisorLukeWelcomeVideo";

const AdvisorWelcomeGuide: React.FC<AdvisorWelcomeProps> = ({
  user
}) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  useEffect(() => {
    if (!user?.firstName) {
      toggleOn();
    }
  }, [toggleOn, user.firstName]);

  const [step, setStep] = useState(user?.firstName ? 1 : 0);

  const onPrevStep = () => {
    setStep(step - 1);
  };

  const onNextStep = () => {
    setStep(step + 1);
  };

  const handleClose = useCallback(() => {
    toggleOff();
    setStep(0);
  }, [toggleOff, setStep]);

  let tutorialContent;

  if (step === 0) {
    tutorialContent = <AdvisorInfoForm onNext={onNextStep} />;
  } else if (step === 1) {
    tutorialContent = (
      <AdvisorTutorialGreeting
        onNext={onNextStep}
        onPrev={onPrevStep}
      />
    );
  } else if (step === 2) {
    tutorialContent = (
      <AdvisorWelcomeVideo onNext={onNextStep} onPrev={onPrevStep} />
    );
  } else if (step === 3) {
    tutorialContent = (
      <AdvisorTutorialSummaryOverview
        onNext={onNextStep}
        onPrev={onPrevStep}
      />
    );
  } else if (step === 4) {
    tutorialContent = (
      <AdvisorTutorialClientDetails
        onNext={onNextStep}
        onPrev={onPrevStep}
      />
    );
  } else if (step === 5) {
    tutorialContent = (
      <AdvisorTutorialEnd onNext={handleClose} onPrev={onPrevStep} />
    );
  } else {
    handleClose();
  }

  return (
    <Box>
      <Typography>
        <FormattedMessage defaultMessage="Welcome to JIC Estates Advisors!" />
        <br /> <br />
        <FormattedMessage defaultMessage="Access your Client Summary Dashboard in the" />{" "}
        <Link
          sx={{
            textDecoration: "none"
          }}
          href={getLinkFullUrl([
            ROUTES.Dashboard,
            DASHBOARD_SECTION_ROUTES.SharedWithMeSummary
          ])}
        >
          <FormattedMessage defaultMessage='"Shared with Me"' />
        </Link>{" "}
        <FormattedMessage defaultMessage="tab, or check out the " />{" "}
        <Link
          sx={{
            textDecoration: "none",
            "&:hover": {
              cursor: "pointer"
            }
          }}
          href={getLinkFullUrl([
            ROUTES.Dashboard,
            DASHBOARD_SECTION_ROUTES.Resources
          ])}
        >
          <FormattedMessage defaultMessage="Advisor Resources" />
        </Link>{" "}
        <FormattedMessage defaultMessage="tab for product demos, tutorials, and other helpful resources." />
        <br /> <br />
        <FormattedMessage defaultMessage='This "My Plan" section is home to the documents, data, and resources for your own personal estate plan, should you create it with JIC Estates.' />
        <br /> <br />
        <FormattedMessage defaultMessage="You can get started on your personal estate plan by launching one of the questionnaire experiences below." />
      </Typography>
      <Modal
        fullWidth
        open={isOpen}
        onClose={handleClose}
        content={tutorialContent}
      />
    </Box>
  );
};

export default AdvisorWelcomeGuide;
