import React from "react";
import { Stack, Typography } from "@mui/material";

import DocumentCard from "@components/DocumentCard";

import { DocumentsListProps } from "./types";

const DocumentsList: React.FC<DocumentsListProps> = ({ items }) => (
  <Stack spacing={5}>
    {items.map(({ key, title, items: groupItems }) => (
      <section {...{ key }}>
        <Typography variant="h2" fontWeight={600} mb={2.5}>
          {title}
        </Typography>
        <Stack
          component="ul"
          spacing={2}
          m={0}
          p={0}
          sx={{ listStyle: "none" }}
        >
          {groupItems.map(({ key: groupKey, ...item }) => (
            <li key={groupKey}>
              <DocumentCard {...item} />
            </li>
          ))}
        </Stack>
      </section>
    ))}
  </Stack>
);

export default DocumentsList;
