import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { PartnerType } from "@api/__generated__/globalTypes";
import { RemainderStrategy } from "@hooks/useTrustEnums";

const RemainderStrategyStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");
  const lowerPartnerType = partnerType.toLowerCase();
  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    children,
    users: [_, partner]
  } = values;

  const { setValue: setEstateBalanceGift } = useFormField(
    "users.0.assets.estateBalance"
  );

  const { value: everythingToPartner } = useFormField(
    "users.0.gifts.everythingGoToPartner"
  );
  const { setValue: setRemainderDistribType } = useFormField(
    "users.0.gifts.remainderDistribType"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const remainderStrategy = get(
        data,
        "users.0.gifts.remainderStrategy"
      );

      if (remainderStrategy === RemainderStrategy.NameIndividually) {
        setRemainderDistribType(null);
      }

      if (remainderStrategy === RemainderStrategy.PartnerOnly) {
        setRemainderDistribType(null);
        setEstateBalanceGift([]);
        setEstateBalanceGift([
          {
            type: "estateBalance",
            beneficiaries: [
              {
                name: `${partner.firstName} ${partner.lastName}`,
                percentageValue: 100
              }
            ]
          }
        ]);
      }
      // Memo: if giving only to children, we reset estateBalance gifts after user selects option for children on different page
    },
    [partner, setEstateBalanceGift, setRemainderDistribType]
  );

  const useItems = [
    {
      value: RemainderStrategy.NameIndividually,
      label: "Name my beneficiaries individually"
    }
  ];

  if (partnerType !== PartnerType.SINGLE && !everythingToPartner) {
    useItems.push({
      value: RemainderStrategy.PartnerOnly,
      label: `My ${lowerPartnerType}`
    });
  }

  if (children && children.length > 0) {
    useItems.push({
      value: RemainderStrategy.ChildrenOnly,
      label: "My children"
    });
  }

  if (
    children &&
    children.length > 0 &&
    partnerType !== PartnerType.SINGLE &&
    !everythingToPartner
  ) {
    useItems.push({
      value: RemainderStrategy.PartnerAndChildren,
      label: `My ${lowerPartnerType} and children`
    });
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: everythingToPartner
          ? `If you survive ${partner.firstName}, who do you want to be the primary beneficiary of your Trust Remainder?`
          : `Who do you want to be the primary beneficiary of your Trust Remainder?`,
        subText:
          "The primary beneficiaries are the first people to receive Trust assets upon your passing."
      }}
      name="users.0.gifts.remainderStrategy"
      {...{ onSubmit }}
      items={useItems}
    />
  );
};

export default RemainderStrategyStep;
