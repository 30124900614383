import { useMemo } from "react";
import { useIntl } from "react-intl";

import MultiSelectArchetype from "@components/archetypes/MultiSelect";
import { MultiSelectOption } from "@components/archetypes/MultiSelect/partials/MultiSelectForm/types";
import { LegalFocus } from "@pages/EstateAdminQuiz/enums";

const LegalFocusStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const options: MultiSelectOption[] = useMemo(
    () => [
      {
        value: LegalFocus.ESTATE_ADMINISTRATION,
        label: formatMessage({
          defaultMessage: "Estate Adminstration (Most Common)"
        })
      },
      {
        value: LegalFocus.BENEFICIARY_RIGHTS,
        label: formatMessage({
          defaultMessage: "Understanding My Rights as Beneficiary"
        })
      },
      {
        value: LegalFocus.ESTATE_CHALLENGE,
        label: formatMessage({
          defaultMessage: "Contesting a Will or Other Estate Plan"
        })
      },
      {
        value: LegalFocus.OTHER,
        label: formatMessage({
          defaultMessage: "Something Else"
        })
      }
    ],
    [formatMessage]
  );

  return (
    <MultiSelectArchetype
      stepLayout={{
        title: `What would you like your expert attorney to assist you with?`
      }}
      {...{ options }}
      valueAsKey
      name="estateData.focusAreas"
    />
  );
};

export default LegalFocusStep;
