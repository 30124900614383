import { Box, Avatar, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

import { AvatarMockProps } from "./type";
import { StyledAccount } from "./styles";

const AvatarMock: React.FC<AvatarMockProps> = ({ name }) => {
  const theme = useTheme();

  const firstInitial = name.slice(0, 1);

  return (
    <Box sx={{ mt: 5, mx: 2.5 }}>
      <StyledAccount>
        <Avatar sx={{ bgColor: theme.palette.text.secondary }}>
          {firstInitial}
        </Avatar>
        <Box sx={{ ml: 2 }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary }}
          >
            {name}
          </Typography>
        </Box>
      </StyledAccount>
    </Box>
  );
};

export default AvatarMock;
