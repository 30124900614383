import PersonDetailsArchetype from "@components/archetypes/PersonDetails";
import { useFormField } from "@components/FormFields/hooks";

const PartnerDesignateAgentStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <PersonDetailsArchetype
      name="users.1.poa.agents.0"
      personRole="Agent"
      stepLayout={{
        title: `No problem. What is the name and address of the person ${partnerFirstName} wants to be agent with Power of Attorney?`
      }}
    />
  );
};

export default PartnerDesignateAgentStep;
