import { createContext, useContext } from "react";
import { noop } from "lodash";

import { AIAssistantContextType } from "./type";

const AIAssistantContext = createContext<AIAssistantContextType>({
  isChatEnabled: false,
  toggleChat: noop
});

const useAIAssistant = () => useContext(AIAssistantContext);

export { AIAssistantContext, useAIAssistant };
