import { Box } from "@mui/material";
import { useMemo } from "react";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import GavelIcon from "@mui/icons-material/Gavel";
import { useIntl } from "react-intl";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { PlanOption } from "@api/__generated__/globalTypes";

import { useAllPartnerReviews } from "../../PartnerReview/useAllPartnerReviews";
import { useAllReviews } from "../../PrimaryDrafterReviews/useAllReviews";
import { ReviewAccordionProps } from "../../types";
import { ReviewAccordion } from "../../components/ReviewAccordion";
import { useAllPetPlanReviews } from "../../PetPlanReviews/useAllPetPlanReviews";
import useHealthcareReview from "../../PrimaryDrafterReviews/useHealthcareReview";
import { usePoaReview } from "../../PrimaryDrafterReviews/usePoaReview";
import { usePartnerHealthcareReview } from "../../PartnerReview/usePartnerHealthcareReview";
import { usePartnerPoaReview } from "../../PartnerReview/usePartnerPoaReview";

const DataReviewSection = () => {
  const primaryDrafterReviews = useAllReviews();
  const partnerReviews = useAllPartnerReviews();
  const petPlanReviews = useAllPetPlanReviews();
  const healthcareItems = useHealthcareReview();
  const poaItems = usePoaReview();
  const partnerHealthcareItems = usePartnerHealthcareReview();
  const partnerPoaItems = usePartnerPoaReview();

  const { formatMessage } = useIntl();

  const advancedHealthCareReview = useMemo(
    () => [
      {
        tab: formatMessage({
          defaultMessage: "Healthcare"
        }),
        items: healthcareItems,
        icon: <HealthAndSafetyIcon />
      }
    ],
    [formatMessage, healthcareItems]
  );

  const poaReview = useMemo(
    () => [
      {
        tab: formatMessage({
          defaultMessage: "Power of Attorney"
        }),
        items: poaItems,
        icon: <GavelIcon />
      }
    ],
    [formatMessage, poaItems]
  );

  const partnerAdvancedHealthCareReview = useMemo(
    () => [
      {
        tab: formatMessage({
          defaultMessage: "Healthcare"
        }),
        items: partnerHealthcareItems,
        icon: <HealthAndSafetyIcon />
      }
    ],
    [formatMessage, partnerHealthcareItems]
  );

  const partnerPoaReview = useMemo(
    () => [
      {
        tab: formatMessage({
          defaultMessage: "Power of Attorney"
        }),
        items: partnerPoaItems,
        icon: <GavelIcon />
      }
    ],
    [formatMessage, partnerPoaItems]
  );

  const { trust, lastWill, petProtection } = useMemberPlansState();
  const isTrustCouplePlan =
    trust?.plan?.planOption === PlanOption.COUPLE ||
    trust?.plan?.planOption === PlanOption.JOINT;
  const isLastWillCouplePlan =
    lastWill?.plan?.planOption === PlanOption.COUPLE;
  const isCouplePlan = isTrustCouplePlan || isLastWillCouplePlan;

  const allReviews = useMemo<ReviewAccordionProps[]>(
    () => [
      ...(trust || lastWill
        ? [
            {
              label: trust
                ? `${trust.plan.primaryDrafter?.firstName}'s Revocable Living Trust Data`
                : `${lastWill?.plan.primaryDrafter?.firstName}'s Last Will & Testament Data`,
              reviews: primaryDrafterReviews,
              initiallyExpanded: !isCouplePlan
            }
          ]
        : []),
      ...((trust || lastWill) && advancedHealthCareReview
        ? [
            {
              label: `${
                trust
                  ? trust.plan.primaryDrafter.firstName
                  : lastWill?.plan.primaryDrafter.firstName
              }'s Advance Healthcare Directive Data`,
              reviews: advancedHealthCareReview
            }
          ]
        : []),
      ...((trust || lastWill) && poaReview
        ? [
            {
              label: `${
                trust
                  ? trust.plan.primaryDrafter.firstName
                  : lastWill?.plan.primaryDrafter.firstName
              }'s Power of Attorney Data`,
              reviews: poaReview
            }
          ]
        : []),
      ...((trust || lastWill) && isCouplePlan
        ? [
            {
              label: trust
                ? `${trust.plan.partner?.firstName}'s Revocable Living Trust Data`
                : `${lastWill?.plan.partner?.firstName}'s Last Will & Testament Data`,
              reviews: partnerReviews
            }
          ]
        : []),
      ...(partnerAdvancedHealthCareReview && isCouplePlan
        ? [
            {
              label: `${
                trust
                  ? trust.plan.partner?.firstName
                  : lastWill?.plan.partner?.firstName
              }'s Advance Healthcare Directive Data`,
              reviews: partnerAdvancedHealthCareReview
            }
          ]
        : []),
      ...(partnerPoaReview && isCouplePlan
        ? [
            {
              label: `${
                trust
                  ? trust.plan.partner?.firstName
                  : lastWill?.plan.partner?.firstName
              }'s Power of Attorney Data`,
              reviews: partnerPoaReview
            }
          ]
        : []),
      ...(petProtection
        ? [
            {
              label: `${petProtection.plan.primaryDrafter?.firstName}'s Pet Estate Plan Data`,
              reviews: petPlanReviews
            }
          ]
        : [])
    ],
    [
      trust,
      advancedHealthCareReview,
      isCouplePlan,
      lastWill,
      partnerAdvancedHealthCareReview,
      partnerPoaReview,
      partnerReviews,
      petPlanReviews,
      petProtection,
      poaReview,
      primaryDrafterReviews
    ]
  );

  return (
    <Box display="flex" flexDirection="column" gap="1rem" mt="2.5rem">
      {allReviews.map(({ label, ...restProps }) => (
        <ReviewAccordion key={label} {...{ ...restProps, label }} />
      ))}
    </Box>
  );
};

export default DataReviewSection;
