import { useIntl } from "react-intl";
import { FormControl, Typography } from "@mui/material";
import * as yup from "yup";
import { useMemo } from "react";

import FormInput from "@components/FormFields/FormInput";
import BeneficiaryArrayForm from "@components/FormFields/BeneficiaryArrayForm";
import {
  Beneficiary,
  BeneficiaryFormProps
} from "@pages/LastWill/types";
import FormCharitiesAutocomplete from "@components/FormFields/FormCharitiesAutocomplete";

import { GiftCashFormProps } from "./types";

const CharityBeneficiaryForm: React.FC<
  Omit<BeneficiaryFormProps, "index">
> = ({ prefix }) => (
  <FormCharitiesAutocomplete
    beneficiaryPrefix={prefix}
    name={`${prefix}.charity`}
    forDollarValue
  />
);

const BeneficiaryForm: React.FC<
  Omit<BeneficiaryFormProps, "index">
> = ({ prefix }) => (
  <>
    <FormInput
      name={`${prefix}.name`}
      label="Beneficiary's Full Name"
    />
    <FormInput
      name={`${prefix}.dollarValue`}
      label="Dollar Value ($USD)"
      InputProps={{
        startAdornment: `$`,
        type: "number"
      }}
    />
  </>
);

const GiftCashForm: React.FC<GiftCashFormProps> = ({
  name,
  index
}) => {
  const { formatMessage } = useIntl();

  const formSchema: yup.SchemaOf<
    Omit<
      Beneficiary,
      | "id"
      | "__typename"
      | "backupDistribution"
      | "distribType"
      | "subTrust"
      | "backupBeneficiaries"
    >
  > = useMemo(
    () =>
      yup.object().shape({
        name: yup.string(),
        dollarValue: yup.number(),
        percentageValue: yup
          .number()
          .typeError("Please enter a value between 0 and 100"),
        charity: yup.object().shape({
          name: yup.string(),
          ein: yup.string(),
          address: yup.object().shape({
            address1: yup.string(),
            address2: yup.string(),
            city: yup.string(),
            zip: yup.string(),
            state: yup.string()
          })
        })
      }),
    []
  );

  return (
    <FormControl variant="standard" sx={{ display: "flex" }}>
      <Typography variant="body1" color="grey.700">
        {formatMessage(
          {
            defaultMessage: "Cash Gift #{number}"
          },
          { number: index + 1 }
        )}
      </Typography>
      <BeneficiaryArrayForm
        addBtnLabel="+ Add Another Beneficiary"
        removeBtnLabel="- Remove Beneficiary"
        singleItemSchema={formSchema}
        IndividualBeneficiaryItem={BeneficiaryForm}
        CharityBeneficiaryItem={CharityBeneficiaryForm}
        limitOneBeneficiary
        name={`${name}.beneficiaries`}
      />
    </FormControl>
  );
};

export default GiftCashForm;
