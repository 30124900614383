import { Button, Box } from "@mui/material";
import { useIntl } from "react-intl";

import BasicFormAutocomplete from "@components/FormFields/FormAutocomplete/BasicAutocomplete";
import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";

import {
  useMembershipRoleOptions,
  usePermissionLevelOptions
} from "../../hooks";
import PermissionLevelInfoModal from "../PermissionLevelInfoModal";
import RoleInfoModal from "../RoleInfoModal";

import { SharePlanFormElementsProps } from "./types";

const SharePlanFormElements: React.FC<SharePlanFormElementsProps> = ({
  disableName = false
}) => {
  const { formatMessage } = useIntl();
  const options = usePermissionLevelOptions();
  const membershipRoleOptions = useMembershipRoleOptions();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.4rem"
      }}
    >
      <BasicFormInput
        label={formatMessage({
          defaultMessage: "Full Name"
        })}
        name="fullName"
        disabled={disableName}
      />
      <BasicFormInput
        label={formatMessage({
          defaultMessage: "Email Address"
        })}
        name="email"
      />
      <RoleInfoModal />
      <BasicFormAutocomplete
        textFieldProps={{
          label: formatMessage({
            defaultMessage: "Role"
          })
        }}
        freeSolo
        options={membershipRoleOptions}
        name="role"
      />
      <PermissionLevelInfoModal />
      <BasicFormAutocomplete
        textFieldProps={{
          label: formatMessage({
            defaultMessage: "Permission Level"
          })
        }}
        options={options}
        name="permissionLevel"
      />
      <BasicFormInput
        label={formatMessage({
          defaultMessage: "Message (Optional)"
        })}
        sx={{
          marginTop: "1.5rem"
        }}
        variant="outlined"
        InputProps={{
          multiline: true,
          minRows: 5
        }}
        name="message"
      />
      <Button
        sx={{
          marginTop: "1.5rem"
        }}
        type="submit"
        variant="contained"
      >
        {formatMessage({
          defaultMessage: "SEND INVITE"
        })}
      </Button>
    </Box>
  );
};

export default SharePlanFormElements;
