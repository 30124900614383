import { useIntl } from "react-intl";
import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { Beneficiary } from "@pages/LastWill/types";
import { DistribType } from "@api/__generated__/globalTypes";
import { usePlanForm } from "@providers/planForm/context";

const PartnerRemainderDistribTypeLoopedStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: beneficiary } = useFormField<Beneficiary>(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}`
  );

  const { setValue: setSubTrustValue } = useFormField(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}.subTrust`
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const distribType = get(
        data,
        `users.1.assets.estateBalance.0.beneficiaries.${iteration}.distribType`
      );

      if (distribType === DistribType.SEPARATE_INDIVIDUAL) {
        setSubTrustValue({
          name: `Trust for ${beneficiary.name}`,
          trustType: DistribType.SEPARATE_INDIVIDUAL
        });
      }
    },
    [iteration, setSubTrustValue, beneficiary.name]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "How do you want to make the {percentageValue}% distribution of the Trust Remainder to {name}?"
          },
          {
            name: beneficiary.name,
            percentageValue: beneficiary.percentageValue
          }
        ),
        subText: "Learn more about these distribution types.",
        moreInfoModalProps: {
          title: "Distribution Types Explained",
          togglerLabel: "Learn more.",
          content: (
            <p>
              <strong>Outright Distributions.</strong> Outright
              distributions are gifts with no strings attached. They
              are made as soon after your death as practical, and your
              beneficiary can use the gift for any purpose.
              <br />
              <br />
              <strong>Gifting in a Trust.</strong> You can make gifts
              subject to greater protection and/or eligibility
              requirements by giving in a trust. If you elect to gift
              in a trust, we&apos;ll ask some follow up questions on
              how you want that trust set up.
            </p>
          )
        }
      }}
      name={`users.1.assets.estateBalance.0.beneficiaries.${iteration}.distribType`}
      items={[
        {
          value: DistribType.OUTRIGHT,
          label: `Give outright`
        },
        {
          value: DistribType.SEPARATE_INDIVIDUAL,
          label: `Give in a trust`
        }
      ]}
      defaultValue={DistribType.OUTRIGHT}
      {...{ onSubmit }}
    />
  );
};

export default PartnerRemainderDistribTypeLoopedStep;
