import { Card, CardHeader, CircularProgress } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { usePlanTodosContext } from "@providers/planTodos/context";

import PlanItem from "./PlanItem";
import CreatePlanTodo from "./CreatePlanTodo";

const PlanTodos: React.FC = () => {
  const { todos, loading } = usePlanTodosContext();

  const { formatMessage } = useIntl();

  if (loading) {
    return <CircularProgress color="inherit" />;
  }

  return (
    <Card
      sx={{
        marginTop: "2.5rem"
      }}
    >
      <CardHeader
        style={{
          fontSize: "1rem"
        }}
        title={formatMessage({
          defaultMessage: "Do This Next"
        })}
      />
      {todos.map((task) => (
        <PlanItem key={task?.id} {...{ task }} />
      ))}
      <CreatePlanTodo />
    </Card>
  );
};

export default PlanTodos;
