import {
  Link,
  Box,
  Typography,
  useTheme,
  Button
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { RESOURCE_PAGE_BASE_URL } from "@setup/consts/misc";

import PlanNotCompletedMessage from "../../components/PlanNotCompletedMessage";
import MyRoleDetails from "../../components/MyRoleDetails";
import ResourceListing from "../../components/ResourceListing";
import DocsAndDataTitle from "../../components/DocsAndDataTitle";
import NeedHelpMessage from "../../components/NeedHelpMessage";
import { PlanTodosWrapper } from "../../components/PlanTodos";
import PlanNotStartedMessage from "../../components/PlanNotStartedMessage";
import { SectionQuickLinks } from "../../components/SectionQuickLinks";
import ResourceVideoListings from "../../components/ResourceListing/ResourceVIdeosListing";

import { clientResourceItems } from "./consts";
import { getClientTutorials } from "./getClientTutorials";

const ClientResources = () => {
  const {
    myRoles,
    trust,
    lastWill,
    petProtection,
    planStarted,
    planCompleted
  } = useMemberPlansState();

  const clientVideos = getClientTutorials(trust, lastWill);

  const theme = useTheme();

  if (!planStarted.isStarted && myRoles === undefined) {
    return (
      <>
        <DocsAndDataTitle />
        <PlanNotStartedMessage />
      </>
    );
  }

  if (!planCompleted && myRoles === undefined) {
    return (
      <>
        <DocsAndDataTitle />
        <PlanNotCompletedMessage />
      </>
    );
  }

  if (myRoles && !trust && !lastWill && !petProtection) {
    return (
      <>
        <DocsAndDataTitle />
        <MyRoleDetails />
      </>
    );
  }

  return (
    <Box>
      <DocsAndDataTitle />
      <SectionQuickLinks items={clientResourceItems} />
      <Typography
        id="checklist"
        variant="h3"
        sx={{ mt: "2.5rem", mb: "1.25rem" }}
      >
        <FormattedMessage defaultMessage="Estate Planning Checklist" />
      </Typography>
      <PlanTodosWrapper />
      <Box mt="2.5rem">
        <NeedHelpMessage message="Need Help?" />
      </Box>
      <Typography
        id="resources"
        variant="h3"
        sx={{ mt: "2.5rem", mb: "1.25rem" }}
      >
        <FormattedMessage defaultMessage="Recommended Resources" />
      </Typography>
      <ResourceListing />
      <Link
        sx={{
          textDecoration: "none",
          color: "inherit"
        }}
        href={RESOURCE_PAGE_BASE_URL}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.1rem",
            gap: "1rem"
          }}
        >
          <Typography
            color={theme.palette.grey["500"]}
            sx={{
              fontSize: "0.875rem",
              fontWeight: "500"
            }}
          >
            Want even more tips? Check out our resource library.
          </Typography>
          <Button variant="contained" color="primary">
            Go to Resources
          </Button>
        </Box>
      </Link>
      {clientVideos && clientVideos.length > 0 && (
        <Box>
          <Typography
            id="tutorials"
            variant="h3"
            sx={{ mt: "2.5rem", mb: "1.25rem" }}
          >
            <FormattedMessage defaultMessage="Tutorials" />
          </Typography>
          <ResourceVideoListings videos={clientVideos} />
        </Box>
      )}
    </Box>
  );
};

export default ClientResources;
