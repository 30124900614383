import { gql } from "@apollo/client";

import { IMAGE_FRAGMENT, LINK_FRAGMENT } from "./fragments";

export const GET_NAVIGATION = gql`
  ${IMAGE_FRAGMENT}
  ${LINK_FRAGMENT}
  query GetNavigation {
    allNavigation {
      _id
      items {
        _key
        label
        link {
          ...LinkFragment
        }
        items {
          _key
          label
          link {
            ...LinkFragment
          }
        }
      }
      logo {
        ...ImageFragment
      }
      mobileLogo {
        ...ImageFragment
      }
    }
  }
`;
