import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import { ChangeEvent, useCallback } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import { FormCheckboxProps } from "./types";

const BasicFormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  ...restProps
}) => {
  const defaultValue = get(restProps, "defaultValue", false);

  const { control, watch, setValue: setRHFValue } = useFormContext();

  const value = watch(name) || "";

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked: inputValue } = event.target;

      setRHFValue(name, inputValue, { shouldValidate: true });
    },
    [setRHFValue, name]
  );

  return (
    <Controller
      {...{ control, name }}
      defaultValue={value || defaultValue}
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...restProps}
              {...field}
              {...{ value, onChange }}
              size="small"
            />
          }
          {...{ label }}
        />
      )}
    />
  );
};

export default BasicFormCheckbox;
