/* eslint-disable camelcase */
import { uniq, groupBy } from "lodash";

import { PetProtection } from "@api/plans/__generated__/PetProtection";

const isSameVet = (pets: PetProtection | null): boolean => {
  let result = false;

  if (
    pets?.pets &&
    pets?.pets.length > 1 &&
    uniq(pets?.pets?.map((pet) => pet?.vetId)).length < 2
  ) {
    result = true;
  }

  return result;
};

export default isSameVet;
