import { Box, Stack, Typography } from "@mui/material";

import RoadmapItem from "./partials/RoadmapItem";
import { RoadmapProps } from "./types";

const Roadmap: React.FC<RoadmapProps> = ({ title, items }) => (
  <Box>
    {title && (
      <Typography component="h2" fontWeight={500} mb={1.25}>
        {title}
      </Typography>
    )}
    <Stack>
      {items.map(({ label, ...item }) => (
        <RoadmapItem key={label} {...{ label, ...item }} />
      ))}
    </Stack>
  </Box>
);

export default Roadmap;
