import { makeMutationHook } from "@utils/apollo-utils";

import { CREATE_PAYMENT_CHECKOUT } from "./mutations";
import {
  CreatePaymentCheckout,
  CreatePaymentCheckoutVariables
} from "./__generated__/CreatePaymentCheckout";

export const useCreatePaymentCheckoutMutation = makeMutationHook<
  CreatePaymentCheckout,
  CreatePaymentCheckoutVariables
>(CREATE_PAYMENT_CHECKOUT);
