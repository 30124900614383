import { FormattedMessage } from "react-intl";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import InfoModal from "@components/InfoModal";
import { useFormField } from "@components/FormFields/hooks";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { EXPERT_MATCHING_ROUTES } from "@pages/EstateAdminQuiz/consts";
import { ROUTES } from "@setup/consts/routes";
import { HouseholdData } from "@pages/EstateAdminQuiz/types";
import useToggle from "@hooks/useToggle";

import { EXPERT_MATCHING_RESULTS } from "../../consts";

const FinancialFailureMessage: React.FC = () => {
  // TODO: See if we can get the parent button to toggle on the info modal...
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { value: householdData } =
    useFormField<HouseholdData>("householdData");

  const { personalInvestmentHelp } = householdData;

  const financialFocus = personalInvestmentHelp
    ? "the estate and your personal"
    : "the estate";

  const useModalRecommendations = (
    includePersonalTips: boolean | undefined
  ): JSX.Element => (
    <>
      <p>
        <strong>
          <u>Estate Assets</u>
        </strong>
        . Our JIC Estates resource library has great guides on serving
        as an executor or trustee. Here are some of the top tips when
        it comes to managing {financialFocus} assets:
        <ol>
          <li>
            <strong>Get organized.</strong> Gather all the necessary
            documents for the estate documents, estate assets, and
            estate liabilities.
          </li>
          <li>
            <strong>Understand the assets.</strong> Review the value
            for each asset, how it is titled, and who may have a claim
            against it.
          </li>
          <li>
            <strong>Create a plan.</strong> Create a plan on how you
            will manage each asset that is in line with the interest
            of the deceased and the beneficiaries.
          </li>
          <li>
            <strong>Communicate with beneficiaries.</strong> Keep the
            beneficiaries informed about the status of the estate and
            any material decisions being made.
          </li>
        </ol>
      </p>
      {includePersonalTips && (
        <p>
          <strong>
            <u>
              Financial and Registered Investment Advisor Alternatives
            </u>
          </strong>
          {`. As you continue building your income and investible assets,
          you might consider working with a so-called "robo advisor"
          to help invest your money and achieve your financial goals. `}
          Here are some of the most popular ones:
          <ul>
            <li>Fidelity Go</li>
            <li>Vanguard Digital</li>
            <li>Betterment</li>
            <li>Wealthfront</li>
          </ul>
          Although not a substitute for the more comprehensive,
          personalized approach of an experienced human financial or
          registered investment advisor, robo advisors offer a
          convenient and cost-effective way to manage your investments
          and reach your financial goals. <br /> <br /> In many cases,
          you can get started with as little as $10.
        </p>
      )}
    </>
  );

  return (
    <>
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        <FormattedMessage defaultMessage="Shoot! Our financial experts are not a fit for you at this time." />
      </Typography>
      <Box maxWidth="29.375rem" width="100%" m="0 auto">
        <Typography textAlign="left" mt="1.5rem">
          <FormattedMessage defaultMessage="If you like, you can sign up to be notified when we make additional services available." />
        </Typography>
        <Typography textAlign="left" mt="1.25rem">
          {`In the meantime, you might find these tips on managing ${financialFocus} assets helpful.`}
        </Typography>
        <Grid
          container
          spacing={2.5}
          mt={{ xs: 3.75, md: 2.5 }}
          sx={{
            "> .MuiGrid-root": {
              paddingTop: 0
            }
          }}
        >
          <Grid item xs={12} md={6} paddingBottom="1.5rem">
            <Button
              color="inherit"
              variant="outlined"
              onClick={toggleOn}
              sx={{
                width: "100%",
                minWidth: "auto"
              }}
            >
              <InfoModal
                title={`Tips on managing ${financialFocus} assets`}
                togglerLabel="VIEW TIPS"
                content={useModalRecommendations(
                  personalInvestmentHelp
                )}
              />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                minWidth: "auto"
              }}
              href={getLinkFullUrl([
                ROUTES.EstateAdminQuiz,
                EXPERT_MATCHING_ROUTES.Results,
                EXPERT_MATCHING_RESULTS.CollectFailedUserInfo
              ])}
            >
              <FormattedMessage defaultMessage="Sign Up For Updates" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FinancialFailureMessage;
