import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const useFinancialAccountSubTypeOptions =
  (): AutocompleteBaseOption[] => {
    const { formatMessage } = useIntl();

    return useMemo(
      () =>
        [
          formatMessage({
            defaultMessage:
              "Bank Account Without Beneficiary Designation"
          }),
          formatMessage({
            defaultMessage:
              "Bank Account With Beneficiary Designation"
          }),
          formatMessage({ defaultMessage: "Investment Account" }),
          formatMessage({ defaultMessage: "Retirement Account" }),
          formatMessage({ defaultMessage: "Crypto Account" }),
          formatMessage({
            defaultMessage:
              "Other Account With Beneficiary Designation"
          }),
          formatMessage({
            defaultMessage:
              "Other Account Without Beneficiary Designation"
          })
        ].map((type) => ({
          key: type,
          value: type,
          label: type
        })),
      [formatMessage]
    );
  };
