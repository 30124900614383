import AgentCompensationAmountArchetype from "@components/archetypes/AgentCompensationAmountArchetype";

const AgentCompensationAmountStep: React.FC = () => (
  <AgentCompensationAmountArchetype
    stepLayout={{
      title: `Ok. What compensation should your agent receive?`
    }}
    name="users.1.poa"
  />
);

export default AgentCompensationAmountStep;
