import { PlanType } from "@api/__generated__/globalTypes";
import {
  TrustPlan,
  LastWillPlan,
  PetProtectionPlan,
  Plan
} from "@providers/memberPlansState/type";

export const isTrustPlan = (plan?: Plan): plan is TrustPlan =>
  plan?.type === PlanType.TRUST;

export const isLastWillPlan = (plan?: Plan): plan is LastWillPlan =>
  plan?.type === PlanType.LAST_WILL;

export const isPetProtectionPlan = (
  plan?: Plan
): plan is PetProtectionPlan =>
  plan?.type === PlanType.PET_PROTECTION;
