export const TRUST_SECTION_ROUTES = {
  Home: "",
  AllAboutYou: "all-about-you",
  Guardians: "guardians",
  Management: "management",
  Gifts: "remainder-gifts",
  Assets: "assets",
  FinalArrangements: "final-arrangements",
  DocumentPreview: "document-preview"
};
