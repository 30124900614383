// @mui
import { styled } from "@mui/material/styles";
import { ListItemIcon, ListItemButton } from "@mui/material";

import { StyledNavItemProps } from "./type";

export const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))<StyledNavItemProps>(({ theme }) => ({
  ...theme.typography.h3,
  height: "5rem",
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: "1.4rem",
  paddingRight: "1.4rem"
  /*
  TODO: Need to adjust this to handle the query selector (i.e., in case of multiple plans shared)
  "&.active": {
    fontWeight: "bold",
    transform: "scale(1.05)",
    transition: "all 0.2s ease",
    textDecoration: "underline"
  }
  */
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: "1.375rem",
  height: "1.375rem",
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2, 2.5)
}));
