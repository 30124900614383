import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const CreateAHCDStep: React.FC = () => {
  const { setValue: setAHCDValue } = useFormField(
    "users.0.healthcare"
  );

  const onSubmit = useCallback(
    ({
      includeAdvanceHealthCareDirective
    }: SingleSelectFormValues) => {
      if (!parseBoolean(includeAdvanceHealthCareDirective)) {
        setAHCDValue({});
      }
    },
    [setAHCDValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to set up an Advance Healthcare Directive?`,
        subText: `Learn more about Advance Health Care Directives`,
        moreInfoModalProps: {
          title: "What is an Advance Health Care Directive?",
          togglerLabel: "Learn more",
          content: (
            <p>
              You have the right to make decisions and give
              instructions about your own health care.
              <br />
              <br />
              An advance health care directive is the legal document
              that allows you to specify these preferences ahead of
              time to serve as a reference if you should ever become
              unable to communicate directly.
              <br />
              <br />
              In your Advance Health Care Directive, you can:
              <ul>
                <li>
                  Name a trusted person to make medical decisions on
                  your behalf
                </li>
                <li>
                  Authorize certain people to have access to your
                  medical information and communicate with your
                  doctors in an emergency
                </li>
                <li>State your preferences for end-of-life care</li>
                <li>
                  Express your wishes regarding the donation of organs
                </li>
              </ul>
              As long as you retain the ability to make and
              communicate decisions, you can change your mind or
              provide different instructions from those in your
              Advance Health Care Directive.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="includeAdvanceHealthCareDirective"
    />
  );
};

export default CreateAHCDStep;
