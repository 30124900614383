export const TRUST_GUARDIANS_ROUTES = {
  Preview: "",
  NominateGuardians: "nominate-guardians",
  NoChildrenNoGuardians: "no-child-guardians",
  PartnerAsGuardian: "designate-partner-guardian",
  DesignateGuardians: "designate-guardians",
  NominateBackupGuardians: "nominate-backup-guardians",
  PrimaryGuardiansOnly: "no-backup-guardian",
  DesignateBackupGuardians: "designate-backup-guardians",
  DesignateBackupGuardiansTest: "designate-backup-guardians-test",
  NominateAntiGuardian: "nominate-anti-guardian",
  DesignateAntiGuardian: "designate-anti-guardian",
  Review: "review",
  ReviewTabs: "review-tabs"
};
