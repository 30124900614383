import * as yup from "yup";
import { useCallback, useMemo } from "react";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import { RadioItem } from "@components/FormFields/FormRadioButtons/types";
import makeYupSchema from "@utils/makeYupSchema";

import {
  SingleSelectFormValues,
  UseSingleSelectArchetypeOptions,
  UseSingleSelectArchetypeReturnType
} from "./types";

const useSingleSelectSchema = (
  name: string,
  values: string[],
  nullable = false
): yup.AnyObjectSchema =>
  useMemo(() => {
    let basicShape: yup.AnySchema = yup.mixed();

    if (nullable) {
      basicShape = basicShape.nullable();
    } else {
      basicShape = basicShape.required();
    }

    return makeYupSchema(
      basicShape.oneOf([...values, ...(nullable ? [null] : [])]),
      name
    );
  }, [name, nullable, values]);

const useSingleSelectArchetype = (
  name: string,
  items: RadioItem[],
  onFormSubmit?: (data: SingleSelectFormValues) => void,
  { nullable }: UseSingleSelectArchetypeOptions = {}
): UseSingleSelectArchetypeReturnType => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useSingleSelectSchema(
    name,
    items.map((item) => item.value as string),
    nullable
  );
  const controls = useFormWithYup<SingleSelectFormValues>(schema);

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useSingleSelectArchetype };
