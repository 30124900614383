import React from "react";
import { Typography } from "@mui/material";
import { Tree, TreeNode } from "react-organizational-chart";
// import { FormattedMessage } from "react-intl";

import * as Styled from "./styles";
import { HierarchyPeopleProps, HierarchyPersonItem } from "./types";

const HierarchyNode: React.FC<HierarchyPersonItem> = ({
  name,
  subItems
}) => (
  <TreeNode
    // eslint-disable-next-line react/no-array-index-key

    label={
      <Styled.Card>
        <Typography>{name}</Typography>
      </Styled.Card>
    }
  >
    {subItems &&
      subItems.length > 0 &&
      subItems.map((subItem) => <HierarchyNode {...subItem} />)}
  </TreeNode>
);

const HierarchyPeople: React.FC<HierarchyPeopleProps> = ({
  items
}) => (
  <Tree label="o">
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <HierarchyNode key={`${item.name}_${index}`} {...item} />
    ))}
  </Tree>
);

export default HierarchyPeople;
