import { PropsWithChildren } from "react";
import { ApolloProvider } from "@apollo/client";

import client from "./client";

const QueryProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => <ApolloProvider {...{ client }}>{children}</ApolloProvider>;

export default QueryProvider;
