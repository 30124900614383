import { useIntl } from "react-intl";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const DocumentTypeStep: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Which of the following estate plan documents did the deceased person have?`,
        subText: `If you don't know, select "I don't know."`
      }}
      name="estateData.documentType"
      items={[
        {
          value: "LAST_WILL",
          label: formatMessage({
            defaultMessage: "Last Will"
          })
        },
        {
          value: "REVOCABLE_TRUST",
          label: formatMessage({
            defaultMessage: "Revocable Living Trust"
          })
        },
        {
          value: "IRREVOCABLE_TRUST",
          label: formatMessage({
            defaultMessage: "Irrevocable Living Trust"
          })
        },
        {
          value: "INTESTATE",
          label: formatMessage({
            defaultMessage:
              "The deceased did not create an estate plan"
          })
        },
        {
          value: "UNKNOWN",
          label: formatMessage({
            defaultMessage: "I don't know"
          })
        }
      ]}
    />
  );
};

export default DocumentTypeStep;
