import { useFormField } from "@components/FormFields/hooks";
import SingleResponseArchetype from "@components/archetypes/SingleResponse";

const PartnerSpecialNoteDetailsStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <SingleResponseArchetype
      fieldProps={{
        multiline: true,
        minRows: 5
      }}
      name="users.1.healthcare.preferences.additionalNote"
      label={`${partnerFirstName}'s Special Instructions...`}
      stepLayout={{
        title: `And what should ${partnerFirstName}'s note say?`
      }}
    />
  );
};

export default PartnerSpecialNoteDetailsStep;
