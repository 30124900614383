/* eslint-disable import/no-named-default */
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box } from "@mui/material";

import { default as lastWillSections } from "@pages/LastWill/routes";
import { default as trustSections } from "@pages/Trust/routes";
import { default as petSections } from "@pages/PetPlan/routes";
import { useSections } from "@providers/planForm/hooks";
import useCognitoAuth from "@hooks/useCognitoAuth";
import { ROUTES } from "@setup/consts/routes";
import { useLayoutContext } from "@pages/App/partials/LayoutProvider";
import { RoadmapItemProps } from "@components/Roadmap/partials/RoadmapItem/types";

import { NavigationItem, UseHeaderMenuReturnType } from "./types";
import { DropdownItem } from "./partials/Dropdown/types";

export const useHeaderMenu = (
  navItems: NavigationItem[],
  dashboardItems: NavigationItem[],
  user?: boolean
): UseHeaderMenuReturnType => {
  const intl = useIntl();
  const trustItems = useSections(trustSections);
  const lastWillItems = useSections(lastWillSections);
  const petItems = useSections(petSections);

  const { pathname } = useLocation();
  const { accessiblePlanPaths } = useLayoutContext();

  const isMenuVisible =
    pathname.includes(ROUTES.Trust) ||
    pathname.includes(ROUTES.LastWill) ||
    pathname.includes(ROUTES.PetPlan);

  const { logout } = useCognitoAuth();

  const mainMenuItems = useMemo(() => {
    const getMenuOutput = (path: string) => {
      if (path.includes(ROUTES.LastWill)) {
        return { parentPath: ROUTES.LastWill, items: lastWillItems };
      }
      if (path.includes(ROUTES.Trust)) {
        return { parentPath: ROUTES.Trust, items: trustItems };
      }
      if (path.includes(ROUTES.PetPlan)) {
        return { parentPath: ROUTES.PetPlan, items: petItems };
      }

      return { parentPath: ROUTES.EstatePlansMatchMaking, items: [] };
    };

    const menuOutput = getMenuOutput(pathname);

    const quizSections = ({
      parentPath,
      items
    }: {
      parentPath: string;
      items: RoadmapItemProps[] | undefined;
    }): DropdownItem[] | undefined =>
      items?.map(({ label, to }) => {
        const href = typeof to === "string" ? to : to.pathname;

        return {
          label,
          href: parentPath ? `${parentPath}/${href}` : href || "/",
          id: label,
          isDisabled: href ? !accessiblePlanPaths[href] : false
        };
      });

    return user
      ? [
          {
            label: intl.formatMessage({
              defaultMessage: "My Estate Plan"
            }),
            id: "1",
            items: quizSections(menuOutput)
          }
        ]
      : navItems;
  }, [
    accessiblePlanPaths,
    intl,
    trustItems,
    lastWillItems,
    petItems,
    navItems,
    pathname,
    user
  ]);

  const mobileMainMenuItems = useMemo(
    () =>
      user
        ? [
            ...(pathname.includes(ROUTES.LastWill)
              ? mainMenuItems
              : []),
            ...dashboardItems,
            {
              label: (
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    svg: {
                      position: "static",
                      transform: "translateY(0) rotate(0)",
                      fontSize: "1.25rem"
                    }
                  }}
                >
                  <FormattedMessage defaultMessage="Sign Out" />
                  <LogoutIcon
                    sx={{
                      marginLeft: "0.75rem"
                    }}
                  />
                </Box>
              ),
              id: "8",
              onClick: logout
            }
          ]
        : navItems,
    [dashboardItems, logout, mainMenuItems, navItems, pathname, user]
  );

  return {
    mainMenuItems,
    mobileMainMenuItems,
    isMenuVisible
  };
};
