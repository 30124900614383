import React from "react";

import { ImageProps } from "./types";
import * as Styled from "./styles";

const Image: React.FC<ImageProps> = ({
  src,
  srcSet,
  alt,
  ...rest
}) => (
  <Styled.Image
    srcSet={srcSet || `${src} 2x`}
    {...{ src, alt, ...rest }}
  />
);

export default Image;
