import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { useFormField } from "@components/FormFields/hooks";

const PainReliefExceptionListStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`, "");

  return (
    <SingleResponseArchetype
      name="users.0.healthcare.preferences.reliefFromPainCondition"
      stepLayout={{
        title: `Sure thing, ${firstName}.`,
        subText: `In your own words and in complete sentences, please state the conditions or situations in which you would not want treatment for alleviation of pain or discomfort.`
      }}
      label="Special Exceptions"
      fieldProps={{
        multiline: true,
        minRows: 5
      }}
    />
  );
};

export default PainReliefExceptionListStep;
