import {
  Divider,
  Box,
  useTheme,
  Typography,
  useMediaQuery
} from "@mui/material";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { FormattedMessage } from "react-intl";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { ROUTES } from "@setup/consts/routes";

import { getLinkFullUrl } from "../utils";

import CreatePlanCard from "./CreatePlanCard";
import {
  lastWillPlanObject,
  trustPlanObject,
  petPlanObject
} from "./CreatePlanCard/consts";

const PlanNotCompletedMessage = () => {
  const theme = useTheme();
  const { planStarted } = useMemberPlansState();

  const lwPath = planStarted.lastWillPath
    ? getLinkFullUrl([ROUTES.LastWill, planStarted.lastWillPath])
    : getLinkFullUrl([ROUTES.LastWillStart]);
  const petPath = planStarted.petProtectionPath
    ? getLinkFullUrl([ROUTES.PetPlan, planStarted.petProtectionPath])
    : getLinkFullUrl([ROUTES.PetStart]);
  const trustPath = planStarted.trustPath
    ? getLinkFullUrl([ROUTES.Trust, planStarted.trustPath])
    : getLinkFullUrl([ROUTES.TrustStart]);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        alignItems: "center"
      }}
    >
      <ScatterPlotIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Let's finish creating your estate plan." />
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "1.25rem"
        }}
      >
        {planStarted.trust && (
          <CreatePlanCard
            name={trustPlanObject.name}
            image={trustPlanObject.image}
            path={trustPath}
            isStarted
          />
        )}
        {planStarted.lastWill && (
          <CreatePlanCard
            name={lastWillPlanObject.name}
            image={lastWillPlanObject.image}
            path={lwPath}
            isStarted
          />
        )}
        {planStarted.petProtection && (
          <CreatePlanCard
            name={petPlanObject.name}
            image={petPlanObject.image}
            path={petPath}
            isStarted
          />
        )}
      </Box>
      {(!planStarted.lastWill ||
        !planStarted.petProtection ||
        !planStarted.trust) && (
        <Box
          sx={{
            mt: "1.25rem",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center"
          }}
        >
          <Divider
            sx={{
              width: "50%",
              color: theme.palette.grey["400"]
            }}
          >
            <FormattedMessage defaultMessage="or" />
          </Divider>
          <Typography>
            <FormattedMessage defaultMessage="Get started on a new plan" />
          </Typography>
          <Box
            sx={{
              mt: "1.25rem",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "1.25rem"
            }}
          >
            {!planStarted.trust && (
              <CreatePlanCard {...trustPlanObject} path={trustPath} />
            )}
            {!planStarted.lastWill && (
              <CreatePlanCard {...lastWillPlanObject} path={lwPath} />
            )}

            {!planStarted.petProtection && (
              <CreatePlanCard {...petPlanObject} path={petPath} />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PlanNotCompletedMessage;
