import React from "react";
import {
  House,
  AccountBalance,
  Interests,
  DirectionsCar,
  AutoAwesome,
  BusinessCenter,
  VolunteerActivism,
  Support
} from "@mui/icons-material";

import { AssetType } from "@api/__generated__/globalTypes";

export const ASSET_TYPE_ICONS: Partial<
  Record<AssetType, React.ReactNode>
> = {
  [AssetType.REAL_ESTATE]: <House />,
  [AssetType.FINANCIAL_ACCOUNT]: <AccountBalance />,
  [AssetType.BUSINESS_INTEREST]: <BusinessCenter />,
  [AssetType.OTHER]: <Interests />,
  [AssetType.CASH]: <VolunteerActivism />,
  [AssetType.ESTATE_BALANCE]: <AutoAwesome />,
  [AssetType.LIFE_INSURANCE_POLICY]: <Support />,
  [AssetType.PERSONAL_PROPERTY]: <DirectionsCar />,
  [AssetType.PERSONAL_PROPERTY_BALANCE]: <DirectionsCar />
};
