import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { PetPlanValues } from "@pages/PetPlan/types";
import { usePlanForm } from "@providers/planForm/context";

import { usePrimaryDrafterPetSpecialPowersReviewTab } from "./Reviews/usePrimaryDrafterPetSpecialPowersReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<PetPlanValues>();

  const items = usePrimaryDrafterPetSpecialPowersReviewTab({
    values
  });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Let's confirm that we got this right.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
