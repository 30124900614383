import { useContext } from "react";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { LastWillPlanValues } from "@pages/LastWill/types";
import {
  PlanFormContext,
  usePlanForm
} from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import { PartnerType } from "@api/__generated__/globalTypes";

import { usePrimaryDrafterAllAboutYouReviewTab } from "./Reviews/usePrimaryDrafterAllAboutYouReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { type } = useContext(PlanFormContext);

  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);

  const items = usePrimaryDrafterAllAboutYouReviewTab({
    values,
    partnerType,
    type
  });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Let's confirm that we got this right.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
