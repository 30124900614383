import { Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { ROUTES } from "@setup/consts/routes";
import { RETURN_URL_LOCAL_STORAGE_KEY } from "@setup/consts/misc";

import { InvalidRouteHandlerProps } from "./types";

const InvalidRouteHandler = ({ to }: InvalidRouteHandlerProps) => {
  const { pathname, search } = useLocation();
  const fullPath = `${pathname}${search}`;

  useEffect(() => {
    const foundRoute = Object.values(ROUTES)
      .filter((route) => route.length > 1)
      .find((route) => pathname.startsWith(route));

    if (foundRoute) {
      localStorage.setItem(RETURN_URL_LOCAL_STORAGE_KEY, fullPath);
    }
  }, [fullPath, pathname]);

  return <Navigate {...{ to }} />;
};

export default InvalidRouteHandler;
