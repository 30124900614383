import { useMemo } from "react";
import { useIntl } from "react-intl";
import { capitalize } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import { DEFAULT_DATE_FORMAT } from "@setup/consts/misc";
import formatDate from "@utils/formatDate";
import { LAST_WILL_ALL_ABOUT_YOU_ROUTES } from "@pages/LastWill/sections/AllAboutYou/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { ROUTES } from "@setup/consts/routes";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PartnerType } from "@api/__generated__/globalTypes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_ALL_ABOUT_YOU_ROUTES } from "@pages/Trust/sections/AllAboutYou/consts";

import { getLinkFullUrl } from "../utils";

export const useAllAboutYouReview = () => {
  const { formatMessage } = useIntl();
  const { trust, lastWill } = useMemberPlansState();
  const { primaryDrafterLastWill } = lastWill || {};

  const canEdit = !!trust?.canEdit || !!lastWill?.canEdit;

  const primaryDrafter = trust
    ? trust?.plan.primaryDrafter
    : lastWill?.plan.primaryDrafter;
  const partnerType = trust
    ? trust?.plan.partnerType
    : lastWill?.plan.partnerType;
  const partner = trust
    ? trust?.plan.partner
    : lastWill?.plan.partner;

  const childs = trust
    ? trust.primaryDrafterLastWill?.childs
    : primaryDrafterLastWill?.childs;

  const personalInfo = useMemo<ReviewItem | undefined>(
    () =>
      primaryDrafter
        ? {
            title: formatMessage({ defaultMessage: "Personal Info" }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.AllAboutYou,
                  TRUST_ALL_ABOUT_YOU_ROUTES.Basics
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.AllAboutYou,
                  LAST_WILL_ALL_ABOUT_YOU_ROUTES.Basics
                ]),
            items: [
              [
                `${primaryDrafter?.firstName} ${primaryDrafter?.lastName}`,
                `Born ${formatDate(
                  primaryDrafter?.birthdate,
                  DEFAULT_DATE_FORMAT
                )}`,
                `${primaryDrafter?.address?.county?.name}, ${primaryDrafter?.address?.state?.name}`
              ]
            ]
          }
        : undefined,
    [trust, canEdit, formatMessage, primaryDrafter]
  );

  const partnerInfo = useMemo<ReviewItem | undefined>(
    () =>
      // Using firstName vs. partner here b/c partner may be reset to empty object
      partner?.firstName && partnerType !== PartnerType.SINGLE
        ? {
            title: `Your ${capitalize(partnerType ?? "")}'s Info`,
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.AllAboutYou,
                  TRUST_ALL_ABOUT_YOU_ROUTES.PartnerDetails
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.AllAboutYou,
                  LAST_WILL_ALL_ABOUT_YOU_ROUTES.PartnerDetails
                ]),
            items: [
              [
                `${partner?.firstName} ${partner?.lastName}`,
                `Born ${formatDate(
                  partner?.birthdate,
                  DEFAULT_DATE_FORMAT
                )}`,
                `${partner?.address?.county?.name}, ${partner?.address?.state?.name}`
              ]
            ]
          }
        : undefined,
    [trust, partner, partnerType, canEdit]
  );

  const childrenInfo = useMemo<ReviewItem | undefined>(
    () =>
      childs?.length
        ? {
            title: formatMessage({
              defaultMessage: "Your Children's Info"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.AllAboutYou,
                  TRUST_ALL_ABOUT_YOU_ROUTES.ChildrenDetails
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.AllAboutYou,
                  LAST_WILL_ALL_ABOUT_YOU_ROUTES.ChildrenDetails
                ]),
            isFullWidth: true,
            items: childs.map((child) => [
              child?.user?.fullName ?? "",
              `Born ${formatDate(child?.user?.birthdate ?? "")}`,
              partnerType === PartnerType.SINGLE
                ? formatMessage(
                    {
                      defaultMessage: `Child of {fullName}`
                    },
                    {
                      fullName: primaryDrafter?.fullName ?? ""
                    }
                  )
                : formatMessage(
                    {
                      defaultMessage: `Child of {parents}`
                    },
                    {
                      parents: (
                        child?.parents?.map(
                          (parent) =>
                            parent?.fullName ?? parent?.firstName
                        ) ?? []
                      ).join(" and ")
                    }
                  )
            ])
          }
        : undefined,
    [
      trust,
      canEdit,
      childs,
      formatMessage,
      partnerType,
      primaryDrafter?.fullName
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [personalInfo, partnerInfo, childrenInfo].filter(
        (item): item is ReviewItem => Boolean(item)
      ),
    [partnerInfo, personalInfo, childrenInfo]
  );

  return items;
};
