import { useMemo } from "react";
import { useIntl } from "react-intl";

import { PermissionLevel } from "@api/__generated__/globalTypes";
import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePermissionLevelOptions = () => {
  const { formatMessage } = useIntl();

  return useMemo<AutocompleteBaseOption[]>(
    () => [
      {
        key: PermissionLevel.VIEW_ROLE,
        label: formatMessage({ defaultMessage: "View Role Only" }),
        value: PermissionLevel.VIEW_ROLE
      },
      {
        key: PermissionLevel.VIEW,
        label: formatMessage({ defaultMessage: "View Docs & Data" }),
        value: PermissionLevel.VIEW
      }
      /*
      {
        key: PermissionLevel.EDIT,
        label: formatMessage({ defaultMessage: "Edit" }),
        value: PermissionLevel.EDIT
      },
      {
        key: PermissionLevel.OWNER,
        label: formatMessage({ defaultMessage: "Account Owner" }),
        value: PermissionLevel.OWNER
      }
      */
    ],
    [formatMessage]
  );
};
