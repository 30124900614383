import { Pet } from "@pages/PetPlan/types";

const getCareInstructions = (
  pets: Pet[],
  sameCareInstructions: boolean | undefined
) => {
  if (sameCareInstructions || pets.length === 1) {
    return [
      [
        pets[0].feedingInstructions
          ? `Food: ${pets[0].feedingInstructions}`
          : `Food: No instructions provided`,
        pets[0].careInstructions
          ? `Exercise & Other Care: ${pets[0].careInstructions}`
          : `Exercise & Other Care: No instructions provided`,
        pets.length > 1
          ? `✅ Same Care Instructions for all my pets`
          : ""
      ]
    ].filter(Boolean);
  }

  return pets
    .map((pet) => [
      `${pet.name}:`,
      pet.feedingInstructions
        ? `Food: ${pet.feedingInstructions}`
        : "Food: No instructions provided",
      pet.careInstructions
        ? `Exercise & Other Care: ${pet.careInstructions}`
        : "Exercise & Other Care: No instructions provided"
    ])
    .filter(Boolean);
};

export default getCareInstructions;
