import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerSamePreferencesStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);
  const { value: firstName } = useFormField(`users.0.firstName`);
  const { value: primaryDrafterPreferences } = useFormField(
    "users.0.healthcare.preferences"
  );

  const { setValue: setHealthcarePreferencesValue } = useFormField(
    "users.1.healthcare.preferences"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const samePreferences = get(
        data,
        "users.1.healthcare.preferences.copy"
      );

      if (parseBoolean(samePreferences)) {
        setHealthcarePreferencesValue(primaryDrafterPreferences);
      }
    },
    [primaryDrafterPreferences, setHealthcarePreferencesValue]
  );

  return (
    <SingleSelectArchetype
      name="users.1.healthcare.preferences.copy"
      stepLayout={{
        title: `Does ${partnerFirstName} have the same preferences as ${firstName}?`
      }}
      {...{ onSubmit }}
    />
  );
};

export default PartnerSamePreferencesStep;
