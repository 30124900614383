import { useState, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import ControlledTabs from "@components/Tabs/ControlledTabs";
import HierarchyPeople from "@components/HierarchyPeople";
import { Trust_trustees as Trustee } from "@api/trusts/__generated__/Trust";
import { LastWill_representatives as Representative } from "@api/plans/__generated__/LastWill";
import { Healthcare_authorizedPerson as HealthAgent } from "@api/plans/__generated__/Healthcare";
import { PowerOfAttorney_agents as Agent } from "@api/plans/__generated__/PowerOfAttorney";
import { Child } from "@api/plans/__generated__/Child";

import { useAllKeyPeople } from "./KeyPeople/useAllKeyPeople";
import NetWorthTab from "./NetWorth";
import BeneficiariesTab from "./Beneficiaries";
import DistributionsTab from "./Distributions";
import useAssetGifts from "./hooks";
import { VisualTabProps } from "./types";

const IndividualVisualizations = ({
  userLastWill,
  userTrust,
  partnerLastWill,
  userFirstName,
  partnerFirstName,
  partnerType,
  isJointTrust = false,
  canEdit = false
}: VisualTabProps) => {
  const isTrust = !!userTrust;

  const trustees: Trustee[] =
    userTrust?.trustees?.filter(
      (trustee): trustee is Trustee => trustee !== null
    ) || [];

  const representatives: Representative[] =
    userLastWill?.representatives?.filter(
      (rep): rep is Representative => rep !== null
    ) || [];

  const healthAgents: HealthAgent[] =
    userLastWill?.healthcare?.authorizedPerson?.filter(
      (agent): agent is HealthAgent => agent !== null
    ) || [];

  const poaAgents: Agent[] =
    userLastWill?.powerOfAttorney?.agents?.filter(
      (agent): agent is Agent => agent !== null
    ) || [];

  const childs: Child[] =
    userLastWill?.childs?.filter(
      (child): child is Child => child !== null
    ) || [];

  const keyPeople = useAllKeyPeople(
    trustees,
    representatives,
    healthAgents,
    poaAgents,
    childs,
    partnerType,
    !!userLastWill?.partnerAsChildGuardian
  );

  const everythingToPartner =
    userLastWill?.percentGaveToPartner === 100;
  const { specificGifts, estateBalanceGifts } = useAssetGifts(
    userLastWill,
    isTrust
  );

  const partnerEverythingToPartner =
    partnerLastWill?.percentGaveToPartner === 100;
  const {
    specificGifts: partnerSpecificGifts,
    estateBalanceGifts: partnerEstateBalanceGifts
  } = useAssetGifts(partnerLastWill, isTrust);

  // Tab Info
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (_: number, newValue: number) => {
    setCurrentTab(newValue);
  };
  const items = useMemo(
    () => [
      {
        label: "Net Worth",
        content: (
          <NetWorthTab
            userLastWill={userLastWill}
            isTrustPlan={isTrust}
            canEdit={canEdit}
          />
        )
      },
      {
        label: "Distributions",
        content: (
          <DistributionsTab
            name={
              isJointTrust
                ? `${userFirstName} and ${partnerFirstName}`
                : userFirstName
            }
            settlorNames={[userFirstName, partnerFirstName]}
            isTrust={!!userTrust}
            specificGifts={specificGifts}
            estateBalanceGifts={estateBalanceGifts}
            partnerType={partnerType}
            everythingToPartner={everythingToPartner}
            isJointTrust={isJointTrust}
            partnerSpecificGifts={partnerSpecificGifts}
            partnerEstateBalanceGifts={partnerEstateBalanceGifts}
            partnerEverythingToPartner={partnerEverythingToPartner}
          />
        )
      },
      {
        label: "Beneficiaries",
        content: (
          <BeneficiariesTab
            specificGifts={specificGifts}
            estateBalanceGifts={estateBalanceGifts}
          />
        )
      },
      {
        label: "Key People",
        content: (
          <Box>
            <Box
              mb="1.25rem"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.625rem"
              }}
            >
              <InfoOutlinedIcon />
              <Typography>
                View how succession works for the key roles within
                your estate plan.
              </Typography>
            </Box>
            {keyPeople.map((role, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box mb="1.25rem" key={`${role}_${index}`}>
                <Typography
                  variant="h5"
                  style={{ textDecoration: "underline" }}
                >
                  {role.label}
                </Typography>
                <Typography>{role.description}</Typography>
                <HierarchyPeople items={role.items} />
              </Box>
            ))}
          </Box>
        )
      }
    ],
    [
      keyPeople,
      specificGifts,
      estateBalanceGifts,
      userFirstName,
      partnerFirstName,
      userTrust,
      canEdit,
      isTrust,
      userLastWill,
      isJointTrust,
      everythingToPartner,
      partnerType,
      partnerSpecificGifts,
      partnerEstateBalanceGifts,
      partnerEverythingToPartner
    ]
  );

  return (
    <ControlledTabs
      {...{ items }}
      currentTab={currentTab}
      handleChange={handleTabChange}
    />
  );
};

export default IndividualVisualizations;
