export const TRUST_GIFTS_ROUTES = {
  // PrimaryDrafter Starts
  Preview: "",

  GiftProcessOverview: "overview",

  // Overriding Gifts to Partner
  EverythingToPartner: "gifts-to-partner",
  EverythingToPartnerConfirmed: "gifts-to-partner-confirmed",

  TrustRemainderStartStep: "trust-remainder",
  TransitionPrimaryStart: "primary-start",

  // Remainder Section
  RemainderStrategy: "strategy",
  RemainderDistribType: "distrib-type",
  // Remainder Pooled Flow (if applicable)
  RemainderPooledOverview: "pooled-overview",
  RemainderPooledRestrictions: "pooled-restrictions",
  RemainderPooledFamilyIntersitital: "pooled-interstitial",
  RemainderPooledAgeThreshold: "pooled-age",
  RemainderPooledFinalDistribution: "pooled-final-distrib",
  SeparateTrustSetupFinalDistro: "pooled-separate-trust-setup",
  SeparateTrustAgeReqsFinalDistro: "pooled-separate-trust-age",

  RemainderAllocate: "allocate",
  // "Special" Children Separate Trust Loop Starts Here (if applicable)
  RemainderSeparateChildrenTrustLoop: "allocate/children-trusts",
  RemainderChildrenSeparateTrustSetup: "setup",
  RemainderChildrenSeparateTrustAgeReq: "age-reqs",
  RemainderChildrenBeneficiaryFails: "fails",
  RemainderChildrenSeparateTrustCopy: "copy",

  // "Regular" Remainder Allocation Loop Starts Here (if applicable)
  RemainderDistribTypeLooped: "allocate/distrib",
  RemainderSeparateTrustSetupLooped: "separate-trust-setup",
  RemainderSeparateTrustAgeReq: "separate-trust-age-req",
  RemainderSeparateTrustCopy: "separate-trust-copy",
  RemainderAllocateBeneficiariesLoop: "allocate/beneficiaries",
  RemainderAllocateBeneficiaryFails: "fails",
  RemainderAllocateBeneficiaryBackups: "backups",
  RemainderAllocateBeneficiaryCharity: "charity",

  RemainderComplete: "remainder-complete",

  // ExcludePersons
  ExcludePerson: "excluded-persons",
  DesignateExcludedPersons: "designate-excluded-persons",

  // IncludeNoContest
  IncludeNoContest: "no-contest",

  // Partner Starts Here
  PartnerTransitionStart: "partner-start",

  // Overriding Gifts to Partner
  PartnerEverythingToPartner: "partner-gifts-to-partner",
  PartnerEverythingToPartnerConfirmed:
    "partner-gifts-to-partner-confirmed",

  // Remainder Section
  PartnerRemainderStrategy: "partner-strategy",
  PartnerRemainderDistribType: "partner-distrib-type",
  // Remainder Pooled Flow (if applicable)
  PartnerRemainderPooledOverview: "partner-pooled-overview",
  PartnerRemainderPooledRestrictions: "partner-pooled-restrictions",
  PartnerRemainderPooledFamilyIntersitital:
    "partner-pooled-interstitial",
  PartnerRemainderPooledAgeThreshold: "partner-pooled-age",
  PartnerRemainderPooledFinalDistribution:
    "partner-pooled-final-distrib",

  PartnerSeparateTrustSetupFinalDistro:
    "partner-pooled-separate-trust-setup",
  PartnerSeparateTrustAgeReqsFinalDistro:
    "partner-pooled-separate-trust-age",

  PartnerRemainderAllocate: "partner-allocate",
  // "Special" Children Separate Trust Loop Starts Here (if applicable)
  PartnerRemainderSeparateChildrenTrustLoop:
    "partner-allocate/children-trusts",
  PartnerRemainderChildrenSeparateTrustSetup: "setup",
  PartnerRemainderChildrenSeparateTrustAgeReq: "age-reqs",
  PartnerRemainderChildrenBeneficiaryFails: "fails",
  PartnerRemainderChildrenSeparateTrustCopy: "copy",

  // "Regular" Remainder Allocation Loop Starts Here (if applicable)
  PartnerRemainderDistribTypeLooped: "partner-allocate/distrib",
  PartnerRemainderSeparateTrustSetupLooped: "separate-trust-setup",
  PartnerRemainderSeparateTrustAgeReq: "separate-trust-age-req",
  PartnerRemainderSeparateTrustCopy: "separate-trust-copy",
  PartnerRemainderAllocateBeneficiariesLoop:
    "partner-allocate/beneficiaries",
  PartnerRemainderAllocateBeneficiaryFails: "fails",
  PartnerRemainderAllocateBeneficiaryBackups: "backups",
  PartnerRemainderAllocateBeneficiaryCharity: "charity",

  PartnerRemainderComplete: "partner-remainder-complete",

  // ExcludePersons
  PartnerExcludePerson: "partner-excluded-persons",
  PartnerDesignateExcludedPersons:
    "partner-designate-excluded-persons",

  // IncludeNoContest
  PartnerIncludeNoContest: "partner-no-contest",

  // Review
  Review: "review",
  ReviewTabs: "review-tabs"
};
