/* eslint-disable react/no-array-index-key */
import { Box, Button, Grid, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { FileType } from "@api/__generated__/globalTypes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_ALL_ABOUT_YOU_ROUTES } from "@pages/Trust/sections/AllAboutYou/consts";
import { ROUTES } from "@setup/consts/routes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import TrustIcon from "@assets/images/docIcons/DocumentIconTrust.png";
import Image from "@components/Image";
import { File } from "@api/files/__generated__/File";

import { useSlideActions } from "../hooks";
import { getLinkFullUrl } from "../utils";

const JointTrustSlide = () => {
  const { trust } = useMemberPlansState();

  const primaryDrafter = trust?.plan?.primaryDrafter;

  const planFile = useMemo(
    () =>
      trust?.plan?.files?.find(
        (f: File) =>
          f.type === FileType.JOINT_TRUST_DOCUMENT &&
          f.ownerId === primaryDrafter.id
      ),
    [trust?.plan?.files, primaryDrafter]
  );

  const editLink = getLinkFullUrl([
    ROUTES.Trust,
    TRUST_SECTION_ROUTES.AllAboutYou,
    TRUST_ALL_ABOUT_YOU_ROUTES.Preview
  ]);

  const actions = useSlideActions({
    planFile,
    memberPlan: trust,
    editLink
  });
  const navigate = useNavigate();

  const handleGoToEdit = useCallback(() => {
    if (editLink) {
      navigate(editLink);
    }
  }, [editLink, navigate]);

  const renderTop = useCallback(
    () =>
      trust && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography align="center" variant="h5">
            <FormattedMessage
              defaultMessage="{name} Family"
              values={{
                name: primaryDrafter?.lastName ?? ""
              }}
            />{" "}
          </Typography>
          <Typography align="center" variant="h5">
            <FormattedMessage defaultMessage="Joint Trust" />
          </Typography>
          <Box sx={{ mt: "1rem" }}>
            <Image
              height="64px"
              width="64px"
              src={TrustIcon}
              alt="Trust icon"
            />
          </Box>
          <Typography mt="1.2rem" align="center" fontSize="1rem">
            <FormattedMessage defaultMessage="The core document in your plan. Designate who should receive the stuff you own and the rules governing those gifts." />
          </Typography>
        </Box>
      ),
    [trust, primaryDrafter.lastName]
  );

  if (!trust?.primaryDrafterTrust) {
    return (
      <>
        {renderTop()}
        <Typography mt="1.5rem" align="center" fontSize="15px">
          <FormattedMessage defaultMessage="You haven’t created your Joint Trust yet." />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleGoToEdit}
            variant="contained"
            sx={{
              flex: "1",
              borderWidth: "2px",
              fontWeight: "500",
              fontSize: "14px",
              marginTop: "1.1rem"
            }}
          >
            <FormattedMessage defaultMessage="CREATE MY TRUST" />
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      {renderTop()}
      <Grid
        container
        item
        xs={12}
        mt="1.5rem"
        justifyContent="space-around"
      >
        {actions.map(({ icon, label }, index) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer"
            }}
            key={`${label}_${index}`}
          >
            {icon}
            <Typography fontSize="0.875rem">{label}</Typography>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default JointTrustSlide;
