import Typography from "@mui/material/Typography";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";

const SharedPlansTitleSection = () => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h1"
        textAlign="center"
        margin={theme.spacing(2)}
      >
        <FormattedMessage defaultMessage="Shared Plans" />
      </Typography>
      <AllInclusiveIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          mb: "2rem",
          fontSize: "2.875rem"
        }}
      />
    </>
  );
};

export default SharedPlansTitleSection;
