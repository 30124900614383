import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { Beneficiary } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";
import { DistribType } from "@api/__generated__/globalTypes";

import BeneficiaryForm from "../RemainderAllocate/partials/BeneficiaryForm";
import AllocationMessage from "../RemainderAllocate/partials/AllocationMessage";
import useBeneficiarySchema from "../RemainderAllocate/partials/BeneficiaryForm/hooks";
import FormHeader from "../RemainderAllocate/partials/FormHeader";

const PartnerRemainderAllocateStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const singleItemSchema = useBeneficiarySchema();
  const { value } = useFormField<Beneficiary[]>(
    "users.1.assets.estateBalance.0.beneficiaries"
  );
  const { success } = useAllocationSum(value, {
    path: "percentageValue"
  });

  const { value: remainderDistribType } = useFormField(
    "users.1.gifts.remainderDistribType"
  );

  const { value: remainderStrategy } = useFormField(
    "users.1.gifts.remainderStrategy"
  );

  if (remainderDistribType === DistribType.SEPARATE_CHILDREN || remainderStrategy === "childrenOnly") {

    return (
      <ArrayFormArchetype
        stepLayout={{
          title: formatMessage({
            defaultMessage: `What percent of your Trust Remainder should each child receive?`
          })
        }}
        addBtnLabel="Add another beneficiary"
        {...{ singleItemSchema }}
        customRemoveBtn
        SingleItem={BeneficiaryForm}
        name="users.1.assets.estateBalance.0.beneficiaries"
        topContent={<FormHeader />}
        bottomContent={
          <AllocationMessage name="users.1.assets.estateBalance.0.beneficiaries" />
        }
        disabled={!success}
      />
    );
  }

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: formatMessage({
          defaultMessage: `Who should receive your Trust Remainder?`
        })
      }}
      addBtnLabel="Add another beneficiary"
      {...{ singleItemSchema }}
      customRemoveBtn
      SingleItem={BeneficiaryForm}
      name="users.1.assets.estateBalance.0.beneficiaries"
      topContent={<FormHeader />}
      bottomContent={
        <AllocationMessage name="users.1.assets.estateBalance.0.beneficiaries" />
      }
      disabled={!success}
    />
  );
};

export default PartnerRemainderAllocateStep;
