import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { SchemaOf } from "yup";

import { PASSWORD_REGEX } from "@setup/consts/misc";

import { ResetPasswordFormDataProps } from "./types";

export const useResetPasswordFormValidationSchema = (
  isCodeSent: boolean
): SchemaOf<ResetPasswordFormDataProps> => {
  const intl = useIntl();

  return useMemo(
    () =>
      yup
        .object()
        .shape({
          email: yup.string().email().required(),
          verificationCode: yup
            .string()
            .when("email", (email) =>
              email && isCodeSent
                ? yup.string().required()
                : yup.string()
            ),
          newPassword: yup.string().when("email", (email) =>
            email && isCodeSent
              ? yup
                  .string()
                  .min(
                    8,
                    intl.formatMessage({
                      defaultMessage:
                        "Password is too short. Please enter a password with a minimum of 8 characters."
                    })
                  )
                  .matches(
                    PASSWORD_REGEX,
                    intl.formatMessage({
                      defaultMessage:
                        "Invalid password. Your password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and can't contain any empty spaces."
                    })
                  )
                  .required()
              : yup.string()
          )
        })
        .required(),
    [intl, isCodeSent]
  );
};
