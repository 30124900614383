import { useIntl } from "react-intl";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import { useSharesDistribution } from "@hooks/useSharesDistribution";
import { DistributionType } from "@api/__generated__/globalTypes";
import { Beneficiary } from "@pages/LastWill/types";

const BeneficiaryDistribution: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: beneficiary } = useFormField<Beneficiary>(
    `users.0.assets.estateBalance.0.beneficiaries.${iteration}`
  );

  const { value: beneficiaries } = useFormField<Beneficiary[]>(
    `users.0.assets.estateBalance.0.beneficiaries`
  );

  const numBeneficiaries = beneficiaries.length;

  const items = useSharesDistribution(
    beneficiary.name,
    numBeneficiaries
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "If {name} fails to survive you, how do you want to distribute that {percentageValue}% share?"
          },
          {
            name: beneficiary.name,
            percentageValue: beneficiary.percentageValue
          }
        )
      }}
      name={`users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupDistribution`}
      {...{ items }}
      defaultValue={DistributionType.DESCENDANTS}
    />
  );
};

export default BeneficiaryDistribution;
