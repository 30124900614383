import React from "react";
import TextTruncate from "react-text-truncate";
import {
  Link,
  Box,
  Container,
  Typography,
  Button,
  useTheme
} from "@mui/material";

import Image from "@components/Image";

import { ResourceItemProps } from "./types";
import * as Styled from "./styles";

const ResourceItem: React.FC<ResourceItemProps> = ({ item }) => {
  const { title, thumbNail, path, description, readTime } = item;
  const { src, alt } = thumbNail;
  const theme = useTheme();

  return (
    <Link
      href={path}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      color="inherit"
    >
      <Container>
        <Box>
          <Image
            {...{ src, alt }}
            objectFit="cover"
            objectPosittion="center"
          />
        </Box>
        <Box mt="0.625rem">
          {readTime && (
            <Typography
              variant="caption"
              color={theme.palette.grey[500]}
            >
              {readTime} min read
            </Typography>
          )}
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box mt="1.25rem">
          <Typography variant="body1">
            <TextTruncate
              element="span"
              line={3}
              text={description}
              truncateText=" [...]"
            />
          </Typography>
        </Box>
        <Box mt="1.25rem">
          <Button>
            <Styled.ReadMoreButton>
              Read More {">>"}
            </Styled.ReadMoreButton>
          </Button>
        </Box>
      </Container>
    </Link>
  );
};

export default ResourceItem;
