import { string, object } from "yup";

import { AssetType } from "@api/__generated__/globalTypes";

export const businessInterestAssetSchema = {
  name: string().required()
};

export const personalPropertyAssetSchema = {
  name: string().required(),
  subType: string().required()
};

export const financialAccountAssetSchema = {
  name: string().required(),
  subType: string().required(),
  institution: string().required()
};

export const realEstateAssetSchema = {
  address: object().shape({
    address1: string().required(),
    address2: string().nullable(),
    zip: string().required(),
    city: string().required(),
    county: string().nullable(),
    state: string().required(),
    country: string().nullable()
  })
};

export const otherAssetSchema = {
  name: string().required(),
  subType: string().required(),
  address: object().shape({
    address1: string().nullable(),
    address2: string().nullable(),
    zip: string().nullable(),
    city: string().nullable(),
    county: string().nullable(),
    state: string().required(),
    country: string().nullable()
  })
};

export const lifeInsuranceSchema = {
  name: string().required(),
  institution: string().required()
};

export const assetSchemaByType = {
  [AssetType.BUSINESS_INTEREST]: businessInterestAssetSchema,
  [AssetType.PERSONAL_PROPERTY]: personalPropertyAssetSchema,
  [AssetType.FINANCIAL_ACCOUNT]: financialAccountAssetSchema,
  [AssetType.REAL_ESTATE]: realEstateAssetSchema,
  [AssetType.OTHER]: otherAssetSchema,
  [AssetType.LIFE_INSURANCE_POLICY]: lifeInsuranceSchema
};
