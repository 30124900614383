import { useState, ChangeEvent } from "react";
import Papa from "papaparse";

import {
  AssetCreateInput,
  AssetType
} from "@api/__generated__/globalTypes";

import { CsvRow } from "./types";

export const useCsvFileUpload = (
  onSubmit: (
    data: Omit<AssetCreateInput, "lastWillId">[]
  ) => Promise<{
    successImportAssets: (Omit<AssetCreateInput, "lastWillId">)[];
    failImportAssets: (Omit<AssetCreateInput, "lastWillId">)[];
  }>
) => {
  const [step, setStep] = useState(0);
  const [successImportAssets, setSuccessImportAssets] = useState<Omit<AssetCreateInput, "lastWillId">[]>([]);
  const [failImportAssets, setFailImportAssets] = useState<Omit<AssetCreateInput, "lastWillId">[]>([]);

  const createAssets = async (data: CsvRow[]): Promise<void> => {
    const csvAssets = data.map((row) => ({
      name: row["Asset Name"] as string,
      type: row["Asset Type"] as AssetType,
      subtype: (row["Sub Type"] as string) || undefined,
      institution:
        (row["Financial Institution"] as string) || undefined,
      grossValue:
        parseFloat(row["Estimated Gross Value"] as string) || 0,
      liabilityValue:
        parseFloat(row["Estimated Liability Value"] as string) || 0,
      isFunded:
        (row["Funded to Trust"] as unknown as boolean) || false,
      address: {
        address1: (row["Address 1"] as string) || undefined,
        address2: (row["Address 2"] as string) || undefined,
        city: (row.City as string) || undefined,
        state: (row.State as string) || undefined,
        zip: (row["Zip Code"] as string) || undefined,
        country: (row["Country"] as string) || undefined
      },
      beneficiaries: []
    }));

    const result = await onSubmit(csvAssets);
    
    if (result) {
      setSuccessImportAssets(result.successImportAssets);
      setFailImportAssets(result.failImportAssets);
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      Papa.parse<CsvRow>(file, {
        header: true,
        complete: (results) => {
          createAssets(results.data).then(() => {
            console.log(
              "Successfully completed data parsing and submission"
            );
            setStep(step + 1);
          });
        },
        skipEmptyLines: true
      });
    }
  };

  return {
    step,
    handleFileUpload,
    successImportAssets,
    failImportAssets
  };
};
