import { useIntl } from "react-intl";

import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";
import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import { Pet } from "@pages/PetPlan/types";

const DesignateBackupGuardianStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: pet } = useFormField<Pet>(`pets.${iteration}`);

  return (
    <PersonDetailsArrayArchetype
      name={`pets.${iteration}.backupGuardians`}
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "Who would you like to serve as backup Guardian for {name}?"
          },
          {
            name: pet.name
          }
        ),
        subText:
          "You can add additional backup Guardians by hitting the 'Add Another Backup Guardian' button."
      }}
      personRole="Backup Pet Guardian"
      addBtnLabel="Add Another Backup Pet Guardian"
      removeBtnLabel="Remove Backup Pet Guardian"
    />
  );
};

export default DesignateBackupGuardianStep;
