import { gql } from "@apollo/client";

export const AI_CREATE_THREAD = gql`
mutation AICreateThread(
  $content: String!
) {
  aiCreateThread(content: $content) {
    threadId
    message
  }
}
`;

export const AI_GENERATE_CHAT_RESPONSE = gql`
  mutation AIGenerateChatResponse(
    $threadId: String!
    $content: String!
  ) {
    aiGenerateChatResponse(threadId: $threadId, content: $content) {
      message
    }
  }
`;
