import { Membership } from "@api/plans/__generated__/Membership";
import { PermissionLevel } from "@api/__generated__/globalTypes";

export const EDIT_PERMISSION_LEVELS: PermissionLevel[] = [
  PermissionLevel.EDIT,
  PermissionLevel.OWNER
];

export const VIEW_DATA_PERMISSION_LEVELS: PermissionLevel[] = [
  PermissionLevel.VIEW,
  PermissionLevel.EDIT,
  PermissionLevel.OWNER
];

export const VIEW_PERMISSION_LEVELS: PermissionLevel[] = [
  PermissionLevel.VIEW_ROLE,
  PermissionLevel.VIEW,
  PermissionLevel.EDIT,
  PermissionLevel.OWNER
];

export const isOwner = (membership?: Membership | null) =>
  !!(
    membership && membership.permissionLevel === PermissionLevel.OWNER
  );

export const hasEditPermissions = (membership?: Membership | null) =>
  !!(
    membership &&
    EDIT_PERMISSION_LEVELS.includes(membership.permissionLevel)
  );

export const hasViewDataPermissions = (
  membership?: Membership | null
) =>
  !!(
    membership &&
    VIEW_DATA_PERMISSION_LEVELS.includes(membership.permissionLevel)
  );
