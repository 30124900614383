import { Link as ReactLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { StyledRoadmapItemProps } from "./types";

export const Link = styled(ReactLink)<StyledRoadmapItemProps>(
  ({ state, theme }) => ({
    textDecoration: "none",
    lineHeight: 1.6,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color:
      state === "next"
        ? theme.palette.grey[700]
        : theme.palette.text.primary,
    padding: state === "current" ? "0.5rem 0" : "0.35rem 0",
    fontWeight: state === "current" ? 500 : 400,
    borderWidth: state === "current" ? "1px 0" : 0,
    borderColor:
      state === "current"
        ? `transparent transparent ${theme.palette.divider}`
        : "transparent",
    borderStyle: "solid",
    pointerEvents: state === "next" ? "none" : "auto",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        color: theme.palette.text.secondary
      }
    }
  })
);

export const Content = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    color: "currentColor",
    fontSize: "1.5rem",
    marginRight: "0.375rem",
    flexShrink: 0,
    marginBottom: "0.125rem"
  }
}));

export const DoneIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: "1.5rem",
  flexShrink: 0,
  marginLeft: "1rem"
}));
