import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const useBankOptions = (): AutocompleteBaseOption[] => {
  const { formatMessage } = useIntl();

  // Top 200 US Banks by Asset Value + Top 25 Mortgage Brokers, as of Nov 2022

  return useMemo(
    () =>
      [
        formatMessage({ defaultMessage: "1st Source Bank" }),
        formatMessage({ defaultMessage: "Ally Bank" }),
        formatMessage({ defaultMessage: "Ally Invest" }),
        formatMessage({ defaultMessage: "Amalgamated Bank" }),
        formatMessage({ defaultMessage: "Amarillo National Bank" }),
        formatMessage({ defaultMessage: "Amerant Bank" }),
        formatMessage({
          defaultMessage: "American Express National Bank"
        }),
        formatMessage({
          defaultMessage: "American Savings Bank, FSB"
        }),
        formatMessage({ defaultMessage: "AMERIPRISE BANK, FSB" }),
        formatMessage({ defaultMessage: "Ameris Bank" }),
        formatMessage({ defaultMessage: "Apple Bank for Savings" }),
        formatMessage({ defaultMessage: "Arvest Bank" }),
        formatMessage({ defaultMessage: "Associated Bank" }),
        formatMessage({ defaultMessage: "Atlantic Union Bank" }),
        formatMessage({ defaultMessage: "Axos Bank" }),
        formatMessage({ defaultMessage: "Banc of California" }),
        formatMessage({ defaultMessage: "BancFirst" }),
        formatMessage({
          defaultMessage: "Banco Popular de Puerto Rico"
        }),
        formatMessage({ defaultMessage: "Bank Hapoalim B.M." }),
        formatMessage({ defaultMessage: "Bank of America" }),
        formatMessage({
          defaultMessage: "Bank of America California"
        }),
        formatMessage({ defaultMessage: "Bank of Baroda" }),
        formatMessage({ defaultMessage: "Bank of China" }),
        formatMessage({ defaultMessage: "Bank of Hawaii" }),
        formatMessage({ defaultMessage: "Bank of Hope" }),
        formatMessage({ defaultMessage: "Bank of the West" }),
        formatMessage({ defaultMessage: "Bank OZK" }),
        formatMessage({ defaultMessage: "BankUnited" }),
        formatMessage({ defaultMessage: "Banner Bank" }),
        formatMessage({ defaultMessage: "Barclays Bank Delaware" }),
        formatMessage({ defaultMessage: "Beal Bank USA" }),
        formatMessage({ defaultMessage: "Bell Bank" }),
        formatMessage({ defaultMessage: "Berkshire Bank" }),
        formatMessage({ defaultMessage: "BMO Harris Bank" }),
        formatMessage({
          defaultMessage: "BMW Bank of North America"
        }),
        formatMessage({ defaultMessage: "BNY Mellon" }),
        formatMessage({ defaultMessage: "BOKF" }),
        formatMessage({ defaultMessage: "Bremer Bank" }),
        formatMessage({ defaultMessage: "Busey Bank" }),
        formatMessage({ defaultMessage: "Cadence Bank" }),
        formatMessage({ defaultMessage: "Capital One" }),
        formatMessage({ defaultMessage: "Capital One Bank (USA)" }),
        formatMessage({
          defaultMessage: "Capitol Federal Savings Bank"
        }),
        formatMessage({ defaultMessage: "Cathay Bank" }),
        formatMessage({ defaultMessage: "Centennial Bank" }),
        formatMessage({ defaultMessage: "Charles Schwab" }),
        formatMessage({
          defaultMessage: "Charles Schwab Bank, SSB"
        }),
        formatMessage({
          defaultMessage: "Charles Schwab Premier Bank, SSB"
        }),
        formatMessage({
          defaultMessage: "Charles Schwab Trust Bank"
        }),
        formatMessage({ defaultMessage: "CIBC Bank USA" }),
        formatMessage({ defaultMessage: "Citi" }),
        formatMessage({ defaultMessage: "Citibank" }),
        formatMessage({ defaultMessage: "Citizens Bank" }),
        formatMessage({ defaultMessage: "Citizens Business Bank" }),
        formatMessage({ defaultMessage: "City National Bank" }),
        formatMessage({
          defaultMessage: "City National Bank of Florida"
        }),
        formatMessage({ defaultMessage: "Columbia Bank" }),
        formatMessage({ defaultMessage: "Columbia State Bank" }),
        formatMessage({ defaultMessage: "Comenity Bank" }),
        formatMessage({ defaultMessage: "Comenity Capital Bank" }),
        formatMessage({ defaultMessage: "Comerica Bank" }),
        formatMessage({ defaultMessage: "Commerce Bank" }),
        formatMessage({ defaultMessage: "Community Bank" }),
        formatMessage({ defaultMessage: "ConnectOne Bank" }),
        formatMessage({ defaultMessage: "Cross River Bank" }),
        formatMessage({ defaultMessage: "Customers Bank" }),
        formatMessage({
          defaultMessage: "Deutsche Bank Trust Company Americas"
        }),
        formatMessage({ defaultMessage: "Dime Community Bank" }),
        formatMessage({ defaultMessage: "Discover Bank" }),
        formatMessage({
          defaultMessage: "Dollar Bank, Federal Savings Bank"
        }),
        formatMessage({ defaultMessage: "EagleBank" }),
        formatMessage({ defaultMessage: "East West Bank" }),
        formatMessage({ defaultMessage: "Eastern Bank" }),
        formatMessage({
          defaultMessage: "Enterprise Bank & Trust"
        }),
        formatMessage({ defaultMessage: "Etrade" }),
        formatMessage({
          defaultMessage: "Farmers and Merchants Bank of Long Beach"
        }),
        formatMessage({ defaultMessage: "Fidelity" }),
        formatMessage({ defaultMessage: "Fifth Third Bank" }),
        formatMessage({ defaultMessage: "First Bank" }),
        formatMessage({
          defaultMessage: "First Commonwealth Bank"
        }),
        formatMessage({ defaultMessage: "First Financial Bank" }),
        formatMessage({ defaultMessage: "First Foundation Bank" }),
        formatMessage({ defaultMessage: "First Hawaiian Bank" }),
        formatMessage({ defaultMessage: "First Horizon Bank" }),
        formatMessage({ defaultMessage: "First Interstate Bank" }),
        formatMessage({ defaultMessage: "First Merchants Bank" }),
        formatMessage({
          defaultMessage: "First National Bank of Omaha"
        }),
        formatMessage({
          defaultMessage: "First National Bank of Pennsylvania"
        }),
        formatMessage({ defaultMessage: "First Republic Bank" }),
        formatMessage({ defaultMessage: "First Security Bank" }),
        formatMessage({
          defaultMessage: "First United Bank and Trust Company"
        }),
        formatMessage({
          defaultMessage: "First-Citizens Bank & Trust Company"
        }),
        formatMessage({ defaultMessage: "FirstBank" }),
        formatMessage({ defaultMessage: "FirstBank Puerto Rico" }),
        formatMessage({ defaultMessage: "Firstrade" }),
        formatMessage({ defaultMessage: "Flagstar Bank, FSB" }),
        formatMessage({ defaultMessage: "Flushing Bank" }),
        formatMessage({ defaultMessage: "Frost Bank" }),
        formatMessage({ defaultMessage: "Fulton Bank" }),
        formatMessage({ defaultMessage: "Glacier Bank" }),
        formatMessage({ defaultMessage: "Goldman Sachs Bank USA" }),
        formatMessage({ defaultMessage: "Hancock Whitney Bank" }),
        formatMessage({ defaultMessage: "HomeStreet Bank" }),
        formatMessage({ defaultMessage: "Horizon Bank" }),
        formatMessage({ defaultMessage: "HSBC Bank USA" }),
        formatMessage({ defaultMessage: "Independent Bank" }),
        formatMessage({ defaultMessage: "Interactive Brokers" }),
        formatMessage({
          defaultMessage: "International Bank of Commerce"
        }),
        formatMessage({
          defaultMessage: "Israel Discount Bank of New York"
        }),
        formatMessage({ defaultMessage: "JP Morgan" }),
        formatMessage({ defaultMessage: "JPMorgan Chase Bank" }),
        formatMessage({ defaultMessage: "Kearny Bank" }),
        formatMessage({ defaultMessage: "KeyBank" }),
        formatMessage({
          defaultMessage: "Lake Forest Bank & Trust Company"
        }),
        formatMessage({ defaultMessage: "Lakeland Bank" }),
        formatMessage({ defaultMessage: "Liberty Bank" }),
        formatMessage({
          defaultMessage: "Live Oak Banking Company"
        }),
        formatMessage({ defaultMessage: "Luther Burbank Savings" }),
        formatMessage({ defaultMessage: "M1 Finance" }),
        formatMessage({
          defaultMessage: "Manufacturers and Traders Trust Company"
        }),
        formatMessage({ defaultMessage: "Mechanics Bank" }),
        formatMessage({
          defaultMessage: "Merchants Bank of Indiana"
        }),
        formatMessage({ defaultMessage: "Merrill Edge" }),
        formatMessage({ defaultMessage: "MidFirst Bank" }),
        formatMessage({ defaultMessage: "Midland States Bank" }),
        formatMessage({ defaultMessage: "Moomoo" }),
        formatMessage({ defaultMessage: "Morgan Stanley Bank" }),
        formatMessage({
          defaultMessage: "Morgan Stanley Private Bank"
        }),
        formatMessage({ defaultMessage: "MUFG Union Bank" }),
        formatMessage({ defaultMessage: "NBT Bank" }),
        formatMessage({
          defaultMessage: "New York Community Bank"
        }),
        formatMessage({ defaultMessage: "NexBank" }),
        formatMessage({ defaultMessage: "Nicolet National Bank" }),
        formatMessage({ defaultMessage: "Northwest Bank" }),
        formatMessage({ defaultMessage: "OceanFirst Bank" }),
        formatMessage({ defaultMessage: "Old National Bank" }),
        formatMessage({ defaultMessage: "Optum Bank, Inc." }),
        formatMessage({ defaultMessage: "Oriental Bank" }),
        formatMessage({ defaultMessage: "Origin Bank" }),
        formatMessage({ defaultMessage: "Pacific Premier Bank" }),
        formatMessage({ defaultMessage: "Pacific Western Bank" }),
        formatMessage({ defaultMessage: "Pinnacle Bank" }),
        formatMessage({ defaultMessage: "PlainsCapital Bank" }),
        formatMessage({ defaultMessage: "PNC Bank" }),
        formatMessage({ defaultMessage: "Popular Bank" }),
        formatMessage({ defaultMessage: "Premier Bank" }),
        formatMessage({ defaultMessage: "Principal Bank" }),
        formatMessage({ defaultMessage: "Prosperity Bank" }),
        formatMessage({ defaultMessage: "Provident Bank" }),
        formatMessage({ defaultMessage: "Public" }),
        formatMessage({ defaultMessage: "Raymond James Bank" }),
        formatMessage({ defaultMessage: "Regions Bank" }),
        formatMessage({ defaultMessage: "Renasant Bank" }),
        formatMessage({ defaultMessage: "Robinhood" }),
        formatMessage({ defaultMessage: "Rockland Trust Company" }),
        formatMessage({ defaultMessage: "S&T Bank" }),
        formatMessage({
          defaultMessage: "Safra National Bank of New York"
        }),
        formatMessage({ defaultMessage: "Sallie Mae Bank" }),
        formatMessage({ defaultMessage: "Sandy Spring Bank" }),
        formatMessage({ defaultMessage: "Santander Bank, N.A." }),
        formatMessage({ defaultMessage: "Seacoast National Bank" }),
        formatMessage({ defaultMessage: "ServisFirst Bank" }),
        formatMessage({ defaultMessage: "Signature Bank" }),
        formatMessage({ defaultMessage: "Silicon Valley Bank" }),
        formatMessage({ defaultMessage: "Silvergate Bank" }),
        formatMessage({ defaultMessage: "Simmons Bank" }),
        formatMessage({ defaultMessage: "Sofi Invest" }),
        formatMessage({ defaultMessage: "Sogotrade" }),
        formatMessage({ defaultMessage: "Southside Bank" }),
        formatMessage({ defaultMessage: "SouthState Bank" }),
        formatMessage({ defaultMessage: "SpeedTrader" }),
        formatMessage({ defaultMessage: "Stash Invest" }),
        formatMessage({ defaultMessage: "State Bank of India" }),
        formatMessage({
          defaultMessage: "State Street Bank and Trust Company"
        }),
        formatMessage({ defaultMessage: "Stifel Bank and Trust" }),
        formatMessage({
          defaultMessage: "Stock Yards Bank & Trust Company"
        }),
        formatMessage({ defaultMessage: "Synchrony Bank" }),
        formatMessage({ defaultMessage: "Synovus Bank" }),
        formatMessage({ defaultMessage: "TastyWorks" }),
        formatMessage({ defaultMessage: "TD Ameritrade" }),
        formatMessage({ defaultMessage: "TD Bank" }),
        formatMessage({ defaultMessage: "TD Bank USA" }),
        formatMessage({ defaultMessage: "Texas Capital Bank" }),
        formatMessage({
          defaultMessage: "The Bank of New York Mellon"
        }),
        formatMessage({ defaultMessage: "The Central Trust Bank" }),
        formatMessage({
          defaultMessage: "The Huntington National Bank"
        }),
        formatMessage({
          defaultMessage: "The Northern Trust Company"
        }),
        formatMessage({ defaultMessage: "The Park National Bank" }),
        formatMessage({
          defaultMessage:
            "Third Federal Savings and Loan Association of Cleveland"
        }),
        formatMessage({ defaultMessage: "TIAA" }),
        formatMessage({ defaultMessage: "TIAA, FSB" }),
        formatMessage({
          defaultMessage: "Tompkins Community Bank"
        }),
        formatMessage({ defaultMessage: "Towne Bank" }),
        formatMessage({ defaultMessage: "TradeStation" }),
        formatMessage({ defaultMessage: "Tradezero" }),
        formatMessage({ defaultMessage: "Tradier" }),
        formatMessage({ defaultMessage: "Tri Counties Bank" }),
        formatMessage({ defaultMessage: "Tristate Capital Bank" }),
        formatMessage({ defaultMessage: "Truist Bank" }),
        formatMessage({
          defaultMessage: "Trustmark National Bank"
        }),
        formatMessage({ defaultMessage: "U.S. Bank" }),
        formatMessage({ defaultMessage: "UBS Bank USA" }),
        formatMessage({ defaultMessage: "UMB Bank" }),
        formatMessage({ defaultMessage: "Umpqua Bank" }),
        formatMessage({ defaultMessage: "United Bank" }),
        formatMessage({ defaultMessage: "United Community Bank" }),
        formatMessage({
          defaultMessage: "USAA Federal Savings Bank"
        }),
        formatMessage({ defaultMessage: "Valley National Bank" }),
        formatMessage({ defaultMessage: "Vanguard" }),
        formatMessage({ defaultMessage: "Veritex Community Bank" }),
        formatMessage({
          defaultMessage: "Washington Federal Bank"
        }),
        formatMessage({ defaultMessage: "Washington Trust Bank" }),
        formatMessage({ defaultMessage: "Webster Bank" }),
        formatMessage({ defaultMessage: "Webull" }),
        formatMessage({ defaultMessage: "Wells Fargo Bank" }),
        formatMessage({
          defaultMessage: "Wells Fargo Bank South Central"
        }),
        formatMessage({
          defaultMessage: "Wells Fargo National Bank West"
        }),
        formatMessage({ defaultMessage: "WellsTrade" }),
        formatMessage({ defaultMessage: "WesBanco Bank, Inc." }),
        formatMessage({ defaultMessage: "Western Alliance Bank" }),
        formatMessage({
          defaultMessage: "Wilmington Savings Fund Society, FSB"
        }),
        formatMessage({ defaultMessage: "Wintrust Bank" }),
        formatMessage({
          defaultMessage: "Woodforest National Bank"
        }),
        formatMessage({ defaultMessage: "ZacksTrade" }),
        formatMessage({
          defaultMessage: "Zions Bancorporation, N.A."
        })
      ].map((type) => ({
        key: type,
        value: type,
        label: type
      })),
    [formatMessage]
  );
};
