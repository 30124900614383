import { useMemo } from "react";

import { LastWill } from "@api/plans/__generated__/LastWill";
import { AssetType } from "@api/__generated__/globalTypes";

import { useAssets } from "../AssetsTab/utils/useAssets";

const useAssetGifts = (
  userLastWill: LastWill | undefined,
  isTrust: boolean
) => {
  const userAssets = userLastWill?.assets;

  const { assetsThroughLastWill } = useAssets({
    assets: userAssets ?? [],
    excludeAssets: [
      AssetType.CASH,
      AssetType.PERSONAL_PROPERTY_BALANCE,
      AssetType.ESTATE_BALANCE
    ]
  });

  return useMemo(() => {
    const specificGifts = isTrust
      ? userAssets?.filter(
          (asset) =>
            asset &&
            asset.type &&
            ![AssetType.ESTATE_BALANCE].includes(asset.type) &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
        )
      : assetsThroughLastWill?.filter(
          (asset) =>
            asset &&
            asset.type &&
            ![AssetType.ESTATE_BALANCE].includes(asset.type) &&
            asset.beneficiaries &&
            asset.beneficiaries.length > 0
        );

    const estateBalanceGifts = userAssets?.filter(
      (asset) => asset && asset.type === AssetType.ESTATE_BALANCE
    );

    return { specificGifts, estateBalanceGifts };
  }, [isTrust, userAssets, assetsThroughLastWill]);
};

export default useAssetGifts;
