import { useCallback } from "react";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, FormProvider } from "react-hook-form";

import { PermissionLevel } from "@api/__generated__/globalTypes";
import { useUpdateMembership } from "@api/memberships";
import BasicFormSelect from "@components/FormFields/FormSelect/BasicFormSelect";

import { usePermissionLevelOptions } from "../../hooks";

import {
  ChangePermissionFormValues,
  ChangePermissionProps
} from "./types";

const ChangePermission: React.FC<ChangePermissionProps> = ({
  permissionLevel = PermissionLevel.VIEW,
  id,
  userId,
  plan
}) => {
  const permissionLevelOptions = usePermissionLevelOptions();

  const [updateMembership] = useUpdateMembership();
  const disableReAssigning =
    [plan.partner?.id, plan.primaryDrafter?.id].includes(userId) &&
    permissionLevel === PermissionLevel.OWNER;

  const onSubmit = useCallback(
    async (data: ChangePermissionFormValues) => {
      await updateMembership({
        variables: {
          id,
          input: {
            permissionLevel:
              data.permissionLevel || PermissionLevel.VIEW_ROLE
          }
        }
      });
    },
    [id, updateMembership]
  );

  const methods = useForm<ChangePermissionFormValues>({
    defaultValues: {
      permissionLevel: permissionLevel || PermissionLevel.VIEW_ROLE
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <BasicFormSelect
          onChange={() => methods.handleSubmit(onSubmit)()}
          sx={{
            ":before": {
              display: "none"
            },
            ":after": {
              display: "none"
            }
          }}
          name="permissionLevel"
          IconComponent={ExpandMoreIcon}
          items={permissionLevelOptions.map((option) => ({
            ...option,
            disabled: disableReAssigning
          }))}
        />
      </form>
    </FormProvider>
  );
};

export default ChangePermission;
