import { UseFormReturn } from "react-hook-form";

import { ArchetypeProps } from "../types";

export type UsePetPhysicalTraitsArchetypeReturnTypes = {
  controls: UseFormReturn<PetPhysicalTraitsArchetypeValue>;
  goBack: VoidFunction;
  onSubmit: (
    e?: React.BaseSyntheticEvent<object> | undefined
  ) => Promise<void>;
};

export type PetPhysicalTraitsArchetypeProps =
  ArchetypeProps<PetPhysicalTraitsArchetypeValue> & {
    breed: BreedTypes;
  };

export type PetPhysicalTraitsArchetypeValue = {
  breed: string;
  weight: number;
  characteristics: string;
};

export enum BreedTypes {
  DOG = "dog",
  CAT = "cat",
  FISH = "fish",
  HORSE = "horse",
  REPTILE = "reptile",
  BIRD = "bird",
  OTHER = "other"
}
