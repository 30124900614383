import { useCallback } from "react";
import { lowerCase } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import {
  Beneficiary,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import { parseBoolean } from "@utils/parseBoolean";
import { PartnerType } from "@api/__generated__/globalTypes";

const ResidueToPartnerStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");
  const {
    value: residuePercentRemaining,
    setValue: setResiduePercentRemaining
  } = useFormField<number>("residuePercentRemaining");

  const { value: giveSpecificGifts } = useFormField(
    "users.0.gifts.giveSpecificGifts"
  );

  const {
    value: residuePercentAllocated,
    setValue: setResiduePercentAllocated
  } = useFormField<number>("residuePercentAllocated");

  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [, partner]
  } = values;

  const { value: residueGift, setValue: setResidueGift } =
    useFormField<Beneficiary[]>(
      "users.0.assets.estateBalance.0.beneficiaries"
    );

  const onSubmit = useCallback(
    ({ residueBalanceToPartner }: SingleSelectFormValues) => {
      if (parseBoolean(residueBalanceToPartner)) {
        setResidueGift([
          {
            name: partner.firstName && " " && partner.lastName,
            percentageValue: residuePercentRemaining
          }
        ]);
        setResiduePercentAllocated(
          residuePercentAllocated + residuePercentRemaining
        );
        setResiduePercentRemaining(0);
      }

      const allocatedPercentForPartner =
        residueGift?.find(
          (gift) =>
            gift.name === `${partner.firstName} ${partner.lastName}`
        )?.percentageValue ?? 0;

      if (!parseBoolean(residueBalanceToPartner)) {
        setResidueGift([]);
        setResiduePercentRemaining(
          residuePercentRemaining + allocatedPercentForPartner
        );
        setResiduePercentAllocated(
          residuePercentAllocated - allocatedPercentForPartner
        );
      }
    },
    [
      partner.firstName,
      partner.lastName,
      residueGift,
      residuePercentAllocated,
      residuePercentRemaining,
      setResidueGift,
      setResiduePercentAllocated,
      setResiduePercentRemaining
    ]
  );

  const lowerCasePartnerType = lowerCase(partnerType);

  if (giveSpecificGifts) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `After completing your specific gifts, do you want the balance of your assets to go to your ${lowerCasePartnerType}?`
        }}
        {...{ onSubmit }}
        name="residueBalanceToPartner"
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want the balance of your assets to go to your ${lowerCasePartnerType}?`
      }}
      {...{ onSubmit }}
      name="residueBalanceToPartner"
    />
  );
};

export default ResidueToPartnerStep;
