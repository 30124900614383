import React from "react";

import UploadOverview from "./UploadOverview";
import { useCsvFileUpload } from "./hooks";
import UploadComplete from "./UploadComplete";
import { CSVUploadProps } from "./types";

const CsvUploader: React.FC<CSVUploadProps> = ({ onSubmit }) => {
  const { successImportAssets, failImportAssets, step, handleFileUpload } =
    useCsvFileUpload(onSubmit);

  return step === 1 ? (
    <UploadComplete
      handleFileUpload={handleFileUpload}
      importedAssets={successImportAssets}
      notImportedAssets={failImportAssets}
    />
  ) : (
    <UploadOverview handleFileUpload={handleFileUpload} />
  );
};

export default CsvUploader;
