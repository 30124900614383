/* eslint-disable no-irregular-whitespace */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePetBreedsCats = (): AutocompleteBaseOption[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({ defaultMessage: `Abyssinian` }),
        value: "Abyssinian"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Bobtail`
        }),
        value: "American Bobtail"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Curl`
        }),
        value: "American Curl"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Shorthair`
        }),
        value: "American Shorthair"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Wirehair`
        }),
        value: "American Wirehair"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Balinese` }),
        value: "Balinese"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Birman` }),
        value: "Birman"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Bombay` }),
        value: "Bombay"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `British Shorthair`
        }),
        value: "British Shorthair"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Burmese` }),
        value: "Burmese"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Chartreux` }),
        value: "Chartreux"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Color point shorthair`
        }),
        value: "Color point shorthair"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Cornish Rex` }),
        value: "Cornish Rex"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Devon Rex` }),
        value: "Devon Rex"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Egyptian Mau` }),
        value: "Egyptian Mau"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `European Burmese`
        }),
        value: "European Burmese"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Exotic` }),
        value: "Exotic"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Havana Brown` }),
        value: "Havana Brown"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Japanese Bobtail`
        }),
        value: "Japanese Bobtail"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Javanese` }),
        value: "Javanese"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Korat` }),
        value: "Korat"
      },
      {
        label: intl.formatMessage({ defaultMessage: `LaPerm` }),
        value: "LaPerm"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Maine Coon` }),
        value: "Maine Coon"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Manx` }),
        value: "Manx"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Norwegian Forest Cat`
        }),
        value: "Norwegian Forest Cat"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Ocicat` }),
        value: "Ocicat"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Oriental` }),
        value: "Oriental"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Persian` }),
        value: "Persian"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Ragamuffin` }),
        value: "Ragamuffin"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Ragdoll` }),
        value: "Ragdoll"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Russian Blue` }),
        value: "Russian Blue"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Scottish Fold`
        }),
        value: "Scottish Fold"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Selkirk Rex` }),
        value: "Selkirk Rex"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Siamese` }),
        value: "Siamese"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Siberian` }),
        value: "Siberian"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Singapura` }),
        value: "Singapura"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Somali` }),
        value: "Somali"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Sphynx` }),
        value: "Sphynx"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Tonkinese` }),
        value: "Tonkinese"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Turkish Angora`
        }),
        value: "Turkish Angora"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Turkish Van` }),
        value: "Turkish Van"
      }
    ],
    [intl]
  );

  return items;
};
