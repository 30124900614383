import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { PetPlanValues } from "@pages/PetPlan/types";
import { DEFAULT_DATE_FORMAT } from "@setup/consts/misc";
import formatDate from "@utils/formatDate";
import { ROUTES } from "@setup/consts/routes";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ITERATION_QUERY_KEY } from "@providers/planForm/consts";

import { PET_ALL_ABOUT_YOU_ROUTES } from "../../consts";

export const usePrimaryDrafterAllAboutYouReviewTab = ({
  values
}: {
  values: PetPlanValues;
}) => {
  const { formatMessage } = useIntl();

  const {
    users: [primaryDrafter],
    pets
  } = values;

  const iterationQueryKey = ITERATION_QUERY_KEY;
  const physicalTraitsPath = getLinkFullUrl([
    ROUTES.PetPlan,
    PET_SECTION_ROUTES.AllAboutYou,
    PET_ALL_ABOUT_YOU_ROUTES.PhysicalTraitsLoop,
    PET_ALL_ABOUT_YOU_ROUTES.PhysicalTraits,
    `?${iterationQueryKey}=1`
  ]);

  const personalInfo = useMemo<ReviewItem | undefined>(
    () =>
      primaryDrafter
        ? {
            title: formatMessage({ defaultMessage: "Personal Info" }),
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.AllAboutYou,
              PET_ALL_ABOUT_YOU_ROUTES.Basics
            ]),
            items: [
              [
                `${primaryDrafter?.firstName} ${primaryDrafter?.lastName}`,
                `Born ${formatDate(
                  primaryDrafter?.birthdate,
                  DEFAULT_DATE_FORMAT
                )}`,
                `${primaryDrafter?.address?.county}, ${primaryDrafter?.address?.state}`
              ]
            ]
          }
        : undefined,
    [formatMessage, primaryDrafter]
  );

  // TODO : Refactor so that each pet has its own section, made up of a nested petsInfo and petCharacteristicsInfo
  const petsInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Pet Info"
            }),
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.AllAboutYou,
              PET_ALL_ABOUT_YOU_ROUTES.PetDetails
            ]),
            isFullWidth: true,
            items: pets.map((pet) => [
              pet.name,
              pet.type,
              `Born ${formatDate(pet.birthdate)}`
            ])
          }
        : undefined,
    [pets, formatMessage]
  );

  const petCharacteristicsInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Identifying Physical Characteristics"
            }),
            href: physicalTraitsPath,
            isFullWidth: true,
            items: pets.map((pet) => [
              `${pet.name} is a ${[
                pet.weight ? `${pet.weight} lb` : ``,
                pet.breed ? pet.breed : ``
              ].join(" ")}`,
              pet.characteristics ? `"${pet.characteristics}"` : ``
            ])
          }
        : undefined,
    [pets, physicalTraitsPath, formatMessage]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [personalInfo, petsInfo, petCharacteristicsInfo].filter(
        (item): item is ReviewItem => Boolean(item)
      ),
    [petCharacteristicsInfo, petsInfo, personalInfo]
  );

  return items;
};
