import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { differenceInYears } from "date-fns";

const NominateGuardianStep: React.FC = () => {
  const { setValue: setGuardiansValue } = useFormField("guardians");

  const { setValue: setBackupGuardiansValue } =
    useFormField("backupGuardians");
  const { setValue: setNominateBackupGuardiansValue } = useFormField(
    "nominateBackupGuardians"
  );

  const onSubmit = useCallback(
    ({ nominateGuardians }: SingleSelectFormValues) => {
      if (!parseBoolean(nominateGuardians)) {
        setGuardiansValue([]);
        setBackupGuardiansValue([]);
        setNominateBackupGuardiansValue(false);
      }
    },
    [
      setGuardiansValue,
      setBackupGuardiansValue,
      setNominateBackupGuardiansValue
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: "Do you want to nominate guardians for your children?",
        subText:
          "Most people who have children under the age of 18 or children with special needs nominate guardians to look after their children in case something tragic happens."
      }}
      {...{ onSubmit }}
      name="nominateGuardians"
    />
  );
};

export default NominateGuardianStep;
