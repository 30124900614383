import { useIntl } from "react-intl";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";

import TutorialLayout from "@pages/Tutorial/partials/TutorialLayout";
import { TUTORIAL_ROUTES } from "@pages/Tutorial/consts";
import { ROUTES } from "@setup/consts/routes";

const AnswersPage: React.FC = () => {
  const intl = useIntl();

  return (
    <TutorialLayout
      heading={intl.formatMessage({
        defaultMessage: "Answers are saved as you go"
      })}
      description={intl.formatMessage({
        defaultMessage:
          "Your answers are saved at each question, so if your celebrity crush knocks on your door unexpectedly, you can always pick up where you left off."
      })}
      title={intl.formatMessage(
        {
          defaultMessage:
            "tutorial ({currentStep} of {allStepsCount})"
        },
        {
          allStepsCount: 5,
          currentStep: 2
        }
      )}
      progress={40}
      withSkipButton
      nextButtonProps={{
        href: `${ROUTES.Tutorial}/${TUTORIAL_ROUTES.Timing}`,
        label: intl.formatMessage({
          defaultMessage: "Continue"
        })
      }}
    >
      <DynamicFormIcon
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
    </TutorialLayout>
  );
};

export default AnswersPage;
