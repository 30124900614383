import { Stack, FormControl } from "@mui/material";
import { useIntl } from "react-intl";

import useToggle from "@hooks/useToggle";
import BasicFormAddressAutocomplete from "@components/FormFields/FormAddressAutocomplete/BasicFormAddressAutocomplete";
import BasicCurrencyFormInput from "@components/FormFields/FormInput/BasicCurrencyFormInput";

import SpecificGift from "./SpecificGift";
import FundedToTrust from "./FundedToTrust";

const RealEstateForm = ({ showIsFunded = false }) => {
  const [isModalOpened, { toggleOff }] = useToggle();
  const { formatMessage } = useIntl();

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <BasicCurrencyFormInput
          name="grossValue"
          label={formatMessage({
            defaultMessage: "Approximate Value ($USD)"
          })}
          InputProps={{
            startAdornment: `$`
          }}
        />
        <BasicFormAddressAutocomplete
          name="address"
          modalProps={{
            isOpen: isModalOpened,
            onClose: toggleOff,
            onProceed: (): void => {
              toggleOff();
            }
          }}
        />
        {showIsFunded && <FundedToTrust />}
        <SpecificGift />
      </FormControl>
    </Stack>
  );
};

export default RealEstateForm;
