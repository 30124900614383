/* eslint-disable react/no-unescaped-entities */
import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import differenceInYears from "date-fns/differenceInYears";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import FormInput from "@components/FormFields/FormInput";
import FormMobileDatePicker from "@components/FormFields/FormMobileDatePicker";
import { Children } from "@pages/LastWill/types";
import FormRadioButtons from "@components/FormFields/FormRadioButtons";
import { useFormField } from "@components/FormFields/hooks";
import { PartnerType } from "@api/__generated__/globalTypes";
import { ParentValues } from "@hooks/useTrustEnums";

type ChildrenFormProps = {
  prefix: string;
  index: number;
};

const ChildrenForm: React.FC<ChildrenFormProps> = ({
  prefix,
  index
}) => {
  const { formatMessage } = useIntl();
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);
  const { value: partnerType } = useFormField(`partnerType`);
  /* 
  If single, assume all children are of the primaryDrafter.
  If partner, confirm which children belong to whom.
  */

  if (partnerType === PartnerType.SINGLE) {
    return (
      <>
        <FormInput
          name={`${prefix}.name`}
          label={formatMessage(
            { defaultMessage: "Child #{number}’s Full Name" },
            { number: index + 1 }
          )}
        />
        <FormMobileDatePicker
          name={`${prefix}.birthdate`}
          label={formatMessage(
            { defaultMessage: "Child #{number}’s Birthday" },
            { number: index + 1 }
          )}
        />
      </>
    );
  }

  return (
    <>
      <FormInput
        name={`${prefix}.name`}
        label={formatMessage(
          { defaultMessage: "Child #{number}’s Full Name" },
          { number: index + 1 }
        )}
      />
      <FormMobileDatePicker
        name={`${prefix}.birthdate`}
        label={formatMessage(
          { defaultMessage: "Child #{number}’s Birthday" },
          { number: index + 1 }
        )}
      />

      <FormRadioButtons
        name={`${prefix}.parentNames`}
        items={[
          {
            label: `Child of ${partnerFirstName} and Me`,
            value: ParentValues.PrimaryAndPartner
          },
          {
            label: "Child of Someone Else and Me",
            value: ParentValues.PrimaryOnly
          },
          {
            label: `Child of ${partnerFirstName} and Someone Else`,
            value: ParentValues.PartnerOnly
          }
        ]}
      />
    </>
  );
};

const ChildrenDetailsStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const singleFormSchema: yup.SchemaOf<
    Omit<
      Children,
      | "parentNames"
      | "guardians"
      | "backupGuardians"
      | "prohibitedGuardians"
    >
  > = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(),
        birthdate: yup
          .date()
          .typeError(
            formatMessage({
              defaultMessage:
                "Birthdates should be no later than one year from today."
            })
          )
          .test(
            "birthdate",
            formatMessage({
              defaultMessage:
                "Children birthdates should be no later than one year from today."
            }),
            (value) =>
              value
                ? differenceInYears(new Date(value), new Date()) <= 1
                : false
          )
          .required()
      }),
    [formatMessage]
  );

  const partnerFormSchema: yup.SchemaOf<
    Omit<
      Children,
      "guardians" | "backupGuardians" | "prohibitedGuardians"
    >
  > = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(),
        birthdate: yup
          .date()
          .typeError(
            formatMessage({
              defaultMessage:
                "Birthdates should be no later than one year from today."
            })
          )
          .test(
            "birthdate",
            formatMessage({
              defaultMessage:
                "Children birthdates should be no later than one year from today."
            }),
            (value) =>
              value
                ? differenceInYears(new Date(value), new Date()) <= 1
                : false
          )
          .required(),
        parentNames: yup.string().required(
          formatMessage({
            defaultMessage:
              "Please choose one of the options above identifying the parents for this child."
          })
        )
      }),
    [formatMessage]
  );

  if (partnerType === PartnerType.SINGLE) {
    return (
      <ArrayFormArchetype
        stepLayout={{
          title: `Congrats! What are your children's names and birthdays?`
        }}
        addBtnLabel="Add Another Child"
        singleItemSchema={singleFormSchema}
        SingleItem={ChildrenForm}
        name="children"
      />
    );
  }

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `Congrats! What are your children's names and birthdays?`,
        subText: "Learn more about why we collect this information.",
        moreInfoModalProps: {
          title:
            "Why Record Your Children's Birthdates and Names of Parents",
          togglerLabel: "Learn more.",
          content: (
            <p>
              <strong>Recording Birthdates.</strong>
              <br />
              Knowing your children's age can help your Executor
              ensure that any gifts you may make to your children are
              distributed consistent with state law. Additionally,
              some people make gifts to children subject to certain
              age-based requirements.
              <br />
              <br />
              <strong>Identifying Parents for Your Children.</strong>
              <br />
              Parents as used here are those individuals who have or
              who share legal custody of the children. Later, we'll
              ask if you want to nominate Guardians for your children.
              You can only nominate Guardians for children of whom you
              have legal custody.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      addBtnLabel="Add Another Child"
      singleItemSchema={partnerFormSchema}
      SingleItem={ChildrenForm}
      name="children"
    />
  );
};

export default ChildrenDetailsStep;
