import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import AuthLayout from "@components/AuthLayout";
import useCognitoAuth from "@hooks/useCognitoAuth";
import { ROUTES } from "@setup/consts/routes";
import { ReactComponent as Sygnet } from "@assets/svg/sygnet.svg";

import { useResetPasswordFormValidationSchema } from "./hooks";
import { ResetPasswordFormDataProps } from "./types";

const ResetPassword: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const validationSchema =
    useResetPasswordFormValidationSchema(isCodeSent);
  const { forgotPasswordSendVerificationCode, forgotPasswordSubmit } =
    useCognitoAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    clearErrors
  } = useForm<ResetPasswordFormDataProps>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      verificationCode: "",
      newPassword: ""
    }
  });

  const [errorMessage, setErrorMessage] = useState<string>();

  const sendVerificationCode = async ({
    email
  }: ResetPasswordFormDataProps): Promise<void> => {
    try {
      const verificationCodeSent =
        await forgotPasswordSendVerificationCode(email);
      const isVerificationCodeSent = !!verificationCodeSent;

      setIsCodeSent(isVerificationCodeSent);
      if (isVerificationCodeSent) {
        clearErrors();
        setErrorMessage(undefined);
      }
    } catch (error) {
      const formError = error as Error;

      setErrorMessage(formError.message);
    }
  };

  const changePassword = async ({
    email,
    verificationCode,
    newPassword
  }: ResetPasswordFormDataProps): Promise<void> => {
    try {
      const isSubmitted = await forgotPasswordSubmit(
        email,
        verificationCode,
        newPassword
      );

      if (isSubmitted) {
        navigate(ROUTES.SignIn);
      }
    } catch (error) {
      const formError = error as Error;

      setErrorMessage(formError.message);
    }
  };

  return (
    <AuthLayout
      title={intl.formatMessage({
        defaultMessage: "Reset password"
      })}
      isLoading={isSubmitting}
    >
      <Box
        component="form"
        autoComplete="off"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
        onSubmit={handleSubmit(
          isCodeSent ? changePassword : sendVerificationCode
        )}
      >
        <FormControl
          error={!!errorMessage}
          sx={{ display: "flex", width: "100%" }}
        >
          {isCodeSent ? (
            <>
              <Typography mb={5}>
                <FormattedMessage
                  defaultMessage="Enter the six digit code that you received in your
                email to reset your password."
                />
              </Typography>
              <TextField
                label={
                  <FormattedMessage defaultMessage="Verification Code" />
                }
                variant="standard"
                error={!!errors.verificationCode}
                helperText={errors.verificationCode?.message}
                {...register("verificationCode", {
                  required: true
                })}
                autoComplete="off"
                sx={{
                  width: "100%",
                  mb: "1.25rem"
                }}
              />
              <TextField
                label={
                  <FormattedMessage defaultMessage="New Password" />
                }
                variant="standard"
                type="password"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
                {...register("newPassword", {
                  required: true
                })}
                autoComplete="new-password"
                sx={{
                  width: "100%",
                  mb: "1.25rem"
                }}
              />
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "& svg": {
                    fontSize: "2.875rem"
                  }
                }}
                mb={6.25}
              >
                <Sygnet />
              </Box>
              <Typography mb={5}>
                <FormattedMessage
                  defaultMessage="Enter the email address that you use to sign in to
                JIC Estates."
                />
              </Typography>
              <TextField
                label={
                  <FormattedMessage defaultMessage="Email Address" />
                }
                variant="standard"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email", { required: true })}
                autoComplete="email"
                sx={{
                  width: "100%",
                  mb: "1.25rem"
                }}
              />
            </>
          )}
          {errorMessage && (
            <FormHelperText sx={{ ml: 0, mb: 2.5 }}>
              {errorMessage}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          variant="contained"
          size="medium"
          type="submit"
          fullWidth
        >
          {isCodeSent ? (
            <FormattedMessage defaultMessage="Reset password" />
          ) : (
            <FormattedMessage defaultMessage="Send verification code" />
          )}
        </Button>
        <Button
          href={ROUTES.SignIn}
          sx={{ mt: 2.5 }}
          color="inherit"
          fullWidth
        >
          {isCodeSent ? (
            <FormattedMessage defaultMessage="Cancel" />
          ) : (
            <>
              {"<- "}
              <FormattedMessage defaultMessage="Back to login screen" />
            </>
          )}
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default ResetPassword;
