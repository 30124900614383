import { PropsWithChildren, useMemo, useState } from "react";

import { DisableFormContext } from "./context";
import { DisableFormItems, DisableFormState } from "./type";

export const { Provider: DisableFormProvider } = DisableFormContext;

export const DisableFormContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [items, setItems] = useState<DisableFormItems>({});

  const isFormDisabled = useMemo<boolean>(
    () => Object.values(items).some(Boolean),
    [items]
  );

  const disableFormContextValue = useMemo<DisableFormState>(
    () => ({
      items,
      isDisabled: isFormDisabled,
      setDisableItems: setItems
    }),
    [isFormDisabled, items]
  );

  return (
    <DisableFormProvider value={disableFormContextValue}>
      {children}
    </DisableFormProvider>
  );
};
