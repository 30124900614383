import StateSelectArchetype from "@components/archetypes/StateSelect";

const HHStateStep: React.FC = () => (
  <StateSelectArchetype
    stepLayout={{
      title: `In which state do you live?`
    }}
    name="householdData.state"
  />
);

export default HHStateStep;
