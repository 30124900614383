import {
  FormControl,
  Stack,
  FormGroup,
  Typography,
  Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import FormPhoneInput from "@components/FormFields/FormPhoneInput";
import FormMobileDatePicker from "@components/FormFields/FormMobileDatePicker";
import FormAddressAutocomplete from "@components/FormFields/FormAddressAutocomplete";
import FormInput from "@components/FormFields/FormInput";

import ResidenceInfoModal from "./partials/ResidenceInfoModal";
import { UserDetailsFormProps } from "./types";

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  name,
  isAddressWarningDisplayed,
  onProceed,
  onCancel
}) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Stack spacing={4}>
      <FormControl
        component="fieldset"
        variant="standard"
        sx={{ display: "flex", mb: 4 }}
      >
        <FormGroup>
          <Stack spacing={4}>
            <FormInput
              name={`${name}.firstName`}
              label={intl.formatMessage({
                defaultMessage: "First Name"
              })}
            />
            <FormInput
              name={`${name}.lastName`}
              label={intl.formatMessage({
                defaultMessage: "Last Name"
              })}
            />
            <FormMobileDatePicker
              minDate={new Date("1900-01-01")}
              name={`${name}.birthdate`}
              label={intl.formatMessage({
                defaultMessage: "Date of Birth"
              })}
              type="dateOnly"
              DialogProps={{
                sx: {
                  "& .MuiButton-root": {
                    minWidth: "auto",
                    [theme.breakpoints.up("md")]: {
                      minWidth: "13.438rem"
                    }
                  }
                }
              }}
            />
            <FormPhoneInput
              name={`${name}.phone`}
              label={intl.formatMessage({
                defaultMessage: "Phone Number"
              })}
            />
          </Stack>
        </FormGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
        sx={{ display: "flex" }}
      >
        <Box mb={2.5}>
          <Typography
            component="legend"
            variant="body2"
            fontWeight={600}
            p={0}
          >
            <FormattedMessage defaultMessage="Primary Residence Info" />
          </Typography>
          <ResidenceInfoModal />
        </Box>
        <FormGroup>
          <Stack spacing={4}>
            <FormAddressAutocomplete
              name={`${name}.address`}
              modalProps={{
                isOpen: isAddressWarningDisplayed,
                onClose: onCancel,
                onProceed
              }}
            />
          </Stack>
        </FormGroup>
      </FormControl>
    </Stack>
  );
};

export default memo(UserDetailsForm);
