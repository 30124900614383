import { styled } from "@mui/material/styles";
import { ButtonHTMLAttributes } from "react";

import { StyledBurgerProps } from "./types";

export const Burger = styled(
  ({
    open,
    ...restProps
  }: StyledBurgerProps & ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button type="button" {...restProps} />
  )
)<StyledBurgerProps>(({ open, theme }) => ({
  position: "relative",
  top: 0,
  right: "0rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  width: "2.5rem",
  height: "1.5rem",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  padding: 0,
  zIndex: 10,
  "&:focus": {
    outline: "none"
  },
  div: {
    width: "1.5rem",
    height: "0.125rem",
    background: theme.palette.divider,
    transition: "all 0.3s linear",
    position: "relative",
    transformOrigin: "1px",
    ":first-of-type": {
      transform: open ? "rotate(45deg)" : "rotate(0)"
    },
    ":nth-of-type(2)": {
      opacity: open ? "0" : "1",
      transform: open ? "translateX(20px)" : "translateX(0)"
    },
    ":nth-of-type(3)": {
      transform: open ? "rotate(-45deg)" : "rotate(0)"
    }
  }
}));
