import { FormattedMessage } from "react-intl";
import { Container, Typography, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";

import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ROUTES } from "@setup/consts/routes";

const ErrorStep: React.FC = () => {
  const handleClick = () => {
    window.location.href = "https://jicestates.com";
  };

  return (
    <>
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        <FormattedMessage defaultMessage="Sorry, looks like we sent you the wrong way. Let us guide you back!" />
      </Typography>
      <Box maxWidth="29.375rem" width="100%" m="0 auto">
        <Grid
          container
          spacing={2.5}
          mt={{ xs: 3.75, md: 2.5 }}
          sx={{
            "> .MuiGrid-root": {
              paddingTop: 0
            }
          }}
        >
          <Grid item xs={12} md={6} paddingBottom="1.5rem">
            <Button
              color="inherit"
              variant="outlined"
              onClick={handleClick}
              sx={{
                width: "100%",
                minWidth: "auto"
              }}
            >
              <FormattedMessage defaultMessage="Back to Home" />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                minWidth: "auto"
              }}
              href={getLinkFullUrl([ROUTES.EstateAdminQuiz])}
            >
              <FormattedMessage defaultMessage="Re-Start Quiz" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ErrorStep;
