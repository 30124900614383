import { gql } from "@apollo/client";

export const FILE_FRAGMENT = gql`
  fragment File on File {
    id
    type
    name
    path
    ownerId
  }
`;
