import { EstateData } from "@pages/EstateAdminQuiz/types";

import isRightsOrContest from "../../utils";

import AdministrationResults from "./AdministrationResults";
import EstateChallengeResults from "./EstateChallengeResults";

const useLegalResults: React.FC = (estateData: EstateData) => {
  const {
    assetValue,
    probateAssetValue,
    documentType,
    focusAreas,
    state
  } = estateData;

  return isRightsOrContest(focusAreas) ? (
    <EstateChallengeResults assetValue={assetValue} />
  ) : (
    <AdministrationResults
      assetValue={assetValue}
      probateAssetValue={probateAssetValue}
      documentType={documentType}
      state={state}
    />
  );
};

export default useLegalResults;
