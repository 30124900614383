import { v4 } from "uuid";

import { Person } from "@api/planDrafts/__generated__/Person";
import {
  GOOGLE_MAPS_LANGUAGE,
  PLACES_RESTRICTIONS_COUNTRY
} from "@setup/consts/misc";

import { PersonValues } from "./types";

const getPersonData = (person: Person) => ({
  id: person.id || v4(),
  name: person.name || "",
  relationship: person.relationship || "",
  address: {
    __typename: "PersonAddress",
    address1: person.address?.address1 || "",
    city: person.address?.city || "",
    county: person.address?.county || "",
    country: person.address?.country || "",
    state: person.address?.state || "",
    stateCode: person.address?.stateCode || "",
    zip: person.address?.zip || "",
    ...(person.address?.address2
      ? { address2: person.address.address2 }
      : {})
  },
  __typename: "Person"
});

const verifyAddress = (input: string): Promise<boolean> =>
  new Promise((resolve) => {
    const service =
      new window.google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: input || "",
        language: GOOGLE_MAPS_LANGUAGE,
        /*
        componentRestrictions: {
          country: PLACES_RESTRICTIONS_COUNTRY
        },
        */
        types: ["address"]
      },
      (result, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return resolve(false);
        }

        if (!result?.length) {
          return resolve(false);
        }

        if (result[0].description !== input) {
          return resolve(false);
        }

        return resolve(true);
      }
    );
  });

const buildFormAddress = (personData: PersonValues) =>
  `${personData.address.address1}, ${personData.address.city}, ${personData.address.stateCode} ${personData.address.zip}, ${personData.address.country}`;

export { getPersonData, verifyAddress, buildFormAddress };
