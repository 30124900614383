import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { numberWithCommas } from "@utils/misc";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { PET_SPECIAL_POWERS_ROUTES } from "@pages/PetPlan/sections/SpecialPowers/consts";
import { ROUTES } from "@setup/consts/routes";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";

import { getLinkFullUrl } from "../utils";

export const usePetSpecialPowersReview = () => {
  const { formatMessage } = useIntl();
  const { petProtection: petProtectionPlan } = useMemberPlansState();
  const { petProtection, canEdit = false } = petProtectionPlan || {};

  const {
    pets,
    allowGuardianIntoTransactions = false,
    guardianTransactionsMaxAmount,
    areAsideFundsSet = false,
    asideFundsAmount,
    grantGuardianAccessToHealthInformation
  } = petProtection ?? {};

  const emergencyFinanceInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Emergency Finance Authorization:"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.SpecialPowers,
              PET_SPECIAL_POWERS_ROUTES.AllowFinanceAccess
            ]),
            isFullWidth: true,
            items: [
              [
                allowGuardianIntoTransactions
                  ? `✅ You are authorizing your Pet Guardian emergency access to your finances in an amount up to $${numberWithCommas(
                      guardianTransactionsMaxAmount
                    )}.`
                  : "🚫 Your Pet Guardian will not be able to draw on your finances in the event of a pet emergency."
              ]
            ]
          }
        : undefined,
    [
      pets,
      canEdit,
      allowGuardianIntoTransactions,
      guardianTransactionsMaxAmount,
      formatMessage
    ]
  );

  const emergencyHealthInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Emergency Health Info Authorization:"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.SpecialPowers,
              PET_SPECIAL_POWERS_ROUTES.AllowHealthAccess
            ]),
            isFullWidth: true,
            items: [
              [
                grantGuardianAccessToHealthInformation
                  ? `✅ You are authorizing your Pet Guardian to receive limited access to your health information for purposes of determining whether you are incapable of caring for your pets.`
                  : "🚫 You are not providing your Pet Guardian with limited access to your health information."
              ]
            ]
          }
        : undefined,
    [
      pets,
      canEdit,
      grantGuardianAccessToHealthInformation,
      formatMessage
    ]
  );

  const setAsideFundsInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Pet Financial Support:"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.SpecialPowers,
              PET_SPECIAL_POWERS_ROUTES.SetAsideFunds
            ]),
            isFullWidth: true,
            items: [
              [
                areAsideFundsSet
                  ? `✅ You are providing your Pet Guardian with up to $${numberWithCommas(
                      asideFundsAmount
                    )} (per pet) to support your pets' comfort and care in the event of your death or permanent inability to care for your pets.`
                  : "🚫 You are not providing financial support to your Pet Guardian for the continued comfort and care of your pets. In the event of your death or permanent inability to care for your pets, your Pet Guardian will rely only on his or her own finances."
              ]
            ]
          }
        : undefined,
    [pets, canEdit, areAsideFundsSet, asideFundsAmount, formatMessage]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        emergencyFinanceInfo,
        setAsideFundsInfo,
        emergencyHealthInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [emergencyFinanceInfo, emergencyHealthInfo, setAsideFundsInfo]
  );

  return items;
};
