import { useCallback } from "react";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";

import { FormSelectProps, FormSelectValueType } from "./types";

const BasicFormSelect: React.FC<FormSelectProps> = ({
  items,
  name,
  label,
  onChange: onChangeProp,
  ...restProps
}) => {
  const defaultValue = get(restProps, "defaultValue", "");

  const {
    control,
    watch,
    setValue: setRHFValue,
    formState: { errors }
  } = useFormContext();

  const value = watch(name);

  const fieldError = get(errors, `${name}.message`);
  const labelId = `${name}-label`;
  const onChange = useCallback(
    (event: SelectChangeEvent<FormSelectValueType>) => {
      const { value: inputValue } = event.target;

      setRHFValue(name, inputValue, { shouldValidate: true });
      onChangeProp?.(event, inputValue);
    },
    [setRHFValue, onChangeProp, name]
  );

  return (
    <Controller
      {...{ control, name }}
      defaultValue={value || defaultValue}
      render={({ field }): JSX.Element => (
        <FormControl
          variant="standard"
          error={!!fieldError}
          sx={{ display: "flex" }}
        >
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            {...field}
            {...{ label, value, onChange, labelId, ...restProps }}
          >
            {items.map(
              ({
                label: itemLabel,
                value: itemValue,
                key: itemKey,
                ...rest
              }) => (
                <MenuItem
                  value={itemValue}
                  key={itemKey || itemLabel}
                  {...rest}
                >
                  {itemLabel}
                </MenuItem>
              )
            )}
          </Select>
          {!!fieldError && (
            <FormHelperText>{fieldError}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default BasicFormSelect;
