import { Typography, Box, useTheme, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useCallback, useMemo, useState } from "react";
import BlockIcon from "@mui/icons-material/Block";

import {
  InviteMemberInput,
  MembershipStatus
} from "@api/__generated__/globalTypes";
import { Plans_plans_memberships as Memberships } from "@api/plans/__generated__/Plans";
import getPlanHumanizedTitle from "@hooks/getPlanHumanizedTitle";
import useToggle from "@hooks/useToggle";
import Modal from "@components/Modal";
import { MembershipRole } from "@setup/enums";

import { roleDescription } from "../../consts";

import SharePlanForm from "./SharePlanForm";
import { BlockPeopleListProps } from "./types";

const BlockPeopleList = ({ plan }: BlockPeopleListProps) => {
  const blocked = useMemo(
    () =>
      plan?.memberships?.filter(
        (membership) =>
          membership?.status === MembershipStatus.BLOCKED &&
          membership.userId !== plan?.partner?.id
      ),
    [plan?.memberships, plan?.partner?.id]
  );
  const theme = useTheme();
  const [defaultValues, setDefaultValues] = useState(
    {} as InviteMemberInput
  );
  const [openResend, { toggleOn, toggleOff }] = useToggle();

  const openFormModal = useCallback(
    (member: Memberships | null) => {
      toggleOn();
      if (!member || !plan?.id) {
        return;
      }

      setDefaultValues({
        userId: member?.id,
        planId: plan?.id,
        permissionLevel: member.permissionLevel,
        email: member.user?.email ?? "",
        fullName: member.user?.fullName ?? "",
        role: member.roles[0]
      });
    },
    [plan?.id, toggleOn]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Box
        display="flex"
        gap="0.4rem"
        alignItems="center"
        sx={{
          marginTop: "2.5rem"
        }}
      >
        <BlockIcon />
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "22px"
          }}
        >
          <FormattedMessage defaultMessage="BLOCKED" />
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          marginTop: ".5rem"
        }}
      >
        {blocked?.map((member) => (
          <Box
            key={member?.id}
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Box>
              <Typography>{member?.user?.fullName}</Typography>
              <Typography
                sx={{
                  color: theme.palette.text.disabled
                }}
              >
                {member?.roles
                  .map((role) => {
                    const key = role as unknown as MembershipRole;

                    return roleDescription[key]?.label || "";
                  })
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              sx={{
                color: theme.palette.text.disabled,
                borderColor: theme.palette.text.disabled,
                borderWidth: "2px"
              }}
              onClick={() => openFormModal(member)}
            >
              <FormattedMessage defaultMessage="+ Invite" />
            </Button>
          </Box>
        ))}
      </Box>
      <Modal
        fullWidth
        open={openResend}
        onClose={toggleOff}
        content={
          <SharePlanForm
            planId={plan.id}
            drafterName={plan.primaryDrafter?.fullName || ""}
            planName={getPlanHumanizedTitle(plan.type)}
            {...{
              defaultValues,
              disableName: true,
              onCompleted: toggleOff
            }}
          />
        }
      />
    </Box>
  );
};

export default BlockPeopleList;
