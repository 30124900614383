import { Navigate, RouteObject } from "react-router-dom";

import { TUTORIAL_ROUTES } from "./consts";
import AnswersPage from "./sections/Answers";
import TimingPage from "./sections/Timing";
import ExpectationsPage from "./sections/Expectations";
import HomePage from "./sections/Home";
import QuickActionPage from "./sections/QuickAction";
import TeamPage from "./sections/Team";

const steps: RouteObject[] = [
  {
    path: TUTORIAL_ROUTES.Home,
    element: <HomePage />
  },
  {
    path: TUTORIAL_ROUTES.Expectations,
    element: <ExpectationsPage />
  },
  {
    path: TUTORIAL_ROUTES.Answers,
    element: <AnswersPage />
  },
  {
    path: TUTORIAL_ROUTES.Timing,
    element: <TimingPage />
  },
  {
    path: TUTORIAL_ROUTES.QuickAction,
    element: <QuickActionPage />
  },
  {
    path: TUTORIAL_ROUTES.Team,
    element: <TeamPage />
  },
  {
    path: "*",
    element: <Navigate to={TUTORIAL_ROUTES.Home} />
  }
];

export default steps;
