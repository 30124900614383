import { Grid, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewTabsArchetype from "@components/archetypes/ReviewTabs";
import ReviewBox from "@components/ReviewBox";
import {
  PlanFormContext,
  usePlanForm
} from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

import { usePrimaryDrafterGuardiansReviewTab } from "./Reviews/usePrimaryDrafterGuardiansReviewTab";

const ReviewTabsStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter]
  } = values;
  const { type } = useContext(PlanFormContext);
  const childrenItems = usePrimaryDrafterGuardiansReviewTab({
    values,
    type
  });

  const items = useMemo(
    () => [
      {
        label:
          primaryDrafter?.firstName ??
          formatMessage({ defaultMessage: "You" }),
        content: childrenItems ? (
          <Grid container spacing={{ xs: 3, md: 5.5 }}>
            {childrenItems.map(({ title, ...item }) => (
              <Grid item xs={12} md={6} key={title}>
                <ReviewBox {...{ title, ...item }} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>
            <FormattedMessage defaultMessage="No children" />
          </Typography>
        )
      }
    ],
    [childrenItems, formatMessage, primaryDrafter?.firstName]
  );

  return (
    <ReviewTabsArchetype
      {...{ items }}
      stepLayout={{
        title: `Still looking good?`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewTabsStep;
