import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const EditAssetButton = styled(Button)(({ theme }) => ({
  textDecoration: "none",
  lineHeight: 1.8,
  color: theme.palette.primary.main,
  fontWeight: 500,
  minWidth: "auto",
  fontSize: "1rem",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.text.secondary
    }
  }
}));
