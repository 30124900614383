import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SelectItem } from "@components/FormFields/FormSelect/types";

export const usePetTypes = (): SelectItem[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({ defaultMessage: "Dog" }),
        value: "Dog"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Cat" }),
        value: "Cat"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Fish" }),
        value: "Fish"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Bird" }),
        value: "Bird"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Horse" }),
        value: "Horse"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Reptile" }),
        value: "Reptile"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Other" }),
        value: "Other"
      }
    ],
    [intl]
  );

  return items;
};
