import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "@pages/App";
import "./index.css";
import { SENTRY_DSN } from "@setup/environment";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Apollo is not working well with React Strict Mode, so we removed it
root.render(<App />);
