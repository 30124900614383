import { useMemo } from "react";
import { uniq } from "lodash";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import formatDate from "@utils/formatDate";
import { DOCUMENT_HISTORY_DATE_FORMAT } from "@setup/consts/misc";

const useDocumentHistory = () => {
  const { plans, trust, lastWill, petProtection } =
    useMemberPlansState();

  const subjectPlans = useMemo(
    () =>
      plans?.filter(
        (plan) =>
          plan.id === trust?.plan.id ||
          plan.id === lastWill?.plan.id ||
          plan.id === petProtection?.plan.id
      ),
    [plans, trust, lastWill, petProtection]
  );

  return useMemo(
    () =>
      subjectPlans
        .map((plan) => ({
          plan: plan.type,
          timeline: plan.timeline
        }))
        .map((plan) => ({
          ...plan,
          timeline: uniq(
            (plan.timeline ?? []).map((time) =>
              formatDate(time, DOCUMENT_HISTORY_DATE_FORMAT)
            )
          )
        })),
    [subjectPlans]
  );
};

export default useDocumentHistory;
