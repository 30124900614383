import { memo } from "react";

import PersonDetailsArrayProvider from "./context";
import { PersonDetailsArrayArchetypeProps } from "./types";

import PersonDetailsArray from ".";

const PersonDetailsArrayArchetype: React.FC<
  PersonDetailsArrayArchetypeProps
> = ({
  name,
  onSubmit,
  personRole,
  stepLayout,
  addBtnLabel,
  removeBtnLabel
}) => (
  <PersonDetailsArrayProvider
    value={{
      name,
      onSubmit,
      personRole
    }}
  >
    <PersonDetailsArray
      {...{ stepLayout, addBtnLabel, removeBtnLabel }}
    />
  </PersonDetailsArrayProvider>
);

export default memo(PersonDetailsArrayArchetype);
