import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAcceptMembershipInvitation } from "@api/memberships";
import { ROUTES } from "@setup/consts/routes";
import {
  FINANCIAL_ADVISOR_INVITATION_TOKEN_QUERY_PARAM_KEY,
  INVITATION_TOKEN_QUERY_PARAM_KEY
} from "@setup/consts/misc";
import { useAcceptInvitationMutation } from "@api/invitation";

const AcceptInvitation = () => {
  const navigate = useNavigate();
  const onCompleted = useCallback(() => {
    navigate(ROUTES.Dashboard);
  }, [navigate]);
  const onError = useCallback(() => {
    navigate(ROUTES.Dashboard);
  }, [navigate]);
  const [acceptMembershipInvitation] = useAcceptMembershipInvitation({
    onCompleted,
    onError
  });
  const [acceptInvitation] = useAcceptInvitationMutation({
    onCompleted,
    onError
  });

  const [searchParams] = useSearchParams();

  const handleMembershipInvitation = useCallback(
    (token: string) => {
      acceptMembershipInvitation({
        variables: {
          input: {
            token
          }
        }
      });
    },
    [acceptMembershipInvitation]
  );

  const handleOtherInvitations = useCallback(
    (token: string) => {
      acceptInvitation({
        variables: {
          input: {
            token
          }
        }
      });
    },
    [acceptInvitation]
  );

  useEffect(() => {
    const token = searchParams.get(INVITATION_TOKEN_QUERY_PARAM_KEY);
    const financialAdvisorToken = searchParams.get(
      FINANCIAL_ADVISOR_INVITATION_TOKEN_QUERY_PARAM_KEY
    );

    if (token) {
      handleMembershipInvitation(token);
    } else if (financialAdvisorToken) {
      handleOtherInvitations(financialAdvisorToken);
    } else {
      navigate(ROUTES.Dashboard);
    }
  }, [
    handleOtherInvitations,
    handleMembershipInvitation,
    navigate,
    searchParams
  ]);

  return null;
};

export default AcceptInvitation;
