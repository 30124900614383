import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useFormField } from "@components/FormFields/hooks";
import SingleResponseArchetype from "@components/archetypes/SingleResponse";

const PartnerOtherRequestsStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const { value: ceremonyType } = useFormField<number[] | null>(
    `users.1.finalArrangements.ceremonyType`
  );

  const ceremonies = useMemo(() => {
    if (!ceremonyType || ceremonyType?.length === 0) {
      return null;
    }

    if (ceremonyType?.length === 1) {
      return ceremonyType.toString();
    }

    if (ceremonyType?.length === 2) {
      return ceremonyType.join(" and ");
    }

    const ceremoniesValue = ceremonyType?.join(", ");

    const lastCommaIndex = ceremoniesValue?.lastIndexOf(",");
    const ceremoniesWithAnd = `${ceremoniesValue.slice(
      0,
      lastCommaIndex + 1
    )} and ${ceremoniesValue.slice(
      lastCommaIndex + 1,
      ceremoniesValue.length
    )}`;

    return ceremoniesWithAnd;
  }, [ceremonyType]);

  if (!ceremonies) {
    return (
      <SingleResponseArchetype
        stepLayout={{
          title: `Does ${partnerFirstName} have any other requests for ${partnerFirstName}'s final arrangements?`,
          subText: `If you don't have any other requests, leave this blank and click 'Save & Continue.`
        }}
        label="Special Requests"
        fieldProps={{
          multiline: true,
          minRows: 5
        }}
        name="users.1.finalArrangements.specialRequest"
      />
    );
  }

  return (
    <SingleResponseArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} have other requests for ${partnerFirstName}'s final arrangements?`,
        subText: formatMessage(
          {
            defaultMessage: `You can specify further instructions for your {ceremonies}, as well as add additional requests. Otherwise, leave blank and click 'Save & Continue.`
          },
          {
            ceremonies
          }
        )
      }}
      label="Additional Requests..."
      fieldProps={{
        multiline: true,
        minRows: 5
      }}
      name="users.1.finalArrangements.specialRequest"
    />
  );
};

export default PartnerOtherRequestsStep;
