import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { User } from "@api/me/__generated__/User";
import { LastWillData, Trust } from "@pages/LastWill/types";

const PartnerSameTrusteesAndRepsStep: React.FC = () => {
  const { value: primaryDrafter } = useFormField<User>(`users.0`);

  const { value: lastWill } =
    useFormField<LastWillData>(`users.0.lastWill`);
  const { personalRepresentatives, backupPersonalRepresentatives } =
    lastWill;

  const { value: primaryDrafterTrust } =
    useFormField<Trust>(`users.0.trust`);

  const { coTrustees, successorTrustees } = primaryDrafterTrust;

  const { value: partner } = useFormField<User>(`users.1`);

  // Don't set the partner as partner's own coTrustee, successorTrustee, PR or backupPR
  const filteredCoTrustees = coTrustees?.filter(
    (person) => person.id !== partner.personId
  );
  const filteredSuccessorTrustees = successorTrustees?.filter(
    (person) => person.id !== partner.personId
  );

  const filteredPersonalRepresentatives =
    personalRepresentatives?.filter(
      (person) => person.id !== partner.personId
    );

  const filteredBackupPersonalRepresentatives =
    backupPersonalRepresentatives?.filter(
      (person) => person.id !== partner.personId
    );

  const { firstName } = partner;

  const { setValue: setPartnerTrustees } = useFormField(
    "users.1.trust.coTrustees"
  );
  const { setValue: setPartnerBackupTrustees } = useFormField(
    "users.1.trust.successorTrustees"
  );
  const { setValue: setPartnerReps } = useFormField(
    "users.1.lastWill.personalRepresentatives"
  );
  const { setValue: setPartnerBackupReps } = useFormField(
    "users.1.lastWill.backupPersonalRepresentatives"
  );

  const onSubmit = useCallback(
    ({ copyPersonalRepresentatives }: SingleSelectFormValues) => {
      if (parseBoolean(copyPersonalRepresentatives)) {
        setPartnerTrustees(filteredCoTrustees);
        setPartnerBackupTrustees(filteredSuccessorTrustees);
        setPartnerReps(filteredPersonalRepresentatives);
        setPartnerBackupReps(filteredBackupPersonalRepresentatives);
      }
    },
    [
      setPartnerTrustees,
      setPartnerBackupTrustees,
      setPartnerReps,
      setPartnerBackupReps,
      filteredCoTrustees,
      filteredSuccessorTrustees,
      filteredPersonalRepresentatives,
      filteredBackupPersonalRepresentatives
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${firstName} want to appoint the same Trustees and Personal Representatives as ${primaryDrafter.firstName}?`
      }}
      {...{ onSubmit }}
      name="copyPersonalRepresentatives"
    />
  );
};

export default PartnerSameTrusteesAndRepsStep;
