import { FormattedMessage } from "react-intl";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useCallback, useEffect, memo, useState } from "react";
import omit from "lodash/omit";
import Stack from "@mui/material/Stack/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";

import { useFormField } from "@components/FormFields/hooks";
import { Beneficiary } from "@pages/LastWill/types";

import { BeneficiaryArrayFormFieldProps } from "./types";

export enum FormTypes {
  Individual = "Individual",
  Charity = "Charity"
}

const BeneficiaryArrayForm = ({
  name,
  removeBtnLabel,
  IndividualBeneficiaryItem,
  CharityBeneficiaryItem,
  customRemoveBtn = false,
  limitOneBeneficiary = false
}: BeneficiaryArrayFormFieldProps): React.ReactElement => {
  const controls = useFormContext();
  const [forms, setForms] = useState<FormTypes[]>([]);

  const { fields, append, remove } = useFieldArray({
    control: controls.control,
    name
  });

  const { setValue } = useFormField(name);
  const onRemoveClick = useCallback(
    (index: number) => {
      remove(index);
      setForms((state) => [
        ...state.slice(0, index),
        ...state.slice(index + 1)
      ]);
    },
    [remove]
  );

  useEffect(() => {
    setValue(fields.map((field) => omit(field, ["id"])));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, append]);

  useEffect(() => {
    setForms(
      fields.map((field: Beneficiary) =>
        field?.charity?.name
          ? FormTypes.Charity
          : FormTypes.Individual
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canAddBeneficiary =
    !limitOneBeneficiary ||
    (limitOneBeneficiary && fields.length === 0);

  return (
    <>
      <Stack
        component="ul"
        sx={{
          listStyle: "none",
          margin: 0,
          padding: 0,
          width: "100%"
        }}
        spacing={4}
      >
        {fields.map((item, index) => (
          <Stack component="li" key={item.id} spacing={4}>
            {forms[index] === FormTypes.Individual ? (
              <IndividualBeneficiaryItem
                fieldsCount={fields.length}
                removeField={(): void => {
                  onRemoveClick(index);
                }}
                prefix={`${name}.${index}`}
                {...{ index }}
              />
            ) : (
              <CharityBeneficiaryItem
                fieldsCount={fields.length}
                removeField={(): void => {
                  onRemoveClick(index);
                }}
                prefix={`${name}.${index}`}
                {...{ index }}
              />
            )}

            {!customRemoveBtn && fields.length > 1 && (
              <Button
                color="inherit"
                sx={{ width: "100%" }}
                onClick={(): void => {
                  onRemoveClick(index);
                }}
              >
                {removeBtnLabel || (
                  <FormattedMessage defaultMessage="- Remove" />
                )}
              </Button>
            )}
          </Stack>
        ))}
      </Stack>
      <Box
        mt={5}
        sx={{
          display: "flex",
          gap: "1.5rem"
        }}
      >
        {canAddBeneficiary && (
          <>
            <Button
              color="inherit"
              sx={{
                color: "grey.700"
              }}
              variant="outlined"
              onClick={(): void => {
                setForms((state) => [...state, FormTypes.Individual]);
                append({});
              }}
            >
              <FormattedMessage defaultMessage="+ Individual Beneficiary" />
            </Button>
            <Button
              color="inherit"
              sx={{
                color: "grey.700"
              }}
              variant="outlined"
              onClick={(): void => {
                setForms((state) => [...state, FormTypes.Charity]);
                append({});
              }}
            >
              <FormattedMessage defaultMessage="+ Charity Beneficiary" />
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

export default memo(BeneficiaryArrayForm);
