import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/Trust/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    title="Create My Trust"
    stepLayout={{
      title: `This is a quick one!`,
      subText: `In this next section, we'll note any special arrangements that you'd like to make regarding caring for your body and other services after your death.`
    }}
  />
);

export default SectionsPreviewStep;
