import { useMemo } from "react";
import { lowerCase } from "lodash";

import {
  LastWill_assets as Asset,
  LastWill_assets_beneficiaries as Beneficiary
} from "@api/plans/__generated__/LastWill";
import {
  DistributionType,
  PartnerType,
  TerminationDistribution,
  TrustType
} from "@api/__generated__/globalTypes";
import { HierarchyGiftItem } from "@components/HierarchyGifts/types";

import { getSubTrustModalContent } from "./getSubTrustModalContent";

const useEstateBalanceHierarchy = (
  estateBalanceGifts: (Asset | null)[] | undefined,
  partnerType?: PartnerType | undefined,
  everythingToPartner = false,
  isJointTrust = false
): HierarchyGiftItem[] | undefined => {
  const filteredGifts = estateBalanceGifts?.filter(
    (gift) => gift && gift?.beneficiaries
  );

  const getTrustBackupOptions = (
    bene: Beneficiary | null | undefined
  ): HierarchyGiftItem[] => {
    const subTrustModalContent = getSubTrustModalContent(
      bene?.user?.fullName ?? undefined,
      bene?.backupBeneficiaries?.[0]?.subTrust ?? undefined,
      partnerType
    );

    if (
      (bene?.subTrust?.trustType === TrustType.POOLED_FAMILY ||
        bene?.subTrust?.trustType === TrustType.POOLED_CHILDREN) &&
      bene?.subTrust?.terminationDistribution ===
        TerminationDistribution.SEPARATE_CHILDREN &&
      subTrustModalContent
    ) {
      return [
        {
          name: "Separate Children's Trusts",
          moreInfoModalProps: {
            title: "Separate Children's Trust Details",
            togglerLabel: "View Details",
            content: (
              <p>
                {subTrustModalContent.restrictions}
                <br />
                <br />
                {subTrustModalContent.vestingTypeText}
                <br />
                <br />
                {subTrustModalContent.termination}
              </p>
            )
          }
        }
      ];
    }

    if (
      (bene?.subTrust?.trustType === TrustType.POOLED_FAMILY ||
        bene?.subTrust?.trustType === TrustType.POOLED_CHILDREN) &&
      bene?.subTrust?.terminationDistribution ===
        TerminationDistribution.OUTRIGHT
    ) {
      const modalContent =
        partnerType &&
        bene?.subTrust?.trustType === TrustType.POOLED_FAMILY
          ? `The Trust will terminate upon the later to occur of: (i) your ${lowerCase(
              partnerType
            )}'s death and (ii) your youngest child attaining the age of ${
              bene.subTrust?.vestingSchedule?.[0]?.age ?? "majority"
            }`
          : `The Trust will terminate when your youngest child reaches the age of ${
              bene.subTrust?.vestingSchedule?.[0]?.age ?? "majority"
            }.`;

      return [
        {
          name: "Outright Distributions to Children",
          moreInfoModalProps: {
            title: "Distributions to Children",
            togglerLabel: "View Details",
            content: <p>{modalContent}</p>
          }
        }
      ];
    }

    if (
      bene?.backupDistribution ===
      DistributionType.BACKUP_BENEFICIARIES
    ) {
      return (
        bene?.backupBeneficiaries?.map(
          (backup): HierarchyGiftItem => ({
            name: `${backup?.percentageValue}% to  ${
              backup?.user?.fullName ?? backup?.institution?.name
            }`,
            /*
          TODO: Need to double check with jic-api and questionnaire experience that there is not another option posssible here...
          subItems: backup ? getBackupOptions(backup) : undefined
          */
            subItems: [
              {
                name: "co-primary beneficiaries"
              }
            ]
          })
        ) ?? []
      );
    }

    return [
      {
        name: `${bene?.user?.fullName}'s Descendants`
      }
    ];
  };

  const getBackupOptions = (
    bene: Beneficiary | null | undefined
  ): HierarchyGiftItem[] => {
    if (
      bene?.backupDistribution ===
      DistributionType.CO_PRIMARY_BENEFICIARIES
    ) {
      return [
        {
          name: "other co-primary beneficiaries"
        }
      ];
    }

    if (bene?.backupDistribution === DistributionType.CHARITY) {
      return (
        bene?.backupBeneficiaries?.map(
          (backup): HierarchyGiftItem => ({
            name: backup?.institution?.name ?? "Charity"
          })
        ) ?? []
      );
    }

    if (
      bene?.backupDistribution ===
      DistributionType.BACKUP_BENEFICIARIES
    ) {
      return (
        bene?.backupBeneficiaries?.map(
          (backup): HierarchyGiftItem => ({
            name: `${backup?.percentageValue}% to  ${
              backup?.user?.fullName ?? backup?.institution?.name
            }`,
            /*
          TODO: Need to double check with jic-api and questionnaire experience that there is not another option posssible here...
          subItems: backup ? getBackupOptions(backup) : undefined
          */
            subItems: [
              {
                name: "other primary beneficiaries of the Estate Balance"
              }
            ]
          })
        ) ?? []
      );
    }

    return [
      {
        name: `${bene?.user?.fullName}'s Descendants`
      }
    ];
  };

  const primaryBenes = useMemo(
    () =>
      filteredGifts?.flatMap((gift) =>
        gift?.beneficiaries?.filter(
          (bene) => bene && bene.isBackup === false
        )
      ),
    [filteredGifts]
  );

  const estateBalanceDistributions = primaryBenes?.map((bene) => {
    if (bene?.subTrust) {
      const subTrustModalContent = getSubTrustModalContent(
        bene.user?.fullName ?? undefined,
        bene.subTrust,
        partnerType
      );

      const trustName =
        bene?.subTrust.name ?? `Trust for ${bene.user?.fullName}`;

      return {
        name: `${bene?.percentageValue}% to ${trustName}`,
        moreInfoModalProps: {
          title: bene?.subTrust.name ?? "Trust Details",
          togglerLabel: "View Details",
          content: (
            <p>
              {subTrustModalContent?.restrictions}
              <br />
              <br />
              {subTrustModalContent?.vestingTypeText}
              <br />
              <br />
              {subTrustModalContent?.termination}
            </p>
          )
        },
        subItems: getTrustBackupOptions(bene)
      };
    }

    return {
      name: `${bene?.percentageValue}% to ${
        bene?.user?.fullName ?? bene?.institution?.name
      }`,
      subItems: getBackupOptions(bene)
    };
  });

  return estateBalanceDistributions?.filter((item) => item);
};

export default useEstateBalanceHierarchy;
