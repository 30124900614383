import { gql } from "@apollo/client";

export const INVITE_CLIENT = gql`
  mutation InviteClient($input: InviteClientInput!) {
    inviteClient(input: $input)
  }
`;

export const CREATE_AND_COPY_CLIENT_INVITE = gql`
  mutation CreateClientInvite($input: InviteClientInput!) {
    createClientInvite(input: $input)
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input)
  }
`;
