import { useMemo, useCallback } from "react";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { get } from "lodash";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { ArrayFormValues } from "@components/archetypes/ArrayForm/types";
import FormInput from "@components/FormFields/FormInput";
import { useFormField } from "@components/FormFields/hooks";

type ListPeopleFormProps = {
  prefix: string;
  index: number;
};

type BasicNameType = {
  name?: string | null;
};

const ListPeopleForm: React.FC<ListPeopleFormProps> = ({
  prefix,
  index
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormInput
      name={`${prefix}.name`}
      label={formatMessage(
        {
          defaultMessage: "Gift Recipient #{number}’s Full Name"
        },
        { number: index + 1 }
      )}
    />
  );
};

const PartnerPowersMakeGiftsPeopleListStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const formSchema: yup.SchemaOf<BasicNameType> = useMemo(
    () =>
      yup.object().shape({
        name: yup.string()
      }),
    []
  );

  const { setValue: setPeopleListValue } = useFormField(
    "users.1.poa.giftRecipients.list"
  );

  const onSubmit = useCallback(
    (data: ArrayFormValues<BasicNameType>) => {
      const peopleListArray = get(
        data,
        "users.1.poa.giftRecipients.listArray"
      );

      setPeopleListValue(
        peopleListArray?.map((person) => person?.name)?.join(", ")
      );
    },
    [setPeopleListValue]
  );

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `Which individuals or charities would ${partnerFirstName} like to add as a permissible recipient of gifts?`,
        subText:
          "You can add more recipients by clicking the 'Add Recipient' button"
      }}
      addBtnLabel="Add Recipient"
      singleItemSchema={formSchema}
      SingleItem={ListPeopleForm}
      name="users.1.poa.giftRecipients.listArray"
      {...{ onSubmit }}
    />
  );
};

export default PartnerPowersMakeGiftsPeopleListStep;
