import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import getPooledTrustContent from "@components/archetypes/TrustSetupArchetype/getPooledTrustContent";

const PartnerRemainderPooledAgeThresholdStep: React.FC = () => {
  const { setValue: setPercentVesting } = useFormField(
    "users.1.assets.estateBalance.0.beneficiaries.0.subTrust.vestingSchedule.0.percentageValue"
  );

  const onSubmit = useCallback(() => {
    setPercentVesting(100);
  }, [setPercentVesting]);

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `What age must your youngest child reach before the Pooled Trust is terminated?`,
        subText:
          "The Trustee will make distributions to your children from the pooled trust funds up until your youngest child reaches this age. After this age, the remaining funds will be divided and split among your children.",
        moreInfoModalProps: getPooledTrustContent()
      }}
      name="users.1.assets.estateBalance.0.beneficiaries.0.subTrust.vestingSchedule.0.age"
      items={[
        {
          value: 18,
          label: "18"
        },
        {
          value: 21,
          label: "21"
        },
        {
          value: 25,
          label: "25 (most common)"
        },
        {
          value: 30,
          label: "30"
        },
        {
          value: 35,
          label: "35"
        }
      ]}
      {...{ onSubmit }}
    />
  );
};

export default PartnerRemainderPooledAgeThresholdStep;
