import { useIntl } from "react-intl";
import { useMemo } from "react";

import { ReviewItem } from "@components/archetypes/Review/types";
import { PetPlanValues } from "@pages/PetPlan/types";
import { ITERATION_QUERY_KEY } from "@providers/planForm/consts";
import { ROUTES } from "@setup/consts/routes";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";

import { PET_GUARDIANS_ROUTES } from "../../consts";

import getGuardiansInfo from "./getGuardiansInfo";

export const usePrimaryDrafterPetGuardiansReviewTab = ({
  values
}: {
  values: PetPlanValues;
}) => {
  const { formatMessage } = useIntl();
  const iterationQueryKey = ITERATION_QUERY_KEY;

  const { pets = [], sameGuardian } = values;
  const editPath = getLinkFullUrl([
    ROUTES.PetPlan,
    PET_SECTION_ROUTES.Guardians,
    PET_GUARDIANS_ROUTES.GuardianLoop,
    PET_GUARDIANS_ROUTES.DesignateGuardian,
    `?${iterationQueryKey}=1`
  ]);

  const petsGuardianInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Pet Guardian Info"
            }),
            href: editPath,
            isFullWidth: true,
            items: getGuardiansInfo(pets, sameGuardian)
          }
        : undefined,
    [pets, formatMessage, editPath, sameGuardian]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [petsGuardianInfo].filter((item): item is ReviewItem =>
        Boolean(item)
      ),
    [petsGuardianInfo]
  );

  return items;
};
