import MessageIcon from "@mui/icons-material/Message";
import PersonIcon from "@mui/icons-material/Person";
import CelebrationIcon from "@mui/icons-material/Celebration";

import IconList from "@components/IconList";

const QuizNextSteps: React.FC = () => {
  const items = [
    {
      icon: <PersonIcon />,
      label: "Create an account"
    },
    {
      icon: <MessageIcon />,
      label: "Complete the step-by-step questionnaire"
    },

    {
      icon: <CelebrationIcon />,
      label:
        "Complete checkout to get your documents with free updates for life"
    }
  ];

  return <IconList title="What's Next?" items={items} />;
};

export default QuizNextSteps;
