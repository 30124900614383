import { LegalFocus } from "../enums";

const isRightsOrContest = (focusAreas?: string[]) => {
  if (focusAreas?.length === 1) {
    return (
      focusAreas[0] === LegalFocus.BENEFICIARY_RIGHTS ||
      focusAreas[0] === LegalFocus.ESTATE_CHALLENGE
    );
  }
  if (focusAreas?.length === 2) {
    return (
      (focusAreas[0] === LegalFocus.BENEFICIARY_RIGHTS &&
        focusAreas[1] === LegalFocus.ESTATE_CHALLENGE) ||
      (focusAreas[0] === LegalFocus.ESTATE_CHALLENGE &&
        focusAreas[1] === LegalFocus.BENEFICIARY_RIGHTS)
    );
  }

  return false;
};

export default isRightsOrContest;
