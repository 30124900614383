import {
  Button as MaterialButton,
  ListItem as MaterialListItem
} from "@mui/material";
import { styled, darken } from "@mui/material/styles";

import Image from "@components/Image";

export const ListItem = styled(MaterialListItem)(() => ({
  width: "100%",
  padding: 0,
  "&:not(:first-of-type)": {
    marginTop: "1.25rem"
  }
}));

export const Button = styled(MaterialButton)<{
  backgroundColor?: string;
}>(({ theme, backgroundColor }) => ({
  width: "100%",
  paddingLeft: "3.5rem",
  textAlign: "center",
  "&:hover": {
    background: backgroundColor
      ? darken(backgroundColor, 0.2)
      : theme.palette.primary.dark
  }
}));

export const ButtonImage = styled(Image)(() => ({
  position: "absolute",
  width: "2.5rem",
  height: "2.5rem",
  padding: 8,
  background: "white",
  left: 0,
  top: 0
}));

export const ButtonLabel = styled("span")(() => ({
  width: "100%"
}));
