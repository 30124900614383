import * as yup from "yup";

const makeYupSchema = (
  initialSchema: yup.AnySchema,
  name: string,
  defaultArray = false
): yup.AnyObjectSchema => {
  let isArray = defaultArray;

  const path = name.split(".");

  const obj = path
    .slice(1)
    .reverse()
    .reduce((prev, current) => {
      const newSchema = isArray
        ? (yup.array().of(prev) as yup.AnySchema)
        : prev;

      // eslint-disable-next-line no-restricted-globals
      if (Number.isNaN(+current)) {
        isArray = false;

        return yup.object().shape({
          [current]: newSchema
        });
      }

      isArray = true;

      return newSchema;
    }, initialSchema);

  const schema = yup.object().shape({
    [path[0]]: isArray ? yup.array().of(obj) : obj
  });

  return schema;
};

export default makeYupSchema;
