import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { PET_ALL_ABOUT_YOU_ROUTES } from "@pages/PetPlan/sections/AllAboutYou/consts";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";
import { ROUTES } from "@setup/consts/routes";
import { ITERATION_QUERY_KEY } from "@providers/planForm/consts";

import { getLinkFullUrl } from "../utils";

const iterationQueryKey = ITERATION_QUERY_KEY;

export const usePetAllAboutYouReview = () => {
  const { formatMessage } = useIntl();
  const { petProtection: petProtectionPlan } = useMemberPlansState();
  const { petProtection, canEdit = false } = petProtectionPlan || {};

  const pets = petProtection?.pets;

  const petsInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Pet Info"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.AllAboutYou,
              PET_ALL_ABOUT_YOU_ROUTES.PetDetails
            ]),
            isFullWidth: true,
            items: pets.map((pet) =>
              [pet?.name ?? "", pet?.kind ?? ""].filter(
                (item): item is string => Boolean(item)
              )
            )
          }
        : undefined,
    [pets, canEdit, formatMessage]
  );

  const petCharacteristicsInfo = useMemo<ReviewItem | undefined>(
    () =>
      pets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Identifying Physical Characteristics"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.PetPlan,
              PET_SECTION_ROUTES.AllAboutYou,
              PET_ALL_ABOUT_YOU_ROUTES.PhysicalTraitsLoop,
              PET_ALL_ABOUT_YOU_ROUTES.PhysicalTraits,
              `?${iterationQueryKey}=1`
            ]),
            isFullWidth: true,
            items: pets.map((pet) => [
              `${pet?.name} is a ${[pet?.kind ? pet?.kind : ``].join(
                " "
              )}`,
              pet?.characteristics ? `"${pet?.characteristics}"` : ``
            ])
          }
        : undefined,
    [pets, canEdit, formatMessage]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [petsInfo, petCharacteristicsInfo].filter(
        (item): item is ReviewItem => Boolean(item)
      ),
    [petCharacteristicsInfo, petsInfo]
  );

  return items;
};
