import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import HierarchyGifts from "@components/HierarchyGifts";

import useRevTrustDistributionHierarchy from "./useRevTrustDistributionHierarchy";
import useLastWillDistributionHierarchy from "./useLastWillDistributionHierarchy";
import { DistributionTabProps } from "./types";
import useEstateBalanceHierarchy from "./useEstateBalanceHierarchy";
import SurvivorScenarios from "./SurvivorScenarios";

const DistributionsTab: React.FC<DistributionTabProps> = ({
  name,
  settlorNames,
  isTrust,
  specificGifts,
  estateBalanceGifts,
  partnerType,
  everythingToPartner,
  isJointTrust,
  partnerSpecificGifts,
  partnerEstateBalanceGifts,
  partnerEverythingToPartner
}) => {
  const lwDistributions =
    useLastWillDistributionHierarchy(specificGifts);
  const trustDistributions = useRevTrustDistributionHierarchy(
    specificGifts,
    everythingToPartner,
    isJointTrust
  );
  const distributions = isTrust
    ? trustDistributions
    : lwDistributions;

  const estateBalanceDistributions = useEstateBalanceHierarchy(
    estateBalanceGifts,
    partnerType
  );

  return (
    <Box>
      <Box
        mb="1.25rem"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.625rem"
        }}
      >
        <InfoOutlinedIcon />
        <Typography>
          A visual representation of how distributions under your
          estate plan work.
        </Typography>
      </Box>
      <HierarchyGifts
        title={`${name}'s Estate Plan`}
        items={distributions}
      />
      <Box my={4} gap={2}>
        {isTrust ? (
          <>
            <Typography>
              * Any unfunded non-probate assets will pass directly to
              the beneficiary listed on the account.
            </Typography>
            <Typography>
              ** Your Pour Over Will funds into your trust all
              remaining probate assets that weren&apos;t funded prior
              to your passing.
            </Typography>
          </>
        ) : (
          <Typography mt={4}>
            * Any non-probate assets will pass directly to the
            beneficiary listed on the account.
          </Typography>
        )}
      </Box>
      {/*
      Add tabs here to toggle who is the Deceased Settlor...
      */}
      {isJointTrust && (
        <SurvivorScenarios
          name={name}
          isTrust={isTrust}
          specificGifts={specificGifts}
          estateBalanceGifts={estateBalanceGifts}
          partnerType={partnerType}
          everythingToPartner={everythingToPartner}
          isJointTrust={isJointTrust}
          partnerSpecificGifts={partnerSpecificGifts}
          partnerEstateBalanceGifts={partnerEstateBalanceGifts}
          partnerEverythingToPartner={partnerEverythingToPartner}
          settlorNames={settlorNames}
        />
      )}
      {!isJointTrust && estateBalanceDistributions && (
        <HierarchyGifts
          title={
            isTrust
              ? `${name}'s Trust Remainder`
              : `${name}'s Estate Balance`
          }
          items={estateBalanceDistributions}
        />
      )}
    </Box>
  );
};

export default DistributionsTab;
