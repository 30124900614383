import * as yup from "yup";
import { useCallback, useMemo } from "react";
import differenceInYears from "date-fns/differenceInYears";
import { useIntl } from "react-intl";
import get from "lodash/get";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";
import useToggle from "@hooks/useToggle";

import { buildFormAddress, verifyAddress } from "./utils";
import { UserDetailsFormValues } from "./types";

export const useUserDetailsSchema = (
  name: string
): yup.AnyObjectSchema => {
  const intl = useIntl();

  return useMemo(() => {
    const basicShape: yup.AnySchema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phone: yup.string().required(),
      address: yup
        .object()
        .shape({
          address1: yup.string().required().nullable(),
          address2: yup.string().nullable(),
          city: yup.string().required().nullable(),
          state: yup.string().required().nullable(),
          stateCode: yup.string().nullable(),
          zip: yup.string().required().nullable(),
          county: yup.string().required().nullable(),
          country: yup.string().nullable()
        })
        .required(),
      birthdate: yup
        .date()
        .transform((_, originalValue) =>
          originalValue ? new Date(originalValue) : undefined
        )
        .min(
          new Date("1900-01-01"),
          intl.formatMessage({
            defaultMessage:
              "Date of birth must be later than 01/01/1900."
          })
        )
        .typeError(
          intl.formatMessage({
            defaultMessage:
              "Please enter the date in MM/DD/YYYY format."
          })
        )
        .required()
        .nullable()
        .test(
          "birthdate",
          intl.formatMessage({
            defaultMessage:
              "You must be at least 18 years old to create your estate plan with JIC Estates."
          }),
          (value) =>
            value
              ? differenceInYears(new Date(), new Date(value)) >= 18
              : false
        )
    });

    return makeYupSchema(basicShape, name);
  }, [intl, name]);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useUserDetails = (
  name: string,
  onSubmit?: (data: UserDetailsFormValues) => void
) => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useUserDetailsSchema(name);
  const controls = useFormWithYup<UserDetailsFormValues>(schema, {});
  const [isModalOpened, { toggleOn, toggleOff }] = useToggle();

  const onFormSubmit = useCallback(
    async (data: UserDetailsFormValues): Promise<void> => {
      try {
        const personData = get(data, name);

        const formAddress = buildFormAddress(personData);
        const isAddressCorrect = await verifyAddress(formAddress);

        if (isAddressCorrect) {
          onSubmit?.(data);
          saveAndContinue(true);
        } else {
          toggleOn();
        }
      } catch (reason) {
        // eslint-disable-next-line no-console
        console.log(`Handle rejected promise (${reason}) here.`);
      }
    },
    [name, onSubmit, saveAndContinue, toggleOn]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onFormSubmit),
    isModalOpened,
    toggleOff,
    saveAndContinue
  };
};

export { useUserDetails };
