/* eslint-disable no-irregular-whitespace */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePetBreedsOther = (): AutocompleteBaseOption[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({ defaultMessage: `Alpaca` }),
        value: "Alpaca"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Chicken` }),
        value: "Chicken"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Degus` }),
        value: "Degus"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Duck` }),
        value: "Duck"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Ferret` }),
        value: "Ferret"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Gerbil` }),
        value: "Gerbil"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Goose` }),
        value: "Goose"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Guinea pig` }),
        value: "Guinea pig"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Hamster` }),
        value: "Hamster"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Hedgehog` }),
        value: "Hedgehog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Hermit crab` }),
        value: "Hermit crab"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Mouse` }),
        value: "Mouse"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Newt` }),
        value: "Newt"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Pig` }),
        value: "Pig"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Rabbit` }),
        value: "Rabbit"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Rat` }),
        value: "Rat"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Sheep` }),
        value: "Sheep"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Spider` }),
        value: "Spider"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Turkey` }),
        value: "Turkey"
      }
    ],
    [intl]
  );

  return items;
};
