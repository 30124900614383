import { DocumentType } from "@pages/EstateAdminQuiz/enums";
import { EstateAdminResults } from "@pages/EstateAdminQuiz/types";

import getErrorMessage from "./getErrorMessage";
import getDefaultTrustResults from "./getDefaultTrustResults";
import getDefaultProbateResults from "./getDefaultProbateResults";

const getMassachusettsResults = (
  state: string,
  documentType: DocumentType,
  assetValue: string,
  probateAssetValue?: string
): EstateAdminResults => {
  // Memo: Small Estate Threshold is the threshold below which the estate may qualify for expedited probate
  const smallEstateAssetThreshold = "$25,000";
  // Memo: isSmallEstate connects that threshold to the closest assetValue in our quiz...
  const isSmallEstate =
    assetValue === "<$50,000" || probateAssetValue === "<$50,000";
  // Memo: This is the state-specific name for expedited estate administration and full probate in this state
  const probateRec = isSmallEstate
    ? "Voluntary Administration"
    : "Full Probate";
  // Memo: This is the state-specific name for intestate estate administration
  const intestateAdministrationName = "intestate administration";

  if (
    documentType === DocumentType.REVOCABLE_TRUST ||
    documentType === DocumentType.IRREVOCABLE_TRUST
  )
    return getDefaultTrustResults(isSmallEstate, assetValue, state);

  if (
    documentType === DocumentType.LAST_WILL ||
    documentType === DocumentType.INTESTATE
  )
    return getDefaultProbateResults(
      documentType,
      smallEstateAssetThreshold,
      isSmallEstate,
      probateRec,
      intestateAdministrationName,
      assetValue,
      state
    );

  return getErrorMessage();
};

export default getMassachusettsResults;
