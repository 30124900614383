import { Navigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import WorkIcon from "@mui/icons-material/Work";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import HouseIcon from "@mui/icons-material/House";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";

import { PlanFormSection } from "@providers/planForm/types";

import { TRUST_SECTION_ROUTES } from "./consts";
import TrustAllAboutYouSection from "./sections/AllAboutYou";
import allAboutYouSteps from "./sections/AllAboutYou/routes";
import TrustGuardiansSection from "./sections/Guardians";
import guardiansSteps from "./sections/Guardians/routes";
import TrustManagementSection from "./sections/Management";
import managementSteps from "./sections/Management/routes";
import TrustGiftsSection from "./sections/Gifts";
import giftsSteps from "./sections/Gifts/routes";
import TrustAssetsSection from "./sections/Assets";
import assetsSteps from "./sections/Assets/routes";
import TrustFinalArrangementsSection from "./sections/FinalArrangements";
import finalArrangementsSteps from "./sections/FinalArrangements/routes";
import DocumentPreviewPage from "./sections/DocumentPreview";

const sections: PlanFormSection[] = [
  {
    path: TRUST_SECTION_ROUTES.AllAboutYou,
    element: <TrustAllAboutYouSection />,
    children: allAboutYouSteps,
    icon: <AccountCircleIcon />
  },
  {
    path: TRUST_SECTION_ROUTES.Guardians,
    element: <TrustGuardiansSection />,
    children: guardiansSteps,
    icon: <EscalatorWarningIcon />,
    dependency: [TRUST_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: TRUST_SECTION_ROUTES.Management,
    element: <TrustManagementSection />,
    children: managementSteps,
    icon: <WorkIcon />,
    dependency: [TRUST_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: TRUST_SECTION_ROUTES.Gifts,
    element: <TrustGiftsSection />,
    children: giftsSteps,
    icon: <VolunteerActivismIcon />,
    dependency: [TRUST_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: TRUST_SECTION_ROUTES.Assets,
    element: <TrustAssetsSection />,
    children: assetsSteps,
    icon: <HouseIcon />,
    dependency: [TRUST_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: TRUST_SECTION_ROUTES.FinalArrangements,
    element: <TrustFinalArrangementsSection />,
    children: finalArrangementsSteps,
    icon: <LocalFloristIcon />,
    dependency: [TRUST_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: TRUST_SECTION_ROUTES.DocumentPreview,
    element: <DocumentPreviewPage />,
    hideInMenu: true
  },
  {
    path: "*",
    element: <Navigate to={TRUST_SECTION_ROUTES.AllAboutYou} />
  }
];

export default sections;
