import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { PlanType } from "@api/__generated__/globalTypes";
import usePlanTypeContext from "@providers/planType/hooks";
import PageTitle from "@components/PageTitle";
import { DEFAULT_PLAN_TYPE } from "@setup/consts/misc";

const TutorialPage: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const locationState = location.state as PlanType;
  const planType = locationState || DEFAULT_PLAN_TYPE;
  const { setPlanTypeEnum } = usePlanTypeContext();

  useEffect(() => {
    setPlanTypeEnum(planType);
  }, [planType, setPlanTypeEnum]);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ defaultMessage: "Tutorial" })}
      />
      <Outlet />
    </>
  );
};

export default TutorialPage;
