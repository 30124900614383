import { DocumentType } from "@pages/EstateAdminQuiz/enums";
import { EstateAdminResults } from "@pages/EstateAdminQuiz/types";

import getFedEstateTax from "../estateTax/getFedEstateTax";
import getStateEstateTax from "../estateTax/getStateEstateTax";

const getDefaultProbateResults = (
  documentType: string,
  smallEstateAssetThreshold: string | undefined,
  isSmallEstate: boolean,
  probateRec: string,
  intestateAdministrationName: string,
  assetValue: string,
  state: string
): EstateAdminResults => {
  const bulletRecs = [];
  const probateRationale: string[] = [];

  probateRationale.push(
    documentType === DocumentType.LAST_WILL
      ? `Since the deceased had a last will, this estate will need to go through probate in ${state}.`
      : `Since the deceased passed away without an estate plan, the estate will need to go through the ${intestateAdministrationName} process in ${state}.`
  );

  probateRationale.push(
    isSmallEstate
      ? `However, the estate may qualify for an expedited ${probateRec} proceeding in ${state}${
          smallEstateAssetThreshold
            ? ` if the value of probate assets is less than ${smallEstateAssetThreshold} and the estate meets certain other requirements.`
            : `.`
        } `
      : ""
  );

  bulletRecs.push(
    isSmallEstate ? (
      <li>
        <strong>Timing. </strong>
        {probateRec} in {state} is an expedited type of probate
        proceeding that can usually be completed within several
        months.
      </li>
    ) : (
      <li>
        <strong>Timing. </strong>
        {probateRec} in {state} can take several months to upwards of
        a year, depending on the specifics of the estate.
      </li>
    )
  );
  bulletRecs.push(
    isSmallEstate ? (
      <li>
        <strong>Cost. </strong>
        Generally, cost for {probateRec} in {state} is much less than
        a formal (e.g., full) probate proceeding. If the executor is
        serving without compensation and is comfortable interacting
        with the court, you may only need to pay small court fees.
      </li>
    ) : (
      <li>
        <strong>Cost. </strong>
        The cost for {probateRec} in {state} can vary widely based on
        a number of different factors. Your estate administration
        expert can help estimate the applicable cost to this estate.
      </li>
    )
  );
  bulletRecs.push(getStateEstateTax(state, assetValue));
  bulletRecs.push(getFedEstateTax(assetValue));

  return {
    recommendation: probateRec,
    rationale: probateRationale.join(" "),
    bullets: bulletRecs
  };
};

export default getDefaultProbateResults;
