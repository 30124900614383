import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerSameCompStep: React.FC = () => {
  const { value: TrusteeCompType } = useFormField(
    `users.0.trust.trusteesCompensationType`
  );
  const { value: TrusteeCompDollar } = useFormField(
    `users.0.trust.trusteesCompensationDollar`
  );
  const { value: TrusteeCompPercent } = useFormField(
    `users.0.trust.trusteesCompensationPercent`
  );
  const { value: RepCompType } = useFormField(
    `users.0.lastWill.representativesCompensationType`
  );
  const { value: RepCompDollar } = useFormField(
    `users.0.lastWill.representativesCompensationDollar`
  );
  const { value: RepCompPercent } = useFormField(
    `users.0.lastWill.representativesCompensationPercent`
  );

  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);
  const { setValue: setTrusteesCompensationType } = useFormField(
    "users.1.trust.trusteesCompensationType"
  );
  const { setValue: setTrusteesCompensationDollar } = useFormField(
    "users.1.trust.trusteesCompensationDollar"
  );
  const { setValue: setTrusteesCompensationPercent } = useFormField(
    "users.1.trust.trusteesCompensationPercent"
  );
  const { setValue: setCompensationType } = useFormField(
    "users.1.lastWill.representativesCompensationType"
  );
  const { setValue: setCompensationDollar } = useFormField(
    "users.1.lastWill.representativesCompensationDollar"
  );
  const { setValue: setCompensationPercent } = useFormField(
    "users.1.lastWill.representativesCompensationPercent"
  );

  const onSubmit = useCallback(
    ({ copyPersonalRepresentativesComp }: SingleSelectFormValues) => {
      if (parseBoolean(copyPersonalRepresentativesComp)) {
        setTrusteesCompensationType(TrusteeCompType);
        setTrusteesCompensationDollar(TrusteeCompDollar);
        setTrusteesCompensationPercent(TrusteeCompPercent);
        setCompensationType(RepCompType);
        setCompensationDollar(RepCompDollar);
        setCompensationPercent(RepCompPercent);
      }
    },
    [
      setTrusteesCompensationType,
      setTrusteesCompensationDollar,
      setTrusteesCompensationPercent,
      setCompensationType,
      setCompensationDollar,
      setCompensationPercent,
      TrusteeCompType,
      TrusteeCompDollar,
      TrusteeCompPercent,
      RepCompType,
      RepCompDollar,
      RepCompPercent
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `And does ${partnerFirstName} want to compensate ${partnerFirstName}'s Trustees and Personal Representatives in the same way, too?`
      }}
      {...{ onSubmit }}
      name="copyPersonalRepresentativesComp"
    />
  );
};

export default PartnerSameCompStep;
