import { useIntl } from "react-intl";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ModalToggler from "@components/Modal/partials/ModalToggler";
import Modal from "@components/Modal";
import useToggle from "@hooks/useToggle";

import { InfoModalProps } from "./types";

const InfoModal: React.FC<InfoModalProps> = ({
  title,
  togglerLabel,
  content,
  closeLabel,
  actions = []
}) => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <>
      <ModalToggler
        icon={<OpenInNewIcon fontSize="small" color="inherit" />}
        label={togglerLabel}
        onClick={toggleOn}
      />
      <Modal
        open={isOpen}
        onClose={toggleOff}
        title={title}
        content={content}
        actions={[
          {
            label:
              closeLabel ||
              intl.formatMessage({ defaultMessage: "Got it" }),
            onClick: toggleOff,
            variant: "outlined",
            color: "inherit"
          },
          ...actions
        ]}
      />
    </>
  );
};

export default InfoModal;
