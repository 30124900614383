import React, {
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
  useEffect
} from "react";
import { differenceInMilliseconds } from "date-fns";
import { useIntl } from "react-intl";

import Modal from "@components/Modal";
import getSessionToken from "@utils/getSessionToken";
import useCognitoAuth from "@hooks/useCognitoAuth";

import { SessionExpiredContext } from "./context";

export const { Provider: SessionExpiredProvider } =
  SessionExpiredContext;

export const SessionExpiredContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const { formatMessage } = useIntl();
  const { logout } = useCognitoAuth();
  const [isSessionExpired, setSessionExpired] = useState(false);
  const sessionTimeoutRef = useRef<NodeJS.Timeout>();

  const startSessionTimeout = useCallback(async () => {
    const token = await getSessionToken();

    if (!token) {
      return;
    }

    const expirationDate = token.getExpiration();

    const timeAboutToExpire = differenceInMilliseconds(
      new Date(expirationDate * 1000),
      new Date()
    );

    const timeoutRef = setTimeout(() => {
      setSessionExpired(true);
    }, timeAboutToExpire);

    sessionTimeoutRef.current = timeoutRef;
  }, []);

  const stopSessionTimeout = useCallback(() => {
    const timeout = sessionTimeoutRef?.current;

    setSessionExpired(false);

    if (timeout) {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    startSessionTimeout();

    return () => {
      stopSessionTimeout();
    };
  }, [startSessionTimeout, stopSessionTimeout]);

  const onRedirectClick = useCallback(() => {
    logout();
    setSessionExpired(false);
  }, [logout]);

  return (
    <SessionExpiredProvider value={{}}>
      <Modal
        open={isSessionExpired}
        title={formatMessage({ defaultMessage: "Session expired" })}
        content={
          <>
            {formatMessage({
              defaultMessage:
                "Your session has been paused due to inactivity. Please sign in again"
            })}
          </>
        }
        actions={[
          {
            label: "Return to sign in",
            variant: "outlined",
            color: "inherit",
            onClick: onRedirectClick
          }
        ]}
      />
      {children}
    </SessionExpiredProvider>
  );
};
