import { CognitoUserPool } from "amazon-cognito-identity-js";
import { Amplify } from "aws-amplify";

import {
  COGNITO_CLIENT_ID,
  COGNITO_DOMAIN,
  COGNITO_REDIRECT_SIGN_IN,
  COGNITO_REDIRECT_SIGN_OUT,
  COGNITO_REGION,
  USER_POOL_ID
} from "@setup/environment";

const poolData = {
  UserPoolId: USER_POOL_ID,
  ClientId: COGNITO_CLIENT_ID
};

const userPool = new CognitoUserPool(poolData);

Amplify.configure({
  Auth: {
    userPoolId: USER_POOL_ID,
    region: COGNITO_REGION,
    userPoolWebClientId: COGNITO_CLIENT_ID
  },
  oauth: {
    domain: COGNITO_DOMAIN,
    scope: ["email", "openid"],
    redirectSignIn: COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: COGNITO_REDIRECT_SIGN_OUT,
    responseType: "token"
  }
});

export { userPool, Amplify };
