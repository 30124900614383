import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";

import { PET_SPECIAL_POWERS_ROUTES } from "./consts";
import SectionsPreviewStep from "./steps/Preview";
import AllowFinanceAccessStep from "./steps/AllowFinanceAccess";
import AllowFinanceAmountStep from "./steps/AllowFinanceAmount";
import AllowHealthAccessStep from "./steps/AllowHealthAccess";
import SetAsideFundsStep from "./steps/SetAsideFunds";
import SetAsideFundsAmountStep from "./steps/SetAsideFundsAmount";
import ReviewStep from "./steps/Review";

const specialPowersSteps: PlanFormStep[] = [
  {
    path: PET_SPECIAL_POWERS_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: PET_SPECIAL_POWERS_ROUTES.AllowFinanceAccess
  },
  {
    path: PET_SPECIAL_POWERS_ROUTES.AllowFinanceAccess,
    element: <AllowFinanceAccessStep />,
    rules: {
      if: [
        {
          "==": [{ var: "allowGuardianIntoTransactions" }, true]
        },
        PET_SPECIAL_POWERS_ROUTES.AllowFinanceAccessAmount,
        PET_SPECIAL_POWERS_ROUTES.SetAsideFunds
      ]
    }
  },
  {
    path: PET_SPECIAL_POWERS_ROUTES.AllowFinanceAccessAmount,
    element: <AllowFinanceAmountStep />,
    rules: PET_SPECIAL_POWERS_ROUTES.SetAsideFunds
  },
  {
    path: PET_SPECIAL_POWERS_ROUTES.SetAsideFunds,
    element: <SetAsideFundsStep />,
    rules: {
      if: [
        {
          "==": [{ var: "areAsideFundsSet" }, true]
        },
        PET_SPECIAL_POWERS_ROUTES.SetAsideFundsAmount,
        PET_SPECIAL_POWERS_ROUTES.AllowHealthAccess
      ]
    }
  },
  {
    path: PET_SPECIAL_POWERS_ROUTES.SetAsideFundsAmount,
    element: <SetAsideFundsAmountStep />,
    rules: PET_SPECIAL_POWERS_ROUTES.AllowHealthAccess
  },
  {
    path: PET_SPECIAL_POWERS_ROUTES.AllowHealthAccess,
    element: <AllowHealthAccessStep />,
    rules: PET_SPECIAL_POWERS_ROUTES.Review
  },
  {
    path: PET_SPECIAL_POWERS_ROUTES.Review,
    element: <ReviewStep />,
    rules: PET_SECTION_ROUTES.DocumentPreview
  },
  {
    path: "*",
    element: <Navigate to={PET_SPECIAL_POWERS_ROUTES.Preview} />
  }
];

export default specialPowersSteps;
