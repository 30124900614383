import { useCallback } from "react";
import { get } from "lodash";

import { DistribType } from "@api/__generated__/globalTypes";
import { useFormField } from "@components/FormFields/hooks";
import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { parseBoolean } from "@utils/parseBoolean";
import { Beneficiary, SubTrust } from "@pages/LastWill/types";

// TODO @LUKE -- create a callback that, if yes, copies all the props to the rest of the individual beneficiaries
const RemainderSeparateTrustCopyStep: React.FC = () => {
  const { value: remainderDistribType } = useFormField(
    "users.0.gifts.remainderDistribType"
  );

  const people =
    remainderDistribType === DistribType.SEPARATE_CHILDREN
      ? "your children"
      : "the other individual beneficiaries of your Trust Remainder";

  const { value: firstBeneficiaryBackupDistribution } = useFormField<
    string | undefined
  >(
    "users.0.assets.estateBalance.0.beneficiaries.0.backupDistribution"
  );
  const { value: firstBeneficiarySubTrust } = useFormField<
    SubTrust | undefined
  >("users.0.assets.estateBalance.0.beneficiaries.0.subTrust");

  const {
    value: beneficiaries,
    setValue: setEstateBalanceBeneficiaries
  } = useFormField<Beneficiary[]>(
    "users.0.assets.estateBalance.0.beneficiaries"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const isCopy = get(
        data,
        "users.0.gifts.remainderCopyTrustSetup"
      );

      if (parseBoolean(isCopy)) {
        setEstateBalanceBeneficiaries(
          beneficiaries.map((beneficiary) => ({
            ...beneficiary,
            backupDistribution: firstBeneficiaryBackupDistribution,
            subTrust: firstBeneficiarySubTrust
          }))
        );
      }
    },
    [
      setEstateBalanceBeneficiaries,
      beneficiaries,
      firstBeneficiaryBackupDistribution,
      firstBeneficiarySubTrust
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to use the same trust setup for all ${people}?`
        // TODO @LUKE -- would be nice to re-list what the setup is that we're asking to copy...
      }}
      name="users.0.gifts.remainderCopyTrustSetup"
      onSubmit={onSubmit}
    />
  );
};

export default RemainderSeparateTrustCopyStep;
