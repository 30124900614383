import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSelectOption } from "@components/archetypes/MultiSelect/partials/MultiSelectForm/types";
import MultiSelectArchetype from "@components/archetypes/MultiSelect";

const CeremonyStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const options: MultiSelectOption[] = useMemo(
    () => [
      {
        value: "Memorial service",
        label: formatMessage({
          defaultMessage: "Memorial service"
        })
      },
      {
        value: "Cemetery service",
        label: formatMessage({
          defaultMessage: "Cemetery service"
        })
      },
      {
        value: "Funeral service",
        label: formatMessage({
          defaultMessage: "Funeral service"
        })
      }
    ],
    [formatMessage]
  );

  return (
    <MultiSelectArchetype
      {...{ options }}
      name="users.0.finalArrangements.ceremonyType"
      stepLayout={{
        title: `What type of ceremony would you like?`,
        subText: `Select all ceremonies that you would like. If you don't want any of these ceremonies, leave unselected and click 'Save & Continue.'`
      }}
    />
  );
};

export default CeremonyStep;
