import { useCallback } from "react";

import { useDeleteFileMutation } from "@api/files/hooks";
import { useGetPlansQuery } from "@api/plans";

const useDeleteCustomFile = () => {
  const { refetch } = useGetPlansQuery();
  const [deleteFile] = useDeleteFileMutation({
    onCompleted: () => {
      refetch();
    }
  });

  const deleteCustomFile = useCallback(
    (id: number) => {
      deleteFile({
        variables: {
          id
        }
      });
    },
    [deleteFile]
  );

  return {
    deleteCustomFile
  };
};

export default useDeleteCustomFile;
