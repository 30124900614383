import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { PlanOption } from "@api/__generated__/globalTypes";

import TabNotAvailableMessage from "../../components/TabNotAvailableMessage";
import TabRestrictedMessage from "../../components/TabRestrictedMessage";
import DocsAndDataTitle from "../../components/DocsAndDataTitle";

import JointVisualizations from "./JointVisualizations";
import IndividualVisualizations from "./IndividualVisualizations";

const VisualTab = () => {
  const { myRoles, planCompleted, lastWill, trust } =
    useMemberPlansState();

  const canEdit = trust?.canEdit || lastWill?.canEdit;

  const isCouplePlan =
    (trust && trust.plan.planOption === PlanOption.COUPLE) ||
    trust?.plan.planOption === PlanOption.JOINT ||
    (lastWill && lastWill.plan.planOption === PlanOption.COUPLE);

  const isJointTrust =
    trust && trust.plan.planOption === PlanOption.JOINT;

  const sharingDrafterName =
    `${myRoles?.accountOwner?.firstName} ${myRoles?.accountOwner?.lastName}` ||
    myRoles?.accountOwner?.fullName;


  const primaryDrafterFirstName = trust
    ? trust?.plan.primaryDrafter.firstName
    : lastWill?.plan.primaryDrafter.firstName;

  const partnerType = trust
    ? trust?.plan.partnerType
    : lastWill?.plan.partnerType;

  const partnerFirstName = trust
    ? trust?.plan.partner?.firstName
    : lastWill?.plan.partner?.firstName;

  const userLastWill = useMemo(() => {
    if (trust) {
      return trust.primaryDrafterLastWill;
    }

    if (lastWill) {
      return lastWill.primaryDrafterLastWill;
    }

    return undefined;
  }, [trust, lastWill]);

  const partnerLastWill = useMemo(() => {
    if (trust && isCouplePlan) {
      return trust.partnerLastWill;
    }

    if (lastWill && isCouplePlan) {
      return lastWill.partnerLastWill;
    }

    return undefined;
  }, [trust, lastWill, isCouplePlan]);

  const primaryDrafterTrust = trust?.primaryDrafterTrust;
  const partnerTrust = trust?.partnerTrust;

  if (myRoles && !lastWill && !trust) {
    return (
      <>
        <DocsAndDataTitle />
        <TabRestrictedMessage
          tabName="Visualizations"
          message={`You do not have access to view ${sharingDrafterName}'s estate plan visualizations.`}
        />
      </>
    );
  }

  if (!planCompleted || (!lastWill && !trust)) {
    return (
      <>
        <DocsAndDataTitle />
        <TabNotAvailableMessage
          tabName="Visualizations"
          planDependencies="Revocable Living Trust or Last Will"
        />
      </>
    );
  }

  // Couple Plan in which we will need tab for each planMaker
  if ((trust || lastWill) && isCouplePlan && !isJointTrust)
    return (
      <>
        <DocsAndDataTitle />
        <Typography variant="h3" sx={{ mt: "2.5rem" }}>
          <FormattedMessage defaultMessage="Visualizations" />
        </Typography>
        <JointVisualizations
          userLastWill={userLastWill}
          userTrust={primaryDrafterTrust}
          userFirstName={primaryDrafterFirstName}
          partnerFirstName={partnerFirstName}
          partnerType={partnerType}
          partnerLastWill={partnerLastWill}
          partnerTrust={partnerTrust}
          isJointTrust={isJointTrust}
          canEdit={canEdit}
        />
      </>
    );

  // Single Plan or Joint Trust plan in which we need only one tab for both planmakers
  return (
    <>
      <DocsAndDataTitle />
      <Typography variant="h3" sx={{ mt: "2.5rem" }}>
        <FormattedMessage defaultMessage="Visualizations" />
      </Typography>
      <IndividualVisualizations
        userLastWill={userLastWill}
        userTrust={primaryDrafterTrust}
        userFirstName={primaryDrafterFirstName}
        partnerFirstName={partnerFirstName}
        partnerType={partnerType}
        partnerLastWill={partnerLastWill}
        isJointTrust={isJointTrust}
        canEdit={canEdit}
      />
    </>
  );
};

export default VisualTab;
