import { makeQueryHook, makeMutationHook } from "@utils/apollo-utils";

import { GET_ME_QUERY } from "./queries";
import { GetMeQuery } from "./__generated__/GetMeQuery";
import {
  UpdateUser,
  UpdateUserVariables
} from "./__generated__/UpdateUser";
import { UPDATE_USER } from "./mutations";

export const useGetMeQuery = makeQueryHook<GetMeQuery>(GET_ME_QUERY);

export const useUpdateUserMutation = makeMutationHook<
  UpdateUser,
  UpdateUserVariables
>(UPDATE_USER);
