import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { PlanOption } from "@api/__generated__/globalTypes";

import PowersToGrantStep from "../PowersToGrant";
import POAOverviewStep from "../POAOverview";
import CreatePOAStep from "../CreatePOA";
import DesignateAgentStep from "../DesignateAgent";
import NominateBackupAgentStep from "../NominateBackupAgent";
import DesignateBackupAgentStep from "../DesignateBackupAgent";
import PowersStep from "../Powers";
import PowersOptionalOverviewStep from "../PowersOptionalPreview";
import PowersDigitalAssetsStep from "../PowersDigitalAccess";
import PowersMakeGiftsStep from "../PowersMakeGifts";
import PowersMakeGiftsPeopleStep from "../PowersMakeGiftsPeople";
import PowersMakeGiftsPeopleListStep from "../PowersMakeGiftsPeopleList";
import PowersMakeGiftsSelfStep from "../PowersMakeGiftsSelf";
import PowersMakeGiftsLimitTypeStep from "../PowersMakeGiftsLimitType";
import PowersRelatedTxStep from "../PowersRelatedTx";
import PowersTransferToTrustStep from "../PowersTransferToTrust";
import PowersDelegatePowersStep from "../PowersDelegatePowers";
import PowersFiduciaryPowersStep from "../PowersFiduciaryPowers";
import AgentCompensationStep from "../AgentCompensation";
import AgentCompensationAmountStep from "../AgentCompensationAmount";
import TransitionPrimaryCompleteStep from "../TransitionPrimaryComplete";
import PartnerCreatePOAStep from "../PartnerCreatePOA";
import PartnerDesignateAgentStep from "../PartnerDesignateAgent";
import PartnerNominateBackupAgentStep from "../PartnerNominateBackupAgent";
import PartnerDesignateBackupAgentStep from "../PartnerDesignateBackupAgent";
import PartnerPowersStep from "../PartnerPowers";
import PartnerPowersToGrantStep from "../PartnerPowersToGrant";
import PartnerPowersOptionalOverviewStep from "../PartnerPowersOptionalPreview";
import PartnerPowersDigitalAssetsStep from "../PartnerPowersDigitalAccess";
import PartnerPowersMakeGiftsStep from "../PartnerPowersMakeGifts";
import PartnerPowersMakeGiftsSelfStep from "../PartnerPowersMakeGiftsSelf";
import PartnerPowersMakeGiftsPeopleStep from "../PartnerPowersMakeGiftsPeople";
import PartnerPowersMakeGiftsPeopleListStep from "../PartnerPowersMakeGiftsPeopleList";
import PartnerPowersMakeGiftsLimitTypeStep from "../PartnerPowersMakeGiftsLimitType";
import PartnerPowersMakeGiftsLimitAmountStep from "../PartnerPowersMakeGiftsLimitAmount";
import PartnerPowersRelatedTxStep from "../PartnerPowersRelatedTx";
import PartnerPowersTransferToTrustStep from "../PartnerPowersTransferToTrust";
import PartnerPowersDelegatePowersStep from "../PartnerPowersDelegatePowers";
import PartnerPowersFiduciaryPowersStep from "../PartnerPowersFiduciaryPowers";
import PartnerAgentCompensationStep from "../PartnerAgentCompensation";
import PartnerAgentCompensationAmountStep from "../PartnerAgentCompensationAmount";
import TrustReviewStep from "../Reviews/TrustReview";
import TrustReviewTabsStep from "../Reviews/TrustReviewTabs";
import PowersMakeGiftsLimitAmountStep from "../PowersMakeGiftsLimitAmount";
import POACompleteStep from "../POAComplete";
import PreviewPOADedicated from "../PreviewPOADedicated";

import { POWER_OF_ATTORNEY_ROUTES } from "./consts";

const powerOfAttorneySteps: PlanFormStep[] = [
  {
    path: POWER_OF_ATTORNEY_ROUTES.POADedicatedOverviewStep,
    element: <PreviewPOADedicated />,
    rules: POWER_OF_ATTORNEY_ROUTES.CreatePOA
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.CreatePOA,
    element: <CreatePOAStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.poa.include" }, true] },
        POWER_OF_ATTORNEY_ROUTES.DesignateAgent,
        {
          if: [
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
            POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA,
            POWER_OF_ATTORNEY_ROUTES.Review
          ]
        }
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.DesignateAgent,
    element: <DesignateAgentStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent,
    element: <NominateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.poa.nominateBackupAgent" }, true]
        },
        POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent,
        POWER_OF_ATTORNEY_ROUTES.Powers
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent,
    element: <DesignateBackupAgentStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.Powers
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.Powers,
    element: <PowersStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.poa.generalAuthority" }, true] },
        POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview,
        POWER_OF_ATTORNEY_ROUTES.PowersToGrant
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersToGrant,
    element: <PowersToGrantStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview,
    element: <PowersOptionalOverviewStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersDigitalAccess
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersDigitalAccess,
    element: <PowersDigitalAssetsStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersMakeGifts
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersMakeGifts,
    element: <PowersMakeGiftsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.additionalPowers.makeGifts" },
            true
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeople,
        POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeople,
    element: <PowersMakeGiftsPeopleStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.giftRecipients.type" },
            "certainPeople"
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeopleList,
        {
          if: [
            {
              "==": [
                { var: "users.0.poa.giftRecipients.type" },
                "Anyone"
              ]
            },
            POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsSelf,
            POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitType
          ]
        }
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeopleList,
    element: <PowersMakeGiftsPeopleListStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsSelf
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsSelf,
    element: <PowersMakeGiftsSelfStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitType
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitType,
    element: <PowersMakeGiftsLimitTypeStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.giftRecipients.limitType" },
            "Limit up to a certain amount"
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitAmount,
        POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitAmount,
    element: <PowersMakeGiftsLimitAmountStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx,
    element: <PowersRelatedTxStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersTransferToTrust
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersTransferToTrust,
    element: <PowersTransferToTrustStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersDelegatePowers
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersDelegatePowers,
    element: <PowersDelegatePowersStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PowersFiduciaryPowers
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PowersFiduciaryPowers,
    element: <PowersFiduciaryPowersStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.AgentCompensation
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.AgentCompensation,
    element: <AgentCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.compensation" },
            "Specify a compensation amount and reimbursement of expenses"
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.AgentCompensationAmount,
        POWER_OF_ATTORNEY_ROUTES.TransitionPrimaryComplete
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.AgentCompensationAmount,
    element: <AgentCompensationAmountStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.TransitionPrimaryComplete
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.TransitionPrimaryComplete,
    element: <TransitionPrimaryCompleteStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA,
        POWER_OF_ATTORNEY_ROUTES.Review
      ]
    }
  },
  // Partner POA Starts Here
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA,
    element: <PartnerCreatePOAStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.poa.include" }, true] },
        POWER_OF_ATTORNEY_ROUTES.PartnerDesignateAgent,
        POWER_OF_ATTORNEY_ROUTES.ReviewTabs
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerDesignateAgent,
    element: <PartnerDesignateAgentStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent,
    element: <PartnerNominateBackupAgentStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.poa.nominateBackupAgent" }, true] },
        POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent,
        POWER_OF_ATTORNEY_ROUTES.PartnerPowers
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent,
    element: <PartnerDesignateBackupAgentStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowers
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowers,
    element: <PartnerPowersStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.poa.generalAuthority" }, true] },
        POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview,
        POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant,
    element: <PartnerPowersToGrantStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview,
    element: <PartnerPowersOptionalOverviewStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersDigitalAccess
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersDigitalAccess,
    element: <PartnerPowersDigitalAssetsStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGifts
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGifts,
    element: <PartnerPowersMakeGiftsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.additionalPowers.makeGifts" },
            true
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeople,
        POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeople,
    element: <PartnerPowersMakeGiftsPeopleStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.giftRecipients.type" },
            "certainPeople"
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeopleList,
        {
          if: [
            {
              "==": [
                { var: "users.1.poa.giftRecipients.type" },
                "Anyone"
              ]
            },
            POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsSelf,
            POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitType
          ]
        }
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeopleList,
    element: <PartnerPowersMakeGiftsPeopleListStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsSelf
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsSelf,
    element: <PartnerPowersMakeGiftsSelfStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitType
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitType,
    element: <PartnerPowersMakeGiftsLimitTypeStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.giftRecipients.limitType" },
            "Limit up to a certain amount"
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitAmount,
        POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitAmount,
    element: <PartnerPowersMakeGiftsLimitAmountStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx,
    element: <PartnerPowersRelatedTxStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersTransferToTrust
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersTransferToTrust,
    element: <PartnerPowersTransferToTrustStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersDelegatePowers
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersDelegatePowers,
    element: <PartnerPowersDelegatePowersStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerPowersFiduciaryPowers
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerPowersFiduciaryPowers,
    element: <PartnerPowersFiduciaryPowersStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation,
    element: <PartnerAgentCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.compensation" },
            "Specify a compensation amount and reimbursement of expenses"
          ]
        },
        POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensationAmount,
        POWER_OF_ATTORNEY_ROUTES.ReviewTabs
      ]
    }
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensationAmount,
    element: <PartnerAgentCompensationAmountStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.ReviewTabs
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.Review,
    element: <TrustReviewStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.POAComplete
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.ReviewTabs,
    element: <TrustReviewTabsStep />,
    rules: POWER_OF_ATTORNEY_ROUTES.POAComplete
  },
  {
    path: POWER_OF_ATTORNEY_ROUTES.POAComplete,
    element: <POACompleteStep />
  },
  {
    path: "*",
    element: <Navigate to={POWER_OF_ATTORNEY_ROUTES.POAOverview} />
  }
];

export default powerOfAttorneySteps;
