import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { Pet } from "@pages/PetPlan/types";

const DesignateSameInstructionsStep: React.FC = () => {
  const { value: firstPetFeedingInstructions } = useFormField<
    string | undefined
  >("pets.0.feedingInstructions");

  const { value: firstPetCareInstructions } = useFormField<
    string | undefined
  >("pets.0.careInstructions");

  const { value: pets, setValue: setPets } =
    useFormField<Pet[]>("pets");

  const onSubmit = useCallback(
    ({ sameCareInstructions }: SingleSelectFormValues) => {
      if (parseBoolean(sameCareInstructions)) {
        setPets(
          pets.map((pet) => ({
            ...pet,
            feedingInstructions: firstPetFeedingInstructions,
            careInstructions: firstPetCareInstructions
          }))
        );
      }
    },
    [
      firstPetFeedingInstructions,
      firstPetCareInstructions,
      setPets,
      pets
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Should we use the same food and exercise instructions for your other pets too?`
      }}
      {...{ onSubmit }}
      name="sameCareInstructions"
    />
  );
};

export default DesignateSameInstructionsStep;
