import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { RelationshipStatus } from "@setup/enums";
import { PlanOption } from "@api/__generated__/globalTypes";

import { TRUST_GUARDIANS_ROUTES } from "../Guardians/consts";
// Steps Borrowed from Last Will Experience
import BasicsStep from "../../../LastWill/sections/AllAboutYou/steps/Basics";
import HavingChildrenStep from "../../../LastWill/sections/AllAboutYou/steps/HavingChildren";
import ChildrenDetailsStep from "../../../LastWill/sections/AllAboutYou/steps/ChildrenDetails";
import Interstitial1Step from "../../../LastWill/sections/AllAboutYou/steps/Interstitial1";
import PartnerStep from "../../../LastWill/sections/AllAboutYou/steps/Partner";
import PartnerDetailsStep from "../../../LastWill/sections/AllAboutYou/steps/PartnerDetails";
import ReviewStep from "../../../LastWill/sections/AllAboutYou/steps/Review";

// Unique Steps for Trust
import SectionsPreviewStep from "./steps/Preview";
import PlanSelectionStep from "./steps/PlanSelection";
import ConfirmTrustTypeStep from "./steps/ConfirmTrustType";
import { TRUST_ALL_ABOUT_YOU_ROUTES } from "./consts";

const allAboutYouSteps: PlanFormStep[] = [
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: TRUST_ALL_ABOUT_YOU_ROUTES.Basics
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.Basics,
    element: <BasicsStep />,
    rules: TRUST_ALL_ABOUT_YOU_ROUTES.Interstitial1
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.Interstitial1,
    element: <Interstitial1Step />,
    rules: TRUST_ALL_ABOUT_YOU_ROUTES.Partner
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.Partner,
    element: <PartnerStep />,
    rules: {
      if: [
        {
          or: [
            {
              "==": [
                { var: "users.0.relationshipStatus" },
                RelationshipStatus.SINGLE
              ]
            },
            {
              "==": [
                { var: "users.0.relationshipStatus" },
                RelationshipStatus.DIVORCED
              ]
            }
          ]
        },
        TRUST_ALL_ABOUT_YOU_ROUTES.HavingChildren,
        TRUST_ALL_ABOUT_YOU_ROUTES.PlanSelection
      ]
    }
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.PlanSelection,
    element: <PlanSelectionStep />,
    rules: TRUST_ALL_ABOUT_YOU_ROUTES.PartnerDetails
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.PartnerDetails,
    element: <PartnerDetailsStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        TRUST_ALL_ABOUT_YOU_ROUTES.ConfirmTrustType,
        TRUST_ALL_ABOUT_YOU_ROUTES.HavingChildren
      ]
    }
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.ConfirmTrustType,
    element: <ConfirmTrustTypeStep />,
    rules: TRUST_ALL_ABOUT_YOU_ROUTES.HavingChildren
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.HavingChildren,
    element: <HavingChildrenStep />,
    rules: {
      if: [
        { "==": [{ var: "haveChildren" }, true] },
        TRUST_ALL_ABOUT_YOU_ROUTES.ChildrenDetails,
        TRUST_ALL_ABOUT_YOU_ROUTES.Review
      ]
    }
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.ChildrenDetails,
    element: <ChildrenDetailsStep />,
    rules: TRUST_ALL_ABOUT_YOU_ROUTES.Review
  },
  {
    path: TRUST_ALL_ABOUT_YOU_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${TRUST_SECTION_ROUTES.Guardians}/${TRUST_GUARDIANS_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={TRUST_ALL_ABOUT_YOU_ROUTES.Preview} />
  }
];

export default allAboutYouSteps;
