import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import {
  Beneficiary,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import { parseBoolean } from "@utils/parseBoolean";

const PartnerResidueToPartnerStep: React.FC = () => {
  const { value: residuePercentRemaining } = useFormField<number>(
    "partnerResiduePercentRemaining"
  );

  const {
    value: residuePercentAllocated,
    setValue: setResiduePercentAllocated
  } = useFormField<number>("partnerResiduePercentAllocated");

  const { setValue: setResiduePercentRemaining } = useFormField(
    "partnerPercentRemaining"
  );

  const { value: giveSpecificGifts } = useFormField(
    "users.1.gifts.giveSpecificGifts"
  );

  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter, partner]
  } = values;

  const { value: residueGift, setValue: setResidueGift } =
    useFormField<Beneficiary[]>(
      "users.1.assets.estateBalance.0.beneficiaries"
    );

  const onSubmit = useCallback(
    ({ partnerResidueBalanceToPartner }: SingleSelectFormValues) => {
      /* 
      If yes, clear any previously stored residue gifts and create the 
      residue gift giving everything to the partner
      */
      if (parseBoolean(partnerResidueBalanceToPartner)) {
        setResidueGift([]);
        setResidueGift([
          {
            name:
              primaryDrafter.firstName &&
              " " &&
              primaryDrafter.lastName,
            percentageValue: residuePercentRemaining
          }
        ]);
        setResiduePercentAllocated(
          residuePercentAllocated + residuePercentRemaining
        );
        setResiduePercentRemaining(0);
      }

      const allocatedPercentForPartner =
        residueGift?.find(
          (gift) =>
            gift.name === `${partner.firstName} ${partner.lastName}`
        )?.percentageValue ?? 0;

      if (!parseBoolean(partnerResidueBalanceToPartner)) {
        setResiduePercentRemaining(
          residuePercentRemaining + allocatedPercentForPartner
        );
        setResiduePercentAllocated(
          residuePercentAllocated - allocatedPercentForPartner
        );
        setResidueGift([]);
      }
    },
    [
      partner.firstName,
      partner.lastName,
      primaryDrafter.firstName,
      primaryDrafter.lastName,
      residueGift,
      residuePercentAllocated,
      residuePercentRemaining,
      setResidueGift,
      setResiduePercentAllocated,
      setResiduePercentRemaining
    ]
  );

  if (giveSpecificGifts) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `After completing your specific gifts, does ${partner.firstName} want the balance of ${partner.firstName}'s assets to go to ${primaryDrafter.firstName}?`
        }}
        {...{ onSubmit }}
        name="partnerResidueBalanceToPartner"
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partner.firstName} want the balance of ${partner.firstName}'s assets to go to ${primaryDrafter.firstName}?`
      }}
      {...{ onSubmit }}
      name="partnerResidueBalanceToPartner"
    />
  );
};

export default PartnerResidueToPartnerStep;
