/* eslint-disable camelcase */
import { groupBy } from "lodash";

import { PetProtection } from "@api/plans/__generated__/PetProtection";

const includeMedicalNote = (pets: PetProtection | null): boolean => {
  const medicalNotes = pets?.pets?.flatMap(
    (pet) => pet?.medicalInstructions
  );
  const groupedNotes = groupBy(medicalNotes);

  return Object.values(groupedNotes).every((note) => note === null);
};

export default includeMedicalNote;
