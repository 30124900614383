import { useCallback } from "react";
import * as yup from "yup";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { useUpdateUserMutation } from "@api/me";
import { AdvisorShortUpdateInput } from "@api/__generated__/globalTypes";

import { AdvisorTutorialProps } from "../types";

export const useAdvisorInfoForm = ({
  onNext
}: AdvisorTutorialProps) => {
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name")
  });

  const methods =
    useFormWithYup<AdvisorShortUpdateInput>(validationSchema);

  const [updateUser, { loading, error, reset }] =
    useUpdateUserMutation();

  const onFormSubmit = useCallback(
    (data: AdvisorShortUpdateInput) => {
      updateUser({
        variables: {
          input: {
            ...data
          }
        }
      });
      onNext();
    },
    [updateUser, onNext]
  );

  return {
    loading,
    error,
    reset,
    handleSubmit: methods.handleSubmit(onFormSubmit),
    methods
  };
};
