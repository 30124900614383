import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import Image from "@components/Image";
import StepLayout from "@components/StepLayout";
import { PlanType } from "@api/__generated__/globalTypes";
import InfoModal from "@components/InfoModal";

import { useEstatePlansMatchmakingItems } from "./hooks";
import { getMatchmakingResults } from "./utils";
import { EstatePlansMatchmakingFormValues } from "./types";
import QuizNextSteps from "./partials/NextSteps";
import WhatToExpectPartial from "./partials/WhatToExpect";

const MatchMakingResults: React.FC = () => {
  const location = useLocation();

  const data: EstatePlansMatchmakingFormValues =
    (location.state as EstatePlansMatchmakingFormValues) || [];

  const items = useEstatePlansMatchmakingItems();

  const { planType, editLink, image, recommendation, rationale } =
    getMatchmakingResults(data, items);

  const ctaTitle = useMemo(() => {
    if (planType === PlanType.TRUST) return "Trust";

    if (planType === PlanType.PET_PROTECTION) {
      return "Pet Plan";
    }

    return "Last Will";
  }, [planType]);

  const moreInfoContent = <WhatToExpectPartial planType={planType} />;

  return (
    <StepLayout
      title={`Our ${recommendation} package is the best fit.`}
      hideImageOnMobile
    >
      <Box textAlign="center">
        <Image
          height="64px"
          width="64px"
          src={image.src}
          alt={image.alt}
        />
      </Box>
      <Box maxWidth="29.375rem" width="100%" m="0 auto">
        <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
          {rationale}
        </Typography>
        <QuizNextSteps />
        <Grid
          container
          spacing={2.5}
          mt={{ xs: 3.75, md: 2.5 }}
          sx={{
            "> .MuiGrid-root": {
              paddingTop: 0
            }
          }}
        >
          {moreInfoContent && (
            <Grid item xs={12} md={6} paddingBottom="1.5rem">
              <Button
                color="inherit"
                variant="outlined"
                sx={{
                  width: "100%",
                  minWidth: "auto"
                }}
              >
                <InfoModal
                  title={`What to Expect with Your ${recommendation}`}
                  togglerLabel="LEARN MORE"
                  content={moreInfoContent}
                />
              </Button>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                minWidth: "auto"
              }}
              href={editLink}
            >
              CREATE MY {ctaTitle}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </StepLayout>
  );
};

export default MatchMakingResults;
