import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { numberWithCommas } from "@utils/misc";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";

import { LAST_WILL_MANAGEMENT_ROUTES } from "../../consts";

export const usePartnerManagementReviewTab = ({
  values
}: {
  values: LastWillPlanValues;
}) => {
  const {
    users: [, partner]
  } = values;

  const {
    personalRepresentatives: partnerPersonalRepresentatives = [],
    backupPersonalRepresentatives:
      partnerBackupPersonalRepresentatives = []
  } = partner?.lastWill || {};

  const { formatMessage } = useIntl();

  const partnerRepresentativesInfo = useMemo<ReviewItem | undefined>(
    () =>
      partnerPersonalRepresentatives.length > 0
        ? {
            title: formatMessage({
              defaultMessage: `Primary Representative:`
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Management,
              LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerRepresentative
            ]),
            items: [
              [
                `${partnerPersonalRepresentatives[0].name}`,
                `Relationship: ${partnerPersonalRepresentatives[0].relationship}`
              ]
            ]
          }
        : undefined,
    [formatMessage, partnerPersonalRepresentatives]
  );

  const partnerBackupRepresentativesInfo = useMemo<
    ReviewItem | undefined
  >(
    () =>
      partnerBackupPersonalRepresentatives.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Backup Representative:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Management,
              LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerBackupRepresentative
            ]),
            description:
              "(Backup Representatives will serve in the order named)",
            isFullWidth: true,
            items: partnerBackupPersonalRepresentatives.map(
              (backupRep) => [
                backupRep.name,
                `Relationship: ${backupRep.relationship}`
              ]
            )
          }
        : undefined,
    [formatMessage, partnerBackupPersonalRepresentatives]
  );

  const partnerRepresentativeCompensationInfo = useMemo<
    ReviewItem | undefined
  >(
    () =>
      partner.lastWill?.personalRepresentatives?.length
        ? {
            title: formatMessage({
              defaultMessage: "Representative Compensation:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Management,
              LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensation
            ]),
            isFullWidth: true,
            items: [
              [
                partner.lastWill?.representativesCompensationType?.toString() ===
                  "Reasonable compensation and reimbursement of expenses" ||
                partner.lastWill?.representativesCompensationType?.toString() ===
                  "No compensation. Reimbursement of reasonable expenses only"
                  ? partner.lastWill?.representativesCompensationType?.toString()
                  : "",
                partner.lastWill?.representativesCompensationDollar
                  ? `Compensation of $${numberWithCommas(
                      partner.lastWill?.representativesCompensationDollar?.toString()
                    )}, plus reimbursement of reasonable expenses.`
                  : "",
                partner.lastWill?.representativesCompensationPercent
                  ? `Compensation of up to ${partner.lastWill?.representativesCompensationPercent?.toString()}% of my estate, plus reimbursement of reasonable expenses.`
                  : ""
              ].filter((item): item is string => Boolean(item))
            ]
          }
        : undefined,
    [partner, formatMessage]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        partnerRepresentativesInfo,
        partnerBackupRepresentativesInfo,
        partnerRepresentativeCompensationInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      partnerRepresentativesInfo,
      partnerBackupRepresentativesInfo,
      partnerRepresentativeCompensationInfo
    ]
  );

  return items;
};
