import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import {
  PartnerType,
  PersonalPropertyBalanceDistributionType
} from "@api/__generated__/globalTypes";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

const PartnerPersonalPropertyBalanceTypeStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { users, children } = values;
  const [primaryDrafter, partner] = users;
  const everythingToPartner = partner.gifts?.everythingGoToPartner;

  const partnerFirstName = partner.firstName;
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");
  const lowerPartnerType = partnerType.toLowerCase();

  const { setValue: setPersonalPropertyBalanceValue } = useFormField(
    "users.1.assets.personalPropertyBalance"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const personalPropertyBalanceType = get(
        data,
        "users.1.gifts.personalPropertyBalanceType"
      );

      if (
        personalPropertyBalanceType !==
        PersonalPropertyBalanceDistributionType.OTHER_PEOPLE
      ) {
        setPersonalPropertyBalanceValue([]);
      }
    },
    [setPersonalPropertyBalanceValue]
  );

  const useItems = [
    {
      value:
        PersonalPropertyBalanceDistributionType.NAMED_BENEFICIARIES,
      label:
        "Distribute among my named beneficiaries as they may agree"
    }
  ];

  if (
    partnerType !== PartnerType.SINGLE &&
    !everythingToPartner &&
    children &&
    children.length > 0
  ) {
    useItems.push({
      value:
        PersonalPropertyBalanceDistributionType.PARTNER_AND_CHILDREN,
      label: `Distribute among my ${lowerPartnerType} and children as they may agree`
    });
  }

  if (partnerType !== PartnerType.SINGLE && !everythingToPartner) {
    useItems.push({
      value: PersonalPropertyBalanceDistributionType.PARTNER,
      label: `Distribute to my ${lowerPartnerType}`
    });
  }

  if (children && children.length > 0) {
    useItems.push({
      value: PersonalPropertyBalanceDistributionType.CHILDREN,
      label: "Distribute among my children as they may agree"
    });
  }

  useItems.push({
    value: PersonalPropertyBalanceDistributionType.OTHER_PEOPLE,
    label: "Distribute to another person or group of people"
  });

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How does ${partnerFirstName} want to distribute the balance of ${partnerFirstName}'s personal property?`,
        subText: `The balance of your personal property is made up of all personal property items that you are not designating as a specific gift.${
          everythingToPartner
            ? ` You'll make this distribution only if ${primaryDrafter.firstName} does not survive you.`
            : ""
        }`,
        moreInfoModalProps: {
          togglerLabel: "Learn more.",
          title: "Distributing Your Personal Property Balance",
          content: (
            <p>
              Your personal property balance is made up of all
              personal property items that you are not designating as
              a specific gift.
              <br />
              <br />
              <strong>
                What constitutes a personal property item?{" "}
              </strong>{" "}
              Personal property items refer to physical objects that
              you can physically possess, touch, and move. These are
              things like cars, boats, jewelry, artwork, and other
              household items.
              <br />
              <br />
              <strong>
                How do I know if a personal property item will be
                distributed with my personal property balance?
              </strong>{" "}
              Except for those items you specifically identified and
              added a beneficiary in the prior step, all your
              remaining personal property items will be distributed
              with your personal property balance.
            </p>
          )
        }
      }}
      {...{ onSubmit }}
      name="users.1.gifts.personalPropertyBalanceType"
      items={useItems}
    />
  );
};

export default PartnerPersonalPropertyBalanceTypeStep;
