import { Button, ButtonProps } from "@mui/material";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";

const AddAssetButton: React.FC<ButtonProps> = ({
  onClick,
  ...restButtonProps
}) => {
  const { variant, color, title } = restButtonProps;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Button
        variant={variant}
        color={color}
        sx={{
          flex: "1",
          borderWidth: "2px",
          fontWeight: "500",
          fontSize: "14px",
          marginTop: "1.1rem"
        }}
        {...{ onClick }}
      >
        {title || <FormattedMessage defaultMessage="+ ADD ASSET" />}
      </Button>
    </Box>
  );
};

export default AddAssetButton;
