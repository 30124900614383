import { Box, Stack, Typography } from "@mui/material";

import IconListItem from "./partials/IconListItem";
import { RoadmapProps } from "./types";

const IconList: React.FC<RoadmapProps> = ({ title, items }) => (
  <Box>
    {title && (
      <Typography variant="h5" fontWeight={500} mb={1.25}>
        {title}
      </Typography>
    )}
    <Stack>
      {items.map(({ label, ...item }) => (
        <IconListItem key={label} {...{ label, ...item }} />
      ))}
    </Stack>
  </Box>
);

export default IconList;
