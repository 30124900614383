import React from "react";
import { Tab, Tabs as MaterialTabs } from "@mui/material";

import TabPanel from "./partials/TabPanel";
import { ControlledTabsProps } from "./types";

const ControlledTabs: React.FC<ControlledTabsProps> = ({
  items,
  currentTab,
  handleChange
}) => (
  <>
    <MaterialTabs
      {...{ value: currentTab }}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        mb: 2.5
      }}
    >
      {items.map(({ label }) => (
        <Tab key={label} {...{ label }} />
      ))}
    </MaterialTabs>
    {items.map(({ label, content }, index) => (
      <TabPanel key={label} {...{ value: currentTab, index }}>
        {content}
      </TabPanel>
    ))}
  </>
);

export default ControlledTabs;
