import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const PartnerDesignateBackupAgentStep: React.FC = () => (
  <PersonDetailsArrayArchetype
    name="users.1.poa.successorAgents"
    stepLayout={{
      title: "Who should be the successor agent?"
    }}
    personRole="Successor Agent"
    addBtnLabel="Add next successor agent"
    removeBtnLabel="Remove successor agent"
  />
);

export default PartnerDesignateBackupAgentStep;
