import { noop } from "lodash";
import { createContext, useContext } from "react";

import { EmailSentState } from "./type";

const EmailSentStateContext = createContext<EmailSentState>({
  setShowEmailSentMessage: noop,
  onCompleted: noop,
  showEmailSentMessage: false
});

const useEmailSentState = () => useContext(EmailSentStateContext);

export { EmailSentStateContext, useEmailSentState };
