import React from "react";

import { MARKETING_PAGE_URL } from "@setup/consts/misc";

import SvgImage from "../SvgImage";

import { LogoProps } from "./types";
import * as Styled from "./styles";

const Logo: React.FC<LogoProps> = ({
  logoMobile,
  logoDesktop,
  showDesktopOnly
}) => {
  const {
    svgContent: mobileSvgContent,
    alt: mobileAlt,
    src: mobileSrc
  } = logoMobile || {};
  const {
    alt: desktopAlt,
    svgContent: desktopSvgContent,
    src: desktopSrc
  } = logoDesktop || {};
  const desktopLogo = (
    <Styled.LogoWrapper href={MARKETING_PAGE_URL}>
      <SvgImage
        content={desktopSvgContent}
        alt={desktopAlt}
        src={desktopSrc}
      />
    </Styled.LogoWrapper>
  );

  return showDesktopOnly ? (
    desktopLogo
  ) : (
    <>
      <Styled.DesktopContainer>{desktopLogo}</Styled.DesktopContainer>
      <Styled.MobileContainer>
        <Styled.LogoWrapper href={MARKETING_PAGE_URL}>
          <SvgImage
            content={mobileSvgContent}
            alt={mobileAlt}
            src={mobileSrc}
          />
        </Styled.LogoWrapper>
      </Styled.MobileContainer>
    </>
  );
};

export default Logo;
