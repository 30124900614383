import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Content = styled(Box)(() => ({
  display: "flex",
  alignItems: "top",
  "& .MuiSvgIcon-root": {
    color: "currentColor",
    fontSize: "1.5rem",
    marginRight: "0.625rem",
    flexShrink: 0,
    marginBottom: "0.25rem",
    marginTop: "0.25rem"
  }
}));
