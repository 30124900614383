import { makeQueryHook } from "@utils/apollo-utils";

import { GET_DRAFT_DOCUMENTS } from "./queries";
import {
  GetDraftDocuments,
  GetDraftDocumentsVariables
} from "./__generated__/GetDraftDocuments";

export const useGetDraftDocumentsQuery = makeQueryHook<
  GetDraftDocuments,
  GetDraftDocumentsVariables
>(GET_DRAFT_DOCUMENTS);
