import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthLayout from "@components/AuthLayout";
import Image from "@components/Image";
import imageAuthFail from "@assets/images/image-auth-fail.gif";
import { ROUTES } from "@setup/consts/routes";
import PageTitle from "@components/PageTitle";

import {
  useSignInForm,
  useSignInFormValidationSchema
} from "../SignIn/hooks";
import { SignInFormDataProps } from "../SignIn/types";

const AuthFail: React.FC = () => {
  const intl = useIntl();

  const validationSchema = useSignInFormValidationSchema();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError
  } = useForm<SignInFormDataProps>({
    resolver: yupResolver(validationSchema)
  });
  const { errorMessage, handleLogin } = useSignInForm(setError);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          defaultMessage: "Sign Up Failed"
        })}
      />
      <AuthLayout
        title={intl.formatMessage({
          defaultMessage: "Sign Up Failed"
        })}
        isLoading={isSubmitting}
      >
        <Image
          src={imageAuthFail}
          alt={intl.formatMessage({
            defaultMessage: "Sign Up Failed"
          })}
          width={134}
          height={100}
        />
        <Typography
          variant="body2"
          sx={{
            textAlign: {
              xs: "left",
              sm: "center"
            }
          }}
          m="1.25rem 0"
        >
          <FormattedMessage defaultMessage="That email address is already linked to a different account. Try logging in using your user name and password. If you have forgotten your password, try recovering it." />
        </Typography>

        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
          onSubmit={handleSubmit(handleLogin)}
        >
          <FormControl sx={{ width: "100%", mb: "1.25rem" }}>
            <TextField
              label={
                <FormattedMessage defaultMessage="Email Address" />
              }
              variant="standard"
              type="email"
              error={!!errors.email}
              helperText={errors.email?.message}
              {...register("email", { required: true })}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", mb: "1.25rem" }}>
            <TextField
              label={<FormattedMessage defaultMessage="Password" />}
              variant="standard"
              type="password"
              error={!!errors.password}
              helperText={errors.password?.message}
              {...register("password", { required: true })}
            />
          </FormControl>
          {errorMessage && (
            <FormHelperText sx={{ ml: 0, mb: 2.5 }}>
              {errorMessage}
            </FormHelperText>
          )}
          <Button variant="contained" size="medium" type="submit">
            <FormattedMessage defaultMessage="Sign in" />
          </Button>
        </Box>
        <Button
          variant="text"
          href={ROUTES.ResetPassword}
          color="inherit"
          size="small"
          sx={{ fontSize: "0.875rem", mt: 4 }}
        >
          <FormattedMessage defaultMessage="Reset password" />
        </Button>
      </AuthLayout>
    </>
  );
};

export default AuthFail;
