import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";

import { OrderedListItems } from "@components/StepLayout/types";

export const useMaritalGiftProcessInstructions =
  (): OrderedListItems[] => {
    const intl = useIntl();

    return useMemo(
      () => [
        {
          title: intl.formatMessage({
            defaultMessage: "Marital transfers"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "If your spouse survives you, you have the option to transfer an unlimited amount of assets to your spouse without incurring any tax or penalty."
              })}
            </Typography>
          )
        },
        {
          title: intl.formatMessage({
            defaultMessage: "Your Residuary Estate"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "This represents all your assets that are left over after making any specific gifts. For most willmakers, this is the largest portion of the estate."
              })}
            </Typography>
          )
        },
        {
          title: intl.formatMessage({
            defaultMessage:
              "Specific Gifts to Individuals and Charities"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "If there is a specific asset that you want someone to have -- a house, car, family heirloom, or something else -- you can designate that gift here."
              })}
            </Typography>
          )
        }
      ],
      [intl]
    );
  };

export const useSingleGiftProcessInstructions =
  (): OrderedListItems[] => {
    const intl = useIntl();

    return useMemo(
      () => [
        {
          title: intl.formatMessage({
            defaultMessage: "Your Residuary Estate"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "This represents all your assets that are left over after making any specific gifts. For most willmakers, this is the largest portion of the estate."
              })}
            </Typography>
          )
        },
        {
          title: intl.formatMessage({
            defaultMessage:
              "Specific Gifts to Individuals and Charities"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "If there is a specific asset that you want someone to have -- a house, car, family heirloom, or something else -- you can designate that gift here."
              })}
            </Typography>
          )
        }
      ],
      [intl]
    );
  };

export const useRegisteredGiftProcessInstructions =
  (): OrderedListItems[] => {
    const intl = useIntl();

    return useMemo(
      () => [
        {
          title: intl.formatMessage({
            defaultMessage: "Gifts to Your Partner"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "If your registered domestic partner survives you, you have the option to transfer an unlimited amount of assets to your partner without incurring any tax or penalty."
              })}
            </Typography>
          )
        },
        {
          title: intl.formatMessage({
            defaultMessage: "Your Residuary Estate"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "This represents all your assets that are left over after making any specific gifts. For most willmakers, this is the largest portion of the estate."
              })}
            </Typography>
          )
        },
        {
          title: intl.formatMessage({
            defaultMessage:
              "Specific Gifts to Individuals and Charities"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "If there is a specific asset that you want someone to have -- a house, car, family heirloom, or something else -- you can designate that gift here."
              })}
            </Typography>
          )
        }
      ],
      [intl]
    );
  };

export const usePartnerGiftProcessInstructions =
  (): OrderedListItems[] => {
    const intl = useIntl();

    return useMemo(
      () => [
        {
          title: intl.formatMessage({
            defaultMessage: "Gifts to Your Partner"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "You can leave some or all of your estate to your partner. Unless you are married, however, the unlimited marital deduction will not apply. Federal or state taxes will be calculated in the same way as if you gave to any other person."
              })}
            </Typography>
          )
        },
        {
          title: intl.formatMessage({
            defaultMessage: "Your Residuary Estate"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "This represents all your assets that are left over after making any specific gifts. For most willmakers, this is the largest portion of the estate."
              })}
            </Typography>
          )
        },
        {
          title: intl.formatMessage({
            defaultMessage:
              "Specific Gifts to Individuals and Charities"
          }),
          content: (
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "If there is a specific asset that you want someone to have -- a house, car, family heirloom, or something else -- you can designate that gift here."
              })}
            </Typography>
          )
        }
      ],
      [intl]
    );
  };
