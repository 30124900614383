import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";

import { usePrimaryDrafterManagementReviewTab } from "./Reviews/usePrimaryDrafterManagementReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const items = usePrimaryDrafterManagementReviewTab({ values });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Beautiful. Here's what we've got for management of your Trust.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
