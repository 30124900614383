import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import { FileType } from "@api/__generated__/globalTypes";
import { ROUTES } from "@setup/consts/routes";
import Image from "@components/Image";
import HIPPAImage from "@assets/images/docIcons/DocumentIconHIPAA.png";
import { File } from "@api/files/__generated__/File";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";
import { PET_SPECIAL_POWERS_ROUTES } from "@pages/PetPlan/sections/SpecialPowers/consts";

import { useSlideActions } from "../hooks";
import { getLinkFullUrl } from "../utils";

const editLink = getLinkFullUrl([
  ROUTES.PetPlan,
  PET_SECTION_ROUTES.SpecialPowers,
  PET_SPECIAL_POWERS_ROUTES.AllowHealthAccess
]);

const PetProtectionSlide = () => {
  const { petProtection } = useMemberPlansState();
  const planFile = useMemo(
    () =>
      petProtection?.plan?.files?.find(
        (f: File) => f.type === FileType.HIPAA_DOCUMENT
      ),
    [petProtection]
  );

  const actions = useSlideActions({
    planFile,
    editLink,
    memberPlan: petProtection
  });
  const navigate = useNavigate();

  const handleGoToEdit = useCallback(() => {
    if (editLink) {
      navigate(editLink);
    }
  }, [navigate]);

  const renderTop = useCallback(
    () =>
      petProtection && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography align="center" variant="h5">
            <FormattedMessage defaultMessage="Limited Pet HIPPA Authorization" />
          </Typography>
          <Typography align="center" variant="h5">
            <FormattedMessage
              defaultMessage="of {fullName}"
              values={{
                fullName: petProtection.plan.primaryDrafter?.fullName
              }}
            />{" "}
          </Typography>
          <Box sx={{ mt: "1rem" }}>
            <Image
              height="64px"
              width="64px"
              src={HIPPAImage}
              alt="HIPPA icon"
            />
          </Box>
          <Typography mt="1.2rem" align="center" fontSize="1rem">
            <FormattedMessage defaultMessage="Authorize limited access to your health information in the event of a pet emergency" />
          </Typography>
        </Box>
      ),
    [petProtection]
  );

  if (!petProtection?.petProtection) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {renderTop()}
        <Typography mt="1.5rem" align="center" fontSize="15px">
          <FormattedMessage defaultMessage="You didn't create a Limited Pet HIPPA release yet." />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleGoToEdit}
            variant="contained"
            sx={{
              flex: "1",
              borderWidth: "2px",
              fontWeight: "500",
              fontSize: "14px",
              marginTop: "1.1rem"
            }}
          >
            <FormattedMessage defaultMessage="CREATE HIPPA RELEASE" />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {renderTop()}
      <Grid
        container
        item
        xs={12}
        mt="1.5rem"
        justifyContent="space-around"
      >
        {actions.map(({ icon, label }) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer"
            }}
            key={label}
          >
            {icon}
            <Typography fontSize="0.875rem">{label}</Typography>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default PetProtectionSlide;
