import * as yup from "yup";
import { useCallback, useMemo } from "react";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  Charity,
  CharityFormValues,
  UseCharityArchetypeReturnType
} from "./types";

export const useCharitySchema = (
  name: string
): yup.SchemaOf<Charity> =>
  useMemo(() => {
    const basicShape = yup
      .object()
      .shape({
        name: yup.string().required().nullable(),
        ein: yup.string().required(),
        address: yup.string().required()
      })
      .required();

    return makeYupSchema(basicShape, name);
  }, [name]);

const useCharityArchetype = (
  name: string,
  onFormSubmit?: (data: CharityFormValues) => void
): UseCharityArchetypeReturnType => {
  const { goBack, saveAndContinue } = usePlanForm();
  const schema = useCharitySchema(name);
  const controls = useFormWithYup<CharityFormValues>(schema);

  const onSubmit = useCallback(
    (data: CharityFormValues) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useCharityArchetype };
