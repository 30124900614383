export const parseBoolean = (
  value: boolean | undefined | null | string
): boolean => {
  if (typeof value === "boolean") {
    return value;
  }

  if (typeof value === "string") {
    return value === true.toString();
  }

  return false;
};
