/* eslint-disable react/no-unescaped-entities */
import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPowersRelatedTxStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to allow their agent to engage in related party transactions?`,
        subText: `A "related party transaction" is one in which your agent or one of your agent's heirs may profit. Your agent will still be responsible for acting in your best interest.`,
        moreInfoModalProps: {
          title: "Authority to Engage in Related Party Transactions",
          togglerLabel: "Learn more",
          content: (
            <p>
              A "related party transaction" is a transaction in which
              your agent or someone close to your agent may profit.
              <br />
              <br />
              As a result of this potential conflict of interest,
              agents are generally discouraged from engaging in
              related party transactions. If you are comfortable with
              your agent managing this potential conflict of interest,
              you can clarify that your agent's authority extends to
              these related party situations.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.1.poa.additionalPowers.relatedTx"
    />
  );
};

export default PartnerPowersRelatedTxStep;
