import { FormattedMessage } from "react-intl";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ChecklistIcon from "@mui/icons-material/Checklist";

import { EstateData } from "@pages/EstateAdminQuiz/types";
import { DocumentType } from "@pages/EstateAdminQuiz/enums";
import InfoModal from "@components/InfoModal";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ROUTES } from "@setup/consts/routes";
import { EXPERT_MATCHING_ROUTES } from "@pages/EstateAdminQuiz/consts";

import { EXPERT_MATCHING_RESULTS } from "../../consts";
import useStateResults from "../../stateResults";

const AdministrationResults: React.FC<EstateData> = ({
  state,
  documentType,
  assetValue,
  probateAssetValue
}) => {
  const items = useStateResults(
    state || "",
    documentType || DocumentType.INTESTATE,
    assetValue || "",
    probateAssetValue || ""
  );

  const { recommendation, rationale, bullets } = items;

  return (
    <>
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        {recommendation} is the best fit.
        <br />
        <ChecklistIcon />
      </Typography>
      <Box maxWidth="29.375rem" width="100%" m="0 auto">
        <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
          {rationale}
        </Typography>
        {bullets && (
          <Typography textAlign="left">
            {`You can learn about this recommendation and get a preliminary sense of what to expect for this specific estate by clicking the "Learn More" button below.`}
            <br /> <br />
            {`When you're ready, select "Get My Expert" for a free consultation with one of our estate administration experts.`}
          </Typography>
        )}
        <Grid
          container
          spacing={2.5}
          mt={{ xs: 3.75, md: 2.5 }}
          sx={{
            "> .MuiGrid-root": {
              paddingTop: 0
            }
          }}
        >
          {bullets && (
            <Grid item xs={12} md={6} paddingBottom="1.5rem">
              <Button
                color="inherit"
                variant="outlined"
                sx={{
                  width: "100%",
                  minWidth: "auto"
                }}
              >
                <InfoModal
                  title={`What to Expect with Your ${recommendation} in ${state}`}
                  togglerLabel="LEARN MORE"
                  content={<>{bullets.map((bullet) => bullet)}</>}
                />
              </Button>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                minWidth: "auto"
              }}
              href={getLinkFullUrl([
                ROUTES.EstateAdminQuiz,
                EXPERT_MATCHING_ROUTES.Results,
                EXPERT_MATCHING_RESULTS.CollectUserInfo
              ])}
            >
              <FormattedMessage defaultMessage="Get My Expert" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdministrationResults;
