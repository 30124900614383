import InterstitialArchetype from "@components/archetypes/Interstitial";

const RemainderCompleteStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Super! You've finished allocating your Trust Remainder.`
    }}
  />
);

export default RemainderCompleteStep;
