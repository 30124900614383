import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { BlogLinks } from "@setup/consts/articleLinks";

const AllowFinanceAccessStep: React.FC = () => {
  const { setValue: setFinanceAccessAmount } = useFormField(
    "guardianTransactionsMaxAmount"
  );

  const onSubmit = useCallback(
    ({ allowGuardianIntoTransactions }: SingleSelectFormValues) => {
      if (parseBoolean(allowGuardianIntoTransactions) === false) {
        setFinanceAccessAmount("0");
      }
    },
    [setFinanceAccessAmount]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Would you like to permit your Pet Guardian limited ability to use your finances in an emergency?`,
        subText: `This authorization only applies to instances in which your pet requires emergency care and you are unreachable by phone or other communication.`,
        moreInfoModalProps: {
          title: "Pet Emergency Care Planning",
          togglerLabel: "Learn more",
          content: (
            <p>
              Just as you might have a “Rainy Day Fund” to help pay
              for personal unexpected bills beyond your normal living
              expenses, setting aside funds for your pet&apos;s
              emergency care is critical for the responsible pet
              parent.
              <br />
              <br />
              The right amount of money to save for pet emergency care
              or to authorize in a Limited Pet Power of Attorney as
              part of your Pet Estate Plan is dependent on your and
              your pet&apos;s situation. $1,000 is a good start, and
              based on the cost of an emergency pet care visit, you
              may wish to reserve in excess of that amount.
              <br />
              <br />
              For a more comprehensive discusion on how much to leave
              for pet emergency care, check out our blog using the
              link below.
            </p>
          ),
          closeLabel: "Got it",
          actions: [
            {
              label: "Go to Article",
              href: BlogLinks.howMuchPetEmergencyCare
            }
          ]
        }
      }}
      {...{ onSubmit }}
      name="allowGuardianIntoTransactions"
    />
  );
};

export default AllowFinanceAccessStep;
