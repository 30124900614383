import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPowersMakeGiftsSelfStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Should ${partnerFirstName}'s agent's ability to make gifts include gifts to themselves and others ${partnerFirstName}'s agent supports?`
      }}
      name="users.1.poa.giftRecipients.allowToAgent"
    />
  );
};

export default PartnerPowersMakeGiftsSelfStep;
