/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { lowerCase } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import {
  LastWillPlanValues,
  PowerOfAttorney
} from "@pages/LastWill/types";
import { useAdditionalPowersInfoLabels } from "@hooks/useAdditionalPowersInfoLabels";
import getPoaAgentInfo from "@utils/getPoaAgentInfo";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import {
  PartnerType,
  PlanType
} from "@api/__generated__/globalTypes";
import { numberWithCommas } from "@utils/misc";

import { LAST_WILL_POWER_OF_ATTORNEY_ROUTES } from "../../../LastWill/sections/PowerOfAttorney/consts";
import { POWER_OF_ATTORNEY_ROUTES } from "../main/consts";

export const usePrimaryDrafterPOAReviewTab = ({
  values,
  partnerType,
  planType = PlanType.LAST_WILL
}: {
  partnerType: PartnerType;
  values: LastWillPlanValues;
  planType?: PlanType;
}): ReviewItem[] => {
  const { formatMessage } = useIntl();
  const {
    users: [primaryDrafter]
  } = values;

  const {
    include,
    agents,
    successorAgents,
    generalAuthority = false,
    agentPowers,
    compensation = "",
    compensationLimit,
    compensationType,
    giftRecipients,
    additionalPowers
  } = primaryDrafter?.poa || ({} as PowerOfAttorney);
  const lowerCasePartnerType = lowerCase(partnerType);

  const makePOA = useMemo<ReviewItem | undefined>(
    () =>
      include
        ? undefined
        : {
            title: formatMessage({
              defaultMessage: "Power of Attorney:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.CreatePOA
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.CreatePOA
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `🚫 You chose not to create a Power of Attorney at this time.`
                })
              ]
            ]
          },
    [formatMessage, planType, include]
  );

  const agentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      agents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Primary Agent:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.DesignateAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateAgent
                  ]),
            items: [...agents.map(getPoaAgentInfo)]
          }
        : undefined,
    [formatMessage, agents, planType]
  );

  const successorAgentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      successorAgents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent
                  ]),
            items: [...successorAgents.map(getPoaAgentInfo)]
          }
        : agents && agents.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not nominating a successor agent.`
                })
              ]
            ]
          }
        : undefined,
    [agents, formatMessage, planType, successorAgents]
  );

  const agentPowersInfo = useMemo<ReviewItem | undefined>(
    () =>
      // eslint-disable-next-line no-nested-ternary
      generalAuthority
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Authority:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.Powers
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Powers
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage:
                    "✅ You are granting your agent general authority to act over all common subjects."
                })
              ]
            ]
          }
        : agentPowers
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Authority:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PowersToGrant
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersToGrant
                  ]),
            items: [
              [
                formatMessage({
                  defaultMessage:
                    "Your agent's authority to act is limited to the following subjects:"
                })
              ],
              agentPowers.map((power) => power.toString())
            ]
          }
        : undefined,

    [formatMessage, generalAuthority, planType, agentPowers]
  );

  const additionalPowersInfoLabels = useAdditionalPowersInfoLabels();

  type AdditionalPowersKeys = keyof typeof additionalPowersInfoLabels;

  const additionalPowersInfo = useMemo<ReviewItem | undefined>(
    () =>
      additionalPowers
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Special Authority:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview
                  ]),
            items: [
              Object.entries(additionalPowers)
                .filter(([, value]) => Boolean(value))
                .map(([key]) => {
                  if (key in additionalPowersInfoLabels) {
                    const additionalPowersInfoLabelsKey =
                      key as AdditionalPowersKeys;

                    return additionalPowersInfoLabels[
                      additionalPowersInfoLabelsKey
                    ];
                  }

                  return key;
                })
            ]
          }
        : undefined,
    [
      formatMessage,
      additionalPowers,
      planType,
      additionalPowersInfoLabels
    ]
  );

  const giftPowersDetailsInfo = useMemo<ReviewItem | undefined>(
    () =>
      additionalPowers?.makeGifts
        ? {
            title: formatMessage({
              defaultMessage: "Restrictions on Gift Making:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.PowersMakeGifts
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGifts
                  ]),
            items: [
              [
                `Permissible Gift Recipients:`,
                `- ${
                  giftRecipients?.type === "certainPeople"
                    ? /*
                    TODO: We will eventually want to refactor API to support list array
                        giftRecipients.listArray
                          .map((person: Beneficiary) => person.name)
                          .join(", ")
                    */
                      giftRecipients?.list
                    : giftRecipients?.type ===
                      "partnerAndChildrenOnly"
                    ? `My ${lowerCasePartnerType}, children, and descendants ONLY`
                    : giftRecipients?.type === "childrenOnly"
                    ? "My children only"
                    : giftRecipients?.type
                }`,
                `${
                  giftRecipients?.type === "Anyone"
                    ? [
                        `${
                          giftRecipients?.allowToAgent
                            ? "- Includes ability to gift to people my agent supports"
                            : "- Does not include ability to gift to people my agent supports"
                        }`
                      ]
                    : []
                }`
              ],
              [
                `Gift Limit Per Year, Per Individual:`,
                `- ${giftRecipients?.limitType}
                ${
                  giftRecipients?.limitType ===
                  "Limit up to a certain amount"
                    ? `: $${numberWithCommas(
                        giftRecipients.limitAmount
                      )}`
                    : []
                }`
              ]
            ].filter(Boolean)
          }
        : undefined,
    [
      additionalPowers?.makeGifts,
      formatMessage,
      planType,
      giftRecipients?.type,
      giftRecipients?.list,
      giftRecipients?.allowToAgent,
      giftRecipients?.limitType,
      giftRecipients?.limitAmount,
      lowerCasePartnerType
    ]
  );

  const compensationInfo = useMemo<ReviewItem | undefined>(
    () =>
      compensation
        ? {
            title: formatMessage({
              defaultMessage: "Agent Compensation:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.PowerOfAttorney,
                    POWER_OF_ATTORNEY_ROUTES.AgentCompensation
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                    LAST_WILL_POWER_OF_ATTORNEY_ROUTES.AgentCompensation
                  ]),

            items: [
              [
                `${compensation}`,
                `${
                  compensation ===
                  "Specify a compensation amount and reimbursement of expenses"
                    ? [
                        `$${numberWithCommas(compensationLimit)}`,
                        compensationType
                      ].join(" ")
                    : ""
                }`
              ].filter(Boolean)
            ]
          }
        : undefined,
    [
      compensation,
      planType,
      formatMessage,
      compensationLimit,
      compensationType
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        makePOA,
        agentsInfo,
        successorAgentsInfo,
        agentPowersInfo,
        additionalPowersInfo,
        giftPowersDetailsInfo,
        compensationInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      makePOA,
      agentsInfo,
      successorAgentsInfo,
      agentPowersInfo,
      additionalPowersInfo,
      giftPowersDetailsInfo,
      compensationInfo
    ]
  );

  return items;
};
