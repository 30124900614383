import { useMemo } from "react";
import * as yup from "yup";
import get from "lodash/get";

import { AssetType } from "@api/__generated__/globalTypes";

import { assetSchemaByType } from "./yupSchemas";

export const useBeneficiarySchema = () =>
  useMemo(
    () =>
      yup
        .object()
        .shape({
          name: yup
            .string()
            .nullable()
            .when("charity", {
              is: (charity: any) => !charity?.name,
              then: yup.string().required()
            }),
          percentageValue: yup.string().required(),
          charity: yup.object().shape({
            name: yup.string().nullable(),
            ein: yup.string().nullable()
          })
        })
        .when("isGift", {
          is: false,
          then: (schema) => schema.optional()
        }),
    []
  );

export const useValidationSchema = () => {
  const beneficiarySchema = useBeneficiarySchema();

  return useMemo(
    () =>
      yup.object().shape({
        type: yup.string(),
        grossValue: yup.string().nullable(),
        name: yup
          .string()
          .nullable()
          .when("type", (type: AssetType) =>
            get(
              assetSchemaByType,
              `${type}.name`,
              yup.string().nullable()
            )
          ),
        subtype: yup
          .string()
          .nullable()
          .when("type", (type: AssetType) =>
            get(
              assetSchemaByType,
              `${type}.subType`,
              yup.string().nullable()
            )
          ),
        institution: yup
          .string()
          .nullable()
          .when("type", (type: AssetType) =>
            get(
              assetSchemaByType,
              `${type}.institution`,
              yup.string().nullable()
            )
          ),
        isGift: yup.boolean().nullable(),
        isFunded: yup.boolean().nullable(),
        beneficiaries: yup.array().of(beneficiarySchema),
        address: yup
          .object()
          .when("type", (type: AssetType) =>
            get(
              assetSchemaByType,
              `${type}.address`,
              yup.object().unknown()
            )
          )
      }),
    [beneficiarySchema]
  );
};
