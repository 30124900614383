import {
  CognitoUser,
  CognitoUserSession,
  ISignUpResult
} from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

import useUserContext from "@providers/user/hooks";

interface UseCognitoAuthReturnType {
  signIn: (
    username: string,
    password: string
  ) => Promise<CognitoUser>;
  signUp: (
    username: string,
    password: string,
    isFinancialAdvisor?: boolean
  ) => Promise<ISignUpResult>;
  getToken: () => Promise<CognitoUserSession>;
  logout: () => Promise<void>;
  confirmSignUp: (username: string, code: string) => Promise<any>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<string>;
  forgotPasswordSendVerificationCode: (
    username: string
  ) => Promise<any>;
  resendActivationEmail: (email: string) => Promise<any>;
}

const useCognitoAuth = (): UseCognitoAuthReturnType => {
  const { setCognitoUser } = useUserContext();

  const signIn = async (
    username: string,
    password: string
  ): Promise<CognitoUser> => Auth.signIn(username, password);

  const logout = async (): Promise<void> => {
    Auth.signOut();
    setCognitoUser(undefined);
  };

  const signUp = async (
    email: string,
    password: string,
    isFinancialAdvisor = false
  ): Promise<ISignUpResult> =>
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email
      },
      clientMetadata: isFinancialAdvisor
        ? {
            convertToFinancialAdvisor: "true"
          }
        : undefined
    });

  const getToken = async (): Promise<CognitoUserSession> =>
    Auth.currentSession();

  const confirmSignUp = async (
    username: string,
    code: string
  ): Promise<any> => Auth.confirmSignUp(username, code);

  const forgotPasswordSendVerificationCode = async (
    username: string
  ): Promise<any> => Auth.forgotPassword(username);

  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<string> =>
    Auth.forgotPasswordSubmit(username, code, newPassword);

  const resendActivationEmail = async (email: string): Promise<any> =>
    Auth.resendSignUp(email);

  return {
    signIn,
    signUp,
    getToken,
    logout,
    confirmSignUp,
    forgotPasswordSendVerificationCode,
    forgotPasswordSubmit,
    resendActivationEmail
  };
};

export default useCognitoAuth;
