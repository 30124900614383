import { Box, LinearProgress, Typography } from "@mui/material";

import { ProgressBarSectionProps } from "./types";

const ProgressBarSection: React.FC<ProgressBarSectionProps> = ({
  title,
  progress
}) => {
  const value = Math.min(progress, 100);

  return (
    <Box maxWidth="47.5rem" m="0 auto" pt={{ xs: 2.5, md: 6.25 }}>
      <Typography
        component="h2"
        fontWeight={500}
        fontSize={{ xs: "0.9375rem", md: "0.9375rem" }}
        lineHeight={1.45}
        mb={1}
        px={2}
        sx={{ textAlign: "center", textTransform: "uppercase" }}
      >
        {title}
      </Typography>
      <LinearProgress
        variant="determinate"
        color="secondary"
        {...{ value }}
      />
    </Box>
  );
};

export default ProgressBarSection;
