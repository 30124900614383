import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import imageGoogle from "@assets/images/google.png";
// import imageAmazon from "@assets/images/amazon.png";
// import imageFacebook from "@assets/images/facebook.png";
import { ButtonsListItemProps } from "@components/ButtonsList/types";
import {
  FINANCIAL_ADVISOR_LOCAL_STORAGE_KEY,
  PASSWORD_REGEX
} from "@setup/consts/misc";
import { providerColors } from "@setup/theme";
import {
  setFinancialAdvisorToken,
  removeFinancialAdvisorToken
} from "@utils/financial-advisor-utils";

import { SignUpFormDataProps } from "./types";

export const useButtonsListItem = (
  isFinancialAdvisor = false
): ButtonsListItemProps[] => {
  const intl = useIntl();

  const onClick = useCallback(
    async (provider: CognitoHostedUIIdentityProvider) => {
      try {
        if (isFinancialAdvisor) {
          await setFinancialAdvisorToken();
        }

        await Auth.federatedSignIn({ provider });
      } catch (error) {
        console.log(error);

        removeFinancialAdvisorToken();
      }
    },
    [isFinancialAdvisor]
  );

  return useMemo(
    () => [
      {
        key: "1",
        label: intl.formatMessage({
          defaultMessage: "Sign up with google"
        }),
        backgroundColor: providerColors.GOOGLE,
        onClick: () =>
          onClick(CognitoHostedUIIdentityProvider.Google),
        imgSrc: imageGoogle
      }
      /*
      {
        key: "2",
        label: intl.formatMessage({
          defaultMessage: "Sign up with facebook"
        }),
        backgroundColor: providerColors.FACEBOOK,
        onClick: () =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Facebook
          }),
        imgSrc: imageFacebook
      },
      {
        key: "3",
        label: intl.formatMessage({
          defaultMessage: "Sign up with amazon"
        }),
        backgroundColor: providerColors.AMAZON,
        onClick: () =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Amazon
          }),
        imgSrc: imageAmazon
      }
      */
    ],
    [intl, onClick]
  );
};

export const useSignUpFormValidationSchema =
  (): SchemaOf<SignUpFormDataProps> => {
    const intl = useIntl();

    return useMemo(
      () =>
        yup
          .object({
            email: yup.string().email().required(),
            password: yup
              .string()
              .min(
                8,
                intl.formatMessage({
                  defaultMessage:
                    "Password must be at least 8 characters."
                })
              )
              .matches(
                PASSWORD_REGEX,
                intl.formatMessage({
                  defaultMessage:
                    "Passowrd must contain at least one uppercase, one lowercase, one number and one special character case."
                })
              )
              .required()
          })
          .required(),
      [intl]
    );
  };
