import React from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";

import * as Styled from "./styles";
import { RoadmapItemProps } from "./types";

const IconListItem: React.FC<RoadmapItemProps> = ({
  label,
  icon
}) => (
  <Styled.Content component="span">
    {icon}
    {label}
  </Styled.Content>
);

export default IconListItem;
