import { useIntl } from "react-intl";
import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  useMediaQuery,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Divider,
  CircularProgress
} from "@mui/material";
import { Assistant, Close, ArrowUpward } from "@mui/icons-material";

import { useAIAssistant } from "@providers/aiAssistant/context";
import theme from "@setup/theme";

import {
  useCreateThreadAndResponse,
  useGenerateChatResponse
} from "./hooks";
import {
  ChatAssistantButton,
  ChatWrapper,
  Message,
  MessageBox
} from "./styles";
import { MessageItem } from "./types";

const ChatModal: React.FC = () => {
  const intl = useIntl();
  const { isChatEnabled, toggleChat } = useAIAssistant();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [messages, setMessages] = useState<MessageItem[]>([
    {
      isUser: false,
      text: "Hey there, I'm Erica, an AI Assistant trained on common estate planning topics. I'm still in beta, but go ahead, ask me anything related to estate planning and I'll do my best to answer."
    }
  ]);
  const [input, setInput] = useState<string>("");
  const [threadId, setThreadId] = useState<string>("");
  const [isAILoading, setIsAILoading] = useState<boolean>(false);
  const createThreadAndResponse = useCreateThreadAndResponse();
  const generateChatResponse = useGenerateChatResponse();

  const handleSend = async () => {
    if (input.trim() !== "") {
      const submittedInput = input;
      setInput("");
      const newMessages = [
        ...messages,
        { isUser: true, text: submittedInput }
      ];
      setMessages(newMessages);

      let currentThreadId = threadId;
      setIsAILoading(true);

      // No existing thread
      if (currentThreadId === "") {
        const { threadId, message } = await createThreadAndResponse(
          submittedInput
        );
        currentThreadId = threadId;
        setThreadId(threadId);

        setIsAILoading(false);
        setMessages([
          ...newMessages,
          {
            isUser: false,
            text: message
          }
        ]);

        // Existing thread
      } else if (currentThreadId) {
        const aiResponse = await generateChatResponse(
          currentThreadId,
          submittedInput
        );

        const aiResponseText = aiResponse?.data
          ? aiResponse.data.aiGenerateChatResponse.message
          : "Sorry, I seem to be having trouble generating a response at the moment. Please contact JIC Member Success directly at support@jicestates.com or by phone / text at 203.392.1951.";

        setIsAILoading(false);
        setMessages([
          ...newMessages,
          {
            isUser: false,
            text: aiResponseText
          }
        ]);
      }
    }
  };

  return (
    <>
      {!isChatEnabled && isDesktop ? (
        <Tooltip title="Launch AI Assistant (beta)" arrow>
          <ChatAssistantButton onClick={toggleChat}>
            <Assistant color="inherit" />
          </ChatAssistantButton>
        </Tooltip>
      ) : null}
      <Modal open={isChatEnabled} onClose={toggleChat}>
        <ChatWrapper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Typography variant="h5">
              {intl.formatMessage({
                defaultMessage: "Estate Planning AI Assistant (Beta)"
              })}
            </Typography>
            <IconButton onClick={toggleChat}>
              <Close />
            </IconButton>
          </Box>
          <Divider
            sx={{
              width: "100%",
              color: theme.palette.grey["400"]
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              maxHeight: "calc(100vh - 200px)",
              mt: 2
            }}
          >
            {messages.map((message, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MessageBox key={`${index}_${message}`}>
                <Typography
                  variant="caption"
                  sx={{
                    alignSelf: message.isUser
                      ? "flex-end"
                      : "flex-start",
                    mt: 0.5
                  }}
                >
                  {message.isUser ? "You" : "Erica"}
                </Typography>
                <Message isuser={message.isUser}>
                  {message.text}
                </Message>
              </MessageBox>
            ))}
            {isAILoading && (
              <MessageBox>
                <Typography
                  variant="caption"
                  sx={{
                    alignSelf: "flex-start",
                    mt: 0.5
                  }}
                >
                  Erica is thinking...
                </Typography>

                <CircularProgress />
              </MessageBox>
            )}
          </Box>
          <Box sx={{ display: "flex", mt: 2, alignItems: "end" }}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSend();
                }
              }}
              sx={{ flexGrow: 1 }}
            />
            <Button
              onClick={handleSend}
              variant="contained"
              size="small"
              sx={{
                ml: 1,
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                minWidth: "40px"
              }}
            >
              <ArrowUpward />
            </Button>
          </Box>
        </ChatWrapper>
      </Modal>
    </>
  );
};

export default ChatModal;
