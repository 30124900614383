import { PlanFormSection } from "@providers/planForm/types";

import { DASHBOARD_SECTION_ROUTES } from "./consts";
import DocsAndDataTab from "./Tabs/DocsAndDataTab";
import AssetsTab from "./Tabs/AssetsTab";
import VisualTab from "./Tabs/VisualTab";
import ShareTab from "./Tabs/ShareTab";
import SharedWithMeSummary from "./Tabs/SharedWithMeSummary/index";
import ResourcesTab from "./Tabs/ResourcesTab/index";

const DashboardSections: PlanFormSection[] = [
  {
    path: DASHBOARD_SECTION_ROUTES.DocsAndData,
    element: <DocsAndDataTab />
  },
  {
    path: DASHBOARD_SECTION_ROUTES.Assets,
    element: <AssetsTab />
  },
  {
    path: DASHBOARD_SECTION_ROUTES.Visual,
    element: <VisualTab />
  },
  {
    path: DASHBOARD_SECTION_ROUTES.Share,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element: <ShareTab />
  },
  {
    path: DASHBOARD_SECTION_ROUTES.SharedWithMeSummary,
    element: <SharedWithMeSummary />
  },
  {
    path: DASHBOARD_SECTION_ROUTES.Resources,
    element: <ResourcesTab />
  },
  {
    path: "",
    element: <DocsAndDataTab />
  }
];

export default DashboardSections;
