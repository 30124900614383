import React, { useMemo } from "react";

import { useMemberPlansState } from "@providers/memberPlansState/context";
import PlanTodosProvider from "@providers/planTodos";

import PlanTodos from "./PlanTodos";

export const PlanTodosWrapper: React.FC = () => {
  const { trust, lastWill, petProtection } = useMemberPlansState();

  const planId = useMemo(() => {
    if (trust) return trust.plan.id;
    if (lastWill) return lastWill.plan.id;
    if (petProtection) return petProtection.plan.id;

    return null;
  }, [trust, lastWill, petProtection]);

  if (!planId) {
    return null;
  }

  return (
    <PlanTodosProvider value={{ planId }}>
      <PlanTodos />
    </PlanTodosProvider>
  );
};

export default PlanTodosWrapper;
