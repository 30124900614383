import { forwardRef } from "react";

import * as Styled from "./styles";
import { MultiSelectCheckboxProps } from "./types";

const MultiSelectCheckbox = forwardRef<
  HTMLButtonElement,
  MultiSelectCheckboxProps
>(({ label, icon, color = "secondary", ...rest }, ref) => (
  <Styled.MultiSelectCheckboxLabel
    control={
      <Styled.MultiSelectCheckbox
        {...{ color, icon, ref, ...rest }}
        checkedIcon={icon}
      />
    }
    labelPlacement="bottom"
    componentsProps={{
      typography: {
        variant: "caption",
        fontWeight: "500"
      }
    }}
    {...{ label }}
  />
));

export default MultiSelectCheckbox;
