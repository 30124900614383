/* eslint-disable react/no-unescaped-entities */
import PersonDetailsArchetype from "@components/archetypes/PersonDetails";
import { BlogLinks } from "@setup/consts/articleLinks";
import { useFormField } from "@components/FormFields/hooks";

const PartnerDesignateRepresentativeStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <PersonDetailsArchetype
      name="users.1.lastWill.personalRepresentatives.0"
      personRole="Representative"
      stepLayout={{
        title: `Ok. Who does ${partnerFirstName} want to serve as ${partnerFirstName}'s Personal Representative?`,
        subText:
          "See our tips for choosing a Personal Representative.",
        moreInfoModalProps: {
          title: "Tips for Choosing a Personal Representative",
          content: (
            <>
              <p>
                Your Personal Representative is a trusted individual
                who is responsible for winding up your earthly affairs
                after you pass and following the instructions that you
                provide in your will.
              </p>
              <p>
                There are six characteristics that you should look for
                in a personal representative:
              </p>
              <ol>
                <li>Trustworthy</li>
                <li>Demonstrates attention to detail</li>
                <li>Patient, yet deliberate</li>
                <li>
                  Qualifies under your state's rules for serving as a
                  personal representative
                </li>
                <li>Checks and opens the mail</li>
                <li>Not afraid to ask for help</li>
              </ol>
              <p>
                For a more comprehensive discussion of these
                characteristics, check out our blog post using the
                link below.
              </p>
            </>
          ),
          actions: [
            {
              label: "Go to Article",
              href: BlogLinks.howToChooseRepresentative
            }
          ],
          closeLabel: "Got it"
        }
      }}
    />
  );
};

export default PartnerDesignateRepresentativeStep;
