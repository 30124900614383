import { useIntl } from "react-intl";
import { FormProvider } from "react-hook-form";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormCharitiesAutocomplete from "@components/FormFields/FormCharitiesAutocomplete";

import { ArchetypeProps } from "../types";

import { useCharityArchetype } from "./hooks";
import { CharityFormValues } from "./types";

const CharityBackupArchetype: React.FC<
  ArchetypeProps<CharityFormValues>
> = ({ name, stepLayout, onSubmit: onFormSubmit }) => {
  const intl = useIntl();
  const { controls, goBack, onSubmit } = useCharityArchetype(
    name,
    onFormSubmit
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage: "To which charity would you like to give?"
      })}
      {...stepLayout}
    >
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <FormCharitiesAutocomplete
            {...{ name }}
            // for percentage value, strip the last eight characters (".charity")
            {...{ beneficiaryPrefix: name.slice(0, -8) }}
          />
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default CharityBackupArchetype;
