import CelebrationIcon from "@mui/icons-material/Celebration";

import InterstitialArchetype from "@components/archetypes/Interstitial";

const FinanceEndStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title:
        "Great! That covers the quick financial background we need. Let's get your results!",
      muiIconHeader: <CelebrationIcon />
    }}
  />
);

export default FinanceEndStep;
