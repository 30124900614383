import { createContext, useContext } from "react";

import { DEFAULT_LOCALE } from "./consts";
import { Locale, LocaleState } from "./types";

const LocaleContext = createContext<LocaleState>({
  locale: DEFAULT_LOCALE as Locale
});

const useLocale = () => useContext(LocaleContext);

export { LocaleContext, useLocale };
