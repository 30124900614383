import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerTransitionStartStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `We're going to move on to ${partnerFirstName}'s distributions now.`,
        muiIconHeader: <ThumbUpAltOutlinedIcon />
      }}
    />
  );
};

export default PartnerTransitionStartStep;
