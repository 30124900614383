import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const Interstitial1Step: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Nice to meet you, ${firstName}.`,
        isWide: true,
        subText:
          "The rest of this section will cover gathering details about your spouse, partner, and/or any kids that may be in the picture."
      }}
    />
  );
};

export default Interstitial1Step;
