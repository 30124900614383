import { CheckCircleOutline } from "@mui/icons-material";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerTransitionStartStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Great! Now we'll note the assets that ${partnerFirstName} wants to fund at close.`,
        muiIconHeader: <CheckCircleOutline />
      }}
    />
  );
};

export default PartnerTransitionStartStep;
