import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { PlanOption } from "@api/__generated__/globalTypes";

// Pages 'borrowed' from Last Will Experience
import NominateGuardianStep from "../../../LastWill/sections/Guardians/steps/NominateGuardians";
import NoChildrenNoGuardiansStep from "../../../LastWill/sections/Guardians/steps/NoChildrenNoGuardians";
import PartnerAsGuardianStep from "../../../LastWill/sections/Guardians/steps/PartnerAsGuardian";
import DesignateGuardianStep from "../../../LastWill/sections/Guardians/steps/DesignateGuardians";
import NominateBackupGuardianStep from "../../../LastWill/sections/Guardians/steps/NominateBackupGuardians";
import DesignateBackupGuardianStep from "../../../LastWill/sections/Guardians/steps/DesignateBackupGuardians";
import PrimaryGuardianOnlyStep from "../../../LastWill/sections/Guardians/steps/PrimaryGuardiansOnly";
import NominateAntiGuardianStep from "../../../LastWill/sections/Guardians/steps/NominateAntiGuardian";
import DesignateAntiGuardianStep from "../../../LastWill/sections/Guardians/steps/DesignateAntiGuardians";
import ReviewStep from "../../../LastWill/sections/Guardians/steps/Review";
import ReviewTabsStep from "../../../LastWill/sections/Guardians/steps/ReviewTabs";
import { TRUST_MANAGEMENT_ROUTES } from "../Management/consts";

// Trust specific pages
import SectionsPreviewStep from "./steps/Preview";
import { TRUST_GUARDIANS_ROUTES } from "./consts";

const guardiansSteps: PlanFormStep[] = [
  {
    path: TRUST_GUARDIANS_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: {
      if: [
        { "==": [{ var: "haveChildren" }, true] },
        TRUST_GUARDIANS_ROUTES.NominateGuardians,
        TRUST_GUARDIANS_ROUTES.NoChildrenNoGuardians
      ]
    }
  },
  {
    path: TRUST_GUARDIANS_ROUTES.NominateGuardians,
    element: <NominateGuardianStep />,
    rules: {
      if: [
        {
          and: [
            { "==": [{ var: "nominateGuardians" }, true] },
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] }
          ]
        },
        TRUST_GUARDIANS_ROUTES.PartnerAsGuardian,
        {
          if: [
            { "==": [{ var: "nominateGuardians" }, true] },
            TRUST_GUARDIANS_ROUTES.DesignateGuardians,
            TRUST_GUARDIANS_ROUTES.NoChildrenNoGuardians
          ]
        }
      ]
    }
  },
  {
    path: TRUST_GUARDIANS_ROUTES.NoChildrenNoGuardians,
    element: <NoChildrenNoGuardiansStep />,
    rules: `${TRUST_SECTION_ROUTES.Management}/${TRUST_MANAGEMENT_ROUTES.Preview}`
  },
  {
    path: TRUST_GUARDIANS_ROUTES.PartnerAsGuardian,
    element: <PartnerAsGuardianStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerAsChildGuardian" }, true] },
        TRUST_GUARDIANS_ROUTES.NominateBackupGuardians,
        TRUST_GUARDIANS_ROUTES.DesignateGuardians
      ]
    }
  },
  {
    path: TRUST_GUARDIANS_ROUTES.DesignateGuardians,
    element: <DesignateGuardianStep />,
    rules: TRUST_GUARDIANS_ROUTES.NominateBackupGuardians
  },
  {
    path: TRUST_GUARDIANS_ROUTES.NominateBackupGuardians,
    element: <NominateBackupGuardianStep />,
    rules: {
      if: [
        { "==": [{ var: "nominateBackupGuardians" }, true] },
        TRUST_GUARDIANS_ROUTES.DesignateBackupGuardians,
        TRUST_GUARDIANS_ROUTES.PrimaryGuardiansOnly
      ]
    }
  },
  {
    path: TRUST_GUARDIANS_ROUTES.DesignateBackupGuardians,
    element: <DesignateBackupGuardianStep />,
    rules: TRUST_GUARDIANS_ROUTES.NominateAntiGuardian
  },
  {
    path: TRUST_GUARDIANS_ROUTES.PrimaryGuardiansOnly,
    element: <PrimaryGuardianOnlyStep />,
    rules: TRUST_GUARDIANS_ROUTES.NominateAntiGuardian
  },
  {
    path: TRUST_GUARDIANS_ROUTES.NominateAntiGuardian,
    element: <NominateAntiGuardianStep />,
    rules: {
      if: [
        { "==": [{ var: "nominateProhibitedGuardians" }, true] },
        TRUST_GUARDIANS_ROUTES.DesignateAntiGuardian,
        TRUST_GUARDIANS_ROUTES.Review
      ]
    }
  },
  {
    path: TRUST_GUARDIANS_ROUTES.DesignateAntiGuardian,
    element: <DesignateAntiGuardianStep />,
    rules: TRUST_GUARDIANS_ROUTES.Review
  },

  {
    path: TRUST_GUARDIANS_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${TRUST_SECTION_ROUTES.Management}/${TRUST_MANAGEMENT_ROUTES.Preview}`
  },
  {
    path: TRUST_GUARDIANS_ROUTES.ReviewTabs,
    element: <ReviewTabsStep />,
    rules: `${TRUST_SECTION_ROUTES.Management}/${TRUST_MANAGEMENT_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={TRUST_GUARDIANS_ROUTES.Preview} />
  }
];

export default guardiansSteps;
