/* eslint-disable react/no-unescaped-entities */
import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPowersTransferToTrustStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to enable their agent to transfer property to a revocable living trust that ${partnerFirstName} has created or may create in the future?`,
        subText: `Learn more about this power to transfer property.`,
        moreInfoModalProps: {
          title:
            "Authority to Transfer Property to a Revocable Living Trust",
          togglerLabel: "Learn more",
          content: (
            <p>
              Transferring title of property to a trust is known as
              "funding the trust" and a necessary step for your trust
              to be effective. The process to transfer title for most
              property is generally not very complicated, but it can
              be a bit tedious and you may wish to have your agent
              assist.
              <br />
              <br />
              Grant your agent authority to transfer property to a
              revocable living trust if you are creating or plan to
              create a revocable living trust and wish your agent to
              help with the process.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.1.poa.additionalPowers.transferToTrust"
    />
  );
};

export default PartnerPowersTransferToTrustStep;
