import { useCallback } from "react";
import { omit } from "lodash";

import { LastWill } from "@api/plans/__generated__/LastWill";
import { useCreateAssetMutation } from "@api/assets";
import { AssetCreateInput } from "@api/__generated__/globalTypes";
import useToggle from "@hooks/useToggle";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { useGetPlansQuery } from "@api/plans";

export const useAddAssetModal = (userLastWill: LastWill) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { trust, lastWill } = useMemberPlansState();

  const { refetch } = useGetPlansQuery();
  const [createAsset, { loading, error, reset }] =
    useCreateAssetMutation({
      onCompleted: () => {
        refetch();
        toggleOff();
      }
    });

  const canEdit = trust ? trust.canEdit : lastWill?.canEdit;

  const onSubmit = useCallback(
    (data: Omit<AssetCreateInput, "lastWillId">) => {
      const lastWillId = userLastWill?.id;

      if (!lastWillId || !canEdit) return;

      const isGift = data?.beneficiaries?.length > 0 && data.isGift;

      const address = omit(data.address, "stateCode");

      createAsset({
        variables: {
          input: {
            lastWillId,
            ...{ ...data, isGift, address }
          }
        }
      });
    },
    [canEdit, createAsset, userLastWill?.id]
  );

  return {
    isOpen,
    canEdit: !!canEdit,
    loading,
    error,
    toggleOn,
    toggleOff,
    onSubmit,
    reset
  };
};
