import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { PlanOption } from "@api/__generated__/globalTypes";

// Steps 'borrowed' from Last Will
import ReviewTabsStepPage from "../../../LastWill/sections/FinalArrangements/steps/ReviewTabs";
import RestingPlaceStep from "../../../LastWill/sections/FinalArrangements/steps/RestingPlace";
import CeremonyStep from "../../../LastWill/sections/FinalArrangements/steps/Ceremony";
import OtherRequestsStep from "../../../LastWill/sections/FinalArrangements/steps/OtherRequests";
import PartnerSameArrangementsStep from "../../../LastWill/sections/FinalArrangements/steps/PartnerSameArrangements";
import PartnerCeremonyStep from "../../../LastWill/sections/FinalArrangements/steps/PartnerCeremony";
import PartnerRestingPlaceStep from "../../../LastWill/sections/FinalArrangements/steps/PartnerRestingPlace";
import PartnerOtherRequestsStep from "../../../LastWill/sections/FinalArrangements/steps/PartnerOtherRequests";
import ReviewStep from "../../../LastWill/sections/FinalArrangements/steps/Review";

// Trust Specific Steps
import SectionsPreviewStep from "./steps/Preview";
import { TRUST_FINAL_ARRANGEMENT_ROUTES } from "./consts";

const finalArrangementsSteps: PlanFormStep[] = [
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: TRUST_FINAL_ARRANGEMENT_ROUTES.RestingPlace
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.RestingPlace,
    element: <RestingPlaceStep />,
    rules: TRUST_FINAL_ARRANGEMENT_ROUTES.Ceremony
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.Ceremony,
    element: <CeremonyStep />,
    rules: TRUST_FINAL_ARRANGEMENT_ROUTES.OtherRequests
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.OtherRequests,
    element: <OtherRequestsStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerSameArrangements,
        TRUST_FINAL_ARRANGEMENT_ROUTES.Review
      ]
    }
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerSameArrangements,
    element: <PartnerSameArrangementsStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.finalArrangements.copy" }, true] },
        TRUST_FINAL_ARRANGEMENT_ROUTES.ReviewTabs,
        TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace
      ]
    }
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace,
    element: <PartnerRestingPlaceStep />,
    rules: TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony,
    element: <PartnerCeremonyStep />,
    rules: TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerOtherRequests
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerOtherRequests,
    element: <PartnerOtherRequestsStep />,
    rules: TRUST_FINAL_ARRANGEMENT_ROUTES.ReviewTabs
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.Review,
    element: <ReviewStep />,
    rules: TRUST_SECTION_ROUTES.DocumentPreview
  },
  {
    path: TRUST_FINAL_ARRANGEMENT_ROUTES.ReviewTabs,
    element: <ReviewTabsStepPage />,
    rules: TRUST_SECTION_ROUTES.DocumentPreview
  },
  {
    path: "*",
    element: <Navigate to={TRUST_FINAL_ARRANGEMENT_ROUTES.Preview} />
  }
];

export default finalArrangementsSteps;
