import { useIntl } from "react-intl";
import { useMemo } from "react";

import { ReviewItem } from "@components/archetypes/Review/types";
import { ITERATION_QUERY_KEY } from "@providers/planForm/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { PET_GUARDIANS_ROUTES } from "@pages/PetPlan/sections/Guardians/consts";
import { ROUTES } from "@setup/consts/routes";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";

import { getLinkFullUrl } from "../utils";

import getPetGuardiansEstateViewInfo from "./partials/getPetGuardiansEstateViewInfo";

export const usePetGuardiansReview = () => {
  const { formatMessage } = useIntl();
  const { petProtection: petProtectionPlan } = useMemberPlansState();
  const { petProtection, canEdit = false } = petProtectionPlan || {};

  const iterationQueryKey = ITERATION_QUERY_KEY;
  const editPath = getLinkFullUrl([
    ROUTES.PetPlan,
    PET_SECTION_ROUTES.Guardians,
    PET_GUARDIANS_ROUTES.GuardianLoop,
    PET_GUARDIANS_ROUTES.DesignateGuardian,
    `?${iterationQueryKey}=1`
  ]);

  const petsGuardianInfo = useMemo<ReviewItem | undefined>(
    () =>
      petProtection
        ? {
            title: formatMessage({
              defaultMessage: "Pet Guardian Info"
            }),
            href: editPath,
            canEdit,
            isFullWidth: true,
            items: getPetGuardiansEstateViewInfo(petProtection)
          }
        : undefined,
    [petProtection, formatMessage, canEdit, editPath]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [petsGuardianInfo].filter((item): item is ReviewItem =>
        Boolean(item)
      ),
    [petsGuardianInfo]
  );

  return items;
};
