import { MessageOutlined } from "@mui/icons-material";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface NeedHelpProps {
  message?: string;
}

const NeedHelpMessage: React.FC<NeedHelpProps> = ({ message }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography
          color={theme.palette.grey["500"]}
          sx={{
            fontSize: "1rem",
            fontWeight: "500"
          }}
        >
          {message && (
            <FormattedMessage
              defaultMessage="{message}"
              values={{ message }}
            />
          )}
          {!message && (
            <FormattedMessage defaultMessage="Need help?" />
          )}
        </Typography>
      </Box>
      <Link
        sx={{
          textDecoration: "none",
          color: "inherit"
        }}
        href="sms:1-203-392-1951"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.1rem",
            gap: "1rem"
          }}
        >
          <MessageOutlined />
          <Typography
            color={theme.palette.grey["500"]}
            sx={{
              fontSize: "0.875rem",
              fontWeight: "500"
            }}
          >
            Text Us
          </Typography>
        </Box>
      </Link>
    </>
  );
};

export default NeedHelpMessage;
