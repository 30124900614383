import {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from "react";

import { EmailSentStateContext } from "./context";
import { EmailSentState } from "./type";

export const { Provider: EmailSentContext } = EmailSentStateContext;

export const EmailSentContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [showEmailSentMessage, setShowEmailSentMessage] =
    useState(false);

  const onCompleted = useCallback(() => {
    setShowEmailSentMessage(true);
  }, []);

  const contextValues = useMemo<EmailSentState>(
    () => ({
      setShowEmailSentMessage,
      showEmailSentMessage,
      onCompleted
    }),
    [onCompleted, showEmailSentMessage, setShowEmailSentMessage]
  );

  return (
    <EmailSentContext value={contextValues}>
      {children}
    </EmailSentContext>
  );
};
