import {
  FileDownloadOutlined,
  EditOutlined,
  ShareOutlined,
  DeleteOutline
} from "@mui/icons-material";
import { Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import fileSaver from "file-saver";
import { useCallback, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";

import Popover from "@components/Popover";
import { Plans_plans_files as PlanFile } from "@api/plans/__generated__/Plans";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { ROUTES } from "@setup/consts/routes";

import {
  UseSlideActionReturnType,
  UseSlideActionsProps
} from "../types";
import SetDocumentNameModal from "../components/UpdateDocumentModal";
import { getLinkFullUrl } from "../utils";
import { DASHBOARD_SECTION_ROUTES } from "../consts";

import useDeleteCustomFile from "./useDeleteCustomFile";

export const useSlideActions = ({
  planFile,
  memberPlan,
  editLink,
  customDocument
}: UseSlideActionsProps) => {
  const { deleteCustomFile } = useDeleteCustomFile();
  const { lastWill } = useMemberPlansState();

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const allFiles = useMemo(
    () => memberPlan?.plan.files || [],
    [memberPlan]
  );

  const downloadSingleFile = useCallback(
    (file: PlanFile | undefined) => {
      if (!file || !file?.fileUrl) return;
      fileSaver.saveAs(file?.fileUrl, file?.name ?? file?.fileUrl);
    },
    []
  );

  const downloadAllPlanFiles = useCallback(() => {
    allFiles.forEach((file: PlanFile) => {
      downloadSingleFile(file);
    });
  }, [downloadSingleFile, allFiles]);

  const onEditButtonClicked = useCallback(() => {
    if (editLink) {
      navigate(editLink);
    }
  }, [editLink, navigate]);

  const onDeleteButtonClicked = useCallback(() => {
    if (planFile?.id) {
      deleteCustomFile(planFile?.id);
    }
  }, [deleteCustomFile, planFile?.id]);

  const renderDownloadMenu = useCallback(
    () => (
      <Popover TriggerButton={FileDownloadOutlined}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            border: "1px solid #000",
            padding: "0.3rem"
          }}
        >
          {customDocument && !lastWill?.canEdit ? (
            <Box display="flex">
              <Typography fontSize="14px">
                <FormattedMessage defaultMessage="🚫 You don't have download permission" />
              </Typography>
            </Box>
          ) : (
            <>
              {allFiles.length > 0 && (
                <>
                  <Box
                    display="flex"
                    sx={{
                      cursor: "pointer"
                    }}
                    onClick={downloadAllPlanFiles}
                  >
                    <FileDownloadOutlined />
                    <Typography fontSize="14px">
                      <FormattedMessage
                        defaultMessage="
                    Download All Documents"
                      />
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              <Box
                onClick={() => downloadSingleFile(planFile)}
                display="flex"
                sx={{
                  cursor: "pointer"
                }}
              >
                <FileDownloadOutlined />
                <Typography fontSize="14px">
                  <FormattedMessage defaultMessage="Download" />
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Popover>
    ),
    [
      customDocument,
      allFiles.length,
      downloadAllPlanFiles,
      downloadSingleFile,
      lastWill?.canEdit,
      planFile
    ]
  );

  const renderEditButton = useCallback(() => {
    if (!customDocument)
      return <EditOutlined onClick={onEditButtonClicked} />;

    if (!planFile?.id) return null;

    return (
      <SetDocumentNameModal
        name={planFile?.name || ""}
        id={planFile?.id}
      />
    );
  }, [
    customDocument,
    onEditButtonClicked,
    planFile?.id,
    planFile?.name
  ]);

  return useMemo<UseSlideActionReturnType>(
    () =>
      [
        {
          icon: renderDownloadMenu(),
          label: formatMessage({
            defaultMessage: "Download"
          })
        },
        {
          ...(memberPlan?.canEdit
            ? {
                icon: renderEditButton(),
                label: formatMessage({
                  defaultMessage: "Edit"
                })
              }
            : {})
        },
        {
          ...(memberPlan?.isOwner
            ? {
                icon: (
                  <Link
                    to={getLinkFullUrl([
                      ROUTES.Dashboard,
                      DASHBOARD_SECTION_ROUTES.Share
                    ])}
                  >
                    <ShareOutlined />
                  </Link>
                ),
                label: formatMessage({
                  defaultMessage: "Share"
                })
              }
            : {})
        },
        {
          ...(customDocument && memberPlan?.canEdit
            ? {
                icon: (
                  <DeleteOutline onClick={onDeleteButtonClicked} />
                ),
                label: formatMessage({
                  defaultMessage: "Delete"
                })
              }
            : {})
        }
      ].filter((item): item is UseSlideActionReturnType[0] =>
        Boolean(item.label)
      ),
    [
      customDocument,
      formatMessage,
      memberPlan?.canEdit,
      memberPlan?.isOwner,
      onDeleteButtonClicked,
      renderDownloadMenu,
      renderEditButton
    ]
  );
};
