import { startCase, capitalize } from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { PlanType } from "@api/__generated__/globalTypes";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import getSkippableAnswer from "@utils/getSkippableAnswer";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { LAST_WILL_HEALTHCARE_ROUTES } from "@pages/LastWill/sections/Healthcare/consts";
import { HEALTHCARE_ROUTES } from "@pages/Healthcare/consts";
import { HEALTHCARE_MEDICAL_POA_ROUTES } from "@pages/Healthcare/sections/medicalPOA/consts";

import {
  HEALTHCARE_LIVINGWILL_ROUTES,
  ARE_ORGANS_DONATED_KEYS
} from "../../sections/livingWill/consts";

const usePrimaryDrafterHealthCareReview = ({
  planType = PlanType.LAST_WILL
}: {
  planType?: PlanType;
}): ReviewItem[] => {
  const intl = useIntl();
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter]
  } = values;

  const {
    authorizeAgents = false,
    agents = [],
    backupAgents = [],
    preferences = {},
    medicalRecordAccess = {}
  } = primaryDrafter?.healthcare || {};

  const { authorizeOthers = false, people = [] } =
    medicalRecordAccess;

  // MEDICAL POA ROLL-UP STARTS HERE

  const primaryAgentInfo = useMemo<ReviewItem | undefined>(
    () =>
      authorizeAgents
        ? {
            title: intl.formatMessage({
              defaultMessage: "Primary Healthcare Agent:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.MedicalPOA,
                    HEALTHCARE_MEDICAL_POA_ROUTES.DesignateAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.DesignateAgent
                  ]),
            items: [[agents?.[0]?.name]]
          }
        : undefined,
    [authorizeAgents, planType, intl, agents]
  );

  const backupAgentInfo = useMemo<ReviewItem | undefined>(
    () =>
      backupAgents?.length
        ? {
            title: intl.formatMessage({
              defaultMessage: "Backup Healthcare Agent:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.MedicalPOA,
                    HEALTHCARE_MEDICAL_POA_ROUTES.DesignateBackupAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.DesignateBackupAgent
                  ]),
            items: [
              [backupAgents.map((agent) => agent.name).join(", ")]
            ]
          }
        : {
            title: intl.formatMessage({
              defaultMessage: "Backup Healthcare Agent:"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.MedicalPOA,
                    HEALTHCARE_MEDICAL_POA_ROUTES.NominateBackupAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.NominateBackupAgent
                  ]),
            items: [
              ["You chose not to nominate a backup healthcare agent."]
            ]
          },
    [backupAgents, planType, intl]
  );

  const medicalPOANestedInfo = useMemo<ReviewItem | undefined>(
    () =>
      authorizeAgents
        ? {
            title: intl.formatMessage({
              defaultMessage: "Medical Power of Attorney"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.MedicalPOA,
                    HEALTHCARE_MEDICAL_POA_ROUTES.NominateAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.NominateAgent
                  ]),
            items: [primaryAgentInfo, backupAgentInfo].filter(
              (item): item is ReviewItem => Boolean(item)
            )
          }
        : {
            title: intl.formatMessage({
              defaultMessage: "Medical Power of Attorney"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.MedicalPOA,
                    HEALTHCARE_MEDICAL_POA_ROUTES.NominateAgent
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.NominateAgent
                  ]),
            items: [
              [
                "🚫 You chose not to create a medical power of attorney at this time."
              ]
            ]
          },
    [
      intl,
      authorizeAgents,
      planType,
      primaryAgentInfo,
      backupAgentInfo
    ]
  );

  // GRANT MEDICAL ACCESS ROLLUP STARTS HERE

  const medicalAccessInfo = useMemo<ReviewItem | undefined>(() => {
    if (!authorizeOthers && authorizeAgents) {
      return {
        title: intl.formatMessage({
          defaultMessage: "HIPAA Authorized Persons:"
        }),
        href:
          planType === PlanType.TRUST
            ? getLinkFullUrl([
                ROUTES.Healthcare,
                HEALTHCARE_ROUTES.MedicalPOA,
                HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess
              ])
            : getLinkFullUrl([
                ROUTES.LastWill,
                LAST_WILL_SECTION_ROUTES.Healthcare,
                LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess
              ]),
        items: [
          [
            intl.formatMessage({
              defaultMessage: `Only your healthcare agents will receive access to your healthcare information.`
            })
          ]
        ]
      };
    }

    if (!authorizeOthers && !authorizeAgents) {
      return {
        title: intl.formatMessage({
          defaultMessage: "HIPAA Authorized Persons:"
        }),
        href:
          planType === PlanType.TRUST
            ? getLinkFullUrl([
                ROUTES.Healthcare,
                HEALTHCARE_ROUTES.MedicalPOA,
                HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess
              ])
            : getLinkFullUrl([
                ROUTES.LastWill,
                LAST_WILL_SECTION_ROUTES.Healthcare,
                LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess
              ]),
        items: [
          [
            intl.formatMessage({
              defaultMessage: `None`
            }),
            intl.formatMessage({
              defaultMessage: `You are not providing anyone access to your health information.`
            })
          ]
        ]
      };
    }

    return {
      title: intl.formatMessage({
        defaultMessage: "HIPAA Authorized Persons:"
      }),
      href:
        planType === PlanType.TRUST
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.MedicalPOA,
              HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.GrantMedicalAccess
            ]),
      items: [
        people
          ?.map((person) => person.name)
          .filter((item): item is string => Boolean(item))
      ]
    };
  }, [authorizeOthers, planType, authorizeAgents, intl, people]);

  // LIVING WILL ROLL-UP STARTS HERE

  const generalGuidance = useMemo<ReviewItem>(
    () => ({
      title: "General Guidance:",
      href:
        planType === PlanType.TRUST
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.LivingWill,
              HEALTHCARE_LIVINGWILL_ROUTES.GeneralGuidance
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.GeneralGuidance
            ]),
      items: [
        [
          `- ${capitalize(
            getSkippableAnswer(preferences.generalGuidance, intl)
          )}`
        ]
      ]
    }),
    [intl, planType, preferences.generalGuidance]
  );

  const choiceToProlongLife = useMemo<ReviewItem>(
    () => ({
      title: "Choice to Prolong Life:",
      href:
        planType === PlanType.TRUST
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.LivingWill,
              HEALTHCARE_LIVINGWILL_ROUTES.ProlongLife
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.ProlongLife
            ]),
      items: [
        [
          `- ${getSkippableAnswer(
            preferences.continueToProvideTreatment,
            intl
          )}`
        ]
      ]
    }),
    [intl, planType, preferences.continueToProvideTreatment]
  );

  const artificialNutritionAndHydration = useMemo<ReviewItem>(
    () => ({
      title: "Artificial Nutrition & Hydration:",
      href:
        planType === PlanType.TRUST
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.LivingWill,
              HEALTHCARE_LIVINGWILL_ROUTES.ArtificialNutrition
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.ArtificialNutrition
            ]),
      items: [
        [
          `- ${getSkippableAnswer(
            preferences.artificialNutrition,
            intl
          )}`
        ]
      ]
    }),
    [intl, planType, preferences.artificialNutrition]
  );

  const receiveMaximumPainReliefInAllCases = useMemo<ReviewItem>(
    () => ({
      title: "Receive Maximum Pain Relief in All Cases:",
      href:
        planType === PlanType.TRUST
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.LivingWill,
              HEALTHCARE_LIVINGWILL_ROUTES.PainRelief
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.PainRelief
            ]),
      items: [
        [`- ${getSkippableAnswer(preferences.reliefFromPain, intl)}`]
      ]
    }),
    [intl, planType, preferences.reliefFromPain]
  );

  const exceptionToReceivingMaximumPainRelief = useMemo<
    ReviewItem | undefined
  >(
    () =>
      preferences.reliefFromPainCondition
        ? {
            title: "Exception to Receiving Maximum Pain Relief:",
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.LivingWill,
                    HEALTHCARE_LIVINGWILL_ROUTES.PainReliefException
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.PainReliefException
                  ]),
            items: [
              [
                `- ${getSkippableAnswer(
                  preferences.reliefFromPainCondition,
                  intl
                )}`
              ]
            ]
          }
        : undefined,
    [intl, planType, preferences.reliefFromPainCondition]
  );

  const organDonation = useMemo<ReviewItem>(
    () => ({
      title: "Organ Donation:",
      href:
        planType === PlanType.TRUST
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.LivingWill,
              HEALTHCARE_LIVINGWILL_ROUTES.OrganDonation
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.OrganDonation
            ]),
      items: [
        [`- ${getSkippableAnswer(preferences.organDonation, intl)}`]
      ]
    }),
    [intl, planType, preferences.organDonation]
  );
  const areOrgansDonated = ARE_ORGANS_DONATED_KEYS.includes(
    preferences.organDonation || ""
  );

  const organDonationPurposes = useMemo(() => {
    if (!areOrgansDonated) {
      return [];
    }

    return [
      ...Array.from(
        new Set(
          (preferences.organDonationPurposes || []).map((purpose) =>
            startCase(purpose.toLowerCase())
          )
        )
      )
    ];
  }, [areOrgansDonated, preferences.organDonationPurposes]);

  const specificOrgansToDonate = useMemo(() => {
    if (!areOrgansDonated) {
      return [];
    }

    return [
      ...(preferences.specifiedOrgans || []).map((organs) => organs)
    ];
  }, [areOrgansDonated, preferences.specifiedOrgans]);

  const specificOrgansToDonateInfo = useMemo<ReviewItem | undefined>(
    () =>
      specificOrgansToDonate?.length
        ? {
            title: "Specific Organs to Donate:",
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.LivingWill,
                    HEALTHCARE_LIVINGWILL_ROUTES.OrganDonationList
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.OrganDonationList
                  ]),
            items: [specificOrgansToDonate]
          }
        : undefined,
    [specificOrgansToDonate, planType]
  );

  const organDonationPurposesInfo = useMemo<ReviewItem | undefined>(
    () =>
      organDonationPurposes?.length
        ? {
            title: "Organ Donation Purposes:",
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.LivingWill,
                    HEALTHCARE_LIVINGWILL_ROUTES.OrganDonationPurpose
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.OrganDonationPurpose
                  ]),
            items: [organDonationPurposes]
          }
        : undefined,
    [organDonationPurposes, planType]
  );

  const additionalNote = useMemo<ReviewItem>(
    () => ({
      title: "Additional Note:",
      href:
        planType === PlanType.TRUST
          ? getLinkFullUrl([
              ROUTES.Healthcare,
              HEALTHCARE_ROUTES.LivingWill,
              HEALTHCARE_LIVINGWILL_ROUTES.SpecialNote
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Healthcare,
              LAST_WILL_HEALTHCARE_ROUTES.SpecialNote
            ]),
      items: [
        [
          `${
            preferences.additionalNote
              ? getSkippableAnswer(preferences.additionalNote, intl)
              : "- None provided"
          }`
        ]
      ]
    }),
    [intl, planType, preferences.additionalNote]
  );

  const livingWillNestedInfo = useMemo<ReviewItem | undefined>(
    () =>
      preferences.specified
        ? {
            title: intl.formatMessage({
              defaultMessage: "Living Will"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.LivingWill,
                    HEALTHCARE_LIVINGWILL_ROUTES.CreateLivingWill
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.CreateLivingWill
                  ]),
            items: [
              generalGuidance,
              choiceToProlongLife,
              artificialNutritionAndHydration,
              receiveMaximumPainReliefInAllCases,
              exceptionToReceivingMaximumPainRelief,
              organDonation,
              specificOrgansToDonateInfo,
              organDonationPurposesInfo,
              additionalNote
            ].filter((item): item is ReviewItem => Boolean(item))
          }
        : {
            title: intl.formatMessage({
              defaultMessage: "Living Will"
            }),
            href:
              planType === PlanType.TRUST
                ? getLinkFullUrl([
                    ROUTES.Healthcare,
                    HEALTHCARE_ROUTES.LivingWill,
                    HEALTHCARE_LIVINGWILL_ROUTES.CreateLivingWill
                  ])
                : getLinkFullUrl([
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.Healthcare,
                    LAST_WILL_HEALTHCARE_ROUTES.CreateLivingWill
                  ]),
            items: [
              [
                "🚫 You chose not to create a living will at this time."
              ]
            ]
          },
    [
      additionalNote,
      artificialNutritionAndHydration,
      choiceToProlongLife,
      exceptionToReceivingMaximumPainRelief,
      generalGuidance,
      intl,
      planType,
      organDonation,
      organDonationPurposesInfo,
      receiveMaximumPainReliefInAllCases,
      specificOrgansToDonateInfo,
      preferences.specified
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        medicalPOANestedInfo,
        medicalAccessInfo,
        livingWillNestedInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [medicalPOANestedInfo, medicalAccessInfo, livingWillNestedInfo]
  );

  return items;
};

export default usePrimaryDrafterHealthCareReview;
