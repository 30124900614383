import { useFormField } from "@components/FormFields/hooks";
import InterstitialArchetype from "@components/archetypes/Interstitial";

const PartnerRemainderCompleteStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Super! You've finished allocating your Trust Remainder, ${partnerFirstName}.`
      }}
    />
  );
};

export default PartnerRemainderCompleteStep;
