import jsonLogic from "json-logic-js";
import { template } from "lodash";

import { replaceJsonLogicStepKeysWithFieldKeys } from "@utils/jsonLogic";

import { ITERATION_QUERY_KEY } from "./consts";
import { PlanFormStep } from "./types";
import { PlanType } from "@api/__generated__/globalTypes";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getNextStep = (
  { parent, child }: { parent?: PlanFormStep; child?: PlanFormStep },
  steps: PlanFormStep[],
  values: Record<string, unknown>
) => {
  const nextStepKey = ((): string | undefined => {
    if (child?.rules) {
      const rulesResult = jsonLogic.apply(child.rules, values);

      return typeof rulesResult === "string"
        ? rulesResult
        : rulesResult.path;
    }
    if (parent?.rules) {
      const rulesResult = jsonLogic.apply(parent.rules, values);

      return typeof rulesResult === "string"
        ? rulesResult
        : rulesResult.path;
    }

    return undefined;
  })();

  if (!nextStepKey) return undefined;

  if (nextStepKey.split("/").length > 1) {
    const [parentKey, childKey] = nextStepKey.split("/");
    const nextParent = steps.find((step) => step.path === parentKey);
    const nextChild = nextParent?.children?.find(
      (step) => step.path === childKey
    );

    if (nextParent && nextChild)
      return { parent: nextParent, child: nextChild };
  }

  let currentStepParent = parent;

  let currentStepChild = parent?.children?.find(
    (step) => step.path === nextStepKey
  );

  if (!currentStepChild) {
    currentStepParent = steps.find(
      (step) => step.path === nextStepKey
    );

    currentStepChild = currentStepParent?.children?.[0];
  }

  if (!currentStepParent && !currentStepChild) {
    // eslint-disable-next-line no-console
    console.warn(`Page with route ${nextStepKey} has not found`);
  }

  return {
    parent: currentStepParent,
    child: currentStepChild
  };
};

const getLoopStepsPath = (
  loopRoutes: PlanFormStep[],
  values: Record<string, unknown>,
  iteration: number
): { break?: boolean; path: string }[] => {
  let loopPathCounter = 0;
  let nextLoopStep: PlanFormStep | undefined = loopRoutes[0];

  if (!nextLoopStep.path) {
    return [];
  }

  const loopStepsPath: { break?: boolean; path: string }[] = [
    { path: nextLoopStep.path }
  ];

  while (nextLoopStep && loopPathCounter < loopRoutes.length + 1) {
    let nextLoopStepPath: string | undefined;
    let shouldBreakLoop = false;

    if (nextLoopStep.rules) {
      const rules = replaceJsonLogicStepKeysWithFieldKeys(
        nextLoopStep.rules,
        (key) => template(key)({ [ITERATION_QUERY_KEY]: iteration })
      );
      const rulesResult = jsonLogic.apply(rules, {
        ...values,
        [ITERATION_QUERY_KEY]: iteration
      });

      nextLoopStepPath =
        typeof rulesResult === "string"
          ? rulesResult
          : rulesResult?.path;

      shouldBreakLoop = rulesResult.break;
    } else {
      const loopStepIndex = loopRoutes.findIndex(
        // eslint-disable-next-line no-loop-func
        (route) => route?.path === nextLoopStep?.path
      );

      if (loopStepIndex >= 0) {
        nextLoopStep = loopRoutes[loopStepIndex + 1];
        nextLoopStepPath = nextLoopStep?.path;
      }
    }

    if (shouldBreakLoop && nextLoopStepPath) {
      loopStepsPath.push({
        break: shouldBreakLoop,
        path: nextLoopStepPath
      });

      break;
    }

    nextLoopStep = loopRoutes.find(
      (loopRoute) => loopRoute.path === nextLoopStepPath
    );

    if (
      nextLoopStep &&
      nextLoopStepPath &&
      !loopStepsPath.find((lps) => nextLoopStepPath === lps.path)
    ) {
      loopStepsPath.push({
        path: nextLoopStepPath
      });
    }

    loopPathCounter += 1;
  }

  return loopStepsPath;
};

const generateStorageKey = (planType: PlanType) =>
  `${planType}_PLAN_DATA`;

export { getNextStep, getLoopStepsPath, generateStorageKey };
