import {
  useTheme,
  Typography,
  Box,
  Card,
  Button
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import Image from "@components/Image";

import { CreatePlanCardProps } from "./types";

const CreatePlanCard: React.FC<CreatePlanCardProps> = ({
  name,
  image,
  path,
  isStarted,
  price
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        py: "1.25rem",
        px: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.625rem"
        }}
      >
        <Box textAlign="center">
          <Image
            height="128px"
            width="128px"
            src={image.src}
            alt={image.alt}
          />
        </Box>
        <Button href={path} variant="contained">
          {isStarted && (
            <FormattedMessage
              defaultMessage="Resume {name}"
              values={{ name }}
            />
          )}
          {!isStarted && (
            <FormattedMessage
              defaultMessage="Create {name}"
              values={{ name }}
            />
          )}
        </Button>
        {price && (
          <Box mt="1.25rem">
            <Typography
              align="center"
              variant="body2"
              color={theme.palette.grey[600]}
            >
              {price}
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default CreatePlanCard;
