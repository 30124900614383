import { FormProvider } from "react-hook-form";
import React from "react";
import { useIntl } from "react-intl";

import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";

import { UpdatePlanTodoProps } from "./type";
import { useUpdatePlanTodos } from "./hooks";
import * as Styled from "./styles";

const UpdatePlanTodo: React.FC<UpdatePlanTodoProps> = ({
  task,
  close
}) => {
  const intl = useIntl();
  const { onSubmit, methods } = useUpdatePlanTodos({
    task,
    close
  });

  return (
    <FormProvider {...methods}>
      <Styled.UpdatePlanTodoForm
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <BasicFormInput
          name="title"
          label={intl.formatMessage({
            defaultMessage: "Update title"
          })}
        />
      </Styled.UpdatePlanTodoForm>
    </FormProvider>
  );
};

export default UpdatePlanTodo;
