import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { useMemberPlansState } from "@providers/memberPlansState/context";

import { getEstatePlanTitle } from "../utils";

const DocsAndDataTitle = () => {
  const theme = useTheme();

  const { myRoles, trust, lastWill, petProtection } =
    useMemberPlansState();

  const pageTitle = getEstatePlanTitle(
    trust?.plan || lastWill?.plan || petProtection?.plan || null,
    myRoles
  );

  return (
    <Typography
      variant="h1"
      textAlign="center"
      margin={theme.spacing(2)}
    >
      {pageTitle}
    </Typography>
  );
};

export default DocsAndDataTitle;
