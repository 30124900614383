import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { PlanType } from "@api/__generated__/globalTypes";

import usePrimaryDrafterHealthCareReview from "./usePrimaryDrafterHealthCareReview";

const TrustReviewStep: React.FC = () => {
  const items = usePrimaryDrafterHealthCareReview({
    planType: PlanType.TRUST
  });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Let's confirm that we got this right.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default TrustReviewStep;
