/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppointmentPower {
  GENERAL = "GENERAL",
  LIMITED = "LIMITED",
}

export enum AssetType {
  BUSINESS_INTEREST = "BUSINESS_INTEREST",
  CASH = "CASH",
  ESTATE_BALANCE = "ESTATE_BALANCE",
  FINANCIAL_ACCOUNT = "FINANCIAL_ACCOUNT",
  LIFE_INSURANCE_POLICY = "LIFE_INSURANCE_POLICY",
  OTHER = "OTHER",
  PERSONAL_PROPERTY = "PERSONAL_PROPERTY",
  PERSONAL_PROPERTY_BALANCE = "PERSONAL_PROPERTY_BALANCE",
  REAL_ESTATE = "REAL_ESTATE",
}

export enum DistribType {
  OUTRIGHT = "OUTRIGHT",
  POOLED_CHILDREN = "POOLED_CHILDREN",
  POOLED_FAMILY = "POOLED_FAMILY",
  SEPARATE_CHILDREN = "SEPARATE_CHILDREN",
  SEPARATE_INDIVIDUAL = "SEPARATE_INDIVIDUAL",
  SURVIVOR = "SURVIVOR",
}

export enum DistributionType {
  BACKUP_BENEFICIARIES = "BACKUP_BENEFICIARIES",
  CHARITY = "CHARITY",
  CO_PRIMARY_BENEFICIARIES = "CO_PRIMARY_BENEFICIARIES",
  DESCENDANTS = "DESCENDANTS",
}

export enum FileType {
  CUSTOM_DOCUMENT = "CUSTOM_DOCUMENT",
  HEALTH_CARE_DOCUMENT = "HEALTH_CARE_DOCUMENT",
  HIPAA_DOCUMENT = "HIPAA_DOCUMENT",
  JOINT_TRUST_DOCUMENT = "JOINT_TRUST_DOCUMENT",
  LAST_WILL_DOCUMENT = "LAST_WILL_DOCUMENT",
  PET_PROTECTION_DOCUMENT = "PET_PROTECTION_DOCUMENT",
  POA_DOCUMENT = "POA_DOCUMENT",
  POUR_OVER_WILL_DOCUMENT = "POUR_OVER_WILL_DOCUMENT",
  SEPARATE_TRUST_DOCUMENT = "SEPARATE_TRUST_DOCUMENT",
  TRUST_CERTIFICATE_DOCUMENT = "TRUST_CERTIFICATE_DOCUMENT",
}

export enum MembershipStatus {
  ACCEPTED = "ACCEPTED",
  BLOCKED = "BLOCKED",
  INVITED = "INVITED",
}

export enum OrganDonationPurpose {
  EDUCATION = "EDUCATION",
  OTHER = "OTHER",
  RESEARCH = "RESEARCH",
  THERAPY = "THERAPY",
  TRANSPLANT = "TRANSPLANT",
}

export enum PartnerType {
  FIANCE = "FIANCE",
  PARTNER = "PARTNER",
  SINGLE = "SINGLE",
  SPOUSE = "SPOUSE",
}

export enum PermissionLevel {
  EDIT = "EDIT",
  OWNER = "OWNER",
  VIEW = "VIEW",
  VIEW_ROLE = "VIEW_ROLE",
}

export enum PersonalPropertyBalanceDistributionType {
  CHILDREN = "CHILDREN",
  NAMED_BENEFICIARIES = "NAMED_BENEFICIARIES",
  OTHER_PEOPLE = "OTHER_PEOPLE",
  PARTNER = "PARTNER",
  PARTNER_AND_CHILDREN = "PARTNER_AND_CHILDREN",
}

/**
 * Plan documents
 */
export enum PlanDocument {
  CUSTOM_DOCUMENT = "CUSTOM_DOCUMENT",
  HEALTH_CARE_DOCUMENT = "HEALTH_CARE_DOCUMENT",
  HIPAA_DOCUMENT = "HIPAA_DOCUMENT",
  JOINT_TRUST_DOCUMENT = "JOINT_TRUST_DOCUMENT",
  LAST_WILL_DOCUMENT = "LAST_WILL_DOCUMENT",
  PET_PROTECTION_DOCUMENT = "PET_PROTECTION_DOCUMENT",
  POA_DOCUMENT = "POA_DOCUMENT",
  POUR_OVER_WILL_DOCUMENT = "POUR_OVER_WILL_DOCUMENT",
  SEPARATE_TRUST_DOCUMENT = "SEPARATE_TRUST_DOCUMENT",
  TRUST_CERTIFICATE_DOCUMENT = "TRUST_CERTIFICATE_DOCUMENT",
}

export enum PlanOption {
  COUPLE = "COUPLE",
  JOINT = "JOINT",
  SINGLE = "SINGLE",
}

export enum PlanStatus {
  CANCELLED = "CANCELLED",
  NEW = "NEW",
  PAID = "PAID",
}

export enum PlanType {
  LAST_WILL = "LAST_WILL",
  PET_PROTECTION = "PET_PROTECTION",
  TRUST = "TRUST",
}

export enum Restrictions {
  LIMITED_RESTRICTIONS = "LIMITED_RESTRICTIONS",
  STANDARD_RESTRICTIONS = "STANDARD_RESTRICTIONS",
}

export enum StateCode {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export enum TerminationDistribution {
  OUTRIGHT = "OUTRIGHT",
  SEPARATE_CHILDREN = "SEPARATE_CHILDREN",
}

export enum TrustType {
  POOLED_CHILDREN = "POOLED_CHILDREN",
  POOLED_FAMILY = "POOLED_FAMILY",
  SEPARATE_CHILDREN = "SEPARATE_CHILDREN",
  SEPARATE_INDIVIDUAL = "SEPARATE_INDIVIDUAL",
  SURVIVOR = "SURVIVOR",
}

export enum UserRole {
  CUSTOMER = "CUSTOMER",
  FINANCIAL_ADVISOR = "FINANCIAL_ADVISOR",
}

export enum VestingType {
  AGE_BASED = "AGE_BASED",
  HYBRID = "HYBRID",
  ON_DEATH = "ON_DEATH",
}

export interface AcceptInvitationInput {
  token: string;
}

export interface AcceptMembershipInvitationInput {
  token: string;
}

export interface AddressCreateInput {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  county?: string | null;
  zip?: string | null;
  country?: string | null;
}

export interface AdvisorShortUpdateInput {
  firstName?: string | null;
  lastName?: string | null;
}

export interface AssetCreateInput {
  lastWillId: number;
  name?: string | null;
  type: AssetType;
  subtype?: string | null;
  institution?: string | null;
  grossValue?: number | null;
  liabilityValue?: number | null;
  isGift?: boolean | null;
  isFunded?: boolean | null;
  address?: AddressCreateInput | null;
  beneficiaries: BeneficiaryCreateInput[];
  trustId?: number | null;
}

export interface AssetUpdateInput {
  name?: string | null;
  type: AssetType;
  subtype?: string | null;
  institution?: string | null;
  grossValue?: number | null;
  liabilityValue?: number | null;
  isGift?: boolean | null;
  isFunded?: boolean | null;
  address?: AddressCreateInput | null;
  beneficiaries: BeneficiaryCreateInput[];
  trustId?: number | null;
}

export interface BeneficiaryCreateInput {
  personId?: string | null;
  name?: string | null;
  dollarValue?: number | null;
  percentageValue?: number | null;
  backupDistribution?: DistributionType | null;
  backupBeneficiaries?: BeneficiaryCreateInput[] | null;
  charity?: InstitutionCreateInput | null;
}

export interface InstitutionCreateInput {
  name: string;
  ein?: string | null;
  isCharity?: boolean | null;
  address?: AddressCreateInput | null;
}

export interface InviteClientInput {
  clientName: string;
  clientEmail: string;
  recommendedPlan?: PlanType | null;
  message?: string | null;
}

export interface InviteMemberInput {
  planId: number;
  userId?: number | null;
  fullName: string;
  email: string;
  role: string;
  permissionLevel: PermissionLevel;
  message?: string | null;
}

export interface UpdateFileInput {
  name: string;
}

export interface UpdateMembershipInput {
  permissionLevel: PermissionLevel;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
