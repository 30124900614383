/* eslint-disable react/no-unescaped-entities */
import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPowersDelegatePowersStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to enable ${partnerFirstName}'s agent to delegate their powers under this financial power of attorney?`,
        subText: `Learn more about delegating powers.`,
        moreInfoModalProps: {
          title: "Authority to Delegate Powers",
          togglerLabel: "Learn more",
          content: (
            <p>
              Granting your agent the authority to delegate his or her
              powers means that your agent can entrust another person
              with your agent's responsibilities.
              <br />
              <br />
              This authority to delegate may be helpful if your
              primary agent is temporarily unavailable and needs
              someone else to serve for a period of time.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.1.poa.additionalPowers.delegate"
    />
  );
};

export default PartnerPowersDelegatePowersStep;
