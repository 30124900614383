import { PlanType } from "@api/__generated__/globalTypes";
import { DEFAULT_PLAN_TYPE } from "@setup/consts/misc";
import LastWillImage from "@assets/images/docIcons/DocumentIconLastWillpng.png";
import TrustImage from "@assets/images/docIcons/DocumentIconTrust.png";
import PetImage from "@assets/images/docIcons/DocumentIconPetProtection.png";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { PET_ALL_ABOUT_YOU_ROUTES } from "@pages/PetPlan/sections/AllAboutYou/consts";
import { LAST_WILL_ALL_ABOUT_YOU_ROUTES } from "@pages/LastWill/sections/AllAboutYou/consts";
import { TRUST_ALL_ABOUT_YOU_ROUTES } from "@pages/Trust/sections/AllAboutYou/consts";
import { ROUTES } from "@setup/consts/routes";

import {
  EstatePlansMatchmakingFormValues,
  EstatePlansMatchmakingItems,
  MatchMakingResults
} from "./types";

export const getMatchmakingResults = (
  data: EstatePlansMatchmakingFormValues,
  items: EstatePlansMatchmakingItems[]
): MatchMakingResults => {
  const selectedItems = items.filter(({ value }) =>
    data.matchmakingOptions.includes(value.toString())
  );

  const hasSelectedTrust = selectedItems.some(
    ({ planType }) => planType === PlanType.TRUST
  );

  const hasSelectedLastWill = selectedItems.some(
    ({ planType }) => planType === PlanType.LAST_WILL
  );

  const hasSelectedPet = selectedItems.some(
    ({ planType }) => planType === PlanType.PET_PROTECTION
  );

  if (hasSelectedTrust) {
    const explainers: string[] = [
      "Your revocable living trust plan allows you to designate who should receive the stuff you own and provides maximum control over the rules governing those gifts."
    ];

    explainers.push(
      selectedItems.some(
        ({ value }) => value === "I want to keep my gifts private"
      )
        ? "Furthermore, trusts avoid probate, allowing you to keep gifts and other provisions of your trust private."
        : ""
    );

    explainers.push(
      selectedItems.some(
        ({ value }) => value === "I own property in multiple states"
      )
        ? "Since you own property in multiple states, you'll avoid the need for probate in all those states when you transfer that property to the trust."
        : ""
    );

    const rationale = explainers
      .filter((explainer) => explainer)
      .join(" ");

    return {
      planType: PlanType.TRUST,
      recommendation: "Revocable Living Trust",
      rationale,
      editLink: getLinkFullUrl([
        ROUTES.TrustStart,
        TRUST_ALL_ABOUT_YOU_ROUTES.Basics
      ]),
      image: {
        src: TrustImage,
        alt: "Trust Plan Icon"
      }
    };
  }

  if (hasSelectedLastWill) {
    const rationale = selectedItems.some(
      ({ value }) => value === "I have children under 18"
    )
      ? "Your Last Will allows you to designate who should care for your minor children, make gifts of things you own, and appoint a trusted individual to help wind up your affairs."
      : "Your Last Will allows you to make gifts of things you own and appoint a trusted individual to help wind up your affairs. Through the advance health care directive included complementary with your last will, you can also express certain health care preferences in case you are unable to communicate them later.";

    return {
      planType: PlanType.LAST_WILL,
      recommendation: "Last Will",
      rationale,
      editLink: getLinkFullUrl([
        ROUTES.LastWillStart,
        LAST_WILL_ALL_ABOUT_YOU_ROUTES.Basics
      ]),
      image: {
        src: LastWillImage,
        alt: "Last Will Plan icon"
      }
    };
  }

  if (hasSelectedPet) {
    const rationale =
      "Your Pet Estate Plan allows you to ensure the comfort and care of your pet no matter what";

    return {
      planType: PlanType.PET_PROTECTION,
      recommendation: "Pet Estate Plan",
      rationale,
      editLink: getLinkFullUrl([
        ROUTES.PetStart,
        PET_ALL_ABOUT_YOU_ROUTES.Basics
      ]),
      image: {
        src: PetImage,
        alt: "Pet Estate Plan icon"
      }
    };
  }

  return {
    planType: PlanType.LAST_WILL,
    recommendation: "Last Will",
    rationale:
      "Every American adult should have an estate plan regardless of your age, whether you are married or have children, or how much money you have.",
    editLink: getLinkFullUrl([
      ROUTES.LastWillStart,
      LAST_WILL_ALL_ABOUT_YOU_ROUTES.Basics
    ]),
    image: {
      src: LastWillImage,
      alt: "Last Will Plan icon"
    }
  };
};
