import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import BasicFormCheckbox from "@components/FormFields/FormCheckbox/BasicFormCheckbox";

import { SpecificGiftProps } from "./types";

const FundedToTrust: React.FC<SpecificGiftProps> = ({ label }) => {
  const { watch } = useFormContext();
  const isFunded = watch("isFunded");

  return (
    <Stack>
      <BasicFormCheckbox
        name="isFunded"
        defaultChecked={isFunded}
        label={
          label || (
            <Typography
              sx={{ my: "auto" }}
              variant="body2"
              color="grey.700"
            >
              <FormattedMessage defaultMessage="Asset is Funded to My Trust" />
            </Typography>
          )
        }
      />
    </Stack>
  );
};

export default FundedToTrust;
