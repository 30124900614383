import Redeem from "@mui/icons-material/Redeem";
import House from "@mui/icons-material/House";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DiamondIcon from "@mui/icons-material/Diamond";

import InterstitialArchetype from "@components/archetypes/Interstitial";

import { useSpecificGiftsWorkContent } from "../utils";

const SpecificGiftsStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title:
        "We'll now review any specific gifts that you want to make.",
      muiIconHeader: (
        <>
          <House /> <DirectionsCarIcon />
          <DiamondIcon />
          <Redeem />
        </>
      ),
      subText:
        "Examples of specific gifts might include specifying who should receive your house, car, or jewelry. Any specific gifts you make will be distributed prior to your residuary estate.",
      moreInfoModalProps: {
        title: "How Specific Gifts Work",
        togglerLabel: "Learn more.",
        content: useSpecificGiftsWorkContent()
      }
    }}
  />
);

export default SpecificGiftsStep;
