import { gql } from "@apollo/client";

import { PLAN_DRAFT_FRAGMENT } from "./fragments";

export const CREATE_PLAN_DRAFT = gql`
  ${PLAN_DRAFT_FRAGMENT}
  mutation CreatePlanDraft($data: JSON!, $type: PlanType!) {
    createPlanDraft(data: $data, type: $type) {
      ...PlanDraft
    }
  }
`;

export const UPDATE_PLAN_DRAFT = gql`
  ${PLAN_DRAFT_FRAGMENT}
  mutation UpdatePlanDraft(
    $type: PlanType!
    $data: JSON!
    $lastPath: String!
  ) {
    updatePlanDraft(type: $type, data: $data, lastPath: $lastPath) {
      ...PlanDraft
    }
  }
`;
