import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import {
  ExcludedPerson,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { RelationshipStatus } from "@setup/enums";
import {
  DistribType,
  PartnerType
} from "@api/__generated__/globalTypes";

import { TRUST_GIFTS_ROUTES } from "../../consts";

import {
  getIndividualRemainderGifts,
  getPooledTrustGift,
  getRemainderStrategyText
} from "./utils";

export const usePrimaryDrafterGiftsReviewTab = ({
  values,
  relationshipStatus,
  partnerType = PartnerType.SINGLE,
  excludePersons
}: {
  values: LastWillPlanValues;
  relationshipStatus: RelationshipStatus;
  partnerType: PartnerType;
  excludePersons: ExcludedPerson[];
}): ReviewItem[] => {
  const { formatMessage } = useIntl();

  const {
    users: [primaryDrafter]
  } = values;

  const isJointTrust = primaryDrafter.trust?.isJointTrust;

  const {
    everythingGoToPartner,
    remainderStrategy = "nameIndividually",
    remainderDistribType = DistribType.OUTRIGHT,
    remainderCopyTrustSetup = false
  } = primaryDrafter?.gifts || {};

  const { excludedPersons = [], includeNoContest } =
    primaryDrafter?.lastWill || {};

  const { estateBalance = [] } = primaryDrafter?.assets || {};

  const lowerPartnerType = partnerType.toLowerCase();

  const individualGiftsInfo = useMemo<ReviewItem[] | undefined[]>(
    () =>
      estateBalance[0].beneficiaries &&
      remainderDistribType !== DistribType.POOLED_CHILDREN &&
      remainderDistribType !== DistribType.POOLED_FAMILY
        ? getIndividualRemainderGifts(
            estateBalance[0].beneficiaries,
            everythingGoToPartner,
            isJointTrust
          )
        : [undefined],
    [
      estateBalance,
      everythingGoToPartner,
      isJointTrust,
      remainderDistribType
    ]
  );

  const pooledTrustInfo = useMemo<ReviewItem | undefined[]>(
    () =>
      estateBalance[0].beneficiaries &&
      (remainderDistribType === DistribType.POOLED_CHILDREN ||
        remainderDistribType === DistribType.POOLED_FAMILY)
        ? getPooledTrustGift(
            partnerType,
            estateBalance[0].beneficiaries
          )
        : [undefined],
    [estateBalance, partnerType, remainderDistribType]
  );

  const remainderStrategyInfo = useMemo<ReviewItem | undefined>(
    () =>
      remainderStrategy
        ? {
            title: formatMessage({
              defaultMessage: "Remainder Strategy:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Gifts,
              TRUST_GIFTS_ROUTES.RemainderStrategy
            ]),
            items: [
              getRemainderStrategyText(
                everythingGoToPartner,
                isJointTrust,
                lowerPartnerType,
                remainderStrategy,
                remainderDistribType,
                remainderCopyTrustSetup
              )
            ]
          }
        : undefined,
    [
      formatMessage,
      everythingGoToPartner,
      isJointTrust,
      lowerPartnerType,
      remainderStrategy,
      remainderDistribType,
      remainderCopyTrustSetup
    ]
  );

  const excludedPersonsInfo = useMemo<ReviewItem | undefined>(
    () =>
      excludePersons
        ? {
            title: formatMessage({
              defaultMessage: "Individuals Excluded From Your Trust:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Gifts,
              TRUST_GIFTS_ROUTES.DesignateExcludedPersons
            ]),
            items: excludedPersons?.map((person) => [
              [person.name, person.relationship]
                ?.filter(Boolean)
                .join(", ")
            ])
          }
        : {
            title: formatMessage({
              defaultMessage: "Individuals Excluded From Your Trust:"
            }),
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Gifts,
              TRUST_GIFTS_ROUTES.ExcludePerson
            ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not explicitly excluding individuals from inheriting under your trust.`
                })
              ]
            ]
          },
    [formatMessage, excludePersons, excludedPersons]
  );

  const noContestInfo = useMemo<ReviewItem>(
    () => ({
      title: formatMessage({
        defaultMessage: "No Contest Provision:"
      }),
      href: getLinkFullUrl([
        ROUTES.Trust,
        TRUST_SECTION_ROUTES.Gifts,
        TRUST_GIFTS_ROUTES.IncludeNoContest
      ]),
      items: includeNoContest
        ? [
            [
              formatMessage({
                defaultMessage: `You are including a no contest provision in your trust.`
              })
            ]
          ]
        : [
            [
              formatMessage({
                defaultMessage: `You are not including a no contest provision in your trust.`
              })
            ]
          ]
    }),
    [formatMessage, includeNoContest]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        remainderStrategyInfo,
        ...individualGiftsInfo,
        pooledTrustInfo,
        excludedPersonsInfo,
        noContestInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      remainderStrategyInfo,
      individualGiftsInfo,
      pooledTrustInfo,
      excludedPersonsInfo,
      noContestInfo
    ]
  );

  return items;
};
