/* eslint-disable react/no-unescaped-entities */
const getStateEstateTax = (
  state: string,
  assetValue: string
): JSX.Element => {
  switch (state) {
    case "Connecticut":
      return assetValue === "n/a" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Connecticut state estate tax. The threshold for state
          estate tax in Connecticut is $9.1 million in 2022.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for Connecticut state estate tax.
        </li>
      );
    case "District of Columbia":
      return assetValue === "n/a" ||
        assetValue === "$1,000,001 - $5,000,000" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for District of Columbia estate tax. The threshold for
          estate tax in the District of Columbia is $4.0 million in
          2022.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for estate tax in the District of Columbia.
        </li>
      );
    case "Iowa":
      return (
        <li>
          <strong>State Estate Tax. </strong>Iowa does not have a
          state estate tax. However, it does have a graduated
          inheritance tax on all gifts from estates greater than
          $25,000 in value. The rates vary based on the relationship
          of the person or entity receiving the gift to the deceased
          person, with the highest variable rate topping out at 15%.
        </li>
      );
    case "Illinois":
      return assetValue === "n/a" ||
        assetValue === "$1,000,001 - $5,000,000" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Illinois state estate tax. The threshold for state
          estate tax in Illinois is $4.0 million in 2022.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Illinois.
        </li>
      );
    case "Kentucky":
      return (
        <li>
          <strong>State Estate Tax. </strong>Kentucky does not have a
          state estate tax. However, it does impose an inheritance
          tax. The amount of the inheritance tax depends on the
          relationship of the beneficiary to the deceased person and
          the value of the property.
        </li>
      );
    case "Maine":
      return assetValue === "n/a" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Maine state estate tax. Maine has a graduated state
          estate tax rate on estates greater than $5.9 million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Maine.
        </li>
      );
    case "Maryland":
      return assetValue === "n/a" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Maryland state estate tax. Maryland taxes estates
          exceeding $5.0 million. In addition, Maryland has an
          inheritance tax on gifts to non-lineal descendants of the
          deceased person.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Maryland. However, certain gifts of
          property may be taxable under Maryland's inheritance tax,
          which applies to gifts made to non-lineal descendants.
        </li>
      );
    case "Massachusetts":
      return assetValue === "n/a" ||
        assetValue === "$1,000,001 - $5,000,000" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Massachusetts state estate tax. Massachusetts imposes a
          state estate tax on all estates greater than $1.0 million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Massachusetts.
        </li>
      );
    case "Minnesota":
      return assetValue === "n/a" ||
        assetValue === "$1,000,001 - $5,000,000" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Minnesota's state estate tax. Minnesota imposes a
          graduated state estate tax on all estates greater than $3.0
          million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Minnesota.
        </li>
      );
    case "Nebraska":
      return (
        <li>
          <strong>State Estate Tax. </strong> Nebraska imposes an
          inheritance tax. The amount of the inheritance tax depends
          on the relationship of the beneficiary to the deceased
          person and the value of the property.
        </li>
      );
    case "New Jersey":
      return (
        <li>
          <strong>State Estate Tax. </strong> New Jersey imposes an
          inheritance tax ranging from 11% to 16% on the transfer of
          real and personal property with a value of $500 or more to
          certain beneficiaries.
        </li>
      );
    case "New York":
      return assetValue === "n/a" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for New York state estate tax. New York imposes a graduated
          state estate tax on all estates greater than $6.1 million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in New York.
        </li>
      );
    case "Oregon":
      return assetValue === "n/a" ||
        assetValue === "$1,000,001 - $5,000,000" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Oregon state estate tax. Oregon imposes a graduated
          state estate tax on all estates greater than $1.0 million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Oregon.
        </li>
      );
    case "Rhode Island":
      return assetValue === "n/a" ||
        assetValue === "$1,000,001 - $5,000,000" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Rhode Island state estate tax. Rhode Island imposes a
          state estate tax on all estates greater than $1.5 million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Rhode Island. However, all estates
          in Rhode Island must still file a state estate tax return.
        </li>
      );
    case "Vermont":
      return assetValue === "n/a" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Vermont state estate tax. Vermont imposes a state estate
          tax on all estates greater than $5.0 million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Vermont.
        </li>
      );
    case "Washington":
      return assetValue === "n/a" ||
        assetValue === "$1,000,001 - $5,000,000" ||
        assetValue === "$5,000,001 - $10,000,000" ||
        assetValue === ">$10,000,000" ? (
        <li>
          <strong>State Estate Tax. </strong>This estate may be liable
          for Washington state estate tax. Washington imposes a state
          estate tax on all estates greater than $2.2 million.
        </li>
      ) : (
        <li>
          <strong>State Estate Tax. </strong>This estate is not liable
          for state estate tax in Washington.
        </li>
      );
    default:
      return (
        <li>
          <strong>State Estate Tax. </strong>
          {state} does not have a state estate or inheritance tax.
        </li>
      );
  }
};

export default getStateEstateTax;
