/* eslint-disable react/no-unescaped-entities */
import { useCallback, useMemo } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import {
  Beneficiary,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { RemainderStrategy } from "@hooks/useTrustEnums";
import { usePlanForm } from "@providers/planForm/context";
import {
  DistribType,
  PartnerType
} from "@api/__generated__/globalTypes";

const PartnerRemainderDistribTypeStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter, partner],
    children
  } = values;

  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");
  const lowerPartnerType = partnerType.toLowerCase();

  const { value: remainderStrategy } = useFormField(
    "users.1.gifts.remainderStrategy"
  );

  const { setValue: setCopyTrustSetup } = useFormField(
    "users.1.gifts.remainderCopyTrustSetup"
  );

  const { setValue: setEstateBalanceBeneficiaries } = useFormField<
    Beneficiary[]
  >("users.1.assets.estateBalance.0.beneficiaries");

  const childrenBeneficiaryObject = useMemo<
    Beneficiary[] | undefined
  >(
    () =>
      children && children.length > 0
        ? children.map((child) => ({
            name: child.name,
            percentageValue: 100 / children.length,
            distribType: DistribType.SEPARATE_CHILDREN,
            subTrust: {
              name: `Separate Children Trust for ${child.name}`,
              trustType: DistribType.SEPARATE_CHILDREN
            }
          }))
        : undefined,
    [children]
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const remainderDistribType = get(
        data,
        "users.1.gifts.remainderDistribType"
      );

      if (remainderDistribType === DistribType.POOLED_CHILDREN) {
        setCopyTrustSetup(false);
        setEstateBalanceBeneficiaries([]);
        setEstateBalanceBeneficiaries([
          {
            percentageValue: 100,
            distribType: DistribType.POOLED_CHILDREN,
            subTrust: {
              name: `${partner.firstName} ${partner.lastName} Family Pooled Children's Trust`,
              trustType: DistribType.POOLED_CHILDREN
            }
          }
        ]);
      }
      if (remainderDistribType === DistribType.POOLED_FAMILY) {
        setCopyTrustSetup(false);
        setEstateBalanceBeneficiaries([]);
        setEstateBalanceBeneficiaries([
          {
            percentageValue: 100,
            distribType: DistribType.POOLED_FAMILY,
            subTrust: {
              name: `${partner.firstName} ${partner.lastName} Family Trust`,
              trustType: DistribType.POOLED_FAMILY
            }
          }
        ]);
      }

      if (
        remainderDistribType === DistribType.SEPARATE_CHILDREN &&
        childrenBeneficiaryObject
      ) {
        setEstateBalanceBeneficiaries([]);
        setEstateBalanceBeneficiaries(childrenBeneficiaryObject);
      }
    },
    [
      setEstateBalanceBeneficiaries,
      setCopyTrustSetup,
      partner,
      childrenBeneficiaryObject
    ]
  );

  if (remainderStrategy === RemainderStrategy.ChildrenOnly) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `How does ${partner.firstName} want to make the Remainder distribution to ${partner.firstName}'s children?`,
          subText: "Learn more about these distribution types.",
          moreInfoModalProps: {
            title: "Distribution Types Explained",
            togglerLabel: "Learn more.",
            content: (
              <p>
                <strong>Outright Distributions.</strong> Outright
                distributions are gifts with no strings attached. They
                are made as soon after your death as practical, and
                your beneficiary can use the gift for any purpose.
                <br />
                <br />
                <strong>Separate Trusts.</strong> You can make gifts
                subject to greater protection or eligibility
                requirements by giving in separate trusts -- one for
                each of your beneficiaries.
                <br />
                <br />
                <strong>Pooled Trust for Children.</strong>{" "}
                Recommended for Trustmakers with young families, a
                Pooled Trust differs from a Separate Trust in that it
                initially creates a single trust benefitting all of
                your children from which your Trustee can make equal
                or unequal distributions to support your children's
                wellbeing. When your youngest child reaches a certain
                minimum age, you can choose to dissolve the Pooled
                Trust and gift the remaining amount outright or in
                Separate Trusts. This structure recognizes that prior
                lifetime gifts made to children of different ages may
                be different.
              </p>
            ),
            closeLabel: "Got it"
          }
        }}
        name="users.1.gifts.remainderDistribType"
        items={[
          {
            value: DistribType.OUTRIGHT,
            label: `Give outright`
          },
          {
            value: DistribType.SEPARATE_CHILDREN,
            label: `Give in separate trusts`
          },
          {
            value: DistribType.POOLED_CHILDREN,
            label: `Give in a pooled trust`
          }
        ]}
        {...{ onSubmit }}
      />
    );
  }

  // Default; remainderStrategy === partnerAndChildren
  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How does ${partner.firstName} want to make the distribution to ${primaryDrafter.firstName} and ${partner.firstName}'s children?`,
        subText: "Learn more about these distribution types.",
        moreInfoModalProps: {
          title: "Distribution Types Explained",
          togglerLabel: "Learn more.",
          content: (
            <p>
              <strong>Outright Distributions.</strong> Outright
              distributions are gifts with no strings attached. They
              are made as soon after your death as practical, and your
              beneficiary can use the gift for any purpose.
              <br />
              <br />
              <strong>Separate Trusts.</strong> You can make gifts
              subject to greater protection or eligibility
              requirements by giving in separate trusts -- one for
              each of your beneficiaries.
              <br />
              <br />
              <strong>Pooled Family Trust.</strong> Recommended for
              Trustmakers with young families, a Pooled Trust differs
              from a Separate Trust in that it initially creates a
              single trust benefitting your partner and your children.
              Your Trustee can make equal or unequal distributions to
              support your children's wellbeing. When your youngest
              child reaches a certain minimum age, you can choose to
              dissolve the Pooled Trust and gift the remaining amount
              outright or in separate trusts. This structure
              recognizes that prior lifetime gifts made to children of
              different ages may be different.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.1.gifts.remainderDistribType"
      items={[
        {
          value: DistribType.OUTRIGHT,
          label: `Give outright`
        },
        {
          value: DistribType.SEPARATE_INDIVIDUAL,
          label: `Give in separate trusts`
        },
        {
          value: DistribType.POOLED_FAMILY,
          label: `Give in a pooled family trust`
        }
      ]}
      {...{ onSubmit }}
    />
  );
};

export default PartnerRemainderDistribTypeStep;
