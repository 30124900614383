import { Outlet } from "react-router-dom";

import PlanFormProvider from "@providers/planForm";
import { ROUTES } from "@setup/consts/routes";
import { PlanType } from "@api/__generated__/globalTypes";

import routes from "./routes";
import { EstateAdminQuizValues } from "./types";

const EstateAdminQuiz: React.FC = () => (
  <PlanFormProvider<EstateAdminQuizValues>
    value={{
      parentPath: ROUTES.EstateAdminQuiz,
      steps: routes,
      planType: PlanType.LAST_WILL, // TODO: Come back to see if this matters later,
      mode: "localStorage"
    }}
  >
    <Outlet />
  </PlanFormProvider>
);

export default EstateAdminQuiz;
