import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const CreateLivingWillStep: React.FC = () => {
  const { setValue: setHealthcarePreferencesValue } = useFormField(
    "users.0.healthcare.preferences"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const specifyPreferences = get(
        data,
        "users.0.healthcare.preferences.specified"
      );

      if (!parseBoolean(specifyPreferences)) {
        setHealthcarePreferencesValue({});
        setHealthcarePreferencesValue({
          specified: false
        });
      }
    },
    [setHealthcarePreferencesValue]
  );

  return (
    <SingleSelectArchetype
      name="users.0.healthcare.preferences.specified"
      stepLayout={{
        title: `Would you like to specify your preferences for making healthcare decisions?`
      }}
      {...{ onSubmit }}
      items={[
        {
          label: "Yes, specify my preferences (7 questions)",
          value: true
        },
        {
          label: "No. I'll allow my agent to decide for me.",
          value: false
        }
      ]}
    />
  );
};

export default CreateLivingWillStep;
