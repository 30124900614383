/* eslint-disable react/no-array-index-key */
import { FormattedMessage } from "react-intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { grey } from "@mui/material/colors";
import { useCallback, useState } from "react";

import { isReviewItemsArray } from "./utils";
import { ReviewCardProps } from "./types";
import * as Styled from "./styles";

const ReviewCard: React.FC<ReviewCardProps> = ({
  items,
  icon,
  canEdit,
  ...props
}) => {
  const renderReviewHeader = useCallback(
    (reviewItem: ReviewCardProps) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "space-between",
          alignItems: "center",
          flexGrow: 1
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            zIndex: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: ".5rem"
              }}
            >
              {icon}
              <Typography
                component="h3"
                sx={{
                  width: "100%",
                  lineHeight: 1.6,
                  color: grey[600],
                  fontWeight: 500
                }}
              >
                {reviewItem.title}{" "}
              </Typography>
            </Box>
            {reviewItem.href && canEdit && (
              <Styled.Link
                sx={{
                  paddingTop: reviewItem?.description ? "2rem" : "0"
                }}
                to={reviewItem.href}
              >
                <FormattedMessage defaultMessage="Edit" />
              </Styled.Link>
            )}
          </Box>
          <Typography
            variant="body1"
            sx={{
              lineHeight: 1.6,
              color: grey[600]
            }}
          >
            {reviewItem?.description}{" "}
          </Typography>
        </Box>
        {canEdit && reviewItem.editComponent}
      </Box>
    ),
    [canEdit, icon]
  );

  const renderItems = useCallback(
    (subitems: string[][] | ReviewCardProps[] | undefined) => {
      if (!subitems || subitems.length === 0) return null;

      return (
        <Grid item xs={12}>
          {isReviewItemsArray(subitems) ? (
            subitems.map((item, index) => (
              <Grid
                item
                container
                mb={2}
                xs={12}
                spacing={1}
                key={`${item.title}_${index}`}
              >
                {renderReviewHeader(item)}
                {renderItems(item.items)}
              </Grid>
            ))
          ) : (
            <Grid container spacing={{ xs: 2, md: 5.5 }}>
              {subitems.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={12}
                  key={`${item[0]}_${index}`}
                >
                  {item.map((text, i) => (
                    <Typography key={`${text}_${i}`}>
                      {text}
                    </Typography>
                  ))}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      );
    },
    [renderReviewHeader]
  );

  const [expanded, setExpanded] = useState(false);
  const onChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <Accordion {...{ onChange, expanded }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {renderReviewHeader(props)}
      </AccordionSummary>
      <AccordionDetails>{renderItems(items)}</AccordionDetails>
    </Accordion>
  );
};

export default ReviewCard;
