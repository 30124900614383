import { useCallback } from "react";

import { AssetType } from "@api/__generated__/globalTypes";
import useToggle from "@hooks/useToggle";
import { AssetList } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";

import { AssetWithoutBackupBeneficiaries } from "../AssetForm/types";

export const useAddQuestionnaireAssetModal = (userIndex: string) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { value: assets, setValue: setAssetValue } =
    useFormField<AssetList>(`${userIndex}.assets`);

  const addNewAssets = useCallback(
    ({
      estateBalance = [],
      businessInterests = [],
      cash = [],
      charityContribution = [],
      financialAccounts = [],
      lifeInsurancePolicies = [],
      otherAssets = [],
      personalProperty = [],
      realEstates = []
    }: Partial<AssetList>) => {
      const state = {
        personalProperty: assets?.personalProperty ?? [],
        realEstates: assets?.realEstates ?? [],
        financialAccounts: assets?.financialAccounts ?? [],
        businessInterests: assets?.businessInterests ?? [],
        cash: assets?.cash ?? [],
        otherAssets: assets?.otherAssets ?? [],
        charityContribution: assets?.charityContribution ?? [],
        lifeInsurancePolicies: assets?.lifeInsurancePolicies ?? [],
        estateBalance: assets?.estateBalance ?? []
      };

      const newState = {
        personalProperty: [
          ...state.personalProperty,
          ...personalProperty
        ],
        realEstates: [...state.realEstates, ...realEstates],
        financialAccounts: [
          ...state.financialAccounts,
          ...financialAccounts
        ],
        businessInterests: [
          ...state.businessInterests,
          ...businessInterests
        ],
        cash: [...state.cash, ...cash],
        otherAssets: [...state.otherAssets, ...otherAssets],
        charityContribution: [
          ...state.charityContribution,
          ...charityContribution
        ],
        lifeInsurancePolicies: [
          ...state.lifeInsurancePolicies,
          ...lifeInsurancePolicies
        ],
        estateBalance: [...state.estateBalance, ...estateBalance]
      };

      setAssetValue(newState);
    },
    [assets, setAssetValue]
  );

  const onSubmit = useCallback(
    (data: AssetWithoutBackupBeneficiaries) => {
      let key: keyof AssetList | undefined;

      if (data.type === AssetType.REAL_ESTATE) {
        key = "realEstates";
      } else if (data.type === AssetType.PERSONAL_PROPERTY) {
        key = "personalProperty";
      } else if (data.type === AssetType.FINANCIAL_ACCOUNT) {
        key = "financialAccounts";
      } else if (data.type === AssetType.BUSINESS_INTEREST) {
        key = "businessInterests";
      } else if (data.type === AssetType.LIFE_INSURANCE_POLICY) {
        key = "lifeInsurancePolicies";
      } else if (data.type === AssetType.OTHER) {
        key = "otherAssets";
      }

      if (key) {
        addNewAssets({ [key]: [data] });
      }
      toggleOff();
    },
    [addNewAssets, toggleOff]
  );

  return {
    isOpen,
    toggleOn,
    toggleOff,
    onSubmit
  };
};
