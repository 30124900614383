import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";

const ExcludedPersonsStep: React.FC = () => {
  const { setValue: setExcludedPersons } = useFormField(
    `users.0.lastWill.excludedPersons`
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const excludePersons = get(
        data,
        "users.0.gifts.excludePersons"
      );

      if (!excludePersons) {
        setExcludedPersons([]);
      }
    },
    [setExcludedPersons]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to exclude one or more people from inheriting under your Trust?`
      }}
      {...{ onSubmit }}
      name="users.0.gifts.excludePersons"
    />
  );
};

export default ExcludedPersonsStep;
