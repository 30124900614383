import { Stack, FormControl } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useFiftyStateItems } from "@hooks/useFiftyStateItems";
import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";
import BasicFormSelect from "@components/FormFields/FormSelect/BasicFormSelect";
import BasicCurrencyFormInput from "@components/FormFields/FormInput/BasicCurrencyFormInput";

import SpecificGift from "./SpecificGift";
import FundedToTrust from "./FundedToTrust";

const OtherAssetForm = ({ showIsFunded = false }) => {
  const { formatMessage } = useIntl();
  const fiftyStateItems = useFiftyStateItems();

  const assetLocations = useMemo(
    () => [
      ...fiftyStateItems,
      {
        value: "Outside the United States",
        label: formatMessage({
          defaultMessage: "Outside the United States"
        })
      }
    ],
    [fiftyStateItems, formatMessage]
  );

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <BasicFormInput name="name" label="Asset Nickname" />
        <BasicFormInput name="subtype" label="Type of Asset" />
        <BasicFormSelect
          items={assetLocations}
          name="address.state"
          label="State Where Asset is Located"
        />
        <BasicCurrencyFormInput
          name="grossValue"
          label={formatMessage({
            defaultMessage: "Approximate Value ($USD)"
          })}
          InputProps={{
            startAdornment: `$`
          }}
        />
        {showIsFunded && <FundedToTrust />}
        <SpecificGift />
      </FormControl>
    </Stack>
  );
};

export default OtherAssetForm;
