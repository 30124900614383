import produce from "immer";
import get from "lodash/get";
import set from "lodash/set";
import { useEffect, useMemo } from "react";

import { usePlanForm } from "@providers/planForm/context";

const useFormField = <T = unknown>(
  fieldKey: string,
  defaultValue?: T
): { value: T; setValue: (value: T | null | undefined) => void } => {
  const { values, setValues } = usePlanForm();

  useEffect(() => {
    if (defaultValue === undefined) return;
    setValues((prev: Record<string, unknown>) =>
      produce(prev, (draft) => {
        if (!draft) {
          return;
        }

        if (get(draft, fieldKey, undefined) !== undefined) return;

        set(draft, fieldKey, defaultValue);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldKey, setValues]);

  return useMemo(
    () => ({
      value: get(values, fieldKey, defaultValue) as T,
      setValue: (value: T | null | undefined) =>
        setValues((prev: Record<string, unknown>) =>
          produce(prev, (draft) => {
            if (!draft) {
              return;
            }

            if (!fieldKey.trim() || fieldKey.startsWith(".")) {
              // eslint-disable-next-line no-console
              console.error(
                "Field key cannot be empty! Value won't be stored."
              );

              return;
            }

            set(draft, fieldKey, value);
          })
        )
    }),
    [values, fieldKey, defaultValue, setValues]
  );
};

export { useFormField };
