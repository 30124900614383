import { Stack, FormControl } from "@mui/material";
import { useIntl } from "react-intl";

import { usePersonalPropertyItems } from "@pages/EstatePlanView/Partials/components/GiftForms/usePersonalPropertyItems";
import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";
import BasicFormSelect from "@components/FormFields/FormSelect/BasicFormSelect";
import BasicCurrencyFormInput from "@components/FormFields/FormInput/BasicCurrencyFormInput";

import SpecificGift from "./SpecificGift";
import FundedToTrust from "./FundedToTrust";

const PersonalPropertyForm = ({ showIsFunded = false }) => {
  const personalPropertyItems = usePersonalPropertyItems();
  const { formatMessage } = useIntl();

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <BasicFormInput name="name" label="Item Name" />
        <BasicFormSelect
          items={personalPropertyItems}
          name="subtype"
          label="Type of Item"
        />
        <BasicCurrencyFormInput
          name="grossValue"
          label={formatMessage({
            defaultMessage: "Approximate Value ($USD)"
          })}
          InputProps={{
            startAdornment: `$`
          }}
        />
        {showIsFunded && <FundedToTrust />}
        <SpecificGift />
      </FormControl>
    </Stack>
  );
};

export default PersonalPropertyForm;
