import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const DistAllToPartnerConfirmedStep: React.FC = () => {
  const { value: everythingGoToPartner } = useFormField(
    `users.0.gifts.everythingGoToPartner`
  );
  const { value: firstName } = useFormField(`users.0.firstName`);
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  if (everythingGoToPartner === true) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `Great! We'll note to make that distribution to ${partnerFirstName} in the event that ${partnerFirstName} survives you.`,
          subText: `We'll now cover what you want to happen if you survive ${partnerFirstName}.`
        }}
      />
    );
  }

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Sounds good, ${firstName}.`,
        subText: `In that case, keep in mind that the Primary Beneficiary you choose for each of your gifts will be first in line to receive that gift. ${partnerFirstName} will only first receive gifts for which ${partnerFirstName} is designated as a Primary Beneficiary.`
      }}
    />
  );
};

export default DistAllToPartnerConfirmedStep;
