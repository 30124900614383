import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { Beneficiary } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";
import { usePlanForm } from "@providers/planForm/context";

import BeneficiaryForm from "../RemainderAllocate/partials/BeneficiaryForm";
import AllocationMessage from "../RemainderAllocate/partials/AllocationMessage";
import useBeneficiarySchema from "../RemainderAllocate/partials/BeneficiaryForm/hooks";
import FormHeader from "../RemainderAllocate/partials/FormHeader";

const PartnerRemainderBackupBeneficiariesStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();
  const singleItemSchema = useBeneficiarySchema();
  const { value } = useFormField<Beneficiary[]>(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`
  );
  const { value: currentBeneficiary } = useFormField<Beneficiary>(
    `users.1.assets.estateBalance.0.beneficiaries.${iteration}`
  );
  const { success } = useAllocationSum(value, {
    path: "percentageValue"
  });

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `Ok. Who do you you want to designate as backup beneficiaries for that ${currentBeneficiary.percentageValue}% share if ${currentBeneficiary.name} fails to survive you?`
      }}
      addBtnLabel={formatMessage({
        defaultMessage: "Add Another Backup Beneficiary"
      })}
      {...{ singleItemSchema }}
      customRemoveBtn
      SingleItem={BeneficiaryForm}
      name={`users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`}
      topContent={<FormHeader />}
      bottomContent={
        <AllocationMessage
          name={`users.1.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`}
        />
      }
      disabled={!success}
    />
  );
};

export default PartnerRemainderBackupBeneficiariesStep;
