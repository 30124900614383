import { makeMutationHook } from "@utils/apollo-utils";

import { SEND_MARKETING_DATA } from "./mutations";
import {
  SendMarketingData,
  SendMarketingDataVariables
} from "./__generated__/SendMarketingData";

export const useSendMarketingDataMutation = makeMutationHook<
  SendMarketingData,
  SendMarketingDataVariables
>(SEND_MARKETING_DATA);
