import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SelectItem } from "@components/FormFields/FormSelect/types";

export const useRelationshipItems = (): SelectItem[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({ defaultMessage: "Spouse" }),
        value: "Spouse"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Partner" }),
        value: "Partner"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Sister" }),
        value: "Sister"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Brother" }),
        value: "Brother"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Daughter" }),
        value: "Daughter"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Son" }),
        value: "Son"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Parent" }),
        value: "Parent"
      },
      {
        label: intl.formatMessage({ defaultMessage: "In-Law" }),
        value: "In-Law"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Grandparent" }),
        value: "Grandparent"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Aunt" }),
        value: "Aunt"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Uncle" }),
        value: "Uncle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Other Family Member"
        }),
        value: "Other Family Member"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Friend" }),
        value: "Friend"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Lawyer" }),
        value: "Lawyer"
      },
      {
        label: intl.formatMessage({ defaultMessage: "Accountant" }),
        value: "Accountant"
      }
    ],
    [intl]
  );

  return items;
};
