import LastWillImage from "@assets/images/docIcons/DocumentIconLastWillpng.png";
import TrustImage from "@assets/images/docIcons/DocumentIconTrust.png";
import PetImage from "@assets/images/docIcons/DocumentIconPetProtection.png";

export const lastWillPlanObject = {
  name: "Last Will",
  image: {
    src: LastWillImage,
    alt: "Last Will Plan Icon"
  },
  price: "$135 Individual / $185 Couples"
};

export const trustPlanObject = {
  name: "Trust",
  image: {
    src: TrustImage,
    alt: "Trust Plan Icon"
  },
  price: "$348 Individual / $398 Couples"
};

export const petPlanObject = {
  name: "Pet Plan",
  image: {
    src: PetImage,
    alt: "Pet Plan Icon"
  },
  price: "Free"
};
