import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import LockIcon from "@mui/icons-material/Lock";

const NoSharedPlansMessage: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      alignItems: "center"
    }}
  >
    <LockIcon
      sx={{
        display: "flex",
        justifyContent: "center",
        mx: "auto",
        fontSize: "2.875rem"
      }}
    />
    <Typography>
      <FormattedMessage defaultMessage="You don't have access to any shared plans at the moment. When another JIC Estates member shares a plan with you, those plan details will appear here automatically." />
    </Typography>
  </Box>
);

export default NoSharedPlansMessage;
