import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const CopyPersonalRepresentativesStep: React.FC = () => {
  const { value: primaryReps } = useFormField(
    `users.0.lastWill.personalRepresentatives`
  );
  const { value: primaryBackupReps } = useFormField(
    `users.0.lastWill.backupPersonalRepresentatives`
  );

  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setPartnerReps } = useFormField(
    "users.1.lastWill.personalRepresentatives"
  );
  const { setValue: setPartnerBackupReps } = useFormField(
    "users.1.lastWill.backupPersonalRepresentatives"
  );

  const onSubmit = useCallback(
    ({ copyPersonalRepresentatives }: SingleSelectFormValues) => {
      if (parseBoolean(copyPersonalRepresentatives)) {
        setPartnerReps(primaryReps);
        setPartnerBackupReps(primaryBackupReps);
      }
    },
    [
      setPartnerReps,
      primaryReps,
      setPartnerBackupReps,
      primaryBackupReps
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to appoint the same Personal Representatives and backup Personal Representatives as you?`
      }}
      {...{ onSubmit }}
      name="copyPersonalRepresentatives"
    />
  );
};

export default CopyPersonalRepresentativesStep;
