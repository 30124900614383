import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import {
  PlanOption,
  PersonalPropertyBalanceDistributionType
} from "@api/__generated__/globalTypes";

import { TRUST_FINAL_ARRANGEMENT_ROUTES } from "../FinalArrangements/consts";

import { TRUST_ASSETS_ROUTES } from "./consts";
import SectionsPreviewStep from "./steps/Preview";
import AddAssetsStep from "./steps/AddAssets";
import AssetOverviewStep from "./steps/AssetOverview";
import ListCashStep from "./steps/ListCash";
import ReviewStep from "./steps/Review";
import PersonalPropertyBalanceTypeStep from "./steps/PersonalPropertyBalance";
import PersonalPropertyBalanceOverviewStep from "./steps/PersonalPropertyBalanceOverview";
import MakeCashGiftStep from "./steps/MakeCashGift";
import PartnerTransitionStartStep from "./steps/PartnerTransitionStart";
import PartnerMakeCashGiftStep from "./steps/PartnerMakeCashGift";
import PartnerPersonalPropertyBalanceOverviewStep from "./steps/PartnerPersonalPropertyBalanceOverview";
import PartnerAddAssetsStep from "./steps/PartnerAddAssets";
import PartnerPersonalPropertyBalanceTypeStep from "./steps/PartnerPersonalPropertyBalance";
import ReviewTabsStep from "./steps/ReviewTabs";
import PersonalPropertyBalancePeopleStep from "./steps/PersonalPropertyBalancePeople";
import PartnerPersonalPropertyBalancePeopleStep from "./steps/PartnerPersonalPropertyBalancePeople";
import PartnerListCashStep from "./steps/PartnerListCash";

const assetsSteps: PlanFormStep[] = [
  {
    path: TRUST_ASSETS_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: TRUST_ASSETS_ROUTES.FundingOverview
  },
  {
    path: TRUST_ASSETS_ROUTES.FundingOverview,
    element: <AssetOverviewStep />,
    rules: TRUST_ASSETS_ROUTES.AddAssets
  },
  {
    path: TRUST_ASSETS_ROUTES.AddAssets,
    element: <AddAssetsStep />,
    rules: TRUST_ASSETS_ROUTES.PersonalPropertyBalanceOverview
  },
  {
    path: TRUST_ASSETS_ROUTES.PersonalPropertyBalanceOverview,
    element: <PersonalPropertyBalanceOverviewStep />,
    rules: TRUST_ASSETS_ROUTES.PersonalPropertyBalance
  },
  {
    path: TRUST_ASSETS_ROUTES.PersonalPropertyBalance,
    element: <PersonalPropertyBalanceTypeStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.gifts.personalPropertyBalanceType"
            },
            PersonalPropertyBalanceDistributionType.OTHER_PEOPLE
          ]
        },
        TRUST_ASSETS_ROUTES.PersonalPropertyBalancePeople,
        TRUST_ASSETS_ROUTES.MakeCashGifts
      ]
    }
  },
  {
    path: TRUST_ASSETS_ROUTES.PersonalPropertyBalancePeople,
    element: <PersonalPropertyBalancePeopleStep />,
    rules: TRUST_ASSETS_ROUTES.MakeCashGifts
  },
  {
    path: TRUST_ASSETS_ROUTES.MakeCashGifts,
    element: <MakeCashGiftStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.gifts.makeCashGifts" }, true]
        },
        TRUST_ASSETS_ROUTES.ListCash,
        {
          if: [
            {
              and: [
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                },
                {
                  "!=": [{ var: "users.0.trust.isJointTrust" }, true]
                }
              ]
            },
            TRUST_ASSETS_ROUTES.PartnerTransitionStart,
            {
              if: [
                {
                  "==": [{ var: "users.0.trust.isJointTrust" }, true]
                },
                TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalanceOverview,
                TRUST_ASSETS_ROUTES.Review
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: TRUST_ASSETS_ROUTES.ListCash,
    element: <ListCashStep />,
    rules: {
      if: [
        {
          "==": [{ var: "planOption" }, PlanOption.COUPLE]
        },
        TRUST_ASSETS_ROUTES.PartnerTransitionStart,
        {
          if: [
            {
              "==": [{ var: "users.0.trust.isJointTrust" }, true]
            },
            TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalanceOverview,
            TRUST_ASSETS_ROUTES.Review
          ]
        }
      ]
    }
  },
  // PARTNER STARTS HERE
  {
    path: TRUST_ASSETS_ROUTES.PartnerTransitionStart,
    element: <PartnerTransitionStartStep />,
    rules: TRUST_ASSETS_ROUTES.PartnerAddAssets
  },
  {
    path: TRUST_ASSETS_ROUTES.PartnerAddAssets,
    element: <PartnerAddAssetsStep />,
    rules: TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalanceOverview
  },
  {
    path: TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalanceOverview,
    element: <PartnerPersonalPropertyBalanceOverviewStep />,
    rules: TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalance
  },
  {
    path: TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalance,
    element: <PartnerPersonalPropertyBalanceTypeStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.gifts.personalPropertyBalanceType"
            },
            PersonalPropertyBalanceDistributionType.OTHER_PEOPLE
          ]
        },
        TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalancePeople,
        TRUST_ASSETS_ROUTES.PartnerMakeCashGifts
      ]
    }
  },
  {
    path: TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalancePeople,
    element: <PartnerPersonalPropertyBalancePeopleStep />,
    rules: TRUST_ASSETS_ROUTES.PartnerMakeCashGifts
  },
  {
    path: TRUST_ASSETS_ROUTES.PartnerMakeCashGifts,
    element: <PartnerMakeCashGiftStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.1.gifts.makeCashGifts" }, true]
        },
        TRUST_ASSETS_ROUTES.PartnerListCash,
        TRUST_ASSETS_ROUTES.ReviewTabs
      ]
    }
  },
  {
    path: TRUST_ASSETS_ROUTES.PartnerListCash,
    element: <PartnerListCashStep />,
    rules: TRUST_ASSETS_ROUTES.ReviewTabs
  },
  {
    path: TRUST_ASSETS_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${TRUST_SECTION_ROUTES.FinalArrangements}/${TRUST_FINAL_ARRANGEMENT_ROUTES.Preview}`
  },
  {
    path: TRUST_ASSETS_ROUTES.ReviewTabs,
    element: <ReviewTabsStep />,
    rules: `${TRUST_SECTION_ROUTES.FinalArrangements}/${TRUST_FINAL_ARRANGEMENT_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={TRUST_ASSETS_ROUTES.Preview} />
  }
];

export default assetsSteps;
