import { Stack, FormControl } from "@mui/material";
import { useIntl } from "react-intl";

import { useFiftyStatePlusInternationalItems } from "@hooks/useFiftyStatePlusInternationalItems";
import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";
import BasicFormSelect from "@components/FormFields/FormSelect/BasicFormSelect";
import BasicCurrencyFormInput from "@components/FormFields/FormInput/BasicCurrencyFormInput";

import SpecificGift from "./SpecificGift";
import FundedToTrust from "./FundedToTrust";

const BusinessInterestForm = ({ showIsFunded = false }) => {
  const fiftyStateItems = useFiftyStatePlusInternationalItems();
  const { formatMessage } = useIntl();

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <Stack spacing={2}>
          <BasicFormInput name="name" label="Company Name" />
          <BasicFormSelect
            items={fiftyStateItems}
            name="address.state"
            label="State Where Business is Incorporated"
          />
          <BasicCurrencyFormInput
            name="grossValue"
            label={formatMessage({
              defaultMessage: "Approximate Value ($USD)"
            })}
            InputProps={{
              startAdornment: `$`
            }}
          />
        </Stack>
        {showIsFunded && <FundedToTrust />}
        <SpecificGift />
      </FormControl>
    </Stack>
  );
};

export default BusinessInterestForm;
