import { FormProvider } from "react-hook-form";
import Divider from "@mui/material/Divider/Divider";
import Box from "@mui/material/Box/Box";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import ArrayFormField from "@components/FormFields/ArrayForm";
import { useDisableForm } from "@providers/disableForm/context";

import { ArrayFormArchetypeProps, UnknownRecord } from "./types";
import { useArrayFormArchetype } from "./hooks";

const ArrayFormArchetype = <T extends UnknownRecord>({
  name,
  stepLayout,
  addBtnLabel,
  removeBtnLabel,
  singleItemSchema,
  SingleItem,
  onSubmit: onFormSubmit,
  topContent,
  bottomContent,
  disabled = false,
  customRemoveBtn
}: ArrayFormArchetypeProps<T>): React.ReactElement => {
  const { controls, goBack, onSubmit } = useArrayFormArchetype(
    name,
    singleItemSchema,
    onFormSubmit
  );

  const { isDisabled: isFormDisabled } = useDisableForm();

  return (
    <StepLayout {...stepLayout}>
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          {topContent}
          <ArrayFormField
            {...{
              name,
              addBtnLabel,
              removeBtnLabel,
              SingleItem,
              singleItemSchema,
              customRemoveBtn
            }}
          />
          {!!bottomContent && (
            <Box my={2.5}>
              <Divider />
              <Box mt={2}>{bottomContent}</Box>
            </Box>
          )}
          <FormStepFooter
            prevButtonProps={{
              type: "button",
              onClick: goBack
            }}
            nextButtonProps={{
              type: "submit",
              disabled: isFormDisabled || disabled
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default ArrayFormArchetype;
