import { useCallback } from "react";

import PersonDetailsArchetype from "@components/archetypes/PersonDetails";
import { usePlanForm } from "@providers/planForm/context";
import { Guardian, LastWillPlanValues } from "@pages/LastWill/types";
import { PersonFormValues } from "@components/archetypes/PersonDetails/types";
import { useFormField } from "@components/FormFields/hooks";

const DesignateGuardianStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { children = [] } = values;

  const { setValue: setPrimaryGuardianValue } =
    useFormField("children");

  const { setValue: setPartnerAsChildGuardian } = useFormField(
    "partnerAsChildGuardian"
  );

  const onSubmit = useCallback(
    (guardian: PersonFormValues) => {
      if (!guardian) {
        return;
      }
      const designatedGuardian = guardian[0] as Guardian;

      const childrenWithGuardian = children.map((child) => ({
        ...child,
        guardians: designatedGuardian
      }));

      setPrimaryGuardianValue(childrenWithGuardian);
      setPartnerAsChildGuardian(false);
    },
    [children, setPrimaryGuardianValue, setPartnerAsChildGuardian]
  );

  return (
    <PersonDetailsArchetype
      name="guardians.0"
      stepLayout={{
        title: "Who would you like to serve as primary Guardian?"
      }}
      personRole="Guardian"
      {...{ onSubmit }}
    />
  );
};

export default DesignateGuardianStep;
