import React from "react";
import { Helmet } from "react-helmet";

import { PageTitleProps } from "./types";

const PageTitle: React.FC<PageTitleProps> = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

export default PageTitle;
