export const LAST_WILL_HEALTHCARE_ROUTES = {
  Preview: "",
  CreateAHCD: "create-ahcd",
  PartnerCreateAHCD: "partner-create-ahcd",

  // Individual + Partner Medical POA starts here
  NominateAgent: "nominate-agent",
  DesignatePartnerAsAgent: "partner-as-agent",
  DesignateAgent: "designate-agent",
  NominateBackupAgent: "nominate-backup-agent",
  DesignateBackupAgent: "designate-backup-agent",
  PartnerNominateAgent: "partner-nominate-agent",
  PartnerDesignateAgent: "partner-designate-agent",
  PartnerNominateBackupAgent: "partner-nominate-backup-agent",
  PartnerDesignateBackupAgent: "partner-designate-backup-agent",

  // Medical Access Starts Here
  GrantMedicalAccess: "grant-medical-access",
  GrantSameMedicalAccess: "grant-same-medical-access",
  DesignateMedicalAccess: "designate-medical-access",
  PartnerGrantMedicalAccess: "partner-grant-medical-access",
  PartnerDesignateMedicalAccess: "partner-designate-medical-access",
  MedicalPOAComplete: "medical-poa-complete",

  // Living Will Starts Here
  LivingWillOverview: "living-will-overview",
  CreateLivingWill: "living-will-create",
  GeneralGuidance: "general-guidance",
  ProlongLife: "prolong-life",
  ArtificialNutrition: "artificial-nutrition",
  PainRelief: "pain-relief",
  PainReliefException: "pain-relief-exception",
  PainReliefExceptionList: "pain-relief-exception-list",
  OrganDonation: "donate-organs",
  OrganDonationList: "donate-organs-list",
  OrganDonationPurpose: "donate-organs-purpose",
  SpecialNote: "leave-note",
  SpecialNoteDetails: "leave-note-details",

  // Partner Living Will Questions Start Here
  PartnerCreateLivingWill: "partner-living-will-create",
  PartnerSamePreferences: "partner-same-preferences",
  PartnerPreferenceConfirmation: "partner-confirm-preferences",
  PartnerGeneralGuidance: "partner-general-guidance",
  PartnerProlongLife: "partner-prolong-life",
  PartnerArtificialNutrition: "partner-artificial-nutrition",
  PartnerPainRelief: "partner-pain-relief",
  PartnerPainReliefException: "partner-pain-relief-exception",
  PartnerPainReliefExceptionList:
    "partner-pain-relief-exception-list",
  PartnerOrganDonation: "partner-donate-organs",
  PartnerOrganDonationList: "partner-donate-organs-list",
  PartnerOrganDonationPurpose: "partner-donate-organs-purpose",
  PartnerSpecialNote: "leave-note-partner",
  PartnerSpecialNoteDetails: "leave-note-details-partner",

  // Review Questions Start Here
  Review: "review",
  ReviewTabs: "review-tabs"
};

export const ARE_ORGANS_DONATED_KEYS = [
  "Give only specific organs, tissues, or parts",
  "Give any needed organs, tissues, or parts"
];
