export const TRUST_ASSETS_ROUTES = {
  // PrimaryDrafter Starts
  Preview: "",
  FundingOverview: "overview",
  AddAssets: "add-assets",
  PersonalPropertyBalanceOverview:
    "personal-property-balance-overview",
  PersonalPropertyBalance: "personal-property-balance",
  PersonalPropertyBalancePeople: "personal-property-balance-people",
  MakeCashGifts: "add-cash-gifts",
  ListCash: "cash-gifts",

  // Partner Starts Here
  PartnerTransitionStart: "partner-start",
  PartnerAddAssets: "partner-add-assets",
  PartnerPersonalPropertyBalanceOverview:
    "partner-personal-property-balance-overview",
  PartnerPersonalPropertyBalance: "partner-personal-property-balance",
  PartnerPersonalPropertyBalancePeople:
    "partner-personal-property-balance-people",
  PartnerMakeCashGifts: "partner-add-cash-gifts",
  PartnerListCash: "partner-cash-gifts",

  // Review
  Review: "review",
  ReviewTabs: "review-tabs"
};
