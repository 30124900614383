import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import {
  DistribType,
  PartnerType
} from "@api/__generated__/globalTypes";
import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const RemainderPooledOverviewStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");
  const { value: remainderDistribType } = useFormField(
    "users.0.gifts.remainderDistribType"
  );

  const beneficiary =
    remainderDistribType === DistribType.POOLED_CHILDREN
      ? "your children"
      : `your ${partnerType.toLowerCase()} and children`;

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Great! We'll set up a pooled trust to benefit ${beneficiary}.`,
        subText: `Next, we'll finish setting up the Pooled Trust by establishing the standard for making distributions and the event at which the Pooled Trust will terminate.`,
        muiIconHeader: <CheckCircleOutlineIcon />
        // TODO Add moreInfo modal
      }}
    />
  );
};

export default RemainderPooledOverviewStep;
