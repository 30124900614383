import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const DesignateBackupAgentStep: React.FC = () => (
  <PersonDetailsArrayArchetype
    stepLayout={{
      title: `Who do you want to serve as your backup agent?`
    }}
    personRole="Backup Agent"
    name="users.0.healthcare.backupAgents"
    addBtnLabel="Add next backup agent"
    removeBtnLabel="Remove backup agent"
  />
);

export default DesignateBackupAgentStep;
