import React from "react";
import noop from "lodash/noop";

import { UserContextValue } from "./types";

export const UserContext = React.createContext<UserContextValue>({
  user: undefined,
  cognitoUser: undefined,
  setCognitoUser: noop,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshUserData: async () => {}
});
