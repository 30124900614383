import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import {
  Beneficiary,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import { parseBoolean } from "@utils/parseBoolean";
import usePrimaryDraftersChildren from "@hooks/usePrimaryDraftersChildren";

const PartnerResidueToChildrenStep: React.FC = () => {
  const {
    value: residuePercentAllocated,
    setValue: setResiduePercentAllocated
  } = useFormField<number>("partnerResiduePercentAllocated");

  const {
    value: residuePercentRemaining,
    setValue: setResiduePercentRemaining
  } = useFormField<number>("partnerResiduePercentRemaining");

  const { value: partnerFirstName } = useFormField<string>(
    "users.1.firstName"
  );

  const { value: giveSpecificGifts } = useFormField(
    "users.1.gifts.giveSpecificGifts"
  );

  const { value: residueGift, setValue: setResidueGift } =
    useFormField<Beneficiary[]>(
      "users.1.assets.estateBalance.0.beneficiaries"
    );

  const {
    values: {
      children,
      users: [, partner]
    }
  } = usePlanForm<LastWillPlanValues>();

  const primaryDraftersChildren = usePrimaryDraftersChildren(
    partner?.firstName,
    children
  );

  const onSubmit = useCallback(
    ({ partnerResidueBalanceToChildren }: SingleSelectFormValues) => {
      const numberOfChildrens = primaryDraftersChildren?.length ?? 0;

      if (
        parseBoolean(partnerResidueBalanceToChildren) &&
        numberOfChildrens > 0
      ) {
        const amountPerEach =
          residuePercentRemaining / numberOfChildrens;

        setResidueGift([
          ...(primaryDraftersChildren?.map((child) => ({
            name: child.name,
            percentageValue: amountPerEach
          })) ?? [])
        ]);

        setResiduePercentAllocated(
          residuePercentAllocated + residuePercentRemaining
        );
        setResiduePercentRemaining(0);
      }

      if (!parseBoolean(partnerResidueBalanceToChildren)) {
        const primaryDraftersChildrenNames = [
          ...(primaryDraftersChildren?.map((child) => child?.name) ??
            [])
        ];

        const childrenWithGift = residueGift?.filter(
          (gift) =>
            gift?.name &&
            primaryDraftersChildrenNames.includes(gift?.name)
        );

        const allocatedPercentForChildrens = childrenWithGift?.reduce(
          (accumulator, current) =>
            accumulator + (current?.percentageValue ?? 0),
          0
        );

        setResidueGift([
          ...(residueGift?.filter(
            (gift) =>
              !primaryDraftersChildrenNames.includes(gift?.name ?? "")
          ) ?? [])
        ]);

        setResiduePercentRemaining(allocatedPercentForChildrens);
        setResiduePercentAllocated(
          100 - allocatedPercentForChildrens
        );
      }
    },
    [
      primaryDraftersChildren,
      residueGift,
      residuePercentAllocated,
      residuePercentRemaining,
      setResidueGift,
      setResiduePercentAllocated,
      setResiduePercentRemaining
    ]
  );

  if (giveSpecificGifts) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `After completing your specific gifts, does ${partnerFirstName} want the balance of ${partnerFirstName}'s assets to be divided equally among ${partnerFirstName}'s children?`
        }}
        {...{ onSubmit }}
        name="partnerResidueBalanceToChildren"
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want the balance of ${partnerFirstName}'s assets to be divided equally among ${partnerFirstName}'s children?`
      }}
      {...{ onSubmit }}
      name="partnerResidueBalanceToChildren"
    />
  );
};

export default PartnerResidueToChildrenStep;
