import { videoTutorials } from "@setup/consts/videoTutorials";

import { SectionLinkItem } from "../../components/SectionQuickLinks/types";

export const clientResourceItems: SectionLinkItem[] = [
  {
    id: "checklist",
    title: "Checklist",
    href: "#checklist"
  },
  {
    id: "resources",
    title: "Resources",
    href: "#resources"
  },
  {
    id: "tutorials",
    title: "Tutorials",
    href: "#tutorials"
  }
];

export const advisorResourceItems: SectionLinkItem[] = [
  {
    id: "advisor-tutorials",
    title: "Advisor Tutorials",
    href: "#advisor-tutorials"
  },
  {
    id: "tutorials",
    title: "Client Tutorials",
    href: "#tutorials"
  },
  {
    id: "client-resources",
    title: "Client Resources",
    href: "#client-resources"
  }
];

const {
  advisorPortalOverview,
  planTierOverview,
  howToLinkClientAccount,
  howDocGenWorks,
  howToAccessClientFiles,
  howToSignYourWill,
  howToSignYourAHCD,
  howToSignYourPOA
} = videoTutorials;

export const advisorVideos = [
  advisorPortalOverview,
  planTierOverview,
  howToLinkClientAccount,
  howDocGenWorks,
  howToAccessClientFiles
];

export const sampleClientVideos = [
  howToSignYourWill,
  howToSignYourPOA,
  howToSignYourAHCD
];
