import { Box, Grid, Typography } from "@mui/material";

import { useSlideActions } from "../../hooks";

import { GenericSlideProps } from "./types";

const GenericSlide = ({
  name,
  description,
  customDocument,
  ...restProps
}: GenericSlideProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const actions = useSlideActions({ customDocument, ...restProps });

  return (
    <>
      <Typography align="center" variant="h5">
        {name}
      </Typography>
      <Typography mt="1.2rem" align="center" fontSize="1rem">
        {description}
      </Typography>
      <Grid
        container
        item
        xs={12}
        mt="1.5rem"
        justifyContent="space-around"
      >
        {actions.map(({ icon, label }, index) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer"
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={`${label}_${index}`}
          >
            {icon}
            <Typography fontSize="0.875rem">{label}</Typography>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default GenericSlide;
