import { HouseholdData } from "@pages/EstateAdminQuiz/types";

import FinancialSuccessMessage from "./SuccessMessage";
import FinancialFailureMessage from "./FailureMessage";
import checkFinancialMatch from "./checkFinancialMatch";

const useFinancialResults: React.FC = (
  householdData?: HouseholdData
) => {
  const isMatch = checkFinancialMatch(
    householdData?.income,
    householdData?.assetValue
  );

  return isMatch ? (
    <FinancialSuccessMessage />
  ) : (
    <FinancialFailureMessage />
  );
};

export default useFinancialResults;
