import { useEffect, useMemo } from "react";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { useFormField } from "@components/FormFields/hooks";
import { useDisableForm } from "@providers/disableForm/context";
import { DisableFormItems } from "@providers/disableForm/type";

const RepCompensationDollarStep: React.FC = () => {
  const { value } = useFormField<number>(
    "users.0.lastWill.representativesCompensationDollar"
  );

  const error = useMemo(
    () => +value < 0 || Number.isInteger(value) === false,
    [value]
  );

  const { setDisableItems: setExceededItem } = useDisableForm();

  useEffect(() => {
    setExceededItem((prev: DisableFormItems) => ({
      ...prev,
      "users.0.lastWill.representativesCompensationDollar": error
    }));
  }, [error, setExceededItem]);

  return (
    <SingleResponseArchetype
      stepLayout={{
        title: `What dollar amount would you like to offer your representative as compensation?`
      }}
      name="users.0.lastWill.representativesCompensationDollar"
      label="Compensation"
      fieldProps={{
        type: "number",
        defaultValue: "number",
        InputProps: {
          startAdornment: `$`,
          type: "number"
        },
        error,
        helperText: error
          ? "Compensation must be a whole number greater than $0"
          : ""
      }}
    />
  );
};

export default RepCompensationDollarStep;
