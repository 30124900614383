export const useSpecificGiftsWorkContent = () => (
  <p>
    Imagine everything that you own is in a neat row right in front of
    you.
    <br />
    <br />
    You can pick any particular asset out of that row and designate a
    specific person or charity to receive that specific asset --
    that&apos;s a specific gift.
    <br />
    <br />
    After you&apos;re done making your specific gifts, the remaining
    assets are scooped up into a bucket and gifted based on the
    designations you made for your Residuary Estate.
    <br />
    <br />
    Keep in mind the following when making specific gifts:
    <ol>
      <li>
        Specific gifts are made prior to gifts made from the Residuary
        Estate
      </li>
      <li>
        You only need to make specific gifts in instances in which you
        want a particular asset or item to go to someone in a manner
        different than your residuary estate. For example, you might
        gift your residuary estate in equal shares to your son and
        daughter but make a specific gift of your wedding ring to your
        daughter
      </li>
      <li>
        If there is a reasonably identifiable group of items that you
        would like to gift, you can make that item group a specific
        gift. For example, you might make a specific gift of &quot;my
        jewelry collection&quot;
      </li>
    </ol>
  </p>
);

export const usePersonalPropertyBalanceContent = () => (
  <p>
    Your personal property balance is made up of all personal property
    items that you are not designating as a specific gift.
    <br />
    <br />
    <strong>What constitutes a personal property item? </strong>{" "}
    Personal property items refer to physical objects that you can
    physically possess, touch, and move. These are things like cars,
    boats, jewelry, artwork, and other household items.
    <br />
    <br />
    <strong>
      How do I know if a personal property item will be distributed
      with my personal property balance?
    </strong>{" "}
    Except for those items you specifically identified and added a
    beneficiary in the prior step, all your remaining personal
    property items will be distributed with your personal property
    balance.
  </p>
);
