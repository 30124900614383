/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import { useIntl } from "react-intl";
// import { lowerCase } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LAST_WILL_POWER_OF_ATTORNEY_ROUTES } from "@pages/LastWill/sections/PowerOfAttorney/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { ROUTES } from "@setup/consts/routes";
import { CompensationTypes } from "@setup/consts/misc";
import { numberWithCommas } from "@utils/misc";
import { POWER_OF_ATTORNEY_ROUTES } from "@pages/PowerOfAttorney/steps/main/consts";

import { getLinkFullUrl } from "../utils";

export const usePoaReview = (): ReviewItem[] => {
  const { formatMessage } = useIntl();
  const { lastWill, trust } = useMemberPlansState();

  const primaryDrafterLastWill = trust
    ? trust.primaryDrafterLastWill
    : lastWill?.primaryDrafterLastWill;

  const canEdit = trust ? trust.canEdit : lastWill?.canEdit;

  // const { partnerType } = plan ?? {};

  const {
    agents = [],
    generalAuthority = false,
    powers,
    compensation = "",
    compensationLimit,
    compensationType
    // giftRecipientsType,
    // giftRecipientsList,
    // giftRecipientsAllowtoAgent,
    // giftRecipientsLimitType,
    // giftRecipientsLimitAmount
  } = primaryDrafterLastWill?.powerOfAttorney ?? {};

  const successorAgents = useMemo(
    () => agents?.filter((agent) => agent?.isSuccessor),
    [agents]
  );

  const primaryAgents = useMemo(
    () => agents?.filter((agent) => !agent?.isSuccessor),
    [agents]
  );

  const makePOA = useMemo<ReviewItem | undefined>(
    () =>
      primaryDrafterLastWill?.powerOfAttorney
        ? undefined
        : {
            title: formatMessage({
              defaultMessage: "Power of Attorney:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.POADedicatedOverviewStep
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.CreatePOA
                ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `🚫 You chose not to create a Power of Attorney at this time.`
                })
              ]
            ]
          },
    [
      formatMessage,
      trust,
      canEdit,
      primaryDrafterLastWill?.powerOfAttorney
    ]
  );

  const agentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      primaryAgents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Primary Agent:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.DesignateAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateAgent
                ]),
            items: [
              ...primaryAgents.map((agent) => [
                `${agent?.agent?.fullName ?? ""}`,
                `${agent?.agent?.address?.address1 ?? ""}`,
                `${agent?.agent?.address?.address2 ?? ""}`,
                agent?.agent?.address
                  ? `${agent?.agent?.address?.city}, ${agent?.agent?.address?.state?.name} ${agent?.agent?.address?.zip}`
                  : ""
              ])
            ]
          }
        : undefined,
    [formatMessage, trust, canEdit, primaryAgents]
  );

  const successorAgentsInfo = useMemo<ReviewItem | undefined>(
    () =>
      successorAgents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent
                ]),
            items: [
              ...successorAgents.map((agent) => [
                `${agent?.agent?.fullName ?? ""}`,
                `${agent?.agent?.address?.address1 ?? ""}`,
                `${agent?.agent?.address?.address2 ?? ""}`,
                agent?.agent?.address
                  ? `${agent?.agent?.address?.city}, ${agent?.agent?.address?.state?.name} ${agent?.agent?.address?.zip}`
                  : ""
              ])
            ]
          }
        : agents?.length
        ? {
            title: formatMessage({
              defaultMessage: "Successor Agents:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent
                ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not nominating a successor agent.`
                })
              ]
            ]
          }
        : undefined,
    [agents, trust, canEdit, formatMessage, successorAgents]
  );

  const agentPowersInfo = useMemo<ReviewItem | undefined>(
    () =>
      powers || generalAuthority
        ? {
            title: formatMessage({
              defaultMessage: "Agent's Authority:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.PowersToGrant
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersToGrant
                ]),
            items: [
              [
                formatMessage({
                  defaultMessage:
                    "Your agent's authority to act is limited to the following subjects:"
                }),
                generalAuthority
                  ? "- ✅ General authority to act over all common subjects."
                  : "",
                powers
                  ? `- ${powers
                      .map((power) => `${power?.toString()}`)
                      .join(", ")}`
                  : ""
              ].filter((item): item is string => Boolean(item))
            ]
          }
        : undefined,
    [canEdit, trust, formatMessage, generalAuthority, powers]
  );

  /* 
To be added once we have the giftRecipientsType
  const giftPowersDetailsInfo = useMemo<ReviewItem | undefined>(
    () =>
      powers?.includes("Make Gifts") // Refactor to ENUM value
        ? {
            title: formatMessage({
              defaultMessage: "Restrictions on Gift Making:"
            }),
            href: getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
              LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGifts
            ]),
            items: [
              [
                `Permissible Gift Recipients:`,
                `- ${
                  giftRecipientsType === "certainPeople"
                    ? [
                        giftRecipientsList
                      ]
                    : giftRecipientsType ===
                        "partnerAndChildrenOnly" && partnerType
                    ? `My ${lowerCase(
                        partnerType
                      )}, children, and descendants ONLY`
                    : giftRecipientsType
                }`,
                `${
                  giftRecipientsType === "Anyone"
                    ? [
                        `${
                          giftRecipientsAllowtoAgent
                            ? "- Includes ability to gift to people my agent supports"
                            : "- Does not include ability to gift to people my agent supports"
                        }`
                      ]
                    : []
                }`
              ],
              [
                `Gift Limit Per Year, Per Individual:`,
                `- ${giftRecipientsLimitType}
                ${
                  giftRecipientsLimitType ===
                  "Limit up to a certain amount"
                    ? `: $${numberWithCommas(
                        giftRecipientsLimitAmount
                      )}`
                    : []
                }`
              ]
            ]
          }
        : undefined,
    [
      powers,
      formatMessage,
      giftRecipientsType,
      giftRecipientsList,
      giftRecipientsAllowtoAgent,
      giftRecipientsLimitType,
      giftRecipientsLimitAmount,
      partnerType
    ]
  );
*/

  const compensationInfo = useMemo<ReviewItem | undefined>(
    () =>
      compensation
        ? {
            title: formatMessage({
              defaultMessage: "Agent Compensation:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  POWER_OF_ATTORNEY_ROUTES.AgentCompensation
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
                  LAST_WILL_POWER_OF_ATTORNEY_ROUTES.AgentCompensation
                ]),

            items: [
              [
                `${compensation}`,
                `${
                  compensation ===
                  CompensationTypes.specifyCompensationAmount
                    ? [
                        [
                          `$${numberWithCommas(compensationLimit)}`,
                          compensationType
                        ].join(" ")
                      ]
                    : []
                }`
              ]
            ]
          }
        : undefined,
    [
      compensation,
      canEdit,
      trust,
      formatMessage,
      compensationLimit,
      compensationType
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        makePOA,
        agentsInfo,
        successorAgentsInfo,
        agentPowersInfo,
        compensationInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      makePOA,
      agentsInfo,
      successorAgentsInfo,
      agentPowersInfo,
      compensationInfo
    ]
  );

  return items;
};
