import { useMemo } from "react";
import * as yup from "yup";
import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { Asset } from "@pages/LastWill/types";

import GiftCashForm from "./Partials/GiftCash/GiftCashForm";

const PartnerListCashStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const formSchema: yup.SchemaOf<
    Omit<Asset, "percent" | "backupDistribution" | "isFunded" | "id">
  > = useMemo(
    () =>
      yup.object().shape({
        name: yup.string(),
        type: yup.string(),
        subType: yup.string(),
        institution: yup.string(),
        grossValue: yup.string(),
        address: yup.object().shape({
          address1: yup.string(),
          address2: yup.string(),
          city: yup.string(),
          zip: yup.string(),
          state: yup.string(),
          country: yup.string()
        }),
        isGift: yup.boolean(),
        beneficiaries: yup.array().of(
          yup.object().shape({
            name: yup.string(),
            dollarValue: yup.number()
          })
        ),
        backupBeneficiaries: yup.array().of(
          yup.object().shape({
            name: yup.string().required(
              formatMessage({
                defaultMessage:
                  "Please enter the full name of the person who should receive this gift"
              })
            ),
            dollarValue: yup.number()
          })
        )
      }),
    [formatMessage]
  );

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `What cash gifts do you want to make?`
      }}
      addBtnLabel="Add Another Cash Gift"
      removeBtnLabel="- Remove Cash Gift"
      singleItemSchema={formSchema}
      // eslint-disable-next-line react/no-unstable-nested-components
      SingleItem={({ prefix, index }): React.ReactElement => (
        <GiftCashForm name={prefix} index={index} />
      )}
      name="users.1.assets.cash"
    />
  );
};

export default PartnerListCashStep;
