import InterstitialArchetype from "@components/archetypes/Interstitial";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

const PartnerTrusteeStartStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [_, partner]
  } = values;

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `We're going to make ${partner.firstName} Trustee of ${
          partner.trust?.name ??
          `The ${partner.firstName} ${partner.lastName} Revocable Living Trust`
        }.`,
        subText:
          "As Trustee, you'll have the authority to manage the trust and its assets.",
        moreInfoModalProps: {
          title: "What Does a Trustee Do?",
          togglerLabel: "Learn more.",
          content: (
            <p>
              A Trustee is an individual or entity responsible for
              managing the trust and its affairs.
              <br />
              <br />
              During your (the Trustmaker&apos;s) life, there are two
              primary duties of the Trustee:
              <ol>
                <li>
                  <strong>Manage trust assets. </strong> This includes
                  &quot;funding&quot; the trust by transferring assets
                  into the trust, making investment decisions, and
                  ensuring any real or tangible property assets are
                  maintained
                </li>
                <li>
                  <strong>Keep accurate records. </strong> You&apos;ll
                  need to keep accurate and detailed records of trust
                  transactions, including income received, expenses
                  paid, and any changes in the trust&apos;s assets.
                </li>
              </ol>
              The Trustee is responsible for acting in the
              Trustmaker&apos;s best interest, supporting the
              Trustmaker&apos;s needs, and making decisions that are
              prudent, reasonable, and in line with the
              Trustmaker&apos;s intentions.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
    />
  );
};

export default PartnerTrusteeStartStep;
