import { Healthcare_authorizedPerson as HealthcarePerson } from "@api/plans/__generated__/Healthcare";

import getAgentHierarchy from "./getAgentHierarchy";

const useHealthAgentHierarchy = (
  healthAgents: HealthcarePerson[]
) => {
  if (!healthAgents || healthAgents.length === 0) {
    return [];
  }

  const agents = healthAgents
    .filter((agent) => agent.isAgent)
    .map((agent) => ({
      isBackup: agent.isBackup,
      order: agent.order,
      name: agent.agent?.fullName
    }));

  const hierarchy = getAgentHierarchy(agents);

  return hierarchy;
};

export default useHealthAgentHierarchy;
