import { IconButton } from "@mui/material";
import React from "react";

import * as Styled from "./styles";
import { DropdownListProps, DropdownProps } from "./types";

const DropdownList: React.FC<DropdownListProps> = ({ items }) => (
  <Styled.DesktopSubMenuList>
    {items.map(
      ({
        id,
        label,
        onClick,
        href,
        items: subItemItems,
        isExternal,
        isDisabled
      }) => (
        <Styled.DesktopSubMenuListItem key={id}>
          <Styled.DesktopSubMenuLink
            onClick={(e): void => {
              if (isDisabled) e.preventDefault();

              if (onClick) onClick();
            }}
            variant="text"
            disabled={isDisabled}
            {...{ href, isExternal }}
          >
            {label}
          </Styled.DesktopSubMenuLink>
          {subItemItems && !!subItemItems.length && (
            <DropdownList items={subItemItems} />
          )}
        </Styled.DesktopSubMenuListItem>
      )
    )}
  </Styled.DesktopSubMenuList>
);

const Dropdown: React.FC<DropdownProps> = ({
  label,
  href,
  items,
  isRight,
  onClick,
  isIconButton,
  isExternal
}) => (
  <Styled.Wrapper>
    {isIconButton ? (
      <IconButton
        {...{ href, onClick }}
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
      >
        {label}
      </IconButton>
    ) : (
      <Styled.Link variant="text" {...{ href, onClick, isExternal }}>
        {label}
      </Styled.Link>
    )}
    {items && !!items.length && (
      <Styled.DesktopSubMenuListWrapper
        {...{ isRight }}
        className="desktop-submenu"
      >
        <Styled.DesktopSubMenuListContent>
          <DropdownList {...{ items }} />
        </Styled.DesktopSubMenuListContent>
      </Styled.DesktopSubMenuListWrapper>
    )}
  </Styled.Wrapper>
);

export default Dropdown;
