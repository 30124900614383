import React, { useCallback } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";

import { useFormField } from "@components/FormFields/hooks";

import { MultiSelectFormProps } from "./types";

const MultiSelectForm: React.FC<MultiSelectFormProps> = ({
  name,
  options,
  valueAsKey
}) => {
  const { value, setValue } = useFormField<
    (string | number | boolean)[]
  >(name, []);
  const {
    control,
    setValue: setRHFValue,
    formState: { errors }
  } = useFormContext();

  const fieldError = get(errors, `${name}.message`);

  const handleOnChange = useCallback(
    (checked: boolean, v: string | number | boolean) => {
      const valuesArray = value && Array.isArray(value) ? value : [];

      const newValue = checked
        ? valuesArray.filter((element) => element !== v)
        : [...valuesArray, v];

      setValue(newValue);
      setRHFValue(name, newValue, { shouldValidate: true });
    },
    [value, setValue, setRHFValue, name]
  );

  return (
    <Controller
      {...{ control, name }}
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      render={({ field }) => (
        <FormControl
          component="fieldset"
          error={!!fieldError}
          variant="standard"
        >
          <FormGroup>
            {options.map(({ label, value: optionValue }) => {
              const valuesArray = Array.isArray(value) ? value : [];

              const checked = valuesArray.includes(
                valueAsKey ? optionValue : label
              );

              return (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      {...field}
                      value={checked}
                      onChange={(): void =>
                        handleOnChange(
                          checked,
                          valueAsKey ? optionValue : label
                        )
                      }
                      size="small"
                    />
                  }
                  {...{ label, checked }}
                />
              );
            })}
          </FormGroup>
          {fieldError && (
            <FormHelperText>{fieldError}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default MultiSelectForm;
