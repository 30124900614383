import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const TransitionPrimaryStartStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `We're going to cover ${firstName}'s distributions first.`,
        muiIconHeader: <ThumbUpAltOutlinedIcon />
      }}
    />
  );
};

export default TransitionPrimaryStartStep;
