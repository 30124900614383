import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { PET_SECTION_ROUTES } from "@pages/PetPlan/consts";
import {
  ITERATION_QUERY_KEY,
  NEXT_ITERATION_RULE
} from "@providers/planForm/consts";

import { PET_CARE_ROUTES } from "../Care/consts";

import { PET_GUARDIANS_ROUTES } from "./consts";
import SectionsPreviewStep from "./steps/Preview";
import DesignateGuardianStep from "./steps/DesignateGuardian";
import DesignateBackupGuardiansStep from "./steps/DesignateBackupGuardians";
import NominateBackupGuardiansStep from "./steps/NominateBackupGuardian";
import DesignateSameGuardiansStep from "./steps/DesignateSameGuardians";
import ReviewStep from "./steps/Review";

const guardiansSteps: PlanFormStep[] = [
  {
    path: PET_GUARDIANS_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: PET_GUARDIANS_ROUTES.GuardianLoop
  },
  {
    path: PET_GUARDIANS_ROUTES.GuardianLoop,
    loop: {
      enabled: true,
      iterationCountSource: "pets"
    },
    children: [
      {
        path: PET_GUARDIANS_ROUTES.DesignateGuardian,
        element: <DesignateGuardianStep />,
        rules: PET_GUARDIANS_ROUTES.NominateBackupGuardian
      },
      {
        path: PET_GUARDIANS_ROUTES.NominateBackupGuardian,
        element: <NominateBackupGuardiansStep />,
        rules: {
          if: [
            {
              "==": [
                { var: "pets.${iteration}.nominateBackupGuardian" },
                true
              ]
            },
            PET_GUARDIANS_ROUTES.DesignateBackupGuardians,
            {
              if: [
                {
                  and: [
                    { "==": [{ var: ITERATION_QUERY_KEY }, "0"] },
                    { ">": [{ var: "numberOfPets" }, "1"] }
                  ]
                },
                PET_GUARDIANS_ROUTES.DesignateSameGuardians,
                PET_GUARDIANS_ROUTES.Review
              ]
            }
          ]
        }
      },
      {
        path: PET_GUARDIANS_ROUTES.DesignateBackupGuardians,
        element: <DesignateBackupGuardiansStep />,
        rules: {
          if: [
            {
              and: [
                { "==": [{ var: ITERATION_QUERY_KEY }, "0"] },
                { ">": [{ var: "numberOfPets" }, "1"] }
              ]
            },
            PET_GUARDIANS_ROUTES.DesignateSameGuardians,
            NEXT_ITERATION_RULE
          ]
        }
      },
      {
        path: PET_GUARDIANS_ROUTES.DesignateSameGuardians,
        element: <DesignateSameGuardiansStep />,
        rules: {
          if: [
            { "==": [{ var: "sameGuardian" }, true] },
            { path: PET_CARE_ROUTES.Review, break: true },
            NEXT_ITERATION_RULE
          ]
        }
      }
    ],
    rules: PET_CARE_ROUTES.Review
  },
  {
    path: PET_GUARDIANS_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${PET_SECTION_ROUTES.Care}/${PET_CARE_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={PET_GUARDIANS_ROUTES.Preview} />
  }
];

export default guardiansSteps;
