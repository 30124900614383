import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const HHFinancialAdviceStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `Would you like an investment checkup for your personal finances?`,
      subText:
        "Our financial and registered investment advisor partners offer complimentary consults for qualifying families."
    }}
    name="householdData.personalInvestmentHelp"
  />
);

export default HHFinancialAdviceStep;
