import { useFormField } from "@components/FormFields/hooks";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import InterstitialArchetype from "@components/archetypes/Interstitial";

const PartnerEverythingToPartnerConfirmedStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter, partner]
  } = values;

  const { value: everythingToPartner } = useFormField(
    "users.1.gifts.everythingGoToPartner"
  );

  const isJointTrust = primaryDrafter.trust?.isJointTrust;

  return everythingToPartner ? (
    <InterstitialArchetype
      stepLayout={{
        title: `Sounds good, ${partner.firstName}.`,
        subText: isJointTrust
          ? `In the event that you pass before ${primaryDrafter.firstName}, all your assets will pass to ${primaryDrafter.firstName} in the ${primaryDrafter.firstName} ${primaryDrafter.lastName} Survivor Trust. ${primaryDrafter.firstName} will be able to draw upon the Survivor Trust as needed.`
          : `In the event that you pass before ${primaryDrafter.firstName}, all your assets will pass to ${primaryDrafter.firstName}.`
      }}
    />
  ) : (
    <InterstitialArchetype
      stepLayout={{
        title: `Sounds good, ${partner.firstName}.`,
        subText: `In that case, keep in mind that if you pass before ${primaryDrafter.firstName}, ${primaryDrafter.firstName} will receive only those gifts and assets for which you've specifically designated ${primaryDrafter.firstName} as the primary beneficiary.`
      }}
    />
  );
};

export default PartnerEverythingToPartnerConfirmedStep;
