import { styled } from "@mui/material/styles";
import { Link as ReactLink } from "react-router-dom";

export const Link = styled(ReactLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  paddingLeft: "0.25rem",
  transition: "200ms ease",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.text.secondary
    }
  }
}));
