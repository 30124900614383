/* eslint-disable no-irregular-whitespace */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePetBreedsBird = (): AutocompleteBaseOption[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({ defaultMessage: `African Grey` }),
        value: "African Grey"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Amazon` }),
        value: "Amazon"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Caique` }),
        value: "Caique"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Canary` }),
        value: "Canary"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Cockatiel` }),
        value: "Cockatiel"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Eclectus` }),
        value: "Eclectus"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Finch` }),
        value: "Finch"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Hawk Headed Parrot`
        }),
        value: "Hawk Headed Parrot"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Large Cockatoo`
        }),
        value: "Large Cockatoo"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Large Conure` }),
        value: "Large Conure"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Large Parakeet`
        }),
        value: "Large Parakeet"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Lorie` }),
        value: "Lorie"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Lorikeet` }),
        value: "Lorikeet"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Lovebird` }),
        value: "Lovebird"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Macaw` }),
        value: "Macaw"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Mini-Macaw` }),
        value: "Mini-Macaw"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Parrotlet` }),
        value: "Parrotlet"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Pionus Parrot`
        }),
        value: "Pionus Parrot"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Poicephalus` }),
        value: "Poicephalus"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Small Cockatoo`
        }),
        value: "Small Cockatoo"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Small Conure` }),
        value: "Small Conure"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Small Parakeet`
        }),
        value: "Small Parakeet"
      }
    ],
    [intl]
  );

  return items;
};
