import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import {
  PartnerType,
  DistribType,
  TrustType
} from "@api/__generated__/globalTypes";
import { ROUTES } from "@setup/consts/routes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_GIFTS_ROUTES } from "@pages/Trust/sections/Gifts/consts";
import { Asset_beneficiaries as Beneficiary } from "@api/plans/__generated__/Asset";
import { SubTrust } from "@api/trusts/__generated__/SubTrust";
import { Trust } from "@api/trusts/__generated__/Trust";
import { User } from "@api/me/__generated__/User";

import { getLinkFullUrl } from "../utils";

import {
  getIndividualRemainderGifts,
  getPooledTrustGift,
  getRemainderStrategyText
} from "./TrustRemainderUtils";

export const useTrustRemainder = (
  trust: Trust | undefined,
  beneficiaries: Beneficiary[] | undefined,
  children: (User | null | undefined)[] | undefined,
  partner: User | undefined,
  partnerType = PartnerType.SINGLE,
  everythingToPartner = false,
  isPartner = false
): ReviewItem[] => {
  const { formatMessage } = useIntl();

  const subTrusts = trust?.subTrusts
    ? (trust.subTrusts.filter(
        (subTrust) => subTrust !== null
      ) as SubTrust[])
    : undefined;

    const primaryBeneficiaries = beneficiaries?.filter(
    (beneficiary) => beneficiary.isBackup === false
  );

  const useRemainderItems = (
    estateBenes: Beneficiary[] | undefined,
    trusts: SubTrust[] | undefined
  ) => {
    const remainderDistribType = useMemo(() => {
      if (!trusts || trusts.length === 0) {
        return DistribType.OUTRIGHT;
      }

      if (
        trusts.some(
          (sub) => sub.trustType === TrustType.POOLED_FAMILY
        )
      ) {
        return DistribType.POOLED_FAMILY;
      }

      if (
        trusts.some(
          (sub) => sub.trustType === TrustType.POOLED_CHILDREN
        )
      ) {
        return DistribType.POOLED_CHILDREN;
      }

      return DistribType.SEPARATE_INDIVIDUAL;
    }, [trusts]);

    const remainderCopyTrustSetup = useMemo(() => {
      // Check if at least two subTrusts exist
      if (!trusts || trusts.length < 2) {
        return false;
      }
      // Compare the properties of the first subTrust with the rest
      const {
        trustType,
        restrictions,
        appointmentPower,
        vestingType,
        terminationDistribution,
        vestingSchedule
      } = trusts[0];

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < trusts.length; i++) {
        const subTrust = trusts[i];

        if (
          subTrust.trustType !== trustType ||
          subTrust.restrictions !== restrictions ||
          subTrust.appointmentPower !== appointmentPower ||
          subTrust.vestingType !== vestingType ||
          subTrust.terminationDistribution !==
            terminationDistribution ||
          JSON.stringify(subTrust.vestingSchedule) !==
            JSON.stringify(vestingSchedule)
        ) {
          return false;
        }
      }

      return true;
    }, [trusts]);

    const remainderStrategy = useMemo(() => {
      // All primaryBenes match partner
      if (
        estateBenes?.every((bene) => bene.user?.id === partner?.id)
      ) {
        return "partnerOnly";
      }

      // All primaryBenes match children
      if (
        estateBenes?.every((bene) =>
          children?.some((child) => child?.id === bene.user?.id)
        ) ||
        remainderDistribType === DistribType.POOLED_CHILDREN
      ) {
        return "childrenOnly";
      }

      if (
        estateBenes?.every(
          (bene) =>
            children?.some((child) => child?.id === bene.user?.id) ||
            partner?.id === bene.user?.id
        ) ||
        remainderDistribType === DistribType.POOLED_FAMILY
      ) {
        return "partnerAndChildren";
      }

      return "nameIndividually";
    }, [estateBenes, remainderDistribType]);

    return {
      remainderStrategy,
      remainderDistribType,
      remainderCopyTrustSetup
    };
  };

  const {
    remainderStrategy,
    remainderDistribType,
    remainderCopyTrustSetup
  } = useRemainderItems(primaryBeneficiaries, subTrusts);

  const lowerPartnerType = partnerType.toLowerCase();

  const individualGiftsInfo = useMemo<ReviewItem[] | undefined[]>(
    () =>
      beneficiaries &&
      remainderDistribType !== DistribType.POOLED_CHILDREN &&
      remainderDistribType !== DistribType.POOLED_FAMILY
        ? getIndividualRemainderGifts(
            beneficiaries,
            subTrusts,
            everythingToPartner,
            isPartner
          )
        : [undefined],
    [
      beneficiaries,
      subTrusts,
      remainderDistribType,
      everythingToPartner,
      isPartner
    ]
  );

  const pooledTrustInfo = useMemo<ReviewItem | undefined[]>(
    () =>
      beneficiaries &&
      (remainderDistribType === DistribType.POOLED_CHILDREN ||
        remainderDistribType === DistribType.POOLED_FAMILY)
        ? getPooledTrustGift(
            partnerType,
            beneficiaries,
            isPartner
          )
        : [undefined],
    [
      beneficiaries,
      subTrusts,
      partnerType,
      remainderDistribType,
      isPartner
    ]
  );

  const remainderStrategyInfo = useMemo<ReviewItem | undefined>(
    () =>
      remainderStrategy
        ? {
            title: formatMessage({
              defaultMessage: "Remainder Strategy:"
            }),
            href: isPartner
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Gifts,
                  TRUST_GIFTS_ROUTES.PartnerRemainderStrategy
                ])
              : getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Gifts,
                  TRUST_GIFTS_ROUTES.RemainderStrategy
                ]),
            items: [
              getRemainderStrategyText(
                lowerPartnerType,
                remainderStrategy,
                remainderDistribType,
                remainderCopyTrustSetup
              )
            ]
          }
        : undefined,
    [
      formatMessage,
      isPartner,
      lowerPartnerType,
      remainderStrategy,
      remainderDistribType,
      remainderCopyTrustSetup
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        remainderStrategyInfo,
        ...individualGiftsInfo,
        pooledTrustInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [remainderStrategyInfo, individualGiftsInfo, pooledTrustInfo]
  );

  return items;
};
