import { Theme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { createBreakpoints } from "@mui/system";

import LinkBehavior from "./Link";

const breakpoints = createBreakpoints({});

const components: Theme["components"] = {
  MuiInput: {
    styleOverrides: {
      input: {
        padding: "0.25rem 0",
        height: "2rem",
        fontSize: "1.125rem"
      },
      root: {
        "&::before, &::after": {
          borderBottomWidth: "1px"
        },
        "&::before": {
          borderBottomColor: "#64635D"
        },
        "&::after": {
          borderBottomColor: "#0E0E10"
        },
        "&:hover:not(.Mui-disabled)": {
          "&::before, &::after": {
            borderBottomWidth: "1px"
          }
        },
        "&:hover:not(.Mui-disabled)::before": {
          borderBottomColor: "#64635D"
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: "inherit",
        fontSize: "1.125rem",
        minHeight: "2rem",
        display: "flex",
        alignItems: "center",
        [breakpoints.up("md")]: {
          fontSize: "1.125rem"
        },
        "&.Mui-focused": {
          color: "inherit"
        }
      },
      shrink: {
        color: "#575757",
        minHeight: "auto"
      }
    }
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior
    }
  },
  MuiButton: {
    styleOverrides: {
      containedSizeMedium: {
        minHeight: "2.5rem",
        minWidth: "13.438rem"
      },
      outlinedSizeMedium: {
        minHeight: "2.5rem",
        minWidth: "13.438rem"
      },
      textSizeMedium: {
        minHeight: "2.5rem",
        minWidth: "13.438rem"
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        boxShadow: "0px 1px 0.5rem rgba(0, 0, 0, 0.15)"
      },
      listbox: {
        "& .MuiAutocomplete-option": {
          display: "block"
        }
      }
    }
  },
  MuiRadio: {
    defaultProps: {
      checkedIcon: <CheckCircleIcon />
    },
    styleOverrides: {
      root: {
        padding: "0.625rem",
        "& .MuiSvgIcon-fontSizeSmall": {
          fontSize: "1.2rem"
        },
        "+ .MuiFormControlLabel-label": {
          fontSize: "1.125rem",
          marginTop: "0.4rem",
          [breakpoints.up("md")]: {
            fontSize: "1.125rem"
          }
        },
        "&:not(.Mui-checked) .MuiSvgIcon-fontSizeSmall": {
          fill: "#9A9A9A"
        },
        "&.Mui-checked + .MuiFormControlLabel-label": {
          fontWeight: 600
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      standard: {
        "&:focus": {
          backgroundColor: "transparent"
        }
      }
    }
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        boxShadow: "0px 1px 0.5rem rgba(0, 0, 0, 0.15)"
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: "0.625rem",
        "& .MuiSvgIcon-fontSizeSmall": {
          fontSize: "1.34rem"
        },
        "+ .MuiFormControlLabel-label": {
          fontSize: "1.125rem",
          marginTop: "0.425rem",
          [breakpoints.up("md")]: {
            fontSize: "1.125rem"
          }
        },
        "&:not(.Mui-checked) .MuiSvgIcon-fontSizeSmall": {
          fill: "#9A9A9A"
        },
        "&.Mui-checked + .MuiFormControlLabel-label": {
          fontWeight: 600
        }
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        alignItems: "flex-start"
      }
    }
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        backgroundColor: "#E4DDCF",
        height: "0.375rem"
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: "0.375rem"
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: "1.125rem",
        opacity: 1,
        fontWeight: 400,
        "&.Mui-selected": {
          fontWeight: 700
        }
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        transition: "250ms ease",
        [breakpoints.up("md")]: {
          "&:hover": {
            opacity: "0.7"
          }
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        "&.PhoneInput": {
          position: "relative"
        },
        "&.PhoneInputInput": {
          position: "static"
        },
        "& .PhoneInput": {
          "& .MuiInputLabel-root[data-shrink='false']": {
            left: "50px"
          }
        },
        "& .PhoneInputCountry": {
          marginTop: "1rem"
        }
      }
    }
  }
};

export default components;
