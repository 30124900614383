import { Address } from "@api/plans/__generated__/Address";

const buildAddress = (address?: Address | null): string => {
  if (!address) return "";

  let userAddress = address.address1 || "";

  if (address.address2) {
    userAddress += ` ${address.address2}`;
  }

  if (address.city) {
    userAddress += `, ${address.city}`;
  }

  if (address.state?.code) {
    userAddress += `, ${address.state.code}`;
  }

  if (address.zip) {
    userAddress += ` ${address.zip}`;
  }

  return userAddress;
};

export default buildAddress;
