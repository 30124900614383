import { useMemo } from "react";
import { lowerCase } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import {
  PartnerType,
  PlanOption
} from "@api/__generated__/globalTypes";

const PlanSelectionStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);

  const lowerCasePartnerType = lowerCase(partnerType);

  const planOptions = useMemo(
    () => [
      {
        label: "Both of Us (Couple's Plan - $185) -- 55% Savings",
        value: PlanOption.COUPLE
      },
      {
        label: "Just Me (Individual Will - $135)",
        value: PlanOption.SINGLE
      }
    ],
    []
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to make a plan for you and your ${lowerCasePartnerType}, or just for you?`
      }}
      name="planOption"
      items={planOptions}
    />
  );
};

export default PlanSelectionStep;
