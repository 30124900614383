import React, { PropsWithChildren, useMemo, useState } from "react";

import { ProgressBarContext } from "./context";

export const { Provider: ProgressBarProvider } = ProgressBarContext;

export const ProgressBarContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [progress, setProgress] = useState<number | undefined>(
    undefined
  );
  const [title, setTitle] = useState<string | undefined>(undefined);
  const progressBarContextValue = useMemo(
    () => ({
      progress,
      setProgress,
      title,
      setTitle
    }),
    [progress, title]
  );

  return (
    <ProgressBarProvider value={progressBarContextValue}>
      {children}
    </ProgressBarProvider>
  );
};
