import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPersonalPropertyBalanceOverviewStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Great work! Now we're going to decide how ${partnerFirstName} wants to gift ${partnerFirstName}'s personal property balance.`,
        muiIconHeader: <InfoOutlinedIcon />,
        subText: `Your Personal Property Balance represents the rest of your personal property items (cars, boats, jewelry, furniture, etc.) that you didn't already designate as a specific gift.`
      }}
    />
  );
};

export default PartnerPersonalPropertyBalanceOverviewStep;
