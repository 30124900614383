export const LAST_WILL_SECTION_ROUTES = {
  Home: "",
  AllAboutYou: "all-about-you",
  Guardians: "guardians",
  Management: "management",
  Assets: "assets",
  Gifts: "gifts",
  FinalArrangements: "final-arrangements",
  Healthcare: "healthcare",
  PowerOfAttorney: "power-of-attorney",
  DocumentPreview: "document-preview"
};
