import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPowersOptionalOverviewStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `We'll now review some special powers that ${partnerFirstName} may optionally provide ${partnerFirstName}'s agent.`
      }}
    />
  );
};

export default PartnerPowersOptionalOverviewStep;
