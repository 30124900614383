const getPooledTrustContent = () => ({
  title: "At what age should I terminate the Pooled Trust?",
  togglerLabel: "Learn more.",
  content: (
    <p>
      As a reminder, the idea behind a Pooled Trust is that it helps
      normalize for the fact that different age beneficiaries may have
      already received different contributions and benefits during
      their lifetimes.
      <br />
      <br />
      Therefore, in deciding the age at which the pooled trust should
      terminate, you might consider how long you plan to directly
      support each of your children financially.
      <br />
      <br />
      For example, if you plan to support your children through
      college, you might set the age threshold to age 25. This helps
      ensure that a younger child who may not have completed college
      can benefit from the Pooled Trust funds disproportionately more
      compared to an older child who may have already completed and
      had their college subsidized by you.
    </p>
  ),
  closeLabel: "Got it"
});

export default getPooledTrustContent;
