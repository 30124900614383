import { useFormField } from "@components/FormFields/hooks";
import InterstitialArchetype from "@components/archetypes/Interstitial";
import { videoTutorials } from "@setup/consts/videoTutorials";

const GiftProcessOverviewStep: React.FC = () => {
  const { value: isJointTrust } = useFormField(
    "users.0.trust.isJointTrust"
  );

  const thumbnail = isJointTrust
    ? videoTutorials.hopwJointTrustGiftsWork
    : videoTutorials.howSeparateTrustGiftsWork;

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Everything You Need to Know About Gifts In Your Trust`,
        videoProps: {
          src: thumbnail.src,
          title: thumbnail.title,
          showCaption: false
        },
        subText:
          "Watch this video for an overview on gifts made in your Trust Agreement."
      }}
    />
  );
};

export default GiftProcessOverviewStep;
