import InterstitialArchetype from "@components/archetypes/Interstitial";

const PowersOptionalOverviewStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `We'll now review some special powers that you may optionally provide your agent.`
    }}
  />
);

export default PowersOptionalOverviewStep;
