import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPowersDigitalAssetsStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to provide their agent access to ${partnerFirstName}'s Digital Assets and Communication?`,
        subText: `Learn more about providing Digital Access.`,
        moreInfoModalProps: {
          title:
            "Authority to Access Digital Assets and Communication",
          togglerLabel: "Learn more",
          content: (
            <p>
              Providing access to your Digtal Assets and Communication
              allows your agent to exercise authority over the content
              of most forms of electronic communication sent or
              received by you.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.1.poa.additionalPowers.digitalAccess"
    />
  );
};

export default PartnerPowersDigitalAssetsStep;
