import { useMemo } from "react";
import { useIntl } from "react-intl";

import MultiSelectArchetype from "@components/archetypes/MultiSelect";
import { MultiSelectOption } from "@components/archetypes/MultiSelect/partials/MultiSelectForm/types";
import { BlogLinks } from "@setup/consts/articleLinks";

const PowersToGrantStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const options: MultiSelectOption[] = useMemo(
    () => [
      {
        value: "Real Property",
        label: formatMessage({
          defaultMessage: "Real Property (e.g., Real Estate)"
        })
      },
      {
        value: "Tangible Personal Property",
        label: formatMessage({
          defaultMessage:
            "Tangible Personal Property (e.g., Cars, Boats and Other Personal Items)"
        })
      },
      {
        value: "Stocks and Bonds",
        label: formatMessage({
          defaultMessage: "Stocks and Bonds"
        })
      },
      {
        value: "Commodities and Options",
        label: formatMessage({
          defaultMessage: "Commodities and Options"
        })
      },
      {
        value: "Banks and Other Financial Institutions",
        label: formatMessage({
          defaultMessage: "Banks and Other Financial Institutions"
        })
      },
      {
        value: "Operation of Entity or Business",
        label: formatMessage({
          defaultMessage: "Operation of Entity or Business"
        })
      },
      {
        value: "Insurance and Annuities",
        label: formatMessage({
          defaultMessage: "Insurance and Annuities"
        })
      },
      {
        value: "Estates, Trusts, and Other Beneficial Interests",
        label: formatMessage({
          defaultMessage:
            "Estates, Trusts, and Other Beneficial Interests"
        })
      },
      {
        value: "Claims and Litigation",
        label: formatMessage({
          defaultMessage: "Claims and Litigation"
        })
      },
      {
        value: "Personal and Family Maintenance",
        label: formatMessage({
          defaultMessage: "Personal And Family Maintenance"
        })
      },
      {
        value:
          "Benefits from Governmental Programs or Civil / Military Service",
        label: formatMessage({
          defaultMessage:
            "Benefits from Governmental Programs or Civil / Military Service"
        })
      },
      {
        value: "Retirement Plans",
        label: formatMessage({
          defaultMessage: "Retirement Plans"
        })
      },
      {
        value: "Taxes",
        label: formatMessage({
          defaultMessage: "Taxes"
        })
      }
    ],
    [formatMessage]
  );

  return (
    <MultiSelectArchetype
      valueAsKey
      {...{ options }}
      name="users.0.poa.agentPowers"
      stepLayout={{
        title: `Which of the following subjects should your agent be allowed to manage?`,
        subText: `Choose as many as you like.`,
        moreInfoModalProps: {
          title: "Choosing Specific Powers to Grant",
          togglerLabel: "Learn more",
          content: (
            <p>
              You can authorize your agent to act on your behalf in a
              few specific subjects or across a wider range of general
              subjects.
              <br />
              <br />
              Unless you are creating your power of attorney for a
              specific, time-limited purpose, many estate planning
              attorneys recommend granting general powers to an agent
              you highly trust, because transactions often involve
              dealing with multiple subjects simultaneously.
              <br />
              <br />
              If you&apos;d like to learn more about what a particular
              power entails, check out our article on Powers in a
              Financial Powers of Attorney using the link below.
            </p>
          ),
          actions: [
            {
              label: "Go to Article",
              href: BlogLinks.whatisFinancialPOA
            }
          ],
          closeLabel: "Got it"
        }
      }}
    />
  );
};

export default PowersToGrantStep;
