import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { PlanOption } from "@api/__generated__/globalTypes";
import { HEALTHCARE_ROUTES } from "@pages/Healthcare/consts";

import NoAHCDStep from "../../steps/NoAHCD";
import MedicalPOAPreviewStep from "../../steps/MedicalPOAPreview";
import CreateAHCDStep from "../../steps/CreateAHCD";
import PartnerCreateAHCDStep from "../../steps/PartnerCreateAHCD";
import NominateAgentStep from "../../steps/NominateAgent";
import DesignateAgentStep from "../../steps/DesignateAgent";
import NominateBackupAgentStep from "../../steps/NominateBackupAgent";
import DesignateBackupAgentStep from "../../steps/DesignateBackupAgent";
import PartnerNominateAgentStep from "../../steps/PartnerNominateAgent";
import DesignatePartnerAsAgentStep from "../../steps/DesignatePartnerAsAgent";
import PartnerDesignateAgentStep from "../../steps/PartnerDesignateAgent";
import PartnerNominateBackupAgentStep from "../../steps/PartnerNominateBackupAgent";
import PartnerDesignateBackupAgentStep from "../../steps/PartnerDesignateBackupAgent";
import GrantMedicalAccessStep from "../../steps/GrantMedicalAccess";
import GrantSameMedicalAccessStep from "../../steps/GrantSameMedicalAccess";
import PartnerGrantMedicalAccessStep from "../../steps/PartnerGrantMedicalAccess";
import PartnerDesignateMedicalAccessStep from "../../steps/PartnerDesignateMedicalAccess";
import DesignateMedicalAccessStep from "../../steps/DesignateMedicalAccess";
import { HEALTHCARE_LIVINGWILL_ROUTES } from "../livingWill/consts";

import { HEALTHCARE_MEDICAL_POA_ROUTES } from "./consts";

const ahcdSteps: PlanFormStep[] = [
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.Preview,
    element: <MedicalPOAPreviewStep />,
    rules: HEALTHCARE_MEDICAL_POA_ROUTES.CreateAHCD
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.CreateAHCD,
    element: <CreateAHCDStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        HEALTHCARE_MEDICAL_POA_ROUTES.PartnerCreateAHCD,
        {
          if: [
            {
              "==": [
                { var: "includeAdvanceHealthCareDirective" },
                true
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.NominateAgent,
            HEALTHCARE_MEDICAL_POA_ROUTES.NoAHCD
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerCreateAHCD,
    element: <PartnerCreateAHCDStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeAdvanceHealthCareDirective" }, true]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.NominateAgent,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateAgent,
            HEALTHCARE_MEDICAL_POA_ROUTES.NoAHCD
          ]
        }
      ]
    }
  },
  // Start of Medical POA Section
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.NominateAgent,
    element: <NominateAgentStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                { var: "users.0.healthcare.authorizeAgents" },
                true
              ]
            },
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] }
          ]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.DesignatePartnerAsAgent,
        {
          if: [
            {
              "==": [
                { var: "users.0.healthcare.authorizeAgents" },
                true
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.DesignateAgent,
            {
              if: [
                {
                  and: [
                    {
                      "==": [
                        {
                          var: "includePartnerAdvanceHealthCareDirective"
                        },
                        true
                      ]
                    },
                    {
                      "==": [{ var: "planOption" }, PlanOption.COUPLE]
                    }
                  ]
                },
                HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateAgent,
                HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.DesignatePartnerAsAgent,
    element: <DesignatePartnerAsAgentStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerAsHealthcareAgent" }, true] },
        HEALTHCARE_MEDICAL_POA_ROUTES.NominateBackupAgent,
        HEALTHCARE_MEDICAL_POA_ROUTES.DesignateAgent
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.DesignateAgent,
    element: <DesignateAgentStep />,
    rules: HEALTHCARE_MEDICAL_POA_ROUTES.NominateBackupAgent
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.NominateBackupAgent,
    element: <NominateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.healthcare.nominateBackupAgent" },
            true
          ]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.DesignateBackupAgent,
        {
          if: [
            {
              and: [
                { "==": [{ var: "partnerAsHealthcareAgent" }, true] },

                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateBackupAgent,
            {
              if: [
                {
                  and: [
                    {
                      "==": [
                        {
                          var: "includePartnerAdvanceHealthCareDirective"
                        },
                        true
                      ]
                    },
                    {
                      "==": [{ var: "planOption" }, PlanOption.COUPLE]
                    }
                  ]
                },
                HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateAgent,
                HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.DesignateBackupAgent,
    element: <DesignateBackupAgentStep />,
    rules: {
      if: [
        { "==": [{ var: "partnerAsHealthcareAgent" }, true] },
        HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateBackupAgent,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateAgent,
            HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateAgent,
    element: <PartnerNominateAgentStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.1.healthcare.authorizeAgents" }, true]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateAgent,
        {
          if: [
            {
              "==": [
                { var: "includeAdvanceHealthCareDirective" },
                true
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess,
            HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateAgent,
    element: <PartnerDesignateAgentStep />,
    rules: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateBackupAgent
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerNominateBackupAgent,
    element: <PartnerNominateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.nominateBackupAgent" },
            true
          ]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateBackupAgent,
        {
          if: [
            {
              "==": [
                { var: "includeAdvanceHealthCareDirective" },
                true
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess,
            HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateBackupAgent,
    element: <PartnerDesignateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [{ var: "includeAdvanceHealthCareDirective" }, true]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess,
        HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.GrantMedicalAccess,
    element: <GrantMedicalAccessStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.0.healthcare.medicalRecordAccess.authorizeOthers"
            },
            true
          ]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.DesignateMedicalAccess,
        {
          if: [
            {
              and: [
                {
                  "==": [
                    {
                      var: "includePartnerAdvanceHealthCareDirective"
                    },
                    true
                  ]
                },
                {
                  "==": [{ var: "planOption" }, PlanOption.COUPLE]
                }
              ]
            },
            HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess,
            `${HEALTHCARE_ROUTES.LivingWill}/${HEALTHCARE_LIVINGWILL_ROUTES.Preview}`
          ]
        }
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.DesignateMedicalAccess,
    element: <DesignateMedicalAccessStep />,
    rules: {
      if: [
        {
          and: [
            {
              "==": [
                {
                  var: "includePartnerAdvanceHealthCareDirective"
                },
                true
              ]
            },
            {
              "==": [{ var: "planOption" }, PlanOption.COUPLE]
            }
          ]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.GrantSameMedicalAccess,
        `${HEALTHCARE_ROUTES.LivingWill}/${HEALTHCARE_LIVINGWILL_ROUTES.Preview}`
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.GrantSameMedicalAccess,
    element: <GrantSameMedicalAccessStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.healthcare.copyMedicalRecordAccess" },
            true
          ]
        },
        `${HEALTHCARE_ROUTES.LivingWill}/${HEALTHCARE_LIVINGWILL_ROUTES.Preview}`,
        HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerGrantMedicalAccess,
    element: <PartnerGrantMedicalAccessStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: "users.1.healthcare.medicalRecordAccess.authorizeOthers"
            },
            true
          ]
        },
        HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateMedicalAccess,
        `${HEALTHCARE_ROUTES.LivingWill}/${HEALTHCARE_LIVINGWILL_ROUTES.Preview}`
      ]
    }
  },
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.PartnerDesignateMedicalAccess,
    element: <PartnerDesignateMedicalAccessStep />,
    rules: `${HEALTHCARE_ROUTES.LivingWill}/${HEALTHCARE_LIVINGWILL_ROUTES.Preview}`
  },
  // Primary (And Partner) Decline to Create AHCD
  {
    path: HEALTHCARE_MEDICAL_POA_ROUTES.NoAHCD,
    element: <NoAHCDStep />
  },
  {
    path: "*",
    element: <Navigate to={HEALTHCARE_MEDICAL_POA_ROUTES.Preview} />
  }
];

export default ahcdSteps;
