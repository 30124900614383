import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";

const PartnerExcludedPersonsStep: React.FC = () => {
  const { setValue: setExcludedPersons } = useFormField(
    `users.1.gifts.excludedPersons`
  );

  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const excludePersons = get(
        data,
        "users.1.gifts.excludePersons"
      );

      if (!excludePersons) {
        setExcludedPersons([]);
      }
    },
    [setExcludedPersons]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to exclude one or more people from inheriting under ${partnerFirstName}'s will?`
      }}
      {...{ onSubmit }}
      name="users.1.gifts.excludePersons"
    />
  );
};

export default PartnerExcludedPersonsStep;
