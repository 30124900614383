import SectionsPreview from "@components/archetypes/SectionPreview";
import { ROUTES } from "@setup/consts/routes";
import { DASHBOARD_SECTION_ROUTES } from "@pages/EstatePlanView/Partials/consts";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";

import sections from "../routes";

const POADedicatedOverviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    stepLayout={{
      title: "Let's create your Power of Attorney.",
      subText:
        "A Power of Attorney is a legal document through which you authorize another person, called your agent, to act on your behalf. Granting authority to a trusted individual can be helpful to facilitate transactions under ordinary circumstances, and all the more so if you temporarily or permanently lose the ability to make or communicate your decisions."
    }}
    prevButtonProps={{
      type: "button",
      href: getLinkFullUrl([
        ROUTES.Dashboard,
        DASHBOARD_SECTION_ROUTES.DocsAndData
      ])
    }}
  />
);

export default POADedicatedOverviewStep;
