import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import React from "react";

import { AssetNetWorthData, NetWorthTableProps } from "./types";

const NetWorthTable: React.FC<NetWorthTableProps> = ({
  assets = []
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem",
      variant: "body1"
    }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Name</TableCell>

          <TableCell>Type</TableCell>
          <TableCell>Subtype</TableCell>
          <TableCell>Value</TableCell>
          <TableCell>% Net Worth</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assets.map((asset: AssetNetWorthData) => {
          const {
            id,
            name,
            editComponent,
            type,
            subtype,
            value,
            percentNetWorth
          } = asset;

          return (
            <TableRow key={id}>
              <TableCell>{editComponent}</TableCell>
              <TableCell>{name}</TableCell>

              <TableCell>{type}</TableCell>
              <TableCell>{subtype}</TableCell>
              <TableCell align="right">{value}</TableCell>
              <TableCell align="right">{percentNetWorth}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </Box>
);

export default NetWorthTable;
