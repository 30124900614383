const getAgeVestingContent = () => (
  <p>
    We offer two different vesting options for trusts created under
    your plan.
    <br />
    <br />
    <strong>#1 Age-Based Vesting</strong>. Beneficiaries of trusts
    with age-based vesting gain control of the Trust principal (i.e.,
    funds) in installments as they reach certain age thresholds. For
    example, a Trustmaker might decide to distribute 1/3 of the gift
    to the beneficiary at age 25, 1/3 at 30, and 1/3 at 35. This
    strategy is popular with Trustmakers who are making gifts to minor
    or adult children.
    <br />
    <br />
    <strong>#2 No Vesting (Lifetime Trust)</strong>. If you make your
    gift in a Lifetime Trust, distributions to the beneficiary will
    never be made outright -- they will always be subject to the
    distribution standard that you selected. The trust will continue
    until the beneficiary passes away or the trust funds are
    exhausted.
  </p>
);

export default getAgeVestingContent;
