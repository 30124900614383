/* eslint-disable camelcase */
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { PetProtection } from "@api/plans/__generated__/PetProtection";

import isSameVet from "./isSameVet";

const getVetInfo = (pets: PetProtection) => {
  const sameVeterinarian = isSameVet(pets);

  if (pets.pets?.length === 1) {
    return [
      [
        `${pets.pets[0]?.vet?.user?.fullName ?? ""}`,
        formatPhoneNumberIntl(pets.pets[0]?.vet?.user?.phone ?? ""),
        `${pets.pets[0]?.vet?.user?.address?.address1 ?? ""}`,
        `${pets.pets[0]?.vet?.user?.address?.address2 ?? ""}`,
        pets.pets[0]?.vet?.user?.address
          ? `${pets.pets[0]?.vet?.user?.address.city}, ${pets.pets[0]?.vet?.user?.address.state?.code} ${pets.pets[0]?.vet?.user?.address.zip}`
          : ""
      ]
    ].filter(Boolean);
  }

  if (sameVeterinarian && pets.pets) {
    return [
      [
        `${pets.pets[0]?.vet?.user?.fullName ?? ""}`,
        formatPhoneNumberIntl(pets.pets[0]?.vet?.user?.phone ?? ""),
        `${pets.pets[0]?.vet?.user?.address?.address1 ?? ""}`,
        `${pets.pets[0]?.vet?.user?.address?.address2 ?? ""}`,
        pets.pets[0]?.vet?.user?.address
          ? `${pets.pets[0]?.vet?.user?.address.city}, ${pets.pets[0]?.vet?.user?.address.state?.code} ${pets.pets[0]?.vet?.user?.address.zip}`
          : ""
      ],
      ["✅ Same Vet for all my pets"]
    ].filter(Boolean);
  }

  return pets.pets
    ?.map((pet) => [
      `${pet?.name}:`,
      `${pet?.vet?.user?.fullName ?? ""}`,
      formatPhoneNumberIntl(pet?.vet?.user?.phone ?? ""),
      `${pet?.vet?.user?.address?.address1 ?? ""}`,
      `${pet?.vet?.user?.address?.address2 ?? ""}`,
      pet?.vet?.user?.address
        ? `${pet?.vet?.user?.address.city}, ${pet?.vet?.user?.address.state?.code} ${pet?.vet?.user?.address.zip}`
        : ""
    ])
    .filter(Boolean);
};

export default getVetInfo;
