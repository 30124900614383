/* eslint-disable react/no-unescaped-entities */
import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const PowersFiduciaryPowersStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `Do you want to grant your agent the ability to exercise fiduciary powers that you may have?`,
      subText: `Learn more about granting fiduciary powers.`,
      moreInfoModalProps: {
        title: "Authority to Exercise Fiduciary Powers",
        togglerLabel: "Learn more",
        content: (
          <p>
            A fiduciary is a person who makes financial decisions on
            behalf of another person or group who is legally obligated
            to act in that person or group's best interests.
            <br />
            <br />
            If you are serving as a fiduciary and your fiduciary
            arrangement allows you to delegate your powers, you may do
            so in your Power of Attorney.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    name="users.0.poa.additionalPowers.fiduciary"
  />
);

export default PowersFiduciaryPowersStep;
