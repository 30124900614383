import { DocumentType } from "@pages/EstateAdminQuiz/enums";
import { EstateAdminResults } from "@pages/EstateAdminQuiz/types";

import getDefaultResults from "./getStateDefaultResults";
import getAlabamaResults from "./getAlabamaResults";
import getAlaskaResults from "./getAlaskaResults";
import getArizonaResults from "./getArizonaResults";
import getArkansasResults from "./getArkansasResults";
import getCaliforniaResults from "./getCaliforniaResults";
import getColoradoResults from "./getColoradoResults";
import getConnecticutResults from "./getConnecticutResults";
import getDelawareResults from "./getDelawareResults";
import getDCResults from "./getDCResults";
import getFloridaResults from "./getFloridaResults";
import getGeorgiaResults from "./getGeorgiaResults";
import getHawaiiResults from "./getHawaiiResults";
import getIdahoResults from "./getIdahoResults";
import getIllinoisResults from "./getIllinoisResults";
import getIndianaResults from "./getIndianaResults";
import getIowaResults from "./getIowaResults";
import getKansasResults from "./getKansasResults";
import getKentuckyResults from "./getKentuckyResults";
import getLouisianaResults from "./getLouisianaResults";
import getMaineResults from "./getMaineResults";
import getMarylandResults from "./getMarylandResults";
import getMassachusettsResults from "./getMassachusettsResults";
import getMichiganResults from "./getMichiganResults";
import getMinnesotaResults from "./getMinnesotaResults";
import getMississippiResults from "./getMississippiResults";
import getMissouriResults from "./getMissouriResults";
import getMontanaResults from "./getMontanaResults";
import getNebraskaResults from "./getNebraskaResults";
import getNevadaResults from "./getNevadaResults";
import getNewHampshireResults from "./getNewHampshireResults";
import getNewJerseyResults from "./getNewJerseyResults";
import getNewMexicoResults from "./getNewMexicoResults";
import getNewYorkResults from "./getNewYorkResults";
import getNorthCarolinaResults from "./getNorthCarolinaResults";
import getNorthDakotaResults from "./getNorthDakotaResults";
import getOhioResults from "./getOhioResults";
import getOklahomaResults from "./getOklahomaResults";
import getOregonResults from "./getOregonResults";
import getPennsylvaniaResults from "./getPennsylvaniaResults";
import getRhodeIslandResults from "./getRhodeIslandResults";
import getSouthCarolinaResults from "./getSouthCarolinaResults";
import getSouthDakotaResults from "./getSouthDakotaResults";
import getTennesseeResults from "./getTennesseeResults";
import getTexasResults from "./getTexasResults";
import getUtahResults from "./getUtahResults";
import getVermontResults from "./getVermontResults";
import getVirginiaResults from "./getVirginiaResults";
import getWashingtonResults from "./getWashingtonResults";
import getWestVirginiaResults from "./getWestVirginiaResults";
import getWisconsinResults from "./getWisconsinResults";
import getWyomingResults from "./getWyomingResults";

const useStateResults = (
  state: string,
  documentType: DocumentType,
  assetValue: string,
  probateAssetValue?: string
): EstateAdminResults => {
  switch (state) {
    case "Alabama":
      return getAlabamaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Alaska":
      return getAlaskaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Arizona":
      return getArizonaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Arkansas":
      return getArkansasResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "California":
      return getCaliforniaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Colorado":
      return getColoradoResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Connecticut":
      return getConnecticutResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Delaware":
      return getDelawareResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "District of Columbia":
      return getDCResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Florida":
      return getFloridaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Georgia":
      return getGeorgiaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Hawaii":
      return getHawaiiResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Idaho":
      return getIdahoResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Illinois":
      return getIllinoisResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Indiana":
      return getIndianaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Iowa":
      return getIowaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Kansas":
      return getKansasResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Kentucky":
      return getKentuckyResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Louisiana":
      return getLouisianaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Maine":
      return getMaineResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Maryland":
      return getMarylandResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Massachusetts":
      return getMassachusettsResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Michigan":
      return getMichiganResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Minnesota":
      return getMinnesotaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Mississippi":
      return getMississippiResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Missouri":
      return getMissouriResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Montana":
      return getMontanaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Nebraska":
      return getNebraskaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Nevada":
      return getNevadaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "New Hampshire":
      return getNewHampshireResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "New Jersey":
      return getNewJerseyResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "New Mexico":
      return getNewMexicoResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "New York":
      return getNewYorkResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "North Carolina":
      return getNorthCarolinaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "North Dakota":
      return getNorthDakotaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Ohio":
      return getOhioResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Oklahoma":
      return getOklahomaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Oregon":
      return getOregonResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Pennsylvania":
      return getPennsylvaniaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Rhode Island":
      return getRhodeIslandResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "South Carolina":
      return getSouthCarolinaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "South Dakota":
      return getSouthDakotaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Tennessee":
      return getTennesseeResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Texas":
      return getTexasResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Utah":
      return getUtahResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Vermont":
      return getVermontResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Virginia":
      return getVirginiaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Washington":
      return getWashingtonResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "West Virginia":
      return getWestVirginiaResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Wisconsin":
      return getWisconsinResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    case "Wyoming":
      return getWyomingResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
    default:
      return getDefaultResults(
        state,
        documentType,
        assetValue,
        probateAssetValue
      );
  }
};

export default useStateResults;
