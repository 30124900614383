import { useMemo } from "react";
import * as yup from "yup";
import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import FormInput from "@components/FormFields/FormInput";
import FormSelect from "@components/FormFields/FormSelect";
import { useRelationshipItems } from "@hooks/useRelationshipItems";
import { ExcludedPerson } from "@pages/LastWill/types";

type ExcludedPersonsFormProps = {
  prefix: string;
  index: number;
};

const ExcludedPersonsForm: React.FC<ExcludedPersonsFormProps> = ({
  prefix,
  index
}) => {
  const { formatMessage } = useIntl();
  const relationshipItems = useRelationshipItems();

  return (
    <>
      <FormInput
        name={`${prefix}.name`}
        label={formatMessage(
          { defaultMessage: "Person #{number}’s Full Name" },
          { number: index + 1 }
        )}
      />
      <FormSelect
        name={`${prefix}.relationship`}
        label={formatMessage(
          {
            defaultMessage: "Person #{number}’s Relationship to You"
          },
          { number: index + 1 }
        )}
        items={relationshipItems}
      />
    </>
  );
};

const PartnerDesignateExcludedPersonsStep: React.FC = () => {
  const formSchema: yup.SchemaOf<Omit<ExcludedPerson, "reason">> =
    useMemo(
      () =>
        yup.object().shape({
          name: yup.string().required(),
          relationship: yup.string()
        }),
      []
    );

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `Who do you want to exclude from inheriting under your trust?`,
        subText:
          "You can add additional people by hitting the 'Add Another Person' button."
      }}
      addBtnLabel="Add Another Person"
      singleItemSchema={formSchema}
      SingleItem={ExcludedPersonsForm}
      name="users.1.lastWill.excludedPersons"
    />
  );
};

export default PartnerDesignateExcludedPersonsStep;
