import { useIntl } from "react-intl";

import Roadmap from "@components/Roadmap";
import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import { usePlanForm } from "@providers/planForm/context";
import { useSections } from "@providers/planForm/hooks";
import PageTitle from "@components/PageTitle";

import { SectionsPreviewProps } from "./types";

const SectionsPreview: React.FC<SectionsPreviewProps> = ({
  sections,
  title,
  stepLayout,
  prevButtonProps
}) => {
  const intl = useIntl();
  const { goBack, saveAndContinue, activeStepKey } = usePlanForm();
  const items = useSections(sections, activeStepKey);

  return (
    <>
      <PageTitle
        title={
          title ||
          intl.formatMessage({
            defaultMessage: "Create My Plan"
          })
        }
      />
      <StepLayout
        title={intl.formatMessage({
          defaultMessage: "Let's do this!"
        })}
        {...stepLayout}
      >
        {!!items?.length && (
          <Roadmap
            title={intl.formatMessage({ defaultMessage: "Sections" })}
            {...{ items }}
          />
        )}
        <FormStepFooter
          prevButtonProps={
            prevButtonProps
              ? prevButtonProps
              : { type: "button", onClick: goBack }
          }
          nextButtonProps={{
            onClick: () => saveAndContinue()
          }}
        />
      </StepLayout>
    </>
  );
};

export default SectionsPreview;
