import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/LastWill/routes";
import { useFormField } from "@components/FormFields/hooks";
import { PlanOption } from "@api/__generated__/globalTypes";

const SectionsPreviewStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);
  const { value: planOption } =
    useFormField<PlanOption>(`planOption`);

  if (planOption === PlanOption.COUPLE) {
    return (
      <SectionsPreview
        {...{ sections }}
        stepLayout={{
          title: `Last section, ${firstName} and ${partnerFirstName}! You're almost to the finish line.`
        }}
      />
    );
  }

  return (
    <SectionsPreview
      {...{ sections }}
      stepLayout={{
        title: `Last section, ${firstName}! You're almost to the finish line.`
      }}
    />
  );
};

export default SectionsPreviewStep;
