import { useCallback } from "react";
import produce from "immer";
import set from "lodash/set";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { LastWillMember } from "@pages/LastWill/types";
import { parseBoolean } from "@utils/parseBoolean";

const SpecialNoteStep: React.FC = () => {
  const { value: users, setValue: setCognitoUsers } =
    useFormField<Partial<LastWillMember>[]>("users");

  const onSubmit = useCallback(
    ({ includeHealthcareNote }: SingleSelectFormValues) => {
      if (!parseBoolean(includeHealthcareNote)) {
        const newUsers = produce(users, (draft) => {
          if (!draft) {
            return;
          }

          users.forEach((_, index) => {
            set(
              draft,
              `[${index}].healthcare.preferences.additionalNote`,
              null
            );
          });
        });

        setCognitoUsers(newUsers);
      }
    },
    [setCognitoUsers, users]
  );

  return (
    <SingleSelectArchetype
      name="includeHealthcareNote"
      stepLayout={{
        title: `Last one! Would you like to leave an individual note in your healthcare documents on any other requests or preferences we didn't cover?`
      }}
      {...{ onSubmit }}
    />
  );
};

export default SpecialNoteStep;
