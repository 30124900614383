import { styled } from "@mui/material/styles";

export const CreatePlanTodoForm = styled("form")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "1.25rem"
}));

export const UpdatePlanTodoForm = styled("form")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: "1.25rem",
  flexGrow: 1,
  margin: 0
}));
