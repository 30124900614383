import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { Pet, Vet } from "@pages/PetPlan/types";

const DesignateSameVetStep: React.FC = () => {
  const { value: firstPetVet } = useFormField<Vet>(
    "pets.0.veterinarian"
  );
  const { value: pets, setValue: setPets } =
    useFormField<Pet[]>("pets");
  const onSubmit = useCallback(
    ({ sameVeterinarian }: SingleSelectFormValues) => {
      if (parseBoolean(sameVeterinarian)) {
        setPets(
          pets.map((pet) => ({
            ...pet,
            veterinarian: firstPetVet
          }))
        );
      }
    },
    [setPets, pets, firstPetVet]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Should we use the same veterinarian info for your other pets too?`
      }}
      {...{ onSubmit }}
      name="sameVeterinarian"
    />
  );
};

export default DesignateSameVetStep;
