import { Box, Typography, Grid, Container } from "@mui/material";
import TextTruncate from "react-text-truncate";

import { ResourceVideoListingProps } from "./types";

const ResourceVideoListings: React.FC<ResourceVideoListingProps> = ({
  videos
}) => (
  <Grid
    container
    gridTemplateColumns={["1fr", "repeat(2,1fr"]}
    gap="3rem 1rem"
  >
    {videos.map((video) => (
      <Grid item xs={12} md={6} key={video.title}>
        <Container>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0
            }}
          >
            <iframe
              src={video.src}
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              title={video.title}
            />
          </div>
          <Box my="1.25rem">
            <Typography variant="h5">{video.title}</Typography>
            <Typography variant="body1">
              <TextTruncate
                element="span"
                line={3}
                text={video.caption}
                truncateText=" [...]"
              />
            </Typography>
          </Box>
        </Container>
      </Grid>
    ))}
  </Grid>
);

export default ResourceVideoListings;
