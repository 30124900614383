import { useIntl } from "react-intl";

import { useTheme } from "@mui/material/styles";
import { FormProvider } from "react-hook-form";
import { useCallback } from "react";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";

import { STATES_AND_COUNTIES } from "@setup/consts/states";
import { useFormField } from "@components/FormFields/hooks";

import { ArchetypeProps } from "../types";

import UserDetailsForm from "./UserDetailsForm";
import { useUserDetails } from "./hooks";
import { UserDetailsFormValues } from "./types";

const UserDetailsArchetype: React.FC<
  ArchetypeProps<UserDetailsFormValues>
> = ({ name, stepLayout, onSubmit: onFormSubmit }) => {
  const intl = useIntl();
  const theme = useTheme();
  const {
    controls,
    goBack,
    onSubmit,
    isModalOpened,
    toggleOff,
    saveAndContinue
  } = useUserDetails(name, onFormSubmit);

  const { setValue: setFormValue } = controls;

  const { value: stateValue } = useFormField(`${name}.address.state`);
  const counties =
    STATES_AND_COUNTIES.find(
      ({ name: state }) => state === stateValue
    )?.counties || [];

  const { setValue: setCounty } = useFormField(
    `${name}.address.county`
  );

  const resetCounty = useCallback(() => {
    setFormValue(`${name}.address.county`, "");
    setCounty(null);
  }, [name, setCounty, setFormValue]);

  return (
    <StepLayout
      title={intl.formatMessage({
        defaultMessage: "Let’s start with the basics"
      })}
      {...stepLayout}
    >
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <UserDetailsForm
            {...{ name }}
            isAddressWarningDisplayed={isModalOpened}
            onCancel={toggleOff}
            onProceed={() => {
              saveAndContinue(true);
              toggleOff();
            }}
          />

          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default UserDetailsArchetype;
