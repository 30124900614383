import React, { PropsWithChildren, useMemo, useState } from "react";

import { PlanType } from "@api/__generated__/globalTypes";
import { DEFAULT_PLAN_TYPE } from "@setup/consts/misc";

import { PlanTypeContext } from "./context";

export const { Provider: PlanTypeProvider } = PlanTypeContext;

export const PlanTypeContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [planTypeEnum, setPlanTypeEnum] =
    useState<PlanType>(DEFAULT_PLAN_TYPE);
  const planTypeContextValue = useMemo(
    () => ({
      planTypeEnum,
      setPlanTypeEnum
    }),
    [planTypeEnum]
  );

  return (
    <PlanTypeProvider value={planTypeContextValue}>
      {children}
    </PlanTypeProvider>
  );
};
