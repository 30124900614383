import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import "@utils/cognito";
import theme from "@setup/theme";
import IntlProvider from "@providers/intl";
import QueryProvider from "@providers/query";
import { ProgressBarContextProvider } from "@providers/progressBar";
import { UserContextProvider } from "@providers/user";
import { PlanTypeContextProvider } from "@providers/planType";
import { ArrowAnimationContextProvider } from "@providers/arrowAnimation";
import { DisableFormContextProvider } from "@providers/disableForm";
// eslint-disable-next-line import/order
import { SessionExpiredContextProvider } from "@providers/sessionExpired";
import "@setup/yup";
import "@fontsource/inter/600.css";
import "@fontsource/inter/800.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { MembersPlansStateContextProvider } from "@providers/memberPlansState";
import { ChatAssistantProvider } from "@providers/aiAssistant";

import Routes from "./partials/Routes";
import LayoutProvider from "./partials/LayoutProvider";

const App: React.FC = () => (
  <IntlProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryProvider>
        <UserContextProvider>
          <PlanTypeContextProvider>
            <ArrowAnimationContextProvider>
              <BrowserRouter>
                <ThemeProvider {...{ theme }}>
                  <CssBaseline />
                  <ProgressBarContextProvider>
                    <MembersPlansStateContextProvider>
                      <ChatAssistantProvider>
                        <LayoutProvider>
                          <DisableFormContextProvider>
                            <SessionExpiredContextProvider>
                              <Routes />
                            </SessionExpiredContextProvider>
                          </DisableFormContextProvider>
                        </LayoutProvider>
                      </ChatAssistantProvider>
                    </MembersPlansStateContextProvider>
                  </ProgressBarContextProvider>
                </ThemeProvider>
              </BrowserRouter>
            </ArrowAnimationContextProvider>
          </PlanTypeContextProvider>
        </UserContextProvider>
      </QueryProvider>
    </LocalizationProvider>
  </IntlProvider>
);

export default App;
