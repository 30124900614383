import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, Container, Typography, Box } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SupportIcon from "@mui/icons-material/Support";
import PeopleIcon from "@mui/icons-material/People";

import ModalStepFooter from "@components/ModalStepFooter";
import { videoTutorials } from "@setup/consts/videoTutorials";
import IconList from "@components/IconList";

import { AdvisorTutorialProps } from "../types";

const AdvisorWelcomeVideo: React.FC<AdvisorTutorialProps> = ({
  onNext,
  onPrev
}) => {
  const whatToExpectItems = [
    { label: "We ship updates fast", icon: <RocketLaunchIcon /> },
    { label: "We provide great support", icon: <SupportIcon /> },
    {
      label: "We have actively involved co-founders",
      icon: <PeopleIcon />
    }
  ];

  const { lukeWelcomeVideo: video } = videoTutorials;

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="1.25rem"
        alignItems="center"
        sx={{
          mb: "2.5rem"
        }}
      >
        <Container>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0
            }}
          >
            <iframe
              src={video.src}
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              title={video.title}
            />
          </div>
        </Container>
        <IconList
          title="3 Things You Can Expect from JIC Estates Advisors"
          items={whatToExpectItems}
        />
        <Typography variant="body1" align="left">
          <FormattedMessage defaultMessage="Our Co-Founder Luke leads our Advisor partnerships. You can reach him directly via" />{" "}
          <Link href="mailto:luke@jicestates.com">
            luke@jicestates.com
          </Link>{" "}
          <FormattedMessage defaultMessage="or his direct line" />{" "}
          <Link href="tel:18473477612">847.347.7612</Link>{" "}
          <FormattedMessage defaultMessage=" (2/5)." />
        </Typography>
      </Box>
      <ModalStepFooter
        prevButtonProps={{ type: "button", onClick: onPrev }}
        nextButtonProps={{
          type: "submit",
          label: "Continue",
          onClick: onNext
        }}
      />
    </Box>
  );
};

export default AdvisorWelcomeVideo;
