import {
  SubTrust,
  SubTrust_vestingSchedule as VestingSchedule
} from "@api/trusts/__generated__/SubTrust";
import {
  PartnerType,
  Restrictions,
  TerminationDistribution,
  TrustType
} from "@api/__generated__/globalTypes";
import { getVestingTypeText } from "@pages/EstatePlanView/Partials/hooks/TrustRemainderUtils";

export const getRestrictionsText = (restrictions?: Restrictions) =>
  restrictions === Restrictions.LIMITED_RESTRICTIONS
    ? `This trust is a free access trust (low restrictions on distributions).`
    : `Distributions under the trust are subject to standard restrictions.`;

export const getSubTrustModalContent = (
  beneficiaryName: string | undefined,
  subTrust: SubTrust | undefined,
  partnerType = PartnerType.PARTNER
) => {
  if (!subTrust) {
    return null;
  }

  if (subTrust.trustType === TrustType.POOLED_FAMILY) {
    return {
      restrictions: getRestrictionsText(
        subTrust.restrictions ?? undefined
      ),
      vestingTypeText: getVestingTypeText(
        undefined, // beneficiary name
        subTrust.trustType,
        subTrust.vestingType ?? undefined,
        subTrust.vestingSchedule?.filter(
          (schedule) => schedule !== null
          // eslint-disable-next-line camelcase
        ) as VestingSchedule[] | undefined,
        partnerType
      ),
      termination: `Upon termination of the Pooled Trust, any remaining trust funds will be distributed to the surviving trust beneficiaries ${
        subTrust.terminationDistribution ===
        TerminationDistribution.SEPARATE_CHILDREN
          ? "in separate trusts"
          : "outright and free of trust"
      }.`
    };
  }

  if (subTrust.trustType === TrustType.POOLED_CHILDREN) {
    return {
      restrictions: getRestrictionsText(
        subTrust.restrictions ?? undefined
      ),
      vestingTypeText: getVestingTypeText(
        undefined, // beneficiary name
        subTrust.trustType,
        subTrust.vestingType ?? undefined,
        subTrust.vestingSchedule?.filter(
          (schedule) => schedule !== null
          // eslint-disable-next-line camelcase
        ) as VestingSchedule[] | undefined,
        partnerType
      ),
      termination: `Upon termination of the Pooled Children's Trust, any remaining trust funds will be distributed to the surviving trust beneficiaries ${
        subTrust.terminationDistribution ===
        TerminationDistribution.SEPARATE_CHILDREN
          ? "in separate trusts"
          : "outright and free of trust"
      }.`
    };
  }

  return {
    restrictions: getRestrictionsText(
      subTrust.restrictions ?? undefined
    ),
    vestingTypeText: getVestingTypeText(
      beneficiaryName,
      subTrust.trustType,
      subTrust.vestingType ?? undefined,
      subTrust.vestingSchedule?.filter(
        (schedule) => schedule !== null
        // eslint-disable-next-line camelcase
      ) as VestingSchedule[] | undefined,
      partnerType
    )
  };
};
