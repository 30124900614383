import { useState, useCallback, useEffect } from "react";
import pullAt from "lodash/pullAt";
import { useLocation } from "react-router-dom";

interface RefRegistryEntry {
  element: HTMLElement;
  toggleOff: VoidFunction;
  toggleState: boolean;
}

export type UseToggleReturnType = [
  boolean,
  {
    toggle: VoidFunction;
    toggleOn: VoidFunction;
    toggleOff: VoidFunction;
    registerContainerRef: (ref: HTMLElement | null) => void;
  }
];

let documentClickHandlerRegistered = false;
const refsRegistry: RefRegistryEntry[] = [];
const documentClickHander = (event: MouseEvent): void => {
  refsRegistry.forEach(({ element, toggleOff, toggleState }) => {
    if (toggleState && !element.contains(event.target as Node)) {
      toggleOff();
    }
  });
};

const useToggle = ({
  defaultToggleState = false,
  toggleOnRouteChange = true,
  onToggle
}: {
  defaultToggleState?: boolean;
  toggleOnRouteChange?: boolean;
  onToggle?: (toggleState: boolean) => void;
} = {}): UseToggleReturnType => {
  const [toggleState, setToggleState] = useState(defaultToggleState);
  const { pathname } = useLocation();
  const toggle = useCallback(() => {
    setToggleState((currentToggleState) => {
      onToggle?.(!currentToggleState);

      return !currentToggleState;
    });
  }, [onToggle]);
  const toggleOff = useCallback(() => {
    onToggle?.(false);
    setToggleState(false);
  }, [onToggle]);
  const toggleOn = useCallback(() => {
    onToggle?.(false);
    setToggleState(true);
  }, [onToggle]);

  useEffect(() => {
    if (!documentClickHandlerRegistered) {
      documentClickHandlerRegistered = true;
      document.addEventListener("click", documentClickHander);
    }
  }, []);

  useEffect(() => {
    if (toggleOnRouteChange) {
      setToggleState(false);
    }
  }, [pathname, toggleOnRouteChange]);

  const registerContainerRef = useCallback(
    (ref: HTMLElement | null): void => {
      const currentEntryIndex = refsRegistry.findIndex(
        ({ toggleOff: entryToggleOff }) =>
          entryToggleOff === toggleOff
      );

      if (!ref && currentEntryIndex >= 0) {
        pullAt(refsRegistry, currentEntryIndex);

        return;
      }

      if (ref && currentEntryIndex < 0) {
        refsRegistry.push({
          element: ref,
          toggleOff,
          toggleState
        });
      }
    },
    [toggleOff, toggleState]
  );

  return [
    toggleState,
    { toggle, toggleOn, toggleOff, registerContainerRef }
  ];
};

export default useToggle;
