import { Outlet } from "react-router-dom";

import PlanFormProvider from "@providers/planForm";
import { ROUTES } from "@setup/consts/routes";
import { PlanType } from "@api/__generated__/globalTypes";

import matchmakingSteps from "./routes";
import { EstatePlansMatchmakingFormValues } from "./types";

const EstatePlansMatchmakingSection: React.FC = () => (
  <PlanFormProvider<EstatePlansMatchmakingFormValues>
    value={{
      parentPath: ROUTES.EstatePlansMatchMaking,
      steps: matchmakingSteps,
      planType: PlanType.LAST_WILL, // TODO: Come back to see if this matters later,
      mode: "localStorage"
    }}
  >
    <Outlet />
  </PlanFormProvider>
);

export default EstatePlansMatchmakingSection;
