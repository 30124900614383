import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { usePlanForm } from "@providers/planForm/context";
import { Pet, PetPlanValues } from "@pages/PetPlan/types";

const MedicalStep: React.FC = () => {
  const { values } = usePlanForm<PetPlanValues>();
  const {
    pets: [firstPet, secondPet]
  } = values;

  const { value: pets, setValue: setPets } =
    useFormField<Pet[]>("pets");

  const onSubmit = useCallback(
    ({ petAddMedicalNote }: SingleSelectFormValues) => {
      if (parseBoolean(petAddMedicalNote) === false) {
        setPets(
          pets.map((pet) => ({
            ...pet,
            medicalInstructions: undefined
          }))
        );
      }
    },
    [setPets, pets]
  );

  if (!secondPet) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `Do you want to add any medical notes or allergy information for ${firstPet.name}?`
        }}
        name="petAddMedicalNote"
        {...{ onSubmit }}
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to add any medical notes or allergy information for your pets?`
      }}
      name="petAddMedicalNote"
      {...{ onSubmit }}
    />
  );
};

export default MedicalStep;
