import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { Beneficiary } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";

import BeneficiaryForm from "./ResidueAllocate/partials/BeneficiaryForm";
import AllocationMessage from "./ResidueAllocate/partials/AllocationMessage";
import useBeneficiarySchema from "./ResidueAllocate/partials/BeneficiaryForm/hooks";
import FormHeader from "./ResidueAllocate/partials/FormHeader";

const PersonalPropertyBalancePeopleStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const singleItemSchema = useBeneficiarySchema();
  const { value } = useFormField<Beneficiary[]>(
    "users.0.assets.personalPropertyBalance.0.beneficiaries"
  );
  const { success } = useAllocationSum(value, {
    path: "percentageValue"
  });

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: formatMessage({
          defaultMessage:
            "Who should receive the balance of your personal property?"
        })
      }}
      addBtnLabel="Add another beneficiary"
      {...{ singleItemSchema }}
      customRemoveBtn
      SingleItem={BeneficiaryForm}
      name="users.0.assets.personalPropertyBalance.0.beneficiaries"
      topContent={<FormHeader />}
      bottomContent={
        <AllocationMessage name="users.0.assets.personalPropertyBalance.0.beneficiaries" />
      }
      disabled={!success}
    />
  );
};

export default PersonalPropertyBalancePeopleStep;
