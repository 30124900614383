import { Navigate } from "react-router-dom";
import PetsIcon from "@mui/icons-material/Pets";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StarsIcon from "@mui/icons-material/Stars";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

import { PlanFormSection } from "@providers/planForm/types";

import { PET_SECTION_ROUTES } from "./consts";
import PetAllAboutYouSection from "./sections/AllAboutYou";
import allAboutYouSteps from "./sections/AllAboutYou/routes";
import PetGuardiansSection from "./sections/Guardians";
import guardiansSteps from "./sections/Guardians/routes";
import PetCareSection from "./sections/Care";
import careSteps from "./sections/Care/routes";
import PetSpecialPowersSection from "./sections/SpecialPowers";
import specialPowersSteps from "./sections/SpecialPowers/routes";
import DocumentPreviewPage from "./sections/DocumentPreview";

const sections: PlanFormSection[] = [
  {
    path: PET_SECTION_ROUTES.AllAboutYou,
    element: <PetAllAboutYouSection />,
    children: allAboutYouSteps,
    icon: <PetsIcon />
  },
  {
    path: PET_SECTION_ROUTES.Guardians,
    element: <PetGuardiansSection />,
    children: guardiansSteps,
    icon: <AssignmentIndIcon />,
    dependency: [PET_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: PET_SECTION_ROUTES.Care,
    element: <PetCareSection />,
    children: careSteps,
    icon: <HealthAndSafetyIcon />,
    isOptional: true,
    dependency: [PET_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: PET_SECTION_ROUTES.SpecialPowers,
    element: <PetSpecialPowersSection />,
    children: specialPowersSteps,
    icon: <StarsIcon />,
    isOptional: true,
    dependency: [PET_SECTION_ROUTES.AllAboutYou]
  },
  {
    path: PET_SECTION_ROUTES.DocumentPreview,
    element: <DocumentPreviewPage />,
    hideInMenu: true
  },
  {
    path: "*",
    element: <Navigate to={PET_SECTION_ROUTES.AllAboutYou} />
  }
];

export default sections;
