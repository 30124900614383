import { NavLink as RouterLink } from "react-router-dom";
import { Box, ListItemText } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { NavItemProps } from "./type";

const NavItem: React.FC<NavItemProps> = ({ item }) => {
  const { title, path, icon, info, subItems } = item ?? {};

  const to = path;

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleDropdown();
  };

  return (
    <>
      <StyledNavItem component={RouterLink} {...{ to }}>
        {icon && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}
        {subItems && (
          <>
            <StyledNavItemIcon onClick={handleClick}>
              <ExpandMoreIcon
                color="inherit"
                sx={{
                  transform: isOpen ? "rotate0" : "rotate(-90deg)",
                  transition: "transform .5s"
                }}
              />
            </StyledNavItemIcon>
            <ListItemText
              onClick={handleClick}
              disableTypography
              primary={title}
            />
          </>
        )}
        {!subItems && (
          <ListItemText disableTypography primary={title} />
        )}
        {info && info}
      </StyledNavItem>
      {subItems && isOpen && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          {subItems.map((subItem) => (
            <Box
              key={subItem.path}
              style={{
                marginLeft: "1.4rem",
                paddingLeft: "1.4rem"
              }}
            >
              <StyledNavItem component={RouterLink} to={subItem.path}>
                <ListItemText
                  disableTypography
                  primary={subItem.title}
                />
              </StyledNavItem>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default NavItem;
