import React, { forwardRef, useCallback, useState } from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  TextFieldProps
} from "@mui/material";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/types";

import { useFormField } from "../hooks";

import { FormPhoneInputProps } from "./types";

const CustomPhoneInput = forwardRef<HTMLElement, TextFieldProps>(
  (props, ref) => (
    <TextField inputRef={ref} variant="standard" {...props} />
  )
);

const FormPhoneInput: React.FC<FormPhoneInputProps> = ({
  name,
  label,
  ...restProps
}) => {
  const defaultValue = get(restProps, "defaultValue", "");
  const { value: fieldValue, setValue: setFieldValue } = useFormField(
    name,
    defaultValue
  );
  const [value, setValue] = useState(fieldValue);

  const {
    control,
    setValue: setRHFValue,
    formState: { errors }
  } = useFormContext();

  const fieldError = get(errors, `${name}.message`);

  const onChange = useCallback(
    (inputValue?: E164Number) => {
      setValue(inputValue);
      setFieldValue(inputValue);
      setRHFValue(name, inputValue, { shouldValidate: true });
    },
    [setFieldValue, setRHFValue, name]
  );

  return (
    <Controller
      defaultValue={value}
      {...{ control, name }}
      render={({ field }): JSX.Element => (
        <FormControl
          component="fieldset"
          error={!!fieldError}
          variant="standard"
        >
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="US"
            inputComponent={CustomPhoneInput}
            limitMaxLength
            addInternationalOption={false}
            numberInputProps={{ label, error: !!fieldError }}
            {...restProps}
            {...field}
            {...{ value, onChange }}
          />
          {fieldError && (
            <FormHelperText>{fieldError}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormPhoneInput;
