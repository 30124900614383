import { Container } from "@mui/material";

import EstateAdminFormCapture from "@components/archetypes/EstateAdminFormCapture";

const FailedUserInfo: React.FC = () => (
  <Container maxWidth="sm">
    <EstateAdminFormCapture
      name="user"
      stepLayout={{
        title:
          "Great! Complete and submit your info to be notified when additional services become available."
      }}
    />
  </Container>
);

export default FailedUserInfo;
