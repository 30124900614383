/* eslint-disable camelcase */
import { PetProtection } from "@api/plans/__generated__/PetProtection";

import areSameGuardians from "./areSameGuardians";

const getPetGuardiansEstateViewInfo = (pets: PetProtection) => {
  const sameGuardian = areSameGuardians(pets);

  if (sameGuardian) {
    return [
      [
        pets.pets && pets.pets[0]?.guardians?.length
          ? `Guardian: ${pets.pets[0]?.guardians.map(
              (person) => person?.guardian?.fullName
            )}`
          : `You are not nominating a Pet Guardian.`,
        pets.pets && pets.pets[0]?.backupGuardians?.length
          ? `Backup Guardian: ${pets.pets[0]?.backupGuardians.map(
              (person) => person?.guardian?.fullName
            )}`
          : "You are not nominating a backup Pet Guardian for your pets.",
        "✅ Same Guardians for all my pets"
      ]
    ].filter(Boolean);
  }
  if (pets.pets?.length === 1) {
    return pets.pets
      .map((pet) => [
        pet?.guardians?.length
          ? `Guardian: ${pet.guardians.map(
              (person) => person?.guardian?.fullName
            )}`
          : ``,
        pet?.backupGuardians?.length
          ? `Backup Guardian: ${pet.backupGuardians.map(
              (person) => person?.guardian?.fullName
            )}`
          : `You are not nominating a backup guardian for ${pet?.name}.`
      ])
      .filter(Boolean);
  }

  return pets.pets
    ?.map((pet) => [
      `${pet?.name}:`,
      pet?.guardians?.length
        ? `Guardian: ${pet.guardians.map(
            (person) => person?.guardian?.fullName
          )}`
        : ``,
      pet?.backupGuardians?.length
        ? `Backup Guardian: ${pet.backupGuardians?.map(
            (person) => person?.guardian?.fullName
          )}`
        : `You are not nominating a backup guardian for ${pet?.name}.`
    ])
    .filter(Boolean);
};

export default getPetGuardiansEstateViewInfo;
