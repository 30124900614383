import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const PowersDigitalAssetsStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `Do you want to provide your agent access to your Digital Assets and Communication?`,
      subText: `Learn more about Digital Access authorization.`,
      moreInfoModalProps: {
        title: "Authority to Access Digital Assets and Communication",
        togglerLabel: "Learn more",
        content: (
          <p>
            Providing access to your Digtal Assets and Communication
            allows your agent to exercise authority over the content
            of most forms of electronic communication sent or received
            by you.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    name="users.0.poa.additionalPowers.digitalAccess"
  />
);

export default PowersDigitalAssetsStep;
