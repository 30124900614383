import { makeMutationHook } from "@utils/apollo-utils";

import {
  ACCEPT_MEMBERSHIP_INVITATION,
  INVITE_MEMBER,
  UPDATE_MEMBERSHIP
} from "./mutations";
import {
  AcceptMembershipInvitation,
  AcceptMembershipInvitationVariables
} from "./__generated__/AcceptMembershipInvitation";
import {
  InviteMember,
  InviteMemberVariables
} from "./__generated__/InviteMember";
import {
  UpdateMembership,
  UpdateMembershipVariables
} from "./__generated__/UpdateMembership";

export const useAcceptMembershipInvitation = makeMutationHook<
  AcceptMembershipInvitation,
  AcceptMembershipInvitationVariables
>(ACCEPT_MEMBERSHIP_INVITATION);

export const useInviteMember = makeMutationHook<
  InviteMember,
  InviteMemberVariables
>(INVITE_MEMBER);

export const useUpdateMembership = makeMutationHook<
  UpdateMembership,
  UpdateMembershipVariables
>(UPDATE_MEMBERSHIP);
