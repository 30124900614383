import { gql } from "@apollo/client";

import { FILE_FRAGMENT } from "@api/files/fragments";
import { USER_FRAGMENT } from "@api/me";
import {
  SUBTRUST_FRAGMENT,
  TRUSTEE_FRAGMENT,
  TRUST_FRAGMENT
} from "@api/trusts/fragments";

import {
  ASSET_FRAGMENT,
  CHILD_FRAGMENT,
  HEALTHCARE_FRAGMENT,
  LAST_WILL_FRAGMENT,
  MEMBERSHIPS_FRAGMENT,
  PET_PROTECTION_FRAGMENT,
  PLAN_TODO_FRAGMENT,
  POWER_OF_ATTORNEY_FRAGMENT
} from "./fragments";

export const GET_PLANS = gql`
  ${FILE_FRAGMENT}
  ${LAST_WILL_FRAGMENT}
  ${HEALTHCARE_FRAGMENT}
  ${POWER_OF_ATTORNEY_FRAGMENT}
  ${CHILD_FRAGMENT}
  ${USER_FRAGMENT}
  ${MEMBERSHIPS_FRAGMENT}
  ${PET_PROTECTION_FRAGMENT}
  ${TRUST_FRAGMENT}
  ${SUBTRUST_FRAGMENT}
  ${TRUSTEE_FRAGMENT}
  ${ASSET_FRAGMENT}
  query Plans {
    plans {
      id
      type
      files {
        ...File
        fileUrl
      }
      planOption
      status
      partnerType
      primaryDrafterId
      primaryDrafter {
        ...User
      }
      partnerId
      partner {
        ...User
      }
      lastWills {
        ...LastWill
      }
      memberships {
        ...Membership
      }
      membership {
        ...Membership
      }
      petProtection {
        ...PetProtection
      }
      timeline
      trusts {
        ...Trust
        subTrusts {
          ...SubTrust
        }
        trustees {
          ...Trustee
        }
        assets {
          ...Asset
        }
      }
      subTrusts {
        ...SubTrust
      }
    }
  }
`;

export const PLAN_TODOS = gql`
  ${PLAN_TODO_FRAGMENT}
  query PlanTodos($planId: Int!) {
    planTodos(planId: $planId) {
      ...PlanTodo
    }
  }
`;
