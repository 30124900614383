import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import getTrustDistributionStandardsContent from "@components/archetypes/TrustSetupArchetype/getTrustDistributionStandardsContent";
import { useFormField } from "@components/FormFields/hooks";
import {
  DistribType,
  Restrictions
} from "@api/__generated__/globalTypes";

const RemainderPooledRestrictionsStep: React.FC = () => {
  const { value: remainderDistribType } = useFormField(
    "users.0.gifts.remainderDistribType"
  );

  const generateTrustType = (distribType: string) => {
    if (distribType === DistribType.POOLED_CHILDREN) {
      return "Pooled Children's Trust";
    }

    return "Pooled Family Trust";
  };

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `What should be the distribution standard for your ${generateTrustType(
          remainderDistribType as string
        )}?`,
        subText:
          "The distribution standard defines how your beneficiaries can use the Trust income and principal.",
        moreInfoModalProps: {
          title: "Standard vs. Free Access Distribution Standards",
          togglerLabel: "Learn more.",
          content: getTrustDistributionStandardsContent(),
          closeLabel: "Got it"
        }
      }}
      name="users.0.assets.estateBalance.0.beneficiaries.0.subTrust.restrictions"
      items={[
        {
          value: Restrictions.STANDARD_RESTRICTIONS,
          label: "Standard Restrictions"
        },
        {
          value: Restrictions.LIMITED_RESTRICTIONS,
          label: "Low Restrictions (Free Access Trust)"
        }
      ]}
    />
  );
};

export default RemainderPooledRestrictionsStep;
