import { memo } from "react";

import ArrayFormArchetype from "../../ArrayForm";
import { BASIC_PERSON_DETAILS_SCHEMA } from "../consts";

import PersonDetailsItem from "./partials/PersonDetailsItem";
import { usePersonDetailsArrayContext } from "./context";
import { PersonDetailsArrayProps } from "./types";

const PersonDetailsArray: React.FC<PersonDetailsArrayProps> = ({
  stepLayout,
  addBtnLabel,
  removeBtnLabel
}) => {
  const { name, onSubmit } = usePersonDetailsArrayContext();

  return (
    <ArrayFormArchetype
      {...{ onSubmit, name, stepLayout, addBtnLabel, removeBtnLabel }}
      singleItemSchema={BASIC_PERSON_DETAILS_SCHEMA}
      SingleItem={PersonDetailsItem}
    />
  );
};

export default memo(PersonDetailsArray);
