import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const useLifeInsuranceCompanyOptions =
  (): AutocompleteBaseOption[] => {
    const { formatMessage } = useIntl();

    // Top US Life Insurers by Volume, as of Nov 2022

    return useMemo(
      () =>
        [
          formatMessage({
            defaultMessage: "Accordia Life Insurance Company"
          }),
          formatMessage({ defaultMessage: "Aflac" }),
          formatMessage({
            defaultMessage: "Allianz Life Insurance Company"
          }),
          formatMessage({ defaultMessage: "Allstate" }),
          formatMessage({
            defaultMessage:
              "American General Life Insurance Company (AGLA)"
          }),
          formatMessage({ defaultMessage: "American Income Life" }),
          formatMessage({ defaultMessage: "American National" }),
          formatMessage({
            defaultMessage: "AXA Equitable Life Insurance Company"
          }),
          formatMessage({
            defaultMessage: "Baltimore Life Insurance Company"
          }),
          formatMessage({ defaultMessage: "Cincinnati Life" }),
          formatMessage({ defaultMessage: "Columbus Life" }),
          formatMessage({
            defaultMessage: "Farmers Insurance Group"
          }),
          formatMessage({
            defaultMessage: "Fidelity Life Association"
          }),
          formatMessage({ defaultMessage: "Foresters Financial" }),
          formatMessage({ defaultMessage: "Genworth Financial" }),
          formatMessage({
            defaultMessage: "Gerber Life Insurance Company"
          }),
          formatMessage({ defaultMessage: "Globe Life" }),
          formatMessage({
            defaultMessage: "Guardian Life Insurance Company"
          }),
          formatMessage({
            defaultMessage: "ING U.S. –  Voya Financial"
          }),
          formatMessage({ defaultMessage: "John Hancock Insurance" }),
          formatMessage({
            defaultMessage: "Lincoln Financial Group"
          }),
          formatMessage({ defaultMessage: "MassMutual" }),
          formatMessage({ defaultMessage: "MetLife" }),
          formatMessage({ defaultMessage: "Minnesota Life" }),
          formatMessage({ defaultMessage: "Mutual of Omaha" }),
          formatMessage({
            defaultMessage: "Mutual Trust Life Insurance"
          }),
          formatMessage({ defaultMessage: "National Life Group" }),
          formatMessage({
            defaultMessage: "National Western Life Insurance Company"
          }),
          formatMessage({
            defaultMessage: "New York Life Insurance Company (NYLIC)"
          }),
          formatMessage({
            defaultMessage:
              "North American Company for Life and Health"
          }),
          formatMessage({ defaultMessage: "Northwestern Mutual" }),
          formatMessage({ defaultMessage: "Pacific Life" }),
          formatMessage({ defaultMessage: "Primerica" }),
          formatMessage({
            defaultMessage: "Protective Life Insurance Company"
          }),
          formatMessage({ defaultMessage: "Prudential Financial" }),
          formatMessage({
            defaultMessage: "Royal Neighbors of America"
          }),
          formatMessage({ defaultMessage: "Sagicor Life Insurance" }),
          formatMessage({
            defaultMessage: "Savings Bank Life Insurance (SBLI)"
          }),
          formatMessage({
            defaultMessage: "State Farm Life Insurance"
          }),
          formatMessage({ defaultMessage: "Symetra" }),
          formatMessage({ defaultMessage: "Transamerica" }),
          formatMessage({ defaultMessage: "United Home Life" }),
          formatMessage({
            defaultMessage:
              "United Services Automobile Association (USAA)"
          }),
          formatMessage({ defaultMessage: "Vantis Life Insurance" })
        ].map((type) => ({
          key: type,
          value: type,
          label: type
        })),
      [formatMessage]
    );
  };
