import { HierarchyPerson } from "./types";

const getAgentHierarchy = (agents: HierarchyPerson[]) => {
  const primaryAgents: HierarchyPerson[] = agents.filter(
    (agent) => agent.isBackup === false
  );
  // Filters for backup agents and sorts by order
  const sortedBackupAgents: HierarchyPerson[] = agents
    .filter((agent) => agent.isBackup)
    .sort((a, b) => a.order - b.order);

  // If no backup agents, return only the primaries
  if (sortedBackupAgents.length === 0) {
    return primaryAgents;
  }

  let current = sortedBackupAgents[sortedBackupAgents.length - 1];

  // eslint-disable-next-line no-plusplus
  for (let i = sortedBackupAgents.length - 2; i >= 0; i--) {
    sortedBackupAgents[i] = {
      ...sortedBackupAgents[i],
      subItems: [current]
    };

    current = sortedBackupAgents[i];
  }

  const hierarchy = [
    { ...primaryAgents[0], subItems: [current] },
    ...(primaryAgents.length > 1
      ? primaryAgents.slice(1, primaryAgents.length)
      : [])
  ];

  return hierarchy;
};

export default getAgentHierarchy;
