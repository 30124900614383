import React from "react";
import { Grid } from "@mui/material";

import { IResourceItem, ResourceListingProps } from "./types";
import ResourceItem from "./ResourceItem";
import useResourceRecs from "./hooks";

const ResourceListing: React.FC<ResourceListingProps> = ({
  showSample = false
}) => {
  const recommendations = useResourceRecs(showSample);

  return (
    <Grid
      container
      gridTemplateColumns={["1fr", "repeat(2,1fr"]}
      gap="3rem 1rem"
    >
      {recommendations.map((item: IResourceItem) => (
        <Grid item md={6}>
          <ResourceItem key={item.key} {...{ item }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ResourceListing;
