import { useIntl } from "react-intl";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ModalToggler from "@components/Modal/partials/ModalToggler";
import Modal from "@components/Modal";
import useToggle from "@hooks/useToggle";

const ResidenceInfoModal: React.FC = () => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <>
      <ModalToggler
        icon={<OpenInNewIcon fontSize="small" color="inherit" />}
        label={intl.formatMessage({
          defaultMessage: "Learn More"
        })}
        onClick={toggleOn}
      />
      <Modal
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Primary Residence"
        })}
        content={
          <>
            Your primary residence is the place where you live the
            majority of the year. Typically, this is the same
            residence that you list when filing your taxes.
            <br />
            <br />
            We use this information to make your documents state
            specific.
          </>
        }
        actions={[
          {
            label: intl.formatMessage({ defaultMessage: "Got it" }),
            onClick: toggleOff,
            variant: "outlined",
            color: "inherit"
          }
        ]}
      />
    </>
  );
};

export default ResidenceInfoModal;
