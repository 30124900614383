import { useIntl } from "react-intl";
import { FormControl, FormLabel, Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useMemo } from "react";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormRadioButtons from "@components/FormFields/FormRadioButtons";
import FormInput from "@components/FormFields/FormInput";

import { useAgentCompensationAmountArchetype } from "./hooks";
import { AgentCompensationAmountArchetypeProps } from "./types";

const AgentCompensationAmountArchetype: React.FC<
  AgentCompensationAmountArchetypeProps
> = ({ name, stepLayout, onSubmit: onFormSubmit }) => {
  const intl = useIntl();
  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({
          defaultMessage: "per year of service"
        }),
        value: "per year of service"
      },
      {
        label: intl.formatMessage({
          defaultMessage: "per lifetime of service"
        }),
        value: "per lifetime of service"
      }
    ],
    [intl]
  );

  const { controls, goBack, onSubmit } =
    useAgentCompensationAmountArchetype(name, onFormSubmit);

  return (
    <StepLayout {...stepLayout}>
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <Stack spacing={4}>
            <FormInput
              name={`${name}.compensationLimit`}
              label={intl.formatMessage({
                defaultMessage: "Compensation Amount"
              })}
              type="number"
              defaultValue="number"
              InputProps={{
                startAdornment: `$`
              }}
            />
            <FormControl>
              <FormLabel>
                {intl.formatMessage({
                  defaultMessage: "This amount is...."
                })}
              </FormLabel>
              <FormRadioButtons
                name={`${name}.compensationType`}
                items={items}
              />
            </FormControl>
          </Stack>
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default AgentCompensationAmountArchetype;
