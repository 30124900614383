import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerDistAllToPartnerConfirmedStep: React.FC = () => {
  const { value: everythingGoToPartner } = useFormField(
    `users.1.gifts.everythingGoToPartner`
  );
  const { value: primaryFirstName } =
    useFormField(`users.0.firstName`);
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  if (everythingGoToPartner === true) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `Great! We'll note to make that distribution to ${primaryFirstName} in the event that ${primaryFirstName} survives you.`,
          subText: `We'll now cover what you want to happen if you survive ${primaryFirstName}.`
        }}
      />
    );
  }

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Sounds good, ${partnerFirstName}.`,
        subText: `In that case, keep in mind that the Primary Beneficiary you choose for each of your gifts will be first in line to receive that gift. ${primaryFirstName} will only first receive gifts for which ${primaryFirstName} is designated as a Primary Beneficiary.`
      }}
    />
  );
};

export default PartnerDistAllToPartnerConfirmedStep;
