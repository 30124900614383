import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "@api/me";

export const PLAN_DRAFT_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment PlanDraft on PlanDraft {
    token
    data
    type
    lastPath
    user {
      ...User
    }
  }
`;

export const PERSON_FRAGMENT = gql`
  fragment Person on Person {
    id
    firstName
    lastName
    name
    relationship
    address {
      address1
      address2
      zip
      state
      stateCode
      county
      city
      country
    }
  }
`;
