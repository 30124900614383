import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const MultiSelectCheckbox = styled(Checkbox, {
  shouldForwardProp: (props) => props !== "planType"
})<CheckboxProps>(({ theme }) => ({
  borderRadius: "0.25rem",
  position: "static",
  padding: 0,
  "& .MuiTouchRipple-root": {
    zIndex: -1
  },
  "&.Mui-checked .MuiTouchRipple-root": {
    backgroundColor: "currentColor"
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2.1rem",
    color: theme.palette.text.primary
  }
}));

export const MultiSelectCheckboxLabel = styled(
  FormControlLabel
)<FormControlLabelProps>(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexGrow: 1,
  height: "100%",
  width: "100%",
  margin: 0,
  boxShadow: theme.shadows[24],
  borderRadius: "0.25rem",
  padding: "0.625rem",
  textAlign: "center",
  minHeight: "6rem",
  alignItems: "center",
  color: theme.palette.text.primary,
  "& .MuiFormControlLabel-label": {
    display: "flex",
    maxWidth: "12rem",
    width: "100%",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    lineHeight: 1.47,
    marginTop: 0
  }
}));
