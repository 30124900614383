import { Stack, Typography, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import BeneficiaryArrayForm from "@components/FormFields/BeneficiaryArrayForm";
import BasicCharityBeneficiaryForm from "@pages/LastWill/sections/Gifts/steps/Partials/CharityBeneficiaryForm/BasicCharityBeneficiaryForm";
import BasicFormCheckbox from "@components/FormFields/FormCheckbox/BasicFormCheckbox";

import BeneficiaryForm from "./BeneficiaryForm";
import { SpecificGiftProps } from "./types";

const SpecificGift: React.FC<SpecificGiftProps> = ({ label }) => {
  const { watch } = useFormContext();

  const isGift = watch("isGift", true);

  return (
    <Stack>
      <BasicFormCheckbox
        name="isGift"
        defaultChecked={isGift}
        label={
          label || (
            <Typography
              sx={{ my: "auto" }}
              variant="body2"
              color="grey.700"
            >
              <FormattedMessage defaultMessage="Make this Asset a Specific Gift" />
            </Typography>
          )
        }
      />
      {isGift && (
        <>
          <Typography variant="body1" color="grey.700">
            Primary Beneficiaries:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <BeneficiaryArrayForm
              addBtnLabel="+ Add Another Beneficiary"
              removeBtnLabel="- Remove Beneficiary"
              IndividualBeneficiaryItem={BeneficiaryForm}
              CharityBeneficiaryItem={BasicCharityBeneficiaryForm}
              name="beneficiaries"
            />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default SpecificGift;
