/* eslint-disable react/no-array-index-key */
import { FormattedMessage } from "react-intl";
import { Grid, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import InfoModal from "@components/InfoModal";
import ReviewCard from "@components/ReviewCard";

import * as Styled from "./styles";
import { AssetReviewPartialProps } from "./types";

const AssetReviewPartial: React.FC<AssetReviewPartialProps> = ({
  title,
  href,
  description,
  moreInfoModalProps,
  reviewItems
}) => (
  <Box display="flex" flexDirection="column" mb="2.5rem">
    <Typography
      component="h3"
      sx={{
        lineHeight: 1.6,
        color: grey[600],
        fontWeight: 500
      }}
    >
      {title}{" "}
      {href && (
        <Styled.Link to={href}>
          <FormattedMessage defaultMessage="Edit" />
        </Styled.Link>
      )}
    </Typography>
    {description && (
      <Typography variant="body1" mb={1.25}>
        {description}
        {moreInfoModalProps && (
          <Box component="span" ml={0.5}>
            <InfoModal {...moreInfoModalProps} />
          </Box>
        )}
      </Typography>
    )}

    <Box>
      {reviewItems &&
        reviewItems.map(
          ({ title: reviewItemTitle, ...item }, idx) => (
            <Grid
              mt="1rem"
              item
              xs={12}
              md={12}
              // eslint-disable-next-line react/no-array-index-key
              key={`${reviewItemTitle}_${idx}`}
            >
              <ReviewCard
                {...{
                  canEdit: true, // TODO: Update this with User Edit privileges
                  title: reviewItemTitle,
                  ...item
                }}
              />
            </Grid>
          )
        )}
    </Box>
  </Box>
);

export default AssetReviewPartial;
