import { useIntl } from "react-intl";

import { Trust_trustees as Trustee } from "@api/trusts/__generated__/Trust";
import { LastWill_representatives as Representative } from "@api/plans/__generated__/LastWill";
import { Healthcare_authorizedPerson as HealthcareAgent } from "@api/plans/__generated__/Healthcare";
import { PowerOfAttorney_agents as PoaAgent } from "@api/plans/__generated__/PowerOfAttorney";
import { Child } from "@api/plans/__generated__/Child";
import { PartnerType } from "@api/__generated__/globalTypes";

import useRepresentativeHierarchy from "./useRepresentativeHierarchy";
import useTrusteeHierarchy from "./useTrusteeHierarchy";
import useHealthAgentHierarchy from "./useHealthAgentHierarchy";
import usePoaAgentHierarchy from "./usePoaAgentHierarchy";
import useGuardianHierarchy from "./useGuardianHierarchy";

export const useAllKeyPeople = (
  trustees: Trustee[] | undefined,
  representatives: Representative[],
  healthcareAgents: HealthcareAgent[],
  poaAgents: PoaAgent[],
  childs: Child[],
  partnerType: PartnerType,
  partnerAsChildGuardian: boolean
) => {
  const trusteePeople = useTrusteeHierarchy(trustees);
  const reps = useRepresentativeHierarchy(representatives);
  const healthAgents = useHealthAgentHierarchy(healthcareAgents);
  const finAgents = usePoaAgentHierarchy(poaAgents);
  const guardians = useGuardianHierarchy(
    childs,
    partnerType,
    partnerAsChildGuardian
  );

  const { formatMessage } = useIntl();

  const keyPeople = [];

  if (trusteePeople.length > 0) {
    keyPeople.push({
      label: formatMessage({
        defaultMessage: "Trustees"
      }),
      description: formatMessage({
        defaultMessage:
          "Trustees are individuals or entities responsible for managing the trust and its affairs."
      }),
      items: trusteePeople
    });
  }

  if (reps.length > 0) {
    keyPeople.push({
      label: formatMessage({
        defaultMessage: "Personal Representatives"
      }),
      description: formatMessage({
        defaultMessage:
          "Your Personal Representative, also known as an Executor, is a trusted individual responsible for winding up your earthly affairs after you pass."
      }),
      items: reps
    });
  }

  if (finAgents.length > 0) {
    keyPeople.push({
      label: formatMessage({
        defaultMessage: "Agents with Power of Attorney"
      }),
      description: formatMessage({
        defaultMessage:
          "These are people who can make certain financial decisions on your behalf, according to the powers with which you entrusted them in your Financial Power of Attorney"
      }),
      items: finAgents
    });
  }

  if (healthAgents.length > 0) {
    keyPeople.push({
      label: formatMessage({
        defaultMessage: "Health Care Agents"
      }),
      description: formatMessage({
        defaultMessage:
          "A healthcare agent is a trusted individual who is authorized to access your medical information and make certain healthcare decisions on your behalf, should you be unable to do so personally."
      }),
      items: healthAgents
    });
  }

  if (guardians.length > 0) {
    keyPeople.push({
      label: formatMessage({
        defaultMessage: "Guardians"
      }),
      description: formatMessage({
        defaultMessage:
          "The Guardian is the person who will be responsible for the wellbeing of your minor children should you pass away before your children reach adulthood."
      }),
      items: guardians
    });
  }

  return keyPeople;
};
