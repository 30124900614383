import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";
import { useFormField } from "@components/FormFields/hooks";
import { usePlanForm } from "@providers/planForm/context";
import { getPersonData } from "@components/archetypes/PersonDetails/utils";

const usePersonAutocomplete = (name: string) => {
  useFormField(`${name}.__typename`, "Person");
  const { setValue: setPerson } = useFormField(name);
  const { setValue: setFormValue } = useFormContext();

  const { people } = usePlanForm();

  const autocompleteOptions = useMemo(() => {
    const options = people.map((p) => ({
      label: p.name,
      value: p.id
    }));

    return options.filter(
      (opt): opt is AutocompleteBaseOption =>
        !!opt.label && !!opt.value
    );
  }, [people]);

  const onSelect = useCallback(
    (id: string) => {
      const person = people.find((p) => p.id === id);

      if (!person) {
        return;
      }

      const personData = getPersonData(person);

      setPerson(personData);
      setFormValue(name, personData);
    },
    [name, people, setFormValue, setPerson]
  );

  return {
    people,
    autocompleteOptions,
    onSelect
  };
};

export default usePersonAutocomplete;
