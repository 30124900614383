import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Delete } from "@mui/icons-material";

import { AssetType } from "@api/__generated__/globalTypes";
import BasicFormAutocomplete from "@components/FormFields/FormAutocomplete/BasicAutocomplete";

import { useAssetForms, useAssetsOptions } from "../../hooks";

import { useAssetForm } from "./hooks";
import { AssetFormProps } from "./types";

const AssetForm: React.FC<AssetFormProps> = ({
  onSubmit,
  loading,
  defaultValues,
  editMode = false,
  showIsFunded = false,
  onDeleteAsset
}) => {
  const assetForms = useAssetForms(showIsFunded);
  const autocompleteOptions = useAssetsOptions();
  const {
    methods,
    handleSubmit,
    assetType,
    exceeded,
    resetForm,
    left
  } = useAssetForm({ onSubmit, defaultValues });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <FormControl variant="standard" sx={{ display: "flex" }}>
          <BasicFormAutocomplete
            disabled={editMode}
            onChange={(_, newValue) =>
              newValue &&
              resetForm(
                typeof newValue === "string"
                  ? newValue
                  : newValue?.value
              )
            }
            textFieldProps={{
              label: "Type of Asset"
            }}
            defaultValue={AssetType.REAL_ESTATE}
            name="type"
            options={autocompleteOptions}
          />
        </FormControl>
        {assetForms[assetType]}
        {(exceeded || left > 0) && (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <FormHelperText sx={{ ml: 0, mb: 2.5, color: "red" }}>
              {left && (
                <FormattedMessage
                  defaultMessage="You have {left}% left to allocate."
                  values={{ left }}
                />
              )}
              {exceeded && (
                <FormattedMessage defaultMessage="The sum of the total ownership exceeded 100%" />
              )}
            </FormHelperText>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "1.4rem",
            mt: "1.2rem"
          }}
        >
          <Button
            disabled={exceeded}
            type="submit"
            sx={{ width: "60%", margin: "0 auto" }}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Done" />
          </Button>
          {editMode && (
            <Button
              type="button"
              color="inherit"
              onClick={onDeleteAsset}
              variant="text"
            >
              <Delete />
              <FormattedMessage defaultMessage="Delete Asset" />
            </Button>
          )}
        </Box>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </FormProvider>
  );
};

export default AssetForm;
