import { useCallback, useState } from "react";
import { v4 } from "uuid";

import { useFormField } from "@components/FormFields/hooks";

const usePersonId = (name: string) => {
  const [id, setId] = useState(v4());
  const { setValue: setPersonId } = useFormField(`${name}.id`, id);

  const randomizePersonId = useCallback(() => {
    const newId = v4();

    setPersonId(newId);
    setId(newId);
  }, [setPersonId]);

  return {
    randomizePersonId
  };
};

export default usePersonId;
