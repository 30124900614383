import { FormattedMessage, useIntl } from "react-intl";

import Modal from "@components/Modal";

import { FormAddressAutocompleteModalProps } from "./types";

const FormAddressAutocompleteModal: React.FC<
  FormAddressAutocompleteModalProps
> = ({ isOpen, onClose, onProceed }) => {
  const intl = useIntl();

  return (
    <Modal
      open={isOpen}
      {...{ onClose }}
      content={
        <>
          <FormattedMessage defaultMessage="We couldn’t verify the address you selected." />
          <br />
          <br />
          <FormattedMessage defaultMessage="Proceed anyways?" />
        </>
      }
      actions={[
        {
          label: intl.formatMessage({ defaultMessage: "Yes" }),
          onClick: onProceed,
          variant: "outlined",
          color: "inherit",
          type: "submit"
        },
        {
          label: intl.formatMessage({ defaultMessage: "No" }),
          onClick: onClose,
          color: "inherit"
        }
      ]}
    />
  );
};

export default FormAddressAutocompleteModal;
