import SingleResponseArchetype from "@components/archetypes/SingleResponse";

const TrusteeCompensationDollarStep: React.FC = () => (
  <SingleResponseArchetype
    label="Compensation"
    fieldProps={{
      valuePrefix: "$",
      type: "number"
    }}
    stepLayout={{
      title: `What dollar amount would you like to offer your trustee as compensation?`
    }}
    name="users.0.trust.trusteesCompensationDollar"
  />
);

export default TrusteeCompensationDollarStep;
