import { Box } from "@mui/system";

import SpecificGift from "./SpecificGift";

const CashForm = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    <SpecificGift />
  </Box>
);

export default CashForm;
