import {
  AccordionSummary,
  AccordionDetails,
  Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useCallback } from "react";

import * as Styled from "../styles";
import { ReviewAccordionProps } from "../types";

import { ReviewSection } from "./ReviewSection";

export const ReviewAccordion: React.FC<ReviewAccordionProps> = ({
  reviews,
  label,
  initiallyExpanded
}) => {
  const [expanded, setExpanded] = useState(
    initiallyExpanded ?? false
  );

  const onChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <Box sx={{ border: "1px solid #ddd", borderRadius: "4px" }}>
      <Styled.Accordion {...{ onChange, expanded }}>
        <AccordionSummary
          sx={{
            ":before": {
              display: "none"
            },
            ":after": {
              display: "none"
            }
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          {label}
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gap="2rem">
            {reviews
              ?.filter((item) => item.items?.length)
              .map((item) => (
                <ReviewSection key={item.tab} {...item} />
              ))}
          </Box>
        </AccordionDetails>
      </Styled.Accordion>
    </Box>
  );
};
