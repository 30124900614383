import { capitalize } from "lodash";

import { Guardian } from "@pages/LastWill/types";

const getPrimaryGuardianInfo = (
  guardians: Guardian[],
  partnerAsChildGuardian: boolean,
  partnerType: string | null | undefined
) => {
  if (partnerAsChildGuardian && partnerType) {
    return `Primary Guardian: Surviving ${capitalize(partnerType)}`;
  }

  if (guardians?.length) {
    return `Primary Guardian: ${guardians
      ?.map((guardian) => guardian.name)
      .join(", ")}`;
  }

  return "";
};

export default getPrimaryGuardianInfo;
