import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography
} from "@mui/material";

import useCognitoAuth from "@hooks/useCognitoAuth";
import NeedHelpMessage from "@pages/EstatePlanView/Partials/components/NeedHelpMessage";

import { SignUpSuccessProps } from "./types";

const SignUpSuccess: React.FC<SignUpSuccessProps> = ({ email }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<
    { text: string; isSuccess: boolean } | undefined
  >(undefined);
  const { resendActivationEmail } = useCognitoAuth();

  const resendEmail = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await resendActivationEmail(email);

      setMessage({
        text: intl.formatMessage({ defaultMessage: "Email sent" }),
        isSuccess: true
      });
    } catch (error) {
      const formError = error as Error;

      setMessage({ text: formError.message, isSuccess: false });
    } finally {
      setIsLoading(false);
      if (message && message.isSuccess) {
        setTimeout(() => setMessage(undefined), 5000);
      }
    }
  };

  return (
    <>
      <Typography
        variant="h1"
        component="h2"
        lineHeight="1.5"
        letterSpacing="0"
        textAlign="center"
        mb="2.5rem"
      >
        <FormattedMessage
          defaultMessage="We sent an email verification to {email}."
          values={{
            email: (
              <Box
                component="span"
                sx={{
                  wordBreak: "break-word"
                }}
              >
                {email}
              </Box>
            )
          }}
        />
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        lineHeight="1.78"
      >
        <FormattedMessage defaultMessage="Please click on the link in that email to proceed." />
        <br />
        <br />
        <FormattedMessage defaultMessage="Can’t find the email? Check your spam folder from sender" />
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        lineHeight="1.78"
        m="0.25rem 0 2.75rem"
      >
        <Link
          href="mailto:support@justincaseestates.com"
          color="inherit"
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@justincaseestates.com
        </Link>
      </Typography>
      <Box
        sx={{
          maxWidth: "28.25rem",
          margin: "0 auto",
          width: "100%"
        }}
      >
        {message && (
          <Typography
            textAlign="center"
            variant="caption"
            component="p"
            color={message.isSuccess ? "success.main" : "error.main"}
            sx={{ mb: 2.5 }}
          >
            {message.text}
          </Typography>
        )}
        <Grid container style={{ gridColumnGap: "0.5rem" }}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              href="/sign-in"
              color="inherit"
              fullWidth
              sx={{ mb: 2.5 }}
            >
              <FormattedMessage defaultMessage="<< Back to Sign In" />
              {isLoading && (
                <CircularProgress size={16} sx={{ ml: 2 }} />
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={resendEmail}
              color="primary"
              fullWidth
              sx={{ mb: 2.5 }}
            >
              <FormattedMessage defaultMessage="Resend verification" />
              {isLoading && (
                <CircularProgress size={16} sx={{ ml: 2 }} />
              )}
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: "1.1rem"
          }}
        >
          <NeedHelpMessage message="Need More Help?" />
        </Box>
      </Box>
    </>
  );
};

export default SignUpSuccess;
