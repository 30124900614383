import { Link as ReactLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

import theme from "@setup/theme";

export const Card = styled(Paper)(() => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  padding: "0.625rem"
}));

export const Link = styled(ReactLink)(() => ({
  textDecoration: "none",
  lineHeight: 1.8,
  color: theme.palette.primary.main,
  fontWeight: 400,
  fontSize: "0.938rem",
  flexShrink: 0,
  marginLeft: "0.75rem",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.text.secondary
    }
  }
}));
