import { useNavigate } from "react-router-dom";
import {
  ProviderProps,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { Box, CircularProgress } from "@mui/material";

import useProgressBarContext from "@providers/progressBar/hooks";
import { useLayoutContext } from "@pages/App/partials/LayoutProvider";
import { GetPlanDraft_planDraft as PlanDraft } from "@api/planDrafts/__generated__/GetPlanDraft";
import { useSendMarketingDataMutation } from "@api/marketing/hooks";

import { PlanFormContext } from "./context";
import {
  useAccessiblePaths,
  useActiveStepKey,
  useFormProgress,
  useIterationIndex,
  useNextStepKey,
  usePrevStepKey,
  useStepsPath
} from "./hooks";
import { PlanFormProviderProps } from "./types";
import { usePlanDataStorage } from "./usePlanDataStorage";

const PlanFormProvider = <T extends Record<string, unknown>>({
  children,
  value: { parentPath, steps, planType, mode = "online" }
}: ProviderProps<PlanFormProviderProps>): React.ReactElement => {
  const navigate = useNavigate();
  const [values, setValues] = useState<T>({} as T);
  const stepsPath = useStepsPath(steps, values);
  const [userLastPath, setUserLastPath] = useState(
    steps?.[0].path || "/"
  );
  const activeStepKey = useActiveStepKey(parentPath, stepsPath);
  const { setAccessiblePlanPaths } = useLayoutContext();

  const progress = useFormProgress(parentPath, steps);
  const { setProgress } = useProgressBarContext();
  const nextStepKey = useNextStepKey(activeStepKey, stepsPath);
  const prevStepKey = usePrevStepKey(activeStepKey, stepsPath);
  const iteration = useIterationIndex();
  const [callMarketingData] = useSendMarketingDataMutation();

  const onFetchDraftData = useCallback((planDraft: PlanDraft) => {
    setValues(planDraft.data);
    setUserLastPath(planDraft.lastPath);
  }, []);

  const {
    loading,
    token,
    suggestedPeople,
    saveData,
    refreshSuggestedPeople
  } = usePlanDataStorage({
    mode,
    planType,
    onFetchDraftData
  });

  useEffect(() => {
    setProgress(progress);

    return () => setProgress(undefined);
  }, [progress, setProgress]);

  const accessiblePaths = useAccessiblePaths(
    stepsPath,
    steps,
    userLastPath
  );

  useEffect(() => {
    setAccessiblePlanPaths(accessiblePaths);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessiblePaths]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (
      activeStepKey &&
      activeStepKey !== stepsPath[0] &&
      stepsPath.includes(activeStepKey)
    ) {
      return;
    }

    if (!userLastPath) {
      navigate(stepsPath[0]);

      return;
    }

    const [sectionName] = (userLastPath || "").split("/");

    if (
      accessiblePaths[sectionName] &&
      stepsPath.includes(userLastPath)
    ) {
      navigate(userLastPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const sendMarketingData = useCallback(
    (email: string): Promise<boolean> =>
      callMarketingData({
        variables: {
          email,
          data: values,
          klaviyoListId: "RFdexc"
        }
      }).then((response) => !!response.data?.sendMarketingData),
    [callMarketingData, values]
  );

  const saveAndContinue = useCallback(
    async (refreshPeople = false) => {
      const lastPath = nextStepKey || stepsPath[0];

      await saveData(values, lastPath);

      setUserLastPath(lastPath);

      if (refreshPeople) {
        await refreshSuggestedPeople();
      }

      if (nextStepKey !== undefined) navigate(nextStepKey);
    },
    [
      nextStepKey,
      stepsPath,
      saveData,
      values,
      navigate,
      refreshSuggestedPeople
    ]
  );

  const goBack = useCallback(() => {
    if (prevStepKey !== undefined) {
      navigate(prevStepKey);
    }
  }, [navigate, prevStepKey]);

  useEffect(() => {
    refreshSuggestedPeople();
  }, [refreshSuggestedPeople]);

  const contextValue = useMemo(
    () => ({
      values,
      isBackAvailable: !!prevStepKey,
      token,
      type: planType,
      goBack,
      setValues,
      saveAndContinue,
      accessiblePaths,
      people: suggestedPeople,
      activeStepKey,
      iteration,
      sendMarketingData
    }),
    [
      values,
      prevStepKey,
      token,
      planType,
      goBack,
      saveAndContinue,
      accessiblePaths,
      suggestedPeople,
      activeStepKey,
      iteration,
      sendMarketingData
    ]
  );

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <PlanFormContext.Provider value={contextValue}>
      {children}
    </PlanFormContext.Provider>
  );
};

export default PlanFormProvider;
