import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import BeneCardLayout from "./BeneCardLayout";
import useBeneficiaries from "./hooks";
import { BeneficiariesTabProps } from "./types";

const BeneficiariesTab: React.FC<BeneficiariesTabProps> = ({
  specificGifts,
  estateBalanceGifts
}) => {
  const beneficiaries = useBeneficiaries(
    specificGifts,
    estateBalanceGifts
  );

  return (
    <Box>
      <Box
        mb="1.25rem"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.625rem"
        }}
      >
        <InfoOutlinedIcon />
        <Typography>
          A visual representation of who will receive your assets.
        </Typography>
      </Box>
      <Box my="2.5rem">
        <BeneCardLayout benes={beneficiaries} />
      </Box>
    </Box>
  );
};

export default BeneficiariesTab;
