import { PlanType } from "@api/__generated__/globalTypes";

const getPlanHumanizedTitle = (planType: PlanType) => {
  if (planType === PlanType.PET_PROTECTION) return "Pet Estate Plan";
  if (planType === PlanType.TRUST) return "Revocable Living Trust";

  return "Last Will & Testament";
};

export default getPlanHumanizedTitle;
