import { Navigate } from "react-router-dom";

import { ROUTES } from "@setup/consts/routes";
import TUTORIAL_ROUTES from "@pages/Tutorial/routes";
import SignIn from "@pages/auth/SignIn";
import SignUpPage from "@pages/auth/SignUp";
import AdvisorSignUpPage from "@pages/auth/SignUp/AdvisorSignUp";
import TutorialPage from "@pages/Tutorial";
import SignUpConfirmationPage from "@pages/SignupConfirmation";
import AuthFail from "@pages/auth/AuthFail";
import LastWillPlan from "@pages/LastWill";
import LAST_WILL_ROUTES from "@pages/LastWill/routes";
import TrustPlan from "@pages/Trust";
import TRUST_ROUTES from "@pages/Trust/routes";
import PetPlan from "@pages/PetPlan";
import PET_ROUTES from "@pages/PetPlan/routes";
import ResetPassword from "@pages/auth/ResetPassword";
import EstatePlanView from "@pages/EstatePlanView";
import AcceptInvitation from "@pages/auth/AcceptInvitation";
import DashboardSections from "@pages/EstatePlanView/Partials/routes";
import EstateAdminQuizSection from "@pages/EstateAdminQuiz";
import estateAdminQuizSteps from "@pages/EstateAdminQuiz/routes";
import EstatePlansMatchmakingSection from "@pages/EstatePlansMatchmaking";
import matchmakingSteps from "@pages/EstatePlansMatchmaking/routes";
import POWER_OF_ATTORNEY_ROUTES from "@pages/PowerOfAttorney/routes";
import PowerOfAttorney from "@pages/PowerOfAttorney";
import Healthcare from "@pages/Healthcare";
import HEALTHCARE_ROUTES from "@pages/Healthcare/routes";

import InvalidRouteHandler from "../InvalidRouteHandler";

import { RoutesProps } from "./types";

export const APP_ROUTES: RoutesProps[] = [
  {
    path: ROUTES.LastWill,
    element: <LastWillPlan />,
    children: LAST_WILL_ROUTES,
    isProtected: true
  },
  {
    path: ROUTES.Trust,
    element: <TrustPlan />,
    children: TRUST_ROUTES,
    isProtected: true
  },
  {
    path: ROUTES.PetPlan,
    element: <PetPlan />,
    children: PET_ROUTES,
    isProtected: true
  },
  {
    path: ROUTES.PowerOfAttorney,
    element: <PowerOfAttorney />,
    children: POWER_OF_ATTORNEY_ROUTES,
    isProtected: true
  },
  {
    path: ROUTES.Healthcare,
    element: <Healthcare />,
    children: HEALTHCARE_ROUTES,
    isProtected: true
  },
  {
    path: ROUTES.Tutorial,
    element: <TutorialPage />,
    children: TUTORIAL_ROUTES,
    isProtected: true
  },
  {
    path: ROUTES.SignUp,
    element: <SignUpPage />
  },
  {
    path: ROUTES.AdvisorSignUp,
    element: <AdvisorSignUpPage />
  },
  {
    path: ROUTES.SignIn,
    element: <SignIn />
  },
  {
    path: ROUTES.SignInExistingAccount,
    element: <AuthFail />
  },
  {
    path: ROUTES.ResetPassword,
    element: <ResetPassword />
  },
  {
    path: ROUTES.Root,
    element: <EstatePlanView />,
    isProtected: true
  },
  {
    path: ROUTES.EstateAdminQuiz,
    element: <EstateAdminQuizSection />,
    children: estateAdminQuizSteps
  },
  {
    path: ROUTES.EstatePlansMatchMaking,
    element: <EstatePlansMatchmakingSection />,
    children: matchmakingSteps,
    isProtected: false
  },
  {
    path: ROUTES.Dashboard,
    element: <EstatePlanView />,
    children: DashboardSections,
    isProtected: true
  },
  {
    path: ROUTES.SignUpConfirmation,
    element: <SignUpConfirmationPage />
  },
  {
    path: ROUTES.Invitation,
    element: <AcceptInvitation />,
    isProtected: true
  },
  {
    path: "*",
    element: <Navigate to={ROUTES.Root} />,
    isProtected: true
  },
  {
    path: "*",
    element: <InvalidRouteHandler to={ROUTES.SignUp} />
  }
];
