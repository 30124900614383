import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { PlanOption } from "@api/__generated__/globalTypes";

const CreatePOAStep: React.FC = () => {
  const { value: planOption } =
    useFormField<PlanOption>(`planOption`);
  const { value: firstName } = useFormField(`users.0.firstName`);

  const { setValue: setPOAValues } = useFormField("users.0.poa");

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const shouldIncludePoA = get(data, "users.0.poa.include");

      if (!parseBoolean(shouldIncludePoA)) {
        setPOAValues({
          include: false
        });
      }
    },
    [setPOAValues]
  );

  if (planOption === PlanOption.COUPLE) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `Let's start with ${firstName}. Do you want to create a Power of Attorney?`
        }}
        name="users.0.poa.include"
        {...{ onSubmit }}
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want to create a Power of Attorney?`
      }}
      name="users.0.poa.include"
      {...{ onSubmit }}
    />
  );
};

export default CreatePOAStep;
