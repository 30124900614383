/* eslint-disable react/no-unescaped-entities */
import InterstitialArchetype from "@components/archetypes/Interstitial";

const InterstitialM1Step: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `We're going to pick someone to serve as your Personal Representative.`,
      subText: "Learn more about Personal Representatives.",
      moreInfoModalProps: {
        title: "What is a Personal Representative?",
        togglerLabel: "Learn more",
        content: (
          <p>
            Your Personal Representative, also known as an Executor,
            is a trusted individual responsible for winding up your
            earthly affairs after you pass.
            <br />
            <br />
            Your Personal Representative protects and manages your
            property until all debts and taxes are paid, and sees that
            what&apos;s left is transferred to the people who are
            entitled to it.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
  />
);

export default InterstitialM1Step;
