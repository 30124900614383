import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { Beneficiary } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";

import BeneficiaryForm from "./partials/BeneficiaryForm";
import AllocationMessage from "./partials/AllocationMessage";
import useBeneficiarySchema from "./partials/BeneficiaryForm/hooks";
import FormHeader from "./partials/FormHeader";

const ResidueAllocateStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const singleItemSchema = useBeneficiarySchema();
  const { value } = useFormField<Beneficiary[]>(
    "users.0.assets.estateBalance.0.beneficiaries"
  );
  const { success } = useAllocationSum(value, {
    path: "percentageValue"
  });

  const { value: everythingGoToPartner } = useFormField(
    "users.0.gifts.everythingGoToPartner"
  );
  const { value: partnerFirstName } = useFormField<string>(
    "users.1.firstName"
  );

  if (everythingGoToPartner === true) {
    return (
      <ArrayFormArchetype
        stepLayout={{
          title: formatMessage(
            {
              defaultMessage:
                "If {partnerFirstName} fails to survive you, who should receive your residuary estate?"
            },
            {
              partnerFirstName
            }
          )
        }}
        addBtnLabel="Add another beneficiary"
        {...{ singleItemSchema }}
        customRemoveBtn
        SingleItem={BeneficiaryForm}
        name="users.0.assets.estateBalance.0.beneficiaries"
        topContent={<FormHeader />}
        bottomContent={
          <AllocationMessage name="users.0.assets.estateBalance.0.beneficiaries" />
        }
        disabled={!success}
      />
    );
  }

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: formatMessage({
          defaultMessage: `Who should receive your residuary estate?`
        })
      }}
      addBtnLabel="Add another beneficiary"
      {...{ singleItemSchema }}
      customRemoveBtn
      SingleItem={BeneficiaryForm}
      name="users.0.assets.estateBalance.0.beneficiaries"
      topContent={<FormHeader />}
      bottomContent={
        <AllocationMessage name="users.0.assets.estateBalance.0.beneficiaries" />
      }
      disabled={!success}
    />
  );
};

export default ResidueAllocateStep;
