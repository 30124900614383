import { List } from "@mui/material";

import { ButtonsListProps } from "./types";
import * as Styled from "./styles";

const ButtonsList: React.FC<ButtonsListProps> = ({ items }) => (
  <List disablePadding sx={{ width: "100%" }}>
    {items.map(
      ({ key, href, label, imgSrc, backgroundColor, onClick }) => (
        <Styled.ListItem {...{ key }} disableGutters>
          <Styled.Button
            {...{ href, onClick, backgroundColor }}
            sx={{ backgroundColor }}
            variant="contained"
          >
            {imgSrc && (
              <Styled.ButtonImage src={imgSrc} alt={label} />
            )}
            <Styled.ButtonLabel>{label}</Styled.ButtonLabel>
          </Styled.Button>
        </Styled.ListItem>
      )
    )}
  </List>
);

export default ButtonsList;
