/* eslint-disable no-irregular-whitespace */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePetBreedsHorse = (): AutocompleteBaseOption[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({ defaultMessage: `Akhal Teke` }),
        value: "Akhal Teke"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Quarter`
        }),
        value: "American Quarter"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Saddlebred`
        }),
        value: "American Saddlebred"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Andalusian` }),
        value: "Andalusian"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Appaloosa` }),
        value: "Appaloosa"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Arabian` }),
        value: "Arabian"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Belgian` }),
        value: "Belgian"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Clydesdale` }),
        value: "Clydesdale"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Friesian` }),
        value: "Friesian"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Marwari` }),
        value: "Marwari"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Miniature` }),
        value: "Miniature"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Morgan` }),
        value: "Morgan"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Mustang` }),
        value: "Mustang"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Percheron` }),
        value: "Percheron"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Shetland Pony`
        }),
        value: "Shetland Pony"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Shire` }),
        value: "Shire"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Standardbred` }),
        value: "Standardbred"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Tennesee Walking`
        }),
        value: "Tennesee Walking"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Thoroughbred` }),
        value: "Thoroughbred"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Warmblooded` }),
        value: "Warmblooded"
      }
    ],
    [intl]
  );

  return items;
};
