import { gql } from "@apollo/client";

export const GET_CHARITIES = gql`
  query GetCharities($text: String!, $take: Int!) {
    charity(text: $text, take: $take) {
      nodes {
        name
        ein
        zip
        city
        state
        street
      }
    }
  }
`;
