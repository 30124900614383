import { PersonalPropertyBalanceDistributionType } from "@api/__generated__/globalTypes";
import { Asset } from "@api/plans/__generated__/Asset";

const getHumanizedPersonalPropertyTypeAPI = (
  balanceType: PersonalPropertyBalanceDistributionType | undefined,
  partnerType: string,
  assets?: Pick<Asset, "beneficiaries">[]
): string => {
  const beneficiaries = assets?.flatMap((asset) =>
    asset.beneficiaries?.map((bene) => bene)
  );
  const recipients = beneficiaries?.flatMap(
    (bene) => bene?.user?.fullName
  );

  if (
    balanceType ===
    PersonalPropertyBalanceDistributionType.PARTNER_AND_CHILDREN
  ) {
    return `Distribute among my ${partnerType} and children as they may agree.`;
  }

  if (
    balanceType === PersonalPropertyBalanceDistributionType.PARTNER
  ) {
    return `Distribute to my ${partnerType}`;
  }

  if (
    balanceType === PersonalPropertyBalanceDistributionType.CHILDREN
  ) {
    return "Distribute among my children as they may agree";
  }

  if (
    balanceType ===
      PersonalPropertyBalanceDistributionType.OTHER_PEOPLE &&
    assets
  ) {
    return `Distribute among the following people as they may agree: ${recipients
      ?.map((person) => person)
      .join(", ")}`;
  }

  return "Distribute among my named beneficiaries";
};

export default getHumanizedPersonalPropertyTypeAPI;
