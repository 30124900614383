import { useFormField } from "@components/FormFields/hooks";
import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";

const PartnerDesignateCoTrusteeStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <PersonDetailsArrayArchetype
      name="users.1.trust.coTrustees"
      stepLayout={{
        title: `Who does ${partnerFirstName} want to add as Co-Trustee?`,
        subText:
          "Any Co-Trustee(s) you add here will have all the same powers in managing the trust as you. They'll be authorized to act indepenendently, and you'll have the ability to change or remove them at any time.",
        moreInfoModalProps: {
          title: "Designating Co-Trustee(s)",
          togglerLabel: "Learn more.",
          content: (
            <p>
              A Co-Trustee is an individual or entity who serves as
              Trustee alongside you. They are responsible for managing
              the trust&apos;s affairs and generally have all the same
              powers that you do as Trustmaker, with the exception
              that only the Trustmaker can change or revoke the trust.
              <br />
              <br />
              <strong>Designating Multiple Co-Trustees. </strong>
              <br />
              <br />
              Each Co-Trustee you designate will have authority to act
              severally (meaning, independent of any majority vote by
              the other Co-Trustees) and have all the same powers in
              managing the trust affairs as you do, except the power
              to amend or revoke the trust.
              <br />
              <br />
              If you decide to designate multiple Co-Trustees, you
              should be confident that they will communicate with one
              another to avoid potentially contradictory actions.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      personRole="Co-Trustee"
      addBtnLabel="Add Another Co-Trustee"
      removeBtnLabel="Remove Co-Trustee"
    />
  );
};

export default PartnerDesignateCoTrusteeStep;
