import { useMemo, useCallback } from "react";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { FormControl } from "@mui/material";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import FormInput from "@components/FormFields/FormInput";
import FormSelect from "@components/FormFields/FormSelect";
import { useRelationshipItems } from "@hooks/useRelationshipItems";
import { Guardian, LastWillPlanValues } from "@pages/LastWill/types";
import { ArrayFormValues } from "@components/archetypes/ArrayForm/types";
import { PersonValues } from "@components/archetypes/PersonDetails/types";
import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";

type AntiGuardianFormProps = {
  prefix: string;
  index: number;
};
// TODO PHASE 3: Update where storing this data once we see how it works with 'regular' Guardian

const AntiGuardianForm: React.FC<AntiGuardianFormProps> = ({
  prefix,
  index
}) => {
  const { formatMessage } = useIntl();
  const relationshipItems = useRelationshipItems();

  return (
    <>
      <FormInput
        name={`${prefix}.name`}
        label={formatMessage(
          { defaultMessage: "Person #{number}’s Full Name" },
          { number: index + 1 }
        )}
      />
      <FormSelect
        name={`${prefix}.relationship`}
        label={formatMessage(
          {
            defaultMessage: "Person #{number}’s Relationship to You"
          },
          { number: index + 1 }
        )}
        items={relationshipItems}
      />
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <FormInput
          name={`${prefix}.reason`}
          label={formatMessage(
            {
              defaultMessage: "Reason Person #{number} is Not a Fit"
            },
            { number: index + 1 }
          )}
          variant="outlined"
          multiline
          minRows={5}
        />
      </FormControl>
    </>
  );
};

const DesignateAntiGuardianStep: React.FC = () => {
  const { formatMessage } = useIntl();

  // Not sure if I got the format for address right...
  const formSchema: yup.SchemaOf<Guardian> = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(),
        relationship: yup.string().required(),
        address: yup.object().shape({
          address1: yup.string().optional(),
          address2: yup.string().optional(),
          city: yup.string().optional(),
          zip: yup.string().optional(),
          state: yup.string().optional(),
          county: yup.string().optional(),
          country: yup.string().optional()
        })
      }),
    [formatMessage]
  );

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `Who would not be a good choice for guardian?`,
        subText:
          "You can add additional people by hitting the 'Add Another Person' button."
      }}
      addBtnLabel="Add Another Person"
      singleItemSchema={formSchema}
      SingleItem={AntiGuardianForm}
      name="prohibitedGuardians"
      // {...{onSubmit}}
    />
  );
};

export default DesignateAntiGuardianStep;
