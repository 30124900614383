import { gql } from "@apollo/client";

export const INVITATION_FRAGMENT = gql`
  fragment Invitation on Invitation {
    clientName
    clientEmail
    token
    recommendedPlan
  }
`;
