import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewTabsArchetype from "@components/archetypes/ReviewTabs";
import ReviewBox from "@components/ReviewBox";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

import { usePrimaryDrafterManagementReviewTab } from "./Reviews/usePrimaryDrafterManagementReviewTab";
import { usePartnerManagementReviewTab } from "./Reviews/usePartnerManagementReviewTab";

const ReviewTabsStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter, partner]
  } = values;

  const primaryDrafterItem = usePrimaryDrafterManagementReviewTab({
    values
  });
  const partnerItem = usePartnerManagementReviewTab({ values });

  const items = useMemo(
    () => [
      {
        label:
          primaryDrafter?.firstName ??
          formatMessage({ defaultMessage: "You" }),
        content: (
          <Grid container spacing={{ xs: 3, md: 5.5 }}>
            {primaryDrafterItem.map(({ title, ...item }) => (
              <Grid item xs={12} md={12} key={title}>
                <ReviewBox {...{ title, ...item }} />
              </Grid>
            ))}
          </Grid>
        )
      },
      {
        label:
          partner?.firstName ??
          formatMessage({ defaultMessage: "Partner" }),
        content: partnerItem ? (
          <Grid container spacing={{ xs: 3, md: 5.5 }}>
            {partnerItem.map(({ title, ...item }) => (
              <Grid item xs={12} md={12} key={title}>
                <ReviewBox {...{ title, ...item }} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>
            <FormattedMessage defaultMessage="No Partner" />
          </Typography>
        )
      }
    ],
    [
      formatMessage,
      partner?.firstName,
      partnerItem,
      primaryDrafter?.firstName,
      primaryDrafterItem
    ]
  );

  return (
    <ReviewTabsArchetype
      {...{ items }}
      stepLayout={{
        title: `Looks like an all star cast!`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewTabsStep;
