import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/PetPlan/routes";
import { useFormField } from "@components/FormFields/hooks";

const SectionsPreviewStep: React.FC = () => {
  const { value: firstName } = useFormField("users.0.firstName");

  return (
    <SectionsPreview
      title="Create My Pet Plan"
      {...{ sections }}
      stepLayout={{
        title: `Well done, ${firstName}! You are making play out of this Pet Estate Plan.`,
        subText: "Just one section left!"
      }}
    />
  );
};

export default SectionsPreviewStep;
