import { useIntl } from "react-intl";
import {
  Box,
  CircularProgress,
  Container,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { useCallback } from "react";

import PageTitle from "@components/PageTitle";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { EmailSentContextProvider } from "@providers/emailSentState";

import { useLoggedInUserName } from "./Partials/utils";
import * as Styled from "./Partials/styles";
import SideNavSection from "./Partials/components/SideNav";
import useDashboardNavItems from "./Partials/hooks/useDashboardNavItems";

const EstatePlanView: React.FC = () => {
  const intl = useIntl();

  const { loading } = useMemberPlansState();
  const items = useDashboardNavItems();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const userName = useLoggedInUserName() || "New Member";

  const renderDashBoardView = useCallback(() => {
    if (loading) {
      return (
        <Styled.LoadingWrapper>
          <CircularProgress />
        </Styled.LoadingWrapper>
      );
    }

    return (
      <>
        <PageTitle
          title={intl.formatMessage({
            defaultMessage: "JIC Estates | Member Dashboard"
          })}
        />
        <Container
          maxWidth="lg"
          sx={{
            py: { xs: 2.5, md: 6.25 }
          }}
        >
          <Outlet />
        </Container>
      </>
    );
  }, [intl, loading]);

  return (
    <Box
      sx={{
        display: "flex"
      }}
    >
      <EmailSentContextProvider>
        {isDesktop && (
          <SideNavSection
            accountOwner={userName}
            sectionNames={items}
            sx={{position: "sticky", top: theme.spacing(5), height: "100vh", overflowY: "auto"}}
          />
        )}
        {renderDashBoardView()}
      </EmailSentContextProvider>
    </Box>
  );
};

export default EstatePlanView;
