export const LAST_WILL_FINAL_ARRANGEMENT_ROUTES = {
  Ceremony: "ceremony",
  OtherRequests: "other-requests",
  PartnerCeremony: "partner-ceremony",
  PartnerOtherRequests: "partner-requests",
  PartnerRestingPlace: "partner-resting-place",
  PartnerSameArrangements: "same-arrangements",
  Preview: "",
  RestingPlace: "resting-place",
  Review: "review",
  ReviewTabs: "review-tabs"
};
