import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";

import { VestingSchedule } from "@pages/LastWill/types";

const useAgeVestingScheduleSchema =
  (): yup.SchemaOf<VestingSchedule> => {
    const { formatMessage } = useIntl();

    const singleItemSchema: yup.SchemaOf<VestingSchedule> = useMemo(
      () =>
        yup.object().shape({
          percentageValue: yup
            .number()
            .required()
            .typeError(
              formatMessage({
                defaultMessage:
                  "Please enter a number between 0 and 100."
              })
            ),
          age: yup
            .number()
            .integer(
              "Please enter a whole number between 18 and 121."
            )
            .typeError(
              formatMessage({
                defaultMessage:
                  "Please enter a whole number between 18 and 121."
              })
            )
            .test(
              "age",
              formatMessage({
                defaultMessage:
                  "Vesting age should be a whole number between 18 and 121."
              }),
              (value) => (value ? value >= 18 && value <= 121 : false)
            )
            .required(
              formatMessage({
                defaultMessage:
                  "Please enter the age (as a number) at which this distribution should occur"
              })
            )
        }),
      [formatMessage]
    );

    return singleItemSchema;
  };

export default useAgeVestingScheduleSchema;
