import { useMemo } from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";

import { Asset } from "@api/plans/__generated__/Asset";
import { AssetType } from "@api/__generated__/globalTypes";
import { useAssetHumanizedTitle } from "@hooks/useAssetHumanizedTitle";
import { ReviewCardProps } from "@components/ReviewCard/types";
import getAssetNickname from "@utils/getAssetNickname";
import getAssetNetValue from "@utils/getAssetNetValue";
import { usePersonalPropertyTypeIcons } from "@pages/EstatePlanView/Partials/hooks/usePersonalPropertyTypeIcons";
import { ASSET_TYPE_ICONS } from "@setup/consts/assetTypeIcons";
import { getBeneficiaryInfo } from "@utils/getBeneficiaryInfo";
import EditAssetModal from "@pages/EstatePlanView/Partials/components/EditAssetModal";

import { EditAssetButton } from "../styles";

export const useAssetReviewItems = (
  assets: Asset[],
  showIsFunded = false
): ReviewCardProps[] => {
  const humanizedTitles = useAssetHumanizedTitle();
  const personalPropertyIcons = usePersonalPropertyTypeIcons();

  return useMemo(
    () =>
      assets.map<ReviewCardProps>((asset): ReviewCardProps => {
        const assetNickname = getAssetNickname(asset);
        const assetType = get(humanizedTitles, asset.type || "", "");
        const isPersonalProperty =
          asset.type === AssetType.PERSONAL_PROPERTY;
        const isFinancialAccount =
          asset.type === AssetType.FINANCIAL_ACCOUNT;

        const icon = isPersonalProperty
          ? get(personalPropertyIcons, asset.subtype || "", undefined)
          : get(ASSET_TYPE_ICONS, asset.type || "", undefined);

        // eslint-disable-next-line no-nested-ternary
        const description =
          isPersonalProperty || isFinancialAccount
            ? `${assetType} > ${asset.subtype}`
            : assetType ?? undefined;

        return {
          href: "",
          icon,
          title: assetNickname || assetType,
          description,
          items: [
            [`Approximate Value: ${getAssetNetValue(asset)}`],
            [
              getBeneficiaryInfo(
                asset.beneficiaries || [],
                asset.isFunded,
                showIsFunded
              )
            ]
          ].filter((item) => item.length > 0),
          editComponent: (
            <EditAssetModal
              {...{ asset, showIsFunded }}
              trigger={({ onClick }) => (
                <EditAssetButton {...{ onClick }}>
                  <FormattedMessage defaultMessage="Edit" />
                </EditAssetButton>
              )}
            />
          )
        };
      }),
    [assets, humanizedTitles, personalPropertyIcons, showIsFunded]
  );
};
