import {
  Button,
  Box,
  useMediaQuery
} from "@mui/material";
import { useIntl } from "react-intl";

import BasicFormAutocomplete from "@components/FormFields/FormAutocomplete/BasicAutocomplete";
import BasicFormInput from "@components/FormFields/FormInput/BasicFormInput";

import RecommendedPlanInfoModal from "../RecommendedPlanInfoModal";
import usePlanTypeOptions from "../../hooks/usePlanTypeOptions";
import { InviteClientFormElementsProps } from "./types";
import { Send as SendIcon } from "@mui/icons-material";
import { Link as LinkIcon } from "@mui/icons-material";
import theme from "@setup/theme";

const InviteClientFormElements: React.FC< InviteClientFormElementsProps> = ({ onCopyInviteLink, onInviteClient }) => {
  const { formatMessage } = useIntl();
  const planTypeOptions = usePlanTypeOptions();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.4rem"
      }}
    >
      <BasicFormInput
        label={formatMessage({
          defaultMessage: "Client Name"
        })}
        name="fullName"
      />
      <BasicFormInput
        label={formatMessage({
          defaultMessage: "Client Email Address"
        })}
        type="email"
        name="email"
      />
      <RecommendedPlanInfoModal />
      <BasicFormAutocomplete
        textFieldProps={{
          label: formatMessage({
            defaultMessage: "Recommended Plan"
          })}}
        options={planTypeOptions}
        name="recommendedPlan"
      />
      <BasicFormInput
        label={formatMessage({
          defaultMessage: "Message (Optional)"
        })}
        sx={{
          mt: "1.5rem",
          whiteSpace: "pre-line"
        }}
        variant="outlined"
        InputProps={{
          multiline: true,
          minRows: 5
        }}
        name="message"
      />
      <Box
        sx={{
          mt: "1.5rem",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          gap: "0.5rem"
        }}
      >
        <Button
          type="submit"
          variant="contained"
          startIcon={<SendIcon />}
          onClick={onInviteClient}
        >
          {formatMessage({
            defaultMessage: "SEND INVITE"
          })}
        </Button>
        <Button
          type="submit"
          variant="outlined"
          startIcon={<LinkIcon sx={{ transform: "rotate(-45deg)" }}/>}
          onClick={onCopyInviteLink}
        >
          {formatMessage({
            defaultMessage: "COPY LINK"
          })}
        </Button>
      </Box>
    </Box>
  );
};

export default InviteClientFormElements;
