import {
  Chair,
  Diamond,
  Flatware,
  DirectionsCar,
  Interests
} from "@mui/icons-material";
import { useMemo } from "react";

export const usePersonalPropertyTypeIcons = () =>
  useMemo(
    () => ({
      "Fixtures and Furniture": <Chair />,
      Jewelry: <Diamond />,
      "Household Items": <Flatware />,
      Vehicle: <DirectionsCar />,
      Other: <Interests />
    }),
    []
  );
