import { useForm } from "react-hook-form";
import { useCallback } from "react";

import { CreatePlanTodoVariables } from "@api/plans/__generated__/CreatePlanTodo";
import { UpdatePlanTodoVariables } from "@api/plans/__generated__/UpdatePlanTodo";
import { usePlanTodosContext } from "@providers/planTodos/context";

import {
  UpdatePlanTodoProps,
  UseCreatePlanTodosParams,
  UseUpdatePlanTodosParams
} from "./type";

export const useCreatePlanTodos = () => {
  const methods = useForm<UseCreatePlanTodosParams>({
    defaultValues: {
      title: ""
    }
  });

  const { createPlanTodo } = usePlanTodosContext();

  const onSubmit = useCallback(
    async (data: CreatePlanTodoVariables) => {
      if (!data?.title) return;

      createPlanTodo(data.title);
      methods.reset();
    },
    [createPlanTodo, methods]
  );

  return {
    onSubmit,
    methods
  };
};

export const useUpdatePlanTodos = ({
  task,
  close
}: UpdatePlanTodoProps) => {
  const { updatePlanTodoTitle } = usePlanTodosContext();

  const methods = useForm<UseUpdatePlanTodosParams>({
    defaultValues: {
      title: task.title
    }
  });

  const onSubmit = useCallback(
    async (data: UpdatePlanTodoVariables) => {
      if (!data?.title) return;

      updatePlanTodoTitle(task, data.title);
      close();
    },
    [updatePlanTodoTitle, task, close]
  );

  return {
    onSubmit,
    methods
  };
};
