import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";

import { EXPERT_MATCHING_RESULTS } from "./consts";
import UserInfo from "./steps/CollectUserInfo";
import FailedUserInfo from "./steps/CollectFailedUserInfo";
import InfoSubmittedStep from "./steps/InfoSubmitted";
import ResultsTabsStep from "./steps/ResultsTabs";
import ResultsStep from "./steps/Results";

const expertMatchingResultsSteps: PlanFormStep[] = [
  {
    path: EXPERT_MATCHING_RESULTS.Results,
    element: <ResultsStep />
  },
  {
    path: EXPERT_MATCHING_RESULTS.ResultsTab,
    element: <ResultsTabsStep />
  },
  {
    path: EXPERT_MATCHING_RESULTS.CollectUserInfo,
    element: <UserInfo />
  },
  {
    path: EXPERT_MATCHING_RESULTS.CollectFailedUserInfo,
    element: <FailedUserInfo />
  },
  {
    path: EXPERT_MATCHING_RESULTS.InfoSubmitted,
    element: <InfoSubmittedStep />
  },
  {
    path: "*",
    element: <Navigate to={EXPERT_MATCHING_RESULTS.Results} />
  }
];

export default expertMatchingResultsSteps;
