import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const CreateOrModifyTrustStep: React.FC = () => {

  const options = [
    {
      label: "Create new trust",
      value: false,
    },
    {
      label: "Modify an existing trust built outside of JIC Estates",
      value: true
    }
  ]
  
  return (
    <SingleSelectArchetype
      stepLayout={{
        title: "Will you be creating a new trust or modifying an existing one built outside of JIC Estates?",
      }}
      name="users.0.trust.isExistingTrust"
      items={options}
      defaultValue={false}
    />
  );
};

export default CreateOrModifyTrustStep;
