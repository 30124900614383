import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/LastWill/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    stepLayout={{
      title:
        "Now that we’re properly sorted, let's get this party started!",
      subText: "We'll build your Will in 5 sections."
    }}
  />
);

export default SectionsPreviewStep;
