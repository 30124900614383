import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";

import PageTitle from "@components/PageTitle";
import useProgressBarContext from "@providers/progressBar/hooks";

const PetCareSection: React.FC = () => {
  const intl = useIntl();
  const { setTitle } = useProgressBarContext();

  useEffect(() => {
    setTitle(intl.formatMessage({ defaultMessage: "Pet Care Instructions" }));
  }, [intl, setTitle]);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          defaultMessage: "Pet Care Instructions"
        })}
      />
      <Outlet />
    </>
  );
};

export default PetCareSection;
