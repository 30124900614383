import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";

import useUserContext from "@providers/user/hooks";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import DocumentUploader from "@components/DocumentUploader";
import { useDeleteFileMutation } from "@api/files/hooks";
import { ROUTES } from "@setup/consts/routes";

import PlanNotStartedMessage from "../../components/PlanNotStartedMessage";
import PlanNotCompletedMessage from "../../components/PlanNotCompletedMessage";
import MyRoleDetails from "../../components/MyRoleDetails";
import DocsSlider from "../../components/DocsSlider";
import DocumentHistory from "../../components/DocumentHistory";
import DocsAndDataTitle from "../../components/DocsAndDataTitle";
import AdvisorTitleContent from "../../components/AdvisorTitleContent";
import NeedHelpMessage from "../../components/NeedHelpMessage";
import {
  lastWillPlanObject,
  trustPlanObject
} from "../../components/CreatePlanCard/consts";
import { getLinkFullUrl } from "../../utils";

import DataReviewSection from "./DataReviewSection";
import CreatePlanCard from "../../components/CreatePlanCard";

const DocsAndDataTab = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [, { loading }] = useDeleteFileMutation();
  const userInfo = useUserContext();
  const { isAdvisor, user } = userInfo;
  const {
    myRoles,
    trust,
    lastWill,
    petProtection,
    planStarted,
    planCompleted
  } = useMemberPlansState();

  const lwPath = planStarted.lastWillPath
    ? getLinkFullUrl([ROUTES.LastWill, planStarted.lastWillPath])
    : getLinkFullUrl([ROUTES.LastWillStart]);
  const trustPath = planStarted.trustPath
    ? getLinkFullUrl([ROUTES.Trust, planStarted.trustPath])
    : getLinkFullUrl([ROUTES.TrustStart]);

  if (!planStarted.isStarted && myRoles === undefined) {
    return (
      <>
        {isAdvisor && <AdvisorTitleContent user={user} />}
        {!isAdvisor && <DocsAndDataTitle />}
        <PlanNotStartedMessage />
      </>
    );
  }

  if (!planCompleted && myRoles === undefined) {
    return (
      <>
        {isAdvisor && <AdvisorTitleContent user={user} />}
        {!isAdvisor && <DocsAndDataTitle />}
        <PlanNotCompletedMessage />
      </>
    );
  }

  if (myRoles && !trust && !lastWill && !petProtection) {
    return (
      <>
        <DocsAndDataTitle />
        <MyRoleDetails />
      </>
    );
  }

  return (
    <Grid mt={theme.spacing(2)}>
      <DocsAndDataTitle />
      <Typography variant="h3" sx={{ mt: "2.5rem", mb: "1.25rem" }}>
        <FormattedMessage defaultMessage="Documents" />
      </Typography>
      <DocsSlider />
      {(trust?.canEdit || lastWill?.canEdit) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.1rem"
          }}
        >
          <DocumentUploader
            planId={trust ? trust?.plan.id : lastWill?.plan.id}
          />
        </Box>
      )}
      <Box mt="1.25rem">
        {(trust?.isOwner ||
          lastWill?.isOwner ||
          petProtection?.isOwner) && (
          <NeedHelpMessage message="Need Help With Signing?" />
        )}
        {!trust?.isOwner &&
          !lastWill?.isOwner &&
          !petProtection?.isOwner && (
            <NeedHelpMessage message="Need Help With Something?" />
          )}
      </Box>
      <Typography variant="h3" sx={{ mt: "2.5rem" }}>
        Data
      </Typography>
      <DataReviewSection />
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          marginTop: "1.5rem"
        }}
      >
        <Typography variant="h3" sx={{ mt: "2.5rem" }}>
          <FormattedMessage defaultMessage="Document History" />
        </Typography>
      </Box>
      <DocumentHistory />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (t) => t.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!trust?.isOwner &&
        !lastWill?.isOwner &&
        petProtection?.isOwner && (
          <Box mt="1.25rem" alignContent="center" textAlign="center">
            <Typography
              variant="h5"
              sx={{ mt: "2.5rem", mb: "1.25rem", align: "center" }}
            >
              <FormattedMessage defaultMessage="Ready to Take the Next Step in Your Estate Plan?" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                gap: "1.25rem"
              }}
            >
              <CreatePlanCard
                name={trustPlanObject.name}
                image={trustPlanObject.image}
                path={trustPath}
                isStarted={planStarted.trust}
              />
              <CreatePlanCard
                name={lastWillPlanObject.name}
                image={lastWillPlanObject.image}
                path={lwPath}
                isStarted={planStarted.lastWill}
              />
            </Box>
          </Box>
        )}
    </Grid>
  );
};

export default DocsAndDataTab;
