/* eslint-disable no-irregular-whitespace */
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePetBreedsDogs = (): AutocompleteBaseOption[] => {
  const intl = useIntl();

  const items = useMemo(
    () => [
      {
        label: intl.formatMessage({
          defaultMessage: `Affenpinscher`
        }),
        value: "Affenpinscher"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Afghan Hound` }),
        value: "Afghan Hound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Airedale Terrier`
        }),
        value: "Airedale Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Akita` }),
        value: "Akita"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Alaskan Malamute`
        }),
        value: "Alaskan Malamute"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American English Coonhound`
        }),
        value: "American English Coonhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Eskimo Dog`
        }),
        value: "American Eskimo Dog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Foxhound`
        }),
        value: "American Foxhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Hairless Terrier`
        }),
        value: "American Hairless Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Staffordshire Terrier`
        }),
        value: "American Staffordshire Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `American Water Spaniel`
        }),
        value: "American Water Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Anatolian Shepherd Dog`
        }),
        value: "Anatolian Shepherd Dog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Australian Cattle Dog`
        }),
        value: "Australian Cattle Dog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Australian Shepherd`
        }),
        value: "Australian Shepherd"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Australian Terrier`
        }),
        value: "Australian Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Azawakh` }),
        value: "Azawakh"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Barbet` }),
        value: "Barbet"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Basenji` }),
        value: "Basenji"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Basset Hound` }),
        value: "Basset Hound"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Beagle` }),
        value: "Beagle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bearded Collie`
        }),
        value: "Bearded Collie"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Beauceron` }),
        value: "Beauceron"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bedlington Terrier`
        }),
        value: "Bedlington Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Belgian Laekenoi`
        }),
        value: "Belgian Laekenoi"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Belgian Malinois`
        }),
        value: "Belgian Malinois"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Belgian Sheepdog`
        }),
        value: "Belgian Sheepdog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Belgian Tervuren`
        }),
        value: "Belgian Tervuren"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bergamasco Sheepdog`
        }),
        value: "Bergamasco Sheepdog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Berger Picard`
        }),
        value: "Berger Picard"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bernese Mountain Dog`
        }),
        value: "Bernese Mountain Dog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bichons Frise`
        }),
        value: "Bichons Frise"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Biewer Terrier`
        }),
        value: "Biewer Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Black and Tan Coonhound`
        }),
        value: "Black and Tan Coonhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Black Russian Terrier`
        }),
        value: "Black Russian Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Bloodhound` }),
        value: "Bloodhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bluetick Coonhound`
        }),
        value: "Bluetick Coonhound"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Boerboel` }),
        value: "Boerboel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Border Collie`
        }),
        value: "Border Collie"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Border Terrier`
        }),
        value: "Border Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Borzois` }),
        value: "Borzois"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Boston Terrier`
        }),
        value: "Boston Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Bouviers des Flandres`
        }),
        value: "Bouviers des Flandres"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Boxer` }),
        value: "Boxer"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Boykin Spaniel`
        }),
        value: "Boykin Spaniel"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Briard` }),
        value: "Briard"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Brittany` }),
        value: "Brittany"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Brussels Griffon`
        }),
        value: "Brussels Griffon"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Bull Terrier` }),
        value: "Bull Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Bulldog` }),
        value: "Bulldog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Bullmastiff` }),
        value: "Bullmastiff"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Cairn Terrier`
        }),
        value: "Cairn Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Canaan Dog` }),
        value: "Canaan Dog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Cane Corso` }),
        value: "Cane Corso"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Cardigan Welsh Corgi`
        }),
        value: "Cardigan Welsh Corgi"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Cavalier King Charles Spaniel`
        }),
        value: "Cavalier King Charles Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Cesky Terrier`
        }),
        value: "Cesky Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Chesapeake Bay Retriever`
        }),
        value: "Chesapeake Bay Retriever"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Chihuahua` }),
        value: "Chihuahua"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Chinese Crested`
        }),
        value: "Chinese Crested"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Chinese Shar-Pei`
        }),
        value: "Chinese Shar-Pei"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Chinook` }),
        value: "Chinook"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Chow Chow` }),
        value: "Chow Chow"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Cirnechi dell’Etna`
        }),
        value: "Cirnechi dell’Etna"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Clumber Spaniel`
        }),
        value: "Clumber Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Cocker Spaniel`
        }),
        value: "Cocker Spaniel"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Collie` }),
        value: "Collie"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Coton de Tulear`
        }),
        value: "Coton de Tulear"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Curly-Coated Retriever`
        }),
        value: "Curly-Coated Retriever"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Dachshund` }),
        value: "Dachshund"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Dalmatian` }),
        value: "Dalmatian"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Dandie Dinmont Terrier`
        }),
        value: "Dandie Dinmont Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Doberman Pinscher`
        }),
        value: "Doberman Pinscher"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Dogo Argentino`
        }),
        value: "Dogo Argentino"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Dogues de Bordeaux`
        }),
        value: "Dogues de Bordeaux"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `English Cocker Spaniel`
        }),
        value: "English Cocker Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `English Foxhound`
        }),
        value: "English Foxhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `English Setter`
        }),
        value: "English Setter"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `English Springer Spaniel`
        }),
        value: "English Springer Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `English Toy Spaniel`
        }),
        value: "English Toy Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Entlebucher Mountain Dog`
        }),
        value: "Entlebucher Mountain Dog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Field Spaniel`
        }),
        value: "Field Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Finnish Lapphund`
        }),
        value: "Finnish Lapphund"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Finnish Spitz`
        }),
        value: "Finnish Spitz"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Flat-Coated Retriever`
        }),
        value: "Flat-Coated Retriever"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Fox Terriers (Wire)`
        }),
        value: "Fox Terriers (Wire)"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `French Bulldog`
        }),
        value: "French Bulldog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `German Pinscher`
        }),
        value: "German Pinscher"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `German Shepherd`
        }),
        value: "German Shepherd"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `German Shorthaired Pointer`
        }),
        value: "German Shorthaired Pointer"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `German Wirehaired Pointer`
        }),
        value: "German Wirehaired Pointer"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Giant Schnauzer`
        }),
        value: "Giant Schnauzer"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Glen of Imaal Terrier`
        }),
        value: "Glen of Imaal Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Golden Retriever`
        }),
        value: "Golden Retriever"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Gordon Setter`
        }),
        value: "Gordon Setter"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Grand Basset Griffon Vendeen`
        }),
        value: "Grand Basset Griffon Vendeen"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Great Dane` }),
        value: "Great Dane"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Great Pyrenee`
        }),
        value: "Great Pyrenee"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Greater Swiss Mountain Dog`
        }),
        value: "Greater Swiss Mountain Dog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Greyhound` }),
        value: "Greyhound"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Harrier` }),
        value: "Harrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Havanese` }),
        value: "Havanese"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Ibizan Hound` }),
        value: "Ibizan Hound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Icelandic Sheepdog`
        }),
        value: "Icelandic Sheepdog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Irish Red and White Setter`
        }),
        value: "Irish Red and White Setter"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Irish Setter` }),
        value: "Irish Setter"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Irish Terrier`
        }),
        value: "Irish Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Irish Water Spaniel`
        }),
        value: "Irish Water Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Irish Wolfhound`
        }),
        value: "Irish Wolfhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Italian Greyhound`
        }),
        value: "Italian Greyhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Japanese Chin`
        }),
        value: "Japanese Chin"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Keeshonden` }),
        value: "Keeshonden"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Kerry Blue Terrier`
        }),
        value: "Kerry Blue Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Komondorok` }),
        value: "Komondorok"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Kuvaszok` }),
        value: "Kuvaszok"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Labrador` }),
        value: "Labrador"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Lagotti Romagnoli`
        }),
        value: "Lagotti Romagnoli"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Lakeland Terrier`
        }),
        value: "Lakeland Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Leonberger` }),
        value: "Leonberger"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Lhasa Apso` }),
        value: "Lhasa Apso"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Lowchen` }),
        value: "Lowchen"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Maltese` }),
        value: "Maltese"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Manchester Terrier`
        }),
        value: "Manchester Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Mastiff` }),
        value: "Mastiff"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Miniature American Shepherd`
        }),
        value: "Miniature American Shepherd"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Miniature Bull Terrier`
        }),
        value: "Miniature Bull Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Miniature Pinscher`
        }),
        value: "Miniature Pinscher"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Miniature Schnauzer`
        }),
        value: "Miniature Schnauzer"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Neapolitan Mastiff`
        }),
        value: "Neapolitan Mastiff"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Nederlandse Kooikerhondje`
        }),
        value: "Nederlandse Kooikerhondje"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Newfoundland` }),
        value: "Newfoundland"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Norfolk Terrier`
        }),
        value: "Norfolk Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Norwegian Buhund`
        }),
        value: "Norwegian Buhund"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Norwegian Elkhound`
        }),
        value: "Norwegian Elkhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Norwegian Lundehund`
        }),
        value: "Norwegian Lundehund"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Norwich Terrier`
        }),
        value: "Norwich Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Nova Scotia Duck Tolling Retriever`
        }),
        value: "Nova Scotia Duck Tolling Retriever"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Old English Sheepdog`
        }),
        value: "Old English Sheepdog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Otterhound` }),
        value: "Otterhound"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Papillon` }),
        value: "Papillon"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Parson Russell Terrier`
        }),
        value: "Parson Russell Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Pekingese` }),
        value: "Pekingese"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Pembroke Welsh Corgi`
        }),
        value: "Pembroke Welsh Corgi"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Petits Bassets Griffons Vendeen`
        }),
        value: "Petits Bassets Griffons Vendeen"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Pharaoh Hound`
        }),
        value: "Pharaoh Hound"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Plott Hound` }),
        value: "Plott Hound"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Pointer` }),
        value: "Pointer"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Polish Lowland Sheepdog`
        }),
        value: "Polish Lowland Sheepdog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Pomeranian` }),
        value: "Pomeranian"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Poodle` }),
        value: "Poodle"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Portuguese Podengo Pequeno`
        }),
        value: "Portuguese Podengo Pequeno"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Portuguese Water Dog`
        }),
        value: "Portuguese Water Dog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Pug` }),
        value: "Pug"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Pulik` }),
        value: "Pulik"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Pumik` }),
        value: "Pumik"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Pyrenean Shepherd`
        }),
        value: "Pyrenean Shepherd"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Rat Terrier` }),
        value: "Rat Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Redbone Coonhound`
        }),
        value: "Redbone Coonhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Rhodesian Ridgeback`
        }),
        value: "Rhodesian Ridgeback"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Rottweiler` }),
        value: "Rottweiler"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Russell Terrier`
        }),
        value: "Russell Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Saluki` }),
        value: "Saluki"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Samoyed` }),
        value: "Samoyed"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Schipperke` }),
        value: "Schipperke"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Scottish Deerhound`
        }),
        value: "Scottish Deerhound"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Scottish Terrier`
        }),
        value: "Scottish Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Sealyham Terrier`
        }),
        value: "Sealyham Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Shetland Sheepdog`
        }),
        value: "Shetland Sheepdog"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Shiba Inu` }),
        value: "Shiba Inu"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Shih Tzu` }),
        value: "Shih Tzu"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Siberian Huskie`
        }),
        value: "Siberian Huskie"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Silky Terrier`
        }),
        value: "Silky Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Skye Terrier` }),
        value: "Skye Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Sloughi` }),
        value: "Sloughi"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Smooth Fox Terrier`
        }),
        value: "Smooth Fox Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Soft Coated Wheaten Terrier`
        }),
        value: "Soft Coated Wheaten Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Spanish Water Dog`
        }),
        value: "Spanish Water Dog"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Spinoni Italiani`
        }),
        value: "Spinoni Italiani"
      },
      {
        label: intl.formatMessage({ defaultMessage: `St. Bernard` }),
        value: "St. Bernard"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Staffordshire Bull Terrier`
        }),
        value: "Staffordshire Bull Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Standard Schnauzer`
        }),
        value: "Standard Schnauzer"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Sussex Spaniel`
        }),
        value: "Sussex Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Swedish Vallhund`
        }),
        value: "Swedish Vallhund"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Tibetan Mastiff`
        }),
        value: "Tibetan Mastiff"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Tibetan Spaniel`
        }),
        value: "Tibetan Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Tibetan Terrier`
        }),
        value: "Tibetan Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Toy Fox Terrier`
        }),
        value: "Toy Fox Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Treeing Walker Coonhound`
        }),
        value: "Treeing Walker Coonhound"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Vizsla` }),
        value: "Vizsla"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Weimaraner` }),
        value: "Weimaraner"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Welsh Springer Spaniel`
        }),
        value: "Welsh Springer Spaniel"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Welsh Terrier`
        }),
        value: "Welsh Terrier"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `West Highland White Terrier`
        }),
        value: "West Highland White Terrier"
      },
      {
        label: intl.formatMessage({ defaultMessage: `Whippet` }),
        value: "Whippet"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Wirehaired Pointing Griffon`
        }),
        value: "Wirehaired Pointing Griffon"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Wirehaired Vizsla`
        }),
        value: "Wirehaired Vizsla"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Xoloitzcuintli`
        }),
        value: "Xoloitzcuintli"
      },
      {
        label: intl.formatMessage({
          defaultMessage: `Yorkshire Terrier`
        }),
        value: "Yorkshire Terrier"
      }
    ],
    [intl]
  );

  return items;
};
