import { startCase } from "lodash";

import { ReviewItem } from "@components/archetypes/Review/types";
import {
  RemainderStrategy,
  TerminationDistribution
} from "@hooks/useTrustEnums";
import {
  SubTrust,
  VestingSchedule,
  Beneficiary
} from "@pages/LastWill/types";
import {
  DistribType,
  VestingType,
  Restrictions,
  DistributionType,
  PartnerType
} from "@api/__generated__/globalTypes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { ROUTES } from "@setup/consts/routes";

import { TRUST_GIFTS_ROUTES } from "../../consts";

export const getAssetBeneficiaryInfo = (
  beneficiaries: Beneficiary[],
  backupBeneficiaries: Beneficiary[]
) => [
  ...beneficiaries.map(
    (beneficiary) =>
      `${beneficiary.percentageValue}% to ${
        beneficiary.name ?? beneficiary?.charity?.name
      }`
  ),
  ...backupBeneficiaries.map(
    (beneficiary) =>
      `${beneficiary.percentageValue}% to ${
        beneficiary.name ?? beneficiary?.charity?.name
      }`
  )
];

export const getRemainderStrategyText = (
  everythingToPartner: boolean | undefined,
  isJointTrust: boolean | undefined,
  partnerType: string,
  strategy: string | undefined,
  distribType: string | undefined,
  copyTrustSetup: boolean | undefined
) => {
  const description = [];

  if (everythingToPartner) {
    description.push(
      `✅ If my ${partnerType} survives me, everything will go to my ${partnerType}${
        isJointTrust ? ` in the Survivor Trust` : ``
      }.`
    );
    description.push(`If my ${partnerType} does not survive me:`);
  }

  if (strategy === RemainderStrategy.PartnerOnly) {
    description.push(
      `✅  My ${partnerType} is the primary beneficiary of my Trust Remainder.`
    );
  }

  if (strategy === RemainderStrategy.ChildrenOnly) {
    description.push(
      "✅  My children are the primary beneficiaries of my Trust Remainder."
    );
  }

  if (
    strategy === RemainderStrategy.ChildrenOnly &&
    (distribType === DistribType.SEPARATE_CHILDREN ||
      DistribType.POOLED_CHILDREN)
  ) {
    description.push(
      `✅  Gifts to my children will be made in ${
        distribType === DistribType.POOLED_CHILDREN
          ? "a Pooled Children's Trust"
          : "separate children's trusts as described below"
      }.`
    );
  }

  if (
    strategy === RemainderStrategy.ChildrenOnly &&
    distribType === DistribType.SEPARATE_CHILDREN &&
    copyTrustSetup
  ) {
    description.push(
      "✅  We'll set up the children's trusts with the same distribution standards, vesting, and backup contingencies."
    );
  }

  if (strategy === RemainderStrategy.PartnerAndChildren) {
    description.push(
      `✅  My ${partnerType} and children are the primary beneficiaries of my Trust Remainder.`
    );
  }

  if (
    strategy === RemainderStrategy.PartnerAndChildren &&
    distribType === DistribType.POOLED_FAMILY
  ) {
    description.push(
      "✅  My Trust Remainder gift will be made in a Pooled Family Trust"
    );
  }

  if (strategy === RemainderStrategy.NameIndividually) {
    description.push(
      `✅  I am gifting my Trust Remainder to the below individuals and/or organizations`
    );
  }

  return description;
};

const getDistribTypeText = (
  name: string | undefined,
  distribType: string | undefined
) => {
  if (distribType === DistribType.SEPARATE_INDIVIDUAL) {
    return `subject to the ${name ?? "terms of the"} Trust`;
  }

  if (distribType === DistribType.POOLED_CHILDREN) {
    return `subject to the terms of the Pooled Children's Trust`;
  }

  if (distribType === DistribType.POOLED_FAMILY) {
    return `subject to the terms of the Family Trust`;
  }

  if (distribType === DistribType.SEPARATE_CHILDREN) {
    return `subject to the terms of the Separate Children's Trust`;
  }

  return "outright";
};

const getVestingSchedule = (
  vestingSchedule: VestingSchedule[] | undefined
) =>
  vestingSchedule && vestingSchedule.length > 0
    ? [...vestingSchedule]
        .sort((a, b) => a.age - b.age)
        .map(
          (interval) =>
            `${interval.percentageValue}% at age ${interval.age}`
        )
        .join(", ")
    : "";

const getVestingTypeText = (
  beneficiaryName: string | undefined,
  trustType: string,
  vestingType: VestingType | undefined,
  vestingSchedule: VestingSchedule[] | undefined,
  partnerType: PartnerType | undefined
) => {
  if (
    trustType === DistribType.POOLED_FAMILY &&
    vestingSchedule &&
    vestingSchedule.length > 0 &&
    partnerType
  ) {
    return `The Pooled Family Trust will terminate upon the later to occur of (i) my ${partnerType.toLowerCase()}'s death and (ii) my youngest child reaching the age of ${
      vestingSchedule[0].age ? vestingSchedule[0].age : "majority"
    }.`;
  }
  if (
    trustType === DistribType.POOLED_CHILDREN &&
    vestingSchedule &&
    vestingSchedule.length > 0
  ) {
    return `The Pooled Children's Trust will terminate when my youngest child reaches the age of ${
      vestingSchedule[0].age ? vestingSchedule[0].age : "majority"
    }.`;
  }

  if (vestingType === VestingType.ON_DEATH && beneficiaryName) {
    return `The trust will be set up as a lifetime trust and continue for so long as ${beneficiaryName} is alive and the trust's funds remain.`;
  }

  if (vestingType === VestingType.AGE_BASED) {
    return `The trust will vest on the following schedule: ${getVestingSchedule(
      vestingSchedule
    )}`;
  }

  return "";
};

const getSubTrustFeaturesText = (
  beneficiaryName: string | undefined,
  subTrust: SubTrust,
  partnerType?: PartnerType | undefined
) => {
  const { trustType, restrictions, vestingType, vestingSchedule } =
    subTrust;

  return [
    restrictions === Restrictions.LIMITED_RESTRICTIONS
      ? `This trust is a free access trust (low restrictions on distributions).`
      : `Distributions under the trust are subject to standard restrictions.`,
    getVestingTypeText(
      beneficiaryName,
      trustType,
      vestingType,
      vestingSchedule,
      partnerType
    )
  ];
};

const getBackupDistribText = (
  name: string | undefined,
  backups: Beneficiary[] | undefined,
  backupDistribution: string
) => {
  if (
    backupDistribution === DistributionType.CO_PRIMARY_BENEFICIARIES
  ) {
    return `If ${
      name ?? "this beneficiary"
    } predeceases me, this share will be given to the other co-primary beneficiaries of this gift`;
  }

  if (backups && backupDistribution === DistributionType.CHARITY) {
    return `If ${
      name ?? "this beneficiary"
    } predeceases me, this share will be given to ${backups
      .map((backup) => startCase(backup.charity?.name?.toLowerCase()))
      .join(", ")}`;
  }

  if (
    backups &&
    backupDistribution === DistributionType.BACKUP_BENEFICIARIES
  ) {
    return `If ${
      name ?? "this beneficiary"
    } predeceases me, this share will be given to the following beneficiaries: ${backups
      .map((backup) => `${backup.percentageValue}% to ${backup.name}`)
      .join(", ")}`;
  }

  // Default : DistributionType.DESCENDANTS
  return `If ${
    name ?? "this beneficiary"
  } predeceases me, this share will be distributed to ${
    name ? `${name}'s` : "their"
  } descendants`;
};

export const getIndividualRemainderGifts = (
  beneficiaries: Beneficiary[],
  everythingToPartner: boolean | undefined,
  isJointTrust = false,
  isPartner = false
): ReviewItem[] =>
  beneficiaries.map((beneficiary) => {
    const {
      name,
      charity,
      percentageValue,
      distribType,
      subTrust,
      backupBeneficiaries,
      backupDistribution
    } = beneficiary;
    const giftDetails = [];

    if (name) {
      giftDetails.push([
        `${percentageValue}% to ${
          name ?? charity?.name
        }, to be distributed ${getDistribTypeText(
          name,
          distribType
        )}.`
      ]);
    }
    if (
      distribType &&
      distribType !== DistribType.OUTRIGHT &&
      subTrust &&
      name
    ) {
      giftDetails.push(getSubTrustFeaturesText(name, subTrust));
    }
    if (name && backupDistribution) {
      giftDetails.push([
        `${getBackupDistribText(
          name,
          backupBeneficiaries,
          backupDistribution
        )}.`
      ]);
    }

    return {
      title: `${
        everythingToPartner && isJointTrust ? "Contingent " : ""
      }Remainder Gift to ${name}:`,
      href: getLinkFullUrl([
        ROUTES.Trust,
        TRUST_SECTION_ROUTES.Gifts,
        isPartner
          ? TRUST_GIFTS_ROUTES.PartnerRemainderAllocate
          : TRUST_GIFTS_ROUTES.RemainderAllocate
      ]),
      items: giftDetails
    };
  });

const getPooledTrustTerminationText = (subTrust: SubTrust) => {
  const { terminationDistribution } = subTrust;

  return `Upon termination of the Pooled Trust, any remaining trust funds will be distributed to the surviving trust beneficiaries ${
    terminationDistribution ===
    TerminationDistribution.SeparateChildren
      ? "equally in separate trusts"
      : "in equal shares outright and free of trust"
  }.`;
};

export const getPooledTrustGift = (
  partnerType: PartnerType,
  beneficiaries: Beneficiary[]
): ReviewItem => {
  const {
    name,
    subTrust,
  } = beneficiaries[0];

  return {
    title: `The ${subTrust?.name ?? "Pooled Trust"}:`,
    href: getLinkFullUrl([
      ROUTES.Trust,
      TRUST_SECTION_ROUTES.Gifts,
      TRUST_GIFTS_ROUTES.RemainderPooledOverview
    ]),
    items: subTrust
      ? [
          getSubTrustFeaturesText(name, subTrust, partnerType),
          [getPooledTrustTerminationText(subTrust)]
        ]
      : undefined
  };
};
