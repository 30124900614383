import { useMemo } from "react";

import { PlanOption } from "@api/__generated__/globalTypes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

export const usePolicyHolderOptions = (
  isQuestionnaire = false
): AutocompleteBaseOption[] => {
  // Pick up last values from Questionnaire
  const { values } = usePlanForm<LastWillPlanValues>();
  // Pick up completed plan values
  const { trust, lastWill } = useMemberPlansState();

  const options = useMemo<AutocompleteBaseOption[]>(() => {
    if (isQuestionnaire) {
      const {
        users: [primaryDrafter, partner],
        planOption: quizPlanOption
      } = values;

      const qIsCouplePlan =
        quizPlanOption === PlanOption.COUPLE ||
        quizPlanOption === PlanOption.JOINT;

      const qPrimaryName = `${primaryDrafter.firstName} ${primaryDrafter.lastName}`;
      const qPartnerName = partner
        ? `${partner?.firstName} ${partner?.lastName}`
        : undefined;

      return [
        {
          label: qPrimaryName ?? "",
          value: qPrimaryName ?? "",
          key: qPrimaryName ?? ""
        },
        {
          ...(qIsCouplePlan && partner
            ? {
                label: qPartnerName ?? "",
                value: qPartnerName ?? "",
                key: qPartnerName ?? ""
              }
            : {})
        }
      ].filter((item): item is AutocompleteBaseOption =>
        Boolean(item.label)
      );
    }

    const pPrimaryName = trust
      ? trust?.plan?.primaryDrafter?.fullName
      : lastWill?.plan?.primaryDrafter?.fullName;
    const pPartnerName = trust
      ? trust?.plan?.partner?.fullName
      : lastWill?.plan?.partner?.fullName;

    const pisCouplePlan = trust
      ? trust?.plan?.planOption === PlanOption.COUPLE ||
        trust?.plan?.planOption === PlanOption.JOINT
      : lastWill?.plan?.planOption === PlanOption.COUPLE;

    return [
      {
        label: pPrimaryName ?? "",
        value: pPrimaryName ?? "",
        key: pPrimaryName ?? ""
      },
      {
        ...(pisCouplePlan
          ? {
              label: pPartnerName ?? "",
              value: pPartnerName ?? "",
              key: pPartnerName ?? ""
            }
          : {})
      }
    ].filter((item): item is AutocompleteBaseOption =>
      Boolean(item.label)
    );
  }, [isQuestionnaire]);

  return options;
};
