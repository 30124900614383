import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";
import { usePlanForm } from "@providers/planForm/context";
import { PetPlanValues } from "@pages/PetPlan/types";

const InterstitialStep: React.FC = () => {
  const { value: firstName } = useFormField("users.0.firstName");

  const { values } = usePlanForm<PetPlanValues>();
  const {
    pets: [firstPet, secondPet, ...pets]
  } = values;

  if (!secondPet) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `Well done, ${firstName}!`,
          subText: `This next section will capture information about ${firstPet.name}'s health information.`
        }}
      />
    );
  }

  if (secondPet && pets.length === 0) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `Well done, ${firstName}!`,
          subText: `This next section will capture information about ${firstPet.name}'s and ${secondPet.name}'s health care information.`
        }}
      />
    );
  }

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Well done, ${firstName}!`,
        subText: `This next section will capture information about your pets' health care information.`
      }}
    />
  );
};

export default InterstitialStep;
