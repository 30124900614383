import React from "react";
import { Box, Typography } from "@mui/material";

import { CSVUploadResultsProps } from "./types";
import { DoneIcon } from "./styles";
import ReviewCard from "@components/ReviewCard";

const UploadComplete: React.FC<CSVUploadResultsProps> = ({
  importedAssets,
  notImportedAssets
}) => {
  const numImportedAssets = importedAssets?.length;
  const numNotImportedAssets = notImportedAssets?.length;

  const importedAssetNames = importedAssets?.map((asset) => [asset.name ?? "Unnamed Asset"]);
  const notImportedAssetNames = notImportedAssets?.map((asset) => [asset.name ?? "Unnamed Asset"]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography align="center">
        <DoneIcon />
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.25rem">
        {importedAssets && importedAssets?.length > 0 && (
          <ReviewCard title={`${numImportedAssets} Assets Created`} items={importedAssetNames}/>
        )}
        {notImportedAssets && notImportedAssets.length > 0 && (
          <ReviewCard
            title={`${numNotImportedAssets} Record Failed to Create`}
            items={notImportedAssetNames}
          />
        )}
      </Box>
    </Box>
  );
};

export default UploadComplete;
