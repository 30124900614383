import { FormControl, Stack } from "@mui/material";
import { memo } from "react";

import FormSelect from "@components/FormFields/FormSelect";
import { useRelationshipItems } from "@hooks/useRelationshipItems";
import FormPhoneInput from "@components/FormFields/FormPhoneInput";
import FormAddressAutocompleteNoCounty from "@components/FormFields/FormAddressAutocompleteNoCounty";
import PersonAutocompleteField from "@components/FormFields/PersonAutocompleteField";
import usePersonId from "@hooks/usePersonId";

import { PersonDetailsFormProps } from "./types";

const PersonDetailsForm: React.FC<PersonDetailsFormProps> = ({
  name,
  personRole,
  hideRelationshipField,
  isAddressWarningDisplayed,
  onProceed,
  onCancel
}) => {
  const relationshipItems = useRelationshipItems();
  const { randomizePersonId } = usePersonId(name);

  return (
    <Stack spacing={4}>
      <PersonAutocompleteField
        {...{ name, personRole }}
        personNameKeyPath="name"
      />
      {!hideRelationshipField && (
        <FormSelect
          name={`${name}.relationship`}
          onSelect={randomizePersonId}
          label={
            personRole
              ? `${personRole}'s Relationship to You`
              : "Relationship to You"
          }
          items={relationshipItems}
        />
      )}
      <FormPhoneInput
        name={`${name}.phone`}
        label={
          personRole ? `${personRole}'s Phone Number` : "Phone Number"
        }
      />
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <FormAddressAutocompleteNoCounty
          name={`${name}.address`}
          modalProps={{
            isOpen: isAddressWarningDisplayed,
            onClose: onCancel,
            onProceed
          }}
        />
      </FormControl>
    </Stack>
  );
};

export default memo(PersonDetailsForm);
