/* eslint-disable no-case-declarations */
import { ImageFragment } from "@api/allNavigation/__generated__/ImageFragment";
import { LinkFragment } from "@api/allNavigation/__generated__/LinkFragment";
import { ImageData } from "@components/Header/partials/Logo/types";

export const tryGetProtocol = (url: string): string | undefined => {
  try {
    const urlObj = new URL(url);

    return urlObj.protocol;
  } catch {
    return undefined;
  }
};

export const isExternalAppLink = (url: string): boolean => {
  const externalAppRegex = /^(sms:)|(tel:)|(mailto:).*$/;

  return externalAppRegex.test(url);
};

export const getLinkData = (
  link: LinkFragment | undefined | null
): { href: string; isExternal: boolean } => {
  if (!link) {
    return {
      href: "/",
      isExternal: false
    };
  }

  const { type, url, resource, media } = link;

  switch (type) {
    case "external": {
      const href = url || "/";
      const protocol = tryGetProtocol(href);

      return {
        href,
        isExternal: !!protocol
      };
    }
    case "internal":
      const { slug } = resource || {};
      const { current } = slug || {};

      return {
        href: current || "/",
        isExternal: false
      };
    case "media":
      const { asset } = media || {};
      const { url: mediaUrl } = asset || {};

      return {
        href: mediaUrl || "/",
        isExternal: true
      };
    default:
      return {
        href: "/",
        isExternal: false
      };
  }
};

export const getImageData = (
  image: ImageFragment | null | undefined
): ImageData => {
  const { asset, alt } = image || {};
  const { url, metadata, source } = asset || {};
  const { dimensions } = metadata || {};
  const { width, height } = dimensions || {};
  const svgContent = source?._key || undefined;
  const reverseRatio =
    width && height ? (height / width) * 100 : undefined;

  return {
    src: url || undefined,
    alt: alt || "",
    height: height || undefined,
    width: width || undefined,
    ...(reverseRatio ? { reverseRatio } : {}),
    ...(svgContent ? { svgContent } : {})
  };
};

export const enumValueToString = (text: string): string => {
  const textWithoutUnderscore = text.split("_").join(" ");

  return (
    textWithoutUnderscore.charAt(0).toUpperCase() +
    textWithoutUnderscore.slice(1).toLowerCase()
  );
};

export const numberWithCommas = (
  number: string | number | undefined | null
) => {
  if (number === undefined || number === null) {
    return "";
  }
  const stringifiedNumber = number.toString();

  return stringifiedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
