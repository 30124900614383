import { useMemo } from "react";
import { useIntl } from "react-intl";
import groupBy from "lodash/groupBy";
import { camelCase, capitalize, lowerCase } from "lodash";
import {
  AccountBalance,
  AutoAwesome,
  BusinessCenter,
  Diamond,
  House,
  VolunteerActivism
} from "@mui/icons-material";

import { ReviewItem } from "@components/archetypes/Review/types";
import { numberWithCommas } from "@utils/misc";
import { LAST_WILL_GIFTS_ROUTES } from "@pages/LastWill/sections/Gifts/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { TRUST_ASSETS_ROUTES } from "@pages/Trust/sections/Assets/consts";
import { getBeneficiaryInfo } from "@utils/getBeneficiaryInfo";
import {
  PartnerType,
  AssetType,
  PlanOption
} from "@api/__generated__/globalTypes";
import { ROUTES } from "@setup/consts/routes";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import {
  Asset,
  Asset_beneficiaries as Beneficiary
} from "@api/plans/__generated__/Asset";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_GIFTS_ROUTES } from "@pages/Trust/sections/Gifts/consts";

import getHumanizedPersonalPropertyTypeAPI from "../hooks/getHumanizedPersonalPropertyTypeAPI";
import { UseAssetReviewProps } from "../types";
import { getLinkFullUrl } from "../utils";
import isAssetOutsideLastWill from "../Tabs/AssetsTab/utils/isAssetOutsideLastWill";
import { useTrustRemainder } from "../hooks/useTrustRemainder";
import { useLastWillRemainder } from "../hooks/useLastWillRemainder";

export const usePartnerGiftsReview = ({
  excludeAssets = [],
  showExcludedPersons
}: UseAssetReviewProps): ReviewItem[] => {
  const { formatMessage } = useIntl();
  const { trust, lastWill } = useMemberPlansState();

  const isJointTrust =
    trust && trust?.plan.planOption === PlanOption.JOINT;

  const partnerType = trust
    ? trust.plan.partnerType ?? PartnerType.SINGLE
    : lastWill?.plan.partnerType ?? PartnerType.SINGLE;

  const primaryDrafter = trust
    ? trust.plan.primaryDrafter
    : lastWill?.plan.primaryDrafter;

  const lowerPartnerType = partnerType.toLowerCase();
  const partnerLastWill = trust
    ? trust.partnerLastWill
    : lastWill?.partnerLastWill;

  const personalPropertyBalanceDistribution =
    partnerLastWill?.personalPropertyBalanceDistribution;
  const includeNoContest = partnerLastWill?.includeNoContest;

  const canEdit = !!trust?.canEdit || !!lastWill?.canEdit;
  const children = partnerLastWill?.childs
    ?.filter((child) => child !== null)
    .map((child) => child?.user);

  const assets = useMemo(
    () =>
      groupBy(
        partnerLastWill?.assets
          ?.filter((asset): asset is Asset => Boolean(asset))
          ?.filter((asset) =>
            trust
              ? !!asset.beneficiaries?.length && asset.isGift
              : !!asset.beneficiaries?.length &&
                asset.isGift &&
                !isAssetOutsideLastWill(asset)
          )
          ?.map((asset) => ({
            ...asset,
            type: camelCase(asset?.type?.toLowerCase())
          })),
        (b) => b?.type
      ),
    [trust, partnerLastWill?.assets]
  );

  const everythingGoToPartner =
    partnerLastWill?.percentGaveToPartner === 100;

  const everythingGoToPartnerInfo = useMemo<ReviewItem | undefined>(
    () =>
      partnerType && partnerType !== PartnerType.SINGLE
        ? {
            title: formatMessage(
              {
                defaultMessage: `Distributions to Your {partnerType}:`
              },
              {
                partnerType: capitalize(partnerType)
              }
            ),
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Gifts,
                  TRUST_GIFTS_ROUTES.PartnerEverythingToPartner
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerDistAllToPartner
                ]),
            items: [
              [
                everythingGoToPartner
                  ? formatMessage(
                      {
                        defaultMessage: `If {partner} survives you, everything will go to {partner}.`
                      },
                      {
                        partner:
                          primaryDrafter?.firstName ||
                          `your ${lowerCase(partnerType)}`
                      }
                    )
                  : formatMessage(
                      {
                        defaultMessage: `{partner} will be the beneficiary only for those gifts which you've designated {partner} to receive below.`
                      },
                      {
                        partner:
                          primaryDrafter?.firstName ||
                          `Your ${lowerCase(partnerType)}`
                      }
                    )
              ]
            ]
          }
        : undefined,
    [
      everythingGoToPartner,
      trust,
      partnerType,
      formatMessage,
      primaryDrafter?.firstName
    ]
  );

  const personalPropertyBalanceInfo = useMemo<ReviewItem | undefined>(
    () =>
      personalPropertyBalanceDistribution
        ? {
            title: formatMessage({
              defaultMessage: "Personal Property Balance:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerPersonalPropertyBalance
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerPersonalPropertyBalance
                ]),
            items: [
              [
                getHumanizedPersonalPropertyTypeAPI(
                  personalPropertyBalanceDistribution,
                  lowerPartnerType,
                  assets?.personalPropertyBalance
                )
              ].filter(Boolean)
            ]
          }
        : undefined,
    [
      assets?.personalPropertyBalance,
      canEdit,
      trust,
      lowerPartnerType,
      personalPropertyBalanceDistribution,
      formatMessage
    ]
  );

  const cashGiftInfo = useMemo<ReviewItem | undefined>(
    () =>
      !excludeAssets.includes(AssetType.CASH) && assets?.cash?.length
        ? {
            title: formatMessage({
              defaultMessage: "Cash"
            }),
            icon: <VolunteerActivism />,
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerListCash
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerListCash
                ]),
            items: assets?.cash.map((asset) =>
              [
                ...(asset?.beneficiaries ?? []).map(
                  (beneficiary) =>
                    `$${numberWithCommas(
                      beneficiary?.dollarValue
                    )} to ${
                      beneficiary?.institution?.name ??
                      beneficiary?.user?.fullName
                    }`
                )
              ].filter(Boolean)
            )
          }
        : undefined,
    [assets?.cash, trust, canEdit, excludeAssets, formatMessage]
  );

  const hasSpecificGifts =
    (!excludeAssets.includes(AssetType.PERSONAL_PROPERTY_BALANCE) &&
      assets?.personalPropertyBalance) ||
    (!excludeAssets.includes(AssetType.REAL_ESTATE) &&
      assets?.realEstate?.length) ||
    (!excludeAssets.includes(AssetType.FINANCIAL_ACCOUNT) &&
      assets?.financialAccount?.length) ||
    (!excludeAssets.includes(AssetType.BUSINESS_INTEREST) &&
      assets?.businessInterest?.length) ||
    (!excludeAssets.includes(AssetType.OTHER) &&
      assets?.otherAssets?.length);

  const personalPropertyInfo = useMemo<ReviewItem | undefined>(
    () =>
      !excludeAssets.includes(AssetType.PERSONAL_PROPERTY) &&
      assets?.personalProperty?.length
        ? {
            title: formatMessage({
              defaultMessage: "Personal Property"
            }),
            icon: <Diamond />,
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerAddAssets
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerAddSpecificGifts
                ]),
            items: assets?.personalProperty?.map((asset) =>
              [
                `${asset.name}`,
                trust
                  ? getBeneficiaryInfo(
                      asset?.beneficiaries,
                      asset.isFunded,
                      true // showIsFunded
                    )
                  : getBeneficiaryInfo(asset?.beneficiaries)
              ].filter(Boolean)
            )
          }
        : undefined,
    [
      trust,
      assets?.personalProperty,
      canEdit,
      excludeAssets,
      formatMessage
    ]
  );

  const realEstateInfo = useMemo<ReviewItem | undefined>(
    () =>
      !excludeAssets.includes(AssetType.REAL_ESTATE) &&
      assets?.realEstate?.length
        ? {
            title: formatMessage({
              defaultMessage: "Real Estate"
            }),
            icon: <House />,
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerAddAssets
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerAddSpecificGifts
                ]),
            items: assets?.realEstate?.map((asset) =>
              [
                asset.name
                  ? asset.name
                  : [
                      asset?.address?.address1,
                      asset?.address?.city,
                      asset?.address?.state?.code,
                      asset?.address?.country?.name
                    ]
                      .filter(Boolean)
                      .join(", "),
                trust
                  ? getBeneficiaryInfo(
                      asset?.beneficiaries,
                      asset.isFunded,
                      true // showIsFunded
                    )
                  : getBeneficiaryInfo(asset?.beneficiaries)
              ].filter(Boolean)
            )
          }
        : undefined,
    [trust, assets?.realEstate, canEdit, excludeAssets, formatMessage]
  );

  const financialAccountInfo = useMemo<ReviewItem | undefined>(
    () =>
      !excludeAssets.includes(AssetType.FINANCIAL_ACCOUNT) &&
      assets?.financialAccount?.length
        ? {
            title: formatMessage({
              defaultMessage: "Financial Account"
            }),
            icon: <AccountBalance />,
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerAddAssets
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerAddSpecificGifts
                ]),
            items: assets?.financialAccount.map((asset) =>
              [
                `${
                  asset.subtype
                    ? `${asset.subtype} > ${asset.name}`
                    : asset.name
                }`,
                trust
                  ? getBeneficiaryInfo(
                      asset?.beneficiaries,
                      asset.isFunded,
                      true // showIsFunded
                    )
                  : getBeneficiaryInfo(asset?.beneficiaries)
              ].filter(Boolean)
            )
          }
        : undefined,
    [
      trust,
      assets?.financialAccount,
      canEdit,
      excludeAssets,
      formatMessage
    ]
  );

  const businessInterestsInfo = useMemo<ReviewItem | undefined>(
    () =>
      !excludeAssets.includes(AssetType.BUSINESS_INTEREST) &&
      assets?.businessInterest?.length
        ? {
            title: formatMessage({
              defaultMessage: "Business Interest"
            }),
            icon: <BusinessCenter />,
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerAddAssets
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerAddSpecificGifts
                ]),
            items: assets?.businessInterest.map((asset) =>
              [
                `${asset?.name}${
                  asset.address?.state?.name
                    ? `, registered in ${asset.address?.state.name}`
                    : ""
                }`,
                trust
                  ? getBeneficiaryInfo(
                      asset?.beneficiaries,
                      asset.isFunded,
                      true // showIsFunded
                    )
                  : getBeneficiaryInfo(asset?.beneficiaries)
              ].filter(Boolean)
            )
          }
        : undefined,
    [
      trust,
      assets?.businessInterest,
      canEdit,
      excludeAssets,
      formatMessage
    ]
  );

  const otherGiftInfo = useMemo<ReviewItem | undefined>(
    () =>
      !excludeAssets.includes(AssetType.OTHER) &&
      assets?.otherAssets?.length
        ? {
            title: formatMessage({
              defaultMessage: "Other Assets"
            }),
            icon: <AutoAwesome />,
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerAddAssets
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerAddSpecificGifts
                ]),
            items: assets?.otherAssets?.map((asset) =>
              [
                asset?.name ?? "",
                asset?.subtype ?? "",
                trust
                  ? getBeneficiaryInfo(
                      asset?.beneficiaries,
                      asset.isFunded,
                      true // showIsFunded
                    )
                  : getBeneficiaryInfo(asset?.beneficiaries)
              ].filter(Boolean)
            )
          }
        : undefined,
    [
      trust,
      assets?.otherAssets,
      canEdit,
      excludeAssets,
      formatMessage
    ]
  );

  // Roll Up for all the specific gifts
  const specificGiftsInfo = useMemo<ReviewItem | undefined>(
    () =>
      hasSpecificGifts
        ? {
            title: formatMessage(
              {
                defaultMessage: `{prefix}Specific Gifts:`
              },
              {
                prefix: everythingGoToPartner ? "Contingent " : ""
              }
            ),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerAddAssets
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerSpecificGifts
                ]),
            items: [
              ...[
                {
                  title: "",
                  href: "",
                  items: [
                    [
                      formatMessage({
                        defaultMessage:
                          "You are making the following specific gifts:"
                      })
                    ]
                  ]
                },
                personalPropertyInfo,
                personalPropertyBalanceInfo,
                realEstateInfo,
                financialAccountInfo,
                businessInterestsInfo,
                otherGiftInfo,
                cashGiftInfo
              ].filter((item): item is ReviewItem => Boolean(item))
            ]
          }
        : {
            title: formatMessage({
              defaultMessage: "Specific Gifts:"
            }),
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Assets,
                  TRUST_ASSETS_ROUTES.PartnerAddAssets
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerSpecificGifts
                ]),
            items: [
              [
                formatMessage({
                  defaultMessage: `You are not making any specific gifts.`
                })
              ]
            ]
          },
    [
      formatMessage,
      canEdit,
      trust,
      hasSpecificGifts,
      everythingGoToPartner,
      personalPropertyInfo,
      realEstateInfo,
      financialAccountInfo,
      businessInterestsInfo,
      otherGiftInfo,
      personalPropertyBalanceInfo,
      cashGiftInfo
    ]
  );

  // ResiduaryGifts Info Starts Here
  const estateBeneficiaries =
    assets?.estateBalance && assets?.estateBalance[0].beneficiaries
      ? (assets?.estateBalance[0].beneficiaries.filter(
          (beneficiary) => beneficiary !== null
        ) as Beneficiary[])
      : undefined;

  const trustRemainderItems = useTrustRemainder(
    isJointTrust ? trust?.primaryDrafterTrust : trust?.partnerTrust,
    estateBeneficiaries,
    children,
    primaryDrafter,
    partnerType,
    everythingGoToPartner,
    true // isPartner
  );

  const lastWillRemainderItems = useLastWillRemainder(
    estateBeneficiaries,
    primaryDrafter,
    partnerType
  );

  const residuaryGiftsInfo = useMemo<ReviewItem | undefined>(
    () =>
      !excludeAssets.includes(AssetType.ESTATE_BALANCE) &&
      assets?.estateBalance &&
      assets?.estateBalance[0].beneficiaries
        ? {
            title: trust
              ? formatMessage({
                  defaultMessage: "Trust Remainder:"
                })
              : formatMessage({
                  defaultMessage: "Estate Balance:"
                }),
            canEdit,
            description: formatMessage({
              defaultMessage: `(this is what's left over after all specific gifts; typically the largest part of your estate)`
            }),
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Gifts,
                  TRUST_GIFTS_ROUTES.PartnerTransitionStart
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Gifts,
                  LAST_WILL_GIFTS_ROUTES.PartnerResidueOverview
                ]),
            items: trust
              ? trustRemainderItems
              : lastWillRemainderItems
          }
        : undefined,
    [
      trust,
      trustRemainderItems,
      lastWillRemainderItems,
      assets?.estateBalance,
      canEdit,
      excludeAssets,
      formatMessage
    ]
  );

  const excludedPersonsInfo = useMemo<ReviewItem | undefined>(() => {
    if (!showExcludedPersons) {
      return undefined;
    }

    if (partnerLastWill?.excludedPersons?.length) {
      return {
        title: formatMessage({
          defaultMessage: "Individuals Excluded From Your Will:"
        }),
        canEdit,
        href: trust
          ? getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Gifts,
              TRUST_GIFTS_ROUTES.PartnerDesignateExcludedPersons
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Gifts,
              LAST_WILL_GIFTS_ROUTES.PartnerDesignateExcludedPersons
            ]),
        items: partnerLastWill?.excludedPersons?.map((person) =>
          [`${person?.excludedPerson?.fullName}`].filter(Boolean)
        )
      };
    }

    return {
      title: formatMessage({
        defaultMessage: "Individuals Excluded From Your Will:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Gifts,
            TRUST_GIFTS_ROUTES.PartnerDesignateExcludedPersons
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Gifts,
            LAST_WILL_GIFTS_ROUTES.PartnerExcludePerson
          ]),
      items: [
        [
          formatMessage({
            defaultMessage: `You are not explicitly excluding individuals from inheriting under your will.`
          })
        ]
      ]
    };
  }, [
    formatMessage,
    canEdit,
    trust,
    partnerLastWill?.excludedPersons,
    showExcludedPersons
  ]);

  const noContestInfo = useMemo<ReviewItem | undefined>(() => {
    if (!includeNoContest) {
      return undefined;
    }

    return {
      title: formatMessage({
        defaultMessage: "No Contest Provision:"
      }),
      canEdit,
      href: trust
        ? getLinkFullUrl([
            ROUTES.Trust,
            TRUST_SECTION_ROUTES.Gifts,
            TRUST_GIFTS_ROUTES.PartnerIncludeNoContest
          ])
        : getLinkFullUrl([
            ROUTES.LastWill,
            LAST_WILL_SECTION_ROUTES.Gifts,
            LAST_WILL_GIFTS_ROUTES.PartnerIncludeNoContest
          ]),
      items: includeNoContest
        ? [
            [
              "You included a no contest provision in your estate plan."
            ]
          ]
        : [
            [
              "You are not including a no contest provision in your estate plan."
            ]
          ]
    };
  }, [formatMessage, canEdit, includeNoContest, trust]);

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        everythingGoToPartnerInfo,
        residuaryGiftsInfo,
        specificGiftsInfo,
        excludedPersonsInfo,
        noContestInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      everythingGoToPartnerInfo,
      specificGiftsInfo,
      residuaryGiftsInfo,
      excludedPersonsInfo,
      noContestInfo
    ]
  );

  return items;
};
