import { useCallback } from "react";

import { InviteMemberInput } from "@api/__generated__/globalTypes";
import { useFormWithYup } from "@hooks/useFormWithYup";
import { useInviteMember } from "@api/memberships";
import { useEmailSentState } from "@providers/emailSentState/context";

import { UseSharePlanFormProps } from "../components/ShareTab/types";

import { useInviteMemberSchema } from "./useInviteMemberSchema";

export const useSharePlanForm = ({
  defaultValues = {},
  planId,
  onCompleted: onEmailSent
}: UseSharePlanFormProps) => {
  const validationSchema = useInviteMemberSchema();
  const methods = useFormWithYup<Omit<InviteMemberInput, "planId">>(
    validationSchema,
    {
      defaultValues
    }
  );
  const { onCompleted } = useEmailSentState();
  const [invite, { loading, error, reset }] = useInviteMember({
    onCompleted: () => {
      onCompleted();
      onEmailSent?.();
      reset();
      methods.reset();
    }
  });

  const onSubmit = useCallback(
    (input: Omit<InviteMemberInput, "planId">) => {
      if (!planId) return;

      invite({
        variables: {
          input: {
            ...input,
            planId
          }
        }
      });
    },
    [invite, planId]
  );

  return {
    methods,
    onSubmit,
    loading: loading || methods.formState.isSubmitting,
    error,
    reset
  };
};
