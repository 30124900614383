import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { usePlanForm } from "@providers/planForm/context";
import { useFormField } from "@components/FormFields/hooks";
import {
  ExcludedPerson,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { PartnerType } from "@api/__generated__/globalTypes";
import { RelationshipStatus } from "@setup/enums";

import { usePrimaryDrafterGiftsReviewTab } from "./Reviews/usePrimaryDrafterGiftsReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const { value: relationshipStatus } =
    useFormField<RelationshipStatus>("users.0.relationshipStatus");
  const { value: partnerType } =
    useFormField<PartnerType>("partnerType");

  const { value: excludePersons } = useFormField<ExcludedPerson[]>(
    "users.0.gifts.excludePersons"
  );

  const items = usePrimaryDrafterGiftsReviewTab({
    values,
    relationshipStatus,
    partnerType,
    excludePersons
  });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Let's confirm that we got this right.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
