import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";

const PartnerRestingPlaceStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `After ${partnerFirstName} passes, what would ${partnerFirstName} like as a final resting place?`,
        subText: `I request that my body be...`
      }}
      name="users.1.finalArrangements.bodyRestingPlace"
      nullable
      items={[
        {
          label: "cremated",
          value: "cremated"
        },
        {
          label: "donated to science",
          value: "donated to science"
        },
        {
          label: "buried",
          value: "buried"
        },
        {
          label: "Skip this question",
          value: null
        }
      ]}
    />
  );
};

export default PartnerRestingPlaceStep;
