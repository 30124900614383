import { PartnerType } from "@api/__generated__/globalTypes";
import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";
import {
  useMaritalGiftProcessInstructions,
  usePartnerGiftProcessInstructions,
  useRegisteredGiftProcessInstructions,
  useSingleGiftProcessInstructions
} from "@hooks/processOverviewInstruction";
import { RelationshipStatus } from "@setup/enums";

const GiftProcessOverviewStep: React.FC = () => {
  const { value: partnerType } =
    useFormField<PartnerType>(`partnerType`);
  const { value: relationshipStatus } =
    useFormField<RelationshipStatus>(`relationshipStatus`);

  const singleGiftProcessInstruction =
    useSingleGiftProcessInstructions();
  const maritalGiftProcessInstructions =
    useMaritalGiftProcessInstructions();
  const registeredGiftProcessInstructions =
    useRegisteredGiftProcessInstructions();
  const partnerGiftProcessInstructions =
    usePartnerGiftProcessInstructions();

  if (partnerType === PartnerType.SINGLE) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: "We'll plan your distribution in two parts:",
          isWide: true,
          orderedListItems: singleGiftProcessInstruction
        }}
      />
    );
  }
  if (partnerType === PartnerType.SPOUSE) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `We'll plan your distribution in three parts:`,
          orderedListItems: maritalGiftProcessInstructions
        }}
      />
    );
  }

  if (
    relationshipStatus ===
    RelationshipStatus.REGISTERED_DOMESTIC_PARTNERSHIP
  ) {
    return (
      <InterstitialArchetype
        stepLayout={{
          title: `We'll plan your distribution in three parts:`,
          orderedListItems: registeredGiftProcessInstructions
        }}
      />
    );
  }

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `We'll plan your distribution in three parts:`,
        orderedListItems: partnerGiftProcessInstructions
      }}
    />
  );
};

export default GiftProcessOverviewStep;
