import { useMemo } from "react";
import { get } from "lodash";

import round from "@utils/round";

import {
  AllocationSumOptions,
  UseAllocationSumReturnType
} from "./types";

const useAllocationSum = (
  data: unknown[],
  options: AllocationSumOptions
): UseAllocationSumReturnType => {
  const { path, max = 100 } = options;
  const allocated = useMemo(
    () =>
      (data || []).reduce<number>((prev, current) => {
        const value = get(current, path, 0);
        const parsedValue = parseFloat(value);

        if (Number.isNaN(parsedValue)) {
          return prev;
        }

        return round(prev + parsedValue);
      }, 0),
    [data, path]
  );

  return {
    allocated: allocated ?? 0,
    success: max === allocated,
    exceeded: max < allocated,
    left: round(max - allocated) ?? max,
    max
  };
};

export default useAllocationSum;
