import { useEffect } from "react";
import { useIntl } from "react-intl";
import { QuestionAnswer } from "@mui/icons-material";

import TutorialLayout from "@pages/Tutorial/partials/TutorialLayout";
import usePlanTypeContext from "@providers/planType/hooks";
import useArrowAnimationContext from "@providers/arrowAnimation/hooks";
import { getPlanRoute } from "@utils/getPlanRoute";

const TeamPage: React.FC = () => {
  const intl = useIntl();
  const { planTypeEnum } = usePlanTypeContext();
  const { setShowRightArrow } = useArrowAnimationContext();

  useEffect(() => {
    setShowRightArrow(true);

    return () => setShowRightArrow(false);
  }, [setShowRightArrow]);

  return (
    <TutorialLayout
      heading={intl.formatMessage({
        defaultMessage: "Our team is standing by"
      })}
      description={intl.formatMessage({
        defaultMessage:
          "We know that creating an estate plan is not an every day thing. If you are unsure about a question or would like some extra help, engage with our support team. Even if we’re not in-office, we always respond as soon as possible."
      })}
      title={intl.formatMessage(
        {
          defaultMessage:
            "tutorial ({currentStep} of {allStepsCount})"
        },
        {
          allStepsCount: 5,
          currentStep: 5
        }
      )}
      progress={100}
      nextButtonProps={{
        href: getPlanRoute(planTypeEnum),
        label: intl.formatMessage({
          defaultMessage: "Continue"
        })
      }}
    >
      <QuestionAnswer
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: "auto",
          fontSize: "2.875rem"
        }}
      />
    </TutorialLayout>
  );
};

export default TeamPage;
