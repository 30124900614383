import { Box, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ROUTES } from "@setup/consts/routes";

import { HEALTHCARE_ROUTES } from "../consts";
import { HEALTHCARE_MEDICAL_POA_ROUTES } from "../sections/medicalPOA/consts";
import { useDocumentsPreview } from "@components/archetypes/DocumentPreview/hooks";
import { useState } from "react";

const HealthcareCompleteStep: React.FC = () => {
  const { loading, handleGoToCheckout, paymentLoading } =
    useDocumentsPreview();
  const [isLoading, setIsLoading] = useState(false);
  
  return (
  <Box maxWidth="29.375rem" width="100%" m="0 auto">
    <Typography
      variant="h1"
      letterSpacing="0"
      textAlign="center"
      lineHeight="1.5"
      mb={{ xs: 2.5 }}
    >
      <HealthAndSafetyIcon />
      <br />
      You&apos;re not creating an Advance Health Care Directive.
    </Typography>
    <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
      <FormattedMessage defaultMessage="If you change your mind later, you can always come back to finish this questionnaire." />
    </Typography>
    <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
      <FormattedMessage defaultMessage="Please click the button below to return home to the member dashboard." />
    </Typography>
    <Box display="flex" flexDirection="row" gap="0.625rem">
      <Button
        variant="outlined"
        color="inherit"
        sx={{
          width: "100%",
          minWidth: "auto"
        }}
        href={getLinkFullUrl([
          ROUTES.Healthcare,
          HEALTHCARE_ROUTES.MedicalPOA,
          HEALTHCARE_MEDICAL_POA_ROUTES.CreateAHCD
        ])}
      >
        <FormattedMessage defaultMessage="Go Back" />
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{
          width: "100%",
          minWidth: "auto"
        }}
        disabled={paymentLoading}
        onClick={handleGoToCheckout}
      >
        <FormattedMessage defaultMessage="Return Home" />
      </Button>
    </Box>
  </Box>
);
}

export default HealthcareCompleteStep;
