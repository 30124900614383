import { useContext } from "react";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import ReviewArchetype from "@components/archetypes/Review";
import { LastWillPlanValues } from "@pages/LastWill/types";
import {
  PlanFormContext,
  usePlanForm
} from "@providers/planForm/context";

import { usePrimaryDrafterFinalArrangementsReviewTab } from "./Reviews/usePrimaryDrafterFinalArrangementsReviewTab";

const ReviewStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const { type } = useContext(PlanFormContext);
  const items = usePrimaryDrafterFinalArrangementsReviewTab({
    values,
    type
  });

  return (
    <ReviewArchetype
      {...{ items }}
      stepLayout={{
        title: `Let's confirm that we got this right.`,
        muiIconHeader: <AssignmentTurnedInIcon />
      }}
    />
  );
};

export default ReviewStep;
