import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import differenceInYears from "date-fns/differenceInYears";

import { usePetTypes } from "@hooks/usePetTypes";
import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import FormInput from "@components/FormFields/FormInput";
import FormSelect from "@components/FormFields/FormSelect";
import FormMobileDatePicker from "@components/FormFields/FormMobileDatePicker";
import { Pet } from "@pages/PetPlan/types";
import { ArrayFormValues } from "@components/archetypes/ArrayForm/types";
import { useFormField } from "@components/FormFields/hooks";

type PetFormProps = {
  prefix: string;
  index: number;
};

const PetForm: React.FC<PetFormProps> = ({ prefix, index }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormInput
        name={`${prefix}.name`}
        label={formatMessage(
          { defaultMessage: "Pet #{number}’s Name" },
          { number: index + 1 }
        )}
      />
      <FormSelect
        name={`${prefix}.type`}
        items={usePetTypes()}
        label={formatMessage(
          { defaultMessage: "Type of Pet" },
          { number: index + 1 }
        )}
      />
      <FormMobileDatePicker
        name={`${prefix}.birthdate`}
        label={formatMessage(
          { defaultMessage: "Pet #{number}’s Birthday" },
          { number: index + 1 }
        )}
      />
    </>
  );
};

const PetDetailsStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { setValue: setNumberOfPets } = useFormField("numberOfPets");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const singleFormSchema: yup.SchemaOf<
    Omit<
      Pet,
      | "weight"
      | "breed"
      | "characteristics"
      | "feedingInstructions"
      | "careInstructions"
      | "medicalInstructions"
      | "guardians"
      | "backupGuardians"
      | "veterinarian"
    >
  > = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(),
        type: yup.string().required(),
        birthdate: yup
          .date()
          .typeError(
            formatMessage({
              defaultMessage:
                "Birthdates should be no later than one year from today."
            })
          )
          .test(
            "birthdate",
            formatMessage({
              defaultMessage:
                "Birthdates should be no later than one year from today."
            }),
            (value) =>
              value
                ? differenceInYears(new Date(value), new Date()) <= 1
                : false
          )
          .required()
      }),
    [formatMessage]
  );

  const onSubmit = useCallback(
    ({ pets }: ArrayFormValues<Pet | unknown>) => {
      setNumberOfPets(pets.length);
    },
    [setNumberOfPets]
  );

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: `What are your pets' names and birthdays?`
      }}
      addBtnLabel="+ Add Another Pet"
      singleItemSchema={singleFormSchema}
      SingleItem={PetForm}
      name="pets"
      {...{ onSubmit }}
    />
  );
};

export default PetDetailsStep;
