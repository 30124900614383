import { PermissionLevel } from "@api/__generated__/globalTypes";

const getPermissionHumanizedTitle = (permission: PermissionLevel) => {
  if (permission === PermissionLevel.OWNER) return "Full Access";
  if (permission === PermissionLevel.EDIT) return "Edit Access";
  if (permission === PermissionLevel.VIEW) return "View Docs & Data";

  return "View Role Only";
};

export default getPermissionHumanizedTitle;
