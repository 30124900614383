import { useCallback } from "react";

import PersonDetailsArrayArchetype from "@components/archetypes/PersonDetails/Array/Wrapper";
import { PersonValues } from "@components/archetypes/PersonDetails/types";
import { useFormField } from "@components/FormFields/hooks";
import { ArrayFormValues } from "@components/archetypes/ArrayForm/types";

const DesignateMedicalAccessStep: React.FC = () => {
  const { value: sameMedicalAccess } = useFormField(
    "users.0.healthcare.copyMedicalRecordAccess"
  );
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );
  const { setValue: copyMedicalRecordAccess } = useFormField(
    "users.1.healthcare.medicalRecordAccess.people"
  );

  const onSubmit = useCallback(
    ({ MedicalAccessPeople }: ArrayFormValues<PersonValues>) => {
      if (sameMedicalAccess) {
        copyMedicalRecordAccess(MedicalAccessPeople);
      }
    },
    [copyMedicalRecordAccess, sameMedicalAccess]
  );

  if (sameMedicalAccess) {
    return (
      <PersonDetailsArrayArchetype
        name="users.0.healthcare.medicalRecordAccess.people"
        stepLayout={{
          title: `Who would you and ${partnerFirstName} like to have access to your medical records?`
        }}
        personRole="Authorized Person"
        {...{ onSubmit }}
      />
    );
  }

  return (
    <PersonDetailsArrayArchetype
      name="users.0.healthcare.medicalRecordAccess.people"
      stepLayout={{
        title: `Who would you like to have access to your medical records?`
      }}
      personRole="Authorized Person"
    />
  );
};

export default DesignateMedicalAccessStep;
