import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominateAntiGuardianStep: React.FC = () => {
  const { setValue: setnominateProhibitedGuardiansValue } =
    useFormField("prohibitedGuardians");

  const onSubmit = useCallback(
    ({ nominateProhibitedGuardians }: SingleSelectFormValues) => {
      if (!parseBoolean(nominateProhibitedGuardians)) {
        setnominateProhibitedGuardiansValue([]);
      }
    },
    [setnominateProhibitedGuardiansValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title:
          "Is there anyone closely related to you who would NOT be a good fit for guardian?",
        subText:
          "Learn more about warning against improper guardians.",
        moreInfoModalProps: {
          togglerLabel: "Learn more",
          content: (
            <p>
              The Court has final approval on who serves as Guardian,
              but your Nomination of Guardians and any warnings
              against improper Guardians will weigh heavily in its
              decision.
              <br />
              <br />
              If your child has another natural parent or close
              relative who you would not feel comfortable assuming the
              role of Guardian, you should detail your concern about
              why such person(s) would be an improper choice.
            </p>
          ),
          title: "Improper Guardians",
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="nominateProhibitedGuardians"
    />
  );
};

export default NominateAntiGuardianStep;
