import { useIntl } from "react-intl";

import {
  HeadersWrapper,
  NameColumnHeader,
  SharesColumnHeader
} from "./styles";

const ResidueAllocateStep: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <HeadersWrapper>
      <SharesColumnHeader textAlign="center">%</SharesColumnHeader>
      <NameColumnHeader>
        {formatMessage({ defaultMessage: "Beneficiary Name" })}
      </NameColumnHeader>
    </HeadersWrapper>
  );
};

export default ResidueAllocateStep;
