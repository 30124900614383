import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { LAST_WILL_MANAGEMENT_ROUTES } from "@pages/LastWill/sections/Management/consts";
import { numberWithCommas } from "@utils/misc";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_MANAGEMENT_ROUTES } from "@pages/Trust/sections/Management/consts";
import { ROUTES } from "@setup/consts/routes";
import { getCompensationInfo } from "@pages/Trust/sections/Management/steps/Reviews/utils";
import { CompensationType } from "@hooks/useTrustEnums";
import { PlanOption } from "@api/__generated__/globalTypes";

import { getLinkFullUrl } from "../utils";
import checkSameReps from "../hooks/checkSameReps";
import getTrustName from "../hooks/getTrustName";

export const usePartnerManagementReview = () => {
  const { formatMessage } = useIntl();
  const { trust, lastWill } = useMemberPlansState();
  const { partnerLastWill } = lastWill || {};
  const { primaryDrafterTrust, partnerTrust } = trust || {};

  const canEdit = !!trust?.canEdit || !!lastWill?.canEdit;
  const primaryDrafter = trust
    ? trust.plan.primaryDrafter
    : lastWill?.plan.primaryDrafter;
  const partner = trust ? trust.plan.partner : lastWill?.plan.partner;

  const isJointTrust =
    trust && trust?.plan.planOption === PlanOption.JOINT;

  const representatives = trust
    ? trust.partnerLastWill?.representatives || []
    : partnerLastWill?.representatives || [];

  // Trust-Focused Items

  const {
    name: primaryTrustName,
    trustees: primaryTrustees,
    trusteesCompensationType: primaryTrusteesCompensationType,
    trusteesCompensationDollar: primaryTrusteesCompensationDollar,
    trusteesCompensationPercent: primaryTrusteesCompensationPercent
  } = primaryDrafterTrust ?? {};

  const {
    name: partnerTrustName,
    trustees: partnerTrustees,
    trusteesCompensationType,
    trusteesCompensationDollar,
    trusteesCompensationPercent
  } = partnerTrust ?? {};

  const trustees = isJointTrust
    ? primaryTrustees?.filter(
        (trustee) =>
          trustee?.trustee?.id !== partner.id &&
          trustee?.trustee?.id !== primaryDrafter.id
      ) || []
    : partnerTrustees?.filter(
        (trustee) => trustee?.trustee?.id !== partner.id
      ) || [];

  const trustName = primaryDrafterTrust
  ? getTrustName(
    isJointTrust ? primaryTrustName : partnerTrustName,
    primaryDrafter,
    partner,
    true, // isPartner
    isJointTrust
  )
  : "";

  const coTrustees = trustees.filter((trustee) => !trustee?.isBackup);
  const backupTrustees = trustees.filter(
    (trustee) => trustee?.isBackup
  );

  const nominateTrusteesAsReps = checkSameReps(
    representatives,
    trustees
  );

  const trustBackgroundInfo = useMemo<ReviewItem | undefined>(
    () =>
      trust
        ? {
            title: `Trust Overview:`,
            canEdit,
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerTrusteeStart
            ]),
            items: isJointTrust
              ? [
                  [`✅  We'll call your joint trust "${trustName}."`],
                  [
                    `✅  As the Trustmakers, only you and ${primaryDrafter.firstName} will have the power to change or revoke the trust.`
                  ],
                  [
                    `✅  You and ${primaryDrafter.firstName} are also Trustees authorized to manage the trust's affairs.`
                  ]
                ]
              : [
                  [`✅  We'll call your trust ${trustName}."`],
                  [
                    `✅  As the Trustmaker, only you will have the power to change or revoke the trust.`
                  ],
                  [
                    `✅  You'll also be a Trustee authorized to manage the trust's affairs.`
                  ]
                ]
          }
        : undefined,
    [
      trust,
      isJointTrust,
      trustName,
      canEdit,
      primaryDrafter
    ]
  );

  const coTrusteesInfo = useMemo<ReviewItem | undefined>(() => {
    if (!trust) {
      return undefined;
    }

    if (coTrustees.length > 0) {
      return {
        title: formatMessage({
          defaultMessage: `Co-Trustees:`
        }),
        canEdit,
        href: getLinkFullUrl([
          ROUTES.Trust,
          TRUST_SECTION_ROUTES.Management,
          TRUST_MANAGEMENT_ROUTES.PartnerDesignateCoTrustee
        ]),
        items: [
          coTrustees
            .map((person) => person?.trustee?.fullName)
            .filter(
              (item): item is string => typeof item === "string"
            )
        ]
      };
    }

    return {
      title: `Co-Trustees:`,
      href: getLinkFullUrl([
        ROUTES.Trust,
        TRUST_SECTION_ROUTES.Management,
        TRUST_MANAGEMENT_ROUTES.PartnerNominateCoTrustee
      ]),
      canEdit,
      items: [
        [
          "👀 You are not nominating any Co-Trustees.",
          "As long as you have capacity, only you will have authority to act as Trustee."
        ]
      ]
    };
  }, [trust, formatMessage, canEdit, coTrustees]);

  const backupTrusteesInfo = useMemo<ReviewItem | undefined>(() => {
    if (!trust) {
      return undefined;
    }

    if (backupTrustees.length > 0) {
      return {
        title: formatMessage({
          defaultMessage: `Successor Trustees:`
        }),
        canEdit,
        href: getLinkFullUrl([
          ROUTES.Trust,
          TRUST_SECTION_ROUTES.Management,
          TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupTrustee
        ]),
        description:
          coTrustees.length > 1
            ? "If your Co-Trustees are unavailable, your Successor Trustees will serve in the order named below."
            : "Your Successor Trustees will serve in the order named below.",
        items: [
          backupTrustees
            .map((backup) => backup?.trustee?.fullName)
            .filter(
              (item): item is string => typeof item === "string"
            )
        ]
      };
    }

    return {
      title: `Successor Trustees:`,
      canEdit,
      href: getLinkFullUrl([
        ROUTES.Trust,
        TRUST_SECTION_ROUTES.Management,
        TRUST_MANAGEMENT_ROUTES.PartnerNominateBackupTrustee
      ]),
      items: [["👀 You are not nominating any Successor Trustees."]]
    };
  }, [trust, backupTrustees, formatMessage, canEdit, coTrustees]);

  const trusteeCompensationInfo = useMemo<ReviewItem | undefined>(
    () =>
      trusteesCompensationType
        ? {
            title: formatMessage({
              defaultMessage: "Trustee Compensation:"
            }),
            canEdit,
            href: getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensation
            ]),
            isFullWidth: true,
            items: [
              [
                trusteesCompensationType.toString() ===
                  CompensationType.Reasonable ||
                trusteesCompensationType.toString() ===
                  CompensationType.None
                  ? trusteesCompensationType.toString()
                  : "",
                trusteesCompensationDollar
                  ? `Compensation of $${numberWithCommas(
                      trusteesCompensationDollar.toString()
                    )}, plus reimbursement of reasonable expenses`
                  : "",
                trusteesCompensationPercent
                  ? `Compensation up to ${trusteesCompensationPercent.toString()}% of my estate, plus reimbursement of reasonable expenses.`
                  : ""
              ].filter((item) => Boolean(item))
            ]
          }
        : undefined,
    [
      canEdit,
      trusteesCompensationType,
      trusteesCompensationDollar,
      trusteesCompensationPercent,
      formatMessage
    ]
  );

  // LastWill Focused Items
  const personalRepresentatives = representatives.filter(
    (representative) => !representative?.isBackup
  );
  const backupPersonalRepresentatives = representatives.filter(
    (representative) => representative?.isBackup
  );

  const representativesCompensationType = useMemo(
    () =>
      trust
        ? trust.partnerLastWill?.representativesCompensationType
        : partnerLastWill?.representativesCompensationType,
    [trust, partnerLastWill]
  );

  const representativesCompensationDollar = useMemo(
    () =>
      trust
        ? trust.partnerLastWill?.representativesCompensationDollar
        : partnerLastWill?.representativesCompensationDollar,
    [trust, partnerLastWill]
  );

  const representativesCompensationPercent = useMemo(
    () =>
      trust
        ? trust.partnerLastWill?.representativesCompensationPercent
        : partnerLastWill?.representativesCompensationPercent,
    [trust, partnerLastWill]
  );

  const representativesInfo = useMemo<ReviewItem | undefined>(() => {
    if (nominateTrusteesAsReps && trust) {
      return {
        title: formatMessage({
          defaultMessage: "Personal Representatives Information"
        }),
        canEdit,
        href: getLinkFullUrl([
          ROUTES.Trust,
          TRUST_SECTION_ROUTES.Management,
          TRUST_MANAGEMENT_ROUTES.PartnerDesignateTrusteeSameReps
        ]),
        items: [
          [
            "✅ Your Trustees will also serve as your Personal Representatives for your Pour Over Will."
          ],
          [
            "They'll receive the following compensation for serving as Personal Representatives:",
            ...getCompensationInfo(
              representativesCompensationType ?? undefined,
              representativesCompensationDollar ?? undefined,
              representativesCompensationPercent ?? undefined
            )
          ]
        ]
      };
    }

    if (personalRepresentatives.length > 0) {
      return {
        title: formatMessage({
          defaultMessage: `Primary Representative:`
        }),
        canEdit,
        href: trust
          ? getLinkFullUrl([
              ROUTES.Trust,
              TRUST_SECTION_ROUTES.Management,
              TRUST_MANAGEMENT_ROUTES.PartnerDesignateRepresentative
            ])
          : getLinkFullUrl([
              ROUTES.LastWill,
              LAST_WILL_SECTION_ROUTES.Management,
              LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerRepresentative
            ]),
        items: [
          [`${personalRepresentatives[0]?.representative?.fullName}`]
        ]
      };
    }

    return undefined;
  }, [
    formatMessage,
    canEdit,
    personalRepresentatives,
    trust,
    nominateTrusteesAsReps,
    representativesCompensationType,
    representativesCompensationPercent,
    representativesCompensationDollar
  ]);

  const backupRepresentativesInfo = useMemo<ReviewItem | undefined>(
    () =>
      !nominateTrusteesAsReps &&
      backupPersonalRepresentatives.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Backup Representative:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Management,
                  TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupRepresentative
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Management,
                  LAST_WILL_MANAGEMENT_ROUTES.DesignatePartnerBackupRepresentative
                ]),
            description:
              "(Backup Representatives will serve in the order named)",
            isFullWidth: true,
            items: backupPersonalRepresentatives
              .map((backupRep) => [
                backupRep?.representative?.fullName ?? ""
              ])
              .filter(Boolean)
          }
        : undefined,
    [
      nominateTrusteesAsReps,
      trust,
      backupPersonalRepresentatives,
      canEdit,
      formatMessage
    ]
  );

  const representativeCompensationInfo = useMemo<
    ReviewItem | undefined
  >(
    () =>
      !nominateTrusteesAsReps && personalRepresentatives?.length
        ? {
            title: formatMessage({
              defaultMessage: "Representative Compensation:"
            }),
            canEdit,
            href: trust
              ? getLinkFullUrl([
                  ROUTES.Trust,
                  TRUST_SECTION_ROUTES.Management,
                  TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation
                ])
              : getLinkFullUrl([
                  ROUTES.LastWill,
                  LAST_WILL_SECTION_ROUTES.Management,
                  LAST_WILL_MANAGEMENT_ROUTES.PartnerRepCompensation
                ]),
            isFullWidth: true,
            items: [
              [
                representativesCompensationType?.toString() ===
                  "Reasonable compensation and reimbursement of expenses" ||
                representativesCompensationType?.toString() ===
                  "No compensation. Reimbursement of reasonable expenses only"
                  ? representativesCompensationType?.toString()
                  : "",
                representativesCompensationDollar
                  ? `Compensation of $${numberWithCommas(
                      representativesCompensationDollar?.toString()
                    )}, plus reimbursement of reasonable expenses`
                  : "",
                representativesCompensationPercent
                  ? `Compensation up to ${representativesCompensationPercent?.toString()}% of my estate, plus reimbursement of reasonable expenses.`
                  : ""
              ].filter((item): item is string => Boolean(item))
            ]
          }
        : undefined,
    [
      trust,
      nominateTrusteesAsReps,
      personalRepresentatives?.length,
      formatMessage,
      canEdit,
      representativesCompensationType,
      representativesCompensationDollar,
      representativesCompensationPercent
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        trustBackgroundInfo,
        coTrusteesInfo,
        backupTrusteesInfo,
        trusteeCompensationInfo,
        representativesInfo,
        backupRepresentativesInfo,
        representativeCompensationInfo
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      trustBackgroundInfo,
      coTrusteesInfo,
      backupTrusteesInfo,
      trusteeCompensationInfo,
      representativeCompensationInfo,
      backupRepresentativesInfo,
      representativesInfo
    ]
  );

  return items;
};
