import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";

const GrantSameMedicalAccessStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setPartnerMedicalAccessValue } = useFormField(
    "users.1.healthcare.medicalRecordAccess.people"
  );

  const { value: primaryDrafterMedicalAccessPeople } = useFormField(
    "users.0.healthcare.medicalRecordAccess.people"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const sameMedicalAccess = get(
        data,
        "users.1.healthcare.copyMedicalRecordAccess"
      );

      if (sameMedicalAccess) {
        const people =
          primaryDrafterMedicalAccessPeople &&
          Array.isArray(primaryDrafterMedicalAccessPeople)
            ? primaryDrafterMedicalAccessPeople
            : [];

        setPartnerMedicalAccessValue(people);
      }
    },
    [primaryDrafterMedicalAccessPeople, setPartnerMedicalAccessValue]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Copy that. Will ${partnerFirstName}'s choices be the same as yours?`
      }}
      {...{ onSubmit }}
      name="users.1.healthcare.copyMedicalRecordAccess"
    />
  );
};

export default GrantSameMedicalAccessStep;
