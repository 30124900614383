import { Button, DialogActions, DialogContent } from "@mui/material";

import LinkBehavior from "@setup/theme/partials/Link";
import { isExternalAppLink, tryGetProtocol } from "@utils/misc";

import { ModalProps } from "./types";
import * as Styled from "./styles";

const Modal: React.FC<ModalProps> = ({
  title,
  content,
  onClose,
  actions,
  ...rest
}) => (
  <Styled.Modal {...{ onClose, ...rest }}>
    {title && (
      <Styled.ModalTitle variant="h3" textAlign="center">
        {title}
      </Styled.ModalTitle>
    )}
    <DialogContent>{content}</DialogContent>
    {actions?.length && (
      <DialogActions>
        {actions.map(({ label, ...actionRest }, index) => (
          <Button
            // eslint-disable-next-line react/no-unstable-nested-components
            LinkComponent={(props) => (
              <LinkBehavior
                {...props}
                {...(tryGetProtocol(props.href) ||
                isExternalAppLink(props.href)
                  ? { isExternal: true, target: "_blank" }
                  : {})}
              />
            )}
            {...actionRest}
            key={label}
            sx={index ? { mt: 2.5 } : undefined}
          >
            {label}
          </Button>
        ))}
      </DialogActions>
    )}
  </Styled.Modal>
);

export default Modal;
