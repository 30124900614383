import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import debounce from "lodash/debounce";

import { GET_CHARITIES } from "@api/charities/queries";
import { GetCharities } from "@api/charities/__generated__/GetCharities";

import { useFormField } from "../hooks";

import {
  FormCharitiesAutocompleteOption,
  UseCharitiesReturnType
} from "./types";
import {
  CHARITIES_TO_SHOW_COUNT,
  CHARITY_VALUE_MIN_LENGTH
} from "./consts";

export const useCharities = (
  name: string
): UseCharitiesReturnType => {
  const [value, setValue] = useState<string>("");
  const [open, setOpen] = useState(false);
  const { setValue: setFieldValue } = useFormField(name);
  const { setValue: setRHFValue } = useFormContext();
  const [getCharities, { loading, data }] =
    useLazyQuery<GetCharities>(GET_CHARITIES);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCharitiesLoad = useCallback(
    debounce((inputValue: string) => {
      getCharities({
        variables: {
          text: inputValue,
          take: CHARITIES_TO_SHOW_COUNT
        }
      });
    }, 500),
    [getCharities]
  );

  const items = data?.charity?.nodes?.map((item) => ({
    label: item?.name ?? "",
    value: item?.name ?? "",
    ein: item?.ein ?? "",
    address: `${item?.street}, ${item?.city}, ${item?.state} ${item?.zip}`
  }));

  const handleOpen = useCallback((): void => {
    if (value.length >= CHARITY_VALUE_MIN_LENGTH) {
      setOpen(true);
    }
  }, [value.length]);

  const handleInputChange = (newInputValue: string): void => {
    setValue(newInputValue);
    handleCharitiesLoad(newInputValue);
    setOpen(newInputValue.length >= CHARITY_VALUE_MIN_LENGTH);
  };

  const handleChange = useCallback(
    (
      fieldValue: FormCharitiesAutocompleteOption | string | null
    ): void => {
      if (typeof fieldValue !== "string") {
        setFieldValue({
          name: fieldValue?.label ?? "",
          ein: fieldValue?.ein ?? "",
          address: fieldValue?.address ?? ""
        });
        setRHFValue(
          name,
          {
            name: fieldValue?.label ?? "",
            ein: fieldValue?.ein ?? "",
            address: fieldValue?.address ?? ""
          },
          { shouldValidate: true }
        );
      }
    },
    [name, setFieldValue, setRHFValue]
  );

  return {
    items,
    loading,
    handleOpen,
    handleInputChange,
    handleChange,
    open,
    setOpen,
    value
  };
};
