import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ReviewItem } from "@components/archetypes/Review/types";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { LAST_WILL_FINAL_ARRANGEMENT_ROUTES } from "@pages/LastWill/sections/FinalArrangements/consts";
import { ROUTES } from "@setup/consts/routes";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanType } from "@api/__generated__/globalTypes";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { TRUST_FINAL_ARRANGEMENT_ROUTES } from "@pages/Trust/sections/FinalArrangements/consts";

export const usePartnerFinalArrangementsReviewTab = ({
  values,
  type
}: {
  values: LastWillPlanValues;
  type: PlanType;
}) => {
  const {
    users: [, partner]
  } = values;

  const {
    bodyRestingPlace: bodyRestingPlacePartner = "",
    ceremonyType: ceremonyTypePartner = [],
    specialRequest: specialRequestPartner = ""
  } = partner?.finalArrangements || {};

  const { formatMessage } = useIntl();

  const bodyRestingPlaceInfoPartner = useMemo<ReviewItem | undefined>(
    () =>
      bodyRestingPlacePartner
        ? {
            title: formatMessage({
              defaultMessage: "Final Resting Place:"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerRestingPlace
                  ]
            ),
            items: [
              [
                `I would like my body to be ${bodyRestingPlacePartner}.`
              ]
            ]
          }
        : undefined,
    [bodyRestingPlacePartner, formatMessage, type]
  );

  const ceremonyTypeInfoPartner = useMemo<ReviewItem | undefined>(
    () =>
      ceremonyTypePartner?.length > 0
        ? {
            title: formatMessage({
              defaultMessage: "Ceremony Type:"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
                  ]
            ),
            items: [ceremonyTypePartner?.map((kind) => kind)]
          }
        : undefined,
    [formatMessage, ceremonyTypePartner, type]
  );

  const specialRequestInfoPartner = useMemo<ReviewItem | undefined>(
    () =>
      specialRequestPartner
        ? {
            title: formatMessage({
              defaultMessage: "Additional Requests:"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerOtherRequests
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerOtherRequests
                  ]
            ),
            isFullWidth: true,
            items: [[specialRequestPartner]]
          }
        : undefined,
    [formatMessage, specialRequestPartner, type]
  );

  const noInstructionsDisclosure = useMemo<ReviewItem | undefined>(
    () =>
      !bodyRestingPlaceInfoPartner &&
      !ceremonyTypeInfoPartner &&
      !specialRequestInfoPartner
        ? {
            title: formatMessage({
              defaultMessage: "No Instructions Provided"
            }),
            href: getLinkFullUrl(
              type === PlanType.TRUST
                ? [
                    ROUTES.Trust,
                    TRUST_SECTION_ROUTES.FinalArrangements,
                    TRUST_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
                  ]
                : [
                    ROUTES.LastWill,
                    LAST_WILL_SECTION_ROUTES.FinalArrangements,
                    LAST_WILL_FINAL_ARRANGEMENT_ROUTES.PartnerCeremony
                  ]
            ),
            isFullWidth: true,
            items: [
              [
                "👀 You are not providing instructions for your Final Arrangements to your Personal Representative."
              ],
              [
                "This means that your Personal Representative will make these decisions on your behalf based on your Personal Representative's understanding of your values and preferences."
              ],
              [
                "If this is not your intent, please provide instructions."
              ]
            ]
          }
        : undefined,
    [
      formatMessage,
      bodyRestingPlaceInfoPartner,
      ceremonyTypeInfoPartner,
      specialRequestInfoPartner,
      type
    ]
  );

  const items = useMemo<ReviewItem[]>(
    () =>
      [
        bodyRestingPlaceInfoPartner,
        ceremonyTypeInfoPartner,
        specialRequestInfoPartner,
        noInstructionsDisclosure
      ].filter((item): item is ReviewItem => Boolean(item)),
    [
      bodyRestingPlaceInfoPartner,
      ceremonyTypeInfoPartner,
      specialRequestInfoPartner,
      noInstructionsDisclosure
    ]
  );

  return items;
};
