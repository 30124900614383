import { createContext, useContext } from "react";
import { noop } from "lodash";

import { DisableFormItems, DisableFormState } from "./type";

const DisableFormContext = createContext<DisableFormState>({
  items: {} as DisableFormItems,
  isDisabled: false,
  setDisableItems: noop
});

const useDisableForm = () => useContext(DisableFormContext);

export { DisableFormContext, useDisableForm };
