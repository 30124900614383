import { AssetType } from "@api/__generated__/globalTypes";

export const ASSETS_TYPE_ORDER = [
  AssetType.PERSONAL_PROPERTY,
  AssetType.REAL_ESTATE,
  AssetType.FINANCIAL_ACCOUNT,
  AssetType.BUSINESS_INTEREST,
  AssetType.OTHER,
  AssetType.PERSONAL_PROPERTY_BALANCE,
  AssetType.CASH,
  AssetType.ESTATE_BALANCE
];
