import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { useFormField } from "@components/FormFields/hooks";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";

const PartnerRepCompensationStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setRepCompensationDollar } = useFormField(
    "users.1.lastWill.representativesCompensationDollar"
  );
  const { setValue: setRepCompensationPercent } = useFormField(
    "users.1.lastWill.representativesCompensationPercent"
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const repCompensation = get(
        data,
        "users.1.lastWill.representativesCompensationType"
      );

      if (
        repCompensation !==
        "A specific percent of my estate and reimbursement of expenses"
      ) {
        setRepCompensationPercent(null);
      }
      if (
        repCompensation !==
        "A specific dollar amount and reimbursement of expenses"
      ) {
        setRepCompensationDollar(null);
      }
    },
    [setRepCompensationPercent, setRepCompensationDollar]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How would ${partnerFirstName} like to compensate ${partnerFirstName}'s Personal Representative?`,
        subText:
          "Learn more about Personal Representative compensation.",
        moreInfoModalProps: {
          title: "Personal Representative Compensation",
          content: (
            <div>
              <p>
                Serving as a Personal Representative can be a lot of
                work. State law recognizes this, and as a default,
                your Personal Representative is entitled to seek
                reasonable compensation for his or her services.
              </p>
              <p>
                Even if you’d like the default rule to apply,
                expressing this to be your intent in your will is
                important because doing so can help mitigate potential
                conflict among your beneficiaries.
              </p>
              <p>
                If you do not want to compensate your Personal
                Representative, you need to state that intent in your
                will. You should also speak with your Personal
                Representative and make sure that your Personal
                Representative is still willing to serve in that
                capacity without payment.
              </p>
            </div>
          ),
          closeLabel: "Got it"
        }
      }}
      name="users.1.lastWill.representativesCompensationType"
      items={[
        {
          label:
            "No compensation. Reimbursement of reasonable expenses only",
          value:
            "No compensation. Reimbursement of reasonable expenses only"
        },
        {
          label:
            "Reasonable compensation and reimbursement of expenses (most common)",
          value:
            "Reasonable compensation and reimbursement of expenses"
        },
        {
          label:
            "A specific dollar amount and reimbursement of expenses",
          value:
            "A specific dollar amount and reimbursement of expenses"
        },
        {
          label:
            "A specific percent of my estate and reimbursement of expenses",
          value:
            "A specific percent of my estate and reimbursement of expenses"
        }
      ]}
      {...{ onSubmit }}
    />
  );
};

export default PartnerRepCompensationStep;
