import { useIntl } from "react-intl";

import ArrayFormArchetype from "@components/archetypes/ArrayForm";
import { Beneficiary } from "@pages/LastWill/types";
import { useFormField } from "@components/FormFields/hooks";
import useAllocationSum from "@hooks/useAllocationSum";
import { usePlanForm } from "@providers/planForm/context";

import BeneficiaryForm from "./partials/BeneficiaryForm";
import AllocationMessage from "./partials/AllocationMessage";
import useBeneficiarySchema from "./partials/BeneficiaryForm/hooks";
import FormHeader from "./partials/FormHeader";

const ResidueAllocateStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();
  const singleItemSchema = useBeneficiarySchema();
  const { value } = useFormField<Beneficiary[]>(
    `users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`
  );
  const { success } = useAllocationSum(value, {
    path: "percentageValue"
  });

  return (
    <ArrayFormArchetype
      stepLayout={{
        title: formatMessage({
          defaultMessage: "Who should receive your residuary estate?"
        })
      }}
      addBtnLabel={formatMessage({
        defaultMessage: "Add another beneficiary"
      })}
      {...{ singleItemSchema }}
      customRemoveBtn
      SingleItem={BeneficiaryForm}
      name={`users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`}
      topContent={<FormHeader />}
      bottomContent={
        <AllocationMessage
          name={`users.0.assets.estateBalance.0.beneficiaries.${iteration}.backupBeneficiaries`}
        />
      }
      disabled={!success}
    />
  );
};

export default ResidueAllocateStep;
