import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";

import { Beneficiary } from "@pages/LastWill/types";

const useBeneficiarySchema = (): yup.SchemaOf<
  Omit<
    Beneficiary,
    | "id"
    | "__typename"
    | "backupDistribution"
    | "dollarValue"
    | "charity"
    | "distribType"
    | "backupBeneficiaries"
    | "subTrust"
  >
> => {
  const { formatMessage } = useIntl();

  const singleItemSchema: yup.SchemaOf<
    Omit<
      Beneficiary,
      | "id"
      | "__typename"
      | "backupDistribution"
      | "dollarValue"
      | "charity"
      | "distribType"
      | "backupBeneficiaries"
      | "subTrust"
    >
  > = useMemo(
    () =>
      yup.object().shape({
        percentageValue: yup
          .number()
          .required()
          .typeError(
            formatMessage({
              defaultMessage:
                "Please enter a number between 0 and 100."
            })
          ),
        name: yup.string().required(),
        dollarValue: yup.number().optional()
      }),
    [formatMessage]
  );

  return singleItemSchema;
};

export default useBeneficiarySchema;
