import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const TransitionPrimaryCompleteStep: React.FC = () => {
  const { value: firstName } = useFormField(`users.0.firstName`);

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Phew! No more questions for you, ${firstName}.`
      }}
    />
  );
};

export default TransitionPrimaryCompleteStep;
