import InterstitialArchetype from "@components/archetypes/Interstitial";

const InterstitialStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Now that we're best friends, let's learn a bit about your pets.`,
      isWide: true
    }}
  />
);

export default InterstitialStep;
