import { Link as ReactLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Typography, TypographyProps } from "@mui/material";

export const Title = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    lineHeight: 1.6,
    color: theme.palette.grey[700],
    fontWeight: 500
  })
);

export const Link = styled(ReactLink)(({ theme }) => ({
  textDecoration: "none",
  lineHeight: 1.8,
  color: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: "1rem",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      color: theme.palette.text.secondary
    }
  }
}));
