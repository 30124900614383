import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const PainReliefExceptionStep: React.FC = () => (
  <SingleSelectArchetype
    nullable
    name="users.0.healthcare.preferences.reliefFromPainAddNote"
    stepLayout={{
      title: `Do you wish to identify any exceptions for which you would not want to receive maximum pain relief?`,
      subText: `Click 'Learn more' for examples of when some individuals identify exceptions.`,
      moreInfoModalProps: {
        title:
          "Identifying Exceptions to Receiving Maximum Pain Relief",
        togglerLabel: "Learn more.",
        content: (
          <p>
            If you like, you may choose to specify exceptions for
            which you don&apos;t want to receive maximum pain relief.
            <br />
            <br />
            Here are a few hypothetical scenarios for which some
            people identify exceptions:
            <br />
            <br />
            <strong>Desire to maintain communication.</strong> You are
            willing to tolerate higher levels of pain if doing so
            allows you to stay alert and able to communicate with your
            family or healthcare providers.
            <br />
            <br />
            <strong>Opioid sensitivity or aversion.</strong> You do
            not wish to receive certain opioids (e.g., OxyContin).
            <br />
            <br />
            <strong>
              Allergic reactions or other side effects.
            </strong>{" "}
            If you have a known allergy or severe adverse reactions to
            certain pain relief medications, you may want to specify
            those here.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
    items={[
      {
        label: "Yes",
        value: true
      },
      {
        label: "No",
        value: false
      }
    ]}
  />
);

export default PainReliefExceptionStep;
