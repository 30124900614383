import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./fragments";

export const GET_ME_QUERY = gql`
  ${USER_FRAGMENT}
  query GetMeQuery {
    me {
      ...User
    }
  }
`;
