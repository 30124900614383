import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/Trust/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    stepLayout={{
      title:
        "Great! Next, we'll identify the assets with which you want to fund the trust and make any specific gifts."
    }}
  />
);

export default SectionsPreviewStep;
