import { gql } from "@apollo/client";

import { FILE_FRAGMENT } from "./fragments";

export const CREATE_FILE_UPLOAD_URL = gql`
  mutation CreateFileUploadUrl(
    $planId: Int!
    $fileName: String!
    $contentLength: Int!
  ) {
    createFileUploadUrl(
      planId: $planId
      fileName: $fileName
      contentLength: $contentLength
    ) {
      url
      path
    }
  }
`;

export const CREATE_FILE = gql`
  ${FILE_FRAGMENT}
  mutation CreateFile($planId: Int!, $path: String!, $name: String!) {
    createFile(planId: $planId, path: $path, name: $name) {
      ...File
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: Int!) {
    deleteFile(id: $id)
  }
`;

export const UPDATE_FILE = gql`
  ${FILE_FRAGMENT}
  mutation UpdateFile($id: Int!, $input: UpdateFileInput!) {
    updateFile(id: $id, input: $input) {
      ...File
    }
  }
`;

export const REGENERATE_PLAN_DOCUMENTS = gql`
  mutation RegeneratePlanDocuments($planId: Int!) {
    regeneratePlanDocuments(planId: $planId)
  }
`;
