import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { useFormField } from "@components/FormFields/hooks";

const PartnerPainReliefExceptionListStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <SingleResponseArchetype
      name="users.1.healthcare.preferences.reliefFromPainCondition"
      stepLayout={{
        title: `Sure thing, ${partnerFirstName}.`,
        subText: `In your own words and in complete sentences, please state the conditions or situations in which you would not want treatment for alleviation of pain or discomfort.`
      }}
      label="Special Exceptions"
      fieldProps={{
        multiline: true,
        minRows: 5
      }}
    />
  );
};

export default PartnerPainReliefExceptionListStep;
