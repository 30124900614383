import { makeMutationHook } from "@utils/apollo-utils";

import {
  AI_GENERATE_CHAT_RESPONSE,
  AI_CREATE_THREAD
} from "./mutations";
import {
  AIGenerateChatResponse,
  AIGenerateChatResponseVariables
} from "./__generated__/AIGenerateChatResponse";
import {
  AICreateThread,
  AICreateThreadVariables
} from "./__generated__/AICreateThread";

export const useGenerateChatResponseMutation = makeMutationHook<
  AIGenerateChatResponse,
  AIGenerateChatResponseVariables
>(AI_GENERATE_CHAT_RESPONSE);

export const useCreateThreadMutation = makeMutationHook<
  AICreateThread,
  AICreateThreadVariables
>(AI_CREATE_THREAD);
