/* eslint-disable react/no-unescaped-entities */
import InterstitialArchetype from "@components/archetypes/Interstitial";

const PartnerRepresentativeOverviewStep: React.FC = () => (
  <InterstitialArchetype
    stepLayout={{
      title: `Awesome! Now, we're going to pick someone to serve as your Personal Representative.`,
      subText:
        "Your Personal Representative, also known as an Executor, is the individual or entity responsible for handling anything that may sit outside of your Trust at the time of your passing.",
      moreInfoModalProps: {
        title:
          "What is the Difference Between a Personal Representative and a Trustee?",
        togglerLabel: "Learn more.",
        content: (
          <p>
            When you create your Revocable Living Trust, you create
            not just the trust agreement but also a pour over will
            that works in tandem with the trust.
            <br />
            <br />
            Whereas your Trustee is responsible for managing all the
            trust&apos;s affairs, your Personal Representative is
            responsible for handling those duties within the pour over
            will. That may include:
            <ul>
              <li>
                Identifying, collecting, and distributing to the Trust
                any probate assets that weren&apos;t funded to the
                trust during your lifetime
              </li>
              <li>
                Ensuring that your minor children (if you have them)
                are placed with the appropriate child guardian you
                designate
              </li>
              <li>
                Organizing your funeral, cremation, or other final
                arrangements
              </li>
            </ul>
            Typically, these pour over will duties are fairly limited
            and Trustmakers often appoint the same people to act as
            Personal Representative as they do Trustee.
          </p>
        ),
        closeLabel: "Got it"
      }
    }}
  />
);

export default PartnerRepresentativeOverviewStep;
