/* eslint-disable react/no-unescaped-entities */
import PersonDetailsArchetype from "@components/archetypes/PersonDetails";
import { useFormField } from "@components/FormFields/hooks";
import { BlogLinks } from "@setup/consts/articleLinks";

const PartnerDesignateAgentStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  return (
    <PersonDetailsArchetype
      name="users.1.healthcare.agents.0"
      stepLayout={{
        title: `And who should serve as ${partnerFirstName}'s agent?`,
        subText: "See our tips for choosing a healthcare agent.",
        moreInfoModalProps: {
          title: "Tips for Choosing a Healthcare Agent",
          togglerLabel: "Learn more",
          content: (
            <p>
              Of all the decisions that you'll make in an advance
              health care directive, choosing who to select as your
              agent under a medical power of attorney is the most
              important.
              <br />
              <br />
              Choose a family member, friend, or other person who:
              <ul>
                <li>is at least 18 years old</li>
                <li>knows you well</li>
                <li>
                  you trust to do what is best for you and is willing
                  to carry out your wishes, even if he or she may not
                  agree with your wishes
                </li>
                <li>
                  would be comfortable talking with and questioning
                  your physicians and other health care providers;
                </li>
                <li>
                  would not be too upset to carry out your wishes if
                  you became very sick, and
                </li>
                <li>
                  can be there for you when you need it and is willing
                  to accept this important role
                </li>
              </ul>
              For a more comprehensive discussion of these
              characteristics, check out our blog post using the link
              below.
            </p>
          ),
          actions: [
            {
              label: "Go to Article",
              href: BlogLinks.howToChooseMedicalPOA
            }
          ],
          closeLabel: "Got it"
        }
      }}
      personRole="Agent"
    />
  );
};

export default PartnerDesignateAgentStep;
