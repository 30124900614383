import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { EditOutlined } from "@mui/icons-material";

import Error from "@components/Error";
import Modal from "@components/Modal";

import { useUpdateDocument } from "./hooks";
import UpdateDocumentForm from "./UpdateDocumentForm";
import { UpdateDocumentNameModalProps } from "./types";

const UpdateDocumentNameModal: React.FC<
  UpdateDocumentNameModalProps
> = ({ id, name }) => {
  const intl = useIntl();
  const { isOpen, error, toggleOff, toggleOn } = useUpdateDocument({
    id
  });

  if (error) {
    return (
      <Box mt="1.5rem">
        <Error onAction={toggleOff} />
      </Box>
    );
  }

  return (
    <>
      <EditOutlined onClick={toggleOn} />
      <Modal
        fullWidth
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Update Document Name"
        })}
        content={<UpdateDocumentForm {...{ id, name }} />}
      />
    </>
  );
};

export default UpdateDocumentNameModal;
