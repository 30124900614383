import { PlanType } from "@api/__generated__/globalTypes";
import { ROUTES } from "@setup/consts/routes";

export const getPlanRoute = (planTypeEnum: PlanType): string => {
  switch (planTypeEnum) {
    case PlanType.PET_PROTECTION:
      return ROUTES.PetStart;
    default:
      return ROUTES.LastWillStart;
  }
};
