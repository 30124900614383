import React from "react";

import imageArrow from "@assets/images/image-arrow.png";

import { HeaderArrowProps } from "./types";
import * as Styled from "./styles";

const Arrow: React.FC<HeaderArrowProps> = ({ isReversed }) => (
  <Styled.ImageWrapper {...{ isReversed }}>
    <Styled.Img
      src={imageArrow}
      objectFit="contain"
      alt="pointer arrow"
      {...{ isReversed }}
    />
  </Styled.ImageWrapper>
);

export default Arrow;
