import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { PlanOption } from "@api/__generated__/globalTypes";

import PowersToGrantStep from "../../../PowerOfAttorney/steps/PowersToGrant";
import SectionsPreviewStep from "../../../PowerOfAttorney/steps/Preview";
import POAOverviewStep from "../../../PowerOfAttorney/steps/POAOverview";
import CreatePOAStep from "../../../PowerOfAttorney/steps/CreatePOA";
import DesignateAgentStep from "../../../PowerOfAttorney/steps/DesignateAgent";
import NominateBackupAgentStep from "../../../PowerOfAttorney/steps/NominateBackupAgent";
import DesignateBackupAgentStep from "../../../PowerOfAttorney/steps/DesignateBackupAgent";
import PowersStep from "../../../PowerOfAttorney/steps/Powers";
import PowersOptionalOverviewStep from "../../../PowerOfAttorney/steps/PowersOptionalPreview";
import PowersDigitalAssetsStep from "../../../PowerOfAttorney/steps/PowersDigitalAccess";
import PowersMakeGiftsStep from "../../../PowerOfAttorney/steps/PowersMakeGifts";
import PowersMakeGiftsPeopleStep from "../../../PowerOfAttorney/steps/PowersMakeGiftsPeople";
import PowersMakeGiftsPeopleListStep from "../../../PowerOfAttorney/steps/PowersMakeGiftsPeopleList";
import PowersMakeGiftsSelfStep from "../../../PowerOfAttorney/steps/PowersMakeGiftsSelf";
import PowersMakeGiftsLimitTypeStep from "../../../PowerOfAttorney/steps/PowersMakeGiftsLimitType";
import PowersRelatedTxStep from "../../../PowerOfAttorney/steps/PowersRelatedTx";
import PowersTransferToTrustStep from "../../../PowerOfAttorney/steps/PowersTransferToTrust";
import PowersDelegatePowersStep from "../../../PowerOfAttorney/steps/PowersDelegatePowers";
import PowersFiduciaryPowersStep from "../../../PowerOfAttorney/steps/PowersFiduciaryPowers";
import AgentCompensationStep from "../../../PowerOfAttorney/steps/AgentCompensation";
import AgentCompensationAmountStep from "../../../PowerOfAttorney/steps/AgentCompensationAmount";
import TransitionPrimaryCompleteStep from "../../../PowerOfAttorney/steps/TransitionPrimaryComplete";
import PartnerCreatePOAStep from "../../../PowerOfAttorney/steps/PartnerCreatePOA";
import PartnerDesignateAgentStep from "../../../PowerOfAttorney/steps/PartnerDesignateAgent";
import PartnerNominateBackupAgentStep from "../../../PowerOfAttorney/steps/PartnerNominateBackupAgent";
import PartnerDesignateBackupAgentStep from "../../../PowerOfAttorney/steps/PartnerDesignateBackupAgent";
import PartnerPowersStep from "../../../PowerOfAttorney/steps/PartnerPowers";
import PartnerPowersToGrantStep from "../../../PowerOfAttorney/steps/PartnerPowersToGrant";
import PartnerPowersOptionalOverviewStep from "../../../PowerOfAttorney/steps/PartnerPowersOptionalPreview";
import PartnerPowersDigitalAssetsStep from "../../../PowerOfAttorney/steps/PartnerPowersDigitalAccess";
import PartnerPowersMakeGiftsStep from "../../../PowerOfAttorney/steps/PartnerPowersMakeGifts";
import PartnerPowersMakeGiftsSelfStep from "../../../PowerOfAttorney/steps/PartnerPowersMakeGiftsSelf";
import PartnerPowersMakeGiftsPeopleStep from "../../../PowerOfAttorney/steps/PartnerPowersMakeGiftsPeople";
import PartnerPowersMakeGiftsPeopleListStep from "../../../PowerOfAttorney/steps/PartnerPowersMakeGiftsPeopleList";
import PartnerPowersMakeGiftsLimitTypeStep from "../../../PowerOfAttorney/steps/PartnerPowersMakeGiftsLimitType";
import PartnerPowersMakeGiftsLimitAmountStep from "../../../PowerOfAttorney/steps/PartnerPowersMakeGiftsLimitAmount";
import PartnerPowersRelatedTxStep from "../../../PowerOfAttorney/steps/PartnerPowersRelatedTx";
import PartnerPowersTransferToTrustStep from "../../../PowerOfAttorney/steps/PartnerPowersTransferToTrust";
import PartnerPowersDelegatePowersStep from "../../../PowerOfAttorney/steps/PartnerPowersDelegatePowers";
import PartnerPowersFiduciaryPowersStep from "../../../PowerOfAttorney/steps/PartnerPowersFiduciaryPowers";
import PartnerAgentCompensationStep from "../../../PowerOfAttorney/steps/PartnerAgentCompensation";
import PartnerAgentCompensationAmountStep from "../../../PowerOfAttorney/steps/PartnerAgentCompensationAmount";
import ReviewStep from "../../../PowerOfAttorney/steps/Reviews/Review";
import ReviewTabsStep from "../../../PowerOfAttorney/steps/Reviews/ReviewTabs";
import PowersMakeGiftsLimitAmountStep from "../../../PowerOfAttorney/steps/PowersMakeGiftsLimitAmount";

import { LAST_WILL_POWER_OF_ATTORNEY_ROUTES } from "./consts";

const powerOfAttorneySteps: PlanFormStep[] = [
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.POAOverview
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.POAOverview,
    element: <POAOverviewStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.CreatePOA
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.CreatePOA,
    element: <CreatePOAStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.poa.include" }, true] },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateAgent,
        {
          if: [
            { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
            LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA,
            LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Review
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateAgent,
    element: <DesignateAgentStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.NominateBackupAgent,
    element: <NominateBackupAgentStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.poa.nominateBackupAgent" }, true]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Powers
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.DesignateBackupAgent,
    element: <DesignateBackupAgentStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Powers
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Powers,
    element: <PowersStep />,
    rules: {
      if: [
        { "==": [{ var: "users.0.poa.generalAuthority" }, true] },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersToGrant
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersToGrant,
    element: <PowersToGrantStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersOptionalPreview,
    element: <PowersOptionalOverviewStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersDigitalAccess
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersDigitalAccess,
    element: <PowersDigitalAssetsStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGifts
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGifts,
    element: <PowersMakeGiftsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.additionalPowers.makeGifts" },
            true
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeople,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeople,
    element: <PowersMakeGiftsPeopleStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.giftRecipients.type" },
            "certainPeople"
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeopleList,
        {
          if: [
            {
              "==": [
                { var: "users.0.poa.giftRecipients.type" },
                "Anyone"
              ]
            },
            LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsSelf,
            LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitType
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsPeopleList,
    element: <PowersMakeGiftsPeopleListStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsSelf
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsSelf,
    element: <PowersMakeGiftsSelfStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitType
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitType,
    element: <PowersMakeGiftsLimitTypeStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.giftRecipients.limitType" },
            "Limit up to a certain amount"
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitAmount,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersMakeGiftsLimitAmount,
    element: <PowersMakeGiftsLimitAmountStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersRelatedTx,
    element: <PowersRelatedTxStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersTransferToTrust
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersTransferToTrust,
    element: <PowersTransferToTrustStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersDelegatePowers
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersDelegatePowers,
    element: <PowersDelegatePowersStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersFiduciaryPowers
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PowersFiduciaryPowers,
    element: <PowersFiduciaryPowersStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.AgentCompensation
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.AgentCompensation,
    element: <AgentCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.poa.compensation" },
            "Specify a compensation amount and reimbursement of expenses"
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.AgentCompensationAmount,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.TransitionPrimaryComplete
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.AgentCompensationAmount,
    element: <AgentCompensationAmountStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.TransitionPrimaryComplete
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.TransitionPrimaryComplete,
    element: <TransitionPrimaryCompleteStep />,
    rules: {
      if: [
        { "==": [{ var: "planOption" }, PlanOption.COUPLE] },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Review
      ]
    }
  },
  // Partner POA Starts Here
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerCreatePOA,
    element: <PartnerCreatePOAStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.poa.include" }, true] },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateAgent,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.ReviewTabs
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateAgent,
    element: <PartnerDesignateAgentStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerNominateBackupAgent,
    element: <PartnerNominateBackupAgentStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.poa.nominateBackupAgent" }, true] },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowers
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerDesignateBackupAgent,
    element: <PartnerDesignateBackupAgentStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowers
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowers,
    element: <PartnerPowersStep />,
    rules: {
      if: [
        { "==": [{ var: "users.1.poa.generalAuthority" }, true] },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersToGrant,
    element: <PartnerPowersToGrantStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersOptionalPreview,
    element: <PartnerPowersOptionalOverviewStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersDigitalAccess
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersDigitalAccess,
    element: <PartnerPowersDigitalAssetsStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGifts
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGifts,
    element: <PartnerPowersMakeGiftsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.additionalPowers.makeGifts" },
            true
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeople,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeople,
    element: <PartnerPowersMakeGiftsPeopleStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.giftRecipients.type" },
            "certainPeople"
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeopleList,
        {
          if: [
            {
              "==": [
                { var: "users.1.poa.giftRecipients.type" },
                "Anyone"
              ]
            },
            LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsSelf,
            LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitType
          ]
        }
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsPeopleList,
    element: <PartnerPowersMakeGiftsPeopleListStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsSelf
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsSelf,
    element: <PartnerPowersMakeGiftsSelfStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitType
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitType,
    element: <PartnerPowersMakeGiftsLimitTypeStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.giftRecipients.limitType" },
            "Limit up to a certain amount"
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitAmount,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersMakeGiftsLimitAmount,
    element: <PartnerPowersMakeGiftsLimitAmountStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersRelatedTx,
    element: <PartnerPowersRelatedTxStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersTransferToTrust
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersTransferToTrust,
    element: <PartnerPowersTransferToTrustStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersDelegatePowers
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersDelegatePowers,
    element: <PartnerPowersDelegatePowersStep />,
    rules:
      LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersFiduciaryPowers
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerPowersFiduciaryPowers,
    element: <PartnerPowersFiduciaryPowersStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensation,
    element: <PartnerAgentCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.poa.compensation" },
            "Specify a compensation amount and reimbursement of expenses"
          ]
        },
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensationAmount,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.ReviewTabs
      ]
    }
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.PartnerAgentCompensationAmount,
    element: <PartnerAgentCompensationAmountStep />,
    rules: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.ReviewTabs
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Review,
    element: <ReviewStep />,
    rules: LAST_WILL_SECTION_ROUTES.DocumentPreview
  },
  {
    path: LAST_WILL_POWER_OF_ATTORNEY_ROUTES.ReviewTabs,
    element: <ReviewTabsStep />,
    rules: LAST_WILL_SECTION_ROUTES.DocumentPreview
  },
  {
    path: "*",
    element: (
      <Navigate to={LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Preview} />
    )
  }
];

export default powerOfAttorneySteps;
