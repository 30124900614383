import { Children } from "react";
import Grid from "@mui/material/Grid";

import useDocSlides from "../hooks/useDocSlides";

const DocsSlider = () => {
  const slides = useDocSlides();

  return (
    <Grid container spacing={2}>
      {Children.map(slides, (slide, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item xs={12} sm={6} lg={4} key={`Slide_${index}`}>
          {slide}
        </Grid>
      ))}
    </Grid>
  );
};

export default DocsSlider;
