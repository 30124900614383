import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InterstitialArchetype from "@components/archetypes/Interstitial";
import { BlogLinks } from "@setup/consts/articleLinks";
import { LegalFocus } from "@pages/EstateAdminQuiz/enums";
import { useFormField } from "@components/FormFields/hooks";
import { EstateData } from "@pages/EstateAdminQuiz/types";

const ChallengeInterstitialStep: React.FC = () => {
  const { value: estateData } =
    useFormField<EstateData>("estateData");
  const { focusAreas } = estateData;

  /*
  FYI -- Only s/b coming to this step if looking for ONLY one of (or both) estate 
  challenge or rights as beneficiary
  */

  return focusAreas?.includes(LegalFocus.ESTATE_CHALLENGE) ? (
    <InterstitialArchetype
      stepLayout={{
        title: `What You Need to Know About Contesting an Estate Plan`,
        isWide: true,
        muiIconHeader: <InfoOutlinedIcon />,
        subText: `Your JIC Estates estate administration expert can offer quick feedback on the likelihood of prevailing in your specifc estate plan contest. You should know that contesting an estate plan is very difficult, and most contests are not successful.`,
        moreInfoModalProps: {
          title: "Contesting an Estate Plan",
          togglerLabel: "Learn more",
          content: (
            <p>
              Contesting an estate plan is very difficult, and most
              contests are not successful.
              <br />
              <br />
              Check out our JIC Estates resource library to learn more
              about who can contest an estate plan, when you can
              contest, and how to do so successfully.
            </p>
          ),
          actions: [
            {
              label: "Go to Resource Library",
              href: BlogLinks.contestingAWill
            }
          ],
          closeLabel: "Got it"
        }
      }}
    />
  ) : (
    <InterstitialArchetype
      stepLayout={{
        title: `Understanding Your Rights As a Beneficiary`,
        isWide: true,
        muiIconHeader: <InfoOutlinedIcon />,
        subText: `As a beneficiary of an estate plan, you have certian rights protected by law. Your estate administration expert can walk you through all the rights and privileges that you have under this specific estate plan. For a general list, click 'Learn More.'`,
        moreInfoModalProps: {
          title: "Understanding Your Rights As a Beneficiary",
          togglerLabel: "Learn more",
          content: (
            <p>
              <strong>Inheritance Rights. </strong>
              Your primary right is to receive your inheritance as
              outlined in the deceased person&apos;s will or trust.
              <br />
              <br />
              <strong>Information Rights. </strong>
              You have the right to receive inforamtion about the
              estate, including a copy of the estate plan, and any
              financial documents related to the estate&apos;s
              administration.
              <br />
              <br />
              <strong>Right to Contest. </strong>
              You have the right to contest the will or trust if you
              believe there are grounds to do so. In most cases, you
              also have the right to remove an executor or trustee
              through majority vote of the other beneficiaries.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
    />
  );
};

export default ChallengeInterstitialStep;
