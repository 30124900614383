import { Outlet } from "react-router-dom";

import PlanFormProvider from "@providers/planForm";
import { ROUTES } from "@setup/consts/routes";
import { PlanType } from "@api/__generated__/globalTypes";

import routes from "./routes";
import { PetPlanValues } from "./types";

const PetPlan: React.FC = () => (
  <PlanFormProvider<PetPlanValues>
    value={{
      parentPath: ROUTES.PetPlan,
      steps: routes,
      planType: PlanType.PET_PROTECTION
    }}
  >
    <Outlet />
  </PlanFormProvider>
);

export default PetPlan;
