import { useState, useMemo } from "react";

import Tabs from "@components/Tabs";

import IndividualVisualizations from "./IndividualVisualizations";
import { VisualTabProps } from "./types";

const JointVisualizations = ({
  userLastWill,
  userTrust,
  partnerLastWill,
  userFirstName,
  partnerFirstName,
  partnerType,
  partnerTrust = undefined,
  isJointTrust = false,
  canEdit = false
}: VisualTabProps) => {
  const items = useMemo(
    () => [
      {
        label: userFirstName,
        content: (
          <IndividualVisualizations
            userLastWill={userLastWill}
            userTrust={userTrust}
            userFirstName={userFirstName}
            partnerFirstName={partnerFirstName}
            partnerType={partnerType}
            partnerLastWill={partnerLastWill}
            partnerTrust={partnerTrust}
            isJointTrust={isJointTrust}
            canEdit={canEdit}
          />
        )
      },
      {
        label: partnerFirstName ?? "Partner",
        content: (
          <IndividualVisualizations
            userLastWill={partnerLastWill}
            userTrust={partnerTrust}
            userFirstName={`${partnerFirstName}`}
            partnerFirstName={userFirstName}
            partnerType={partnerType}
            partnerLastWill={userLastWill}
            partnerTrust={userTrust}
            isJointTrust={isJointTrust}
            canEdit={canEdit}
          />
        )
      }
    ],
    [
      userLastWill,
      userTrust,
      userFirstName,
      partnerFirstName,
      partnerLastWill,
      partnerTrust,
      partnerType,
      isJointTrust,
      canEdit
    ]
  );

  return <Tabs {...{ items }} />;
};

export default JointVisualizations;
