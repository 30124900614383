import { Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormattedMessage } from "react-intl";

import { BlogLinks } from "@setup/consts/articleLinks";
import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";
import AssetReviewPartial from "@components/AssetReviewPartial";
import AddAssetsModal from "@components/QuestionnaireAssetModal/AddAssetModal";

import {
  getHowToTransferAssetsContent,
  getWhyTransferAssetsContent,
  useAssetReviewCardItems,
  useFundedAndNotFundedAssets
} from "./Reviews/utils";

const PartnerAddAssetsStep = () => {
  const { values, goBack, saveAndContinue } =
    usePlanForm<LastWillPlanValues>();

  const {
    users: [_, partner]
  } = values;

  const { fundedAssets, notFundedAssets } =
    useFundedAndNotFundedAssets(partner.assets);

  const fundedReviewItems = useAssetReviewCardItems(
    fundedAssets,
    "users.1"
  );
  const notFundedReviewItems = useAssetReviewCardItems(
    notFundedAssets,
    "users.1"
  );

  return (
    <StepLayout
      title="Add an Asset by clicking the 'Add Asset' button below."
      isWide
    >
      {fundedReviewItems.length === 0 &&
        notFundedReviewItems.length === 0 && (
          <>
            <Typography variant="h3" textAlign="center">
              <InfoOutlinedIcon />
            </Typography>
            <Typography variant="body1" textAlign="center">
              <FormattedMessage defaultMessage="You haven't added any assets to your plan yet. Add an asset by clicking the 'Add Asset' button below." />
            </Typography>
          </>
        )}
      {(fundedReviewItems.length > 0 ||
        notFundedReviewItems.length > 0) && (
        <AssetReviewPartial
          title="Assets You Are Funding to Your Trust"
          href="#ignore"
          description="These are assets titled in the name of your revocable trust."
          moreInfoModalProps={{
            togglerLabel: "Learn more.",
            title: "Transferring Assets to Your Trust",
            content: getHowToTransferAssetsContent(partner),
            actions: [
              {
                label: "Guide on Trust Funding",
                href: BlogLinks.howToFundTrust
              }
            ],
            closeLabel: "Got it"
          }}
          reviewItems={fundedReviewItems}
        />
      )}
      {(fundedReviewItems.length > 0 ||
        notFundedReviewItems.length > 0) && (
        <AssetReviewPartial
          title="Assets Not Yet Funded to Your Trust"
          href="#ignore"
          description="You don't have to fund all your assets into the trust right away, but you should make funding the trust a priority over the next few weeks in order to get the most out of your Trust."
          moreInfoModalProps={{
            togglerLabel: "Learn more.",
            title: "Transferring Assets to Your Trust",
            content: getWhyTransferAssetsContent(),
            actions: [
              {
                label: "Guide on Trust Funding",
                href: BlogLinks.howToFundTrust
              }
            ],
            closeLabel: "Got it"
          }}
          reviewItems={notFundedReviewItems}
        />
      )}
      <AddAssetsModal userIndex="users.1" showIsFunded />
      <FormStepFooter
        prevButtonProps={{ type: "button", onClick: goBack }}
        nextButtonProps={{
          type: "submit",
          onClick: () => saveAndContinue()
        }}
      />
    </StepLayout>
  );
};

export default PartnerAddAssetsStep;
