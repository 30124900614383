import { PropsWithChildren } from "react";
import { Box, Container, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useIntl } from "react-intl";

import ProgressBarSection from "@components/ProgressBarSection";
import usePlanTypeContext from "@providers/planType/hooks";
import FormStepFooter from "@components/FormStepFooter";
import { getPlanRoute } from "@utils/getPlanRoute";

import { TutorialLayoutProps } from "./types";

const TutorialLayout: React.FC<
  PropsWithChildren<TutorialLayoutProps>
> = ({
  children,
  title,
  progress,
  heading,
  description,
  prevButtonProps: skipButtonProps,
  nextButtonProps,
  withSkipButton
}) => {
  const intl = useIntl();
  const { planTypeEnum } = usePlanTypeContext();
  const {
    href: skipButtonHref = getPlanRoute(planTypeEnum),
    label: skipButtonLabel = intl.formatMessage({
      defaultMessage: "Skip Tutorial"
    }),
    ...restSkipButtonProps
  } = skipButtonProps || {};

  return (
    <>
      <ProgressBarSection {...{ title, progress }} />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          py: { xs: 2.5, md: 6.25 }
        }}
      >
        {children}
        <Box
          display="flex"
          justifyContent="center"
          mx="auto"
          mt={children ? 0 : 2}
        />
        <Box maxWidth="29.375rem" width="100%" m="0 auto">
          <Typography variant="body2" textAlign="left" mt="0.5rem">
            <strong>{heading}</strong>
            <br />
            {description}
          </Typography>
          {(skipButtonProps || nextButtonProps) && (
            <FormStepFooter
              {...{ skipButtonProps, nextButtonProps }}
              prevButtonProps={
                withSkipButton
                  ? {
                      href: skipButtonHref,
                      label: skipButtonLabel,
                      variant: "outlined",
                      ...restSkipButtonProps
                    }
                  : undefined
              }
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default TutorialLayout;
