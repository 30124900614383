import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/Trust/routes";

const SectionsPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    title="Create My Trust"
    stepLayout={{
      title:
        "Now that we’re properly sorted, let's get this party started!",
      subText:
        "We'll build your Revocable Living Trust in 6 sections."
    }}
  />
);

export default SectionsPreviewStep;
