import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { CompensationType } from "@hooks/useTrustEnums";

const RepCompensationStep: React.FC = () => {
  const { setValue: setRepCompensationDollar } = useFormField(
    "users.0.lastWill.representativesCompensationDollar"
  );
  const { setValue: setRepCompensationPercent } = useFormField(
    "users.0.lastWill.representativesCompensationPercent"
  );

  const { value: sameRepsAsTrustees } = useFormField(
    "users.0.trust.nominateTrusteesAsReps"
  );

  const recommendation = sameRepsAsTrustees
    ? "Most people designating their Trustees to also serve as Personal Representatives don't offer any additional compensation for serving as Personal Representative. Instead, these Trustee-Personal Representatives receive only the compensation specified in the trust agreement for serving as Trustee."
    : "Provided that you fund your trust properly, the Personal Representative's role should be fairly limited.";

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const repCompensation = get(
        data,
        "users.0.lastWill.representativesCompensationType"
      );

      if (repCompensation !== CompensationType.Percent) {
        setRepCompensationPercent(null);
      }
      if (repCompensation !== CompensationType.Dollar) {
        setRepCompensationDollar(null);
      }
    },
    [setRepCompensationPercent, setRepCompensationDollar]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `How would you like to compensate your Personal Representative?`,
        subText: recommendation,
        moreInfoModalProps: {
          title: "Personal Representative Compensation",
          togglerLabel: "Learn more.",
          content: (
            <p>
              Provided that you properly fund your revocable living
              trust during your lifetime, the responsibilities of your
              Personal Representative should be fairly limited.
              <br />
              <br />
              Most Trustmakers therefore decide to only offer their
              Personal Representatives reasonable reimbursement of
              expenses in their duties.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="users.0.lastWill.representativesCompensationType"
      items={[
        {
          label:
            "No compensation. Reimbursement of reasonable expenses only (most common)",
          value: CompensationType.None
        },
        {
          label: CompensationType.Reasonable,
          value: CompensationType.Reasonable
        },
        {
          label: CompensationType.Dollar,
          value: CompensationType.Dollar
        },
        {
          label: CompensationType.Percent,
          value: CompensationType.Percent
        }
      ]}
    />
  );
};

export default RepCompensationStep;
