import { useCallback } from "react";
import { get } from "lodash";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { usePlanForm } from "@providers/planForm/context";
import {
  Beneficiary,
  LastWillPlanValues
} from "@pages/LastWill/types";

const PartnerEverythingToPartnerStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();
  const {
    users: [primaryDrafter]
  } = values;

  const { setValue: setPercentGaveToPartner } = useFormField(
    "users.1.gifts.percentGaveToPartner"
  );
  const { setValue: setEstateBalanceBeneficiaries } = useFormField<
    Beneficiary[]
  >("users.1.assets.estateBalance.0.beneficiaries");

  const isJointTrust = primaryDrafter.trust?.isJointTrust;

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const everythingToPartner = get(
        data,
        "users.1.gifts.everythingGoToPartner"
      );

      if (everythingToPartner) {
        setPercentGaveToPartner(100);

        if (!isJointTrust) {
          setEstateBalanceBeneficiaries([]);
          setEstateBalanceBeneficiaries([
            {
              name: `${primaryDrafter.firstName} ${primaryDrafter.lastName}`,
              percentageValue: 100
            }
          ]);
        }
      } else setPercentGaveToPartner(null);
    },
    [
      primaryDrafter,
      isJointTrust,
      setPercentGaveToPartner,
      setEstateBalanceBeneficiaries
    ]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `If you predecease ${primaryDrafter.firstName}, do you want everything to go to ${primaryDrafter.firstName}?`
      }}
      name="users.1.gifts.everythingGoToPartner"
      {...{ onSubmit }}
    />
  );
};

export default PartnerEverythingToPartnerStep;
