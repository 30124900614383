import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { SyntheticEvent, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import {
  AutocompleteBaseOption,
  FormAutocompleteProps
} from "./types";

// eslint-disable-next-line react/function-component-definition
function BasicFormAutocomplete<
  T extends string | AutocompleteBaseOption = string
>({
  textFieldProps = {},
  onChange: onChangeProp,
  name,
  freeSolo,
  ...restProps
}: FormAutocompleteProps<T>): ReactComponentReturnType {
  const {
    control,
    formState: { errors },
    watch,
    setValue: setRHFValue
  } = useFormContext();

  const value = watch(name);

  const onChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      newValue: string | T | NonNullable<T> | null
    ) => {
      const fieldValue =
        typeof newValue === "string" ? newValue : newValue?.value;

      setRHFValue(name, fieldValue, { shouldValidate: true });
      onChangeProp?.(event, newValue);
    },
    [setRHFValue, name, onChangeProp]
  );

  const fieldError = get(errors, `${name}.message`);

  const selectedValue =
    restProps.options.find(
      (option) =>
        option === value || get(option, "value", null) === value
    ) ||
    value ||
    "";

  return (
    <Controller
      defaultValue={value ?? restProps.defaultValue}
      {...{ control, name }}
      render={({ field }): JSX.Element => (
        <Autocomplete<T, false, boolean, boolean>
          disableClearable
          autoSelect={freeSolo}
          {...restProps}
          {...field}
          getOptionLabel={(option): string =>
            typeof option === "string" ? option : option?.label
          }
          renderOption={(params, option): JSX.Element => (
            <li
              {...params}
              key={
                typeof option === "string"
                  ? option
                  : option.key || option.value
              }
            >
              {typeof option === "string" ? option : option.label}
            </li>
          )}
          renderInput={(params): JSX.Element => (
            <TextField
              {...(fieldError
                ? { error: true, helperText: fieldError }
                : {})}
              {...params}
              variant="standard"
              {...textFieldProps}
            />
          )}
          {...{ value: selectedValue, onChange, freeSolo }}
        />
      )}
    />
  );
}

export default BasicFormAutocomplete;
