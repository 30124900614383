import * as yup from "yup";
import { useCallback, useMemo } from "react";

import { useFormWithYup } from "@hooks/useFormWithYup";
import { usePlanForm } from "@providers/planForm/context";
import makeYupSchema from "@utils/makeYupSchema";

import {
  TrustSetupArchetypeValue,
  UseTrustSetupArchetypeReturnTypes
} from "./types";

const useTrustSetupArchetype = (
  name: string,
  onFormSubmit?: (data: TrustSetupArchetypeValue) => void
): UseTrustSetupArchetypeReturnTypes => {
  const { goBack, saveAndContinue } = usePlanForm();

  const schema: yup.SchemaOf<TrustSetupArchetypeValue> = useMemo(
    () =>
      makeYupSchema(
        yup.object().shape({
          restrictions: yup.string().required(),
          vestingType: yup.string().required()
        }),
        name
      ),
    [name]
  );

  const controls = useFormWithYup<TrustSetupArchetypeValue>(schema);

  const onSubmit = useCallback(
    (data: TrustSetupArchetypeValue) => {
      onFormSubmit?.(data);
      saveAndContinue();
    },
    [onFormSubmit, saveAndContinue]
  );

  return {
    controls,
    goBack,
    onSubmit: controls.handleSubmit(onSubmit)
  };
};

export { useTrustSetupArchetype };
