import React from "react";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";
import { Box, Stack, useMediaQuery } from "@mui/material";
import PersonIconOutlined from "@mui/icons-material/PersonOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import useCognitoAuth from "@hooks/useCognitoAuth";
import useArrowAnimationContext from "@providers/arrowAnimation/hooks";

import AccountDropdownSection from "../AccountDropdown/AccountDropdownSection";
import Arrow from "../Arrow";

import { UserActionsProps } from "./types";
import useHelpMenuItems from "./helpMenuItems";

const UserActions: React.FC<UserActionsProps> = ({
  dashboardItems,
  showAssistant
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { logout } = useCognitoAuth();
  const { showRightArrow } = useArrowAnimationContext();
  const helpItems = useHelpMenuItems(showAssistant);

  const items = dashboardItems.reduce(
    (result, currentItem, currentIndex) => {
      const isLast = currentIndex === dashboardItems.length - 1;
      const { label, id, href, items: currentItems } = currentItem;

      return [
        ...result,
        {
          label,
          id,
          href,
          items: isLast
            ? [
                ...currentItems,
                {
                  label: (
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                      }}
                    >
                      <FormattedMessage defaultMessage="Sign Out" />
                      <LogoutIcon sx={{ marginLeft: "0.75rem" }} />
                    </Box>
                  ),
                  id: "logout",
                  onClick: logout
                }
              ]
            : currentItems
        }
      ];
    },
    []
  );

  return (
    <Stack direction="row" spacing={{ xs: 0, md: 0 }}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center"
        }}
      >
        <AccountDropdownSection
          label="Help"
          isRight
          items={helpItems}
        />
        {showRightArrow && <Arrow />}
      </Box>
      {isDesktop && (
        <AccountDropdownSection
          isRight
          label={<PersonIconOutlined />}
          isIconButton
          {...{ items }}
        />
      )}
    </Stack>
  );
};

export default UserActions;
