import { useIntl } from "react-intl";

import SingleResponseArchetype from "@components/archetypes/SingleResponse";
import { usePlanForm } from "@providers/planForm/context";
import { Pet } from "@pages/PetPlan/types";
import { useFormField } from "@components/FormFields/hooks";

const MedicalNoteStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { iteration } = usePlanForm();

  const { value: pet } = useFormField<Pet>(`pets.${iteration}`);

  /* eslint-disable react/no-unescaped-entities */
  return (
    <SingleResponseArchetype
      stepLayout={{
        title: formatMessage(
          {
            defaultMessage:
              "Please note any allergy or other medical requirements for {name}."
          },
          {
            name: pet.name
          }
        ),
        subText: `See an example.`,
        moreInfoModalProps: {
          title: "Example Medical Information",
          content: (
            <p>
              "Clifford seems to be allergic to Clavamox, a common
              antibiotic. If he needs antibiotics, we ask the doctor
              to prescribe a different option."
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      label="Medical Information"
      fieldProps={{
        multiline: true,
        minRows: 3
      }}
      name={`pets.${iteration}.medicalInstructions`}
    />
  );
};

export default MedicalNoteStep;
