import { Navigate } from "react-router-dom";

import { PlanFormStep } from "@providers/planForm/types";
import { TRUST_SECTION_ROUTES } from "@pages/Trust/consts";
import { CompensationType } from "@hooks/useTrustEnums";
import { PlanOption } from "@api/__generated__/globalTypes";

import { TRUST_GIFTS_ROUTES } from "../Gifts/consts";

import { TRUST_MANAGEMENT_ROUTES } from "./consts";
import ReviewStep from "./steps/Review";
import ReviewTabsStepPage from "./steps/ReviewTabs";
import DesignateRepresentativeStep from "./steps/DesignateRepresentative";
import SectionsPreviewStep from "./steps/Preview";
import TrusteeOverviewStep from "./steps/TrusteeOverview";
import DesignateBackupRepresentativeStep from "./steps/DesignateBackupRepresentative";
import NominateBackupRepresentativeStep from "./steps/NominateBackupRepresentative";
import RepCompensationStep from "./steps/RepCompensation";
import RepCompensationPercentStep from "./steps/RepCompensationPercent";
import RepCompensationDollarStep from "./steps/RepCompensationDollar";
import PartnerRepCompensationStep from "./steps/PartnerRepCompensation";
import PartnerRepCompensationDollarStep from "./steps/PartnerRepCompensationDollar";
import PartnerRepCompensationPercentStep from "./steps/PartnerRepCompensationPercent";
import NominateCoTrusteeStep from "./steps/NominateCoTrustee";
import DesignateCoTrusteeStep from "./steps/DesignateCoTrustee";
import NominateBackupTrusteeStep from "./steps/NominateBackupTrustee";
import DesignateBackupTrusteeStep from "./steps/DesignateBackupTrustee";
import TrusteeCompensationStep from "./steps/TrusteeCompensation";
import RepresentativeOverviewStep from "./steps/RepresentativeOverview";
import DesignateTrusteeSameRepsStep from "./steps/DesignateTrusteeSameReps";
import TrusteeCompensationDollarStep from "./steps/TrusteeCompensationDollar";
import TrusteeCompensationPercentStep from "./steps/TrusteeCompensationPercent";
import PrimaryTrustEndStep from "./steps/PrimaryEnd";
import PartnerTrusteeStartStep from "./steps/PartnerTrusteeStart";
import PartnerNominateCoTrusteeStep from "./steps/PartnerNominateCoTrustee";
import PartnerNominateBackupTrusteeStep from "./steps/PartnerNominateBackupTrustee";
import PartnerDesignateBackupTrusteeStep from "./steps/PartnerDesignateBackupTrustee";
import PartnerDesignateTrusteeSameRepsStep from "./steps/PartnerDesignateTrusteeSameReps";
import PartnerNominateBackupRepresentativeStep from "./steps/PartnerNominateBackupRepresentative";
import PartnerDesignateBackupRepresentativeStep from "./steps/PartnerDesignateBackupRepresentative";
import PartnerTrusteeCompensationStep from "./steps/PartnerTrusteeCompensation";
import PartnerTrusteeCompensationDollarStep from "./steps/PartnerTrusteeCompensationDollar";
import PartnerTrusteeCompensationPercentStep from "./steps/PartnerTrusteeCompensationPercent";
import PartnerDesignateRepresentativeStep from "./steps/PartnerDesignateRepresentative";
import PartnerSameTrusteesAndRepsStep from "./steps/PartnerSameTrusteesAndReps";
import PartnerDesignateCoTrusteeStep from "./steps/PartnerDesignateCoTrustee";
import PartnerRepresentativeOverviewStep from "./steps/PartnerRepresentativeOverview";
import PartnerSameCompStep from "./steps/PartnerSameComp";
import TrustNameStep from "./steps/TrustName";
import TrustNameCustomizeStep from "./steps/TrustNameCustomize";
import PartnerTrustNameStep from "./steps/PartnerTrustName";
import PartnerTrustNameCustomizeStep from "./steps/PartnerTrustNameCustomize";
import CreateOrModifyTrustStep from "./steps/CreateNewTrust";
import ExistingTrustDateStep from "./steps/ExistingTrustDate";
import PartnerCreateOrModifyTrustStep from "./steps/PartnerCreateNewTrust";
import PartnerExistingTrustDateStep from "./steps/PartnerExistingTrustDate";

const managementSteps: PlanFormStep[] = [
  {
    path: TRUST_MANAGEMENT_ROUTES.Preview,
    element: <SectionsPreviewStep />,
    rules: TRUST_MANAGEMENT_ROUTES.CreateOrModify
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.CreateOrModify,
    element: <CreateOrModifyTrustStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.trust.isExistingTrust" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.TrustNameCustom,
        TRUST_MANAGEMENT_ROUTES.TrustName
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.TrustName,
    element: <TrustNameStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.trust.useCustomName" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.TrustNameCustom,
        TRUST_MANAGEMENT_ROUTES.TrusteeOverview
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.TrustNameCustom,
    element: <TrustNameCustomizeStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.trust.isExistingTrust" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.TrustDate,
        TRUST_MANAGEMENT_ROUTES.TrusteeOverview
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.TrustDate,
    element: <ExistingTrustDateStep />,
    rules: TRUST_MANAGEMENT_ROUTES.TrusteeOverview
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.TrusteeOverview,
    element: <TrusteeOverviewStep />,
    rules: TRUST_MANAGEMENT_ROUTES.NominateCoTrustee
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.NominateCoTrustee,
    element: <NominateCoTrusteeStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.trust.nominateCoTrustee" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.DesignateCoTrustee,
        TRUST_MANAGEMENT_ROUTES.DesignateBackupTrustee
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.DesignateCoTrustee,
    element: <DesignateCoTrusteeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.NominateBackupTrustee
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.NominateBackupTrustee,
    element: <NominateBackupTrusteeStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.0.trust.nominateSuccessorTrustee" },
            true
          ]
        },
        TRUST_MANAGEMENT_ROUTES.DesignateBackupTrustee,
        TRUST_MANAGEMENT_ROUTES.TrusteeCompensation
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.DesignateBackupTrustee,
    element: <DesignateBackupTrusteeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.TrusteeCompensation
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.TrusteeCompensation,
    element: <TrusteeCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `users.0.trust.trusteesCompensationType`
            },
            CompensationType.Percent
          ]
        },
        TRUST_MANAGEMENT_ROUTES.TrusteeCompensationPercent,
        {
          if: [
            {
              "==": [
                {
                  var: `users.0.trust.trusteesCompensationType`
                },
                CompensationType.Dollar
              ]
            },
            TRUST_MANAGEMENT_ROUTES.TrusteeCompensationDollar,
            TRUST_MANAGEMENT_ROUTES.RepresentativeOverview
          ]
        }
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.TrusteeCompensationDollar,
    element: <TrusteeCompensationDollarStep />,
    rules: TRUST_MANAGEMENT_ROUTES.RepresentativeOverview
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.TrusteeCompensationPercent,
    element: <TrusteeCompensationPercentStep />,
    rules: TRUST_MANAGEMENT_ROUTES.RepresentativeOverview
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.RepresentativeOverview,
    element: <RepresentativeOverviewStep />,
    rules: TRUST_MANAGEMENT_ROUTES.DesignateTrusteeSameReps
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.DesignateTrusteeSameReps,
    element: <DesignateTrusteeSameRepsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: `users.0.trust.nominateTrusteesAsReps` },
            true
          ]
        },
        TRUST_MANAGEMENT_ROUTES.RepCompensation,
        TRUST_MANAGEMENT_ROUTES.DesignateRepresentative
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.DesignateRepresentative,
    element: <DesignateRepresentativeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.NominateBackupRepresentative
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.NominateBackupRepresentative,
    element: <NominateBackupRepresentativeStep />,
    rules: {
      if: [
        {
          "==": [{ var: "nominateBackupRep" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.DesignateBackupRepresentative,
        TRUST_MANAGEMENT_ROUTES.RepCompensation
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.DesignateBackupRepresentative,
    element: <DesignateBackupRepresentativeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.RepCompensation
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.RepCompensation,
    element: <RepCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `users.0.lastWill.representativesCompensationType`
            },
            CompensationType.Percent
          ]
        },
        TRUST_MANAGEMENT_ROUTES.RepCompensationPercent,
        {
          if: [
            {
              "==": [
                {
                  var: `users.0.lastWill.representativesCompensationType`
                },
                CompensationType.Dollar
              ]
            },
            TRUST_MANAGEMENT_ROUTES.RepCompensationDollar,
            {
              if: [
                { "==": [{ var: `planOption` }, PlanOption.COUPLE] },
                TRUST_MANAGEMENT_ROUTES.PrimaryEnd,
                TRUST_MANAGEMENT_ROUTES.Review
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.RepCompensationPercent,
    element: <RepCompensationPercentStep />,
    rules: {
      if: [
        { "==": [{ var: `planOption` }, PlanOption.COUPLE] },
        TRUST_MANAGEMENT_ROUTES.PrimaryEnd,
        TRUST_MANAGEMENT_ROUTES.Review
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.RepCompensationDollar,
    element: <RepCompensationDollarStep />,
    rules: {
      if: [
        { "==": [{ var: `planOption` }, PlanOption.COUPLE] },
        TRUST_MANAGEMENT_ROUTES.PrimaryEnd,
        TRUST_MANAGEMENT_ROUTES.Review
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PrimaryEnd,
    element: <PrimaryTrustEndStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.0.trust.isJointTrust" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerDesignateTrusteeSameReps,
        TRUST_MANAGEMENT_ROUTES.PartnerCreateOrModify
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerCreateOrModify,
    element: <PartnerCreateOrModifyTrustStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.1.trust.isExistingTrust" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerTrustNameCustom,
        TRUST_MANAGEMENT_ROUTES.PartnerTrustName
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerTrustName,
    element: <PartnerTrustNameStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.1.trust.useCustomName" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerTrustNameCustom,
        TRUST_MANAGEMENT_ROUTES.PartnerTrusteeStart
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerTrustNameCustom,
    element: <PartnerTrustNameCustomizeStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.1.trust.isExistingTrust" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerTrustDate,
        TRUST_MANAGEMENT_ROUTES.PartnerTrusteeStart
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerTrustDate,
    element: <PartnerExistingTrustDateStep />,
    rules: TRUST_MANAGEMENT_ROUTES.PartnerTrusteeStart
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerTrusteeStart,
    element: <PartnerTrusteeStartStep />,
    rules: TRUST_MANAGEMENT_ROUTES.PartnerSameTrusteesAndReps
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerSameTrusteesAndReps,
    element: <PartnerSameTrusteesAndRepsStep />,
    rules: {
      if: [
        {
          "==": [{ var: "copyPersonalRepresentatives" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerSameComp,
        TRUST_MANAGEMENT_ROUTES.PartnerNominateCoTrustee
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerSameComp,
    element: <PartnerSameCompStep />,
    rules: {
      if: [
        {
          "==": [{ var: "copyPersonalRepresentativesComp" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.ReviewTabs,
        TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensation
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerNominateCoTrustee,
    element: <PartnerNominateCoTrusteeStep />,
    rules: {
      if: [
        {
          "==": [{ var: "users.1.trust.nominateCoTrustee" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerDesignateCoTrustee,
        TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupTrustee
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerDesignateCoTrustee,
    element: <PartnerDesignateCoTrusteeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.PartnerNominateBackupTrustee
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerNominateBackupTrustee,
    element: <PartnerNominateBackupTrusteeStep />,
    rules: {
      if: [
        {
          "==": [
            { var: "users.1.trust.nominateSuccessorTrustee" },
            true
          ]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupTrustee,
        TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensation
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupTrustee,
    element: <PartnerDesignateBackupTrusteeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensation
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensation,
    element: <PartnerTrusteeCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `users.1.trust.trusteesCompensationType`
            },
            CompensationType.Percent
          ]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensationPercent,
        {
          if: [
            {
              "==": [
                {
                  var: `users.1.trust.trusteesCompensationType`
                },
                CompensationType.Dollar
              ]
            },
            TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensationDollar,
            {
              if: [
                {
                  "==": [
                    {
                      var: `copyPersonalRepresentatives`
                    },
                    true
                  ]
                },
                TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation,
                TRUST_MANAGEMENT_ROUTES.PartnerRepresentativeOverview
              ]
            }
          ]
        }
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensationDollar,
    element: <PartnerTrusteeCompensationDollarStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `copyPersonalRepresentatives`
            },
            true
          ]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation,
        TRUST_MANAGEMENT_ROUTES.PartnerRepresentativeOverview
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerTrusteeCompensationPercent,
    element: <PartnerTrusteeCompensationPercentStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `copyPersonalRepresentatives`
            },
            true
          ]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation,
        TRUST_MANAGEMENT_ROUTES.PartnerRepresentativeOverview
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerRepresentativeOverview,
    element: <PartnerRepresentativeOverviewStep />,
    rules: TRUST_MANAGEMENT_ROUTES.PartnerDesignateTrusteeSameReps
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerDesignateTrusteeSameReps,
    element: <PartnerDesignateTrusteeSameRepsStep />,
    rules: {
      if: [
        {
          "==": [
            { var: `users.1.trust.nominateTrusteesAsReps` },
            true
          ]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation,
        TRUST_MANAGEMENT_ROUTES.PartnerDesignateRepresentative
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerDesignateRepresentative,
    element: <PartnerDesignateRepresentativeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.PartnerNominateBackupRepresentative
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerNominateBackupRepresentative,
    element: <PartnerNominateBackupRepresentativeStep />,
    rules: {
      if: [
        {
          "==": [{ var: "nominatePartnerBackupRep" }, true]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupRepresentative,
        TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerDesignateBackupRepresentative,
    element: <PartnerDesignateBackupRepresentativeStep />,
    rules: TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerRepCompensation,
    element: <PartnerRepCompensationStep />,
    rules: {
      if: [
        {
          "==": [
            {
              var: `users.1.lastWill.representativesCompensationType`
            },
            CompensationType.Percent
          ]
        },
        TRUST_MANAGEMENT_ROUTES.PartnerRepCompensationPercent,
        {
          if: [
            {
              "==": [
                {
                  var: `users.1.lastWill.representativesCompensationType`
                },
                CompensationType.Dollar
              ]
            },
            TRUST_MANAGEMENT_ROUTES.PartnerRepCompensationDollar,
            TRUST_MANAGEMENT_ROUTES.ReviewTabs
          ]
        }
      ]
    }
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerRepCompensationPercent,
    element: <PartnerRepCompensationPercentStep />,
    rules: TRUST_MANAGEMENT_ROUTES.ReviewTabs
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.PartnerRepCompensationDollar,
    element: <PartnerRepCompensationDollarStep />,
    rules: TRUST_MANAGEMENT_ROUTES.ReviewTabs
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.Review,
    element: <ReviewStep />,
    rules: `${TRUST_SECTION_ROUTES.Gifts}/${TRUST_GIFTS_ROUTES.Preview}`
  },
  {
    path: TRUST_MANAGEMENT_ROUTES.ReviewTabs,
    element: <ReviewTabsStepPage />,
    rules: `${TRUST_SECTION_ROUTES.Gifts}/${TRUST_GIFTS_ROUTES.Preview}`
  },
  {
    path: "*",
    element: <Navigate to={TRUST_MANAGEMENT_ROUTES.Preview} />
  }
];

export default managementSteps;
