import { gql } from "@apollo/client";

import { MEMBERSHIPS_FRAGMENT } from "@api/plans/fragments";

export const INVITE_MEMBER = gql`
  ${MEMBERSHIPS_FRAGMENT}
  mutation InviteMember($input: InviteMemberInput!) {
    inviteMember(input: $input) {
      ...Membership
    }
  }
`;

export const ACCEPT_MEMBERSHIP_INVITATION = gql`
  ${MEMBERSHIPS_FRAGMENT}
  mutation AcceptMembershipInvitation(
    $input: AcceptMembershipInvitationInput!
  ) {
    acceptMembershipInvitation(input: $input) {
      ...Membership
    }
  }
`;

export const UPDATE_MEMBERSHIP = gql`
  ${MEMBERSHIPS_FRAGMENT}
  mutation UpdateMembership(
    $id: Int!
    $input: UpdateMembershipInput!
  ) {
    updateMembership(id: $id, input: $input) {
      ...Membership
    }
  }
`;
