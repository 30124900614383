import { useIntl } from "react-intl";
import { FormControl, FormLabel } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useMemo } from "react";

import StepLayout from "@components/StepLayout";
import FormStepFooter from "@components/FormStepFooter";
import FormRadioButtons from "@components/FormFields/FormRadioButtons";

import { useSingleSelectArchetype } from "./hooks";
import { SingleSelectArchetypeProps } from "./types";

const SingleSelectArchetype: React.FC<SingleSelectArchetypeProps> = ({
  items,
  name,
  stepLayout,
  onSubmit: onFormSubmit,
  nullable = false,
  defaultValue
}) => {
  const intl = useIntl();
  const defaultRadioItems = useMemo(
    () => [
      {
        label: intl.formatMessage({
          defaultMessage: "Yes"
        }),
        value: true
      },
      {
        label: intl.formatMessage({
          defaultMessage: "No"
        }),
        value: false
      }
    ],
    [intl]
  );

  const { controls, goBack, onSubmit } = useSingleSelectArchetype(
    name,
    items || defaultRadioItems,
    onFormSubmit,
    { nullable }
  );

  const defaultSelectedValue =
  defaultValue !== undefined && defaultValue !== null
    ? defaultValue
    : items?.[0].value ?? defaultRadioItems[0].value;

  return (
    <StepLayout
      title={intl.formatMessage(
        {
          defaultMessage: "Do you or {name} have children?"
        },
        {
          name: "Sally"
        }
      )}
      {...stepLayout}
    >
      <FormProvider {...controls}>
        <form {...{ onSubmit }}>
          <FormControl>
            <FormRadioButtons
              {...{ name }}
              defaultValue={defaultSelectedValue}
              items={items || defaultRadioItems}
            />
          </FormControl>
          <FormStepFooter
            prevButtonProps={{ type: "button", onClick: goBack }}
            nextButtonProps={{
              type: "submit"
            }}
          />
        </form>
      </FormProvider>
    </StepLayout>
  );
};

export default SingleSelectArchetype;
