import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { LastWillPlanValues } from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import { parseBoolean } from "@utils/parseBoolean";
import { PlanOption } from "@api/__generated__/globalTypes";

const HavingChildrenStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter, partner]
  } = values;

  const { value: planOption } =
    useFormField<PlanOption>(`planOption`);
  const { setValue: setChildrenValue } = useFormField("children");
  const { setValue: clearGuardians } = useFormField(`guardians`);
  const { setValue: clearBackupGuardians } =
    useFormField(`backupGuardians`);
  const { setValue: clearNominateGuardians } =
    useFormField(`nominateGuardians`);
  const { setValue: clearNominateBackupGuardians } = useFormField(
    `nominateBackupGuardians`
  );

  const onSubmit = useCallback(
    ({ haveChildren }: SingleSelectFormValues) => {
      if (!parseBoolean(haveChildren)) {
        setChildrenValue([]);
        clearGuardians([]);
        clearBackupGuardians([]);
        clearNominateGuardians(false);
        clearNominateBackupGuardians(false);
      }
    },
    [
      clearBackupGuardians,
      clearGuardians,
      clearNominateBackupGuardians,
      clearNominateGuardians,
      setChildrenValue
    ]
  );

  if (planOption === PlanOption.SINGLE) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `Do you have children, ${primaryDrafter.firstName}?`
        }}
        {...{ onSubmit }}
        name="haveChildren"
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you or ${partner?.firstName} have children?`
      }}
      {...{ onSubmit }}
      name="haveChildren"
    />
  );
};

export default HavingChildrenStep;
