import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

import { useDocumentsPreview } from "@components/archetypes/DocumentPreview/hooks";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { ROUTES } from "@setup/consts/routes";
import { PlanOption } from "@api/__generated__/globalTypes";

import { HEALTHCARE_ROUTES } from "../consts";
import { HEALTHCARE_LIVINGWILL_ROUTES } from "../sections/livingWill/consts";
import { usePlanForm } from "@providers/planForm/context";
import { LastWillPlanValues } from "@pages/LastWill/types";

const HealthcareCompleteStep: React.FC = () => {
  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    users: [primaryDrafter, partner],
    planOption
  } = values;
  
  const { loading, handleGoToCheckout, paymentLoading } =
    useDocumentsPreview();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Added to show loading screen long enough to see what's diplayed
    if (loading) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 3000);
    }
  }, [loading]);

  return !primaryDrafter?.healthcare && !partner?.healthcare ? (
    <Box maxWidth="29.375rem" width="80%" m="0 auto">
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        <HealthAndSafetyIcon />
        <br />
        You chose not to create an Advance Healthcare Directive.
      </Typography>
      <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
        <FormattedMessage defaultMessage="If this is incorrect, please go back and adjust your selections. Otherwise, please click the button below to return home to the member dashboard." />
      </Typography>
      
      <Box display="flex" flexDirection="row" gap="0.625rem">
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            width: "100%",
            minWidth: "auto"
          }}
          href={
            planOption === PlanOption.COUPLE
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs
                ])
              : getLinkFullUrl([
                  ROUTES.PowerOfAttorney,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.Review
                ])
          }
        >
          <FormattedMessage defaultMessage="Go Back" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            minWidth: "auto",
            alignContent: "center"
          }}
          disabled={paymentLoading}
          onClick={handleGoToCheckout}
        >
          <FormattedMessage defaultMessage="Return Home" />
        </Button>
      </Box>
    </Box>
  )
  :  (
    <Box maxWidth="29.375rem" width="100%" m="0 auto">
      <Typography
        variant="h1"
        letterSpacing="0"
        textAlign="center"
        lineHeight="1.5"
        mb={{ xs: 2.5 }}
      >
        <HealthAndSafetyIcon />
        <br />
        Ready to get your docs?
      </Typography>
      <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
        <FormattedMessage defaultMessage="Please click below to generate your Advance Health Care Directive and return to the member dashboard." />
      </Typography>
      <Typography textAlign="left" mt="1.5rem" mb="1.5rem">
        <FormattedMessage defaultMessage="Note generating the documents may take 1-2 minutes." />
      </Typography>
      <Box display="flex" flexDirection="row" gap="0.625rem">
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            width: "100%",
            minWidth: "auto"
          }}
          href={
            planOption === PlanOption.COUPLE
              ? getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.ReviewTabs
                ])
              : getLinkFullUrl([
                  ROUTES.Healthcare,
                  HEALTHCARE_ROUTES.LivingWill,
                  HEALTHCARE_LIVINGWILL_ROUTES.Review
                ])
          }
        >
          <FormattedMessage defaultMessage="Go Back" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            minWidth: "auto"
          }}
          disabled={paymentLoading}
          onClick={handleGoToCheckout}
        >
          <FormattedMessage defaultMessage="Generate Documents" />
        </Button>
      </Box>
    </Box>
  );
};

export default HealthcareCompleteStep;
