import * as jose from "jose";

import { FINANCIAL_ADVISOR_LOCAL_STORAGE_KEY } from "@setup/consts/misc";

const secret = jose.base64url.decode(
  "iYoiIzdeco4q84IcMcBVhRXXSkoNHKkhKSCUCrY2bBo="
);

export const generateFinancialAdvisorToken = async () => {
  const jwt = await new jose.EncryptJWT({
    convertUserToFinancialAdvisor: true
  })
    .setProtectedHeader({ alg: "dir", enc: "A128CBC-HS256" })
    .setIssuedAt()
    .setExpirationTime("5m")
    .encrypt(secret);

  return jwt;
};

export const setFinancialAdvisorToken = async () => {
  const jwt = await generateFinancialAdvisorToken();

  localStorage.setItem(FINANCIAL_ADVISOR_LOCAL_STORAGE_KEY, jwt);
};

export const checkFinancialAdvisorTokenValidity = async () => {
  const token = localStorage.getItem(
    FINANCIAL_ADVISOR_LOCAL_STORAGE_KEY
  );

  if (!token) {
    return false;
  }

  try {
    const { payload } = await jose.jwtDecrypt(token, secret);

    if (!payload.exp) {
      return false;
    }

    if (payload.exp < (new Date().getTime() + 1) / 1000) {
      return false;
    }

    return payload?.convertUserToFinancialAdvisor ?? false;
  } catch {
    return false;
  }
};

export const removeFinancialAdvisorToken = () =>
  localStorage.removeItem(FINANCIAL_ADVISOR_LOCAL_STORAGE_KEY);
