import { Grid, Typography, Box } from "@mui/material";

import ReviewCard from "@components/ReviewCard";

import { AssetsPartialsProps } from "../types";

const AssetsPartial: React.FC<AssetsPartialsProps> = ({
  reviewItems,
  title,
  description,
  canEdit = false
}) => (
  <Box display="flex" flexDirection="column" mt="2.5rem">
    <Typography
      sx={{
        fontSize: "1.5rem",
        fontWeight: "500"
      }}
    >
      {title}
    </Typography>
    {description && (
      <Typography
        sx={{
          fontSize: "14px",
          marginTop: "1rem"
        }}
      >
        {description}
      </Typography>
    )}
    <Box>
      {reviewItems.map(({ title: reviewItemTitle, ...item }, idx) => (
        <Grid
          mt="1rem"
          item
          xs={12}
          md={12}
          // eslint-disable-next-line react/no-array-index-key
          key={`${reviewItemTitle}_${idx}`}
        >
          <ReviewCard
            {...{ canEdit, title: reviewItemTitle, ...item }}
          />
        </Grid>
      ))}
    </Box>
  </Box>
);

export default AssetsPartial;
