import { ChangeEvent, useCallback, useMemo } from "react";
import get from "lodash/get";
import clamp from "lodash/clamp";
import omit from "lodash/omit";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

import { numberWithCommas } from "@utils/misc";

import { useFormField } from "../hooks";

import { FormInputProps } from "./types";

const FormInput: React.FC<FormInputProps> = (props) => {
  const { name, type, InputProps, inputProps, valuePrefix } = props;
  const { value = "", setValue } = useFormField<string | number>(
    name,
    ""
  );

  const { min, max } = inputProps || InputProps?.inputProps || {};

  const {
    control,
    setValue: setRHFValue,
    formState: { errors }
  } = useFormContext();

  const fieldError = get(errors, `${name}.message`);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue, valueAsNumber } = event.target;

      const newValue = ((): string | number => {
        if (valuePrefix && type !== "number") {
          return inputValue
            .replaceAll(valuePrefix, "")
            .replaceAll(",", "");
        }
        if (type === "number" && !Number.isNaN(valueAsNumber)) {
          return clamp(valueAsNumber, min, max);
        }

        return inputValue;
      })();

      setValue(newValue);
      setRHFValue(name, newValue, { shouldValidate: true });
    },
    [setValue, setRHFValue, name, min, max, type, valuePrefix]
  );

  const valuePrefixed: string | number = useMemo(() => {
    if (!valuePrefix || type === "number") {
      return value;
    }

    if (valuePrefix === "%") {
      return `${value ?? ""}${valuePrefix}`;
    }

    return `${valuePrefix}${numberWithCommas(value) ?? ""}`;
  }, [value, valuePrefix, type]);

  return (
    <Controller
      defaultValue={value}
      {...{ control, name }}
      render={({ field }): React.ReactElement => (
        <TextField
          variant="standard"
          {...(fieldError
            ? { error: true, helperText: fieldError }
            : {})}
          {...omit(props, "valuePrefix")}
          {...field}
          {...{ value: valuePrefixed, onChange }}
        />
      )}
    />
  );
};

export default FormInput;
