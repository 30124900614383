import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const NominatePartnerBackupRepresentativeStep: React.FC = () => {
  const { value: partnerFirstName } =
    useFormField(`users.1.firstName`);

  const { setValue: setBackupReps } = useFormField(
    "users.1.lastWill.backupPersonalRepresentatives"
  );

  const onSubmit = useCallback(
    ({ nominatePartnerBackupRep }: SingleSelectFormValues) => {
      if (!parseBoolean(nominatePartnerBackupRep)) {
        setBackupReps([]);
      }
    },
    [setBackupReps]
  );

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Does ${partnerFirstName} want to nominate a backup Personal Representative?`,
        subText:
          "A backup Personal Representative can serve if the primary Personal Representative is unavailable."
      }}
      {...{ onSubmit }}
      name="nominatePartnerBackupRep"
    />
  );
};

export default NominatePartnerBackupRepresentativeStep;
