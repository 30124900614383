import React from "react";
import { Typography, useTheme } from "@mui/material";
import { Tree, TreeNode } from "react-organizational-chart";

import InfoModal from "@components/InfoModal";

import * as Styled from "./styles";
import { HierarchyGiftsProps, HierarchyGiftItem } from "./types";

const HierarchyNode: React.FC<HierarchyGiftItem> = ({
  name,
  subItems,
  moreInfoModalProps,
  giftNote
}) => {
  const theme = useTheme();

  return (
    <TreeNode
      // eslint-disable-next-line react/no-array-index-key
      label={
        <Styled.Card>
          <Typography>{name}</Typography>
          {giftNote && (
            <Typography
              variant="caption"
              color={theme.palette.grey[500]}
            >
              {giftNote}
            </Typography>
          )}
          {moreInfoModalProps && (
            <InfoModal {...moreInfoModalProps} />
          )}
        </Styled.Card>
      }
    >
      {subItems &&
        subItems.length > 0 &&
        subItems.map((subItem, index) => (
          <HierarchyNode
            // eslint-disable-next-line react/no-array-index-key
            key={`${subItem.name}_${index}`}
            {...subItem}
          />
        ))}
    </TreeNode>
  );
};

const HierarchyGifts: React.FC<HierarchyGiftsProps> = ({
  title,
  items
}) => (
  <Tree label={title}>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <HierarchyNode {...item} key={`${item.name}_${index}`} />
    ))}
  </Tree>
);

export default HierarchyGifts;
