import { PropsWithChildren, useMemo, useState } from "react";

import { AIAssistantContext } from "./context";
import { AIAssistantContextType } from "./type";

export const { Provider: AIAssistantProvider } = AIAssistantContext;

export const ChatAssistantProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [isChatEnabled, setIsChatEnabled] = useState(false);

  const chatAssistantContextValue =
    useMemo<AIAssistantContextType>(() => {
      const toggleChat = () => {
        setIsChatEnabled((prev) => !prev);
      };

      return {
        isChatEnabled,
        toggleChat
      };
    }, [isChatEnabled]);

  return (
    <AIAssistantProvider value={chatAssistantContextValue}>
      {children}
    </AIAssistantProvider>
  );
};
