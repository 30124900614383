import SingleSelectArchetype from "@components/archetypes/SingleSelect";

const PowersMakeGiftsSelfStep: React.FC = () => (
  <SingleSelectArchetype
    stepLayout={{
      title: `Should your agent's ability to make gifts include gifts to themselves and others your agent supports?`
    }}
    name="users.0.poa.giftRecipients.allowToAgent"
  />
);

export default PowersMakeGiftsSelfStep;
