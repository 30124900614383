import { makeMutationHook } from "@utils/apollo-utils";

import {
  ACCEPT_INVITATION,
  INVITE_CLIENT,
  CREATE_AND_COPY_CLIENT_INVITE
} from "./mutations";
import {
  AcceptInvitation,
  AcceptInvitationVariables
} from "./__generated__/AcceptInvitation";
import {
  InviteClient,
  InviteClientVariables
} from "./__generated__/InviteClient";
import {
  CreateClientInvite,
  CreateClientInviteVariables
} from "./__generated__/CreateClientInvite";

export const useInviteClientMutation = makeMutationHook<
  InviteClient,
  InviteClientVariables
>(INVITE_CLIENT);

export const useCreateAndCopyClientInviteMutation = makeMutationHook<
  CreateClientInvite,
  CreateClientInviteVariables
>(CREATE_AND_COPY_CLIENT_INVITE);

export const useAcceptInvitationMutation = makeMutationHook<
  AcceptInvitation,
  AcceptInvitationVariables
>(ACCEPT_INVITATION);
