import { useCallback } from "react";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import {
  Beneficiary,
  LastWillPlanValues
} from "@pages/LastWill/types";
import { usePlanForm } from "@providers/planForm/context";
import { parseBoolean } from "@utils/parseBoolean";
import usePrimaryDraftersChildren from "@hooks/usePrimaryDraftersChildren";

const ResidueToChildrenStep: React.FC = () => {
  const {
    value: residuePercentRemaining,
    setValue: setResiduePercentRemaining
  } = useFormField<number>("residuePercentRemaining", 100);

  const {
    value: residuePercentAllocated,
    setValue: setResiduePercentAllocated
  } = useFormField<number>("residuePercentAllocated", 0);

  const { value: giveSpecificGifts } = useFormField(
    "users.0.gifts.giveSpecificGifts"
  );

  const { value: residueGift, setValue: setResidueGift } =
    useFormField<Beneficiary[]>(
      "users.0.assets.estateBalance.0.beneficiaries"
    );

  const { values } = usePlanForm<LastWillPlanValues>();

  const {
    children,
    users: [, partner]
  } = values;

  const primaryDraftersChildren = usePrimaryDraftersChildren(
    partner?.firstName,
    children
  );

  const onSubmit = useCallback(
    ({ residueBalanceToChildren }: SingleSelectFormValues) => {
      const numberOfChildrens = primaryDraftersChildren?.length ?? 0;

      if (
        parseBoolean(residueBalanceToChildren) &&
        numberOfChildrens > 0
      ) {
        const amountPerEach =
          residuePercentRemaining / numberOfChildrens;

        setResidueGift([
          ...(primaryDraftersChildren?.map((child) => ({
            name: child.name,
            percentageValue: amountPerEach
          })) ?? [])
        ]);

        setResiduePercentAllocated(
          residuePercentAllocated + residuePercentRemaining
        );
        setResiduePercentRemaining(0);
      }

      if (!parseBoolean(residueBalanceToChildren)) {
        const primaryDraftersChildrenNames = [
          ...(primaryDraftersChildren?.map((child) => child?.name) ??
            [])
        ];

        const childrenWithGift = residueGift?.filter(
          (gift) =>
            gift?.name &&
            primaryDraftersChildrenNames.includes(gift.name)
        );

        const allocatedPercentForChildrens = childrenWithGift?.reduce(
          (accumulator, current) =>
            accumulator + (current?.percentageValue ?? 0),
          0
        );

        setResidueGift([
          ...(residueGift?.filter(
            (gift) =>
              gift.name &&
              !primaryDraftersChildrenNames.includes(gift.name)
          ) ?? [])
        ]);

        setResiduePercentRemaining(allocatedPercentForChildrens);
        setResiduePercentAllocated(
          100 - allocatedPercentForChildrens
        );
      }
    },
    [
      primaryDraftersChildren,
      residueGift,
      residuePercentAllocated,
      residuePercentRemaining,
      setResidueGift,
      setResiduePercentAllocated,
      setResiduePercentRemaining
    ]
  );

  if (giveSpecificGifts) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `After completing your specific gifts, do you want the balance of your assets to be divided equally among your children?`
        }}
        name="residueBalanceToChildren"
        {...{ onSubmit }}
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Do you want the balance of your assets to be divided equally among your children?`
      }}
      name="residueBalanceToChildren"
      {...{ onSubmit }}
    />
  );
};

export default ResidueToChildrenStep;
