import InterstitialArchetype from "@components/archetypes/Interstitial";
import { useFormField } from "@components/FormFields/hooks";

const PartnerResidueCompleteStep: React.FC = () => {
  const { value: partnerFirstName } = useFormField(
    "users.1.firstName"
  );

  return (
    <InterstitialArchetype
      stepLayout={{
        title: `Super! We've finished allocating the balance of ${partnerFirstName}'s estate.`
      }}
    />
  );
};

export default PartnerResidueCompleteStep;
