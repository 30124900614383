import { capitalize } from "lodash";

import {
  Child,
  Child_guardians as Guardians
} from "@api/plans/__generated__/Child";
import { PartnerType } from "@api/__generated__/globalTypes";

import getAgentHierarchy from "./getAgentHierarchy";

const useGuardianHierarchy = (
  childs: Child[],
  partnerType: PartnerType,
  partnerAsChildGuardian: boolean
) => {
  if (!childs || childs.length === 0) {
    return [];
  }

  const humanizedPartnerType = capitalize(partnerType);

  // Function to serialize guardian data for comparison.
  const serializeGuardians = (
    guardians: (Guardians | null)[] | null
  ) =>
    guardians
      ? guardians
          .filter((g) => g !== null)
          .map(
            (g: Guardians | null) =>
              `${g?.guardian?.id}-${g?.isBackup}-${g?.guardian?.fullName}`
          )
          .join(",")
      : undefined;

  // Check if all children have the same guardians in the same order.
  const firstChildGuardians = serializeGuardians(childs[0].guardians);
  const allChildrenHaveSameGuardians = childs.every(
    (child) =>
      serializeGuardians(child.guardians) === firstChildGuardians
  );

  if (allChildrenHaveSameGuardians && childs[0].guardians) {
    // All children have the same guardians.
    const agents = [];

    if (
      partnerAsChildGuardian &&
      partnerType !== PartnerType.SINGLE
    ) {
      agents.push({
        name: `Surviving ${humanizedPartnerType}`,
        order: -1,
        isBackup: false
      });
    }

    agents.push(
      ...childs[0].guardians.map((agent, idx) => ({
        isBackup: !!agent?.isBackup,
        order: agent?.order ?? idx,
        name: agent?.guardian?.fullName
      }))
    );

    const hierarchy = getAgentHierarchy(agents);

    return hierarchy;
  }

  // Ensure we return an empty array when guardians are undefined
  return childs.map((child) => {
    const agents = [];

    if (
      partnerAsChildGuardian &&
      partnerType !== PartnerType.SINGLE
    ) {
      agents.push({
        name: `Surviving ${humanizedPartnerType}`,
        order: -1,
        isBackup: false
      });
    }

    agents.push(
      ...(child.guardians?.map((agent, idx) => ({
        isBackup: !!agent?.isBackup,
        order: agent?.order ?? idx,
        name: agent?.guardian?.fullName ?? `Missing Guardian Info`
      })) ?? [])
    );

    return {
      name: `${child.user?.fullName}'s Guardians`,
      isBackup: false,
      order: -1,
      subItems: getAgentHierarchy(agents)
    };
  });
};

export default useGuardianHierarchy;
