import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import { NavigationItem } from "@components/Header/types";
import {
  getImageData,
  getLinkData,
  tryGetProtocol
} from "@utils/misc";
import { MARKETING_PAGE_URL } from "@setup/consts/misc";
import { useGetNavigationQuery } from "@api/allNavigation/hooks";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { isOwner } from "@utils/permission-utils";
import { ROUTES } from "@setup/consts/routes";
import { DASHBOARD_SECTION_ROUTES } from "@pages/EstatePlanView/Partials/consts";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";

import { UseMainNavigationReturnType } from "./types";

export type ItemType<ArrayType extends Array<unknown>> =
  ArrayType[number];

export const useMainNavigation = (): UseMainNavigationReturnType => {
  const intl = useIntl();
  const { plans } = useMemberPlansState();

  const plansSharedWithMe = useMemo(
    () => plans?.filter((plan) => !isOwner(plan?.membership)),
    [plans]
  );

  const { loading, data } = useGetNavigationQuery();

  const headerProps = useMemo(() => {
    if (!data) {
      return {
        dashboardItems: [],
        navItems: [],
        logoDesktop: undefined,
        logoMobile: undefined
      };
    }

    const {
      allNavigation: [navigationData]
    } = data;

    if (!navigationData) {
      return {
        dashboardItems: [],
        navItems: [],
        logoDesktop: undefined,
        logoMobile: undefined
      };
    }

    const { items, logo, mobileLogo } = navigationData;

    const sharedPlanItems =
      (plansSharedWithMe ?? [])?.map((plan, index) => ({
        label: plan?.primaryDrafter?.fullName ?? "",
        href: generatePath(":pathname?planId=:planId", {
          planId: plan?.id.toString(),
          pathname: getLinkFullUrl([ROUTES.Dashboard])
        }),
        items: [
          {
            label: intl.formatMessage({
              defaultMessage: "Docs & Data"
            }),
            href: generatePath(":pathname?planId=:planId", {
              planId: plan?.id.toString(),
              pathname: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.DocsAndData
              ])
            }),
            id: `${index.toString()}.1`
          },
          {
            label: intl.formatMessage({
              defaultMessage: "Assets"
            }),
            href: generatePath(":pathname?planId=:planId", {
              planId: plan?.id.toString(),
              pathname: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Assets
              ])
            }),
            id: `${index.toString()}.2`
          }
        ],
        id: index.toString()
      })) ?? [];

    return {
      logoDesktop: getImageData(logo),
      logoMobile: getImageData(mobileLogo),
      navItems: (items ?? []).map<NavigationItem>((item) => {
        const {
          _key,
          label,
          link: itemLink,
          items: subItems
        } = item ?? {};
        const { href } = getLinkData(itemLink);

        const protocol = tryGetProtocol(href);

        return {
          id: _key || "",
          label: label || "",
          href: protocol
            ? href
            : `${MARKETING_PAGE_URL}${href}` || "/",
          isExternal: true,
          items: (subItems ?? []).reduce<
            ItemType<Required<NavigationItem>["items"]>[]
          >((result, subItem) => {
            if (!subItem) {
              return result;
            }

            const {
              _key: subItemKey,
              label: subItemLabel,
              link: subItemLink
            } = subItem;
            const { href: subItemHref } = getLinkData(subItemLink);
            const subProtocol = tryGetProtocol(subItemHref);

            return [
              ...result,
              {
                id: subItemKey || "",
                label: subItemLabel || "",
                href: subProtocol
                  ? subItemHref
                  : `${MARKETING_PAGE_URL}${subItemHref}` || "/",
                isExternal: true
              }
            ];
          }, [])
        };
      }),
      dashboardItems: [
        {
          label: intl.formatMessage({
            defaultMessage: "My Plan"
          }),
          id: "6.0",
          items: [
            {
              label: intl.formatMessage({
                defaultMessage: "Docs & Data"
              }),
              href: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.DocsAndData
              ]),
              id: "6.2"
            },
            {
              label: intl.formatMessage({
                defaultMessage: "Assets"
              }),
              href: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Assets
              ]),
              id: "6.3"
            },
            {
              label: intl.formatMessage({
                defaultMessage: "Visualize"
              }),
              href: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Visual
              ]),
              id: "6.4"
            },
            {
              label: intl.formatMessage({
                defaultMessage: "Share"
              }),
              href: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Share
              ]),
              id: "6.5"
            },
            {
              label: intl.formatMessage({
                defaultMessage: "Resources"
              }),
              href: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.Resources
              ]),
              id: "6.6"
            }
          ]
        },
        /*
        {
          label: intl.formatMessage({
            defaultMessage: "My Dashboard"
          }),
          href: "/dashboard",
          id: "6.1"
        },
        /*
        {
          label: intl.formatMessage({ defaultMessage: "My Account" }),
          id: "6",
          items: [
            {
              label: intl.formatMessage({
                defaultMessage: "My Dashboard"
              }),
              href: ROUTES.Dashboard,
              id: "6.1"
            },
            {
              label: intl.formatMessage({
                defaultMessage: "Account Settings"
              }),
              href: "/business",
              id: "6.3"
            }
          ]
        }
        */
        {
          label: intl.formatMessage({
            defaultMessage: "Shared with Me"
          }),
          id: "7",
          items: [
            {
              label: intl.formatMessage({
                defaultMessage: "Summary"
              }),
              href: getLinkFullUrl([
                ROUTES.Dashboard,
                DASHBOARD_SECTION_ROUTES.SharedWithMeSummary
              ]),
              id: "7.01"
            },
            ...sharedPlanItems
          ]
        }
      ]
    };
  }, [data, intl, plansSharedWithMe]);

  return {
    headerProps,
    loading
  };
};
