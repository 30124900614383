import { format, isValid } from "date-fns";

import { DEFAULT_DATE_FORMAT } from "@setup/consts/misc";

const formatDate = (
  date?: Date | string | null,
  dateFormat = DEFAULT_DATE_FORMAT
): string => {
  if (!date) return "-";

  const parsedDate = new Date(date);

  if (!isValid(parsedDate)) return "-";

  return format(parsedDate, dateFormat);
};

export default formatDate;
