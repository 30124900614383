import { AssetType } from "@api/__generated__/globalTypes";

const getAssetParentPath = (assetType: string | undefined) => {
  let assetParentPath = "";

  switch (assetType) {
    case AssetType.BUSINESS_INTEREST:
      assetParentPath = "businessInterests";
      break;
    case AssetType.CASH:
      assetParentPath = "cash";
      break;
    case AssetType.FINANCIAL_ACCOUNT:
      assetParentPath = "financialAccounts";
      break;
    case AssetType.OTHER:
      assetParentPath = "otherAssets";
      break;
    case AssetType.PERSONAL_PROPERTY:
      assetParentPath = "personalProperty";
      break;
    case AssetType.REAL_ESTATE:
      assetParentPath = "realEstates";
      break;
    case AssetType.LIFE_INSURANCE_POLICY:
      assetParentPath = "lifeInsurancePolicies";
      break;
    default:
      assetParentPath = "unknownAsset";
  }

  return assetParentPath;
};

export default getAssetParentPath;
