import { makeMutationHook, makeQueryHook } from "@utils/apollo-utils";

import { GET_FINANCIAL_ADVISOR_METRICS_QUERY } from "./queries";
import { FinancialAdvisorMetricsQuery } from "./__generated__/FinancialAdvisorMetricsQuery";
import { ConvertToFinancialAdvisorMutation } from "./__generated__/ConvertToFinancialAdvisorMutation";
import { CONVERT_TO_FINANCIAL_ADVISOR_MUTATION } from "./mutations";

export const useFinancialAdvisorMetricsQuery =
  makeQueryHook<FinancialAdvisorMetricsQuery>(
    GET_FINANCIAL_ADVISOR_METRICS_QUERY
  );

export const useConvertToFinancialAdvisorMutation =
  makeMutationHook<ConvertToFinancialAdvisorMutation>(
    CONVERT_TO_FINANCIAL_ADVISOR_MUTATION
  );
