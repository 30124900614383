import { RESOURCE_PAGE_BASE_URL } from "./misc";

export const ExternalLinks = {
  csvImportInstructions: "https://docs.google.com/spreadsheets/d/14Nqd4XyGqD1tOTpQZjPM-Ty4EtE1OwoQ/edit?usp=sharing&ouid=111286056694915345855&rtpof=true&sd=true",
  lukeCalendly: "https://calendly.com/jic-luke",
  organDonor: "https://organdonor.gov",
  organDonorStatistics:
    "https://www.organdonor.gov/sites/default/files/organ-donor/learn/statistics/PatientsWaitingVSTransplantsPerf-ByOrgan.jpg"
};

export const BlogLinks = {
  contestingAWill: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/contesting-a-will`,
  familyMemberTrusteeCompensation: `${RESOURCE_PAGE_BASE_URL}/trusts/should-you-compensate-a-family-member-trustee`,
  operationOfLawVsProbate: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/operation-of-law-vs-probate-assets`,
  guideToAHCD: `${RESOURCE_PAGE_BASE_URL}/advance-health-care-directive/what-is-an-advance-healthcare-directive`,
  guideToGoodWitnesses: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/guide-to-picking-good-witnesses`,
  howMuchToLeavePet: `${RESOURCE_PAGE_BASE_URL}/pet-estate-plan/how-much-money-should-you-leave-for-your-pet`,
  howMuchPetEmergencyCare: `${RESOURCE_PAGE_BASE_URL}/pet-estate-plan/how-much-money-should-you-save-for-pet-emergency-care`,
  howToChooseGuardian: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/how-to-choose-a-guardian-for-your-minor-or-special-needs-children`,
  howToChooseRepresentative: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/how-to-choose-an-executor`,
  howToChooseMedicalPOA: `${RESOURCE_PAGE_BASE_URL}/advance-health-care-directive/how-to-choose-an-agent-for-a-medical-power-of-attorney`,
  howToFundTrust: `${RESOURCE_PAGE_BASE_URL}/trusts/how-to-fund-your-trust`,
  whatisFinancialPOA: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/what-is-a-durable-financial-power-of-attorney`,
  whatIsLimitedPetPOA: `${RESOURCE_PAGE_BASE_URL}/pet-estate-plan/what-is-a-limited-pet-power-of-attorney`,
  whatIsLivingWill: `${RESOURCE_PAGE_BASE_URL}/advance-health-care-directive/what-is-a-living-will`,
  whatIsNoContest: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/whats-a-no-contest-provision`,
  whatIsNotaryPublic: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/what-is-a-notary-public`,
  whatIsRevocableTrust: `${RESOURCE_PAGE_BASE_URL}/trusts/what-is-a-revocable-living-trust`,
  whatIsNoContestProvision: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/whats-a-no-contest-provision`,
  whichStatesAreCommunityProperty: `${RESOURCE_PAGE_BASE_URL}/last-will-and-testament/which-states-are-community-property-states`
};
