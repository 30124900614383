import React from "react";
import { FormattedMessage } from "react-intl";
import { Typography, Box } from "@mui/material";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import { useTheme } from "@mui/material/styles";

import { DocumentCardProps } from "./types";
import * as Styled from "./styles";

const DocumentCard: React.FC<DocumentCardProps> = ({
  title,
  owner,
  description
}) => {
  const theme = useTheme();

  return (
    <Styled.Wrapper 
      elevation={0}
      >
      <Typography variant="h2" fontWeight={600} mb={0.5}>
        {title}
      </Typography>
      {owner && (
        <Typography
          variant="h3"
          fontWeight={500}
          color={theme.palette.grey[500]}
          mb={1}
        >
          {owner}
        </Typography>
      )}
      <Box mb={1}>
        <CheckCircleTwoToneIcon sx={{ 
          fontSize: "2.5rem", 
          color: "#0DC0B4"
          }} />
      </Box>
      <Typography variant="caption" mb={2} component="p">
        <FormattedMessage defaultMessage="Ready for download at Checkout" />
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Styled.Wrapper>
  );
};

export default DocumentCard;
