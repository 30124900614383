export enum RemainderStrategy {
  PartnerOnly = "partnerOnly",
  ChildrenOnly = "childrenOnly",
  PartnerAndChildren = "partnerAndChildren",
  NameIndividually = "nameIndividually"
}

export enum AppointmentPower {
  Limited = "LIMITED_RESTRICTIONS",
  General = "GENERAL_RESTRICTIONS"
}

export enum TerminationDistribution {
  Outright = "OUTRIGHT",
  SeparateChildren = "SEPARATE_CHILDREN"
}

export enum CompensationType {
  None = "No compensation. Reimbursement of reasonable expenses only",
  Reasonable = "Reasonable compensation and reimbursement of expenses",
  Dollar = "A specific dollar amount and reimbursement of expenses",
  Percent = "A specific percent of my estate and reimbursement of expenses"
}

export enum ParentValues {
  PrimaryOnly = "primaryOnly",
  PartnerOnly = "partnerOnly",
  PrimaryAndPartner = "primaryAndPartner"
}
