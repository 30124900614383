import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import useCognitoAuth from "@hooks/useCognitoAuth";
import { ROUTES } from "@setup/consts/routes";

const SignupConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const { confirmSignUp } = useCognitoAuth();
  const [searchParams] = useSearchParams();

  const username = searchParams.get("username") as string;
  const code = searchParams.get("verificationCode") as string;

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await confirmSignUp(username, code);
        navigate(ROUTES.SignIn);
      } catch {}
    })();
  }, [code, confirmSignUp, navigate, username]);

  return null;
};

export default SignupConfirmation;
