/* eslint-disable react/no-unescaped-entities */
import { useCallback } from "react";
import get from "lodash/get";

import SingleSelectArchetype from "@components/archetypes/SingleSelect";
import { SingleSelectFormValues } from "@components/archetypes/SingleSelect/types";
import { useFormField } from "@components/FormFields/hooks";
import { parseBoolean } from "@utils/parseBoolean";

const GrantMedicalAccessStep: React.FC = () => {
  const { setValue: setMedicalAccessValue } = useFormField(
    "users.0.healthcare.medicalRecordAccess.people"
  );

  const { value: agentsAuthorized } = useFormField<boolean>(
    `users.0.healthcare.authorizeAgents`
  );

  const onSubmit = useCallback(
    (data: SingleSelectFormValues) => {
      const grantMedicalAccess = get(
        data,
        "users.0.healthcare.medicalRecordAccess.authorizeOthers"
      );

      if (!parseBoolean(grantMedicalAccess)) {
        setMedicalAccessValue([]);
      }
    },
    [setMedicalAccessValue]
  );

  if (parseBoolean(agentsAuthorized)) {
    return (
      <SingleSelectArchetype
        stepLayout={{
          title: `In addition to your healthcare agent, do you want to grant anyone else access to your medical records?`,
          subText: `Learn more about HIPAA authorizations.`,
          moreInfoModalProps: {
            title: "HIPAA Authorizations",
            togglerLabel: "Learn more",
            content: (
              <p>
                The Health Insurance Portability and Accountability
                Act (HIPAA) is a federal law that protects sensitive
                patient health information from being disclosed
                without the patient's consent or knowledge.
                <br />
                <br />
                When you provide a HIPAA Authorization and Release to
                a person, that person:
                <br />
                ✅ Can communicate with your doctors and receive
                updates on your medical condition
                <br />
                🚫 Cannot make any healthcare decisions for you,
                unless you've separately made them your healthcare
                agent under a medical power of attorney
                <br />
                <br />
                You may revoke a person's authorization at any time by
                providing notice to your healthcare and health
                information service providers.
              </p>
            ),
            closeLabel: "Got it"
          }
        }}
        {...{ onSubmit }}
        name="users.0.healthcare.medicalRecordAccess.authorizeOthers"
      />
    );
  }

  return (
    <SingleSelectArchetype
      stepLayout={{
        title: `Ok, no healthcare agent. Do you want to grant anyone access to your medical information?`,
        subText: `Learn more about HIPAA authorizations.`,
        moreInfoModalProps: {
          title: "HIPAA Authorizations",
          togglerLabel: "Learn more",
          content: (
            <p>
              The Health Insurance Portability and Accountability Act
              (HIPAA) is a federal law that protects sensitive patient
              health information from being disclosed without the
              patient's consent or knowledge.
              <br />
              <br />
              When you provide a HIPAA Authorization and Release to a
              person, that person:
              <br />
              ✅ Can communicate with your doctors and receive updates
              on your medical condition
              <br />
              🚫 Cannot make any healthcare decisions for you, unless
              you've separately made them your healthcare agent under
              a medical power of attorney
              <br />
              <br />
              You may revoke a person's authorization at any time by
              providing notice to your healthcare and health
              information service providers.
            </p>
          ),
          closeLabel: "Got it"
        }
      }}
      {...{ onSubmit }}
      name="users.0.healthcare.medicalRecordAccess.authorizeOthers"
    />
  );
};

export default GrantMedicalAccessStep;
