import * as Styled from "./styles";
import { BurgerProps } from "./types";

const Burger: React.FC<BurgerProps> = ({ open, setOpen }) => (
  <Styled.Burger {...{ open }} onClick={(): void => setOpen(!open)}>
    <div />
    <div />
    <div />
  </Styled.Burger>
);

export default Burger;
