import SyncMUIIcon from "@mui/icons-material/Sync";
import { styled, keyframes } from "@mui/material/styles";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SyncIcon = styled(SyncMUIIcon)`
  animation: ${rotate} 2s linear infinite;
`;
