import * as yup from "yup";

export const BASIC_PERSON_DETAILS_SCHEMA = yup.object().shape({
  id: yup.string(),
  name: yup.string().required().nullable(),
  relationship: yup.string(),
  phone: yup.string().typeError("Please enter a valid phone number"),
  address: yup
    .object()
    .shape({
      address1: yup.string().required(),
      address2: yup.string(),
      city: yup.string().required(),
      zip: yup.string().required(),
      state: yup.string().nullable().required("Field is required"),
      country: yup
        .string()
        .typeError("Please select a country from the dropdown options")
    })
    .required()
});
