/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled } from "@mui/material/styles";

import { ImageProps, StyledImageProps } from "./types";

export const Image = styled(
  ({ objectFit, objectPosittion, alt, ...rest }: ImageProps) => (
    <img {...{ alt, ...rest }} />
  )
)<StyledImageProps>(({ objectFit, objectPosittion }) => ({
  width:
    objectFit === "contain" || objectFit === "cover"
      ? "100%"
      : "auto",
  objectFit,
  objectPosition: objectPosittion
}));
