import { useCallback, useEffect } from "react";

import { AssetType } from "@api/__generated__/globalTypes";
import { useFormWithYup } from "@hooks/useFormWithYup";
import useAllocationSum from "@hooks/useAllocationSum";

import { useValidationSchema } from "./useAddAssetValidationSchema";
import { AssetFormValues, UseAssetFormParams } from "./types";
import mapFormValuesToAsset from "./utils/mapFormValuesToAsset";

export const useQuestionnaireAssetForm = ({
  onSubmit,
  defaultValues
}: UseAssetFormParams) => {
  const validationSchema = useValidationSchema();
  const methods = useFormWithYup<AssetFormValues>(validationSchema, {
    defaultValues: defaultValues || {
      type: AssetType.REAL_ESTATE,
      beneficiaries: [{}],
      isGift: true
    }
  });

  const assetType = methods.watch("type");
  const beneficiaries = methods.watch("beneficiaries", []);
  const isGift = methods.watch("isGift", true);

  useEffect(() => {
    if (!isGift && beneficiaries && beneficiaries?.length > 0) {
      methods.setValue("beneficiaries", []);
    }
  }, [beneficiaries, isGift, methods]);

  const { exceeded, left } = useAllocationSum(
    [...(beneficiaries || [undefined])],
    {
      path: "percentageValue"
    }
  );

  const resetForm = useCallback(
    (type: string) => {
      methods.reset({
        type: type as AssetType,
        beneficiaries: [{}],
        isGift: true
      });
    },
    [methods]
  );

  const onFormSubmit = useCallback(
    (data: AssetFormValues) => {
      const input = mapFormValuesToAsset(data);

      onSubmit(input);
    },
    [onSubmit]
  );

  return {
    methods,
    handleSubmit: methods.handleSubmit(onFormSubmit),
    resetForm,
    assetType,
    exceeded: isGift ? exceeded : false,
    left: isGift ? left : 0,
    isGift
  };
};
