import SectionsPreview from "@components/archetypes/SectionPreview";
import sections from "@pages/Healthcare/routes";
import { ROUTES } from "@setup/consts/routes";
import { DASHBOARD_SECTION_ROUTES } from "@pages/EstatePlanView/Partials/consts";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";

const MedicalPOAPreviewStep: React.FC = () => (
  <SectionsPreview
    {...{ sections }}
    stepLayout={{
      title: "Let's create your Advance Health Care Directive",
      subText:
        "An advance health care directive allows you to appoint a trusted individual to make healthcare decisions on your behalf as well as specify the type of care you'd like to receive."
    }}
    prevButtonProps={{
      type: "button",
      href: getLinkFullUrl([
        ROUTES.Dashboard,
        DASHBOARD_SECTION_ROUTES.DocsAndData
      ])
    }}
  />
);

export default MedicalPOAPreviewStep;
